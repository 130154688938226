export const enum EFPEnrolledMerchantListParams {
  size = 'limit',
  page = 'page',
  sort = 'order_by',
  search = 'search',
}

export const enum EFPEnrolledMerchantListSort {
  asc = 'asc',
  desc = 'desc',
  createdOn = 'created_on',
}

export const CFPEnrolledMerchantListParamsDefault = {
  [EFPEnrolledMerchantListParams.size]: 10,
  [EFPEnrolledMerchantListParams.page]: 1,
  [EFPEnrolledMerchantListParams.sort]: [
    EFPEnrolledMerchantListSort.createdOn,
    EFPEnrolledMerchantListSort.asc,
  ],
  [EFPEnrolledMerchantListParams.search]: '',
};
