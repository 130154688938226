<gtr-modal
  #addGatewayConfigModal
  id="addGatewayConfigModal"
  [isOpen]="false"
  [modalSize]="'medium'"
  [header]="
    editable ? 'Update gateway configuration' : 'Add gateway configuration'
  "
  (closeModal)="closeModal()"
  [dataTransactionName]="'account-gateway-config-add'">
  <div slot="modal-body">
    <ng-container *ngIf="hasOpened">
      <form (ngSubmit)="submitForm()" [formGroup]="gatewayConfigForm" [attr.data-transaction-name]="'account-gateway-config-add'">
        <div class="row">
          <div class="col-12 col-md-6 mb-1">
            <gtr-select
              inputLabel="Payment Method Type"
              [isOnlyView]="false"
              [valueKey]="'value'"
              [labelKey]="'label'"
              [options]="paymentMethodTypeOption"
              [formControlName]="formKeys.configPm"
              [is_invalid]="gatewayConfigForm?.controls?.[formKeys.configPm]?.invalid && (gatewayConfigForm?.controls?.[formKeys.configPm]?.touched || gatewayConfigForm?.controls?.[formKeys.configPm]?.dirty)"
              (valueChanged)="toggleConfigPm($event)"
              [dataTransactionName]="'account-gateway-config-add--payment-method-type'">
              <div slot="custom-error">
                <app-input-error-msg
                  [control]="gatewayConfigForm?.controls?.[formKeys.configPm]"
                >
                </app-input-error-msg>
              </div>
            </gtr-select>
          </div>

          <div class="col-12 col-md-6 mb-1">
            <gtr-select
              inputLabel="Configuration Type"
              [isOnlyView]="false"
              [valueKey]="'value'"
              [labelKey]="'label'"
              [options]="gatewayConfigForm?.controls?.[formKeys.configPm]?.value === paymentMethodType.ach ? configTypeAchOptions : configTypeOptions"
              [formControlName]="formKeys.configType"
              [is_invalid]="gatewayConfigForm?.controls?.[formKeys.configType]?.invalid && (gatewayConfigForm?.controls?.[formKeys.configType]?.touched || gatewayConfigForm?.controls?.[formKeys.configType]?.dirty)"
              (valueChanged)="toggleConfigType($event)"
              [dataTransactionName]="'account-gateway-config-add--configuration-type'">
              <div slot="custom-error">
                <app-input-error-msg
                  [control]="gatewayConfigForm?.controls?.[formKeys.configType]"
                >
                </app-input-error-msg>
              </div>
            </gtr-select>
          </div>

          <div class="col-12 col-md-6 mb-1">
            <gtr-input
              type="text"
              [placeholder]="''"
              [inputLabel]="'MID'"
              [formControlName]="formKeys.mid"
              [isValid]="!gatewayConfigForm?.controls?.[formKeys.mid]?.touched || ((gatewayConfigForm?.controls?.[formKeys.mid]?.touched || gatewayConfigForm?.controls?.[formKeys.mid]?.dirty) && gatewayConfigForm?.controls?.[formKeys.mid]?.valid)"
              [dataTransactionName]="'account-gateway-config-add--mid'">
              <div slot="custom-error">
                <app-input-error-msg
                  [control]="gatewayConfigForm?.controls?.[formKeys.mid]"
                >
                </app-input-error-msg>
              </div>
            </gtr-input>
          </div>

          <ng-container
            *ngIf="gatewayConfigForm?.controls?.[formKeys.configPm]?.value === paymentMethodType.card"
          >
            <div class="col-12 col-md-6 mb-1">
              <gtr-input
                type="text"
                [placeholder]="''"
                [inputLabel]="'TID'"
                [formControlName]="formKeys.tid"
                [isValid]="!gatewayConfigForm?.controls?.[formKeys.tid]?.touched || ((gatewayConfigForm?.controls?.[formKeys.tid]?.touched || gatewayConfigForm?.controls?.[formKeys.tid]?.dirty) && gatewayConfigForm?.controls?.[formKeys.tid]?.valid)"
                [dataTransactionName]="'account-gateway-config-add--tid'">
                <div slot="custom-error">
                  <app-input-error-msg
                    [control]="gatewayConfigForm?.controls?.[formKeys.tid]"
                  >
                  </app-input-error-msg>
                </div>
              </gtr-input>
            </div>

            <div
              class="col-12 col-md-6 mb-1"
              *ngIf="gatewayConfigForm?.controls?.[formKeys.configType]?.value !== configTypes.direct"
            >
              <gtr-input
                type="text"
                [placeholder]="''"
                [inputLabel]="'Payfac MID'"
                [formControlName]="formKeys.payfacMid"
                [isValid]="!gatewayConfigForm?.controls?.[formKeys.payfacMid]?.touched || ((gatewayConfigForm?.controls?.[formKeys.payfacMid]?.touched || gatewayConfigForm?.controls?.[formKeys.payfacMid]?.dirty) && gatewayConfigForm?.controls?.[formKeys.payfacMid]?.valid)"
                [dataTransactionName]="'account-gateway-config-add--payfac-mid'">
                <div slot="custom-error">
                  <app-input-error-msg
                    [control]="gatewayConfigForm?.controls?.[formKeys.payfacMid]"
                  >
                  </app-input-error-msg>
                </div>
              </gtr-input>
            </div>
          </ng-container>

          <div class="col-12 col-md-6 mb-1">
            <gtr-input
              type="text"
              [placeholder]="''"
              [inputLabel]="'Statement Descriptor Prefix'"
              [formControlName]="formKeys.descriptorPrefix"
              [isValid]="!gatewayConfigForm?.controls?.[formKeys.descriptorPrefix]?.touched || ((gatewayConfigForm?.controls?.[formKeys.descriptorPrefix]?.touched || gatewayConfigForm?.controls?.[formKeys.descriptorPrefix]?.dirty) && gatewayConfigForm?.controls?.[formKeys.descriptorPrefix]?.valid)"
              [dataTransactionName]="'account-gateway-config-add--statement-descriptor-prefix'">
              <div slot="custom-error">
                <app-input-error-msg
                  [control]="gatewayConfigForm?.controls?.[formKeys.descriptorPrefix]"
                >
                </app-input-error-msg>
              </div>
            </gtr-input>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-12 mb-1">
            <ng-container
              *ngIf="
                gatewayConfigForm?.controls?.[formKeys.configPm]?.value ===
                paymentMethodType.card
              "
            >
              <gtr-checkbox
                [checked]="
                  gatewayConfigForm?.controls?.[formKeys.setAsDefaultCCConfig]
                    ?.value
                "
                [label]="'Set as Card default configuration'"
                (selectionChanged)="
                  selectionChanged($event, formKeys.setAsDefaultCCConfig)
                " [dataTransactionName]="'account-gateway-config-add--set-default-config'"
              >
              </gtr-checkbox>
            </ng-container>

            <ng-container
              *ngIf="
                gatewayConfigForm?.controls?.[formKeys.configPm]?.value ===
                paymentMethodType.ach
              "
            >
              <gtr-checkbox
                [checked]="
                  gatewayConfigForm?.controls?.[formKeys.setAsDefaultACHConfig]
                    ?.value
                "
                [label]="'Set as ACH default configuration'"
                (selectionChanged)="
                  selectionChanged($event, formKeys.setAsDefaultACHConfig)
                " [dataTransactionName]="'account-gateway-config-add--set-ach-default-config'"
              >
              </gtr-checkbox
            ></ng-container>
          </div>
        </div>
      </form>
    </ng-container>
  </div>
  <div class="d-flex flex-row mt-36" slot="modal-actions">
    <gtr-button variant="link" color="gray" (click)="closeModal()"
                [dataTransactionName]="'account-gateway-config-add--cancel'"
                [attr.data-transaction-name]="'account-gateway-config-add--cancel'"
      >Cancel</gtr-button
    >
    <gtr-button
      variant="raised"
      color="primary"
      [isLoading]="loading"
      [disabled]="loading"
      (click)="submitForm()"
      [dataTransactionName]="'account-gateway-config-add--add'"
      [attr.data-transaction-name]="'account-gateway-config-add--add'"
      >{{ editable ? "Update" : "Add" }}</gtr-button
    >
  </div>
</gtr-modal>
