import { Router } from '@angular/router';
import { EMerchantApplicationIdUrl } from '@parameters/authentication/msp-merchant-application-list.parameter';

export class ApiEndpointHelper {
  public static setApiUrl(router: Router): string {
    try {
      const currentRoute = router.routerState.snapshot.root;
      let merchantId: string = '';
      const segments = this.getRouteSegments(currentRoute) || [];
      const activeSegment = segments?.find((segment: string) =>
        segment?.includes(`:${EMerchantApplicationIdUrl.merchantApplicationId}`)
      );
      const vertex = activeSegment?.split('/') || [];
      const activeVertex = vertex?.find((vertex: string) =>
        vertex?.includes(`:${EMerchantApplicationIdUrl.merchantApplicationId}`)
      );
      if (
        activeSegment?.includes(
          `:${EMerchantApplicationIdUrl.merchantApplicationId}`
        ) ||
        activeVertex?.includes(
          `:${EMerchantApplicationIdUrl.merchantApplicationId}`
        )
      ) {
        const uuidPattern =
          /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
        const urlSize = router.routerState?.snapshot?.url?.split('/') || [];
        const id = urlSize?.find((url: string) => uuidPattern?.test(url));
        merchantId = id;
      }
      return merchantId || '';
    } catch (err) {}
  }
  public static getRouteSegments(route: any): string[] {
    const segments = [];
    while (route) {
      if (route.routeConfig && route.routeConfig.path) {
        segments.unshift(route.routeConfig.path);
      }
      route = route.firstChild;
    }
    return segments;
  }
}
