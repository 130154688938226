import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class CookieClientService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  public setCookie(key: string, value: any) {
    this.document.cookie = `${key}=${JSON.stringify(value)}`;
  }

  public getCookie<T>(key: string) {
    let name = `${key}=`;
    let decodedCookie = decodeURIComponent(this.document.cookie);
    let ca = decodedCookie.split(';');

    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return JSON.parse(c.substring(name.length, c.length)) as T;
      }
    }
    return null;
  }

  public removeCookie(key: string) {
    this.document.cookie = `${key}=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
  }
}
