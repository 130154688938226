import { EEquipmentListForOnboardingCustomizationParams } from '@parameters/core/equipment-list-for-onboarding-customization.parameter';
import {
  IResponseBase,
  IResponseData,
} from '@interfaces/generic/responses.interface';
import { IEquipmentForOnboardingCustomization } from '@interfaces/core/equipment-for-onboarding-customization.interface';

export interface IEquipmentListForOnboardingCustomizationParams {
  [EEquipmentListForOnboardingCustomizationParams.size]: number;
  [EEquipmentListForOnboardingCustomizationParams.page]: number;
  [EEquipmentListForOnboardingCustomizationParams.cashDiscount]: boolean;
}

export const CEquipmentListForOnboardingCustomizationParamsDefault: IEquipmentListForOnboardingCustomizationParams =
  {
    [EEquipmentListForOnboardingCustomizationParams.size]: 50,
    [EEquipmentListForOnboardingCustomizationParams.page]: 1,
    [EEquipmentListForOnboardingCustomizationParams.cashDiscount]: false,
  };

export interface IEquipmentListForOnboardingCustomizationResponse
  extends IResponseBase<IResponseData<IEquipmentForOnboardingCustomization>> {}
