import {
  IResponseBase,
  IResponseData,
} from '@interfaces/generic/responses.interface';
import {
  EDepositDetailCSVBody,
  EDepositDetailParams,
} from '@parameters/reports/deposits/deposit-detail.parameter';
import { EDepositType } from '@parameters/reports/deposits/deposit.parameter';

export interface IDepositDetailParams {
  [EDepositDetailParams.size]: number;
  [EDepositDetailParams.page]: number;
}

export interface IDepositDetail {
  totalTransactionAmount: number;
  totalFeeAmount: number;
  totalReserveAmount: number;
  totalNetAmount: number;
  offsetType: EDepositType;
}

export interface IDepositDetailSettlement {
  transactionAmount: number;
  feeAmount: number;
  reserveAmount: number;
  netAmount: number;
  settlementDetailDescription: string;
}

export interface IDepositDetailResponseData<T> extends IResponseData<T> {
  other: IDepositDetail;
}

export interface IDepositDetailResponse
  extends IResponseBase<IDepositDetailResponseData<IDepositDetailSettlement>> {}

export const CDepositDetailParamsDefault: IDepositDetailParams = {
  [EDepositDetailParams.size]: 10,
  [EDepositDetailParams.page]: 1,
};

// Download CSV Params
export interface IDepositDetailCSVBody {
  [EDepositDetailCSVBody.id]: string;
}

export interface IDepositDetailCSVResponse
  extends IResponseBase<{ url: string }> {}

export const CDepositDetailCSVBodyDefault: IDepositDetailCSVBody = {
  [EDepositDetailCSVBody.id]: null,
};
