import {
  EFPAllowedMerchantsParams,
  EFPAllowedMerchantsSort,
} from '@parameters/funding-plans/fp-allowed-merchants.parameter';
import {
  IResponseBase,
  IResponseData,
} from '@interfaces/generic/responses.interface';

export interface IFPAllowedMerchantsParams {
  [EFPAllowedMerchantsParams.size]: number;
  [EFPAllowedMerchantsParams.page]: number;
  [EFPAllowedMerchantsParams.sort]: EFPAllowedMerchantsSort[];
}

export const CFPAllowedMerchantsParamsDefault = {
  [EFPAllowedMerchantsParams.size]: 10,
  [EFPAllowedMerchantsParams.page]: 1,
  [EFPAllowedMerchantsParams.sort]: [EFPAllowedMerchantsSort.createdDateAsc],
};

export interface IFPAllowedMerchants {
  id: string;
  bussinessName: string;
  createdAt: string;
}

export interface IFPAllowedMerchantsResponse
  extends IResponseBase<IResponseData<IFPAllowedMerchants>> {}
