import { ApmBase, init as initApm } from '@elastic/apm-rum';
import { environment } from "@environments/environment";
import { ProxyGateway } from "@parameters/http/api-proxy-gateway";

export function createApmInstance(): ApmBase {
  const environmentName = environment.production ? 'prod' : 'dev';
  const apiBase = ProxyGateway.loadBaseApiUrl()?.slice(0, -1);
  const serviceName = `isoaas-frontend-${environmentName}`

  // Agent API is exposed through this apm instance
  return location.hostname !== 'localhost' ?
    initApm({
      serviceName: serviceName,
      serverUrl: environment.esRUM_ServiceURL,
      distributedTracingOrigins: [apiBase],
      environment: environmentName,
      propagateTracestate: true
    })
    : null;
}

export const apmInstance = createApmInstance();
