export const enum EReportsAuthStatus {
  approved = 'approved',
  declined = 'declined',
  error = 'error',
  unknown = 'unknown',
}

export const CReportsAuthStatusColor = {
  [EReportsAuthStatus.approved]: 'success',
  [EReportsAuthStatus.declined]: 'dark',
  [EReportsAuthStatus.error]: 'warning',
  [EReportsAuthStatus.unknown]: 'info',
};

export const CReportsAuthStatusName = {
  [EReportsAuthStatus.approved]: 'Approved',
  [EReportsAuthStatus.declined]: 'Declined',
  [EReportsAuthStatus.error]: 'Error',
  [EReportsAuthStatus.unknown]: 'Unknown',
};

export const enum EReportsAuthOperationType {
  credit = 'credit',
  debit = 'debit',
}

export const CReportsAuthOperationTypeColor = {
  [EReportsAuthOperationType.credit]: 'warning',
  [EReportsAuthOperationType.debit]: 'blue',
};

export const CReportsAuthOperationTypeName = {
  [EReportsAuthOperationType.credit]: 'Credit',
  [EReportsAuthOperationType.debit]: 'Debit',
};
