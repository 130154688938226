export class EntitiesFormHelper {
  public static formatPayload(formValue: any) {
    // Check if the input is an object
    if (typeof formValue !== 'object' || formValue === null) {
      return formValue;
    }

    for (let key in formValue) {
      if (formValue.hasOwnProperty(key)) {
        // Replace empty strings with null
        if (formValue[key] === '') {
          formValue[key] = null;
        }
        // Trim string values
        if (typeof formValue[key] === 'string') {
          formValue[key] = formValue[key].trim();
        }
        // Recursive call for nested objects
        if (typeof formValue[key] === 'object' && formValue[key] !== null) {
          formValue[key] = this.formatPayload(formValue[key]);
        }
      }
    }
  }
}
