import {
  IResponseBase,
  IResponseData,
} from '@interfaces/generic/responses.interface';
import { EMyMerchantListParams } from '@parameters/merchant/my-merchant/my-merchant-list.parameter';
import { IMyMerchant } from '@interfaces/merchant/my-merchant/my-merchant.interface';

export interface IMyMerchantListParams {
  [EMyMerchantListParams.page]: number;
  [EMyMerchantListParams.size]: number;
  [EMyMerchantListParams.search]: string;
}

export const CMyMerchantListParamsDefault: IMyMerchantListParams = {
  [EMyMerchantListParams.page]: 1,
  [EMyMerchantListParams.size]: 10,
  [EMyMerchantListParams.search]: '',
};

export interface IMyMerchantListResponse
  extends IResponseBase<IResponseData<IMyMerchant>> {}
