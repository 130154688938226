import { IAccountGatewayConfigForm } from '@interfaces/accounts/account-gateway-configurations.interface';

export enum EPaymentMethodType {
  card = 'card',
  ach = 'ach',
}

export const CPaymentMethodType = {
  [EPaymentMethodType.ach]: 'ACH',
  [EPaymentMethodType.card]: 'Card',
};

export const CPaymentMethodTypeOptions = [
  {
    value: EPaymentMethodType.card,
    label: CPaymentMethodType[EPaymentMethodType.card],
  },
  {
    value: EPaymentMethodType.ach,
    label: CPaymentMethodType[EPaymentMethodType.ach],
  },
];

export enum EConfigType {
  direct = 'direct',
  subMerchant = 'sub',
}

export const CConfigType = {
  [EConfigType.direct]: 'Direct',
  [EConfigType.subMerchant]: 'Sub-merchant',
};

export const CConfigTypeOptions = [
  {
    value: EConfigType.direct,
    label: CConfigType[EConfigType.direct],
  },
  {
    value: EConfigType.subMerchant,
    label: CConfigType[EConfigType.subMerchant],
  },
];

export const CConfigTypeAchOptions = [
  {
    value: EConfigType.direct,
    label: CConfigType[EConfigType.direct],
  },
];

export enum EAccountGatewayConfigFormKeys {
  configType = 'configType',
  configPm = 'configPm',
  mid = 'mid',
  tid = 'tid',
  descriptorPrefix = 'descriptorPrefix',
  payfacMid = 'payfacMid',
  setAsDefaultCCConfig = 'setAsDefaultCCConfig',
  setAsDefaultACHConfig = 'setAsDefaultACHConfig',
}

export const CAccountGatewayConfigFormValue: IAccountGatewayConfigForm = {
  [EAccountGatewayConfigFormKeys.configType]: EConfigType.direct,
  [EAccountGatewayConfigFormKeys.configPm]: EPaymentMethodType.card,
  [EAccountGatewayConfigFormKeys.mid]: '',
  [EAccountGatewayConfigFormKeys.tid]: '',
  [EAccountGatewayConfigFormKeys.descriptorPrefix]: '',
  [EAccountGatewayConfigFormKeys.payfacMid]: '',
  [EAccountGatewayConfigFormKeys.setAsDefaultCCConfig]: false,
  [EAccountGatewayConfigFormKeys.setAsDefaultACHConfig]: false,
};

export enum EAccountGatewayConfigListLabel {
  configType = 'config type | id',
  configuration = 'configuration',
  defaultConfig = 'default config',
  descriptorPrefix = 'descriptor prefix',
  actions = 'actions',
}
