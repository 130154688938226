import { Injectable } from '@angular/core';
import { GenericClientService } from '@clients/generic/generic-client.service';
import { Observable } from 'rxjs';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import { HttpParams } from '@angular/common/http';
import { EMetricsKpisDealParams } from '@parameters/metrics/metrics-kpis-deal.parameter';
import {
  CMetricsKPIsDealParamsDefault,
  IMetricsKPIsDealParams,
  IMetricsKPIsDealResponse,
} from '@interfaces/metrics/metrics-kpis-deal.interface';

@Injectable()
export class MetricsKPIsDealClientService {
  constructor(private genericClient: GenericClientService) {}

  getMetricsKpisDeal(
    queryParams: IMetricsKPIsDealParams
  ): Observable<IMetricsKPIsDealResponse> {
    const endpoint = EndpointsParameter.MetricsKPIsDeal;

    const params = new HttpParams()
      .set(
        EMetricsKpisDealParams.startDate,
        queryParams[EMetricsKpisDealParams.startDate] ||
          CMetricsKPIsDealParamsDefault[EMetricsKpisDealParams.startDate]
      )
      .set(
        EMetricsKpisDealParams.endDate,
        queryParams[EMetricsKpisDealParams.endDate] ||
          CMetricsKPIsDealParamsDefault[EMetricsKpisDealParams.endDate]
      )
      .set(
        EMetricsKpisDealParams.kpiList,
        queryParams[EMetricsKpisDealParams.kpiList].join(',') ||
          CMetricsKPIsDealParamsDefault[EMetricsKpisDealParams.kpiList].join(
            ','
          )
      )
      .set(
        EMetricsKpisDealParams.relationshipList,
        queryParams[EMetricsKpisDealParams.relationshipList].join(',') ||
          CMetricsKPIsDealParamsDefault[
            EMetricsKpisDealParams.relationshipList
          ].join(',')
      );
    return this.genericClient.genericGet(endpoint, { params });
  }
}
