import { Injectable } from '@angular/core';
import { GenericClientService } from '@clients/generic/generic-client.service';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import {
  CReportsAuthListParamsDefault,
  IReportsAuthCSVParams,
  IReportsAuthCSVResponse,
  IReportsAuthListParams,
  IReportsAuthListResponse,
} from '@interfaces/reports/auth/auth-list.interface';
import { EReportsAuthListParams } from '@parameters/reports/auth/auth-list.parameter';
import { IReportsAuthDetailResponse } from '@interfaces/reports/auth/auth-detail.interface';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';

@Injectable()
export class AuthClientService {
  constructor(private genericClient: GenericClientService) {}

  getAuthList(
    queryParams: IReportsAuthListParams
  ): Observable<IReportsAuthListResponse> {
    const endpoint = EndpointsParameter.ReportsAuthsV2;
    let params = new HttpParams()
      .set(
        EReportsAuthListParams.size,
        queryParams[EReportsAuthListParams.size] ||
          CReportsAuthListParamsDefault[EReportsAuthListParams.size]
      )
      .set(
        EReportsAuthListParams.page,
        queryParams[EReportsAuthListParams.page] ||
          CReportsAuthListParamsDefault[EReportsAuthListParams.page]
      )
      .set(
        EReportsAuthListParams.sort,
        queryParams[EReportsAuthListParams.sort] ||
          CReportsAuthListParamsDefault[EReportsAuthListParams.sort]
      )
      .set(
        EReportsAuthListParams.search,
        queryParams[EReportsAuthListParams.search] ||
          CReportsAuthListParamsDefault[EReportsAuthListParams.search]
      )
      .set(
        EReportsAuthListParams.startDate,
        queryParams[EReportsAuthListParams.startDate] ||
          CReportsAuthListParamsDefault[EReportsAuthListParams.startDate]
      )
      .set(
        EReportsAuthListParams.endDate,
        queryParams[EReportsAuthListParams.endDate] ||
          CReportsAuthListParamsDefault[EReportsAuthListParams.endDate]
      )
      .set(
        EReportsAuthListParams.midList,
        queryParams[EReportsAuthListParams.midList].join(',') ||
          CReportsAuthListParamsDefault[EReportsAuthListParams.midList].join(
            ','
          )
      )
      .set(
        EReportsAuthListParams.binList,
        queryParams[EReportsAuthListParams.binList].join(',') ||
          CReportsAuthListParamsDefault[EReportsAuthListParams.binList].join(
            ','
          )
      )
      .set(
        EReportsAuthListParams.cardBrandList,
        queryParams[EReportsAuthListParams.cardBrandList].join(',') ||
          CReportsAuthListParamsDefault[
            EReportsAuthListParams.cardBrandList
          ].join(',')
      )
      .set(
        EReportsAuthListParams.operationTypeList,
        queryParams[EReportsAuthListParams.operationTypeList].join(',') ||
          CReportsAuthListParamsDefault[
            EReportsAuthListParams.operationTypeList
          ].join(',')
      );

    if (queryParams[EReportsAuthListParams.status]) {
      params = params.append(
        EReportsAuthListParams.status,
        queryParams[EReportsAuthListParams.status].join(',') ||
          CReportsAuthListParamsDefault[EReportsAuthListParams.status].join(',')
      );
    }

    return this.genericClient.genericGet<IReportsAuthListResponse>(endpoint, {
      params,
    });
  }

  getAuthDetail(id: string): Observable<IReportsAuthDetailResponse> {
    const endpoint = `${EndpointsParameter.ReportsAuths}/${id}`;
    return this.genericClient.genericGet<IReportsAuthDetailResponse>(endpoint);
  }

  downloadCSV(
    body: IReportsAuthCSVParams
  ): Observable<IReportsAuthCSVResponse> {
    const endpoint = EndpointsParameter.ReportsAuthCSV;
    return this.genericClient.genericPost<IReportsAuthCSVResponse>(
      endpoint,
      body
    );
  }
}
