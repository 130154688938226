import { Injectable } from '@angular/core';
import { GenericClientService } from '@clients/generic/generic-client.service';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GenericHelper } from '@helpers/generic.helper';
import {
  IFPOnboardingTemplateCreateRequest,
  IFPOnboardingTemplateCreateResponse,
  IFPOnboardingTemplateListParams,
  IFpOnboardingTemplateListResponse,
} from '@interfaces/funding-plans/fp-onboarding-templates.interface';
import {
  CFPOnboardingTemplateListParamsDefault,
  EFPOnboardingTemplateListParams,
} from '@parameters/funding-plans/fp-onboarding-templates-list.parameter';
import {
  IFPChangeLogListResponse,
  IFPChangeLogParams,
} from '@interfaces/funding-plans/fp-change-log-list.interface';
import {
  IFPCreateEnrolledMerchantRequest,
  IFPCreateEnrolledMerchantResponse,
  IFPDeleteEnrolledMerchantRequest,
  IFPDeleteEnrolledMerchantResponse,
  IFPEnrolledMerchantListResponse,
  IFPEnrolledMerchantParams,
} from '@interfaces/funding-plans/fp-enrolled-merchant-list.interface';
import {
  CFPEnrolledMerchantListParamsDefault,
  EFPEnrolledMerchantListParams,
} from '@parameters/funding-plans/fp-enrolled-merchant-list.parameter';
import {
  CFPChangeLogListParamsDefault,
  EFPChangeLogListParams,
} from '@parameters/funding-plans/fp-change-log-list.parameter';
import {
  CFPAllowedMerchantsParamsDefault,
  IFPAllowedMerchantsParams,
  IFPAllowedMerchantsResponse,
} from '@interfaces/funding-plans/fp-allowed-merchants.interface';
import { EFPAllowedMerchantsParams } from '@parameters/funding-plans/fp-allowed-merchants.parameter';
import {
  CFPAllowedOnboardingTemplatesParamsDefault,
  IFPAllowedOnboardingTemplatesParams,
  IFPAllowedOnboardingTemplatesResponse,
} from '@interfaces/funding-plans/fp-allowed-onboarding-templates.interface';
import { EFPAllowedOnboardingTemplatesParams } from '@parameters/funding-plans/fp-allowed-onboarding-templates.parameter';

@Injectable()
export class FundingPlanDetailClientService {
  constructor(private genericClient: GenericClientService) {}

  /**
   * Get Funding Plan Onboarding Template List
   * @returns
   */
  getOnboardingTemplateList(
    fundingPlanId: string,
    queryParams: IFPOnboardingTemplateListParams
  ): Observable<IFpOnboardingTemplateListResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.FPOnboardingTemplate,
      fundingPlanId
    );

    const params = new HttpParams()
      .set(
        EFPOnboardingTemplateListParams.size,
        queryParams[EFPOnboardingTemplateListParams.size] ||
          CFPOnboardingTemplateListParamsDefault[
            EFPOnboardingTemplateListParams.size
          ]
      )
      .set(
        EFPOnboardingTemplateListParams.page,
        queryParams[EFPOnboardingTemplateListParams.page] ||
          CFPOnboardingTemplateListParamsDefault[
            EFPOnboardingTemplateListParams.page
          ]
      )
      .set(
        EFPOnboardingTemplateListParams.sort,
        queryParams[EFPOnboardingTemplateListParams.sort]?.join(':') ||
          CFPOnboardingTemplateListParamsDefault[
            EFPOnboardingTemplateListParams.sort
          ].join(':')
      )
      .set(
        EFPOnboardingTemplateListParams.search,
        queryParams[EFPOnboardingTemplateListParams.search] ||
          CFPOnboardingTemplateListParamsDefault[
            EFPOnboardingTemplateListParams.search
          ]
      );

    return this.genericClient.genericGet<IFpOnboardingTemplateListResponse>(
      endpoint,
      { params }
    );
  }

  createOnboardingTemplate(
    fundingPlanId: string,
    request: IFPOnboardingTemplateCreateRequest
  ): Observable<IFPOnboardingTemplateCreateResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.FPOnboardingTemplate,
      fundingPlanId
    );

    return this.genericClient.genericPost<IFPOnboardingTemplateCreateResponse>(
      endpoint,
      request
    );
  }

  /**
   * Get Funding Plan Change Logs List
   * @returns
   */
  getChangeLogList(
    fundingPlanId: string,
    queryParams: IFPChangeLogParams
  ): Observable<IFPChangeLogListResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.FPChangeLog,
      fundingPlanId
    );

    const params = new HttpParams()
      .set(
        EFPChangeLogListParams.size,
        queryParams[EFPChangeLogListParams.size] ||
          CFPChangeLogListParamsDefault[EFPChangeLogListParams.size]
      )
      .set(
        EFPChangeLogListParams.page,
        queryParams[EFPChangeLogListParams.page] ||
          CFPChangeLogListParamsDefault[EFPChangeLogListParams.page]
      )
      .set(
        EFPChangeLogListParams.sort,
        queryParams[EFPChangeLogListParams.sort]?.join(':') ||
          CFPChangeLogListParamsDefault[EFPChangeLogListParams.sort].join(':')
      )
      .set(
        EFPChangeLogListParams.search,
        queryParams[EFPChangeLogListParams.search] ||
          CFPChangeLogListParamsDefault[EFPChangeLogListParams.search]
      );

    return this.genericClient.genericGet<IFPChangeLogListResponse>(endpoint, {
      params,
    });
  }

  /**
   * Get Funding Plan Enrolled Merchants
   * @returns
   */
  getEnrolledMerchantList(
    fundingPlanId: string,
    queryParams: IFPEnrolledMerchantParams
  ): Observable<IFPEnrolledMerchantListResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.FPEnrolledMerchant,
      fundingPlanId
    );

    const params = new HttpParams()
      .set(
        EFPEnrolledMerchantListParams.size,
        queryParams[EFPEnrolledMerchantListParams.size] ||
          CFPEnrolledMerchantListParamsDefault[
            EFPEnrolledMerchantListParams.size
          ]
      )
      .set(
        EFPEnrolledMerchantListParams.page,
        queryParams[EFPEnrolledMerchantListParams.page] ||
          CFPEnrolledMerchantListParamsDefault[
            EFPEnrolledMerchantListParams.page
          ]
      )
      .set(
        EFPEnrolledMerchantListParams.sort,
        queryParams[EFPEnrolledMerchantListParams.sort]?.join(':') ||
          CFPEnrolledMerchantListParamsDefault[
            EFPEnrolledMerchantListParams.sort
          ].join(':')
      )
      .set(
        EFPEnrolledMerchantListParams.search,
        queryParams[EFPEnrolledMerchantListParams.search] ||
          CFPEnrolledMerchantListParamsDefault[
            EFPEnrolledMerchantListParams.search
          ]
      );

    return this.genericClient.genericGet<IFPEnrolledMerchantListResponse>(
      endpoint,
      { params }
    );
  }

  getAllowedMerchants(
    fundingPlanId: string,
    queryParams: IFPAllowedMerchantsParams
  ): Observable<IFPAllowedMerchantsResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.FPAllowedMerchants,
      fundingPlanId
    );
    const params = new HttpParams()
      .set(
        EFPAllowedMerchantsParams.size,
        queryParams[EFPAllowedMerchantsParams.size] ||
          CFPAllowedMerchantsParamsDefault[EFPAllowedMerchantsParams.size]
      )
      .set(
        EFPAllowedMerchantsParams.page,
        queryParams[EFPAllowedMerchantsParams.page] ||
          CFPAllowedMerchantsParamsDefault[EFPAllowedMerchantsParams.page]
      )
      .set(
        EFPAllowedMerchantsParams.sort,
        queryParams[EFPAllowedMerchantsParams.sort]?.join(':') ||
          CFPAllowedMerchantsParamsDefault[EFPAllowedMerchantsParams.sort].join(
            ':'
          )
      );
    return this.genericClient.genericGet<IFPAllowedMerchantsResponse>(
      endpoint,
      { params }
    );
  }

  getAllowedOnboardingTemplates(
    fundingPlanId: string,
    queryParams: IFPAllowedOnboardingTemplatesParams
  ): Observable<IFPAllowedOnboardingTemplatesResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.FPAllowedOnboardingTemplates,
      fundingPlanId
    );
    const params = new HttpParams()
      .set(
        EFPAllowedOnboardingTemplatesParams.size,
        queryParams[EFPAllowedOnboardingTemplatesParams.size] ||
          CFPAllowedOnboardingTemplatesParamsDefault[
            EFPAllowedOnboardingTemplatesParams.size
          ]
      )
      .set(
        EFPAllowedOnboardingTemplatesParams.page,
        queryParams[EFPAllowedOnboardingTemplatesParams.page] ||
          CFPAllowedOnboardingTemplatesParamsDefault[
            EFPAllowedOnboardingTemplatesParams.page
          ]
      )
      .set(
        EFPAllowedOnboardingTemplatesParams.sort,
        queryParams[EFPAllowedOnboardingTemplatesParams.sort]?.join(':') ||
          CFPAllowedOnboardingTemplatesParamsDefault[
            EFPAllowedOnboardingTemplatesParams.sort
          ].join(':')
      );
    return this.genericClient.genericGet<IFPAllowedOnboardingTemplatesResponse>(
      endpoint,
      { params }
    );
  }

  /**
   * Create Enrolled Merchant for Funding Plan
   * @param fundingPlanId
   * @param request
   * @returns
   */
  createEnrolledMerchant(
    fundingPlanId: string,
    request: IFPCreateEnrolledMerchantRequest
  ): Observable<IFPCreateEnrolledMerchantResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.FPEnrolledMerchant,
      fundingPlanId
    );

    return this.genericClient.genericPost<IFPCreateEnrolledMerchantResponse>(
      endpoint,
      request
    );
  }

  /**
   * Delete Enrolled Merchant for Funding Plan
   * @param fundingPlanId
   * @param request
   * @returns
   */
  deleteEnrolledMerchant(
    fundingPlanId: string,
    request: IFPDeleteEnrolledMerchantRequest
  ): Observable<IFPDeleteEnrolledMerchantResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.FPDeleteEnrolledMerchant,
      fundingPlanId
    );

    return this.genericClient.genericDelete<IFPDeleteEnrolledMerchantResponse>(
      endpoint,
      { ['body' as any]: request as any }
    );
  }
}
