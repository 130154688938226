import { IUserPartnerDomainsParams } from '@interfaces/ops-and-uw-tools/users/user-partner-domains.interface';

export const enum EUserListParams {
  size = 'limit',
  page = 'page',
  sort = 'order_by',
  search = 'search',
  name = 'fullName',
  email = 'email',
  status = 'status',
}

export const enum EUserListSort {
  createdDateAsc = 'created_date:asc',
  createdDateDesc = 'created_date:desc',
}

export const enum EUserPartnerEligibleDomains {
  page = 'page',
  limit = 'limit',
  search = 'search',
}

export const CUserPartnerEligibleDomains: IUserPartnerDomainsParams = {
  [EUserPartnerEligibleDomains.page]: 1,
  [EUserPartnerEligibleDomains.limit]: 10,
  [EUserPartnerEligibleDomains.search]: '',
};
