import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[gtr-stats-container]',
})
export class GtrStatsContainerDirective {
  /**
   * container styles
   */

  private styles = {
    'list-style': 'none',
    display: 'inline-block',
    'padding-right': '15px',
    width: '25%',
    'min-width': '171px',
    'z-index': 0,
    //...and so on
  };

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // Rewrite media query css by calling addStyles Method based on window.innerWidth condition
    this.addStyles();
  }

  // Private
  private _nativeElement: any;
  /**
   * Constructor
   *
   * @param {ElementRef} _elementRef
   */
  constructor(private _elementRef: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    this.addStyles();
  }

  addStyles(): void {
    if (window.innerWidth < 768) {
      this.styles['min-width'] = '250px';
    } else {
      this.styles['min-width'] = '171px';
    }

    // Get the native element
    this._nativeElement = this._elementRef.nativeElement;
    Object.keys(this.styles).forEach((element) => {
      this.renderer.setStyle(
        this._nativeElement,
        `${element}`,
        this.styles[element]
      );
    });
  }
}
