import { Component, Input } from '@angular/core';
import { MENU_ITEM_DATA } from '../sidebar.interface';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
})
export class MenuItemComponent {
  /* Input property in which user will pass input data i.e. icon, name and path */
  @Input() public dataSet?: MENU_ITEM_DATA;

  constructor() {}

  ngOnChanges(changes: any) {
    // this.dataSet = changes.dataSet;
  }
}
