<ng-container *ngIf="dataSet && dataSet.show && !dataSet.hide; else customMenuContent">
  <ng-container *ngIf="dataSet && dataSet.isLabel">
    <ng-container *ngTemplateOutlet="menuLabel"></ng-container>
  </ng-container>

  <ng-container *ngIf="dataSet && !dataSet.isLabel">
    <ng-container *ngTemplateOutlet="menuLink"></ng-container>
  </ng-container>
</ng-container>

<!--  Template for custom content passed in menu  -->
<ng-template #customMenuContent>
  <li [tabindex]="0">
    <ng-content></ng-content>
  </li>
</ng-template>

<!--  Template for menu label  -->
<ng-template #menuLabel>
  <li
    class="menu-item-label"
    [aria-label]="dataSet.name"
    [attr.aria-describedby]="dataSet.name"
    #menuLink
  >
    {{ dataSet.name }}
  </li>
</ng-template>

<!--  Template for menu link  -->
<ng-template #menuLink>
  <li
    class="menu-item-container"
    [tabindex]="dataSet.path ? 0 : -1"
    [attr.aria-describedby]="dataSet.name"
    [attr.aria-disabled]="dataSet.disabled ? 'true' : 'false'"
    [attr.data-transaction-name]="'menu-item-'+(dataSet.path || 'unknown')"
    [ngClass]="{ disabled: dataSet.disabled }"
    [routerLink]="[dataSet.path]"
    routerLinkActive="active"
    #menuLinkContent
    [class.active]="menuLinkContent.setAttribute('expand', true)"
  >
    <span class="menu-wrapper">
      <ng-container *ngIf="dataSet.icon">
        <span class="menu-icon-wrapper">
          <img
            src="assets/images/menu/{{ dataSet.icon }}.svg"
            alt="dataset-icon"
            class="menu-icon"
          />
        </span>
      </ng-container>
      <span class="menu-label">{{ dataSet.name }}</span>
    </span>
  </li>
</ng-template>
