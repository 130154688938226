import { IResponseBase } from '@interfaces/generic/responses.interface';
import {
  CDashboardChartParamsDate,
  CDashboardChartParamsGroupBy,
  EDashboardChartGroupBy,
  EDashboardChartParams,
} from '@parameters/dashboard/dashboard-chart.parameter';
import { ECardBrand } from '@parameters/filter/card-brand.parameter';
import { EVolumeAs } from '@parameters/filter/volume-as.parameter';

export interface IDashboardChartParams {
  [EDashboardChartParams.startDate]: string;
  [EDashboardChartParams.endDate]: string;
  [EDashboardChartParams.midList]: number[];
  [EDashboardChartParams.binList]: number[];
  [EDashboardChartParams.cardBrandList]: ECardBrand[];
  [EDashboardChartParams.relationshipList]: string[];
  [EDashboardChartParams.groupBy]: EDashboardChartGroupBy;
  [EDashboardChartParams.volumeAs]?: EVolumeAs[];
}

export interface IDashboardChartSeriesResponse {
  dt: string;
  label: Date | number;
  val: number;
  priorVal: number;
  percentChange: number;
}

export interface IDashboardChartAggregateResponse {
  total: number;
  priorPeriodTotal: number;
  percentChange: number;
}

export interface IDashboardChartDataResponse {
  groupedBy: EDashboardChartGroupBy;
  aggregate: IDashboardChartAggregateResponse;
  series: IDashboardChartSeriesResponse[];
}

export interface IDashboardChartResponse
  extends IResponseBase<IDashboardChartDataResponse> {}

export const CDashboardChartParamsDefault: IDashboardChartParams = {
  [EDashboardChartParams.startDate]: CDashboardChartParamsDate.value.startDate,
  [EDashboardChartParams.endDate]: CDashboardChartParamsDate.value.endDate,
  [EDashboardChartParams.midList]: [],
  [EDashboardChartParams.binList]: [],
  [EDashboardChartParams.cardBrandList]: [],
  [EDashboardChartParams.relationshipList]: [],
  [EDashboardChartParams.groupBy]: CDashboardChartParamsGroupBy,
  [EDashboardChartParams.volumeAs]: [EVolumeAs.calendar],
};
