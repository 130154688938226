import {
  EEntityMerchantListParams,
  EEntityMerchantListSort,
} from '@parameters/entity/merchant/entity-merchant-list.parameter';
import { EEntityRelationshipType } from '@parameters/entity/relationship/entity-relationship.parameter';
import {
  IResponseBase,
  IResponseData,
} from '@interfaces/generic/responses.interface';
import { IEntityMerchant } from '@interfaces/entity/merchant/entity-merchant.interface';

export interface IEntityMerchantListResponse
  extends IResponseBase<IResponseData<IEntityMerchant>> {}

export interface IEntityMerchantListParams {
  [EEntityMerchantListParams.currentRelationship]?: boolean;
  [EEntityMerchantListParams.relationshipTypes]: EEntityRelationshipType[];
  [EEntityMerchantListParams.sort]: EEntityMerchantListSort;
  [EEntityMerchantListParams.page]: number;
  [EEntityMerchantListParams.size]: number;
  [EEntityMerchantListParams.search]: string;
}

export const CEntityMerchantListParamsDefault: IEntityMerchantListParams = {
  [EEntityMerchantListParams.currentRelationship]: true,
  [EEntityMerchantListParams.relationshipTypes]: [
    EEntityRelationshipType.referralSource,
    EEntityRelationshipType.agentSource,
    EEntityRelationshipType.agent,
    EEntityRelationshipType.referral,
    EEntityRelationshipType.salesManager,
  ],
  [EEntityMerchantListParams.sort]: EEntityMerchantListSort.createdAtDesc,
  [EEntityMerchantListParams.page]: 1,
  [EEntityMerchantListParams.size]: 10,
  [EEntityMerchantListParams.search]: '',
};
