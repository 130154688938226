import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cipherText',
})
export class CipherTextPipe implements PipeTransform {
  transform(
    value: string,
    sign: string,
    sliceNumber: number,
    returnSymbol: string
  ): any {
    if (!value) return returnSymbol;
    return sign + value.substring(value.length - sliceNumber);
  }
}
