import { FormControl, FormGroup } from '@angular/forms';

//OCE: Onboarding Customization Edit

export enum EOCEOtherQuestionsFormControlName {
  publicTrade = 'publicTrade',
  bankrupt = 'bankrupt',
  deliveryTime = 'deliveryTime',
  cardHolder = 'cardHolder',
  PCICompliant = 'PCICompliant',
  dataBreach = 'dataBreach',
}

export enum EOCEOtherQuestionsOptionFormControlName {
  active = 'active',
  hidden = 'hidden',
  custom = 'custom',
}

export interface IOCEOtherQuestionsFormValue {
  [EOCEOtherQuestionsFormControlName.publicTrade]: IOCEOtherQuestionsOptionFormValue;
  [EOCEOtherQuestionsFormControlName.bankrupt]: IOCEOtherQuestionsOptionFormValue;
  [EOCEOtherQuestionsFormControlName.deliveryTime]: IOCEOtherQuestionsOptionFormValue;
  [EOCEOtherQuestionsFormControlName.cardHolder]: IOCEOtherQuestionsOptionFormValue;
  [EOCEOtherQuestionsFormControlName.PCICompliant]: IOCEOtherQuestionsOptionFormValue;
  [EOCEOtherQuestionsFormControlName.dataBreach]: IOCEOtherQuestionsOptionFormValue;
}

export interface IOCEOtherQuestionsOptionFormValue {
  [EOCEOtherQuestionsOptionFormControlName.active]: boolean;
  [EOCEOtherQuestionsOptionFormControlName.hidden]: boolean;
  [EOCEOtherQuestionsOptionFormControlName.custom]?: string;
}

export interface IOCEOtherQuestionsForm {
  [EOCEOtherQuestionsFormControlName.publicTrade]: FormGroup<IOCEOtherQuestionsOptionForm>;
  [EOCEOtherQuestionsFormControlName.bankrupt]: FormGroup<IOCEOtherQuestionsOptionForm>;
  [EOCEOtherQuestionsFormControlName.deliveryTime]: FormGroup<IOCEOtherQuestionsOptionForm>;
  [EOCEOtherQuestionsFormControlName.cardHolder]: FormGroup<IOCEOtherQuestionsOptionForm>;
  [EOCEOtherQuestionsFormControlName.PCICompliant]: FormGroup<IOCEOtherQuestionsOptionForm>;
  [EOCEOtherQuestionsFormControlName.dataBreach]: FormGroup<IOCEOtherQuestionsOptionForm>;
}

export interface IOCEOtherQuestionsOptionForm {
  [EOCEOtherQuestionsOptionFormControlName.active]: FormControl<
    IOCEOtherQuestionsOptionFormValue[EOCEOtherQuestionsOptionFormControlName.active]
  >;
  [EOCEOtherQuestionsOptionFormControlName.hidden]: FormControl<
    IOCEOtherQuestionsOptionFormValue[EOCEOtherQuestionsOptionFormControlName.hidden]
  >;
  [EOCEOtherQuestionsOptionFormControlName.custom]?: FormControl<
    IOCEOtherQuestionsOptionFormValue[EOCEOtherQuestionsOptionFormControlName.custom]
  >;
}

export const COCEOtherQuestionsOptionFormDefault: IOCEOtherQuestionsOptionFormValue =
  {
    [EOCEOtherQuestionsOptionFormControlName.active]: false,
    [EOCEOtherQuestionsOptionFormControlName.hidden]: false,
    [EOCEOtherQuestionsOptionFormControlName.custom]: null,
  };
