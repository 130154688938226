import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavbarEventbusService {
  isBlockSearchOpened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public isBlockSearchOpened$: Observable<boolean> =
    this.isBlockSearchOpened.asObservable();
  isNavNotificationMenuVisible: boolean = false;
  isNavProfileMenuVisible: boolean = false;
}
