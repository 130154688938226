import { IResponseBase } from '@interfaces/generic/responses.interface';
import {
  CMetricsKpisProspectParamsDate,
  EMetricsKpisProspectParamsKpi,
  EMetricsKpisProspectParams,
} from '@parameters/metrics/metrics-kpis-prospect.parameter';
import { IMetricsKpisValue } from '@interfaces/metrics/metrics-kpis.interface';

export interface IMetricsKpisProspectParams {
  [EMetricsKpisProspectParams.startDate]: number;
  [EMetricsKpisProspectParams.endDate]: number;
  [EMetricsKpisProspectParams.midList]: number[];
  [EMetricsKpisProspectParams.binList]: number[];
  [EMetricsKpisProspectParams.kpiList]: EMetricsKpisProspectParamsKpi[];
}

export interface IMetricsKpisProspect {
  totalProspects: IMetricsKpisValue;
  newProspects: IMetricsKpisValue;
  approvedProspects: IMetricsKpisValue;
  declinedProspects: IMetricsKpisValue;
}

export interface IMetricsKpisProspectResponse
  extends IResponseBase<IMetricsKpisProspect> {}

export const CMetricsKpisProspectParamsDefault: IMetricsKpisProspectParams = {
  [EMetricsKpisProspectParams.startDate]:
    CMetricsKpisProspectParamsDate.unixValue.startDate,
  [EMetricsKpisProspectParams.endDate]:
    CMetricsKpisProspectParamsDate.unixValue.endDate,
  [EMetricsKpisProspectParams.midList]: [],
  [EMetricsKpisProspectParams.binList]: [],
  [EMetricsKpisProspectParams.kpiList]: [
    EMetricsKpisProspectParamsKpi.total,
    EMetricsKpisProspectParamsKpi.new,
    EMetricsKpisProspectParamsKpi.approved,
    EMetricsKpisProspectParamsKpi.declined,
  ],
};
