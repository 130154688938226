import { IResponseBase } from '@interfaces/generic/responses.interface';
import { IMetricsKpisValue } from '@interfaces/metrics/metrics-kpis.interface';
import {
  CMetricsKpisDealParamsDate,
  EMetricsKpisDealParams,
  EMetricsKpisDealParamsKpi,
} from '@parameters/metrics/metrics-kpis-deal.parameter';

export interface IMetricsKPIsDealParams {
  [EMetricsKpisDealParams.startDate]: number;
  [EMetricsKpisDealParams.endDate]: number;
  [EMetricsKpisDealParams.relationshipList]: string[];
  [EMetricsKpisDealParams.kpiList]: EMetricsKpisDealParamsKpi[];
}

export interface IMetricsKPIsDeal {
  totalDeals: IMetricsKpisValue;
  newDeals: IMetricsKpisValue;
  approvedDeals: IMetricsKpisValue;
  declinedDeals: IMetricsKpisValue;
}

export interface IMetricsKPIsDealResponse
  extends IResponseBase<IMetricsKPIsDeal> {}

export const CMetricsKPIsDealParamsDefault: IMetricsKPIsDealParams = {
  [EMetricsKpisDealParams.startDate]:
    CMetricsKpisDealParamsDate.unixValue.startDate,
  [EMetricsKpisDealParams.endDate]:
    CMetricsKpisDealParamsDate.unixValue.endDate,
  [EMetricsKpisDealParams.relationshipList]: [],
  [EMetricsKpisDealParams.kpiList]: [
    EMetricsKpisDealParamsKpi.total,
    EMetricsKpisDealParamsKpi.new,
    EMetricsKpisDealParamsKpi.declined,
    EMetricsKpisDealParamsKpi.approved,
  ],
};
