import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';

dayjs.extend(timezone);
dayjs.extend(utc);

export class DatesHelper {
  public static todayEndOfDayLocal() {
    return dayjs().local().endOf('day');
  }

  public static todayEndOfDayUTC() {
    return dayjs().endOf('day').utc();
  }

  public static todayEndOfDayUnixTimestamp() {
    return this.todayEndOfDayUTC().unix();
  }

  public static startOfDayDaysAgoLocal(daysAgo: number) {
    return dayjs().local().subtract(daysAgo, 'day').startOf('day');
  }

  public static startOfDayDaysAgoUTC(daysAgo: number) {
    return dayjs().subtract(daysAgo, 'day').startOf('day').utc();
  }

  public static startOfDayDaysAgoUnixTimestamp(daysAgo: number) {
    return this.startOfDayDaysAgoUTC(daysAgo).unix();
  }

  public static startOfDayMonthsAgoUTC(monthsAgo: number) {
    return dayjs().subtract(monthsAgo, 'month').startOf('day').utc();
  }

  public static startOfDayMonthsAgoUnixTimestamp(monthsAgo: number) {
    return this.startOfDayMonthsAgoUTC(monthsAgo).unix();
  }

  public static startOfDayYearsAgoUTC(yearsAgo: number) {
    return dayjs().subtract(yearsAgo, 'year').startOf('day').utc();
  }

  public static startOfDayFirstDayOfCurrentMonthLocal() {
    return dayjs().local().startOf('month').startOf('day');
  }

  public static startOfDayFirstDayOfCurrentMonthUTC() {
    return dayjs().startOf('month').startOf('day').utc();
  }

  public static startOfDayFirstDayOfCurrentMonthUnixTimestamp() {
    return this.startOfDayFirstDayOfCurrentMonthUTC().unix();
  }

  public static endOfDayLastDayOfCurrentMonthLocal() {
    return dayjs().local().endOf('month').endOf('day');
  }

  public static endOfDayLastDayOfCurrentMonthUTC() {
    return dayjs().endOf('month').endOf('day').utc();
  }

  public static endOfDayLastDayOfCurrentMonthUnixTimestamp() {
    return this.endOfDayLastDayOfCurrentMonthUTC().unix();
  }

  public static startOfDayFirstDayOfLastMonthLocal() {
    return dayjs().local().subtract(1, 'month').startOf('month').startOf('day');
  }

  public static startOfDayFirstDayOfLastMonthUTC() {
    return dayjs().subtract(1, 'month').startOf('month').startOf('day').utc();
  }

  public static startOfDayFirstDayOfLastMonthUnixTimestamp() {
    return this.startOfDayFirstDayOfLastMonthUTC().unix();
  }

  public static endOfDayLastDayOfLastMonthLocal() {
    return dayjs().local().subtract(1, 'month').endOf('month').endOf('day');
  }

  public static endOfDayLastDayOfLastMonthUTC() {
    return dayjs().subtract(1, 'month').endOf('month').endOf('day').utc();
  }

  public static endOfDayLastDayOfLastMonthUnixTimestamp() {
    return this.endOfDayLastDayOfLastMonthUTC().unix();
  }

  public static startOfDayYearsAgoUnixTimestamp(yearsAgo: number) {
    return this.startOfDayYearsAgoUTC(yearsAgo).unix();
  }

  public static customStartOfDayLocal(date: Date) {
    return dayjs(date).local().startOf('day');
  }

  public static customStartOfDayUTC(date: Date) {
    return dayjs(date).startOf('day').utc();
  }

  public static customStartOfDayUnixTimestamp(date: Date) {
    return this.customStartOfDayUTC(date).unix();
  }

  public static customEndOfDayLocal(date: Date) {
    return dayjs(date).local().endOf('day');
  }

  public static customEndOfDayUTC(date: Date) {
    return dayjs(date).endOf('day').utc();
  }

  public static customEndOfDayUnixTimestamp(date: Date) {
    return this.customEndOfDayUTC(date).unix();
  }

  public static customStringToStartOfDayTimezone(
    date: string,
    timezone: string = 'America/New_York'
  ) {
    return dayjs(date).startOf('day').tz(timezone, true);
  }

  public static customStringToStartOfDayTimezoneUnixTimestamp(
    date: string,
    timezone: string = 'America/New_York'
  ) {
    return this.customStringToStartOfDayTimezone(date, timezone).unix();
  }

  public static customStringToEndOfDayTimezone(
    date: string,
    timezone: string = 'America/New_York'
  ) {
    return dayjs(date).endOf('day').tz(timezone, true);
  }

  public static customStringToEndOfDayTimezoneUnixTimestamp(
    date: string,
    timezone: string = 'America/New_York'
  ) {
    return this.customStringToEndOfDayTimezone(date, timezone).unix();
  }
}
