import {
  Directive,
  ElementRef,
  HostListener,
  Optional,
  Self,
} from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appNumericOnly]',
})
export class NumericOnlyDirective {
  constructor(
    private el: ElementRef,
    @Optional() @Self() public ngControl: NgControl
  ) {}

  @HostListener('ngModelChange', ['$event'])
  public onInputChange(value: any) {
    const input = this.el.nativeElement as HTMLInputElement;
    const inputValue =
      typeof value !== 'string' ? String(value)?.trim() : value?.trim();
    // Split the value into integer and decimal parts
    const parts = inputValue?.split('.');
    // Reconstruct the value allowing only positive numbers with one decimal and two decimal digits
    let formattedValue = (parts && parts[0]) || '';
    if (parts?.length > 1) {
      const decimalPart = parts[1]?.substring(0, parts[1]?.length);
      formattedValue += '.' + decimalPart;
      input.value = formattedValue;
      this.ngControl?.valueAccessor?.writeValue(formattedValue);
    }
  }

  @HostListener('keydown', ['$event'])
  public onKeyDown(event: KeyboardEvent) {
    const keyCode = event.keyCode || event.which;
    // NOTE: 37, 38, 39, 40 allow arrow keys
    const allowedKeys = [8, 9, 13, 27, 46, 110, 190, 37, 38, 39, 40];

    if (
      (keyCode < 48 || keyCode > 57) &&
      (keyCode < 96 || keyCode > 105) &&
      !allowedKeys.includes(keyCode)
    ) {
      event.preventDefault();
    }
  }
}
