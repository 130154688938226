import { environment } from '@environments/environment';

export class ProxyGateway {
  public static loadBaseApiUrl(): string {
    try {
      const hostname = window.location.hostname;
      const domain: string = hostname?.match(/[^.]+\.[^.]+$/)?.[0];
      const excludedHost: string[] = ['localhost'];
      if (!excludedHost.includes(hostname)) {
        environment.apiBaseUrl = `${environment.webApiBaseUrl}.${domain}/`;
        environment.paymentApiBaseUrl = `${environment.webApiBaseUrl}.${domain}/internal/payments/`;
        environment.iamApiBaseUrl = `${environment.webApiBaseUrl}.${domain}/internal/iam/`;
      }
      return environment.apiBaseUrl;
    } catch (err) {
      return environment.apiBaseUrl;
    }
  }

  public static loadSdkScriptUrl(): string {
    try {
      const hostname = window.location.hostname;
      const domain: string = hostname?.match(/[^.]+\.[^.]+$/)?.[0];
      const excludedHost: string[] = ['localhost'];
      if (!excludedHost.includes(hostname)) {
        environment.sdkScriptUrl = `${environment.sdkBaseSubdomain}.${domain}/sdk/js/payments/v1.0/sdk.js`;
      }
      return environment.sdkScriptUrl;
    } catch (err) {
      return environment.sdkScriptUrl;
    }
  }
}
