import { IResponseBase } from '@interfaces/generic/responses.interface';
import {
  CMetricsKpisMerchantParamsDate,
  EMetricsKpisMerchantParams,
  EMetricsKpisMerchantParamsKpi,
} from '@parameters/metrics/metrics-kpis-merchant.parameter';
import { IMetricsKpisValue } from '@interfaces/metrics/metrics-kpis.interface';

export interface IMetricsKpisMerchantParams {
  [EMetricsKpisMerchantParams.startDate]: string;
  [EMetricsKpisMerchantParams.endDate]: string;
  [EMetricsKpisMerchantParams.midList]: number[];
  [EMetricsKpisMerchantParams.binList]: number[];
  [EMetricsKpisMerchantParams.kpiList]: EMetricsKpisMerchantParamsKpi[];
  [EMetricsKpisMerchantParams.relationshipIds]: string[];
}

export interface IMetricsKpisMerchant {
  active_merchants: IMetricsKpisValue;
  new_merchants: IMetricsKpisValue;
  closed_merchants: IMetricsKpisValue;
}

export interface IMetricsKpisMerchantResponse
  extends IResponseBase<IMetricsKpisMerchant> {}

export const CMetricsKpisMerchantParamsDefault: IMetricsKpisMerchantParams = {
  [EMetricsKpisMerchantParams.startDate]:
    CMetricsKpisMerchantParamsDate.value.startDate,
  [EMetricsKpisMerchantParams.endDate]:
    CMetricsKpisMerchantParamsDate.value.endDate,
  [EMetricsKpisMerchantParams.midList]: [],
  [EMetricsKpisMerchantParams.binList]: [],
  [EMetricsKpisMerchantParams.kpiList]: [
    EMetricsKpisMerchantParamsKpi.new,
    EMetricsKpisMerchantParamsKpi.active,
    EMetricsKpisMerchantParamsKpi.closed,
  ],
  [EMetricsKpisMerchantParams.relationshipIds]: [],
};
