import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { constants } from '@helpers/constants';
import { APP_ROUTES, RoutePoints } from '@helpers/routes.enum';
import { IMspStoredMerchantApplicationItem } from '@interfaces/authentication/msp-merchant-application-list.interface';
import { IOnboardingSkipableRoutesData } from '@interfaces/authentication/onboading-skipable-routes-list.interface';
import { CookieService } from 'ngx-cookie-service';
import { AuthSession } from 'src/app/auth/models/auth-session.enum';
import { EGatewayTitles } from 'src/app/business/authentication/auth-onboarding/steps/payment-gateways/payment-gateways.component';
import { EOnlinePaymentMethodShortId } from 'src/app/business/authentication/auth-onboarding/steps/taking-payments/taking-payments.component';

@Injectable({
  providedIn: 'root',
})
export class SkipableRoutesService {
  private currentPosition = 1;
  private _routeKey: string = '';
  isNextRoute: boolean;
  isBackRoute: boolean;
  routeList: RoutePoints[] = [];
  skippedUrls: string[] = [];
  private isDataBreach: boolean;
  private _mappedSkipableRouteList: any[] = [];
  onboardingCustomizationRoutes: IOnboardingSkipableRoutesData[] = [];
  defaultMappedRouteList: RoutePoints[] = [];
  private _baseRoute: string = `/onboarding/${this.selectedMerchantId}/`;
  get baseRoute(): string {
    return this._baseRoute;
  }
  set baseRoute(url: string) {
    this._baseRoute = url;
  }
  constructor(private router: Router, private cookieService: CookieService) {}

  progressInc(routeEndPoint: string) {
    this.nextRoute(routeEndPoint);
  }

  progressDec(routeEndPoint: string) {
    this.previousRoute(routeEndPoint);
  }

  set setOnboardingCustomizationRoutes(
    customizedRoutes: IOnboardingSkipableRoutesData[]
  ) {
    this.onboardingCustomizationRoutes = customizedRoutes;
  }
  get getOnboardingCustomizationRoutes(): IOnboardingSkipableRoutesData[] {
    return this.onboardingCustomizationRoutes;
  }

  set setDefaultMappedRoutes(defaultRoutes: RoutePoints[]) {
    this.defaultMappedRouteList = defaultRoutes;
  }
  get getDefaultMappedRoutes(): RoutePoints[] {
    return this.defaultMappedRouteList;
  }

  set mappedSkipableRouteList(routes: any[]) {
    this._mappedSkipableRouteList = routes;
  }

  get mappedSkipableRouteList() {
    return this._mappedSkipableRouteList;
  }
  set routeKey(key: string) {
    this._routeKey = key;
  }

  get routeKey(): string {
    return this._routeKey;
  }

  get getCurrentPosition(): number {
    return this.currentPosition;
  }

  set setcurrentPosition(position: number) {
    this.currentPosition = position;
  }

  get getskippedUrls(): string[] {
    return this.skippedUrls as string[];
  }

  set setSkippedUrls(urls: string[]) {
    this.skippedUrls = urls;
  }

  get getDataBreach(): boolean {
    return this.isDataBreach as boolean;
  }

  set setDataBreach(databreach: boolean) {
    this.isDataBreach = databreach;
  }

  initRoutes(
    paymentMethods: any,
    onlinePaymentMethods: any,
    logoTitle: string
  ) {
    this.initDefaultRoutes(constants.onboarding_accepts_payment_routes);

    let urls = paymentMethods?.map((x) => x?.url);

    this.setSkippedUrls = this.updateUrls(urls);

    this.getSuitableRoutes(paymentMethods);

    this.initDefaultRoutes(constants.onboarding_taking_payments_online);
    const paymentTitle = onlinePaymentMethods;
    if (!this.getskippedUrls.includes(APP_ROUTES.taking_payments)) {
      this.initDefaultRoutes(constants.onboarding_taking_payments_online);
    } else {
      this.skipRoutesForPaymentsOnline(paymentTitle);
    }
    if (!this.getskippedUrls.includes(APP_ROUTES.payment_gateway)) {
      this.updateRoutes([APP_ROUTES.has_existing_gateway_account], true);
    } else if (logoTitle === EGatewayTitles.notUsingCurrently) {
      this.updateRoutes([APP_ROUTES.has_existing_gateway_account], true);
    } else {
      this.updateRoutes([APP_ROUTES.has_existing_gateway_account], false);
    }
    if (paymentMethods.length <= 0) {
      this.updateRoutes([APP_ROUTES.select_hardware], false);
    }
  }

  shouldSkip(routeKey: string) {
    return this.getKeyValues(this.mappedSkipableRouteList, routeKey).filter(
      (item) => item?.skip
    )?.length
      ? true
      : false;
  }

  shouldDefaultSkip(routeKey: string) {
    return this.getKeyValues(
      this.getOnboardingCustomizationRoutes,
      routeKey
    ).filter((item) => item?.skip)?.length
      ? true
      : false;
  }

  getKeyValues<T, K extends keyof T>(array: T[], key: K): T[K][] {
    return array.map((item) => item[key]);
  }

  isSkipableRoute(key: string): boolean {
    return (
      this.mappedSkipableRouteList.find(
        (item: RoutePoints) => item.stepName === key
      )?.skip || false
    );
  }

  isObjectEmpty = (obj): boolean => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  /**
   * Initialize routes to skip true
   * @param urls for urls to be set skip true by default
   * @param isSkip boolean for skip route
   */
  initDefaultRoutes(urls: string[], isSkip: boolean = true) {
    this.updateRoutes(urls, isSkip);
  }

  // checks
  getSuitableRoutes(paymentMethods: any[]) {
    this.updateRoutes(paymentMethods, true);
    this.skipRoutesForGateways(paymentMethods);
    this.updateRoutes(
      [APP_ROUTES.payment_percentage],
      this.skipPercentageRoute(paymentMethods)
    );
  }

  /**
   * Payment Methods List
   * @param {paymentMethods}
   * @returns
   */
  skipPercentageRoute(paymentMethods: any[]): boolean {
    if (
      paymentMethods === undefined ||
      paymentMethods === null ||
      paymentMethods.length === 0
    )
      return true;
    return paymentMethods && paymentMethods.length === 1 ? true : false;
  }

  /**
   * Payment Methods List
   * @param {paymentMethods}
   * @returns
   */
  skipRoutesForGateways(paymentMethods: any[]) {
    let urls = paymentMethods.map((x) => x.url);
    urls = this.updateUrls(urls);

    this.setSkippedUrls = urls;

    this.updateRoutes(urls, false);
    if (
      urls.includes(APP_ROUTES.payment_gateway) ||
      urls.includes(APP_ROUTES.taking_payments)
    ) {
      this.updateRoutes(
        [APP_ROUTES.has_existing_gateway_account, APP_ROUTES.payment_gateway],
        false
      );
    } else {
      this.updateRoutes(
        [APP_ROUTES.has_existing_gateway_account, APP_ROUTES.payment_gateway],
        true
      );
    }
  }

  /**
   * Payment type
   * @param {paymentType}
   * @returns
   */
  skipRoutesForPaymentsOnline(paymentType: string) {
    this.updateRoutesForShoppingAndEcommerce();
    if (paymentType === EOnlinePaymentMethodShortId.WebsiteShoppingCarts) {
      this.updateRoutes([APP_ROUTES.shoping_cart], false);
      return;
    }
    if (paymentType === EOnlinePaymentMethodShortId.ECommercePlatforms) {
      this.updateRoutes([APP_ROUTES.ecommerce_platform], false);
      return;
    }
    if (paymentType === EOnlinePaymentMethodShortId.CustomIntegrations) {
      this.updateRoutesForShoppingAndEcommerce();
      return;
    }
  }

  /**
   * In-person select hardware routes skip
   * @param skip boolean for skip route
   */
  updateRoutesForInPersonSelectHardware(skip: boolean = true): void {
    this.updateRoutes([APP_ROUTES.select_hardware], skip);
  }
  /**
   * Payment Gateway routes skip
   * @param skip boolean for skip route
   */
  updateRoutesForPaymentGatwayAndExistingGatewayAccount(
    skip: boolean = true
  ): void {
    this.updateRoutes(
      [APP_ROUTES.payment_gateway, APP_ROUTES.has_existing_gateway_account],
      skip
    );

    // if(!this.isPaymentMethodsPageSkippable()){
    //   this.updateRoutes(
    //     [APP_ROUTES.payment_gateway, APP_ROUTES.has_existing_gateway_account],
    //     skip
    //   );
    // }else{
    //   this.updateRoutes(
    //     [APP_ROUTES.payment_gateway],
    //     skip
    //   );
    // }
  }
  /**
   * Online payment routes skip
   * @param skip boolean for skip route
   */
  updateRoutesForShoppingAndEcommerce(skip: boolean = true): void {
    this.updateRoutes(
      [APP_ROUTES.ecommerce_platform, APP_ROUTES.shoping_cart],
      skip
    );
  }

  /**
   * is payment methods page is skipbale
   */

  isPaymentMethodsPageSkippable(): boolean {
    return !this.isSkipableRoute(APP_ROUTES.accept_payment);
  }

  /**
   *
   * @param urls
   * @returns
   */

  updateUrls(urls: string[]): Array<any> {
    const gateway = APP_ROUTES.payment_gateway;
    const updateUrlList = urls.map((url: string) => {
      if (url === APP_ROUTES.virtual_terminal) {
        return gateway;
      }
      return url;
    });
    return updateUrlList;
  }

  updateRoutes(urls: string[], isSkip: boolean = false): Array<any> {
    const updatedList = this.mappedSkipableRouteList.map(
      (route: RoutePoints) => {
        urls.forEach((urlstring: string) => {
          if (urlstring === route.stepName) {
            route.skip = isSkip;
          }
        });
        return route;
      }
    );
    return updatedList;
  }
  get getStoredSelectedMerchantApplicationData(): IMspStoredMerchantApplicationItem {
    try {
      return (
        (this.cookieService.get(AuthSession.selectedMerchantApplication) &&
          (JSON?.parse(
            this.cookieService.get(AuthSession.selectedMerchantApplication)
          ) as unknown as IMspStoredMerchantApplicationItem)) ||
        ({} as IMspStoredMerchantApplicationItem)
      );
    } catch (err) {}
  }

  get selectedMerchantId(): string {
    return (
      this.getStoredSelectedMerchantApplicationData
        .selectedApplicationMerchantId || ''
    );
  }

  nextRoute(routeEndPoint: string, url?: string[]) {
    this.baseRoute = `/onboarding/${this.selectedMerchantId}/`;
    try {
      if (this.getskippedUrls && this.getskippedUrls.length > 0) {
        this.mappedSkipableRouteList = [
          ...this.updateRoutes(this.getskippedUrls),
        ];
      }

      const currentPosition = this.getCurrentPosition;
      this.setcurrentPosition = this.getNextEnabledIndex(currentPosition);
      const skip =
        this.mappedSkipableRouteList[this.getNextEnabledIndex(currentPosition)]
          ?.skip;
      if (!skip) {
        const nextSkippableRoute =
          this.mappedSkipableRouteList[
            this.getNextEnabledIndex(currentPosition)
          ].stepName;
        this.router.navigate([this.baseRoute + nextSkippableRoute]);
      } else {
        this.router.navigateByUrl(this.baseRoute + routeEndPoint);
      }
    } catch (error) {
      console.log('Onboarding customization data not found');
    }
  }

  previousRoute(routeEndPoint: string, url?: string[]) {
    this.baseRoute = `/onboarding/${this.selectedMerchantId}/`;
    try {
      if (this.getskippedUrls && this.getskippedUrls.length > 0) {
        this.mappedSkipableRouteList = [
          ...this.updateRoutes(this.getskippedUrls),
        ];
      }
      const currentPosition = this.getCurrentPosition;
      this.setcurrentPosition = this.getPreviousEnabledIndex(currentPosition);
      const skip = this.mappedSkipableRouteList[this.getCurrentPosition].skip;
      if (!skip) {
        const previousSkippableRoute =
          this.mappedSkipableRouteList[this.getCurrentPosition].stepName;
        this.router.navigate([this.baseRoute + previousSkippableRoute]);
      } else {
        this.router.navigate([this.baseRoute + routeEndPoint]);
      }
    } catch (error) {
      console.log('Onboarding customization data not found');
    }
  }

  /**
   *
   * @param currentIndex is the current path location
   * @returns next location index for redirection
   */
  getPreviousEnabledIndex(currentIndex: number): number {
    let previousIndex = currentIndex - 1;
    while (previousIndex >= 0) {
      if (!this.mappedSkipableRouteList[previousIndex].skip) {
        return previousIndex;
      }
      previousIndex--;
    }
    return currentIndex;
  }

  /**
   *
   * @param currentIndex is the current path location
   * @returns previous location index for redirection
   */
  getNextEnabledIndex(currentIndex: number): number {
    let nextIndex = currentIndex + 1;
    while (nextIndex < this.mappedSkipableRouteList.length) {
      if (!this.mappedSkipableRouteList[nextIndex].skip) {
        return nextIndex;
      }
      nextIndex++;
    }
    return currentIndex;
  }
}
