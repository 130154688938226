import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericClientService } from '@clients/generic/generic-client.service';
import {
  IDefaultOtherStandardFeesResponse,
  IPricingMinimumValuesResponse,
} from '@interfaces/offer-pricing/offer-pricing.interface';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import { EOfferPricingType } from '@parameters/offer-pricing/offer-pricing.parameter';
import { Observable } from 'rxjs';

@Injectable()
export class OfferPricingClientService {
  constructor(private readonly genericClient: GenericClientService) {}

  public getDefaultStandardFees(
    offerType: EOfferPricingType
  ): Observable<IDefaultOtherStandardFeesResponse> {
    let params = new HttpParams();
    if (offerType === EOfferPricingType.CASH_DISCOUNT) {
      params = params.append('pricingProgramType', offerType);
    }
    const endpoint = EndpointsParameter.CoreStandardFees;
    return this.genericClient.genericGet<IDefaultOtherStandardFeesResponse>(
      endpoint,
      { params: params }
    );
  }

  public getMinimumValues(
    offerType: EOfferPricingType
  ): Observable<IPricingMinimumValuesResponse> {
    let params = new HttpParams();
    if (offerType === EOfferPricingType.CASH_DISCOUNT) {
      params = params.append('pricingProgramType', offerType);
    }
    const endpoint = EndpointsParameter.CorePricingMinimumValues;
    return this.genericClient.genericGet<IPricingMinimumValuesResponse>(
      endpoint,
      { params: params }
    );
  }
}
