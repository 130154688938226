import {
  IPartnerAccountListResponse,
  IPartnerGatewayAccount,
} from '@interfaces/developers/partner-account-select.interface';
import { ResponsePagination } from '@models/generic/responses.model';

export class PartnerSelectAccounts {
  public id: string;
  public name: string;

  constructor(data: IPartnerGatewayAccount) {
    this.id = data?.id;
    this.name = data?.name;
  }
}

export class PartnerSelectAccountsListResponse {
  list: PartnerSelectAccounts[];
  pagination: ResponsePagination;

  constructor(response: IPartnerAccountListResponse) {
    this.pagination = new ResponsePagination(response?.data?.summary);
    this.list = response?.data?.rows?.length
      ? response?.data?.rows?.map(
          (account) => new PartnerSelectAccounts(account)
        )
      : [];
  }
}
