import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'whiteSpaceSeparator',
})
export class WhiteSpaceSeparator implements PipeTransform {
  transform(value: string): any {
    if (value) {
      const stringArray = [];
      value
        .split(',')
        .map((string) => stringArray.push(`<div>${string}</div>`));
      return stringArray.join(' ');
    }

    return value;
  }
}
