import { Injectable } from '@angular/core';
import { GlobalToastrService } from './toastr.service';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  constructor(private toast: GlobalToastrService) {}

  // Toaster service public methods
  public success(message: string) {
    return this.toast.toastrSuccess(message);
  }
  public warning(message: string, title?) {
    return this.toast.toastrWarning(message, title);
  }
  public info(message: string, title?) {
    return this.toast.toastrInfo(message, title);
  }
  public error(message: string) {
    return this.toast.toastrError(message);
  }
}
