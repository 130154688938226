import {
  AfterViewInit,
  Component,
  ContentChild,
  OnDestroy,
} from '@angular/core';
import { SidenavComponent } from '../sidenav/sidenav.component';
import { SidebarContentComponent } from '../sidebar-content/sidebar-content.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sidebar-container',
  templateUrl: './sidebar-container.component.html',
  styleUrls: ['./sidebar-container.component.scss'],
})
export class SidebarContainerComponent implements AfterViewInit, OnDestroy {
  /* Content projection to get Sidenav component passed in sidebar container */
  @ContentChild(SidenavComponent, { static: true })
  protected sidenav: SidenavComponent;

  /* Content projection to get SidebarContent component passed in sidebar container */
  @ContentChild(SidebarContentComponent, { static: true })
  protected sidebarContent: SidebarContentComponent;

  /* private method to handle subsciptions */
  private subscription: Subscription = new Subscription();

  constructor() {}

  ngAfterViewInit(): void {
    //Subscription for subcribing sidenav opened states
    this.sidenav
      ? this.subscription.add(
          this.sidenav.toggleSidenav.subscribe((opened) => {
            this.sidebarContent.setCollapsed = opened ? false : true;
          })
        )
      : '';

    //Subscription for subcribing sidenav mode states
    this.sidebarContent
      ? this.subscription.add(
          this.sidenav.toggleMode.subscribe((mode) => {
            this.sidebarContent.setMode = mode;
          })
        )
      : '';
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
