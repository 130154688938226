import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericClientService } from '@clients/generic/generic-client.service';
import { GenericHelper } from '@helpers/generic.helper';
import { IApplicationDetail } from '@interfaces/application-details/application-details.interface';
import { IResponseBase } from '@interfaces/generic/responses.interface';
import {
  IAddOfferPricing,
  IAddOfferPricingResponse,
  IOfferPricingResponse,
} from '@interfaces/offer-pricing/offer-pricing.interface';
import {
  IProspectAttachExistingPricing,
  IProspectDetailResponse,
  IProspectSendOffer,
  IProspectSubmitDeal,
} from '@interfaces/prospects/prospect-detail.interface';
import { ProxyGateway } from '@parameters/http/api-proxy-gateway';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import { Observable } from 'rxjs';

@Injectable()
export class ProspectDetailClientService {
  private readonly _apiBase = `${ProxyGateway.loadBaseApiUrl()}v1/prospects`;

  /**
   * Contructor
   * @param genericClient
   */
  constructor(private readonly genericClient: GenericClientService) {}

  /**
   * Get Prospects Detail
   * @param filterId
   * @param params
   * @returns
   */
  public getProspectsDetail(
    prospectId: string,
    params?: string[]
  ): Observable<IProspectDetailResponse> {
    const endpoint = `${this._apiBase}/${prospectId}`;
    const param = new HttpParams().set(
      'filter',
      params.length ? params.join(',') : ''
    );
    return this.genericClient.genericGet<IProspectDetailResponse>(endpoint, {
      params: param,
    });
  }

  /**
   * Update Prospects Detail
   */
  public updateProspectsDetail(
    prospectId: string,
    data: IApplicationDetail
  ): Observable<IResponseBase<{ merchantId: string }>> {
    const endpoint = `${this._apiBase}/${prospectId}`;
    return this.genericClient.genericPatch<
      IResponseBase<{ merchantId: string }>
    >(endpoint, data);
  }

  /**
   * Get Prospect Pricing Plan Data
   * @param prospectId
   * @returns
   */
  public getPricingPlanData(
    prospectId: string
  ): Observable<IOfferPricingResponse> {
    const endpoint = `${this._apiBase}/${prospectId}/pricing-plans`;
    return this.genericClient.genericGet<IOfferPricingResponse>(endpoint);
  }

  /**
   * Add/Update Offer Pricing
   * @param data
   * @returns
   */
  public updateOfferPricing(
    data: IAddOfferPricing,
    prospectId: string,
    isEdit: boolean = false
  ): Observable<IAddOfferPricingResponse> {
    const endpoint = `${this._apiBase}/${prospectId}/pricing-plans`;
    if (isEdit) {
      return this.genericClient.genericPatch<IAddOfferPricingResponse>(
        endpoint,
        data
      );
    } else {
      return this.genericClient.genericPost<IAddOfferPricingResponse>(
        endpoint,
        data
      );
    }
  }

  public senfOffer(
    body: IProspectSendOffer,
    prospectId: string
  ): Observable<IResponseBase<void>> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.ProspectSendOffer,
      prospectId
    );

    return this.genericClient.genericPost(endpoint, body);
  }

  /**
   * Submit Deal
   * @param data
   * @returns
   */
  public submitDeal(
    data: IProspectSubmitDeal
  ): Observable<IResponseBase<void>> {
    const endpoint = EndpointsParameter.Deal;
    return this.genericClient.genericPost<IResponseBase<void>>(endpoint, data);
  }

  /**
   * Revert Signatures
   * @param prospectId
   * @returns
   */
  public revertSignatures(prospectId: string): Observable<IResponseBase<void>> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.ProspectRevertSignature,
      prospectId
    );
    return this.genericClient.genericDelete<IResponseBase<void>>(endpoint);
  }

  public attachExistingPlan(
    prospectId: string,
    body: IProspectAttachExistingPricing
  ): Observable<IResponseBase<void>> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.ProspectAttachExistingPlan,
      prospectId
    );
    return this.genericClient.genericPost<IResponseBase<void>>(endpoint, body);
  }
}
