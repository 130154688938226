import { Injectable } from '@angular/core';
import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class StorageClientService {
  private STORAGE_NAME_EVENT = 'storage';

  constructor() {}

  public get storage(): Storage {
    return localStorage;
  }

  public get storage$() {
    return fromEvent<StorageEvent>(window, this.STORAGE_NAME_EVENT).pipe(
      filter((event) => event && event.storageArea === this.storage)
    );
  }

  public cleanStorage() {
    return this.storage.clear();
  }

  public getStorage(key: string) {
    return this.storage.getItem(key);
  }

  public setStorage(key: string, value: string) {
    return this.storage.setItem(key, value);
  }

  public removeStorage(key: string) {
    return this.storage.removeItem(key);
  }
}
