<div class="navbar-container d-flex content">

    <div class=" position-relative d-flex justify-content-between align-items-center"
        [ngClass]="hasDashboardRoute ? 'container-fluid dashlink' : 'container'">
        <div class="bookmark-wrapper d-flex align-items-center">
            <ul class="nav navbar-nav d-xl-block" *ngIf="!coreConfig.layout.menu.hidden">


                <li class="nav-item dropdown-user d-flex align-items-center"
                    *ngIf="hasDashboardRoute">
                    <a class=" nav-link dropdown-toggle dropdown-user-link " id="navbarUserDropdown"
                        aria-haspopup="true " aria-expanded="false ">

                        <span class="avatar "><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" class="feather feather-user">
                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                <circle cx="12" cy="7" r="4"></circle>
                            </svg></span>
                        <div class="user-nav d-sm-flex d-none ">
                            <span class="user-name font-weight-bolder ">{{username | titlecase}}</span>
                        </div>
                    </a>

                    <a (click)="logout()" [attr.data-transaction-name]="'navbar-logout'"
                    ><span [data-feather]=" 'power' " [class]=" 'mr-50' "></span>
                        Logout</a>
                </li>
                <li class="cp " *ngIf="!hasDashboardRoute">
                    <img [routerLink]=" '/' " [attr.data-transaction-name]="'navbar-logout'" [src]="(imageUrl + '/'+ brandCustomize?.logo)" alt="loading..."
                    [attr.width]="brandCustomize?.logoWidth ? brandCustomize?.logoWidth : 'auto'">
                </li>
            </ul>
            <!--/ Menu Toggler -->
            <div class="onboard-logout" *ngIf="_router.url.includes('onboarding') || _router.url.includes('merchant-application-progress')
            || _router.url.includes('secondary-signer')">
                <a (click)="logout()" [attr.data-transaction-name]="'navbar-logout'" class="dropdown-item bg-white p-0 text-primary"><svg
                        xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-power mr-50">
                        <path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path>
                        <line x1="12" y1="2" x2="12" y2="12"></line>
                    </svg> <span>Logout</span></a>
            </div>
            <ul class="nav navbar-nav flex-row justify-content-around ms-5 ps-4 menu-navs "
                *ngIf="coreConfig.layout.navLinks ">
                <li class="nav-item " *ngIf="brandCustomize?.linkOne">
                    <a href="javascript:void(0)" (click)="openTab(brandCustomize?.linkOneUrl)" [attr.data-transaction-name]="'navbar-link-one'">

                        {{(brandCustomize?.linkOne) | uppercase}}
                    </a>
                </li>
                <li class="nav-item " *ngIf="brandCustomize?.linkTwo">

                    <a href="javascript:void(0)" (click)="openTab(brandCustomize?.linkTwoUrl)" [attr.data-transaction-name]="'navbar-link-two'">
                        {{(brandCustomize?.linkTwo) | uppercase}}
                    </a>


                </li>
                <li class="nav-item " *ngIf="brandCustomize?.linkThree">
                    <a href="javascript:void(0)" (click)="openTab(brandCustomize?.linkThreeUrl)" [attr.data-transaction-name]="'navbar-link-three'">
                        {{(brandCustomize?.linkThree ) | uppercase}}
                    </a>
                </li>
                <li class="nav-item" *ngIf="brandCustomize?.linkFour">
                    <a href="" (click)="openTab(brandCustomize?.linkFourUrl)"  [attr.data-transaction-name]="'navbar-link-four'">
                        {{(brandCustomize?.linkFour ) | uppercase}}
                    </a>
                </li>
                <li class="nav-item " *ngIf="brandCustomize?.linkFive">
                    <a href="javascript:void(0)" (click)="openTab(brandCustomize?.linkFiveUrl)"  [attr.data-transaction-name]="'navbar-link-five'">
                        {{(brandCustomize?.linkFive ) | uppercase}}
                    </a>
                </li>
            </ul>






            <!--/ Toggle skin -->
        </div>
        <div class="ms-auto pe-1 pe-md-0 pe-lg-0" *ngIf="(_router.url.includes( 'signup') || _router.url.includes('onboarding')) && !hasDashboardRoute? 'onboarding': '' ">
            <button *ngIf="!offerTitle " type="button " class="btn btn-primary view-offer ms-auto "
                (click)="toggleOffer() ">{{'your offer' | titlecase}}</button>
            <button *ngIf="offerTitle " type="button " class="btn-close fs-14" (click)="toggleOffer() "
                aria-label="Close ">
            </button>
        </div>
        <ul class="nav navbar-nav align-items-center ms-xl-auto ms-1 d-xl-none" #toggleElement
            *ngIf="!_router.url.includes('onboarding') && !_router.url.includes('merchant-application-progress')
            && !_router.url.includes('secondary-signer') && (hasDashboardRoute ? true: coreConfig.layout.navLinks)">



            <li class="nav-item ">
                <a class="nav-link menu-toggle " (click)="toggleSidebar( 'menu') ">
                    <span [data-feather]=" 'menu' " [class]=" 'ficon' "></span>
                </a>
            </li>
        </ul>




    </div>
</div>
