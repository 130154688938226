import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { EHardwareTerminal } from '@parameters/filter/hardware-terminal.parameter';

export enum EOCEEquipmentPricingFormControlName {
  optionList = 'optionList',
  hidden = 'hidden',
  active = 'active',
}

export enum EOCEEquipmentPricingOptionFormControlName {
  id = 'id',
  active = 'active',
  terminalCost = 'terminalCost',
  monthlyCost = 'monthlyCost',
  included = 'included',
}

export interface IOCEEquipmentPricingFormValue {
  [EOCEEquipmentPricingFormControlName.optionList]: IOCEEquipmentPricingOptionFormValue[];
  [EOCEEquipmentPricingFormControlName.hidden]: boolean;
  [EOCEEquipmentPricingFormControlName.active]: boolean;
}

export interface IOCEEquipmentPricingOptionFormValue {
  [EOCEEquipmentPricingOptionFormControlName.id]: EHardwareTerminal;
  [EOCEEquipmentPricingOptionFormControlName.active]: boolean;
  [EOCEEquipmentPricingOptionFormControlName.terminalCost]: number | string;
  [EOCEEquipmentPricingOptionFormControlName.monthlyCost]: number | string;
  [EOCEEquipmentPricingOptionFormControlName.included]: boolean;
}

export interface IOCEEquipmentPricingForm {
  [EOCEEquipmentPricingFormControlName.optionList]: FormArray<
    FormGroup<IOCEEquipmentPricingOptionForm>
  >;
  [EOCEEquipmentPricingFormControlName.hidden]: FormControl<
    IOCEEquipmentPricingFormValue[EOCEEquipmentPricingFormControlName.hidden]
  >;
  [EOCEEquipmentPricingFormControlName.active]: FormControl<
    IOCEEquipmentPricingFormValue[EOCEEquipmentPricingFormControlName.active]
  >;
}

export interface IOCEEquipmentPricingOptionForm {
  [EOCEEquipmentPricingOptionFormControlName.id]: FormControl<
    IOCEEquipmentPricingOptionFormValue[EOCEEquipmentPricingOptionFormControlName.id]
  >;
  [EOCEEquipmentPricingOptionFormControlName.active]: FormControl<
    IOCEEquipmentPricingOptionFormValue[EOCEEquipmentPricingOptionFormControlName.active]
  >;
  [EOCEEquipmentPricingOptionFormControlName.terminalCost]: FormControl<
    IOCEEquipmentPricingOptionFormValue[EOCEEquipmentPricingOptionFormControlName.terminalCost]
  >;
  [EOCEEquipmentPricingOptionFormControlName.monthlyCost]: FormControl<
    IOCEEquipmentPricingOptionFormValue[EOCEEquipmentPricingOptionFormControlName.monthlyCost]
  >;
  [EOCEEquipmentPricingOptionFormControlName.included]: FormControl<
    IOCEEquipmentPricingOptionFormValue[EOCEEquipmentPricingOptionFormControlName.included]
  >;
}

export const COCEEquipmentPricingFormDefaultValue: IOCEEquipmentPricingFormValue =
  {
    [EOCEEquipmentPricingFormControlName.optionList]: [],
    [EOCEEquipmentPricingFormControlName.hidden]: false,
    [EOCEEquipmentPricingFormControlName.active]: false,
  };
