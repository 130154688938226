import { ProxyGateway } from '@parameters/http/api-proxy-gateway';

const API_BASE = `${ProxyGateway.loadBaseApiUrl()}`;
export class AuthenticationEndpointsParameter {
  /** Entity authentication api endpoint */
  public static EntityForgotPassword = `${API_BASE}v1/users/entity/forgot-password`;
  public static EntityResetPassword = `${API_BASE}v1/users/entity/reset-password`;
  public static EntitySetPassword = `${API_BASE}v1/users/entity/reset-password`;

  /** Multi Signature Principal Merchant api endpoint */
  public static MechantApplicationList = `${API_BASE}v1/users/my-merchants`;
}
