import { IPaymentAccountParams } from '@interfaces/payments/payment-account.interface';

export enum EPaymentAccountParams {
  search = 'search',
  page = 'page',
  limit = 'limit',
}

export const CPaymentAccountParamsDefault: IPaymentAccountParams = {
  [EPaymentAccountParams.search]: '',
  [EPaymentAccountParams.page]: 1,
  [EPaymentAccountParams.limit]: 20,
};

export enum PAYMENT_POP_STATE {
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
}
