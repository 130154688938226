import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericClientService } from '@clients/generic/generic-client.service';
import { GenericHelper } from '@helpers/generic.helper';
import { IResponseBase } from '@interfaces/generic/responses.interface';
import {
  IProspectsDocumentUploadResponse,
  IProspectsRequiredDocumentResponse,
  IProspectsUploadParams,
  IUploadRequiredDocument,
} from '@interfaces/prospects/prospects-uploads.interface';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import {
  CProspectsUploadParams,
  EProspectsUploadParams,
} from '@parameters/prospects/prospects-uploads.parameter';
import { Observable } from 'rxjs';

@Injectable()
export class ProspectUploadsClientsService {
  constructor(private readonly _genericClient: GenericClientService) {}

  public getRequiredDocuments(
    prospectId: string,
    params: { search: string }
  ): Observable<IProspectsRequiredDocumentResponse> {
    const param = new HttpParams().set('search', params?.search || '');

    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.ProspectRequiredDocument,
      prospectId
    );

    return this._genericClient.genericGet<IProspectsRequiredDocumentResponse>(
      endpoint,
      { params: param }
    );
  }

  public getDocumentUploads(
    prospectId: string,
    params: IProspectsUploadParams
  ): Observable<IProspectsDocumentUploadResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.ProspectDocumentUploads,
      prospectId
    );

    let param = new HttpParams()
      .set(
        EProspectsUploadParams.page,
        params[EProspectsUploadParams.page] ||
          CProspectsUploadParams[EProspectsUploadParams.page]
      )
      .set(
        EProspectsUploadParams.limit,
        params[EProspectsUploadParams.limit] ||
          CProspectsUploadParams[EProspectsUploadParams.limit]
      )
      .set(
        EProspectsUploadParams.search,
        params[EProspectsUploadParams.search] ||
          CProspectsUploadParams[EProspectsUploadParams.search]
      )
      .set(
        EProspectsUploadParams.sort,
        params[EProspectsUploadParams.sort].join(',') ||
          CProspectsUploadParams[EProspectsUploadParams.sort].join(',')
      );

    return this._genericClient.genericGet<IProspectsDocumentUploadResponse>(
      endpoint,
      { params: param }
    );
  }

  public uploadRequiredDocuments(
    docs: IUploadRequiredDocument,
    prospectId: string,
    requestId: string
  ): Observable<IResponseBase<void>> {
    let endpoint = '';
    if (requestId) {
      endpoint = GenericHelper.replaceUrl(
        EndpointsParameter.NewProspectUploadDocuments,
        prospectId
      )?.replace('???', requestId);
    } else {
      endpoint = GenericHelper.replaceUrl(
        EndpointsParameter.ProspectUploadDocuments,
        prospectId
      );
    }

    return this._genericClient.genericPost<IResponseBase<void>>(endpoint, docs);
  }

  public deleteRequiredDocument(
    prospectId: string,
    documentId: string
  ): Observable<IResponseBase<void>> {
    const url = GenericHelper.replaceUrl(
      EndpointsParameter.ProspectUploadDocuments,
      prospectId
    );
    const endpoint = `${url}/${documentId}`;
    return this._genericClient.genericDelete(endpoint);
  }
}
