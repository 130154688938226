import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { OnboardingCustomizationEdit } from '@pages/onboarding-customization/onboarding-customization-edit/models/onboarding-customization-edit.model';
import { IOnboardingCustomizationEditFormValue } from '@pages/onboarding-customization/onboarding-customization-edit/services/onboarding-customization-edit-form.interface';
import { OnboardingCustomizationEditRequest } from '@pages/onboarding-customization/onboarding-customization-edit/models/onboarding-customization-edit-request.model';

@Injectable({ providedIn: 'root' })
export class OnboardingCustomizationEditStoreService {
  // Observable string source
  private detailSource = new BehaviorSubject<OnboardingCustomizationEdit>(null);
  private requestSource: Subject<OnboardingCustomizationEditRequest> =
    new Subject();
  private cancelSource: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private saveErrorSource: Subject<boolean> = new Subject();
  private isEditionSource = false;

  constructor() {}

  // Observable string streams
  detail$ = this.detailSource.asObservable();
  request$ = this.requestSource.asObservable();
  cancel$ = this.cancelSource.asObservable();

  saveError$ = this.saveErrorSource.asObservable();

  // Service message commands
  public detail(value: OnboardingCustomizationEdit) {
    this.detailSource.next(value);
  }

  public request(value: IOnboardingCustomizationEditFormValue) {
    const request = value
      ? new OnboardingCustomizationEditRequest(value)
      : null;
    this.requestSource.next(request);
  }

  public cancel(value: boolean = true) {
    this.cancelSource.next(value);
  }

  public saveError() {
    this.saveErrorSource.next(true);
  }

  public set isEdition(value: boolean) {
    this.isEditionSource = value;
  }

  public get isEdition(): boolean {
    return this.isEditionSource;
  }

  public clean() {
    this.cancel(false);
    this.detail(null);
    this.request(null);
    this.isEdition = false;
  }
}
