import { NgModule } from '@angular/core';
import { MerchantMSClientService } from '@clients/merchant/merchant-ms-client.service';
import { FundingPlanClientService } from './funding-plans/funding-plan-client.service';
import { FundingPlanDetailClientService } from '@clients/funding-plans/funding-plan-detail-client.service';
import { CookieClientService } from '@clients/storage/cookie-client.service';
import { StorageClientService } from '@clients/storage/storage-client.service';
import { ProspectClientService } from './prospects/prospect-client.service';
import { MetricsKpisProspectClientService } from '@clients/metrics/metrics-kpis-prospect-client.service';
import { DisputeClientService } from '@clients/reports/dispute/dispute-client.service';
import { VolumeClientService } from '@clients/reports/volume/volume-client.service';
import { DepositClientService } from './deposits/deposit-client.service';
import { MetricsKpisProcessingClientService } from './metrics/metrics-kpis-processing-client.service';
import { DashboardChartClientService } from './dashboard/dashboard-chart-client.service';
import { EntityRelationshipClientService } from './entity/relationship/entity-relationship-client.service';
import { DealClientService } from './ops-and-uw-tools/deals/deal-client.service';
import { ApplicationClientService } from './application/merchant/application-client.service';
import { FpBillingSettingClientService } from '@clients/funding-plans/fp-billing-setting.client.service';
import { EntityMerchantClientService } from '@clients/entity/merchant/entity-merchant-client.service';
import { MetricsKpisMerchantClientService } from '@clients/metrics/metrics-kpis-merchant-client.service';
import { MerchantClientService } from '@clients/merchant/merchant-client.service';
import { MerchantBoardingClientService } from './ops-and-uw-tools/merchant-boarding/merchant-boarding-client.service';
import { AgreementExpressClientService } from './ops-and-uw-tools/agreement-express/agreement-express-client.service';
import { ProspectDetailClientService } from '@clients/prospects/prospect-detail-client.service';
import { OnboardingTemplateClientService } from './onboarding-template/onboarding-template-client.service';
import { AuthClientService } from '@clients/reports/auth/auth-client.service';
import { OfferPricingClientService } from './offer-pricing/offer-pricing-client.service';
import { MetricsKpisVolumeClientService } from '@clients/metrics/metrics-kpis-volume-client.service';
import { MetricsKPIsDealClientService } from '@clients/metrics/metrics-kpis-deal-client.service';
import { BatchClientService } from '@clients/reports/batch/batch-client.service';
import { ProspectApplicationClientService } from './prospects/prospect-application.client.service';
import { MetricsKpisDisputeClientService } from '@clients/metrics/metrics-kpis-dispute-client.service';
import { MyMerchantClientService } from '@clients/merchant/my-merchant-client.service';
import { EntityClientService } from '@clients/entity/entity-client.service';
import { CoreClientService } from './core/core-client.service';
import { ProspectEquipmentClientService } from './prospects/prospect-equipment-client.service';
import { ProspectUploadsClientsService } from './prospects/prospect-uploads-client.service';
import { ResidualClientService } from '@clients/reports/residual/residual-client.service';
import { StatementClientService } from '@clients/reports/statement/statement-client.service';
import { TrainingClientService } from './resources/training/training-client.service';
import { MetricsChartsCardBrandVolumeClientService } from '@clients/metrics/charts/metrics-charts-card-brand-volume-client.service';
import { ProspectOnboardingCustomizationClientService } from '@clients/prospects/prospect-onboarding-customization-client.service';
import { OnboardingTemplateOnboardingCustomizationClientService } from '@clients/onboarding-template/onboarding-template-onboarding-customization-client.service';
import { EquipmentClientService } from '@clients/core/equipment-client.service';
import { UsersAssociateClientService } from '@clients/ops-and-uw-tools/users/users-client.service';
import { UserCreateClientService } from './ops-and-uw-tools/users/user-create-client.service';
import { ProspectsInformationRequestStoreService } from 'src/app/business/portal/portfolio/prospects/services/prospect-information-requests-store.service';
import { ProspectsInformationRequestImplementService } from 'src/app/business/portal/portfolio/prospects/services/prospect-information-req-implement.service';
import { ProspectInfromationRequestClientService } from './prospects/prospect-information-requests';

@NgModule({
  providers: [
    CookieClientService,
    StorageClientService,
    MerchantMSClientService,
    MerchantClientService,
    FundingPlanClientService,
    FundingPlanDetailClientService,
    ProspectClientService,
    MetricsKpisProspectClientService,
    DisputeClientService,
    VolumeClientService,
    DepositClientService,
    MetricsKpisProcessingClientService,
    DashboardChartClientService,
    EntityRelationshipClientService,
    DealClientService,
    ApplicationClientService,
    FpBillingSettingClientService,
    EntityMerchantClientService,
    MetricsKpisMerchantClientService,
    MerchantBoardingClientService,
    AgreementExpressClientService,
    ProspectDetailClientService,
    OnboardingTemplateClientService,
    AuthClientService,
    OfferPricingClientService,
    MetricsKpisVolumeClientService,
    MetricsKPIsDealClientService,
    BatchClientService,
    ProspectApplicationClientService,
    MetricsKpisDisputeClientService,
    MyMerchantClientService,
    EntityClientService,
    CoreClientService,
    ProspectEquipmentClientService,
    ProspectUploadsClientsService,
    ResidualClientService,
    StatementClientService,
    TrainingClientService,
    MetricsChartsCardBrandVolumeClientService,
    ProspectOnboardingCustomizationClientService,
    OnboardingTemplateOnboardingCustomizationClientService,
    EquipmentClientService,
    UsersAssociateClientService,
    UserCreateClientService,
    ProspectsInformationRequestStoreService,
    ProspectsInformationRequestImplementService,
    ProspectInfromationRequestClientService,
  ],
})
export class ClientsModule {}
