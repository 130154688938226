import {
  EUserListParams,
  EUserListSort,
} from '@parameters/ops-and-uw-tools/users/user-list.parameter';
import {
  IResponseBase,
  IResponseData,
} from '@interfaces/generic/responses.interface';
import { EUserStatus } from '@parameters/ops-and-uw-tools/users/user.parameter';
import { UserRoles } from '../../../../auth/models';
import { CountryCode } from 'libphonenumber-js';

export interface IUserListParams {
  [EUserListParams.size]: number;
  [EUserListParams.page]: number;
  [EUserListParams.sort]: string;
  [EUserListParams.search]: string;
  [EUserListParams.name]?: string;
  [EUserListParams.email]?: string;
  [EUserListParams.status]: EUserStatus | string;
}

export const CUserListParamsDefault: IUserListParams = {
  [EUserListParams.size]: 10,
  [EUserListParams.page]: 1,
  [EUserListParams.sort]: EUserListSort.createdDateDesc,
  [EUserListParams.search]: '',
  [EUserListParams.name]: '',
  [EUserListParams.email]: '',
  [EUserListParams.status]: '',
};

export interface IUser {
  id: string;
  email: string;
  role: UserRoles;
  roles: UserRoles[];
  phoneNumber: string;
  phoneNumberCountryCode: CountryCode;
  isAccountActive: EUserStatus;
  globalAccount: boolean;
  createdDate: Date;
  agentFirstName: string;
  agentLastName: string;
  createdAt: string;
  firstName: string;
  isGlobalUser: boolean;
  lastName: string;
}

export interface IUserListResponse
  extends IResponseBase<IResponseData<IUser>> {}
