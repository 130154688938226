import { FormControl } from '@angular/forms';
import {
  IResponseBase,
  IResponseData,
} from '@interfaces/generic/responses.interface';
import {
  EDealNotesListParamSort,
  EDealNotesListParams,
} from '@parameters/ops-and-uw-tools/deal/deal-notes.parameter';

export interface IDealNote {
  body: string;
  createdBy: string;
  dateTime: Date | string;
  email: string;
  id: string;
  title: string;
}

export interface IDealCreateNoteResponse
  extends IResponseBase<{ noteId: string }> {}

export interface IDealNoteDetailResponse extends IResponseBase<IDealNote> {}

export interface IDealNotesListResponse
  extends IResponseBase<IResponseData<IDealNote>> {}

export interface IDealNotesListParams {
  [EDealNotesListParams.limit]: number;
  [EDealNotesListParams.page]: number;
  [EDealNotesListParams.sort]: EDealNotesListParamSort[];
  [EDealNotesListParams.search]: string;
}

export const CDealNotesListParamsDefault: IDealNotesListParams = {
  [EDealNotesListParams.limit]: 10,
  [EDealNotesListParams.page]: 1,
  [EDealNotesListParams.sort]: [EDealNotesListParamSort.createdDateDesc],
  [EDealNotesListParams.search]: '',
};

export enum ECreateDealNoteFormKeys {
  title = 'title',
  body = 'body',
}

export interface ICreateDealNoteFormValue {
  [ECreateDealNoteFormKeys.title]: string;
  [ECreateDealNoteFormKeys.body]: string;
}

export interface ICreateDealNoteForm {
  [ECreateDealNoteFormKeys.title]: FormControl<
    ICreateDealNoteFormValue[ECreateDealNoteFormKeys.title]
  >;
  [ECreateDealNoteFormKeys.body]: FormControl<
    ICreateDealNoteFormValue[ECreateDealNoteFormKeys.body]
  >;
}

export const CCreateDealNoteFormValue = {
  [ECreateDealNoteFormKeys.title]: '',
  [ECreateDealNoteFormKeys.body]: '',
};
