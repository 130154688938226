import { ResponsePagination } from '@models/generic/responses.model';
import { IResponseData } from '@interfaces/generic/responses.interface';
import { IMspMerchantApplication } from '@interfaces/authentication/msp-merchant-application-list.interface';
import {
  EOnboardingPageId,
  RoutePoints,
  routesArray,
} from '@helpers/routes.enum';
import { constants } from '@helpers/constants';
import {
  EMspMerchantApplicationSignatureRoleLabel,
  EMspMerchantApplicationSignatureRoleValue,
  EMspMerchantSignerStatus,
} from '@parameters/authentication/msp-merchant-application-list.parameter';

export class MspMerchantApplication {
  public merchantId: string;
  public businessName: string;
  public createdAt: string;
  public signatureRole: string; //primary_signature, secondary_signature
  public currentPageId: string;
  public status: string; //in_progress,active,
  public offerId: string;
  public currentProspectStatusId: string;
  public progress: number;
  public signatureRoleLabel: string;
  public currentMerchantStatusId: string;

  constructor(iMspMerchantApplication: IMspMerchantApplication) {
    this.merchantId = iMspMerchantApplication?.merchantId;
    this.businessName = iMspMerchantApplication?.businessName;
    this.createdAt = iMspMerchantApplication?.createdAt;
    this.signatureRole = iMspMerchantApplication?.signatureRole;
    this.currentPageId =
      iMspMerchantApplication?.signatureRole ===
      EMspMerchantApplicationSignatureRoleValue.primary
        ? iMspMerchantApplication?.currentPageId
        : iMspMerchantApplication?.status === EMspMerchantSignerStatus.pending
        ? EOnboardingPageId.summary
        : iMspMerchantApplication?.currentPageId;
    this.status = iMspMerchantApplication?.status;
    this.offerId = iMspMerchantApplication?.offerId;
    this.currentProspectStatusId =
      iMspMerchantApplication?.currentProspectStatusId;
    this.progress = this.getProgress();
    this.signatureRoleLabel =
      iMspMerchantApplication?.signatureRole ===
      EMspMerchantApplicationSignatureRoleValue.primary
        ? EMspMerchantApplicationSignatureRoleLabel.primary
        : EMspMerchantApplicationSignatureRoleLabel.secondary;
    this.currentMerchantStatusId =
      iMspMerchantApplication?.currentMerchantStatusId;
  }

  private getProgress(): number {
    let progress = 0;
    try {
      routesArray.find((route: RoutePoints) => {
        if (route.pageId === this.currentPageId) {
          progress = (route.stepNumber / constants.totalSteps) * 100;
        }
      });
    } catch (err) {
    } finally {
      return +progress.toFixed(1);
    }
  }
}

export class MspMerchantApplicationListResponse {
  pagination: ResponsePagination;
  list: MspMerchantApplication[];

  constructor(iResponseData: IResponseData<IMspMerchantApplication>) {
    this.pagination = new ResponsePagination(iResponseData.summary);
    this.list =
      iResponseData.rows && iResponseData.rows?.length
        ? iResponseData.rows.map(
            (iRelationship) => new MspMerchantApplication(iRelationship)
          )
        : [];
  }
}
