import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appMaskInput]',
})
export class MaskInputDirective {
  constructor(public ngControl: NgControl) {}

  @HostListener('inputChanged', ['$event'])
  public onKeydown({ detail }: CustomEvent) {
    const value: string = detail?.value;
    if (value && value.startsWith('XX') && !this.ngControl.pristine) {
      this.ngControl.reset();
    }
  }
}
