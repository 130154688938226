import { Injectable } from '@angular/core';
import { GenericClientService } from '@clients/generic/generic-client.service';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  CReportsResidualListParamsDefault,
  IReportsResidualCSVParams,
  IReportsResidualCSVResponse,
  IReportsResidualListParams,
  IReportsResidualListResponse,
} from '@interfaces/reports/residual/residual-list.interface';
import { EReportsResidualListParams } from '@parameters/reports/residual/residual-list.parameter';

@Injectable()
export class ResidualClientService {
  constructor(private genericClient: GenericClientService) {}

  getList(
    queryParams: IReportsResidualListParams
  ): Observable<IReportsResidualListResponse> {
    const endpoint = EndpointsParameter.ReportsResidual;
    const params = new HttpParams()
      .set(
        EReportsResidualListParams.size,
        queryParams[EReportsResidualListParams.size] ||
          CReportsResidualListParamsDefault[EReportsResidualListParams.size]
      )
      .set(
        EReportsResidualListParams.page,
        queryParams[EReportsResidualListParams.page] ||
          CReportsResidualListParamsDefault[EReportsResidualListParams.page]
      )
      .set(
        EReportsResidualListParams.sort,
        queryParams[EReportsResidualListParams.sort] ||
          CReportsResidualListParamsDefault[EReportsResidualListParams.sort]
      )
      .set(
        EReportsResidualListParams.search,
        queryParams[EReportsResidualListParams.search] ||
          CReportsResidualListParamsDefault[EReportsResidualListParams.search]
      )
      .set(
        EReportsResidualListParams.startDate,
        queryParams[EReportsResidualListParams.startDate] ||
          CReportsResidualListParamsDefault[
            EReportsResidualListParams.startDate
          ]
      )
      .set(
        EReportsResidualListParams.endDate,
        queryParams[EReportsResidualListParams.endDate] ||
          CReportsResidualListParamsDefault[EReportsResidualListParams.endDate]
      )
      .set(
        EReportsResidualListParams.midList,
        queryParams[EReportsResidualListParams.midList].join(',') ||
          CReportsResidualListParamsDefault[
            EReportsResidualListParams.midList
          ].join(',')
      )
      .set(
        EReportsResidualListParams.binList,
        queryParams[EReportsResidualListParams.binList].join(',') ||
          CReportsResidualListParamsDefault[
            EReportsResidualListParams.binList
          ].join(',')
      )
      .set(
        EReportsResidualListParams.agentCodeList,
        queryParams[EReportsResidualListParams.agentCodeList].join(',') ||
          CReportsResidualListParamsDefault[
            EReportsResidualListParams.agentCodeList
          ].join(',')
      )
      .set(
        EReportsResidualListParams.status,
        queryParams[EReportsResidualListParams.status] || ''
      );
    return this.genericClient.genericGet<IReportsResidualListResponse>(
      endpoint,
      {
        params,
      }
    );
  }

  downloadCSV(
    body: IReportsResidualCSVParams
  ): Observable<IReportsResidualCSVResponse> {
    const endpoint = EndpointsParameter.ReportsResidualCSV;
    return this.genericClient.genericPost(endpoint, body);
  }
}
