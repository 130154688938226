import { IResponseBase } from '@interfaces/generic/responses.interface';
import { EStateListParams } from '../parameter/state.parameter';

export interface IStateData {
  id: string;
  name: string;
  abbreviation: string;
  country: string;
}

export interface IStates {
  states: IStateData[];
}

export interface IStateListResponse extends IResponseBase<IStates> {}

export interface IStateListParams {
  [EStateListParams.country]: string;
}

export const CStateParamsDefault: IStateListParams = {
  [EStateListParams.country]: '',
};
