import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
} from 'ngx-om-perfect-scrollbar';

import { CoreCommonModule } from '@core/common.module';
import { NavbarComponent } from './navbar.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false,
};

@NgModule({
  declarations: [NavbarComponent],
  imports: [RouterModule, CoreCommonModule, PerfectScrollbarModule],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
  exports: [NavbarComponent],
})
export class NavbarModule {}
