import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GtrModalComponent } from '@gettrx/core-components-angular';
import { noWhitespaceValidator } from '@helpers/validators';
import {
  IPaymentConfirmPassword,
  IPaymentConfirmPasswordForm,
} from '@interfaces/payments/payment-account.interface';
import { PaymentAccountsStoreService } from '@stores/payment-accounts-store.service';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-payment-confirm-password',
  templateUrl: './payment-confirm-password.component.html',
  styleUrls: ['./payment-confirm-password.component.scss'],
})
export class PaymentConfirmPasswordComponent implements OnInit {
  @ViewChild('confirmPasswordModal', { static: false })
  public editPaymentMethod!: GtrModalComponent;

  private readonly _destroyRef = inject(DestroyRef);
  private readonly _ngZone = inject(NgZone);
  private readonly _cdr = inject(ChangeDetectorRef);
  private readonly _paymentAccountStore = inject(PaymentAccountsStoreService);
  private readonly _commonService = inject(CommonService);

  @Input() public dataTransactionName: string = 'no-tagged';
  @Input() public set show(value: boolean) {
    if (!this.editPaymentMethod) return;

    if (value) {
      this.showModal();
    } else {
      this.closeModal();
    }
  }

  @Output() public modalClosed = new EventEmitter<{
    authenticated: boolean;
  }>();

  public isLoading = false;

  public confirmPasswordForm = new FormGroup<IPaymentConfirmPasswordForm>({
    password: new FormControl('', {
      validators: [Validators.required, noWhitespaceValidator],
    }),
  });

  ngOnInit(): void {}

  public submitForm() {
    if (this.confirmPasswordForm?.valid) {
      this.isLoading = true;
      this._paymentAccountStore
        ?.confirmPaymentPassword(
          this.confirmPasswordForm?.value as IPaymentConfirmPassword
        )
        ?.pipe(takeUntilDestroyed(this._destroyRef))
        ?.subscribe({
          next: (res) => {
            this.isLoading = false;
            this._commonService.paymentAuthToken = res?.data || '';
            this._commonService.success(res?.message || 'Confirmation Successfull!');
            this.closeModal(true);
          },
          error: () => {
            this._ngZone.run(() => {
              this.isLoading = false;
            });
            this._cdr.detectChanges();
          },
        });
    } else {
      this.confirmPasswordForm?.markAllAsTouched();
    }
  }

  /**
   * Open edit modal
   */
  public showModal() {
    this.editPaymentMethod.isOpen = true;
  }

  /**
   * Close modal
   * @param refreshData
   */
  public closeModal(authenticated: boolean = false) {
    this.editPaymentMethod.isOpen = false;
    this.confirmPasswordForm.reset();
    this.modalClosed.emit({ authenticated: authenticated });
  }
}
