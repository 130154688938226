import { DestroyRef, Injectable, inject } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';

import { UserRoles } from 'src/app/auth/models';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
import { GlobalConstantRoutes } from '@parameters/routing/global-constant-routes';
import { AuthSession } from '../models/auth-session.enum';
import { IMspStoredMerchantApplicationItem } from '@interfaces/authentication/msp-merchant-application-list.interface';
import {
  EMspMerchantApplicationSignatureRoleValue,
  EMspMerchantSignerStatus,
} from '@parameters/authentication/msp-merchant-application-list.parameter';
import { MSP_APPLICATION_PROGRESS_PAGE_URL } from '@parameters/routing/msp-application-progress.parameter';
import { APPLICATION_PATH } from '@parameters/routing/application-path';
import { BUSINESS_PATH } from '@parameters/routing/business.parameter';
import { PORTAL, PORTAL_PATH } from '@parameters/routing/portal.parameter';
import { PortalStoreService } from '@stores/portal-store.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface StateData {
  state: string;
  data: any;
}
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  subject$: Subject<StateData> = new Subject();
  updateState: StateData = {} as StateData;
  merchantId$: Subject<string> = new BehaviorSubject(
    this.currentSelectedMerchantId
  );
  subs: Subscription[] = [];

  //private
  private _isoToken: string = '';
  private _portalType: PORTAL = PORTAL.entity;

  private readonly _destroyRef = inject(DestroyRef);

  /**
   *
   * @param cookieService
   * @param _router
   * @param cmService
   * @param _portalService
   */
  constructor(
    private cookieService: CookieService,
    private _router: Router,
    private cmService: CommonService,
    private _portalService: PortalStoreService
  ) {
    this._portalService.type$
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((res) => {
        this._portalType = res;
      });
  }

  // get auth token
  get authToken(): string {
    return (
      this.token || this.cookieService.get(AuthSession.isoAccessToken) || ''
    );
  }

  //set auth token
  set token(token: string) {
    this._isoToken = token;
  }

  get token(): string {
    return this._isoToken as string;
  }

  /**
   * Get logged In user entityId
   */

  get currentEntityId(): string {
    return this.cookieService.get(AuthSession.entityId) || '';
  }

  get userRoles(): Array<string> {
    try {
      return (
        (this.cookieService.get('roles') &&
          (JSON?.parse(
            this.cookieService.get('roles')
          ) as unknown as Array<string>)) ||
        []
      );
    } catch (err) {}
  }
  /**
   * for get allowed bankBinList
   */
  get bankBinsList(): Array<number> {
    try {
      return (
        (this.cookieService?.get(AuthSession.bins) &&
          (JSON?.parse(
            this.cookieService?.get(AuthSession.bins)
          ) as unknown as Array<number>)) ||
        []
      );
    } catch (err) {}
  }

  hasRole(role: string): boolean {
    try {
      return (
        this.userRoles?.length && (this.userRoles?.includes(role) as boolean)
      );
    } catch (err) {}
  }

  hasMultipleRoles(role: string[]): boolean {
    try {
      return (
        this.userRoles?.length &&
        this.userRoles?.some((item) => role.includes(item))
      );
    } catch (err) {}
  }

  /**
   * Auth current merchant data
   */

  get getStoredSelectedMerchantApplicationData(): IMspStoredMerchantApplicationItem {
    try {
      return (
        (this.cookieService.get(AuthSession.selectedMerchantApplication) &&
          (JSON?.parse(
            this.cookieService.get(AuthSession.selectedMerchantApplication)
          ) as unknown as IMspStoredMerchantApplicationItem)) ||
        ({} as IMspStoredMerchantApplicationItem)
      );
    } catch (err) {}
  }

  get currentSelectedMerchantId(): string {
    return this.cookieService.get(AuthSession.currentSelectedMerchantId) || '';
  }

  set currentSelectedMerchantId(id: string) {
    this.cookieService.set(AuthSession.currentSelectedMerchantId, id, {
      path: '/',
    });
  }

  /**
   * Get role based merchantIds
   */

  get primarySignerMerchantIds(): string[] {
    try {
      return (
        (this.cookieService.get(AuthSession.primarySignerMerchantIds) &&
          (JSON?.parse(
            this.cookieService.get(AuthSession.primarySignerMerchantIds)
          ) as unknown as Array<string>)) ||
        []
      );
    } catch (err) {}
  }

  get secondarySignerMerchantIds(): string[] {
    try {
      return (
        (this.cookieService.get(AuthSession.secondarySignerMerchantIds) &&
          (JSON?.parse(
            this.cookieService.get(AuthSession.secondarySignerMerchantIds)
          ) as unknown as Array<string>)) ||
        []
      );
    } catch (err) {}
  }

  goBack() {
    const application = this.getStoredSelectedMerchantApplicationData;
    const basePrefix =
      application.selectedApplicationSignatureRole ===
      EMspMerchantApplicationSignatureRoleValue.secondary
        ? MSP_APPLICATION_PROGRESS_PAGE_URL.secondarySigner
        : 'onboarding';
    const routeBase = `/${basePrefix}/${application.selectedApplicationMerchantId}/`;
    if (
      application.selectedApplicationSignatureRole ===
      EMspMerchantApplicationSignatureRoleValue.secondary
    ) {
      if (
        application.selectedApplicationStatus ===
        EMspMerchantSignerStatus.pending
      ) {
        this._router.navigate([
          routeBase + MSP_APPLICATION_PROGRESS_PAGE_URL.secondarySignerSummary,
        ]);
      } else {
        const route = `/${BUSINESS_PATH.portal}/${PORTAL.merchant}/${APPLICATION_PATH.application}/${application.selectedApplicationMerchantId}`;
        this._router.navigate([route]);
      }
      return;
    } else {
      this.redirectToFirstTab();
    }
  }

  /**
   * User logout
   *
   */
  logout(url?: string) {
    // remove user from local storage to log user out
    localStorage.removeItem('isOnBoarding');
    localStorage.removeItem('userName');
    localStorage.removeItem('step_number');
    localStorage.removeItem('onBoarding');
    const logoutUrl =
      this.hasRole(UserRoles.Merchant) || this.hasRole(UserRoles.MerchantSigner)
        ? '/login'
        : GlobalConstantRoutes.ENTITY_LOGIN;
    this.cookieService.deleteAll('/');
    sessionStorage.clear();
    const execurl = url || logoutUrl;
    this._router.navigate([execurl]).then(() => {
      this.cmService.isLoading = false;
    });
  }

  /**
   * validate roles
   */
  public validateRoles(roles: string[]): boolean {
    const validRoles: string[] = this.userRoles;
    return roles.some((role) => validRoles.includes(role));
  }

  public redirectToFirstTab() {
    const url = this.hasRole(UserRoles.Bank)
      ? `/${BUSINESS_PATH.portal}/${this._portalType}/${PORTAL_PATH.mms}`
      : this.hasRole(UserRoles.GettrxDataEntry) ||
        this.hasRole(UserRoles.GettrxUnderwriting)
      ? `/${BUSINESS_PATH.portal}/${this._portalType}/ops-and-uw-tools/deals`
      : this.hasRole(UserRoles.GettrxSupport)
      ? `/${BUSINESS_PATH.portal}/${this._portalType}/reports/settled-volume`
      : this.hasRole(UserRoles.GettrxRiskAnalyst)
      ? `/${BUSINESS_PATH.portal}/${this._portalType}/risk-analysis/risk-events`
      : '/';

    this._router.navigate([url]);
  }
}
