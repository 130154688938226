import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { GtrIconModule } from "@gettrx/core-components-angular";
import { NgIf } from "@angular/common";
export type InputType = 'input' | 'select';
@Component({
  standalone: true,
  selector: 'app-input-error-msg',
  templateUrl: './input-error-msg.component.html',
  styleUrls: ['./input-error-msg.component.scss'],
  imports: [
    GtrIconModule,
    NgIf
  ]
})
export class InputErrorMsgComponent {
  @Input() control!: AbstractControl;
  @Input() defaultValidation: boolean = false;
  @Input() inputLabel!: string;
  @Input() digitsToRemove: number = 0;
  @Input() customError!: string;

  @Input() min: number;
  @Input() max: number;
  @Input() inputType: string;

  ngOnInit() {}

  get getMaxValue(): number {
    if (!this.control) {
      return;
    }
    const maxValidator = this.control?.getError('max');
    if (maxValidator) {
      return maxValidator?.max;
    }
    return 0;
  }

  get getMinValue(): number {
    if (!this.control) {
      return;
    }
    const minValidator = this.control?.getError('min');
    if (minValidator) {
      return minValidator?.min;
    }
    return 0;
  }

  get getMaxLengthValue(): number {
    if (!this.control) {
      return;
    }
    const maxLengthValidator = this.control?.getError('maxlength');
    if (maxLengthValidator) {
      return maxLengthValidator?.requiredLength;
    }
    return 0;
  }

  get getMinLengthValue(): number {
    if (!this.control) {
      return;
    }
    const minLengthValidator = this.control?.getError('minlength');
    if (minLengthValidator) {
      return minLengthValidator?.requiredLength;
    }
    return 0;
  }

  get minDigitsAfterDecimal(): object {
    if (!this.control) {
      return;
    }
    const minDigits = this.control?.getError('decimalExceeded');
    if (minDigits) {
      return minDigits;
    }
    return {};
  }
  get precisionBeforeDecimal(): object {
    if (!this.control) {
      return;
    }
    const precision = this.control?.getError('numericPrecision');
    if (precision) {
      return precision;
    }
    return {};
  }
}
