import { Injectable, inject } from '@angular/core';
import { PartnerAccountSelectImplementService } from '@implements/partner-account-select-implement.service';
import { IPartnerAccountListParams } from '@interfaces/developers/partner-account-select.interface';
import { EPartnerAccountListParams } from '@parameters/developers/partner-account-select.parameter';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DevelopersAccountService {
  private readonly _partnerAccountStore = inject(
    PartnerAccountSelectImplementService
  );

  public partnerAccountList$ = new BehaviorSubject<any[]>([]);
  public partnerAccountId$ = new Subject<string>();
  public currentPartnerAccount$ = new Subject<string>();
  public refreshPartnerAccount$ = new Subject<boolean>();

  public previousPartnerAccountId: string;

  private readonly filterChangeSubject = new BehaviorSubject<boolean>(false);

  private _search: string = '';
  private _page: number = 1;
  private _limit: number = 100;

  /**
   * Get accounts list
   */
  public getPartnerAccountsList() {
    const params: IPartnerAccountListParams = {
      [EPartnerAccountListParams.search]: this.search || '',
      [EPartnerAccountListParams.page]: this.page || 1,
      [EPartnerAccountListParams.limit]: this.limit || 100,
    };

    return this._partnerAccountStore.getPartnerAccounts(params);
  }

  public set search(value: string) {
    this._search = value || '';
    this.page = 1;
  }

  public set page(value: number) {
    this._page = value || 1;
    this.filterChange = true;
  }

  public set limit(value: number) {
    this._limit = value || 100;
    this.page = 1;
  }

  public get search() {
    return this._search;
  }

  public get page() {
    return this._page;
  }

  public get limit() {
    return this._limit;
  }

  public set filterChange(state: boolean) {
    this.filterChangeSubject.next(state);
  }

  public get filterChange$() {
    return this.filterChangeSubject.asObservable();
  }

  public get partnerAccountsList() {
    return this.partnerAccountList$.asObservable();
  }

  public defaultValues() {
    this._search = '';
    this._page = 1;
    this._limit = 100;
  }
}
