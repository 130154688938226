import { Routes } from '@angular/router';
import { APP_PATH } from "@parameters/routing/app.parameter";
import { EMerchantApplicationIdUrl } from "@parameters/authentication/msp-merchant-application-list.parameter";
import { SubmissionGuard } from "./auth/helpers/onboard.guard";
import { RoleGuard } from "./auth/helpers/roles.guard";
import { UserRoles } from "./auth/models";

export const routes: Routes = [

  {
    path: APP_PATH.business,
    loadChildren: () =>
      import('./business/business.module').then((m) => m.BusinessModule),
  },
  {
    path: APP_PATH.emailVerified,
    // canActivate:[verificationGuard],
    loadChildren: () =>
      import('./core/pages/verify-email/verify-email.module').then(
        (m) => m.VerifyEmailModule
      ),
    data: {
      animation: 'auth',
      title: 'Verification',
    },
  },
  {
    path: `${APP_PATH.applicationSubmitted}/:${EMerchantApplicationIdUrl.merchantApplicationId}`,
    canActivate: [SubmissionGuard, RoleGuard],
    loadChildren: () =>
      import('./core/pages/application-submitted/application.module').then(
        (m) => m.ApplicationModule
      ),
    data: {
      animation: 'auth',
      title: 'Onboarding',
      role: [UserRoles.Merchant, UserRoles.MerchantSigner],
    },
  },
  {
    path: APP_PATH.miscellaneous,
    loadChildren: () =>
      import('./core/pages/miscellaneous/miscellaneous.module').then(
        (m) => m.MiscellaneousModule
      ),
  },
  {
    path: APP_PATH.wildcard,
    redirectTo: '/miscellaneous/page-not-found', //Error 404 - Page not found
    pathMatch: 'full',
  },
];
