import { Injectable } from '@angular/core';

@Injectable()
export class apiList {
  public _merchantBase = `v1/merchants/`;
  public _agentBase = `v1/agent/`;
  public _userBase = `v1/users/`;
  public _coreBase = `v1/core/`;

  // Branding for merchant api
  branding: string = `${this._agentBase}branding`;
  getMerchantOffer: string = `${this._agentBase}branding-offer`;
  getMerchantOfferV2: string = `${this._agentBase}pricing-program`;
  // Auth onboarding
  signup: string = `v1/users`;

  // New merchant signup
  merchantSignup: string = `v1/auth/merchant-signup`;

  login: string = `${this._userBase}login`;
  forgotpassword: string = `${this._userBase}forgot-password`;
  resetPassword: string = `${this._userBase}reset-password`;
  verifyEmail: string = `${this._userBase}verify-email`;
  refreshWeb: string = `${this._userBase}refresh`;

  // Entity Login
  entityLogin: string = `${this._userBase}entity-login`;

  // Onboarding steps

  // global
  getmerchantbusinessdetail: string = `${this._merchantBase}??/`; // done
  merchantbusinessdetail: string = `${this._merchantBase}??/`; // done

  getStateList: string = `${this._coreBase}states`;

  addBusinessType: string = `${this._merchantBase}??/service`; // done
  getBusinessType: string = `${this._coreBase}services`; // done
  // step 3
  getBusinesCategory: string = `${this._merchantBase}??/services`; // done

  //step 4
  getPaymentMethods: string = `${this._merchantBase}??/payment-methods`; //  done
  savePaymentMethods: string = `${this._merchantBase}??/payment-methods`; // done

  //step 5
  savePaymentPercentage: string = `${this._merchantBase}??/payment-methods`; // done

  //step 6
  getHardwareTerminal: string = `${this._merchantBase}??/equipments`; // done
  saveHardwareTerminal: string = `${this._merchantBase}??/equipments`; // done
  getMerchantTerminalCost: string = `${this._merchantBase}??/equipment-calculations`; // done
  //step 7
  saveHasExistingGatewayAccount = `${this._merchantBase}??/gateway-accounts`; // done
  getMerchantTerminalOverPhone = `${this._coreBase}virtual-terminals`; // done
  postMerchantTerminalOverPhone = `${this._merchantBase}??/virtual-terminals`; // done

  //step 8
  getEcommAndOnlinePayments: string = `${this._merchantBase}??/e-commerce-platforms-and-online-payment-methods`;
  saveEcommAndOnlinePayments: string = `${this._merchantBase}??/e-commerce-platforms-and-online-payment-methods`;
  getOnlinePayments: string = `${this._merchantBase}??/online-payment-methods`; // done
  saveOnlinePayment: string = `${this._merchantBase}??/online-payment-methods`; // done

  //step 9
  getShopingCartPlugin: string = `${this._merchantBase}??/shopping-cart-plugins`; // done
  saveShopingCartPlugin: string = `${this._merchantBase}??/shopping-cart-plugins`; // done

  //step 10
  getEcommercePlugin: string = `${this._merchantBase}??/e-commerce-platforms`; // done
  saveEcommercePlugin: string = `${this._merchantBase}??/e-commerce-platforms`; // done

  //step 11
  getPaymentGatewayPlugin: string = `${this._merchantBase}??/payment-gateway-plugins`; //done
  savePaymentGatewayPlugin: string = `${this._merchantBase}??/payment-gateway-methods`; // done

  // step 12
  getBusinessStartYear: string = `${this._merchantBase}??/start-year-month`; // done
  saveYearMonth: string = `${this._merchantBase}??/start-year-month`; // done

  // step 13
  getBusinessTypes: string = `${this._merchantBase}??/business-start-year`;
  saveBusinessType: string = `${this._merchantBase}??/save-year-month`;

  //screen is public
  isPublicUrlType: string = `${this._merchantBase}??/publicly-tradable`; // done
  // screen 11
  saveBusinessWebsite: string = `${this._merchantBase}??/website`; // done

  // business_incorporate
  getBusinesIncorporate: string = `${this._coreBase}merchant-registration-types`; // done
  saveBusinesIncorporate: string = `${this._merchantBase}??/registration-type`; // done
  // where_business_incorporated

  saveWhereBusinessIncorporated: string = `${this._merchantBase}??/state`; // done

  // verify_bussiness
  saveSocialNumberVerifyBussiness: string = `${this._merchantBase}??/ssn-ein`; // done

  // personal-bankruptcy page
  savePersonalBankruptcy: string = `${this._merchantBase}??/bankruptcy`; // done

  // product-received
  getClientServices: string = `${this._coreBase}merchant-periods`; // done
  saveClientService: string = `${this._merchantBase}??/period`; // done

  //business-electronic-information-types
  getBusinessElectronicInformationTypes: string = `${this._coreBase}electronic-information-types`; // done
  saveBusinessElectronicInformationTypes: string = `${this._merchantBase}??/electronic-information-types`; // done

  //pic-dss-complaint
  savePicDssComplaint: string = `${this._merchantBase}??/pci-dss-compliant`; // done

  // product-and-services
  saveProductAndServices: string = `${this._merchantBase}??/product-services`; // done
  // general-info
  saveAdditionalQuestions: string = `${this._merchantBase}??/additional-questions`; // done
  // Bank details
  saveBankDetails: string = `${this._merchantBase}??/bank-details`; // done
  // general-info
  saveProductServices: string = `${this._merchantBase}??/product-services-description`; // done

  saveSalesVolume: string = `${this._merchantBase}??/sales`; // done

  // ach volume
  saveAchVolume: string = `${this._merchantBase}??/ach-sales`; // done

  //business-princi pal
  saveBusinessPrincipal: string = `${this._merchantBase}??/principals`; // done

  //summary
  updateSummary: string = `${this._merchantBase}??/summary`; // done

  // merchant agreement
  getAgreementdetail: string = `${this._merchantBase}??/agreement`; // done
  // saveMerchantAgreement: string = "${this._merchantBase}??/update-accept-agreement";
  saveMerchantAgreement: string = `${this._merchantBase}??/accept-agreement`; // done

  /**
   * Secondary signer agreement endpoints
   */
  getSecondaryMerchantAgreementdetail: string = `${this._merchantBase}??/agreement-secondary-signer`; // done
  saveSecondaryMerchantAgreement: string = `${this._merchantBase}??/accept-agreement-secondary-signer`; // done

  businessStatus: string = `${this._merchantBase}??/onboarding-status`; // done

  resendVerification: string = `${this._userBase}resend-verification-email`;

  uwRequiredDocs: string = `v1/application/??/required-uploads`; // done

  uwProspectStatus: string = `v1/application/??/prospect-status`;

  myMerchants: string = 'v1/my-merchants';

  myUsersMerchants: string = 'v1/users/my-merchants';

  application: string = 'v1/application';

  applicationRequiredDocs: string = '/required-uploads';

  requiredPendingDocs: string = '/required-pending-documents';

  agreementAcceptance: string = '/agreement-acceptance';

  /**
   * Dashboard Page APIS
   */
  dashboradInfo: string = `${this._merchantBase}??/dashboard`; // done

  terminalCostDashboard: string = `${this._merchantBase}??/selected-equipments`; // done
  //net volume graph
  dashboardNetVolumeGraph: `v1/metrics/charts/net-volume`;
  //net deposit graph
  dashboardNetDepositsGraph: `v1/metrics/charts/net-deposits`;
  //transactions count
  dashboardTransactionsCount: `v1/metrics/charts/settled-transactions-count`;
  //disputed volume
  dashboardDisputedVolume: `v1/metrics/charts/disputed-volume`;
}
