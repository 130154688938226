import { IOCEPaymentMethodsFormValue } from '@pages/onboarding-customization/onboarding-customization-edit/views/oce-payment-methods/oce-payment-methods-form.interface';
import { IOCEEquipmentPricingFormValue } from '@pages/onboarding-customization/onboarding-customization-edit/views/oce-equipment-pricing/oce-equipment-pricing-form.interface';
import { IOCEOnlinePhonePaymentGatewayFormValue } from '@pages/onboarding-customization/onboarding-customization-edit/views/oce-online-phone-payment-gateway/oce-online-phone-payment-gateway-form.interface';
import { IOCEMerchantAccountFormValue } from '@pages/onboarding-customization/onboarding-customization-edit/views/oce-merchant-account/oce-merchant-account-form.interface';
import { IOCEOnlinePaymentIntegrationFormValue } from '@pages/onboarding-customization/onboarding-customization-edit/views/oce-online-payment-integration/oce-online-payment-integration-form.interface';
import { IOCEShoppingCartIntegrationFormValue } from '@pages/onboarding-customization/onboarding-customization-edit/views/oce-shopping-cart-integration/oce-shopping-cart-integration-form.interface';
import { IOCEEcommerceIntegrationFormValue } from '@pages/onboarding-customization/onboarding-customization-edit/views/oce-ecommerce-integration/oce-ecommerce-integration-form.interface';
import { IOCEOtherQuestionsFormValue } from '@pages/onboarding-customization/onboarding-customization-edit/views/oce-other-questions/oce-other-questions-form.interface';

export enum EOnboardingCustomizationEditFormControlName {
  paymentMethods = 'paymentMethods',
  equipmentPricing = 'equipmentPricing',
  onlinePhonePaymentGateway = 'onlinePhonePaymentGateway',
  merchantAccount = 'merchantAccount',
  onlinePaymentIntegration = 'ecommercePluginsAndOnlinePaymentMethods',
  shoppingCartIntegration = 'shoppingCartIntegration',
  ecommerceIntegration = 'ecommerceIntegration',
  otherQuestionList = 'otherQuestionList',
}

export interface IOnboardingCustomizationEditFormValue {
  [EOnboardingCustomizationEditFormControlName.paymentMethods]?: IOCEPaymentMethodsFormValue;
  [EOnboardingCustomizationEditFormControlName.equipmentPricing]?: IOCEEquipmentPricingFormValue;
  [EOnboardingCustomizationEditFormControlName.onlinePhonePaymentGateway]?: IOCEOnlinePhonePaymentGatewayFormValue;
  [EOnboardingCustomizationEditFormControlName.merchantAccount]?: IOCEMerchantAccountFormValue;
  [EOnboardingCustomizationEditFormControlName.onlinePaymentIntegration]?: IOCEOnlinePaymentIntegrationFormValue;
  [EOnboardingCustomizationEditFormControlName.shoppingCartIntegration]?: IOCEShoppingCartIntegrationFormValue;
  [EOnboardingCustomizationEditFormControlName.ecommerceIntegration]?: IOCEEcommerceIntegrationFormValue;
  [EOnboardingCustomizationEditFormControlName.otherQuestionList]?: IOCEOtherQuestionsFormValue;
}
