import { Injectable } from '@angular/core';
import { jwtDecode } from "jwt-decode";
import { AuthSession } from "../../auth/models/auth-session.enum";
import { CookieService } from "ngx-cookie-service";
import { apmInstance } from "../../apm.config";

@Injectable({
  providedIn: "root",
})
export class ApmUserService {
  private apm = apmInstance;

  constructor(
    private cookieService: CookieService
  ) {
    this.setUserContext();
  }

  setUserContext() {
    const user = this.getDecodedAccessToken();
    this.apm?.setUserContext({
      'username': user?.['name'] || 'Unknown',
      'id': user?.['id'] || 'Unknown',
      'email': user?.['email'] || 'Unknown'
    })
  }

  getDecodedAccessToken() {
    const token = this.cookieService.get(AuthSession.isoAccessToken) || '';
    return token ? jwtDecode(token) : null
  }
}
