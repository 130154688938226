import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'amountFormatter',
})
export class AmountFormatter implements PipeTransform {
  /**
   * Constructor
   * @param decimalPipe
   */
  constructor(private decimalPipe: DecimalPipe) {}

  /**
   * Transform
   * @param value
   * @returns
   */
  transform(value: number): string {
    if (value === undefined || value === null || isNaN(value)) return;
    return `$${this.decimalPipe.transform(value, '1.2-2')}`;
  }
}
