import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GenericClientService } from '@clients/generic/generic-client.service';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import {
  CReportsDisputeListParamsDefault,
  IReportsDisputeCSVParams,
  IReportsDisputeCSVResponse,
  IReportsDisputeListParams,
  IReportsDisputeListResponse,
} from '@interfaces/reports/dispute/dispute-list.interface';
import {
  IReportsDisputeCaseDocumentListResponse,
  IReportsDisputeDetailResponse,
  IReportsDisputeDownloadCaseDocument,
} from '@interfaces/reports/dispute/dispute-detail.interface';
import { EReportsDisputeListParams } from '@parameters/reports/dispute/dispute-list.parameter';
import { GenericHelper } from '@helpers/generic.helper';

@Injectable()
export class DisputeClientService {
  constructor(private genericClient: GenericClientService) {}

  getDisputesList(
    queryParams: IReportsDisputeListParams
  ): Observable<IReportsDisputeListResponse> {
    const endpoint = EndpointsParameter.ReportsDispute;
    const params = new HttpParams()
      .set(
        EReportsDisputeListParams.size,
        queryParams[EReportsDisputeListParams.size] ||
          CReportsDisputeListParamsDefault[EReportsDisputeListParams.size]
      )
      .set(
        EReportsDisputeListParams.page,
        queryParams[EReportsDisputeListParams.page] ||
          CReportsDisputeListParamsDefault[EReportsDisputeListParams.page]
      )
      .set(
        EReportsDisputeListParams.sort,
        queryParams[EReportsDisputeListParams.sort] ||
          CReportsDisputeListParamsDefault[EReportsDisputeListParams.sort]
      )
      .set(
        EReportsDisputeListParams.search,
        queryParams[EReportsDisputeListParams.search] ||
          CReportsDisputeListParamsDefault[EReportsDisputeListParams.search]
      )
      .set(
        EReportsDisputeListParams.startDate,
        queryParams[EReportsDisputeListParams.startDate] ||
          CReportsDisputeListParamsDefault[EReportsDisputeListParams.startDate]
      )
      .set(
        EReportsDisputeListParams.endDate,
        queryParams[EReportsDisputeListParams.endDate] ||
          CReportsDisputeListParamsDefault[EReportsDisputeListParams.endDate]
      )
      .set(
        EReportsDisputeListParams.midList,
        queryParams[EReportsDisputeListParams.midList].join(',') ||
          CReportsDisputeListParamsDefault[
            EReportsDisputeListParams.midList
          ].join(',')
      )
      .set(
        EReportsDisputeListParams.binList,
        queryParams[EReportsDisputeListParams.binList].join(',') ||
          CReportsDisputeListParamsDefault[
            EReportsDisputeListParams.binList
          ].join(',')
      )
      .set(
        EReportsDisputeListParams.cardBrandList,
        queryParams[EReportsDisputeListParams.cardBrandList].join(',') ||
          CReportsDisputeListParamsDefault[
            EReportsDisputeListParams.cardBrandList
          ].join(',')
      )
      .set(
        EReportsDisputeListParams.disputeStates,
        queryParams[EReportsDisputeListParams.disputeStates]?.join(',') ||
          CReportsDisputeListParamsDefault[
            EReportsDisputeListParams.disputeStates
          ].join(',')
      )
      .set(
        EReportsDisputeListParams.searchBy,
        queryParams[EReportsDisputeListParams.searchBy]
      );
    return this.genericClient.genericGet<IReportsDisputeListResponse>(
      endpoint,
      { params }
    );
  }

  getDisputeDetail(id: string): Observable<IReportsDisputeDetailResponse> {
    const endpoint = `${EndpointsParameter.ReportsDispute}/${id}`;
    return this.genericClient.genericGet<IReportsDisputeDetailResponse>(
      endpoint
    );
  }

  downloadCSV(
    body: IReportsDisputeCSVParams
  ): Observable<IReportsDisputeCSVResponse> {
    const endpoint = EndpointsParameter.ReportsDisputesCSV;
    return this.genericClient.genericPost<IReportsDisputeCSVResponse>(
      endpoint,
      body
    );
  }

  /**
   * Get list of documents related to case number
   * @param caseNumber 
   * @returns 
   */
  getCaseDocuments(
    caseNumber: string
  ): Observable<IReportsDisputeCaseDocumentListResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.ReportsDisputeDocuments,
      caseNumber
    );
    return this.genericClient.genericGet<IReportsDisputeCaseDocumentListResponse>(
      endpoint
    );
  }

  /**
   * Dowload document related to case number
   * @param caseNumber 
   * @param docId 
   * @returns 
   */
  downloadCaseDocument(
    caseNumber: string,
    docId: string
  ): Observable<IReportsDisputeDownloadCaseDocument> {
    const endpoint = `${GenericHelper.replaceUrl(
      EndpointsParameter.ReportsDisputeDocuments,
      caseNumber
    )}/${docId}`;
    return this.genericClient.genericGet<IReportsDisputeDownloadCaseDocument>(
      endpoint
    );
  }
}
