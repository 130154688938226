import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/auth/service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfigService } from '@core/services/config.service';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { DOCUMENT } from '@angular/common';
import { CommonService } from 'src/app/shared/services/common.service';
import { CoreConfig } from '@core/types/core-config';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent implements OnInit, OnDestroy {
  @ViewChild('toggleElement') toggleElement: ElementRef;
  public username: any;
  public coreConfig: CoreConfig;
  public navigation: any;
  public brandingData: any;
  public brandCustomize: any;
  @HostBinding('class.fixed-top')
  public isFixed = false;
  imageUrl: string = environment.imgFileBaseUrl;
  @HostBinding('class.navbar-static-style-on-scroll')
  public windowScrolled = false;
  public offerTitle: boolean = false;

  // Remove .active on outside click

  @HostListener('document:click', ['$event.target'])
  onClick(target: any) {
    if (this.toggleElement && this.toggleElement.nativeElement) {
      const clickedInside = this.toggleElement.nativeElement.contains(target);
      if (!clickedInside) {
        this.closeToggle();
      }
    }
    if (document.querySelectorAll('.open').length) {
      this._elementRef.nativeElement.classList.add('removeIndex');
    } else {
      this._elementRef.nativeElement.classList.remove('removeIndex');
    }
  }

  // Add .navbar-static-style-on-scroll on scroll using HostListener & HostBinding
  @HostListener('window:scroll', [])
  public onWindowScroll() {
    if (
      (this.windowScrolled && window.pageYOffset) ||
      document.documentElement.scrollTop ||
      document.body.scrollTop < 10
    ) {
      this.windowScrolled = false;
    }
  }

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   * @param {CoreConfigService} _coreConfigService
   * @param {CoreSidebarService} _coreSidebarService
   * @param cmService
   * @param _document
   * @param cdk
   */
  constructor(
    public _router: Router,
    private _authenticationService: AuthenticationService,
    private _coreConfigService: CoreConfigService,
    private _coreSidebarService: CoreSidebarService,
    public cmService: CommonService,
    private cdr: ChangeDetectorRef,
    private _elementRef: ElementRef,
    @Inject(DOCUMENT) private _document: Document
  ) {
    if (localStorage.userName) {
      this.username = localStorage.getItem('userName');
    }
    this.cmService.currentUser.subscribe((x) => (this.username = x));

    // Set the private defaults
    this._unsubscribeAll = new Subject();
    if (localStorage.branding) {
      // this.brandingData = JSON.parse(localStorage.getItem(constants.branding));
      this.brandingData = this.cmService.brandingData;
      this.brandCustomize = this.brandingData;
    }
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    if (this._document?.querySelector('.menu-navs')) {
      document?.querySelector('.menu-navs')?.classList.remove('active');
    }
  }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebar(key): void {
    if (
      !(
        this._router.url.includes('login') ||
        this._router.url.includes('signup') ||
        this._router.url.includes('reset-password') ||
        this._router.url.includes('forgot-password')
      )
    ) {
      this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
    } else if (
      this._document?.querySelector('.menu-navs').classList.contains('active')
    ) {
      document?.querySelector('.menu-navs')?.classList.remove('active');
    } else {
      this._document?.querySelector('.menu-navs').classList.add('active');
    }
  }

  /**
   * Logout method
   */
  logout() {
    this._authenticationService.logout();
  }

  openTab(value) {
    if (value) {
      let url = value;
      window.open(url, '_blank');
    }
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to router events to detect changes in navigation
    this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        // Manually trigger change detection when router URL changes
        this.cdr.detectChanges();
      });

    // Subscribe to the config changes
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config: CoreConfig) => {
        this.coreConfig = config;
      });
  }

  closeToggle(): void {
    if (
      this._document?.querySelector('.menu-navs') &&
      this._document?.querySelector('.menu-navs').classList.contains('active')
    ) {
      document?.querySelector('.menu-navs')?.classList.remove('active');
    }
  }

  toggleOffer() {
    this.offerTitle = this._document
      ?.getElementById('sd-ofr')
      ?.classList.toggle('viewsdofr');
  }

  get hasDashboardRoute() {
    const url = this._router.url.split('?return-url')[0];
    return url.includes('dashboard');
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
