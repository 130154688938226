<gtr-modal
  [isOpen]="false"
  modalSize="small"
  [header]="'Confirm your password'"
  #confirmPasswordModal
  id="confirmPasswordModal"
  (closeModal)="closeModal()"
  [dataTransactionName]="dataTransactionName + '--payment-confirm-password'"
>
  <div slot="modal-body">
    <p>For your security, please confirm your password</p>
    <form (ngSubmit)="submitForm()" [formGroup]="confirmPasswordForm"
          [attr.data-transaction-name]="dataTransactionName + '--payment-confirm-password--form'">
      <div class="row mt-2">
        <div class="col-12">
          <gtr-input
            inputLabel="Password"
            type="password"
            formControlName="password"
            [placeholder]="'Enter Password'"
            [defaultValidation]="false"
            [isOnlyView]="false"
            [isValid]="
              !confirmPasswordForm?.controls?.password?.touched ||
              ((confirmPasswordForm?.controls?.password?.touched ||
                confirmPasswordForm?.controls?.password?.dirty) &&
                confirmPasswordForm?.controls?.password?.valid)
            "
            [dataTransactionName]="dataTransactionName + '--payment-confirm-password--password'"
          >
            <div slot="custom-error">
              <app-input-error-msg
                [defaultValidation]="false"
                [control]="confirmPasswordForm?.controls?.password"
              >
              </app-input-error-msg>
            </div>
          </gtr-input>
        </div>
      </div>
    </form>
  </div>
  <div class="d-flex flex-row mt-3" slot="modal-actions">
    <gtr-button
      variant="link"
      color="gray"
      (click)="closeModal()"
      [disabled]="isLoading"
      [dataTransactionName]="dataTransactionName + '--payment-confirm-password--close'"
      [attr.data-transaction-name]="dataTransactionName + '--payment-confirm-password--close'"
    >
      Cancel
    </gtr-button>
    <gtr-button
      [isLoading]="isLoading"
      variant="raised"
      color="primary"
      (click)="submitForm()"
      [disabled]="isLoading"
      [dataTransactionName]="dataTransactionName + '--payment-confirm-password--confirm'"
      [attr.data-transaction-name]="dataTransactionName + '--payment-confirm-password--confirm'"
    >
      Confirm
    </gtr-button>
  </div>
</gtr-modal>
