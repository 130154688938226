import { Injectable, inject } from '@angular/core';
import { PaymentAccountsClientService } from '@clients/payments/payment-accounts-client.service';
import {
  IPaymentConfirmPassword,
  IPaymentPopupEvent,
} from '@interfaces/payments/payment-account.interface';
import {
  PaymentAccessToken,
  PaymentAccount,
  PaymentAccountList,
} from '@models/payments/payment-account.model';
import { PAYMENT_POP_STATE } from '@parameters/payments/payment-account.parameter';
import { BehaviorSubject, Subject, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentAccountsStoreService {
  public paymentAccountList$ = new BehaviorSubject<PaymentAccount[]>([]);
  public paymentModalState$ = new Subject<PAYMENT_POP_STATE>();
  public paymentModalClosed$ = new Subject<IPaymentPopupEvent>();
  public paymentAccountId$ = new Subject<string>();
  public currentPaymentAccount$ = new Subject<string>();
  public refreshPaymentToken$ = new Subject<boolean>();

  public previousAccountId: string;

  private readonly filterChangeSubject = new BehaviorSubject<boolean>(false);
  private readonly _paymentAccountsClient = inject(
    PaymentAccountsClientService
  );

  private _search: string = '';
  private _page: number = 1;
  private _limit: number = 20;

  /**
   * Get accounts list
   */
  public getPaymentAccountsList() {
    const params = {
      search: this.search,
      page: this.page,
      limit: this.limit,
    };
    return this._paymentAccountsClient
      .getPaymentAccountsList(params)
      .pipe(map((res) => new PaymentAccountList(res)));
  }

  public set search(value: string) {
    this._search = value || '';
    this.page = 1;
  }

  public set page(value: number) {
    this._page = value || 1;
    this.filterChange = true;
  }

  public set limit(value: number) {
    this._limit = value || 20;
    this.page = 1;
  }

  public get search() {
    return this._search;
  }

  public get page() {
    return this._page;
  }

  public get limit() {
    return this._limit;
  }

  public set filterChange(state: boolean) {
    this.filterChangeSubject.next(state);
  }

  public get filterChange$() {
    return this.filterChangeSubject.asObservable();
  }

  public get paymentAccountsList() {
    return this.paymentAccountList$.asObservable();
  }

  public authenticatePaymentAccount(accountId: string) {
    return this._paymentAccountsClient
      .authenticatePaymentAccount(accountId)
      .pipe(map((response) => new PaymentAccessToken(response?.data)));
  }

  public confirmPaymentPassword(body: IPaymentConfirmPassword) {
    return this._paymentAccountsClient.getPaymentAuthToken(body);
  }

  public defaultValues() {
    this._search = '';
    this._page = 1;
    this._limit = 20;
  }
}
