import { NgModule } from '@angular/core';

import { SafePipe } from '@core/pipes/safe.pipe';
import { CipherTextPipe } from './mask-string.pipe';
import { FileExtension } from './file-extension.pipe';

@NgModule({
  declarations: [SafePipe, CipherTextPipe, FileExtension],
  imports: [],
  exports: [SafePipe, CipherTextPipe, FileExtension],
})
export class CorePipesModule {}
