export const enum EProspectsUploadParams {
  limit = 'limit',
  page = 'page',
  sort = 'order_by',
  search = 'search',
}

export const enum EProspectsUploadParamSort {
  documentNameAsc = 'file_name:asc',
  documentNameDesc = 'file_name:desc',
  documentTypeAsc = 'documentType:asc',
  documentTypeDesc = 'documentType:desc',
  uploadedDateAsc = 'created_at:asc',
  uploadedDateDesc = 'created_at:desc',
}

export const CProspectsUploadParams = {
  [EProspectsUploadParams.limit]: 10,
  [EProspectsUploadParams.page]: 1,
  [EProspectsUploadParams.sort]: [],
  [EProspectsUploadParams.search]: '',
};
