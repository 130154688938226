import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

import { GenericClientService } from '@clients/generic/generic-client.service';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';

import {
  IEntityRelationshipListResponse,
  IEntityRelationshipListParams,
  CEntityRelationshipParamsDefault,
} from '@interfaces/entity/relationship/entity-relationship-list.interface';
import { EEntityRelationshipListParams } from '@parameters/entity/relationship/entity-relationship-list.parameter';

@Injectable()
export class EntityRelationshipClientService {
  private endpoint: string = EndpointsParameter.EntityRelationshipList;
  constructor(private genericClient: GenericClientService) {}

  /**
   * Get entity relationship list
   * @param queryParams
   * @returns <IEnityRelationshipListResponse> respose from server.
   */
  getRelationshipList(
    queryParams: IEntityRelationshipListParams
  ): Observable<IEntityRelationshipListResponse> {
    let httpParams = new HttpParams()
      .set(
        EEntityRelationshipListParams.size,
        queryParams[EEntityRelationshipListParams.size] ||
          CEntityRelationshipParamsDefault[EEntityRelationshipListParams.size]
      )
      .set(
        EEntityRelationshipListParams.page,
        queryParams[EEntityRelationshipListParams.page] ||
          CEntityRelationshipParamsDefault[EEntityRelationshipListParams.page]
      )
      .set(
        EEntityRelationshipListParams.sort,
        queryParams[EEntityRelationshipListParams.sort] ||
          CEntityRelationshipParamsDefault[EEntityRelationshipListParams.sort]
      )
      .set(
        EEntityRelationshipListParams.search,
        queryParams[EEntityRelationshipListParams.search] ||
          CEntityRelationshipParamsDefault[EEntityRelationshipListParams.search]
      )
      .set(
        EEntityRelationshipListParams.relationshipTypes,
        queryParams[EEntityRelationshipListParams.relationshipTypes].join(
          ','
        ) || ''
      )
      .set(
        EEntityRelationshipListParams.enabled,
        queryParams[EEntityRelationshipListParams.enabled] || null
      )
      .set(
        EEntityRelationshipListParams.entityId,
        queryParams[EEntityRelationshipListParams.entityId] || ''
      );

    if (queryParams[EEntityRelationshipListParams.isDisabled]) {
      httpParams = httpParams.append(
        EEntityRelationshipListParams.isDisabled,
        queryParams[EEntityRelationshipListParams.isDisabled] ? false : null
      );
    }

    return this.genericClient.genericGet<IEntityRelationshipListResponse>(
      this.endpoint,
      { params: httpParams }
    );
  }
}
