export const enum EFPBillingSettingListParams {
  size = 'limit',
  page = 'page',
  sort = 'order_by',
  search = 'search',
}

export const enum EFPBillingSettingListSort {
  asc = 'asc',
  desc = 'desc',
  statusAsc = 'status',
  createdOn = 'created_on',
}

export const CFPBillingSettingListParamsDefault = {
  [EFPBillingSettingListParams.size]: 10,
  [EFPBillingSettingListParams.page]: 1,
  [EFPBillingSettingListParams.sort]: [
    EFPBillingSettingListSort.createdOn,
    EFPBillingSettingListSort.desc,
  ],
  [EFPBillingSettingListParams.search]: '',
};
