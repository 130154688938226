import { IAccountsListParams } from '@interfaces/accounts/accounts-list.interface';

export enum EAccountDetailTabs {
  associatedMerchant = 'associatedMerchant',
  gatewayConfigurations = 'gatewayConfigurations',
}

export enum EAccountsListParams {
  search = 'search',
  mids = 'mids',
  status = 'is_payments_enabled',
  sort = 'order_by',
  page = 'page',
  size = 'limit',
}

export const CAccountsListParams: IAccountsListParams = {
  [EAccountsListParams.mids]: [],
  [EAccountsListParams.search]: '',
  [EAccountsListParams.status]: null,
  [EAccountsListParams.sort]: [],
  [EAccountsListParams.page]: 1,
  [EAccountsListParams.size]: 10,
};

export enum EAccountsListSortKeys {
  accountNameAsc = 'accountName:asc',
  accountNameDesc = 'accountName:desc',
}

export enum EGatewayAccountStatus {
  disabled = 'disabled',
  enabled = 'enabled',
}

export const CGatewayAccountStatus = {
  [EGatewayAccountStatus.disabled]: 'Disabled',
  [EGatewayAccountStatus.enabled]: 'Enabled',
};

export const CGatewayAccountColor = {
  [EGatewayAccountStatus.disabled]: 'dark',
  [EGatewayAccountStatus.enabled]: 'success',
};

export const CGatewayAccountStatusOptions = [
  {
    value: EGatewayAccountStatus.enabled,
    label: CGatewayAccountStatus[EGatewayAccountStatus.enabled],
  },
  {
    value: EGatewayAccountStatus.disabled,
    label: CGatewayAccountStatus[EGatewayAccountStatus.disabled],
  },
];

export enum EAccountsListTableLabel {
  accountName = 'account name',
  relatedMids = 'related mids',
  gatewayStatus = 'gateway status',
  actions = 'actions',
}
