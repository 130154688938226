import { APP_ROUTES } from './routes.enum';

export const constants = {
  rememberme: 'rememberme',
  business_id: 'businessId',
  offer_id: 'offerId',
  branding: 'branding',
  cat_id: 'categoryId',
  is_eligible: 'is_eligible',
  alphabets_with_space: /^(?:[a-zA-Z\s]+)?$/,
  taking_payment_url: 'takingPaymentUrl',
  only_alphabet_regex: /^[A-Za-z ]+$/,
  email_regex: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$',
  password_regex:
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$!%*?&^])(?=.*[a-zA-Z]).{8,}$/,
  phone_regex:
    /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/g,
  alt_phone_number_regex:
    '/^({0,1}[2-9]{1}[0-9]{2}){1} {1}[2-9]{1}[0-9]{2}-{0,1}[0-9]{0,4}$/',
  zip_code: /^\d+$/,
  alpha_numeric: /^[-\w .()&]+$/,
  only_numeric: '^[0-9]*$',
  //min lengths starts
  phone_minlength: 14,
  zipcode_length: 5,
  zipcode_max_length: 5,
  ssn_minlength: 10,
  explanation_limit: 100,
  product_services_min_length: 200,
  years_count: 50,

  //min lengths ends
  totalSteps: 27,
  current_progress_step: 'currentStep',
  current_route: 'currentRoute',
  ownership_length: 1,
  ownership_max_length: 3,
  onboarding_accepts_payment_routes: [
    APP_ROUTES.select_hardware,
    APP_ROUTES.payment_gateway,
    APP_ROUTES.taking_payments,
  ],
  onboarding_taking_payments_online: [
    APP_ROUTES.shoping_cart,
    APP_ROUTES.ecommerce_platform,
  ],
  onboarding_skip_input_routes: [APP_ROUTES.general_info],
};

export const selectOptions: any = {
  yesNo: [
    { label: 'Yes', value: 'true' },
    { label: 'No', value: 'false' },
  ],
  merchantType: [
    { label: 'Direct', value: 1 },
    { label: 'Sub-merchant', value: 2 },
    { label: 'Payfac', value: 3 },
  ],
  ownershipType: [
    { label: 'Sole Prop', value: 1 },
    { label: 'Non-Profit', value: 2 },
    { label: 'Partnership', value: 3 },
    { label: 'Government', value: 4 },
    { label: 'Corp', value: 5 },
    { label: 'LLC', value: 6 },
    { label: 'Other', value: 7 },
  ],
  settleDelay: [
    { label: 'Standard (2 bank days)', value: 'bdays02' },
    { label: '3 bank days', value: 'bdays03' },
    { label: '4 bank days', value: 'bdays04' },
    { label: '5 bank days', value: 'bdays05' },
    { label: '6 bank days', value: 'bdays06' },
    { label: '7 bank days', value: 'bdays07' },
    { label: '8 bank days', value: 'bdays08' },
    { label: '9 bank days', value: 'bdays09' },
    { label: '10 bank days', value: 'bdays10' },
    { label: '11 bank days', value: 'bdays11' },
    { label: '12 bank days', value: 'bdays12' },
    { label: '13 bank days', value: 'bdays13' },
    { label: '14 bank days', value: 'bdays14' },
    { label: '15 bank days', value: 'bdays15' },
    { label: '16 bank days', value: 'bdays16' },
    { label: '17 bank days', value: 'bdays17' },
    { label: '18 bank days', value: 'bdays18' },
    { label: '19 bank days', value: 'bdays19' },
    { label: '20 bank days', value: 'bdays20' },
    { label: '21 bank days', value: 'bdays21' },
    { label: '22 bank days', value: 'bdays22' },
    { label: '23 bank days', value: 'bdays23' },
    { label: '24 bank days', value: 'bdays24' },
    { label: '25 bank days', value: 'bdays25' },
    { label: '26 bank days', value: 'bdays26' },
    { label: '27 bank days', value: 'bdays27' },
    { label: '28 bank days', value: 'bdays28' },
    { label: '29 bank days', value: 'bdays29' },
    { label: '30 bank days', value: 'bdays30' },
  ],

  bankAccountOwnershipCategory: [
    { label: 'Business', value: 1 },
    { label: 'Personal', value: 2 },
  ],
  bankAccountType: [
    { label: 'Checking', value: 1 },
    { label: 'Savings', value: 2 },
  ],
  merchantStatus: [
    { label: 'Closed', value: 12 },
    { label: 'Boarded', value: 13 },
    { label: 'Underwriting', value: 2 },
    { label: 'Sent to Bank', value: 3 },
    { label: 'Pended', value: 4 },
    { label: 'Approved', value: 5 },
    { label: 'Audited', value: 6 },
    { label: 'Declined', value: 7 },
    { label: 'Deployment', value: 8 },
    { label: 'Complete', value: 9 },
    { label: 'Active', value: 10 },
    { label: 'Suspend', value: 11 },
    { label: 'Withdrawn', value: 14 },
    { label: 'WIP', value: 1 },
  ],

  merchantBackEndType: [
    { label: 'TSYS', value: 1 },
    { label: 'GETTRX', value: 2 },
  ],
  merchantBankType: [
    { label: 'Esquire', value: 2 },
    { label: 'Merrick', value: 1 },
    { label: 'First Fresno', value: 3 },
  ],
  rollingReserve: [
    { label: 'Capped Reserve', value: 'cappedReserve' },
    { label: 'Rolling 180 days', value: 'bdays01' },
  ],
};
