import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appTimeMask]',
})
export class TimeInputMaskDirective {
  constructor(private el: ElementRef) {}
  @HostListener('input', ['$event.target.value'])
  public onInput(value: string): void {
    const cleanedValue = this.cleanInput(value);
    const formattedValue = this.formatInput(cleanedValue);
    this.el.nativeElement.value = formattedValue;
  }

  private cleanInput(value: string): string {
    // Remove any non-digit characters and limit to 6 digits
    return value.replace(/[^\d]/g, '').slice(0, 6);
  }

  private formatInput(value: string): string {
    if (value.length === 0) {
      return '';
    }

    // Format as HH:MM:SS
    const hours = value.slice(0, 2);
    const minutes = value.slice(2, 4);
    const seconds = value.slice(4, 6);

    let formattedValue = hours;
    if (minutes) {
      formattedValue += ':' + minutes;
    }
    if (seconds) {
      formattedValue += ':' + seconds;
    }

    return formattedValue;
  }
}
