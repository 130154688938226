import {
  IResponseBase,
  IResponseData,
} from '@interfaces/generic/responses.interface';
import { EMerchantStatus } from '@parameters/merchant/merchant/merchant.parameter';
import {
  CMerchantListParamsVolDate,
  EMerchantListParams,
  EMerchantListSort,
} from '@parameters/merchant/merchant/merchant-list.parameter';
import { IMerchant } from '@interfaces/merchant/merchant.interface';
import { EVolumeAs } from '@parameters/filter/volume-as.parameter';

export interface IMerchantListResponseOther {
  totalMerchants: number;
  totalActiveMerchants: number;
}

export interface IMerchantListResponseData<T> extends IResponseData<T> {
  other: IMerchantListResponseOther;
}

export interface IMerchantListResponse
  extends IResponseBase<IMerchantListResponseData<IMerchant>> {}

export interface IMerchantListParams {
  [EMerchantListParams.sort]: EMerchantListSort[];
  [EMerchantListParams.page]: number;
  [EMerchantListParams.size]: number;
  [EMerchantListParams.search]: string;
  [EMerchantListParams.status]: EMerchantStatus[];
  [EMerchantListParams.volumeAs]: EVolumeAs;
  [EMerchantListParams.volStartDate]: string;
  [EMerchantListParams.volEndDate]: string;
  [EMerchantListParams.approvedStartDate]: string;
  [EMerchantListParams.approvedEndDate]: string;
  [EMerchantListParams.closedStartDate]: string;
  [EMerchantListParams.closedEndDate]: string;
  [EMerchantListParams.mids]: number[];
  [EMerchantListParams.relationshipIds]: string[];
}

export const CMerchantListParamsDefault: IMerchantListParams = {
  [EMerchantListParams.sort]: [EMerchantListSort.dbaNameAsc],
  [EMerchantListParams.page]: 1,
  [EMerchantListParams.size]: 10,
  [EMerchantListParams.search]: '',
  [EMerchantListParams.status]: [],
  [EMerchantListParams.volumeAs]: EVolumeAs.calendar,
  [EMerchantListParams.volStartDate]:
    CMerchantListParamsVolDate.value.startDate,
  [EMerchantListParams.volEndDate]: CMerchantListParamsVolDate.value.endDate,
  [EMerchantListParams.approvedStartDate]: null,
  [EMerchantListParams.approvedEndDate]: null,
  [EMerchantListParams.closedStartDate]: null,
  [EMerchantListParams.closedEndDate]: null,
  [EMerchantListParams.mids]: [],
  [EMerchantListParams.relationshipIds]: [],
};
