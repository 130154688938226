export abstract class GlobalConstantRoutes {
  /** Entity */
  static readonly ENTITY = 'entity';
  static readonly ENTITY_LOGIN = `/${GlobalConstantRoutes.ENTITY}/login`;
  static readonly ENTITY_FORGOT_PASSWORD = `/${GlobalConstantRoutes.ENTITY}/forgot-password`;
  static readonly ENTITY_RESET_PASSWORD = `/${GlobalConstantRoutes.ENTITY}/reset-password`;
  /** MERCHANT */
  static readonly MERCHANT = 'merchant';
  static readonly MERCHANT_LOGIN = `/login`;
  static readonly MERCHANT_FORGOT_PASSWORD = `/forgot-password`;
  static readonly MERCHANT_RESET_PASSWORD = `/reset-password`;
  static MERCHANT_DASHBOARD = 'dashboard/main';
  /** MERCHANT MULTI SIGNATURE PRINCIPALS */
  static readonly MERCHANT_APPLICATION_PROGRESS =
    '/merchant-application-progress';

  static merchantDashboardWithId(merchantId?: string): string {
    return this.MERCHANT_DASHBOARD;
  }
}
