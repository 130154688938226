import {
  CDateFilterListValue,
  EDateFilter,
} from '@parameters/filter/date.parameter';

export const enum EMetricsKPIsDisputeParams {
  startDate = 'start_date',
  endDate = 'end_date',
  midList = 'mids',
  binList = 'bins',
}

export const CMetricsKPIsDisputeParamsDate =
  CDateFilterListValue[EDateFilter.today];
