import { Injectable } from '@angular/core';
import { GenericClientService } from '@clients/generic/generic-client.service';
import { GenericHelper } from '@helpers/generic.helper';

import { IResponseBase } from '@interfaces/generic/responses.interface';

import { IDealUploadRequiredDocument } from '@interfaces/ops-and-uw-tools/deal/deal-upload.interface';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';

import { Observable } from 'rxjs';

@Injectable()
export class ApplicationClientService {
  constructor(private genericClient: GenericClientService) {}

  public uploadRequiredDocuments(
    docs: IDealUploadRequiredDocument,
    merchantId: string,
    requestId: string
  ): Observable<IResponseBase<void>> {
    let endpoint = '';
    if (requestId) {
      endpoint = GenericHelper.replaceUrl(
        EndpointsParameter.NewApplicationUploadDocuments,
        merchantId
      )?.replace('???', requestId);
    } else {
      endpoint = GenericHelper.replaceUrl(
        EndpointsParameter.ApplicationUploadDocuments,
        merchantId
      );
    }

    return this.genericClient.genericPost<IResponseBase<void>>(endpoint, docs);
  }
}
