import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[formControlName][appEinMask]',
})
export class EinMaskDirective {
  constructor(public ngControl: NgControl) {}

  @HostListener('inputChanged', ['$event'])
  public onModelChange({ detail }: CustomEvent) {
    if (detail?.value) {
      this.onInputChange(detail?.value, false);
    }
  }

  @HostListener('keydown.backspace', ['$event'])
  public keydownBackspace(event) {
    if (event) {
      this.onInputChange(event.target.value, true);
    }
  }

  onInputChange(event, backspace) {
    let newVal = event ? event?.replace(/\D/g, '') : '';

    if (backspace && newVal.length <= 2) {
      newVal = newVal.substring(0, newVal.length - 1);
    }

    if (newVal.length === 0) {
      newVal = '';
    } else if (newVal.length <= 2) {
      newVal = newVal.replace(/^(\d{0,2})/, '$1-');
    } else {
      newVal = newVal.substring(0, 10);
      newVal = newVal.replace(/^(\d{0,2})(\d{0,7})/, '$1-$2');
    }

    this.ngControl.valueAccessor.writeValue(newVal || '');
  }
}
