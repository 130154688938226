import { apiList } from '@helpers/apilist';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from 'src/app/auth/service';
import { CommonService } from './common.service';
import { NavigationEnd, Router } from '@angular/router';
import { replaceRouteParams } from '@helpers/functions';
// import { EMerchantApplicationIdUrl } from '@parameters/authentication/msp-merchant-application-list.parameter';
import { ApiEndpointHelper } from '@helpers/api-endpoints.helper';
import { ProxyGateway } from '@parameters/http/api-proxy-gateway';

@Injectable({
  providedIn: 'root',
})
export class HttpsService {
  baseUrl = ProxyGateway.loadBaseApiUrl();
  accessAuthToken: any;
  authentication: string;
  submitted = new BehaviorSubject(false);
  promoItems = new BehaviorSubject('');
  idAddedorUpdatedOutlet: boolean = false;
  private _merchantId: string = '';
  set merchantId(id: string) {
    this._merchantId = id;
  }
  get merchantId(): string {
    return this._merchantId;
  }
  constructor(
    private http: HttpClient,
    private apiList: apiList,
    private router: Router,
    private auth: AuthenticationService,
    private cmService: CommonService
  ) {
    this.merchantId = ApiEndpointHelper.setApiUrl(this.router);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.merchantId = ApiEndpointHelper.setApiUrl(this.router);
      }
    });
  }

  // private setApiUrl() {
  //   const currentRoute = this.router.routerState.snapshot.root;
  //   const segments = this.getRouteSegments(currentRoute);
  //   if (segments[1]?.split('/')[1]?.includes(`:${EMerchantApplicationIdUrl.merchantApplicationId}`)) {
  //     this.baseUrl = environment.apiEntityUrl;
  //     const id = this.router.routerState?.snapshot?.url?.split('/')[2]
  //     this.merchantId = id;
  //   }
  // }
  // private getRouteSegments(route: any): string[] {
  //   const segments = [];
  //   while (route) {
  //     if (route.routeConfig && route.routeConfig.path) {
  //       segments.unshift(route.routeConfig.path);
  //     }
  //     route = route.firstChild;
  //   }
  //   return segments;
  // }

  getApiUrl(): string {
    return this.baseUrl;
  }

  httpPost(url, param, captcha?: string) {
    return this.http.post(
      this.baseUrl + this.appendMerchantIdOnlyForMerchantApi(url),
      param,
      this.header(captcha)
    );
  }

  httpPostWithUrl(url, param) {
    return this.http.post(url, param);
  }

  httpPatchWithUrl(url, param) {
    return this.http.patch(url, param);
  }

  httpPostWithoutHeaders(url, param, headers?: HttpHeaders) {
    return this.http.post(
      this.baseUrl + this.appendMerchantIdOnlyForMerchantApi(url),
      param,
      { headers }
    );
  }

  httpPostWithoutHeadersEntity(url, param, headers?: HttpHeaders) {
    return this.http.post(
      this.baseUrl + this.appendMerchantIdOnlyForMerchantApi(url),
      param,
      {
        observe: 'body',
        headers,
      }
    );
  }

  httpPut(url, param) {
    return this.http.put(
      this.baseUrl + this.appendMerchantIdOnlyForMerchantApi(url),
      param,
      this.header()
    );
  }

  httpPatch(url, param) {
    return this.http.patch(
      this.baseUrl + this.appendMerchantIdOnlyForMerchantApi(url),
      param,
      this.header()
    );
  }

  httpPostWithId(url, param, id) {
    return this.http.post(
      this.baseUrl + this.appendMerchantIdOnlyForMerchantApi(url) + '/' + id,
      param,
      this.header()
    );
  }
  httpDeleteWithId(url, id) {
    return this.http.delete(
      this.baseUrl + this.appendMerchantIdOnlyForMerchantApi(url) + '/' + id,
      this.header()
    );
  }

  httpDeleteWithUrl(url) {
    return this.http.delete(url, this.header());
  }

  httpPostWithFormDataAndId(url, param, id) {
    return this.http.post(
      this.baseUrl + this.appendMerchantIdOnlyForMerchantApi(url) + '/' + id,
      param,
      this.formDataHeader()
    );
  }

  httpPostWithFormDataAndObject(url: string, param: any, object: any) {
    const path: string = this.appendMerchantIdOnlyForMerchantApi(url)?.split(
      '/'
    ) as unknown as string;
    const urlReplaced = replaceRouteParams(path, object);

    return this.http.post(
      this.baseUrl + urlReplaced,
      param,
      this.formDataHeader()
    );
  }

  httpPutWithFormDataAndId(url, param, id) {
    return this.http.put(
      this.baseUrl + this.appendMerchantIdOnlyForMerchantApi(url) + '/' + id,
      param,
      this.formDataHeader()
    );
  }

  httpPutWithDataAndId(url, id, param) {
    return this.http.put(
      this.baseUrl + this.appendMerchantIdOnlyForMerchantApi(url) + '/' + id,
      param,
      this.header()
    );
  }

  httpPutWithQueryAndId(url, id, param) {
    return this.http.put(
      this.baseUrl +
        this.appendMerchantIdOnlyForMerchantApi(url) +
        '/' +
        id +
        '?' +
        param,
      null,
      this.header()
    );
  }

  httpPatchWithDataAndId(url, id, param) {
    return this.http.patch(
      this.baseUrl + this.appendMerchantIdOnlyForMerchantApi(url) + '/' + id,
      param,
      this.header()
    );
  }

  httpPostWithFormData(url, param) {
    return this.http.post(
      this.baseUrl + this.appendMerchantIdOnlyForMerchantApi(url),
      param,
      this.formDataHeader()
    );
  }

  httpPostWithFormDataWithSubUrlAndId(url, subUrl, param, id) {
    return this.http.post(
      this.baseUrl + this.apiList[url] + '/' + id + this.apiList[subUrl],
      param,
      this.formDataHeader()
    );
  }

  httpPostWithFormDataAndIds(url, param, id, id1) {
    return this.http.post(
      this.baseUrl +
        this.appendMerchantIdOnlyForMerchantApi(url) +
        '/' +
        id +
        '/' +
        id1,
      param,
      this.formDataHeader()
    );
  }

  httpPostWithOnlyId(url, id) {
    return this.http.post(
      this.baseUrl + this.appendMerchantIdOnlyForMerchantApi(url) + '/' + id,
      '',
      this.header()
    );
  }

  httpGetLocal(url) {
    return this.http.get(url).toPromise();
  }

  httpGetWithQuery(url, param) {
    return this.http.get(
      this.baseUrl + this.appendMerchantIdOnlyForMerchantApi(url) + '?' + param,
      this.header()
    );
  }

  httpGetWithIdAndQuery(url, id, param) {
    return this.http.get(
      this.baseUrl +
        this.appendMerchantIdOnlyForMerchantApi(url) +
        '/' +
        id +
        '?' +
        param,
      this.header()
    );
  }

  httpGetWithQ(url, param) {
    return this.http.get(
      this.baseUrl + this.appendMerchantIdOnlyForMerchantApi(url) + '?' + param
    );
  }

  httpGetWithId(url, id) {
    return this.http.get(
      this.baseUrl + this.appendMerchantIdOnlyForMerchantApi(url) + '/' + id,
      this.header()
    );
  }

  httpGetWithIdUrl(subUrl, url, id) {
    return this.http.get(
      this.baseUrl + this.apiList[subUrl] + '/' + id + this.apiList[url],
      this.header()
    );
  }

  httpGet(url) {
    return this.http.get(
      this.baseUrl + this.appendMerchantIdOnlyForMerchantApi(url),
      this.header()
    );
  }

  httpGetWithoutHeaders(url) {
    return this.http.get(
      this.baseUrl + this.appendMerchantIdOnlyForMerchantApi(url)
    );
  }

  header(captcha?: string) {
    if (navigator.onLine) {
      if (this.auth.authToken) {
        let headers = new HttpHeaders({
          'cache-control': 'no-cache',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.auth.authToken}`,
          // "x-token": this.auth.authToken,
          // 'ngrok-skip-browser-warning': 'true',
          captcha: captcha || '',
        });
        if (!captcha) {
          headers.delete('captcha');
        }
        const option = {
          headers,
        };
        return option;
      }
    } else {
      this.cmService.warning('Please check you internet connection !');
    }
  }

  formDataHeader() {
    if (this.auth.authToken) {
      const headers = new HttpHeaders({
        'cache-control': 'no-cache',
        mimeType: 'multipart/form-data',
        Authorization: `Bearer ${this.auth.authToken}`,
      });
      const option = {
        headers,
      };
      return option;
    }
  }

  multiPartHeader() {
    if (this.auth.authToken) {
      const headers = new HttpHeaders({
        'cache-control': 'no-cache',
        mimeType: 'multipart/form-data',
        Authorization: `Bearer ${this.auth.authToken}`,
      });
      const option = {
        headers,
      };
      return option;
    }
  }

  appendMerchantIdOnlyForMerchantApi(url: string): string {
    const apiUrl: string = this.apiList[url];
    const merchantId = this.auth.currentSelectedMerchantId;
    const urlWithId = apiUrl.replace('??', this.merchantId || merchantId || '');
    try {
      if (
        apiUrl.includes('merchants') ||
        apiUrl.includes('application') ||
        apiUrl.includes('core')
      ) {
        /**
         * comment for test temp api
         */
        // this.apiList._merchantBase = urlWithId;
        // return (this.apiList._merchantBase || apiUrl || '') as string;

        return (urlWithId || apiUrl || '') as string;
      } else {
        return (apiUrl || '') as string;
      }
    } catch (err) {}
  }
}
