import {
  IResponseBase,
  IResponseData,
} from '@interfaces/generic/responses.interface';
import {
  CReportsVolumeListParamsDate,
  EReportsVolumeCSVParams,
  EReportsVolumeListParams,
  EReportsVolumeListSort,
} from '@parameters/reports/volume/volume-list.parameter';
import { IReportsVolume } from '@interfaces/reports/volume/volume.interface';
import { ECardBrand } from '@parameters/filter/card-brand.parameter';

export interface IReportsVolumeListParams {
  [EReportsVolumeListParams.size]: number;
  [EReportsVolumeListParams.page]: number;
  [EReportsVolumeListParams.sort]: string;
  [EReportsVolumeListParams.search]: string;
  [EReportsVolumeListParams.startDate]: string;
  [EReportsVolumeListParams.endDate]: string;
  [EReportsVolumeListParams.midList]: string[];
  [EReportsVolumeListParams.binList]: number[];
  [EReportsVolumeListParams.cardBrandList]: ECardBrand[];
}

export const CReportsVolumeListParamsDefault: IReportsVolumeListParams = {
  [EReportsVolumeListParams.size]: 10,
  [EReportsVolumeListParams.page]: 1,
  [EReportsVolumeListParams.sort]: EReportsVolumeListSort.createdDateDesc,
  [EReportsVolumeListParams.search]: '',
  [EReportsVolumeListParams.startDate]:
    CReportsVolumeListParamsDate.value.startDate,
  [EReportsVolumeListParams.endDate]:
    CReportsVolumeListParamsDate.value.endDate,
  [EReportsVolumeListParams.midList]: [],
  [EReportsVolumeListParams.binList]: [],
  [EReportsVolumeListParams.cardBrandList]: [],
};

export interface IReportsVolumeListResponse
  extends IResponseBase<IResponseData<IReportsVolume>> {}

export interface IReportsVolumeCSVParams {
  [EReportsVolumeCSVParams.search]: string;
  [EReportsVolumeCSVParams.startDate]: string;
  [EReportsVolumeCSVParams.endDate]: string;
  [EReportsVolumeCSVParams.midList]: string[];
  [EReportsVolumeCSVParams.binList]: string[];
  [EReportsVolumeCSVParams.cardBrandList]: ECardBrand[];
}

export interface IReportsVolumeCSVResponse
  extends IResponseBase<{ url: string }> {}

export enum CARD_TYPE {
  VISA = 'visa',
  MASTER_CARD = 'mastercard',
  DISCOVER = 'discover',
  JCB = 'jcb',
  AMEX = 'amex',
  PIN_DEBIT = 'pin_debit',
  EBT = 'ebt',
  OTHER = 'other',
}

export const CardIcons = {
  [CARD_TYPE.VISA]: 'assets/images/visa.svg',
  [CARD_TYPE.MASTER_CARD]: 'assets/images/mastercard.svg',
  [CARD_TYPE.DISCOVER]: 'assets/images/disc.svg',
  [CARD_TYPE.JCB]: 'assets/images/jcb.svg',
  [CARD_TYPE.AMEX]: 'assets/images/amex.png',
  [CARD_TYPE.PIN_DEBIT]: 'assets/images/pin.svg',
  [CARD_TYPE.EBT]: 'assets/images/EBT.svg',
  [CARD_TYPE.OTHER]: 'assets/images/question.svg',
};
