import { Injectable } from '@angular/core';
import { GenericClientService } from '@clients/generic/generic-client.service';
import { GenericHelper } from '@helpers/generic.helper';
import { IAgreementExpressSubmitResponse } from '@interfaces/ops-and-uw-tools/agreement-express/agreement-express-submit.interface';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import {
  EImportDealFromSFRequest,
  EImportMerchantFromSFRequest,
  ESubmitToAexRequest,
} from '@parameters/ops-and-uw-tools/agreement-express/agreement-express.parameter';

@Injectable()
export class AgreementExpressClientService {
  constructor(private genericClient: GenericClientService) {}

  submitToAex(request: ESubmitToAexRequest) {
    const endpoint = EndpointsParameter.SubmitToAex;
    return this.genericClient.genericPost<IAgreementExpressSubmitResponse>(
      endpoint,
      request
    );
  }

  importMerchantFromSF(request: EImportMerchantFromSFRequest, mid: string) {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.ImportMerchantFromSF,
      mid
    );

    return this.genericClient.genericPost<any>(endpoint, request);
  }

  importDealFromSF(request: EImportDealFromSFRequest, mid: string) {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.ImportDealFromSF,
      mid
    );

    return this.genericClient.genericPost<any>(endpoint, request);
  }
}
