import {
  IResponseBase,
  IResponseData,
} from '@interfaces/generic/responses.interface';
import { IUserAssociate } from './user-associate.interface';
import { EUserDetailListParams } from '@parameters/ops-and-uw-tools/users/user-detail-list.parameter';

export interface IUserDetailListParams {
  [EUserDetailListParams.page]: number;
  [EUserDetailListParams.size]: number;
  [EUserDetailListParams.search]: string;
  [EUserDetailListParams.sort]: string[];
}

export const CUserDetailListParamsDefault: IUserDetailListParams = {
  [EUserDetailListParams.page]: 1,
  [EUserDetailListParams.size]: 10,
  [EUserDetailListParams.search]: '',
  [EUserDetailListParams.sort]: [],
};

export interface IUserDetailListResponse
  extends IResponseBase<IResponseData<IUserAssociate>> {}
