import {
  IAccountGatewayConfiguration,
  IAccountGatewayConfigurationResponse,
} from '@interfaces/accounts/account-gateway-configurations.interface';
import {
  EConfigType,
  EPaymentMethodType,
} from '@parameters/accounts/account-gateway-config.parameter';

export class AccountGatewayConfig {
  public configId: string;
  public configPm: EPaymentMethodType;
  public configType: EConfigType;
  public defaultConfig: boolean;
  public descriptorPrefix: string;
  public mid: string;
  public tid: string;
  public payfacMid: string;

  constructor(iGatewayConfig: IAccountGatewayConfiguration) {
    this.configId = iGatewayConfig?.configId;
    this.configPm = iGatewayConfig?.configPm;
    this.configType = iGatewayConfig?.configType;
    this.defaultConfig = iGatewayConfig?.defaultConfig;
    this.descriptorPrefix = iGatewayConfig?.descriptorPrefix;
    this.mid = iGatewayConfig?.mid;
    this.tid = iGatewayConfig?.tid;
    this.payfacMid = iGatewayConfig?.payfacMid;
  }
}

export class AccountGatewayConfigResponse {
  public list: AccountGatewayConfig[];

  constructor(iGatewayConfigResponse: IAccountGatewayConfigurationResponse) {
    this.list = iGatewayConfigResponse?.data?.configurations?.length
      ? iGatewayConfigResponse?.data?.configurations?.map(
          (gateway) => new AccountGatewayConfig(gateway)
        )
      : [];
  }
}
