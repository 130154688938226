import { Injectable, inject } from '@angular/core';
import { IAccountGatewayConfigForm } from '@interfaces/accounts/account-gateway-configurations.interface';
import { AccountsImplementService } from './accounts-implement.service';

@Injectable()
export class AccountGatewayConfigStoreService {
  private readonly _accountsImplement = inject(AccountsImplementService);

  /**
   * Get Configurations list
   * @param accountId
   * @returns
   */
  public getGatewayConfiguration(accountId: string) {
    return this._accountsImplement.getGatewayConfiguration(accountId);
  }

  /**
   * Add Gateway config
   * @param payload
   * @param accountId
   * @returns
   */
  public addGatewayConfiguration(
    payload: IAccountGatewayConfigForm,
    accountId: string
  ) {
    return this._accountsImplement.addGatewayConfiguration(payload, accountId);
  }

  /**
   * Update gateway config
   * @param payload
   * @param accountId
   * @param gatewayId
   * @returns
   */
  public updateGatewayConfiguration(
    payload: IAccountGatewayConfigForm,
    gatewayId: string,
    accountId: string
  ) {
    return this._accountsImplement.updateGatewayConfiguration(
      payload,
      gatewayId,
      accountId
    );
  }
}
