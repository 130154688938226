import { Injectable } from '@angular/core';
import { PORTAL } from '@parameters/routing/portal.parameter';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PortalStoreService {
  private _type: PORTAL;
  private typeSubject: Subject<PORTAL> = new Subject<PORTAL>();

  set type(value: PORTAL) {
    this._type = value;
    this.typeSubject.next(value);
  }

  get type() {
    return this._type;
  }

  get type$(): Observable<PORTAL> {
    return this.typeSubject.asObservable();
  }
}
