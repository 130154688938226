import { Injectable } from '@angular/core';
import { AccountsImplementService } from './accounts-implement.service';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  CAccountsListParams,
  EAccountsListParams,
  EAccountsListSortKeys,
  EGatewayAccountStatus,
} from '@parameters/accounts/accounts-list.parameter';
import { IAccountsListParams } from '@interfaces/accounts/accounts-list.interface';
import { IAccountForm } from '@interfaces/accounts/accounts-form.interface';

@Injectable()
export class AccountsStoreService {
  private filterChangeSubject: BehaviorSubject<boolean>;
  private filterCleanSubject: BehaviorSubject<boolean>;
  private _filterDirty: boolean;

  private _size: number;
  private _page: number;
  private _sort: EAccountsListSortKeys[];
  private _search: string;
  private _mids: string[];
  private _status: EGatewayAccountStatus;

  /**
   * Constructor
   * @param _accountsImplement
   */
  constructor(private readonly _accountsImplement: AccountsImplementService) {
    this.filterChangeSubject = new BehaviorSubject(Boolean(true));
    this.filterCleanSubject = new BehaviorSubject(Boolean(true));
    this._filterDirty = false;

    this.defaultValues();
  }

  /**
   * Get Accounts List
   * @returns
   */
  filterData() {
    const params: IAccountsListParams = {
      [EAccountsListParams.mids]: this.mids,
      [EAccountsListParams.search]: this.search,
      [EAccountsListParams.status]: this.status,
      [EAccountsListParams.sort]: this.sort,
      [EAccountsListParams.page]: this.page,
      [EAccountsListParams.size]: this.size,
    };
    return this._accountsImplement.getAccountsList(params);
  }

  /** SET Filter States */

  /** Filter change observable */
  public get filterChange$(): Observable<boolean> {
    return this.filterChangeSubject.asObservable();
  }

  /** Filter change */
  public set filterChange(state: boolean) {
    this.dirtyFilter();
    this.filterChangeSubject.next(state);
  }

  /** get filter dirty state */
  public get filterDirty() {
    return this._filterDirty;
  }

  /** get filter clean state */
  public get filterClean$(): Observable<boolean> {
    return this.filterCleanSubject.asObservable();
  }

  /**
   * Method to clean filter params
   * @param param0
   */
  public clean({ emit }: { emit: boolean } = { emit: true }) {
    this.defaultValues();
    if (emit) {
      this.filterChange = true;
    } else {
      this.dirtyFilter();
    }
    this.filterCleanSubject.next(true);
  }

  /** Set default values of params */
  private defaultValues() {
    this._mids = CAccountsListParams[EAccountsListParams.mids];
    this._search = CAccountsListParams[EAccountsListParams.search];
    this._status = CAccountsListParams[EAccountsListParams.status];
    this._sort = [EAccountsListSortKeys.accountNameAsc];
    this._page = CAccountsListParams[EAccountsListParams.page];
    this._size = CAccountsListParams[EAccountsListParams.size];
  }

  /** Toggle filter dirty state */
  private dirtyFilter() {
    const search = !!this.search;
    const status = !!this.status;
    const mids = !!this.mids?.length;

    this._filterDirty = search || status || mids;
  }

  /** Params get/set methods */

  public get search() {
    return this._search;
  }

  public set search(value: string) {
    this._search = value;
    this.page = CAccountsListParams[EAccountsListParams.page];
  }

  public get page() {
    return this._page;
  }

  public set page(value: number) {
    this._page = value || CAccountsListParams[EAccountsListParams.page];
    this.filterChange = true;
  }

  public get size() {
    return this._size;
  }

  public set size(value: number) {
    this._size = value || CAccountsListParams[EAccountsListParams.size];
  }

  public get mids() {
    return this._mids;
  }

  public set mids(value: string[]) {
    this._mids = value?.length ? value : [];
    this.page = CAccountsListParams[EAccountsListParams.page];
  }

  public get status() {
    return this._status;
  }

  public set status(status: EGatewayAccountStatus) {
    this._status = status;
    this.page = CAccountsListParams[EAccountsListParams.page];
  }

  public get sort() {
    return this._sort;
  }

  public set sort(value: EAccountsListSortKeys[]) {
    this._sort = value;
    this.page = CAccountsListParams[EAccountsListParams.page];
  }

  /**
   * Get Account Details
   * @param accountId
   * @returns
   */
  public getAccountDetail(accountId: string) {
    return this._accountsImplement.getAccountDetail(accountId);
  }

  /**
   * Update account details
   * @param payload
   * @param accountId
   * @returns
   */
  public updateAccountDetail(payload: IAccountForm, accountId: string) {
    return this._accountsImplement.updateAccountDetail(payload, accountId);
  }
}
