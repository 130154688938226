import { IOfferPricingBannerData } from '@interfaces/offer-pricing/offer-pricing.interface';
import {
  OfferFlatRateCoreFees,
  OfferInterchangePlusCoreFees,
  OfferCashDiscountCoreFees,
  OfferOtherAgentFees,
  OfferOtherStandardFees,
  OfferTieredCoreFees,
  PreferredDomain,
  OfferAchCoreFees,
} from '../../../../../shared/models/offer-pricing/offer-pricing.model';
import { EPaymentMethodType } from '@parameters/offer-pricing/offer-pricing.parameter';

export class OfferPricingBannerData {
  public preferredBrandingDomain: PreferredDomain;
  public preferredBrandingDomainId: string;
  public agentId: string;
  public tagLine: string;
  public paymentMethodType: EPaymentMethodType;
  public pricingType: string;
  public otherAgentFees: OfferOtherAgentFees;
  public otherStandardFees: OfferOtherStandardFees;
  public achCoreFees?: OfferAchCoreFees;
  public flatRateCoreFees?: OfferFlatRateCoreFees;
  public interchangePlusCoreFees?: OfferInterchangePlusCoreFees;
  public cashDiscountCoreFees?: OfferCashDiscountCoreFees;
  public tieredCoreFees?: OfferTieredCoreFees;

  constructor(iOfferPricingBannerData: IOfferPricingBannerData) {
    this.agentId = iOfferPricingBannerData?.agentId;
    this.tagLine = iOfferPricingBannerData?.tagLine;
    this.preferredBrandingDomain = new PreferredDomain(
      iOfferPricingBannerData?.preferredBrandingDomain
    );
    this.preferredBrandingDomainId =
      iOfferPricingBannerData?.preferredBrandingDomain?.id;
    this.paymentMethodType = iOfferPricingBannerData?.paymentMethodType;
    this.pricingType = iOfferPricingBannerData?.pricingType;
    this.otherAgentFees = new OfferOtherAgentFees(
      iOfferPricingBannerData?.otherAgentFees,
      this.paymentMethodType
    );
    this.otherStandardFees = new OfferOtherStandardFees(
      iOfferPricingBannerData?.otherStandardFees,
      this.paymentMethodType
    );
    this.achCoreFees = new OfferAchCoreFees(
      iOfferPricingBannerData?.achCoreFees
    );
    this.flatRateCoreFees = iOfferPricingBannerData?.flatRateCoreFees
      ? new OfferFlatRateCoreFees(iOfferPricingBannerData?.flatRateCoreFees)
      : null;
    this.interchangePlusCoreFees =
      iOfferPricingBannerData?.interchangePlusCoreFees
        ? new OfferInterchangePlusCoreFees(
            iOfferPricingBannerData?.interchangePlusCoreFees
          )
        : null;
    this.cashDiscountCoreFees = iOfferPricingBannerData?.cashDiscountCoreFees
      ? new OfferCashDiscountCoreFees(
          iOfferPricingBannerData?.cashDiscountCoreFees
        )
      : null;
    this.tieredCoreFees = iOfferPricingBannerData?.tieredCoreFees
      ? new OfferTieredCoreFees(iOfferPricingBannerData?.tieredCoreFees)
      : null;
  }
}
