import { HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { GenericClientService } from '@clients/generic/generic-client.service';
import { GenericHelper } from '@helpers/generic.helper';
import {
  IAccountGatewayConfigForm,
  IAccountGatewayConfigurationResponse,
} from '@interfaces/accounts/account-gateway-configurations.interface';
import {
  IAccountAssociatedMerchantResponse,
  IAccountMerchantsListParams,
} from '@interfaces/accounts/account-merchants-list.interface';
import { IAccountForm } from '@interfaces/accounts/accounts-form.interface';
import {
  IAccount,
  IAccountListResponse,
  IAccountsListParams,
} from '@interfaces/accounts/accounts-list.interface';
import { IResponseBase } from '@interfaces/generic/responses.interface';
import {
  CAccountMerchantsListParams,
  EAccountMerchantsListParams,
} from '@parameters/accounts/account-merchants-list.parameter';
import {
  CAccountsListParams,
  EAccountsListParams,
  EGatewayAccountStatus,
} from '@parameters/accounts/accounts-list.parameter';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import { Observable } from 'rxjs';

@Injectable()
export class AccountsClientService {
  private readonly _genericClient = inject(GenericClientService);

  /**
   * Get accounts list
   * @param params
   * @returns
   */
  public getAccountsList(
    params: IAccountsListParams
  ): Observable<IAccountListResponse> {
    const endpoint = EndpointsParameter.Accounts;
    let urlParams = new HttpParams()
      .set(
        EAccountsListParams.page,
        params?.[EAccountsListParams.page] ||
          CAccountsListParams[EAccountsListParams.page]
      )
      .set(
        EAccountsListParams.size,
        params?.[EAccountsListParams.size] ||
          CAccountsListParams[EAccountsListParams.size]
      )
      .set(
        EAccountsListParams.search,
        params?.[EAccountsListParams.search] ||
          CAccountsListParams[EAccountsListParams.search]
      )
      .set(
        EAccountsListParams.mids,
        params?.[EAccountsListParams.mids]?.join(',') ||
          CAccountsListParams[EAccountsListParams.mids]?.join(',')
      )
      .set(
        EAccountsListParams.sort,
        params?.[EAccountsListParams.sort]?.join(',') ||
          CAccountsListParams[EAccountsListParams.sort]?.join(',')
      );

    if (params?.[EAccountsListParams.status]) {
      urlParams = urlParams.append(
        EAccountsListParams.status,
        params?.[EAccountsListParams.status] === EGatewayAccountStatus.enabled
          ? true
          : false
      );
    }

    return this._genericClient.genericGet<IAccountListResponse>(endpoint, {
      params: urlParams,
    });
  }

  /**
   * Get Account Details
   * @param accountId
   * @returns
   */
  public getAccountDetail(
    accountId: string
  ): Observable<IResponseBase<IAccount>> {
    const endpoint = `${EndpointsParameter.Accounts}/${accountId}`;

    return this._genericClient.genericGet<IResponseBase<IAccount>>(endpoint);
  }

  /**
   * Update Account Details
   * @param payload
   * @param accountId
   * @returns
   */
  public updateAccountDetails(
    payload: IAccountForm,
    accountId: string
  ): Observable<IResponseBase<null>> {
    const endpoint = `${EndpointsParameter.Accounts}/${accountId}`;

    return this._genericClient.genericPatch<IResponseBase<null>>(
      endpoint,
      payload
    );
  }

  /**
   * List of associated merchants
   * @param params
   * @param accountId
   * @returns
   */
  public accountAssociatedMerchants(
    params: IAccountMerchantsListParams,
    accountId: string
  ): Observable<IAccountAssociatedMerchantResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.AccountMerchants,
      accountId
    );

    const urlParams = new HttpParams()
      .set(
        EAccountMerchantsListParams.page,
        params[EAccountMerchantsListParams.page] ||
          CAccountMerchantsListParams[EAccountMerchantsListParams.page]
      )
      .set(
        EAccountMerchantsListParams.size,
        params[EAccountMerchantsListParams.size] ||
          CAccountMerchantsListParams[EAccountMerchantsListParams.size]
      )
      .set(
        EAccountMerchantsListParams.search,
        params[EAccountMerchantsListParams.search] ||
          CAccountMerchantsListParams[EAccountMerchantsListParams.search]
      )
      .set(
        EAccountMerchantsListParams.sort,
        params[EAccountMerchantsListParams.sort]?.join(',') ||
          CAccountMerchantsListParams[EAccountMerchantsListParams.sort]?.join(
            ','
          )
      );

    return this._genericClient.genericGet<IAccountAssociatedMerchantResponse>(
      endpoint,
      {
        params: urlParams,
      }
    );
  }

  /**
   * Get account gateway list
   * @param accountId
   * @returns
   */
  getAccountGatewayConfig(
    accountId: string
  ): Observable<IAccountGatewayConfigurationResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.AccountGatewayConfiguration,
      accountId
    );

    return this._genericClient.genericGet<IAccountGatewayConfigurationResponse>(
      endpoint
    );
  }

  /**
   * Add Account Gateway
   * @param payload
   * @param accountId
   * @returns
   */
  addAccountGatewayConfig(
    payload: IAccountGatewayConfigForm,
    accountId: string
  ): Observable<IResponseBase<null>> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.AccountGatewayConfiguration,
      accountId
    );

    return this._genericClient.genericPost<IResponseBase<null>>(
      endpoint,
      payload
    );
  }

  /**
   * Update gateway config
   * @param payload
   * @param gatewayId
   * @param accountId
   * @returns
   */
  updateAccountGatewayConfig(
    payload: IAccountGatewayConfigForm,
    gatewayId: string,
    accountId: string
  ): Observable<IResponseBase<null>> {
    const endpoint = `${GenericHelper.replaceUrl(
      EndpointsParameter.AccountGatewayConfiguration,
      accountId
    )}/${gatewayId}`;

    return this._genericClient.genericPatch<IResponseBase<null>>(
      endpoint,
      payload
    );
  }
}
