import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appSsnMask]',
})
export class SsnMaskDirective {
  @Input() ssnType: string;
  constructor(public ngControl: NgControl) {}

  @HostListener('ngModelChange', ['$event'])
  public onModelChange(event) {
    this.onInputChange(event, false);
  }

  @HostListener('keydown.backspace', ['$event'])
  public keydownBackspace(event) {
    this.onInputChange(event.target.value, true);
  }

  onInputChange(event, backspace) {
    let newVal = event?.replace(/\D/g, '');

    if (this.ssnType !== 'ssn') {
      if (backspace && newVal?.length <= 1) {
        newVal = newVal?.substring(0, newVal.length - 1);
      }
      if (newVal?.length === 0) {
        newVal = '';
      } else if (newVal?.length <= 3) {
        newVal = newVal?.replace(/^(\d{0,3})/, '$1');
      } else if (newVal?.length <= 5) {
        newVal = newVal?.replace(/^(\d{0,3})(\d{0,2})/, '$1-$2');
      } else if (newVal?.length <= 12) {
        newVal = newVal?.replace(/^(\d{0,3})(\d{0,2})(\d{0,4})/, '$1-$2-$3');
      } else {
        newVal = newVal?.substring(0, 12);
        newVal = newVal?.replace(/^(\d{0,3})(\d{0,2})(\d{0,4})/, '$1-$2-$3');
      }
    } else {
      if (backspace && newVal?.length <= 1) {
        newVal = newVal?.substring(0, newVal?.length - 1);
      }
      if (newVal?.length === 0) {
        newVal = '';
      } else if (newVal?.length === 1) {
        newVal = newVal.replace(/^(\d{0,3})/, '$1-');
      } else {
        newVal = newVal?.substring(0, 9);
        newVal = newVal?.replace(/^(\d{0,2})(\d{0,7})/, '$1-$2');
      }
    }
    this.ngControl.valueAccessor.writeValue(newVal || '');
  }
}
