import {
  CDateFilterListValue,
  EDateFilter,
} from '@parameters/filter/date.parameter';

export const enum EMetricsKpisProspectParams {
  startDate = 'start_date',
  endDate = 'end_date',
  midList = 'mids',
  binList = 'bins',
  kpiList = 'kpis',
}

export const enum EMetricsKpisProspectParamsKpi {
  total = 'total',
  new = 'new',
  approved = 'approved',
  declined = 'declined',
}

export const CMetricsKpisProspectParamsDate =
  CDateFilterListValue[EDateFilter.last30Days];
