import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

import { AuthenticationService } from 'src/app/auth/service';
import { HttpsService } from 'src/app/shared/services/base.service';
import { SkipableRoutesService } from 'src/app/shared/services/skipable-routes.service';
import {
  EMerchantOnboardingStatus,
  EMerchantProspectStatus,
} from 'src/app/business/authentication/models/merchant-prospect-status.enum';
import {
  EOnboardingPageId,
  RoutePoints,
  routesArray,
} from '@helpers/routes.enum';
import { EMspMerchantApplicationSignatureRoleValue } from '@parameters/authentication/msp-merchant-application-list.parameter';
import { Location } from '@angular/common';
import { UserRoles } from '../models';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  /**
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   * @param http
   * @param skipRouteService
   * @param location
   */
  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService,
    private http: HttpsService,
    private skipRouteService: SkipableRoutesService,
    private location: Location
  ) {}

  // canActivate
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const token = this._authenticationService.authToken;
    const id = route?.params?.merchantapplicationid || '';
    this.http.merchantId = id;
    this._authenticationService.currentSelectedMerchantId = id;
    // check if route is restricted by role
    const role =
      this._authenticationService.hasRole(UserRoles.Merchant) ||
      this._authenticationService.hasRole(UserRoles.MerchantSigner);
    if (role) {
      return this.getStatus(token) as Promise<boolean>;
    } else {
      if (!token) {
        this._router.navigate(['/login']);
        return new Promise((resolve, reject) => resolve(false));
      } else {
        return new Promise((resolve, reject) => resolve(false));
      }
    }
  }

  getStatus(token): Promise<boolean> {
    if (token) {
      return new Promise((resolve, reject) => {
        const selectedApplication =
          this._authenticationService.getStoredSelectedMerchantApplicationData;
        if (!selectedApplication.selectedApplicationMerchantId) {
          this.location.back();
          reject(true);
          return;
        }
        this.http.httpGet('businessStatus').subscribe((res: any) => {
          if (res.success) {
            if (
              selectedApplication.selectedApplicationMerchantId &&
              selectedApplication.selectedApplicationSignatureRole ===
                EMspMerchantApplicationSignatureRoleValue.primary
            ) {
              const data = res?.data;
              const {
                currentProspectStatusId,
                onboardingProgressCurrentPageId,
              } = data;

              const matchedRoute: RoutePoints = (routesArray || []).find(
                (route: RoutePoints) =>
                  route?.pageId === onboardingProgressCurrentPageId
              );
              const onboardingStep: number =
                (matchedRoute?.stepNumber === 0
                  ? 1
                  : matchedRoute?.stepNumber) || 1;

              this.skipRouteService.setcurrentPosition = onboardingStep;
              this._authenticationService.updateState.data = res?.data;
              if (
                currentProspectStatusId === EMerchantProspectStatus.CREATED ||
                currentProspectStatusId === EMerchantProspectStatus.OFFER_SENT
              ) {
                if (
                  !onboardingProgressCurrentPageId ||
                  onboardingProgressCurrentPageId === null ||
                  onboardingProgressCurrentPageId === '' ||
                  onboardingProgressCurrentPageId !==
                    EOnboardingPageId.accept_merchant_agreement
                ) {
                  this._authenticationService.updateState.state =
                    EMerchantOnboardingStatus.DRAFT;
                  this._authenticationService.subject$.next(
                    this._authenticationService.updateState
                  );
                  this._authenticationService.subject$.complete();
                  resolve(true);
                }
              } else if (
                onboardingProgressCurrentPageId ===
                  EOnboardingPageId.accept_merchant_agreement ||
                currentProspectStatusId === EMerchantProspectStatus.SIGNED ||
                currentProspectStatusId === EMerchantProspectStatus.SUBMITTED ||
                currentProspectStatusId === EMerchantProspectStatus.IN_REVIEW ||
                currentProspectStatusId ===
                  EMerchantProspectStatus.NEEDS_MORE_INFO ||
                currentProspectStatusId === EMerchantProspectStatus.APPROVED ||
                currentProspectStatusId === EMerchantProspectStatus.DECLINED ||
                currentProspectStatusId === EMerchantProspectStatus.WITHDRAWN
              ) {
                this._authenticationService.updateState.state =
                  EMerchantOnboardingStatus.COMPLETED;
                this._authenticationService.subject$.next(
                  this._authenticationService.updateState
                );
                this._authenticationService.subject$.complete();
                resolve(true);
              } else {
                this._authenticationService.updateState.state =
                  EMerchantOnboardingStatus.DENIED;
                this._authenticationService.subject$.next(
                  this._authenticationService.updateState
                );
                this._authenticationService.subject$.complete();
                resolve(true);
              }
            } else {
              this.location.back();
              reject(true);
              return;
            }
          }
        });
      });
    } else {
      this._router.navigate(['/login']);
      return new Promise((resolve, reject) => {
        resolve(false);
      });
    }
  }
}
