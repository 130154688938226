import { Inject, Injectable, InjectionToken } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { CoreConfig } from '@core/types/core-config';

// Injection token for the core custom settings
export const CORE_CUSTOM_CONFIG = new InjectionToken('coreCustomConfig');

@Injectable({
  providedIn: 'root',
})
export class CoreConfigService {
  // Private
  private readonly _defaultConfig: CoreConfig;
  private _configSubject: BehaviorSubject<CoreConfig>;

  /**
   * Constructor
   *
   * @param _config
   */
  constructor(@Inject(CORE_CUSTOM_CONFIG) _config: CoreConfig) {
    // Set the defaultConfig to localConfig if we have else appConfig (app-config.ts)
    this._defaultConfig = _config;

    // Initialize the config service
    this._configSubject = new BehaviorSubject(
      structuredClone(this._defaultConfig)
    );
  }

  //  Accessors
  // -----------------------------------------------------------------------------------------------------

  // Set the config
  set config(data: CoreConfig) {
    let config: CoreConfig = this._configSubject.getValue();

    // Merge provided data with config, and create new merged config
    config = { ...config, ...data };

    // Inform the observers
    this._configSubject.next(config);
  }

  // Get the config
  get config(): Observable<CoreConfig> {
    return this._configSubject.asObservable();
  }

  /**
   * Reset to the default config
   */
  resetConfig(): void {
    this._configSubject.next(structuredClone(this._defaultConfig));
  }
}
