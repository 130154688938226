<div class="iso-agent-main-container">
  <div class="d-flex justify-content-end mb-36">
    <gtr-button color="primary" (click)="openModal()"
                [dataTransactionName]="'accounts-gateway-config--add'"
                [attr.data-transaction-name]="'accounts-gateway-config--add'"
      >+ Add Configuration</gtr-button
    >
  </div>

  <gtr-table
    [isResponsive]="true"
    [loading]="loading"
    (onAction)="actionDone($event)"
    [data]="accountGatewayRows"
    [columns]="accountGatewayCols"
    [enableSelection]="false"
    [dataTransactionName]="'accounts-gateway-config'"
  ></gtr-table>
</div>

  <app-account-gateway-config-add-modal
  [accountId]="accountId"
  [detail]="activeDetail"
  [setDefaultValues]="defaultValues"
  [show]="addModalState"
  (close)="closeModal($event)"
/>
