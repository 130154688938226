import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericClientService } from '@clients/generic/generic-client.service';
import {
  CDashboardChartParamsDefault,
  IDashboardChartParams,
  IDashboardChartResponse,
} from '@interfaces/dashboard/dashboard-chart.interface';
import { EDashboardChartParams } from '@parameters/dashboard/dashboard-chart.parameter';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import { Observable } from 'rxjs';

@Injectable()
export class DashboardChartClientService {
  /**
   * Constructor
   * @param genericClient
   */
  constructor(private genericClient: GenericClientService) {}

  /**
   * Net Volume Graph Chart Data
   * @param queryParams
   * @returns
   */
  public getNetVolumeGraphChartData(
    queryParams: IDashboardChartParams
  ): Observable<IDashboardChartResponse> {
    const endpoint = EndpointsParameter.MetricsChartsNetVolume;
    let params = this.getDashboardChartParams(queryParams);
    params = params.append(
      EDashboardChartParams.volumeAs,
      queryParams[EDashboardChartParams.volumeAs].join('') ||
        CDashboardChartParamsDefault[EDashboardChartParams.volumeAs].join('')
    );
    return this.genericClient.genericGet(endpoint, { params });
  }

  /**
   * Net Deposits Graph Chart Data
   * @param queryParams
   * @returns
   */
  public getNetDepositsGraphChartData(
    queryParams: IDashboardChartParams
  ): Observable<IDashboardChartResponse> {
    const endpoint = EndpointsParameter.MetricsChartsNetDeposits;
    const params = this.getDashboardChartParams(queryParams);
    return this.genericClient.genericGet(endpoint, { params });
  }

  /**
   * Settled Transactions Graph Chart Data
   * @param queryParams
   * @returns
   */
  public getSettledTransactionsGraphChartData(
    queryParams: IDashboardChartParams
  ): Observable<IDashboardChartResponse> {
    const endpoint = EndpointsParameter.MetricsChartsSettledTransactionsCount;
    let params = this.getDashboardChartParams(queryParams);
    params = params.append(
      EDashboardChartParams.volumeAs,
      queryParams[EDashboardChartParams.volumeAs].join('') ||
        CDashboardChartParamsDefault[EDashboardChartParams.volumeAs].join('')
    );
    return this.genericClient.genericGet(endpoint, { params });
  }

  /**
   * Disputed Volume Graph Chart Data
   * @param queryParams
   * @returns
   */
  public getDisputedVolumeGraphChartData(
    queryParams: IDashboardChartParams
  ): Observable<IDashboardChartResponse> {
    const endpoint = EndpointsParameter.MetricsChartsDisputedVolume;
    const params = this.getDashboardChartParams(queryParams);
    return this.genericClient.genericGet(endpoint, { params });
  }

  /**
   * Common Method to Get Dashboard Chart Params
   * @param queryParams
   * @returns HTTP PARAMS
   */
  private getDashboardChartParams(
    queryParams: IDashboardChartParams
  ): HttpParams {
    return new HttpParams()
      .set(
        EDashboardChartParams.startDate,
        queryParams[EDashboardChartParams.startDate] ||
          CDashboardChartParamsDefault[EDashboardChartParams.startDate]
      )
      .set(
        EDashboardChartParams.endDate,
        queryParams[EDashboardChartParams.endDate] ||
          CDashboardChartParamsDefault[EDashboardChartParams.endDate]
      )
      .set(
        EDashboardChartParams.midList,
        queryParams[EDashboardChartParams.midList].join(',') ||
          CDashboardChartParamsDefault[EDashboardChartParams.midList].join(',')
      )
      .set(
        EDashboardChartParams.binList,
        queryParams[EDashboardChartParams.binList].join(',') ||
          CDashboardChartParamsDefault[EDashboardChartParams.binList].join(',')
      )
      .set(
        EDashboardChartParams.cardBrandList,
        queryParams[EDashboardChartParams.cardBrandList].join(',') ||
          CDashboardChartParamsDefault[
            EDashboardChartParams.cardBrandList
          ].join(',')
      )
      .set(
        EDashboardChartParams.relationshipList,
        queryParams[EDashboardChartParams.relationshipList].join(',') ||
          CDashboardChartParamsDefault[
            EDashboardChartParams.relationshipList
          ].join(',')
      )
      .set(
        EDashboardChartParams.groupBy,
        queryParams[EDashboardChartParams.groupBy] ||
          CDashboardChartParamsDefault[EDashboardChartParams.groupBy]
      );
  }
}
