<form (ngSubmit)="submitForm()" [formGroup]="accountUpdateForm" [attr.data-transaction-name]="'account-detail'">
  <div class="row">
    <div class="col-12 col-md-4 mb-1">
      <gtr-input
        type="text"
        [placeholder]="'Enter account name'"
        [inputLabel]="'Account Name'"
        [formControlName]="formKeys.accountName"
        [isValid]="!accountUpdateForm?.controls?.[formKeys.accountName]?.touched || ((accountUpdateForm?.controls?.[formKeys.accountName]?.touched || accountUpdateForm?.controls?.[formKeys.accountName]?.dirty) && accountUpdateForm?.controls?.[formKeys.accountName]?.valid)"
        [dataTransactionName]="'account-detail--account-name'">
        <div slot="custom-error">
          <app-input-error-msg
            [control]="accountUpdateForm?.controls?.[formKeys.accountName]"
          >
          </app-input-error-msg>
        </div>
      </gtr-input>
    </div>

    <div class="col-12 col-md-4 mb-1">
      <gtr-select
        inputLabel="Gateway Status"
        [isOnlyView]="false"
        [value]="selectedGatewayStatus || ''"
        [valueKey]="'value'"
        [labelKey]="'label'"
        [options]="gatewayStatusOptions"
        (valueChanged)="handleStatusChange($event)"
        [is_invalid]="accountUpdateForm?.controls?.[formKeys.isPaymentEnabled]?.invalid && (accountUpdateForm?.controls?.[formKeys.isPaymentEnabled]?.touched || accountUpdateForm?.controls?.[formKeys.isPaymentEnabled]?.dirty)"
        [dataTransactionName]="'account-detail--gateway-status'">
        <div slot="custom-error">
          <app-input-error-msg
            [control]="accountUpdateForm?.controls?.[formKeys.isPaymentEnabled]"
          >
          </app-input-error-msg>
        </div>
      </gtr-select>
    </div>
  </div>

  <div class="row mt-2">
    <div
      class="col-12 col-md-12 d-flex justify-content-end gap-2 align-items-center"
    >
      <gtr-button variant="outline" color="gray" (click)="discard()"
                  [dataTransactionName]="'account-detail--cancel'"
                  [attr.data-transaction-name]="'account-detail--cancel'">
        Cancel
      </gtr-button>
      <gtr-button
        color="primary"
        (click)="submitForm()"
        [isLoading]="loading"
        [disabled]="loading"
        [dataTransactionName]="'account-detail--update'"
        [attr.data-transaction-name]="'account-detail--update'"
      >
        Update
      </gtr-button>
    </div>
  </div>
</form>
