import {
  IResponseBase,
  IResponseData,
} from '@interfaces/generic/responses.interface';
import {
  EEntityRelationshipListParams,
  EEntityRelationshipListSort,
} from '@parameters/entity/relationship/entity-relationship-list.parameter';
import { EEntityRelationshipType } from '@parameters/entity/relationship/entity-relationship.parameter';
import { IEntityRelationship } from '@interfaces/entity/relationship/entity-relationship.interface';

export interface IEntityRelationshipListResponse
  extends IResponseBase<IResponseData<IEntityRelationship>> {}

export interface IEntityRelationshipListParams {
  [EEntityRelationshipListParams.relationshipTypes]: EEntityRelationshipType[];
  [EEntityRelationshipListParams.enabled]: boolean | null;
  [EEntityRelationshipListParams.sort]: EEntityRelationshipListSort;
  [EEntityRelationshipListParams.page]: number;
  [EEntityRelationshipListParams.size]: number;
  [EEntityRelationshipListParams.search]: string;
  [EEntityRelationshipListParams.entityId]?: string;
  [EEntityRelationshipListParams.isDisabled]?: boolean;
}

export const CEntityRelationshipParamsDefault: IEntityRelationshipListParams = {
  [EEntityRelationshipListParams.relationshipTypes]: [
    EEntityRelationshipType.referralSource,
    EEntityRelationshipType.agentSource,
    EEntityRelationshipType.agent,
    EEntityRelationshipType.referral,
    EEntityRelationshipType.salesManager,
  ],
  [EEntityRelationshipListParams.enabled]: true,
  [EEntityRelationshipListParams.sort]:
    EEntityRelationshipListSort.createdAtDesc,
  [EEntityRelationshipListParams.page]: 1,
  [EEntityRelationshipListParams.size]: 10,
  [EEntityRelationshipListParams.search]: '',
  [EEntityRelationshipListParams.entityId]: '',
  [EEntityRelationshipListParams.isDisabled]: null,
};
