import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  NgZone,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CustomValidator, noWhitespaceValidator } from '@helpers/validators';
import {
  CAccountGatewayConfigFormValue,
  CConfigTypeAchOptions,
  CConfigTypeOptions,
  CPaymentMethodTypeOptions,
  EAccountGatewayConfigFormKeys,
  EConfigType,
  EPaymentMethodType,
} from '@parameters/accounts/account-gateway-config.parameter';
import { AccountGatewayConfigStoreService } from '../../services/account-gateway-store.service';
import { IAccountGatewayConfigForm } from '@interfaces/accounts/account-gateway-configurations.interface';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CommonService } from 'src/app/shared/services/common.service';
import { EntitiesFormHelper } from 'src/app/business/portal/ops-and-uw-tools/manage-entities/models/entities-form-helper.model';
import { AccountGatewayConfig } from '../../models/account-gateway-config.model';

@Component({
  selector: 'app-account-gateway-config-add-modal',
  templateUrl: './account-gateway-config-add-modal.component.html',
  styleUrls: ['./account-gateway-config-add-modal.component.scss'],
})
export class AccountGatewayConfigAddModalComponent {
  @ViewChild('addGatewayConfigModal')
  public addGatewayConfigModal!: any;

  private readonly _accountGatewayStore = inject(
    AccountGatewayConfigStoreService
  );
  private readonly _cdr = inject(ChangeDetectorRef);
  private readonly _ngZone = inject(NgZone);
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _cm = inject(CommonService);

  @Input({ required: true }) public accountId: string;
  breadcrumbData: any;

  @Input() public set show(state: boolean) {
    if (state) {
      this._openModal();
    } else {
      this.closeModal();
    }
  }

  @Input({ required: true }) public set detail(value: AccountGatewayConfig) {
    this.editable = !!value;
    this.editable && this._mapFormValue(value);
  }

  @Input() public set setDefaultValues(value: AccountGatewayConfig) {
    this._mapDefaultValue(value);
  }

  @Output() close = new EventEmitter<boolean>();

  public readonly defaultValues = CAccountGatewayConfigFormValue;
  public readonly paymentMethodTypeOption = CPaymentMethodTypeOptions;
  public readonly configTypeOptions = CConfigTypeOptions;
  public readonly configTypeAchOptions = CConfigTypeAchOptions;
  public readonly formKeys = EAccountGatewayConfigFormKeys;
  public readonly paymentMethodType = EPaymentMethodType;
  public readonly configTypes = EConfigType;

  public hasOpened: boolean;
  public loading: boolean;
  public gatewayData: AccountGatewayConfig;
  public gatewayId: string;
  public editable: boolean;

  public gatewayConfigForm = new FormGroup({
    [EAccountGatewayConfigFormKeys.configType]: new FormControl(
      this.defaultValues[EAccountGatewayConfigFormKeys.configType],
      { validators: [Validators.required, noWhitespaceValidator] }
    ),
    [EAccountGatewayConfigFormKeys.configPm]: new FormControl(
      this.defaultValues[EAccountGatewayConfigFormKeys.configPm],
      { validators: [Validators.required, noWhitespaceValidator] }
    ),
    [EAccountGatewayConfigFormKeys.mid]: new FormControl(
      this.defaultValues[EAccountGatewayConfigFormKeys.mid],
      {
        validators: [
          Validators.required,
          noWhitespaceValidator,
          Validators.minLength(12),
          Validators.maxLength(16),
          CustomValidator.numeric,
        ],
      }
    ),
    [EAccountGatewayConfigFormKeys.payfacMid]: new FormControl(
      this.defaultValues[EAccountGatewayConfigFormKeys.payfacMid],
      {
        validators: [
          noWhitespaceValidator,
          Validators.minLength(12),
          Validators.maxLength(16),
          CustomValidator.numeric,
        ],
      }
    ),
    [EAccountGatewayConfigFormKeys.tid]: new FormControl(
      this.defaultValues[EAccountGatewayConfigFormKeys.tid],
      {
        validators: [
          Validators.required,
          noWhitespaceValidator,
          Validators.maxLength(20),
          CustomValidator.numeric,
        ],
      }
    ),
    [EAccountGatewayConfigFormKeys.descriptorPrefix]: new FormControl(
      this.defaultValues[EAccountGatewayConfigFormKeys.descriptorPrefix],
      { validators: [noWhitespaceValidator, Validators.maxLength(22)] }
    ),
    [EAccountGatewayConfigFormKeys.setAsDefaultCCConfig]: new FormControl(
      this.defaultValues[EAccountGatewayConfigFormKeys.setAsDefaultCCConfig],
      { validators: [noWhitespaceValidator] }
    ),
    [EAccountGatewayConfigFormKeys.setAsDefaultACHConfig]: new FormControl(
      this.defaultValues[EAccountGatewayConfigFormKeys.setAsDefaultACHConfig],
      { validators: [noWhitespaceValidator] }
    ),
  });

  /** Submit form */
  public submitForm() {
    this.gatewayConfigForm?.patchValue({
      ...EntitiesFormHelper.formatPayload(this.gatewayConfigForm?.value),
    });

    if (this.gatewayConfigForm?.valid) {
      this.editable ? this._update() : this._add();
    } else {
      this.gatewayConfigForm?.markAllAsTouched();
    }
  }

  /** Add */
  private _add() {
    this.loading = true;
    this._accountGatewayStore
      .addGatewayConfiguration(this._formattedPayload, this.accountId)
      ?.pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe({
        next: (res) => {
          this.loading = false;
          if (res?.message) {
            this._cm.success(res?.message);
          }
          this.closeModal(true);
        },
        error: () => {
          this._ngZone.run(() => {
            this.loading = false;
            this._cdr.detectChanges();
          });
        },
      });
  }

  /** Update */
  private _update() {
    this.loading = true;
    this._accountGatewayStore
      .updateGatewayConfiguration(
        this._formattedPayload,
        this.gatewayId,
        this.accountId
      )
      ?.pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe({
        next: (res) => {
          this.loading = false;
          if (res?.message) {
            this._cm.success(res?.message);
          }
          this.closeModal(true);
        },
        error: () => {
          this._ngZone.run(() => {
            this.loading = false;
            this._cdr.detectChanges();
          });
        },
      });
  }

  /** Format payload */
  private get _formattedPayload(): IAccountGatewayConfigForm {
    let payload = { ...this.gatewayConfigForm?.value };
    if (this.configPm?.value === EPaymentMethodType.card) {
      if (this.configType?.value === EConfigType.direct) {
        delete payload[EAccountGatewayConfigFormKeys.payfacMid];
      }

      delete payload[EAccountGatewayConfigFormKeys.setAsDefaultACHConfig];

      return payload;
    } else {
      delete payload[EAccountGatewayConfigFormKeys.payfacMid];
      delete payload[EAccountGatewayConfigFormKeys.tid];
      delete payload[EAccountGatewayConfigFormKeys.setAsDefaultCCConfig];
      return payload;
    }
  }

  /** Map Defaut values */
  private _mapDefaultValue(data: AccountGatewayConfig) {
    this.gatewayConfigForm.patchValue({
      [EAccountGatewayConfigFormKeys.configType]:
        data?.configType || EConfigType.direct,
      [EAccountGatewayConfigFormKeys.configPm]:
        data?.configPm || EPaymentMethodType.card,
      [EAccountGatewayConfigFormKeys.mid]: data?.mid || '',
      [EAccountGatewayConfigFormKeys.payfacMid]: data?.payfacMid || '',
      [EAccountGatewayConfigFormKeys.tid]: data?.tid || '',
      [EAccountGatewayConfigFormKeys.setAsDefaultCCConfig]:
        data?.configPm === EPaymentMethodType.card && data?.defaultConfig
          ? true
          : false,
      [EAccountGatewayConfigFormKeys.setAsDefaultACHConfig]:
        data?.configPm === EPaymentMethodType.ach && data?.defaultConfig
          ? true
          : false,
    });

    if (data?.configPm === EPaymentMethodType.ach) {
      this.tid?.removeValidators([Validators.required]);
      this.tid?.updateValueAndValidity();
    }
  }

  /** Map form values */
  private _mapFormValue(data: AccountGatewayConfig) {
    this.gatewayData = data;
    this.gatewayId = data?.configId;
    this.gatewayConfigForm.patchValue({
      [EAccountGatewayConfigFormKeys.configType]: data?.configType || '',
      [EAccountGatewayConfigFormKeys.configPm]: data?.configPm || '',
      [EAccountGatewayConfigFormKeys.mid]: data?.mid || '',
      [EAccountGatewayConfigFormKeys.payfacMid]: data?.payfacMid || '',
      [EAccountGatewayConfigFormKeys.tid]: data?.tid || '',
      [EAccountGatewayConfigFormKeys.descriptorPrefix]:
        data?.descriptorPrefix || '',
      [EAccountGatewayConfigFormKeys.setAsDefaultCCConfig]:
        data?.configPm === EPaymentMethodType.card && data?.defaultConfig
          ? true
          : false,
      [EAccountGatewayConfigFormKeys.setAsDefaultACHConfig]:
        data?.configPm === EPaymentMethodType.ach && data?.defaultConfig
          ? true
          : false,
    });

    if (data?.configPm === EPaymentMethodType.ach) {
      this.tid?.removeValidators([Validators.required]);
      this.tid?.updateValueAndValidity();
    }
  }

  /** Open Modal */
  private _openModal() {
    if (this.addGatewayConfigModal) {
      this.addGatewayConfigModal.isOpen = true;
    }

    this.hasOpened = true;
  }

  /** Close Modal */
  public closeModal(refresh: boolean = false) {
    if (this.addGatewayConfigModal) {
      this.addGatewayConfigModal.isOpen = false;
      this.hasOpened = false;
      this.gatewayData = undefined;
      this.gatewayId = '';
      this.gatewayConfigForm.reset(this.defaultValues);
      this._switchValidations(this.configPm?.value, this.configType?.value);
      this.close.emit(refresh);
    }
  }

  public selectionChanged(
    event: any,
    formControl: EAccountGatewayConfigFormKeys
  ) {
    if (formControl === EAccountGatewayConfigFormKeys.setAsDefaultACHConfig) {
      this.gatewayConfigForm
        .get(EAccountGatewayConfigFormKeys.setAsDefaultACHConfig)
        ?.setValue(event?.checked || false);
    }

    if (formControl === EAccountGatewayConfigFormKeys.setAsDefaultCCConfig) {
      this.gatewayConfigForm
        .get(EAccountGatewayConfigFormKeys.setAsDefaultCCConfig)
        ?.setValue(event?.checked || false);
    }
  }

  public toggleConfigType(selectedConfigType: EConfigType) {
    const configPm = this.configPm?.value;
    this._switchValidations(configPm, selectedConfigType);
  }

  public toggleConfigPm(selectedConfigPm: EPaymentMethodType) {
    const configType = this.configType?.value;
    if (selectedConfigPm === EPaymentMethodType.ach) {
      this.configType.setValue(EConfigType.direct);
    }
    this._switchValidations(selectedConfigPm, configType);
  }

  private _switchValidations(
    configPm: EPaymentMethodType,
    configType: EConfigType
  ) {
    this.payfacMid.reset(
      this.defaultValues[EAccountGatewayConfigFormKeys.payfacMid]
    );
    this.tid.reset(this.defaultValues[EAccountGatewayConfigFormKeys.tid]);
    this.mid.reset(this.defaultValues[EAccountGatewayConfigFormKeys.mid]);
    this.descriptorPrefix.reset(
      this.defaultValues[EAccountGatewayConfigFormKeys.descriptorPrefix]
    );
    this.setAsDefaultACHConfig.reset(
      this.defaultValues[EAccountGatewayConfigFormKeys.setAsDefaultACHConfig]
    );
    this.setAsDefaultCCConfig.reset(
      this.defaultValues[EAccountGatewayConfigFormKeys.setAsDefaultCCConfig]
    );

    if (configPm === EPaymentMethodType.card) {
      if (configType === EConfigType.direct) {
        this.payfacMid.removeValidators([Validators.required]);
        this.tid.addValidators([Validators.required]);
      } else {
        this.payfacMid.addValidators([Validators.required]);
        this.tid.addValidators([Validators.required]);
      }
      this.setAsDefaultCCConfig.addValidators([Validators.required]);
      this.setAsDefaultACHConfig.removeValidators([Validators.required]);
    } else if (configPm === EPaymentMethodType.ach) {
      this.payfacMid.removeValidators([Validators.required]);
      this.tid.removeValidators([Validators.required]);
      this.setAsDefaultCCConfig.removeValidators([Validators.required]);
      this.setAsDefaultACHConfig.addValidators([Validators.required]);
    }

    this.payfacMid.updateValueAndValidity();
    this.tid.updateValueAndValidity();
    this.mid.updateValueAndValidity();
    this.descriptorPrefix.updateValueAndValidity();
    this.setAsDefaultACHConfig.updateValueAndValidity();
    this.setAsDefaultCCConfig.updateValueAndValidity();
  }

  public get payfacMid(): AbstractControl {
    return this.gatewayConfigForm?.get(EAccountGatewayConfigFormKeys.payfacMid);
  }

  public get mid(): AbstractControl {
    return this.gatewayConfigForm?.get(EAccountGatewayConfigFormKeys.mid);
  }

  public get descriptorPrefix(): AbstractControl {
    return this.gatewayConfigForm?.get(
      EAccountGatewayConfigFormKeys.descriptorPrefix
    );
  }

  public get configType(): AbstractControl {
    return this.gatewayConfigForm?.get(
      EAccountGatewayConfigFormKeys.configType
    );
  }

  public get configPm(): AbstractControl {
    return this.gatewayConfigForm?.get(EAccountGatewayConfigFormKeys.configPm);
  }

  public get tid(): AbstractControl {
    return this.gatewayConfigForm?.get(EAccountGatewayConfigFormKeys.tid);
  }

  public get setAsDefaultACHConfig(): AbstractControl {
    return this.gatewayConfigForm?.get(
      EAccountGatewayConfigFormKeys.setAsDefaultACHConfig
    );
  }

  public get setAsDefaultCCConfig(): AbstractControl {
    return this.gatewayConfigForm?.get(
      EAccountGatewayConfigFormKeys.setAsDefaultCCConfig
    );
  }
}
