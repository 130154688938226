import {
  ChangeDetectorRef,
  Component,
  Inject,
  ViewEncapsulation,
} from '@angular/core';
import { fadeIn } from '@core/animations/core.animation';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'content',
  templateUrl: './content.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: [fadeIn],
})
export class ContentComponent {
  /**
   * Constructor
   *
   *
   * @param _document
   * @param changeDetectorRef
   */
  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Fade In Animation
   *
   * @param outlet
   */
  fadeIn(outlet) {
    return outlet.activatedRouteData.animation;
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On Init
   */
  ngOnInit(): void {}

  onActivate(event) {
    if (this._document?.querySelector('.menu-navs')) {
      this._document.querySelector('.menu-navs').classList.remove('active');
    }
  }
}
