import { IAccountForm } from '@interfaces/accounts/accounts-form.interface';

export enum EAccountFormKeys {
  accountName = 'accountName',
  isPaymentEnabled = 'isPaymentsEnabled',
}

export const CAccountFormDefaultValues: IAccountForm = {
  [EAccountFormKeys.accountName]: '',
  [EAccountFormKeys.isPaymentEnabled]: false,
};
