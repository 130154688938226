export const enum EDealUploadParams {
  limit = 'limit',
  page = 'page',
  sort = 'order_by',
  search = 'search',
}

export const enum EDealUploadParamSort {
  documentNameAsc = 'file_name:asc',
  documentNameDesc = 'file_name:desc',
  documentTypeAsc = 'documentType:asc',
  documentTypeDesc = 'documentType:desc',
  uploadedDateAsc = 'created_at:asc',
  uploadedDateDesc = 'created_at:desc',
}

export const CDealUploadParams = {
  [EDealUploadParams.limit]: 10,
  [EDealUploadParams.page]: 1,
  [EDealUploadParams.sort]: [],
  [EDealUploadParams.search]: '',
};
