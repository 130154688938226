import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

import { GenericClientService } from '@clients/generic/generic-client.service';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import {
  CFundingPlanListParamsDefault,
  EFundingPlanListParams,
} from '@parameters/funding-plans/funding-plan-list.parameter';
import {
  IFundingPlanListParams,
  IFundingPlanListResponse,
} from '@interfaces/funding-plans/funding-plan-list.interface';
import { IFundingPlanResponse } from '@interfaces/funding-plans/funding-plan.interface';
import {
  IFundingPlanCreateRequest,
  IFundingPlanCreateResponse,
} from '@interfaces/funding-plans/funding-plan-create.interface';

@Injectable()
export class FundingPlanClientService {
  private endpoint = EndpointsParameter.FundingPlan;

  constructor(private genericClient: GenericClientService) {}

  /**
   * Funding Plan List
   * @param queryParams
   * @returns
   */

  getFundingPlanList(
    queryParams: IFundingPlanListParams
  ): Observable<IFundingPlanListResponse> {
    const httpParams = new HttpParams()
      .set(
        EFundingPlanListParams.status,
        queryParams[EFundingPlanListParams.status]?.join(',') ||
          CFundingPlanListParamsDefault[EFundingPlanListParams.status].join(',')
      )
      .set(
        EFundingPlanListParams.limit,
        queryParams[EFundingPlanListParams.limit] ||
          CFundingPlanListParamsDefault[EFundingPlanListParams.limit]
      )
      .set(
        EFundingPlanListParams.page,
        queryParams[EFundingPlanListParams.page] ||
          CFundingPlanListParamsDefault[EFundingPlanListParams.page]
      )
      .set(
        EFundingPlanListParams.sort,
        queryParams[EFundingPlanListParams.sort]?.join(':') ||
          CFundingPlanListParamsDefault[EFundingPlanListParams.sort].join(':')
      )
      .set(
        EFundingPlanListParams.search,
        queryParams[EFundingPlanListParams.search] ||
          CFundingPlanListParamsDefault[EFundingPlanListParams.search]
      );

    return this.genericClient.genericGet<IFundingPlanListResponse>(
      this.endpoint,
      { params: httpParams }
    );
  }

  /**
   * Funding Plan Create
   * @param request
   * @returns
   */

  createFundingPlan(
    request: IFundingPlanCreateRequest
  ): Observable<IFundingPlanCreateResponse> {
    return this.genericClient.genericPost<IFundingPlanCreateResponse>(
      this.endpoint,
      request
    );
  }

  /**
   * Get Funding Plan Detail
   * @param id
   * @returns
   */

  getFundingPlanDetail(id: string): Observable<IFundingPlanResponse> {
    const endpoint = `${this.endpoint}/${id}`;
    return this.genericClient.genericGet<IFundingPlanResponse>(endpoint);
  }
}
