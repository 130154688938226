import { Injectable } from '@angular/core';
import { MspMerchantApplicationClientService } from '@clients/authentication/msp-merchant-application-client.service';

import {
  IMspMerchantApplicationListParams,
  IMspMerchantApplicationListResponse,
} from '@interfaces/authentication/msp-merchant-application-list.interface';
import { MspMerchantApplicationListResponse } from '@models/authentication/msp-merchant-application/msp-merchant-application-list';
import { Observable, map } from 'rxjs';

@Injectable()
export class MspMerchantApplicationImplementService {
  constructor(
    private mspMerchantApplicationClientService: MspMerchantApplicationClientService
  ) {}

  /**
   * For fetch merchant application list
   * @param params IMspMerchantApplicationListParams
   * @returns IMspMerchantApplicationListParams api reponse
   */
  getMerchantApplicationList(
    params: IMspMerchantApplicationListParams
  ): Observable<MspMerchantApplicationListResponse> {
    return this.mspMerchantApplicationClientService
      .getMerchantApplicationList(params)
      .pipe(
        map((response: IMspMerchantApplicationListResponse) => {
          return response && response.data
            ? new MspMerchantApplicationListResponse(response.data)
            : null;
        })
      );
  }
}
