import {
  CMetricsChartCardBrandVolumeParamsDate,
  EMetricsChartCardBrandVolumeParams,
} from '@parameters/metrics/chart/metrics-charts-card-brand-volume.parameter';
import { ECardBrand } from '@parameters/filter/card-brand.parameter';
import { IResponseBase } from '@interfaces/generic/responses.interface';
import { EVolumeAs } from '@parameters/filter/volume-as.parameter';

export interface IMetricsChartCardBrandVolumeParams {
  [EMetricsChartCardBrandVolumeParams.startDate]: string;
  [EMetricsChartCardBrandVolumeParams.endDate]: string;
  [EMetricsChartCardBrandVolumeParams.midList]: number[];
  [EMetricsChartCardBrandVolumeParams.binList]: number[];
  [EMetricsChartCardBrandVolumeParams.cardBrandList]: ECardBrand[];
  [EMetricsChartCardBrandVolumeParams.relationshipList]: string[];
  [EMetricsChartCardBrandVolumeParams.volumeAs]: EVolumeAs[];
}

export interface IMetricsChartCardBrandVolume {
  label: string;
  value: number;
}

export interface IMetricsChartCardBrandVolumeData {
  records: IMetricsChartCardBrandVolume[];
}

export interface IMetricsChartCardBrandVolumeResponse
  extends IResponseBase<IMetricsChartCardBrandVolumeData> {}

export const CMetricsChartCardBrandVolumeParamsDefault: IMetricsChartCardBrandVolumeParams =
  {
    [EMetricsChartCardBrandVolumeParams.startDate]:
      CMetricsChartCardBrandVolumeParamsDate.value.startDate,
    [EMetricsChartCardBrandVolumeParams.endDate]:
      CMetricsChartCardBrandVolumeParamsDate.value.endDate,
    [EMetricsChartCardBrandVolumeParams.midList]: [],
    [EMetricsChartCardBrandVolumeParams.binList]: [],
    [EMetricsChartCardBrandVolumeParams.cardBrandList]: [],
    [EMetricsChartCardBrandVolumeParams.relationshipList]: [],
    [EMetricsChartCardBrandVolumeParams.volumeAs]: [EVolumeAs.calendar],
  };
