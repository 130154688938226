import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericClientService } from '@clients/generic/generic-client.service';
import { GenericHelper } from '@helpers/generic.helper';
import { IResponseBase } from '@interfaces/generic/responses.interface';
import { IProspectsInformationRequestResponse } from '@interfaces/prospects/prospect-information.interface';
import { IUploadRequiredDocument } from '@interfaces/prospects/prospects-uploads.interface';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import { Observable } from 'rxjs';

@Injectable()
export class ProspectInfromationRequestClientService {
  constructor(private readonly _genericClient: GenericClientService) {}

  public getInformationRequests(
    prospectId: string,
    params: { search: string }
  ): Observable<IProspectsInformationRequestResponse> {
    const param = new HttpParams().set('search', params?.search || '');

    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.ProspectRequiredInformationDocument,
      prospectId
    );

    return this._genericClient.genericGet<IProspectsInformationRequestResponse>(
      endpoint,
      { params: param }
    );
  }

  public uploadRequiredDocuments(
    docs: IUploadRequiredDocument,
    prospectId: string
  ): Observable<IResponseBase<void>> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.ProspectUploadDocuments,
      prospectId
    );

    return this._genericClient.genericPost<IResponseBase<void>>(endpoint, docs);
  }
}
