export const enum ESubmitToTsys {
  sfAccountId = 'sfAccountId',
}

export interface ESubmitToTsysRequest {
  sfAccountId: string;
}

export const enum EValidateMidParams {
  mid = 'mid',
}
