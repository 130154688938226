import { Injectable, inject } from '@angular/core';
import { GenericClientService } from '@clients/generic/generic-client.service';
import { GenericHelper } from '@helpers/generic.helper';
import { IResponseBase } from '@interfaces/generic/responses.interface';
import {
  ICreateUserResponse,
  IInternalUserForm,
  IMerchantUserForm,
  IPartnerUserForm,
} from '@interfaces/ops-and-uw-tools/users/user-create.interface';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import { Observable } from 'rxjs';

@Injectable()
export class UserCreateClientService {
  private genericClient = inject(GenericClientService);

  createMerchantUser(
    payload: IMerchantUserForm,
    id?: string
  ): Observable<ICreateUserResponse> {
    const endpoint = EndpointsParameter.MerchantUsersList;

    if (id) {
      return this.genericClient.genericPatch<ICreateUserResponse>(
        `${endpoint}/${id}`,
        payload
      );
    } else {
      return this.genericClient.genericPost<ICreateUserResponse>(
        endpoint,
        payload
      );
    }
  }

  createPartnerUser(
    payload: IPartnerUserForm,
    id?: string
  ): Observable<ICreateUserResponse> {
    const endpoint = EndpointsParameter.PartnerUsersList;

    if (id) {
      return this.genericClient.genericPatch<ICreateUserResponse>(
        `${endpoint}/${id}`,
        payload
      );
    } else {
      return this.genericClient.genericPost<ICreateUserResponse>(
        endpoint,
        payload
      );
    }
  }

  createInternalUser(
    payload: IInternalUserForm,
    id?: string
  ): Observable<ICreateUserResponse> {
    const endpoint = EndpointsParameter.InternalUsersList;

    if (id) {
      return this.genericClient.genericPatch<ICreateUserResponse>(
        `${endpoint}/${id}`,
        payload
      );
    } else {
      return this.genericClient.genericPost<ICreateUserResponse>(
        endpoint,
        payload
      );
    }
  }

  deleteMerchantAssociatedAccount(userId: string, selectedId: string) {
    const endpoint = `${GenericHelper.replaceUrl(
      EndpointsParameter.MerchantUserAssociatedAccount,
      userId
    )}/${selectedId}`;

    return this.genericClient.genericDelete<IResponseBase<null>>(endpoint);
  }

  deletePartnerAssociatedAccount(userId: string, selectedId: string) {
    const endpoint = `${GenericHelper.replaceUrl(
      EndpointsParameter.PartnerUserAssociatedAccount,
      userId
    )}/${selectedId}`;

    return this.genericClient.genericDelete<IResponseBase<null>>(endpoint);
  }

  deletePartnerAssociatedBin(userId: string, selectedId: string) {
    const endpoint = `${GenericHelper.replaceUrl(
      EndpointsParameter.PartnerUserAssociatedBins,
      userId
    )}/${selectedId}`;

    return this.genericClient.genericDelete<IResponseBase<null>>(endpoint);
  }
}
