import {
  IResponseBase,
  IResponseData,
} from '@interfaces/generic/responses.interface';
import {
  EMspApplicationStatus,
  EMspMerchantApplicationListParams,
  EMspMerchantApplicationListSort,
} from '@parameters/authentication/msp-merchant-application-list.parameter';

export interface IMspMerchantApplication {
  merchantId: string;
  businessName: string;
  createdAt: string;
  signatureRole: string; //primary_signature, secondary_signature
  currentPageId: string;
  status: string; //in_progress,active,
  offerId: string;
  currentProspectStatusId: string;
  progress: number;
  signatureRoleLabel: string;
  currentMerchantStatusId: string;
}

export interface IMspStoredMerchantApplicationItem {
  selectedApplicationMerchantBusiness: string;
  selectedApplicationSignatureRole: string;
  selectedApplicationMerchantId: string;
  selectedApplicationPageId: string;
  selectedApplicationStatus: string;
  selectedApplicationOfferId: string;
  selectedApplicationCurrentProspectStatusId: string;
}

export interface IMspMerchantApplicationListResponse
  extends IResponseBase<IResponseData<IMspMerchantApplication>> {}

export interface IMspMerchantApplicationListParams {
  [EMspMerchantApplicationListParams.status]: EMspApplicationStatus[];
  [EMspMerchantApplicationListParams.sort]: EMspMerchantApplicationListSort;
  [EMspMerchantApplicationListParams.page]: number;
  [EMspMerchantApplicationListParams.size]: number;
  [EMspMerchantApplicationListParams.search]: string;
}

export const CMspMerchantApplicationParamsDefault: IMspMerchantApplicationListParams =
  {
    [EMspMerchantApplicationListParams.status]: [
      EMspApplicationStatus.active,
      EMspApplicationStatus.progress,
    ],
    [EMspMerchantApplicationListParams.sort]:
      EMspMerchantApplicationListSort.createdAtDesc,
    [EMspMerchantApplicationListParams.page]: 1,
    [EMspMerchantApplicationListParams.size]: 10,
    [EMspMerchantApplicationListParams.search]: '',
  };
