import { IAccountAssociatedMerchant } from '@interfaces/accounts/account-merchants-list.interface';
import { IResponseData } from '@interfaces/generic/responses.interface';
import { ResponsePagination } from '@models/generic/responses.model';
import { EMerchantStatus } from '@parameters/merchant/merchant/merchant.parameter';
import { EProspectStatus } from '@parameters/prospects/prospects.parameter';

export class AccountAssociatedMerchant {
  public id: string;
  public dbaName: string;
  public legalName: string;
  public mid: string;
  public createdAt: string;
  public merchantStatus: EMerchantStatus;
  public prospectStatus: EProspectStatus;

  constructor(data: IAccountAssociatedMerchant) {
    this.id = data?.id;
    this.dbaName = data?.dbaName;
    this.legalName = data?.legalName;
    this.mid = data?.mid;
    this.createdAt = data?.createdAt;
    this.merchantStatus = data?.merchantStatus;
    this.prospectStatus = data?.prospectStatus;
  }
}

export class AccountAssociatedMerchantsList {
  public list: AccountAssociatedMerchant[];
  public pagination: ResponsePagination;

  constructor(res: IResponseData<IAccountAssociatedMerchant>) {
    this.pagination = new ResponsePagination(res?.summary);
    this.list = res?.rows?.length
      ? res?.rows?.map((account) => new AccountAssociatedMerchant(account))
      : [];
  }
}
