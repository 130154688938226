import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { routesArray } from 'src/app/shared/helpers/routes.enum';
import { HttpsService } from 'src/app/shared/services/base.service';
import { PORTAL } from '@parameters/routing/portal.parameter';
import { BUSINESS_PATH } from '@parameters/routing/business.parameter';

@Injectable()
export class StepResolveService {
  constructor(private _router: Router, private http: HttpsService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let status = false;
    this.http.httpGet('businessStatus').subscribe((res: any) => {
      if (res.success) {
        const data = res?.data;
        if (data.status === 'draft') {
          let routePoint = state.url.split('/')[2];
          localStorage.setItem('isOnBoarding', 'false');
          let matched = (routesArray || []).find(
            (x) => x.stepNumber == data.onBoardingProgress
          );
          let restrict = (routesArray || []).find(
            (x) => x.stepNumber == matched.stepNumber
          );
          let set = Number(routePoint);
          if (set == restrict.stepNumber) {
            return true;
          } else if (data.onBoardingProgress === 0) {
            this._router.navigate(['/onboarding/business-detail']);
          } else {
            this._router.navigate(['/onboarding/' + restrict.stepName]);
          }
          status = true;
        } else if (data.status === 'completed') {
          this._router.navigate([
            `/${BUSINESS_PATH.portal}/${PORTAL.merchant}/dashboard/merchant`,
          ]);
          status = false;
        } else {
          this._router.navigate(['/login']);
          status = false;
        }
      }
    });
    return status;
  }
}

@Injectable()
export class DashBoardResolveService {
  constructor(private _router: Router, private http: HttpsService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let status = false;
    this.http.httpGet('businessStatus').subscribe((res: any) => {
      if (res.success) {
        const data = res?.data;
        if (data.status === 'completed') {
          status = true;
        } else {
          this._router.navigate(['/login']);
          status = false;
        }
      }
    });
    return status;
  }
}
