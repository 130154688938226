import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { EShoppingCart } from '@parameters/filter/ecommerce.parameter';

//OCE: Onboarding Customization Edit

export enum EOCEShoppingCartIntegrationFormControlName {
  optionList = 'optionList',
  hidden = 'hidden',
  active = 'active',
}

export enum EOCEShoppingCartIntegrationOptionFormControlName {
  id = 'id',
  name = 'name',
  predefined = 'predefined',
  preselected = 'preselected',
}

export interface IOCEShoppingCartIntegrationFormValue {
  [EOCEShoppingCartIntegrationFormControlName.optionList]: IOCEShoppingCartIntegrationOptionFormValue[];
  [EOCEShoppingCartIntegrationFormControlName.hidden]: boolean;
  [EOCEShoppingCartIntegrationFormControlName.active]: boolean;
}

export interface IOCEShoppingCartIntegrationOptionFormValue {
  [EOCEShoppingCartIntegrationOptionFormControlName.id]: EShoppingCart;
  [EOCEShoppingCartIntegrationOptionFormControlName.name]: string;
  [EOCEShoppingCartIntegrationOptionFormControlName.predefined]: boolean;
  [EOCEShoppingCartIntegrationOptionFormControlName.preselected]: boolean;
}

export interface IOCEShoppingCartIntegrationForm {
  [EOCEShoppingCartIntegrationFormControlName.optionList]: FormArray<
    FormGroup<IOCEShoppingCartIntegrationOptionForm>
  >;
  [EOCEShoppingCartIntegrationFormControlName.hidden]: FormControl<
    IOCEShoppingCartIntegrationFormValue[EOCEShoppingCartIntegrationFormControlName.hidden]
  >;
  [EOCEShoppingCartIntegrationFormControlName.active]: FormControl<
    IOCEShoppingCartIntegrationFormValue[EOCEShoppingCartIntegrationFormControlName.active]
  >;
}

export interface IOCEShoppingCartIntegrationOptionForm {
  [EOCEShoppingCartIntegrationOptionFormControlName.id]: FormControl<
    IOCEShoppingCartIntegrationOptionFormValue[EOCEShoppingCartIntegrationOptionFormControlName.id]
  >;
  [EOCEShoppingCartIntegrationOptionFormControlName.name]: FormControl<
    IOCEShoppingCartIntegrationOptionFormValue[EOCEShoppingCartIntegrationOptionFormControlName.name]
  >;
  [EOCEShoppingCartIntegrationOptionFormControlName.predefined]: FormControl<
    IOCEShoppingCartIntegrationOptionFormValue[EOCEShoppingCartIntegrationOptionFormControlName.predefined]
  >;
  [EOCEShoppingCartIntegrationOptionFormControlName.preselected]: FormControl<
    IOCEShoppingCartIntegrationOptionFormValue[EOCEShoppingCartIntegrationOptionFormControlName.preselected]
  >;
}

export const COCEShoppingCartIntegrationFormDefault: IOCEShoppingCartIntegrationFormValue =
  {
    [EOCEShoppingCartIntegrationFormControlName.optionList]: [],
    [EOCEShoppingCartIntegrationFormControlName.hidden]: false,
    [EOCEShoppingCartIntegrationFormControlName.active]: false,
  };
