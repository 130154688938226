import {
  CDateFilterListValue,
  EDateFilter,
} from '@parameters/filter/date.parameter';

export const enum EReportsDisputeListParams {
  size = 'limit',
  page = 'page',
  sort = 'order_by',
  search = 'search',
  startDate = 'start_date',
  endDate = 'end_date',
  midList = 'mids',
  binList = 'bins',
  cardBrandList = 'card_brands',
  disputeStates = 'dispute_state',
  searchBy = 'date_filter_by',
}

export const CReportsDisputeListParamsDate =
  CDateFilterListValue[EDateFilter.last30Days];

export const enum EReportsDisputeCSVParams {
  search = 'search',
  startDate = 'startDate',
  endDate = 'endDate',
  midList = 'mids',
  binList = 'bins',
  cardBrandList = 'cardBrands',
  disputeStates = 'disputeState',
  searchBy = 'dateFilterBy',
}

export const enum EReportsSearchBy {
  loadDate = 'load_date',
  lastModifiedDate = 'last_modified_date',
}

export const CReportsSearchByName = {
  [EReportsSearchBy.loadDate]: 'Processed Date',
  [EReportsSearchBy.lastModifiedDate]: 'Last Update',
};

export const CReportsSearchByList = [
  {
    name: CReportsSearchByName[EReportsSearchBy.loadDate],
    value: EReportsSearchBy.loadDate,
  },
  {
    name: CReportsSearchByName[EReportsSearchBy.lastModifiedDate],
    value: EReportsSearchBy.lastModifiedDate,
  },
];

export enum EDisputeSourceType {
  ACH = 'ach',
  CARD = 'card',
}
