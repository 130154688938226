import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundWithDecimal',
})
export class RoundWithDecimalPipe implements PipeTransform {
  /**
   * Round Decimal Pipe
   * @param value
   * @param decimalCount
   * @returns
   */
  transform(value: number, decimalCount: number = 2) {
    if (isNaN(value) || isNaN(decimalCount) || decimalCount < 0) {
      return NaN;
    }

    return Number(value).toFixed(decimalCount);
  }
}
