import { IPartnerAccountListParams } from '@interfaces/developers/partner-account-select.interface';

export enum EPartnerAccountListParams {
  page = 'page',
  limit = 'limit',
  search = 'search',
}

export const CPartnerAccountListParams: IPartnerAccountListParams = {
  [EPartnerAccountListParams.limit]: 10,
  [EPartnerAccountListParams.page]: 1,
  [EPartnerAccountListParams.search]: '',
};
