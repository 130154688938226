<ng-container *ngIf="isContentLoaded">
  <div [class.details--page--loading]="isContentLoaded"></div>
</ng-container>

<ng-container *ngIf="validateSidebar">
  <!-- NAVBAR -->
  <ng-container *ngTemplateOutlet="navbar"></ng-container>
  <!--/ NAVBAR -->

  <!-- MENU -->
  <ng-container *ngTemplateOutlet="menu"></ng-container>
  <!--/ MENU -->

  <!-- APP-CONTENT -->
  <div class="app-content content">
    <div class="content-overlay"></div>
    <div
      class="header-navbar-shadow"
      *ngIf="!coreConfig.layout.navbar.hidden"
    ></div>
    <!-- CONTENT -->
    <content></content>
    <!--/ CONTENT -->
  </div>
  <!--/ APP-CONTENT -->

  <!-- PARTIALS ---------------------------------------------------------------------------------------- -->

  <!-- NAVBAR -->
  <ng-template #navbar>
    <app-navbar
      *ngIf="!coreConfig.layout.navbar.hidden"
      class="header-navbar navbar align-items-center navbar-shadow navbar-light floating-nav container-fluid"
      [class.margin-0]="validateSidebar && !hasDashboardRoute"
    >
    </app-navbar>
  </ng-template>
  <!--/ NAVBAR -->

  <!-- MENU -->
  <ng-template #menu>
    <core-sidebar
      name="menu"
      [collapsed]="false"
      *ngIf="!coreConfig.layout.menu.hidden && hasDashboardRoute"
      class="main-menu menu-fixed menu-accordio menu-shadow"
      [ngClass]="['menu-light', 'expanded']"
    >
    </core-sidebar>
  </ng-template>
  <!--/ MENU -->
</ng-container>

<ng-container
  *ngIf="
    !validateSidebar && sidebarData && sidebarData.length && !isContentLoaded
  "
>
  <app-sidebar-container>
    <app-sidenav
      #sidenav
      [opened]="sidebarOpened"
      [mode]="sidebarMode"
      (toggleSidenav)="handleSidenav($event)"
      class="sidenav-container"
    >
      <ng-container *ngIf="sidebarDashboardType == dashboardType.PAYMENT">
        <app-payment-account-dropdown></app-payment-account-dropdown>
      </ng-container>

      <ng-container *ngIf="sidebarDashboardType == dashboardType.DEVELOPERS">
        <app-partner-account-dropdown></app-partner-account-dropdown>
      </ng-container>

      <app-menu-list class="menu-items-container">
        <ng-container *ngIf="sidebarDashboardType === dashboardType.Payment">
          Payment Select
        </ng-container>

        <ng-container *ngFor="let key of sidebarData; let i = index">
          <app-menu-item [dataSet]="key"></app-menu-item>
        </ng-container>
      </app-menu-list>
    </app-sidenav>
    <app-sidebar-content>
      <app-top-navbar (toggleSidebar)="toggleSidebarMain()"></app-top-navbar>

      <div class="sidebar-content-container" id="sidebar-container">
        <!-- CONTENT -->
        <router-outlet #outlet="outlet"></router-outlet>
        <!--/ CONTENT -->
      </div>
    </app-sidebar-content>
  </app-sidebar-container>
</ng-container>
