import { EntityRelationship } from '@atoms/relationships-select/models/relationships-select.model';
import {
  IOfferAchCoreFees,
  IOfferAchCreditFees,
  IOfferAchDebitFees,
  IOfferCashDiscountCoreFees,
  IOfferFlatRateCoreFees,
  IOfferInterchangePlusCoreFees,
  IOfferOtherAgentFees,
  IOfferOtherFee,
  IOfferOtherStandardFees,
  IOfferPricingPlanData,
  IOfferPricingType,
  IOfferTieredCoreFees,
  IPreferredDomain,
  IPricingMinimumValues,
} from '@interfaces/offer-pricing/offer-pricing.interface';
import { ETieredOfferType } from '@molecules/multi-step-pricing/parameters/add-pricing-form.parameter';
import {
  EAchPaymentType,
  EPaymentMethodType,
} from '@parameters/offer-pricing/offer-pricing.parameter';

export class OfferPricingType {
  public id: string;
  public name: string;

  constructor(iOfferPricingType: IOfferPricingType) {
    this.id = iOfferPricingType?.id;
    this.name = iOfferPricingType?.name;
  }
}

export class PreferredDomain {
  public domain: string;
  public entityId: string;
  public id: string;
  public primaryColor: string;
  public secondaryColor: string;
  public slug: string;

  constructor(iPreferredDomain: Partial<IPreferredDomain>) {
    this.domain = iPreferredDomain?.domain;
    this.entityId = iPreferredDomain?.entityId;
    this.id = iPreferredDomain?.id;
    this.primaryColor = iPreferredDomain?.primaryColor;
    this.secondaryColor = iPreferredDomain?.secondaryColor;
    this.slug = iPreferredDomain?.slug;
  }
}

export class OfferFlatRateCoreFees {
  public flatRateSwipedPercentage: number;
  public flatRateSwipedPerItem: number;
  public flatRateKeyedInPercentage: number;
  public flatRateKeyedInPerItem: number;

  constructor(iOfferFlatRateCoreFees: IOfferFlatRateCoreFees) {
    this.flatRateSwipedPercentage = Number(
      iOfferFlatRateCoreFees?.flatRateSwipedPercentage
    );
    this.flatRateSwipedPerItem = Number(
      iOfferFlatRateCoreFees?.flatRateSwipedPerItem
    );
    this.flatRateKeyedInPercentage = Number(
      iOfferFlatRateCoreFees?.flatRateKeyedInPercentage
    );
    this.flatRateKeyedInPerItem = Number(
      iOfferFlatRateCoreFees?.flatRateKeyedInPerItem
    );
  }
}

export class OfferAchDebitFees {
  public achDebitDiscountFee: number;
  public achDebitPerItemFee: number;
  public achDebitMaxFeePerTransaction: number;
  public achDebitRefundFee: number;

  constructor(iOfferAchDebitFee: IOfferAchDebitFees) {
    this.achDebitDiscountFee = Number(iOfferAchDebitFee?.achDebitDiscountFee);
    this.achDebitPerItemFee = Number(iOfferAchDebitFee?.achDebitPerItemFee);
    this.achDebitMaxFeePerTransaction = Number(
      iOfferAchDebitFee?.achDebitMaxFeePerTransaction
    );
    this.achDebitRefundFee = Number(iOfferAchDebitFee?.achDebitRefundFee);
  }
}

export class OfferAchCreditFees {
  public achCreditDiscountFee: number;
  public achCreditPerItemFee: number;
  public achCreditMaxFeePerTransaction: number;

  constructor(iOfferAchCreditFees: IOfferAchCreditFees) {
    this.achCreditDiscountFee = Number(
      iOfferAchCreditFees?.achCreditDiscountFee
    );
    this.achCreditPerItemFee = Number(iOfferAchCreditFees?.achCreditPerItemFee);
    this.achCreditMaxFeePerTransaction = Number(
      iOfferAchCreditFees?.achCreditMaxFeePerTransaction
    );
  }
}

export class OfferAchCoreFees {
  public achPricingType: EAchPaymentType;
  public debits: OfferAchDebitFees;
  public credits: OfferAchCreditFees;

  constructor(iOfferAchCoreFees: IOfferAchCoreFees) {
    this.achPricingType = iOfferAchCoreFees?.achPricingType;
    this.debits = iOfferAchCoreFees?.debits
      ? new OfferAchDebitFees(iOfferAchCoreFees?.debits)
      : null;
    this.credits = iOfferAchCoreFees?.credits
      ? new OfferAchCreditFees(iOfferAchCoreFees?.credits)
      : null;
  }
}

export class OfferInterchangePlusCoreFees {
  public intPlusDiscountRatePercentage: number;
  public intPlusAuthFee: number;
  public intPlusAmexDiscountRatePercentage: number;
  public intPlusAmexAuthFee: number;
  public intPlusPerItem: number;
  public highlightPerItemFees: boolean;
  public intPlusPinDebitAuthFee: number;
  public intPlusEbtAuthFee: number;

  constructor(iOfferInterchangePlusCoreFees: IOfferInterchangePlusCoreFees) {
    this.intPlusDiscountRatePercentage = Number(
      iOfferInterchangePlusCoreFees?.intPlusDiscountRatePercentage
    );
    this.intPlusAuthFee = Number(iOfferInterchangePlusCoreFees?.intPlusAuthFee);
    this.intPlusAmexDiscountRatePercentage = Number(
      iOfferInterchangePlusCoreFees?.intPlusAmexDiscountRatePercentage
    );
    this.intPlusAmexAuthFee = Number(
      iOfferInterchangePlusCoreFees?.intPlusAmexAuthFee
    );
    this.intPlusPerItem = Number(iOfferInterchangePlusCoreFees?.intPlusPerItem);
    this.highlightPerItemFees =
      iOfferInterchangePlusCoreFees?.highlightPerItemFees;
    this.intPlusPinDebitAuthFee = Number(
      iOfferInterchangePlusCoreFees?.intPlusPinDebitAuthFee
    );
    this.intPlusEbtAuthFee = Number(
      iOfferInterchangePlusCoreFees?.intPlusEbtAuthFee
    );
  }
}

export class OfferTieredCoreFees {
  public tieredDiscountRatePercentage: number;
  public tieredMidQualRatePercentage: number;
  public tieredNonQualRatePercentage: number;
  public tieredAmexDiscountRatePercentage: number;
  public tieredSignatureDebitPercentage: number;
  public tieredDiscountRatePerItem: number;
  public tieredMidQualRatePerItem: number;
  public tieredNonQualRatePerItem: number;
  public tieredAmexDiscountRatePerItem: number;
  public tieredSignatureDebitPerItem: number;
  public tieredAuthFee: number;
  public tieredAmexAuthFee: number;
  public tieredPinDebitAuthFee: number;
  public tieredEbtAuthFee: number;
  public highlightAuthFees: boolean;
  public tieredType: ETieredOfferType;

  constructor(iOfferTieredCoreFees: IOfferTieredCoreFees) {
    this.tieredDiscountRatePercentage = Number(
      iOfferTieredCoreFees?.tieredDiscountRatePercentage
    );
    this.tieredMidQualRatePercentage = Number(
      iOfferTieredCoreFees?.tieredMidQualRatePercentage
    );
    this.tieredNonQualRatePercentage = Number(
      iOfferTieredCoreFees?.tieredNonQualRatePercentage
    );
    this.tieredAmexDiscountRatePercentage = Number(
      iOfferTieredCoreFees?.tieredAmexDiscountRatePercentage
    );
    this.tieredSignatureDebitPercentage = Number(
      iOfferTieredCoreFees?.tieredSignatureDebitPercentage
    );
    this.tieredDiscountRatePerItem = Number(
      iOfferTieredCoreFees?.tieredDiscountRatePerItem
    );
    this.tieredMidQualRatePerItem = Number(
      iOfferTieredCoreFees?.tieredMidQualRatePerItem
    );
    this.tieredNonQualRatePerItem = Number(
      iOfferTieredCoreFees?.tieredNonQualRatePerItem
    );
    this.tieredAmexDiscountRatePerItem = Number(
      iOfferTieredCoreFees?.tieredAmexDiscountRatePerItem
    );
    this.tieredSignatureDebitPerItem = Number(
      iOfferTieredCoreFees?.tieredSignatureDebitPerItem
    );
    this.tieredAuthFee = Number(iOfferTieredCoreFees?.tieredAuthFee);
    this.tieredAmexAuthFee = Number(iOfferTieredCoreFees?.tieredAmexAuthFee);
    this.tieredPinDebitAuthFee = Number(
      iOfferTieredCoreFees?.tieredPinDebitAuthFee
    );
    this.tieredEbtAuthFee = Number(iOfferTieredCoreFees?.tieredEbtAuthFee);
    this.highlightAuthFees = iOfferTieredCoreFees?.highlightAuthFees;
    this.tieredType = iOfferTieredCoreFees?.tieredType;
  }
}

export class OfferCashDiscountCoreFees {
  public programRecurringFee: number;
  public cashDiscountProgramRatePercentage: number;

  constructor(iOfferCashDiscountCoreFees: IOfferCashDiscountCoreFees) {
    this.programRecurringFee = Number(
      iOfferCashDiscountCoreFees?.programRecurringFee
    );
    this.cashDiscountProgramRatePercentage = Number(
      iOfferCashDiscountCoreFees?.cashDiscountProgramRatePercentage
    );
  }
}

export class OfferOtherAgentFees {
  public showOtherAgentFee: boolean;
  public avsFee: number;
  public avsFeeRecurrence: number;
  public batchFee: number;
  public batchFeeRecurrence: number;
  public gatewayMonthlyFee: number;
  public gatewaySetUpFee: number;
  public gatewayTransFee: number;
  public mobileMonthlyFee: number;
  public mobilePerTransFee: number;
  public mobileSetUpFee: number;
  public onlineReportingFee: number;
  public onlineReportingFeeRecurrence: number;
  public otherFee: number;
  public otherFeeDescription: string;
  public otherFeeName: string;
  public otherFeeRecurrence: number;
  public pinDebitAccessFee: number;
  public pinDebitAccessFeeRecurrence: number;
  public showAvsFee: boolean;
  public showBatchFee: boolean;
  public showGatewayMonthlyFee: boolean;
  public showGatewaySetUpFee: boolean;
  public showGatewayTransFee: boolean;
  public showMobileMonthlyFee: boolean;
  public showMobilePerTransFee: boolean;
  public showMobileSetUpFee: boolean;
  public showOnlineReportingFee: boolean;
  public showOtherFee: boolean;
  public showPinDebitAccessFee: boolean;
  public showStatementFee: boolean;
  public showTerminalWarrantyFee: boolean;
  public showWirelessMonthlyFee: boolean;
  public showWirelessPerTransFee: boolean;
  public showWirelessSetUpFee: boolean;
  public statementFee: number;
  public statementFeeRecurrence: number;
  public terminalWarrantyFee: number;
  public terminalWarrantyFeeRecurrence: number;
  public wirelessMonthlyFee: number;
  public wirelessPerTransFee: number;
  public wirelessSetUpFee: number;
  public merchantClubFee: number;
  public showMerchantClubFee: boolean;

  // ach fees
  public achBatchFee: number;
  public showAchBatchFee: boolean;
  public achSetupFee: number;
  public showAchSetupFee: boolean;

  constructor(
    iOfferOtherAgentFees: IOfferOtherAgentFees,
    paymentMethodType?: EPaymentMethodType
  ) {
    this.avsFee = validateCardTypeValue<number>(
      Number(iOfferOtherAgentFees?.avsFee),
      paymentMethodType
    );
    this.avsFeeRecurrence = validateCardTypeValue<number>(
      Number(iOfferOtherAgentFees?.avsFeeRecurrence),
      paymentMethodType
    );
    this.batchFee = validateCardTypeValue<number>(
      Number(iOfferOtherAgentFees?.batchFee),
      paymentMethodType
    );
    this.batchFeeRecurrence = validateCardTypeValue<number>(
      Number(iOfferOtherAgentFees?.batchFeeRecurrence),
      paymentMethodType
    );
    this.gatewayMonthlyFee = validateCardTypeValue<number>(
      Number(iOfferOtherAgentFees?.gatewayMonthlyFee),
      paymentMethodType
    );
    this.gatewaySetUpFee = validateCardTypeValue<number>(
      Number(iOfferOtherAgentFees?.gatewaySetUpFee),
      paymentMethodType
    );
    this.gatewayTransFee = validateCardTypeValue<number>(
      Number(iOfferOtherAgentFees?.gatewayTransFee),
      paymentMethodType
    );
    this.mobileMonthlyFee = validateCardTypeValue<number>(
      Number(iOfferOtherAgentFees?.mobileMonthlyFee),
      paymentMethodType
    );
    this.mobilePerTransFee = validateCardTypeValue<number>(
      Number(iOfferOtherAgentFees?.mobilePerTransFee),
      paymentMethodType
    );
    this.mobileSetUpFee = validateCardTypeValue<number>(
      Number(iOfferOtherAgentFees?.mobileSetUpFee),
      paymentMethodType
    );
    this.onlineReportingFee = validateCardTypeValue<number>(
      Number(iOfferOtherAgentFees?.onlineReportingFee),
      paymentMethodType
    );
    this.onlineReportingFeeRecurrence = validateCardTypeValue<number>(
      Number(iOfferOtherAgentFees?.onlineReportingFeeRecurrence),
      paymentMethodType
    );
    const _otherFee = iOfferOtherAgentFees?.otherFee as IOfferOtherFee;
    this.otherFee = _otherFee?.fee
      ? validateCardTypeValue<number>(Number(_otherFee?.fee), paymentMethodType)
      : validateCardTypeValue<number>(
          Number(iOfferOtherAgentFees?.otherFee),
          paymentMethodType
        );
    this.otherFeeDescription = validateCardTypeValue<string>(
      iOfferOtherAgentFees?.otherFeeDescription,
      paymentMethodType
    );
    this.otherFeeName = _otherFee?.name
      ? validateCardTypeValue<string>(_otherFee?.name, paymentMethodType)
      : validateCardTypeValue<string>(
          iOfferOtherAgentFees?.otherFeeName,
          paymentMethodType
        );
    this.otherFeeRecurrence = validateCardTypeValue<number>(
      Number(iOfferOtherAgentFees?.otherFeeRecurrence),
      paymentMethodType
    );
    this.pinDebitAccessFee = validateCardTypeValue<number>(
      Number(iOfferOtherAgentFees?.pinDebitAccessFee),
      paymentMethodType
    );
    this.pinDebitAccessFeeRecurrence = validateCardTypeValue<number>(
      Number(iOfferOtherAgentFees?.pinDebitAccessFeeRecurrence),
      paymentMethodType
    );
    this.showAvsFee = validateCardTypeValue<boolean>(
      iOfferOtherAgentFees?.showAvsFee,
      paymentMethodType
    );
    this.showBatchFee = validateCardTypeValue<boolean>(
      iOfferOtherAgentFees?.showBatchFee,
      paymentMethodType
    );
    this.showGatewayMonthlyFee = validateCardTypeValue<boolean>(
      iOfferOtherAgentFees?.showGatewayMonthlyFee,
      paymentMethodType
    );
    this.showGatewaySetUpFee = validateCardTypeValue<boolean>(
      iOfferOtherAgentFees?.showGatewaySetUpFee,
      paymentMethodType
    );
    this.showGatewayTransFee = validateCardTypeValue<boolean>(
      iOfferOtherAgentFees?.showGatewayTransFee,
      paymentMethodType
    );
    this.showMobileMonthlyFee = validateCardTypeValue<boolean>(
      iOfferOtherAgentFees?.showMobileMonthlyFee,
      paymentMethodType
    );
    this.showMobilePerTransFee = validateCardTypeValue<boolean>(
      iOfferOtherAgentFees?.showMobilePerTransFee,
      paymentMethodType
    );
    this.showMobileSetUpFee = validateCardTypeValue<boolean>(
      iOfferOtherAgentFees?.showMobileSetUpFee,
      paymentMethodType
    );
    this.showOnlineReportingFee = validateCardTypeValue<boolean>(
      iOfferOtherAgentFees?.showOnlineReportingFee,
      paymentMethodType
    );
    this.showOtherFee = validateCardTypeValue<boolean>(
      iOfferOtherAgentFees?.showOtherFee,
      paymentMethodType
    );
    this.showPinDebitAccessFee = validateCardTypeValue<boolean>(
      iOfferOtherAgentFees?.showPinDebitAccessFee,
      paymentMethodType
    );
    this.showStatementFee = validateCardTypeValue<boolean>(
      iOfferOtherAgentFees?.showStatementFee,
      paymentMethodType
    );
    this.showTerminalWarrantyFee = validateCardTypeValue<boolean>(
      iOfferOtherAgentFees?.showTerminalWarrantyFee,
      paymentMethodType
    );
    this.showWirelessMonthlyFee = validateCardTypeValue<boolean>(
      iOfferOtherAgentFees?.showWirelessMonthlyFee,
      paymentMethodType
    );
    this.showWirelessPerTransFee = validateCardTypeValue<boolean>(
      iOfferOtherAgentFees?.showWirelessPerTransFee,
      paymentMethodType
    );
    this.showWirelessSetUpFee = validateCardTypeValue<boolean>(
      iOfferOtherAgentFees?.showWirelessSetUpFee,
      paymentMethodType
    );
    this.statementFee = validateCardTypeValue<number>(
      Number(iOfferOtherAgentFees?.statementFee),
      paymentMethodType
    );
    this.statementFeeRecurrence = validateCardTypeValue<number>(
      Number(iOfferOtherAgentFees?.statementFeeRecurrence),
      paymentMethodType
    );
    this.terminalWarrantyFee = validateCardTypeValue<number>(
      Number(iOfferOtherAgentFees?.terminalWarrantyFee),
      paymentMethodType
    );
    this.terminalWarrantyFeeRecurrence = validateCardTypeValue<number>(
      Number(iOfferOtherAgentFees?.terminalWarrantyFeeRecurrence),
      paymentMethodType
    );
    this.wirelessMonthlyFee = validateCardTypeValue<number>(
      Number(iOfferOtherAgentFees?.wirelessMonthlyFee),
      paymentMethodType
    );
    this.wirelessPerTransFee = validateCardTypeValue<number>(
      Number(iOfferOtherAgentFees?.wirelessPerTransFee),
      paymentMethodType
    );
    this.wirelessSetUpFee = validateCardTypeValue<number>(
      Number(iOfferOtherAgentFees?.wirelessSetUpFee),
      paymentMethodType
    );

    this.merchantClubFee = validateCardTypeValue<number>(
      Number(iOfferOtherAgentFees?.merchantClubFee),
      paymentMethodType
    );
    this.showMerchantClubFee = validateCardTypeValue<boolean>(
      iOfferOtherAgentFees?.showMerchantClubFee,
      paymentMethodType
    );

    // ach fees
    this.achBatchFee = validateAchTypeValue<number>(
      Number(iOfferOtherAgentFees?.achBatchFee),
      paymentMethodType
    );
    this.showAchBatchFee = validateAchTypeValue<boolean>(
      iOfferOtherAgentFees?.showAchBatchFee,
      paymentMethodType
    );

    this.achSetupFee = validateAchTypeValue<number>(
      Number(iOfferOtherAgentFees?.achSetupFee),
      paymentMethodType
    );
    this.showAchSetupFee = validateAchTypeValue<boolean>(
      iOfferOtherAgentFees?.showAchSetupFee,
      paymentMethodType
    );

    this.showOtherAgentFee = iOfferOtherAgentFees
      ? hasTruthyValue(iOfferOtherAgentFees)
      : false;
  }
}

export class OfferOtherStandardFees {
  public showOtherStandardFee: boolean;
  public voiceAuthFee: number;
  public showVoiceAuthFee: boolean;
  public retrievalRequestFee: number;
  public showRetrievalRequestFee: boolean;
  public chargebackFee: number;
  public showChargebackFee: boolean;
  public achRejectFee: number;
  public showAchRejectFee: boolean;
  public rdrHandlingFee: number;
  public showRdrHandlingFee: boolean;
  public edgepaySafeguardFee: number;
  public showEdgepaySafeguardFee: boolean;
  public annualAcctMaintenanceFee: number;
  public showAnnualAcctMaintenanceFee: boolean;
  public accountChangeFee: number;
  public showAccountChangeFee: boolean;
  public monthlyMinimumFee: number;
  public showMonthlyMinimumFee: boolean;
  public ecommComplianceFee: number;
  public showEcommComplianceFee: boolean;
  public qtlyPciComplianceFee: number;
  public showQtlyPciComplianceFee: boolean;
  public chargebackHighRiskFee: number;
  public showChargebackHighRiskFee: boolean;
  public chargebackReversalFee: number;
  public showChargebackReversalFee: boolean;
  public arbitrationFee: number;
  public showArbitrationFee: boolean;
  public highRiskRegistrationFee: number;
  public showHighRiskRegistrationFee: boolean;
  public acctMaintenanceFee: number;
  public showAcctMaintenanceFee: boolean;
  public pciMonthlyFee: number;
  public showPciMonthlyFee: boolean;
  public highRiskAdminFee: number;
  public showHighRiskAdminFee: boolean;
  public platformFee: number;
  public showPlatformFee: boolean;

  // ach keys
  public achCorrectionNoticeFee: number;
  public showAchCorrectionNoticeFee: boolean;
  public achAdminReturnFee: number;
  public showAchAdminReturnFee: boolean;
  public achUnauthorizedReturnFee: number;
  public showAchUnauthorizedReturnFee: boolean;
  public achAnnualSubscriptionFee: number;
  public showAchAnnualSubscriptionFee: boolean;
  public achMonthlyMinFee: number;
  public showAchMonthlyMinFee: boolean;
  public achRejectFee2: number;
  public showAchRejectFee2: boolean;

  constructor(
    iOfferOtherStandardFees: IOfferOtherStandardFees,
    paymentMethodType?: EPaymentMethodType
  ) {
    this.voiceAuthFee = validateCardTypeValue<number>(
      Number(iOfferOtherStandardFees?.voiceAuthFee),
      paymentMethodType
    );
    this.showVoiceAuthFee = validateCardTypeValue<boolean>(
      iOfferOtherStandardFees?.showVoiceAuthFee,
      paymentMethodType
    );
    this.retrievalRequestFee = validateCardTypeValue<number>(
      Number(iOfferOtherStandardFees?.retrievalRequestFee),
      paymentMethodType
    );
    this.showRetrievalRequestFee = validateCardTypeValue<boolean>(
      iOfferOtherStandardFees?.showRetrievalRequestFee,
      paymentMethodType
    );
    this.chargebackFee = validateCardTypeValue<number>(
      Number(iOfferOtherStandardFees?.chargebackFee),
      paymentMethodType
    );
    this.showChargebackFee = validateCardTypeValue<boolean>(
      iOfferOtherStandardFees?.showChargebackFee,
      paymentMethodType
    );
    this.achRejectFee = validateCardTypeValue<number>(
      Number(iOfferOtherStandardFees?.achRejectFee),
      paymentMethodType
    );
    this.showAchRejectFee = validateCardTypeValue<boolean>(
      iOfferOtherStandardFees?.showAchRejectFee,
      paymentMethodType
    );
    this.rdrHandlingFee = validateCardTypeValue<number>(
      Number(iOfferOtherStandardFees?.rdrHandlingFee),
      paymentMethodType
    );
    this.showRdrHandlingFee = validateCardTypeValue<boolean>(
      iOfferOtherStandardFees?.showRdrHandlingFee,
      paymentMethodType
    );
    this.edgepaySafeguardFee = validateCardTypeValue<number>(
      Number(iOfferOtherStandardFees?.edgepaySafeguardFee),
      paymentMethodType
    );
    this.showEdgepaySafeguardFee = validateCardTypeValue<boolean>(
      iOfferOtherStandardFees?.showEdgepaySafeguardFee,
      paymentMethodType
    );
    this.annualAcctMaintenanceFee = validateCardTypeValue<number>(
      Number(iOfferOtherStandardFees?.annualAcctMaintenanceFee),
      paymentMethodType
    );
    this.showAnnualAcctMaintenanceFee = validateCardTypeValue<boolean>(
      iOfferOtherStandardFees?.showAnnualAcctMaintenanceFee,
      paymentMethodType
    );
    this.accountChangeFee = validateCardTypeValue<number>(
      Number(iOfferOtherStandardFees?.accountChangeFee),
      paymentMethodType
    );
    this.showAccountChangeFee = validateCardTypeValue<boolean>(
      iOfferOtherStandardFees?.showAccountChangeFee,
      paymentMethodType
    );
    this.monthlyMinimumFee = validateCardTypeValue<number>(
      Number(iOfferOtherStandardFees?.monthlyMinimumFee),
      paymentMethodType
    );
    this.showMonthlyMinimumFee = validateCardTypeValue<boolean>(
      iOfferOtherStandardFees?.showMonthlyMinimumFee,
      paymentMethodType
    );
    this.ecommComplianceFee = validateCardTypeValue<number>(
      Number(iOfferOtherStandardFees?.ecommComplianceFee),
      paymentMethodType
    );
    this.showEcommComplianceFee = validateCardTypeValue<boolean>(
      iOfferOtherStandardFees?.showEcommComplianceFee,
      paymentMethodType
    );
    this.qtlyPciComplianceFee = validateCardTypeValue<number>(
      Number(iOfferOtherStandardFees?.qtlyPciComplianceFee),
      paymentMethodType
    );
    this.showQtlyPciComplianceFee = validateCardTypeValue<boolean>(
      iOfferOtherStandardFees?.showQtlyPciComplianceFee,
      paymentMethodType
    );

    this.chargebackHighRiskFee = validateCardTypeValue<number>(
      Number(iOfferOtherStandardFees?.chargebackHighRiskFee),
      paymentMethodType
    );
    this.showChargebackHighRiskFee = validateCardTypeValue<boolean>(
      iOfferOtherStandardFees?.showChargebackHighRiskFee,
      paymentMethodType
    );
    this.chargebackReversalFee = validateCardTypeValue<number>(
      Number(iOfferOtherStandardFees?.chargebackReversalFee),
      paymentMethodType
    );
    this.showChargebackReversalFee = validateCardTypeValue<boolean>(
      iOfferOtherStandardFees?.showChargebackReversalFee,
      paymentMethodType
    );
    this.arbitrationFee = validateCardTypeValue<number>(
      Number(iOfferOtherStandardFees?.arbitrationFee),
      paymentMethodType
    );
    this.showArbitrationFee = validateCardTypeValue<boolean>(
      iOfferOtherStandardFees?.showArbitrationFee,
      paymentMethodType
    );
    this.highRiskRegistrationFee = validateCardTypeValue<number>(
      Number(iOfferOtherStandardFees?.highRiskRegistrationFee),
      paymentMethodType
    );
    this.showHighRiskRegistrationFee = validateCardTypeValue<boolean>(
      iOfferOtherStandardFees?.showHighRiskRegistrationFee,
      paymentMethodType
    );
    this.acctMaintenanceFee = validateCardTypeValue<number>(
      Number(iOfferOtherStandardFees?.acctMaintenanceFee),
      paymentMethodType
    );
    this.showAcctMaintenanceFee = validateCardTypeValue<boolean>(
      iOfferOtherStandardFees?.showAcctMaintenanceFee,
      paymentMethodType
    );
    this.pciMonthlyFee = validateCardTypeValue<number>(
      Number(iOfferOtherStandardFees?.pciMonthlyFee),
      paymentMethodType
    );
    this.showPciMonthlyFee = validateCardTypeValue<boolean>(
      iOfferOtherStandardFees?.showPciMonthlyFee,
      paymentMethodType
    );
    this.highRiskAdminFee = validateCardTypeValue<number>(
      Number(iOfferOtherStandardFees?.highRiskAdminFee),
      paymentMethodType
    );
    this.showHighRiskAdminFee = validateCardTypeValue<boolean>(
      iOfferOtherStandardFees?.showHighRiskAdminFee,
      paymentMethodType
    );

    this.platformFee = validateCardTypeValue<number>(
      Number(iOfferOtherStandardFees?.platformFee),
      paymentMethodType
    );
    this.showPlatformFee = validateCardTypeValue<boolean>(
      iOfferOtherStandardFees?.showPlatformFee,
      paymentMethodType
    );

    let offerOtherStandardFee = iOfferOtherStandardFees;

    if (offerOtherStandardFee?.merchantClubFee) {
      delete offerOtherStandardFee.merchantClubFee;
    }

    if (offerOtherStandardFee?.showMerchantClubFee) {
      delete offerOtherStandardFee.showMerchantClubFee;
    }

    // ach keys
    this.achCorrectionNoticeFee = validateAchTypeValue<number>(
      Number(iOfferOtherStandardFees?.achCorrectionNoticeFee),
      paymentMethodType
    );
    this.showAchCorrectionNoticeFee = validateAchTypeValue<boolean>(
      iOfferOtherStandardFees?.showAchCorrectionNoticeFee,
      paymentMethodType
    );
    this.achAdminReturnFee = validateAchTypeValue<number>(
      Number(iOfferOtherStandardFees?.achAdminReturnFee),
      paymentMethodType
    );
    this.showAchAdminReturnFee = validateAchTypeValue<boolean>(
      iOfferOtherStandardFees?.showAchAdminReturnFee,
      paymentMethodType
    );
    this.achUnauthorizedReturnFee = validateAchTypeValue<number>(
      Number(iOfferOtherStandardFees?.achUnauthorizedReturnFee),
      paymentMethodType
    );
    this.showAchUnauthorizedReturnFee = validateAchTypeValue<boolean>(
      iOfferOtherStandardFees?.showAchUnauthorizedReturnFee,
      paymentMethodType
    );
    this.achAnnualSubscriptionFee = validateAchTypeValue<number>(
      Number(iOfferOtherStandardFees?.achAnnualSubscriptionFee),
      paymentMethodType
    );
    this.showAchAnnualSubscriptionFee = validateAchTypeValue<boolean>(
      iOfferOtherStandardFees?.showAchAnnualSubscriptionFee,
      paymentMethodType
    );
    this.achMonthlyMinFee = validateAchTypeValue<number>(
      Number(iOfferOtherStandardFees?.achMonthlyMinFee),
      paymentMethodType
    );
    this.showAchMonthlyMinFee = validateAchTypeValue<boolean>(
      iOfferOtherStandardFees?.showAchMonthlyMinFee,
      paymentMethodType
    );
    this.achRejectFee2 = validateAchTypeValue<number>(
      Number(iOfferOtherStandardFees?.achRejectFee2),
      paymentMethodType
    );
    this.showAchRejectFee2 = validateAchTypeValue<boolean>(
      iOfferOtherStandardFees?.showAchRejectFee2,
      paymentMethodType
    );

    this.showOtherStandardFee = iOfferOtherStandardFees
      ? hasTruthyValue(iOfferOtherStandardFees)
      : false;
  }
}

export class OfferPricingPlan {
  public agentId: string;
  public preferredBrandingDomain: PreferredDomain;
  public preferredBrandingDomainId: string;
  public relationshipId: string;
  public paymentMethodType: EPaymentMethodType;
  public tagLine: string;
  public createdOn: string;
  public pricingType: string;
  public achCoreFees?: OfferAchCoreFees;
  public flatRateCoreFees?: OfferFlatRateCoreFees;
  public interchangePlusCoreFees?: OfferInterchangePlusCoreFees;
  public cashDiscountCoreFees?: OfferCashDiscountCoreFees;
  public tieredCoreFees?: OfferTieredCoreFees;
  public otherAgentFees: OfferOtherAgentFees;
  public otherStandardFees: OfferOtherStandardFees;
  public relationship: EntityRelationship;

  constructor(iOfferPricingPlanData: IOfferPricingPlanData) {
    this.agentId = iOfferPricingPlanData?.agentId;
    this.preferredBrandingDomain = new PreferredDomain(
      iOfferPricingPlanData?.preferredBrandingDomain
    );
    this.preferredBrandingDomainId =
      iOfferPricingPlanData?.preferredBrandingDomain?.id;
    this.relationshipId = iOfferPricingPlanData?.relationshipId;
    this.tagLine = iOfferPricingPlanData?.tagLine;
    this.createdOn = iOfferPricingPlanData?.createdOn;
    this.paymentMethodType = iOfferPricingPlanData?.paymentMethodType;
    this.pricingType = iOfferPricingPlanData?.pricingType?.id;
    this.achCoreFees = iOfferPricingPlanData?.achCoreFees
      ? new OfferAchCoreFees(iOfferPricingPlanData?.achCoreFees)
      : null;
    this.flatRateCoreFees = iOfferPricingPlanData?.flatRateCoreFees
      ? new OfferFlatRateCoreFees(iOfferPricingPlanData?.flatRateCoreFees)
      : null;
    this.interchangePlusCoreFees =
      iOfferPricingPlanData?.interchangePlusCoreFees
        ? new OfferInterchangePlusCoreFees(
            iOfferPricingPlanData?.interchangePlusCoreFees
          )
        : null;
    this.cashDiscountCoreFees = iOfferPricingPlanData?.cashDiscountCoreFees
      ? new OfferCashDiscountCoreFees(
          iOfferPricingPlanData?.cashDiscountCoreFees
        )
      : null;
    this.tieredCoreFees = iOfferPricingPlanData?.tieredCoreFees
      ? new OfferTieredCoreFees(iOfferPricingPlanData?.tieredCoreFees)
      : null;
    this.otherAgentFees = new OfferOtherAgentFees(
      {
        ...iOfferPricingPlanData?.otherAgentFees,
        merchantClubFee:
          iOfferPricingPlanData?.otherStandardFees?.merchantClubFee,
        showMerchantClubFee:
          iOfferPricingPlanData?.otherStandardFees?.showMerchantClubFee,
      },
      this.paymentMethodType
    );
    this.otherStandardFees = new OfferOtherStandardFees(
      iOfferPricingPlanData?.otherStandardFees,
      this.paymentMethodType
    );
    this.relationship = iOfferPricingPlanData?.relationship
      ? new EntityRelationship(iOfferPricingPlanData?.relationship)
      : undefined;
  }
}

function hasTruthyValue(obj: IOfferOtherAgentFees | IOfferOtherStandardFees) {
  if (obj) {
    for (const key in obj) {
      if (obj[key] && typeof obj[key] === 'object') {
        if (hasTruthyValue(obj[key])) {
          return true;
        }
      } else if (obj[key] && typeof obj[key] === 'boolean') {
        return true;
      }
    }
  }
  // NOTE: To show all fees (previous value false)
  return true;
}

export class PricingMinimumValues {
  public achDebitsCoreFees: OfferAchDebitFees;
  public achCreditCoreFees: OfferAchCreditFees;
  public flatRateCoreFees: OfferFlatRateCoreFees;
  public interchangePlusCoreFees: OfferInterchangePlusCoreFees;
  public cashDiscountCoreFees: OfferCashDiscountCoreFees;
  public tieredCoreFees: OfferTieredCoreFees;
  public otherAgentFees: OfferOtherAgentFees;
  public otherStandardFees: OfferOtherStandardFees;

  constructor(iPricingMinimumValues: IPricingMinimumValues) {
    this.achDebitsCoreFees = iPricingMinimumValues?.achCoreFees
      ? new OfferAchDebitFees(iPricingMinimumValues?.achCoreFees)
      : undefined;
    this.achCreditCoreFees = iPricingMinimumValues?.achCoreFees
      ? new OfferAchCreditFees(iPricingMinimumValues?.achCoreFees)
      : undefined;
    this.flatRateCoreFees = iPricingMinimumValues?.flatRateCoreFees
      ? new OfferFlatRateCoreFees(iPricingMinimumValues?.flatRateCoreFees)
      : undefined;
    this.interchangePlusCoreFees =
      iPricingMinimumValues?.interchangePlusCoreFees
        ? new OfferInterchangePlusCoreFees(
            iPricingMinimumValues?.interchangePlusCoreFees
          )
        : undefined;
    this.cashDiscountCoreFees = iPricingMinimumValues?.cashDiscountCoreFees
      ? new OfferCashDiscountCoreFees(
          iPricingMinimumValues?.cashDiscountCoreFees
        )
      : undefined;
    this.tieredCoreFees = iPricingMinimumValues?.tieredCoreFees
      ? new OfferTieredCoreFees(iPricingMinimumValues?.tieredCoreFees)
      : undefined;
    this.otherAgentFees = iPricingMinimumValues?.otherAgentFees
      ? new OfferOtherAgentFees({
          ...iPricingMinimumValues?.otherAgentFees,
          merchantClubFee:
            iPricingMinimumValues?.otherStandardFees?.merchantClubFee,
          showMerchantClubFee:
            iPricingMinimumValues?.otherStandardFees?.showMerchantClubFee,
        })
      : undefined;
    this.otherStandardFees = iPricingMinimumValues?.otherStandardFees
      ? new OfferOtherStandardFees(iPricingMinimumValues?.otherStandardFees)
      : undefined;
  }
}

function validateCardTypeValue<T>(
  value: T,
  paymentMethodType: EPaymentMethodType
) {
  return (
    paymentMethodType
      ? paymentMethodType == EPaymentMethodType.CARD_AND_ACH ||
        paymentMethodType == EPaymentMethodType.CARD_ONLY
        ? value
        : null
      : value
  ) as T;
}

function validateAchTypeValue<T>(
  value: T,
  paymentMethodType: EPaymentMethodType
) {
  return (
    paymentMethodType
      ? paymentMethodType == EPaymentMethodType.CARD_AND_ACH ||
        paymentMethodType == EPaymentMethodType.ACH_ONLY
        ? value
        : null
      : value
  ) as T;
}
