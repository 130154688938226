import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import {
  GtrSelectComponent,
  GtrSelectListLoaderVariation,
} from '@gettrx/core-components-angular';
import { ResponsePagination } from '@models/generic/responses.model';
import { PaymentAccount } from '@models/payments/payment-account.model';
import { BUSINESS_PATH } from '@parameters/routing/business.parameter';
import { PAYMENT_PATHS } from '@parameters/routing/payments';
import { PORTAL_PATH } from '@parameters/routing/portal.parameter';
import { PaymentAccountsStoreService } from '@stores/payment-accounts-store.service';
import { PortalStoreService } from '@stores/portal-store.service';
import {
  EMPTY,
  Subject,
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
} from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';
import {
  DashboardType,
  SidebarItemsService,
} from 'src/app/shared/services/sidebar-items.service';

@Component({
  selector: 'app-payment-account-dropdown',
  templateUrl: './payment-account-dropdown.component.html',
  styleUrls: ['./payment-account-dropdown.component.scss'],
})
export class PaymentAccountDropdown implements OnInit, OnDestroy {
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _sidebarService = inject(SidebarItemsService);
  private readonly _commonService = inject(CommonService);
  private readonly _paymentAccountService = inject(PaymentAccountsStoreService);
  private readonly _ngZone = inject(NgZone);
  private readonly _cdRef = inject(ChangeDetectorRef);
  private readonly _portalService = inject(PortalStoreService);

  public readonly router = inject(Router);
  public readonly loaderVariation = GtrSelectListLoaderVariation.TEXT;

  public paymentAccountsList: PaymentAccount[] = [];
  public searchedAccountsList: PaymentAccount[] = [];

  public pagination: ResponsePagination;

  private searchDebouncer$: Subject<string> = new Subject();

  private readonly _paymentAccountStore = inject(PaymentAccountsStoreService);

  @ViewChild('gtrSelect') gtrSelect: GtrSelectComponent;

  public accountId: string;
  public searchValue: string = '';
  public loading: boolean = false;
  public requestLoader: boolean = false;
  public hasSearch: boolean = false;

  ngOnInit(): void {
    /** To Setup search observable */
    this._setupSearchDebouncer();

    /** To authenticate user to get paymentAccessToken on the basis of current account id */
    this._authenticateUser();

    /** To get payment account list */
    this._getPaymentAccountsList();

    /** To Manage confirm password popup state open/close */
    this._managePopupStates();
  }

  /** Manage pop up states for confirm password modal */
  private _managePopupStates() {
    this._paymentAccountService?.paymentModalClosed$
      ?.pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((event) => {
        if (event?.authenticated && this.accountId) {
          this._commonService.paymentAccessToken = '';
          this.handleValueChange(this.accountId);
        }
      });
  }

  /** Get latest payment account whenever get changes and call authenticatePaymentAccount endpoint */
  private _authenticateUser() {
    this._paymentAccountService?.paymentAccountId$
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe({
        next: (selectedAccountId) => {
          if (selectedAccountId) {
            this.accountId = selectedAccountId || '';
            this._commonService.paymentAccountId = this.accountId;
            if (
              selectedAccountId !==
                this._paymentAccountService?.previousAccountId ||
              !this._commonService.paymentAccessToken
            ) {
              this._authenticateAccount(this.accountId);
            }
            this._paymentAccountStore.previousAccountId = this.accountId || '';
          }
        },
      });
  }

  /**
   * Get list of payment account ids
   * @returns
   */
  private _getPaymentAccountsList() {
    this.loading = true;
    return this._paymentAccountService.filterChange$
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        switchMap(() => {
          return this._paymentAccountService.getPaymentAccountsList().pipe(
            catchError(() => {
              this._ngZone.run(() => {
                this.loading = false;
                this.paymentAccountsList = [];
                this.pagination = undefined;
              });
              this._cdRef.detectChanges();
              return EMPTY;
            })
          );
        })
      )
      .subscribe({
        next: (res) => {
          this.loading = false;
          this.paymentAccountsList =
            res.pagination.page === 1
              ? res.list
              : [...this.paymentAccountsList, ...res.list];
          this.pagination = res?.pagination || undefined;

          if (
            this.accountId &&
            !this.paymentAccountsList.find((x) => x.id === this.accountId) &&
            !this.hasSearch
          ) {
            this.searchedAccountsList = [...this.paymentAccountsList];
            this._paymentAccountService.search = this.accountId;
            this._paymentAccountService.filterChange = true;
          }

          if (this.searchedAccountsList.length) {
            this.searchedAccountsList.map((account: PaymentAccount) =>
              this.paymentAccountsList.push(account)
            );
          }
        },
      });
  }

  /** To set value of accountId base of specific cases  */
  private _setupAccountIdValue() {
    if (
      /** CASE 1: If have paymentAccountID & paymentAccessToken then set value of account id */
      this._commonService?.paymentAccountId &&
      this._commonService?.paymentAccessToken
    ) {
      this.accountId = this._commonService.paymentAccountId;
    } else if (
      /** CASE 2: If have paymentAccountID & not paymentAccessToken then call handleValueChange method */
      this._commonService?.paymentAccountId &&
      !this._commonService?.paymentAccessToken
    ) {
      this.handleValueChange(this._commonService?.paymentAccountId);
    } else {
      /** CASE 3: If neither paymentAccountID nor paymentAccessToken are available then call handleValueChange method */
      this.handleValueChange(this.paymentAccountsList?.[0]?.id);
    }
  }

  /** Debounce for account id dropdown search box */
  private _setupSearchDebouncer(): void {
    this.searchDebouncer$
      .pipe(
        debounceTime(750),
        distinctUntilChanged(),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe((search: string) => {
        this.loading = true;
        this._paymentAccountService.search = search;
      });
  }

  /**
   * Handle Pagination for accountIds dropdown it behaves like infinite scroll
   * @param detail
   */
  public handlePagination(detail: any) {
    if (
      detail.reachedBottom &&
      this.pagination?.totalRecords > this.paymentAccountsList?.length &&
      !this.loading
    ) {
      this._paymentAccountService.page = this.pagination.page + 1;
    }
  }

  /**
   * Get latest value of accountID dropdown search
   * @param detail
   */
  public onSearchChange(detail: any) {
    this.hasSearch = false;
    if (detail) {
      this.hasSearch = true;
    }
    this.searchDebouncer$.next(detail);
  }

  /**
   * Handle value change for accountId dropdown
   * @param event
   */
  public handleValueChange(accountId: string) {
    this._paymentAccountService.paymentAccountId$.next(accountId || '');
  }

  /**
   * Method to call endpoint for authenticate Payment account
   * @param accountId
   */
  private _authenticateAccount(accountId: string) {
    if (!accountId) return;

    this.requestLoader = true;
    this._paymentAccountService
      .authenticatePaymentAccount(accountId)
      ?.pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe({
        next: (res) => {
          this.requestLoader = false;
          if (res?.accessToken) {
            this._commonService.paymentAccessToken = res?.accessToken || '';
            this._paymentAccountService.refreshPaymentToken$.next(true);
            this.redirectToSession();
            this._setupAccountIdValue();
          } else {
            this._commonService.warning(
              'Payment access token not found. Please login again.'
            );
          }
        },
        error: () => {
          this.requestLoader = false;
          this._commonService.paymentAccessToken = '';
        },
      });
  }

  /**
   * To get back to dashboard
   * @returns
   */
  public backToDashboard() {
    this._sidebarService.updateDashboardType(DashboardType.DEFAULT);
    this._commonService.paymentAccountId = '';
    this._commonService.paymentAccessToken = '';
    return this.router.navigate([
      `/${BUSINESS_PATH.portal}/${this._portalService.type}`,
    ]);
  }

  /** Method used to refresh paymentAccountId param */
  public redirectToSession() {
    const hasAccountId = this.router?.url?.includes(
      this._paymentAccountService?.previousAccountId
    );

    this._paymentAccountService.currentPaymentAccount$?.next(
      this.accountId || ''
    );
    if (hasAccountId) {
      this.router.navigateByUrl(
        this.router.url?.replace(
          this._paymentAccountService?.previousAccountId,
          this.accountId
        )
      );
    } else {
      this.router.navigate([
        `/${BUSINESS_PATH.portal}/${this._portalService.type}/${PORTAL_PATH.payments}/${this._commonService?.paymentAccountId}/${PAYMENT_PATHS.home}`,
      ]);
    }
  }

  ngOnDestroy(): void {
    this._paymentAccountService.defaultValues();
  }
}
