import { Component, Input, OnChanges, computed, signal } from '@angular/core';
import { OfferFlatRateCoreFees } from '../../../../shared/models/offer-pricing/offer-pricing.model';

@Component({
  selector: 'offer-banner-flat-rate',
  template: `
    <ng-container *ngIf="flatRateCoreFeesFields().length">
      <ng-container *ngFor="let key of flatRateCoreFeesFields()">
        <ng-container
          *ngIf="
            (hasSwipedTransaction() &&
              !hasSameFlatRate() &&
              key.transactionType === 'swiped') ||
            (hasKeyedTransaction() &&
              !hasSameFlatRate() &&
              key.transactionType === 'keyed') ||
            (hasSameFlatRate() && key.transactionType === 'flatRateSame')
          "
          [ngTemplateOutlet]="transactionData"
          [ngTemplateOutletContext]="{ key: key }"
        ></ng-container>
      </ng-container>
    </ng-container>

    <ng-template #transactionData let-key="key">
      <div class="d-flex justify-content-center align-items-center my-1 sidev1">
        <span
          class="offer_val_web fs-22"
          *ngIf="flatRateCoreData()[key.discount]"
        >
          {{ flatRateCoreData()[key.discount] | roundWithDecimal }}%
        </span>
        <span
          class="fw-300 px-0 plus_web"
          *ngIf="
            flatRateCoreData()[key.discount] && flatRateCoreData()[key.perItem]
          "
        >
          +
        </span>
        <span
          class="offer_val_web fs-22"
          *ngIf="flatRateCoreData()[key.perItem]"
        >
          {{ flatRateCoreData()[key.perItem] | currencySymbol }}
        </span>
      </div>
      <p
        class="ps-0 mb-2 fs-12 mobile-content"
        *ngIf="
          key.label &&
          (flatRateCoreData()[key.perItem] || flatRateCoreData()[key.discount])
        "
      >
        {{ key.label }}
      </p>
      <p
        class="ps-0 mb-2 fs-12 desktop-content"
        *ngIf="
          key.label &&
          (flatRateCoreData()[key.perItem] || flatRateCoreData()[key.discount])
        "
      >
        {{ key.label }}
      </p>
    </ng-template>
  `,
})
export class FlatRateDetailComponent implements OnChanges {
  @Input() public flatRateCoreFees: OfferFlatRateCoreFees;

  public readonly flatRateCoreData = signal<OfferFlatRateCoreFees>(null);

  public readonly flatRateCoreFeesFields = signal([
    {
      discount: 'flatRateSwipedPercentage',
      perItem: 'flatRateSwipedPerItem',
      label: 'Per Swiped Transaction',
      transactionType: 'swiped',
    },
    {
      discount: 'flatRateKeyedInPercentage',
      perItem: 'flatRateKeyedInPerItem',
      label: 'Per Online Transaction',
      transactionType: 'keyed',
    },
    {
      discount: 'flatRateSwipedPercentage',
      perItem: 'flatRateSwipedPerItem',
      label: 'Per Transaction',
      transactionType: 'flatRateSame',
    },
  ]);

  public readonly hasSwipedTransaction = computed(() => {
    return (
      this.flatRateCoreData()?.flatRateSwipedPercentage ||
      this.flatRateCoreData()?.flatRateSwipedPerItem
    );
  });

  public readonly hasKeyedTransaction = computed(() => {
    return (
      this.flatRateCoreData()?.flatRateKeyedInPercentage ||
      this.flatRateCoreData()?.flatRateKeyedInPerItem
    );
  });

  public readonly hasSameFlatRate = computed(() => {
    return (
      this.flatRateCoreData()?.flatRateSwipedPerItem ==
        this.flatRateCoreData()?.flatRateKeyedInPerItem &&
      this.flatRateCoreData()?.flatRateSwipedPercentage ==
        this.flatRateCoreData()?.flatRateKeyedInPercentage
    );
  });

  ngOnChanges(): void {
    this.flatRateCoreData.set(this.flatRateCoreFees);
  }
}
