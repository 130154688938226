import { Component, Input, OnChanges, computed, signal } from '@angular/core';
import { OfferAchCoreFees } from '../../../../shared/models/offer-pricing/offer-pricing.model';

@Component({
  selector: 'offer-banner-ach-core-fees',
  template: `
    <ng-container *ngIf="achCoreFeesFields().length">
      <ng-container *ngFor="let key of achCoreFeesFields()">
        <ng-container
          *ngIf="
            (hasDebitFees() &&
              !hasSameCreditDebit() &&
              key.feeType === 'debitOnly') ||
            (hasCreditFees() &&
              !hasSameCreditDebit() &&
              key.feeType === 'creditOnly') ||
            (hasSameCreditDebit() && key.feeType === 'debitCreditSame')
          "
          [ngTemplateOutlet]="transactionData"
          [ngTemplateOutletContext]="{ key: key }"
        ></ng-container>
      </ng-container>
    </ng-container>

    <ng-template #transactionData let-key="key">
      <div class="d-flex justify-content-center align-items-center my-1 sidev1">
        <span
          class="offer_val_web fs-22"
          *ngIf="achCoreFeesData()?.[key.parent]?.[key.discount]"
        >
          {{ achCoreFeesData()?.[key.parent]?.[key.discount] | roundWithDecimal}}%
        </span>
        <span
          class="fw-300 px-0 plus_web"
          *ngIf="
            achCoreFeesData()?.[key.parent]?.[key.discount] && achCoreFeesData()?.[key.parent]?.[key.perItem]
          "
        >
          +
        </span>
        <span
          class="offer_val_web fs-22"
          *ngIf="achCoreFeesData()?.[key.parent]?.[key.perItem]"
        >
          {{ achCoreFeesData()?.[key.parent]?.[key.perItem] | currencySymbol }}
        </span>
      </div>
      <p
        class="ps-0 fs-12 mobile-content {{
          (key.feeType == 'debitOnly' && hasDebitCappedFee()) ||
          (key.feeType == 'creditOnly' && hasCreditCappedFee()) ||
          (key.feeType == 'debitCreditSame' && hasCappedFee())
            ? 'mb-0'
            : 'mb-2'
        }}"
        *ngIf="
          key.label &&
          (achCoreFeesData()?.[key.parent]?.[key.perItem] || achCoreFeesData()?.[key.parent]?.[key.discount])
        "
      >
        {{ key.label }}
      </p>
      <p
        class="ps-0 fs-12 desktop-content {{
          (key.feeType == 'debitOnly' && hasDebitCappedFee()) ||
          (key.feeType == 'creditOnly' && hasCreditCappedFee()) ||
          (key.feeType == 'debitCreditSame' && hasCappedFee())
            ? 'mb-0'
            : 'mb-2'
        }}"
        *ngIf="
          key.label &&
          (achCoreFeesData()?.[key.parent]?.[key.perItem] || achCoreFeesData()?.[key.parent]?.[key.discount])
        "
      >
        {{ key.label }}
      </p>

      @if(hasCappedFee()) {
      <p class="ps-0 mb-2 fs-11 desktop-content"  [class.mt-1]="key.feeType === 'debitCreditSame'" style="opacity: .6;">
        @if(key.feeType === 'debitOnly' && hasDebitCappedFee()) { Capped at
        {{
          achCoreFeesData()?.debits?.achDebitMaxFeePerTransaction || '0.00'
            | currencySymbol
        }}
        } @else if(key.feeType === 'creditOnly' && hasCreditCappedFee()) {
        Capped at
        {{
          achCoreFeesData()?.credits?.achCreditMaxFeePerTransaction || '0.00'
            | currencySymbol
        }}
        } @else if(key.feeType === 'debitCreditSame') { 
            @if(hasDebitCappedFee()) { 
              <div style="line-height: normal;">Capped at {{ achCoreFeesData()?.debits?.achDebitMaxFeePerTransaction || '0.00' | currencySymbol}} for Debits</div> 
            }
            @if(hasCreditCappedFee()) { 
              <div style="line-height: 1.5rem;">Capped at {{ achCoreFeesData()?.credits?.achCreditMaxFeePerTransaction || '0.00' | currencySymbol}} for Credits</div> 
            } 
        }
      </p>
      }
    </ng-template>
  `,
})
export class AchCoreFeesDetailComponent implements OnChanges {
  @Input() public achCoreFees: OfferAchCoreFees;

  public readonly achCoreFeesData = signal<OfferAchCoreFees>(null);

  public readonly achCoreFeesFields = signal([
    {
      parent: 'debits',
      discount: 'achDebitDiscountFee',
      perItem: 'achDebitPerItemFee',
      label: 'Per ACH Debit Transaction',
      feeType: 'debitOnly',
    },
    {
      parent: 'credits',
      discount: 'achCreditDiscountFee',
      perItem: 'achCreditPerItemFee',
      label: 'Per ACH Credit Transaction',
      feeType: 'creditOnly',
    },
    {
      parent: 'debits',
      discount: 'achDebitDiscountFee',
      perItem: 'achDebitPerItemFee',
      label: 'Per ACH Debit/Credit Transaction',
      feeType: 'debitCreditSame',
    },
  ]);

  public readonly hasDebitFees = computed(() => {
    return (
      this.achCoreFeesData()?.debits?.achDebitDiscountFee ||
      this.achCoreFeesData()?.debits?.achDebitPerItemFee
    );
  });

  public readonly hasCreditFees = computed(() => {
    return (
      this.achCoreFeesData()?.credits?.achCreditDiscountFee ||
      this.achCoreFeesData()?.credits?.achCreditPerItemFee
    );
  });

  public readonly hasSameCreditDebit = computed(() => {
    return (
      this.achCoreFeesData()?.credits?.achCreditDiscountFee ==
        this.achCoreFeesData()?.debits?.achDebitDiscountFee &&
      this.achCoreFeesData()?.credits?.achCreditPerItemFee ==
        this.achCoreFeesData()?.debits?.achDebitPerItemFee
    );
  });

  public readonly hasDebitCappedFee = computed(() => {
    return this.achCoreFeesData()?.debits?.achDebitMaxFeePerTransaction;
  });

  public readonly hasCreditCappedFee = computed(() => {
    return this.achCoreFeesData()?.credits?.achCreditMaxFeePerTransaction;
  });

  public readonly hasCappedFee = computed(() => {
    return (
      this.achCoreFeesData()?.credits?.achCreditMaxFeePerTransaction ||
      this.achCoreFeesData()?.debits?.achDebitMaxFeePerTransaction
    );
  });

  ngOnChanges(): void {
    this.achCoreFeesData.set(this.achCoreFees);
  }
}
