import { DOCUMENT } from '@angular/common';
import {
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[gtr-stats-wrapper]',
})
export class GtrStatsWrapperDirective {
  /**
   * Wrapper styles
   */
  private styles = {
    margin: 0,
    padding: 0,
    display: 'flex',
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
    position: 'relative',
    //...and so on
  };

  @HostListener('window:resize', ['$event'])
  public onResize(event) {
    // Rewrite media query css by calling addStyles Method based on window.innerWidth condition
    this.addStyles();
  }

  // Private
  private _nativeElement: any;
  /**
   * Constructor
   *
   * @param {ElementRef} _elementRef
   */
  constructor(
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private _elementRef: ElementRef,
    private renderer: Renderer2
  ) {}

  ngAfterViewInit() {
    this.addStyles();
  }

  addStyles(): void {
    if (window.innerWidth < 1048) {
      this.styles['overflow'] = 'auto';
    } else {
      this.styles['overflow'] = 'initial';
    }

    const gtrSelect = Array.from(
      this._document.querySelectorAll('.gtr-stats-card-date')
    );

    const gtrSelectWrapper = Array.from(
      this._document.querySelectorAll('.gtr-stats-card-date-options-wrapper')
    );

    gtrSelect.forEach((select: any) => {
      select.addEventListener(
        'click',
        ($event: Event) => {
          this.addHeight();
        },
        true
      );
    });

    gtrSelect.forEach((select: any) => {
      select.tabIndex = 0;
      select.addEventListener('blur', ($event: Event) => {
        if (!$event['relatedTarget']) {
          if (($event.target as HTMLElement).querySelector('.open')) {
            const el = ($event.target as HTMLElement).querySelector('.open');
            el.classList.remove('open');
          }
          this.removeHeight();
        }
      });
    });

    gtrSelectWrapper.forEach((element: any) => {
      element.addEventListener('blur', ($event: Event) => {
        if (($event.target as HTMLElement).querySelector('.open')) {
          const el = ($event.target as HTMLElement).querySelector('.open');
          el.classList.remove('open');
        }
        this.removeHeight();
      });
    });

    this.setStyles();
  }

  addHeight() {
    this.styles['padding-bottom'] = '258px';
    this.styles['margin-bottom'] = '-258px';
    this.styles['padding-top'] = '248px';
    this.styles['margin-top'] = '-248px';
    this.styles['z-index'] = '11';
    this.setStyles();
  }

  removeHeight() {
    this.styles['padding-bottom'] = '0';
    this.styles['margin-bottom'] = '0';
    this.styles['padding-top'] = '0';
    this.styles['margin-top'] = '0';
    this.styles['z-index'] = '0';
    this.setStyles();
  }

  setStyles() {
    // Get the native element
    this._nativeElement = this._elementRef.nativeElement;
    Object.keys(this.styles).forEach((element) => {
      this.renderer.setStyle(
        this._nativeElement,
        `${element}`,
        this.styles[element]
      );
    });
  }
}
