import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[searchContainer]',
})
export class SearchContainerDirective {
  /**
   * container styles
   */
  private styles = {
    display: 'flex',
    'align-items': 'center',
    margin: '21px 0px',
    gap: '16px',
    //...and so on
  };

  params = {
    justifyContent: '',
    flexDirection: '',
  };

  @Input('overRideCSS') set overRideCSS(value: any) {
    this.params.justifyContent = value.justifyContent;
    this.params.flexDirection = value.flexDirection;
    this.addStyles();
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event) {
    // Rewrite media query css by calling addStyles Method based on window.innerWidth condition
    this.addStyles();
  }

  // Private
  private _nativeElement: any;
  /**
   * Constructor
   *
   * @param {ElementRef} _elementRef
   */
  constructor(private _elementRef: ElementRef, private renderer: Renderer2) {
    this.addStyles();
  }

  addStyles(): void {
    if (window.innerWidth <= 768) {
      this.styles['flex-direction'] = !this.params.flexDirection
        ? 'row-reverse'
        : this.params.flexDirection;
      this.styles['justify-content'] = !this.params.justifyContent
        ? 'normal'
        : this.params.justifyContent;
    } else {
      this.styles['flex-direction'] = !this.params.flexDirection
        ? 'inherit'
        : this.params.flexDirection;
      this.styles['justify-content'] = !this.params.justifyContent
        ? 'flex-end'
        : this.params.justifyContent;
      this.styles['margin'] = '21px 0px';
    }

    // Get the native element
    this._nativeElement = this._elementRef.nativeElement;

    Object.keys(this.styles).forEach((element) => {
      this.renderer.setStyle(
        this._nativeElement,
        `${element}`,
        this.styles[element]
      );
    });
  }
}
