import { Injectable } from '@angular/core';
import { GenericClientService } from '@clients/generic/generic-client.service';
import { apiList } from '@helpers/apilist';
import { IResponseBase } from '@interfaces/generic/responses.interface';
import { ProxyGateway } from '@parameters/http/api-proxy-gateway';
import { Observable } from 'rxjs';

@Injectable()
export class ProspectApplicationClientService {
  private readonly _apiBase = ProxyGateway.loadBaseApiUrl();
  /**
   * Contructor
   * @param _genericClient
   */
  constructor(
    private readonly _genericClient: GenericClientService,
    private apiList: apiList
  ) {}

  /**
   * getBussingRegistrationType
   * @returns
   */
  public getBussingRegistrationType(): Observable<
    IResponseBase<{ name: string; shortId: string }[]>
  > {
    const endpoint = this._apiBase + this.apiList.getBusinesIncorporate;

    return this._genericClient.genericGet<
      IResponseBase<{ name: string; shortId: string }[]>
    >(endpoint);
  }

  /**
   * Business Periods
   * @returns
   */
  public getBussingPeriods(): Observable<
    IResponseBase<{ name: string; shortId: string }[]>
  > {
    const endpoint = this._apiBase + this.apiList?.getClientServices;
    // const endpoint = this._apiBase + 'v1/merchant/business-periods';

    return this._genericClient.genericGet<
      IResponseBase<{ name: string; shortId: string }[]>
    >(endpoint);
  }

  /**
   * States
   * @returns
   */
  public getBussinessStates(): Observable<
    IResponseBase<{
      states: { name: string; abbreviation: string; id: string }[];
    }>
  > {
    const endpoint = this._apiBase + this.apiList?.getStateList;

    // const endpoint = this._apiBase + 'v1/merchant/states';

    return this._genericClient.genericGet<
      IResponseBase<{
        states: { name: string; abbreviation: string; id: string }[];
      }>
    >(endpoint);
  }
}
