import {
  CDateFilterListValue,
  EDateFilter,
} from '@parameters/filter/date.parameter';

export const enum EMetricsKpisDealParams {
  startDate = 'start_date',
  endDate = 'end_date',
  relationshipList = 'relationship_ids',
  kpiList = 'kpis',
}

export const enum EMetricsKpisDealParamsKpi {
  total = 'total_deals',
  new = 'new_deals',
  approved = 'approved_deals',
  declined = 'declined_deals',
}

export const CMetricsKpisDealParamsDate =
  CDateFilterListValue[EDateFilter.last30Days];
