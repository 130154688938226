import {
  IResponseBase,
  IResponseData,
} from '@interfaces/generic/responses.interface';
import {
  CReportsAuthListParamsDate,
  CReportsAuthListParamsStatus,
  EReportsAuthCSVParams,
  EReportsAuthListParams,
} from '@parameters/reports/auth/auth-list.parameter';
import {
  EReportsAuthStatus,
  EReportsAuthOperationType,
} from '@parameters/reports/auth/auth.parameter';
import { IReportsAuth } from '@interfaces/reports/auth/auth.interface';
import { ECardBrand } from '@parameters/filter/card-brand.parameter';

export interface IReportsAuthListParams {
  [EReportsAuthListParams.size]: number;
  [EReportsAuthListParams.page]: number;
  [EReportsAuthListParams.sort]: string;
  [EReportsAuthListParams.search]: string;
  [EReportsAuthListParams.status]: EReportsAuthStatus[];
  [EReportsAuthListParams.startDate]: number;
  [EReportsAuthListParams.endDate]: number;
  [EReportsAuthListParams.midList]: string[];
  [EReportsAuthListParams.binList]: number[];
  [EReportsAuthListParams.cardBrandList]: ECardBrand[];
  [EReportsAuthListParams.operationTypeList]: EReportsAuthOperationType[];
}

export const CReportsAuthListParamsDefault: IReportsAuthListParams = {
  [EReportsAuthListParams.size]: 10,
  [EReportsAuthListParams.page]: 1,
  [EReportsAuthListParams.sort]: '',
  [EReportsAuthListParams.search]: '',
  [EReportsAuthListParams.status]: CReportsAuthListParamsStatus,
  [EReportsAuthListParams.startDate]:
    CReportsAuthListParamsDate.unixValue.startDate,
  [EReportsAuthListParams.endDate]:
    CReportsAuthListParamsDate.unixValue.endDate,
  [EReportsAuthListParams.midList]: [],
  [EReportsAuthListParams.binList]: [],
  [EReportsAuthListParams.cardBrandList]: [],
  [EReportsAuthListParams.operationTypeList]: [],
};

export interface IReportsAuthListResponseOther {
  approvedCount: number;
  authVolume: number;
  declinedCount: number;
  netAuthVolume: number;
}

export interface IReportsAuthListResponseData<T> extends IResponseData<T> {
  other: IReportsAuthListResponseOther;
}

export interface IReportsAuthListResponse
  extends IResponseBase<IReportsAuthListResponseData<IReportsAuth>> {}

export interface IReportsAuthCSVParams {
  [EReportsAuthCSVParams.search]: string;
  [EReportsAuthCSVParams.status]: EReportsAuthStatus[];
  [EReportsAuthCSVParams.startDate]: number;
  [EReportsAuthCSVParams.endDate]: number;
  [EReportsAuthCSVParams.midList]: string[];
  [EReportsAuthCSVParams.binList]: string[];
  [EReportsAuthCSVParams.cardBrandList]: ECardBrand[];
  [EReportsAuthCSVParams.operationTypeList]: EReportsAuthOperationType[];
}

export interface IReportsAuthCSVResponse
  extends IResponseBase<{ url: string }> {}
