import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { NumberFormatDirective } from './directives/number-mask.directive';
import { SsnMaskDirective } from './directives/ssn-mask.directive';
import { NumericOnlyDirective } from './directives/number-input.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchContainerDirective } from './directives/search-container.directive';
import { SearchContainerWrapperDirective } from './directives/search-container-wrapper.directive';
import { BtnContainerDirective } from './directives/btn-container.directive';
import { BtnBoxContainerDirective } from './directives/btn-box-container.directive';
import { BtnBoxWrapperDirective } from './directives/btn-box-wrapper.directive';
import { GtrStatsWrapperDirective } from './directives/gtr-stats-wrapper.directive';
import { GtrStatsContainerDirective } from './directives/gtr-stats-container.directive';
import { EinMaskDirective } from './directives/ein-mask.directive';
import { MaskInputDirective } from './directives/mask-input.directive';
import { OfferBannerModule } from '@molecules/offer-banner/offer-banner.module';
import { FaxNumberMaskDirective } from './directives/fax-mask.directive';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import {
  GtrBadgeModule,
  GtrButtonModule,
  GtrCheckboxModule,
  GtrDisplayValueModule,
  GtrIconModule,
  GtrInputModule,
  GtrModalModule,
  GtrSelectModule,
  GtrTableModule,
} from '@gettrx/core-components-angular';
import { AccountGatewayConfigurationComponent } from '../business/portal/settings/manage-accounts/components/account-gateway-configuration/account-gateway-configuration.component';
import { AccountGatewayConfigAddModalComponent } from '../business/portal/settings/manage-accounts/components/account-gateway-config-add-modal/account-gateway-config-add-modal.component';
import { AccountGatewayConfigStoreService } from '../business/portal/settings/manage-accounts/services/account-gateway-store.service';
import { AccountsImplementService } from '../business/portal/settings/manage-accounts/services/accounts-implement.service';
import { AccountsClientService } from '@clients/accounts/accounts-client.service';
import { InputErrorMsgComponent } from '../core/components/molecules/input-error-msg/input-error-msg.component';
import { BreadcrumbModule } from '../core/components/atoms/breadcrumb/breadcrumb.module';
import { AccountDetailHomeComponent } from '../business/portal/settings/manage-accounts/components/account-detail-home/account-detail-home.component';
import { AccountDetailFormComponent } from '../business/portal/settings/manage-accounts/components/account-detail-form/account-detail-form.component';
import { AccountsStoreService } from '../business/portal/settings/manage-accounts/services/accounts-store.service';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    OfferBannerModule,
    GtrIconModule,
    GtrButtonModule,
    GtrTableModule,
    GtrModalModule,
    GtrSelectModule,
    GtrInputModule,
    InputErrorMsgComponent,
    BreadcrumbModule,
    GtrDisplayValueModule,
    GtrBadgeModule,
    GtrCheckboxModule,
  ],
  declarations: [
    PhoneMaskDirective,
    NumberFormatDirective,
    SsnMaskDirective,
    NumericOnlyDirective,
    SearchContainerDirective,
    SearchContainerWrapperDirective,
    BtnContainerDirective,
    BtnBoxContainerDirective,
    BtnBoxWrapperDirective,
    GtrStatsWrapperDirective,
    GtrStatsContainerDirective,
    EinMaskDirective,
    MaskInputDirective,
    FaxNumberMaskDirective,
    AccountGatewayConfigurationComponent,
    AccountGatewayConfigAddModalComponent,
    AccountDetailHomeComponent,
    AccountDetailFormComponent,
  ],
  exports: [
    NgxSliderModule,
    PhoneMaskDirective,
    FaxNumberMaskDirective,
    SsnMaskDirective,
    NumberFormatDirective,
    SearchContainerDirective,
    SearchContainerWrapperDirective,
    BtnContainerDirective,
    BtnBoxContainerDirective,
    BtnBoxWrapperDirective,
    NumericOnlyDirective,
    GtrStatsWrapperDirective,
    GtrStatsContainerDirective,
    EinMaskDirective,
    MaskInputDirective,
    AccountGatewayConfigurationComponent,
    AccountGatewayConfigAddModalComponent,
    AccountDetailHomeComponent,
    AccountDetailFormComponent,
  ],
  providers: [
    AccountGatewayConfigStoreService,
    AccountsImplementService,
    AccountsClientService,
    AccountsClientService,
    AccountsImplementService,
    AccountGatewayConfigStoreService,
    AccountsStoreService,
  ],
})
export class SharedModule {}
