import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { OfferPricingBannerData } from '../models/offer-banner.model';
import { IOfferPricingBannerData } from '@interfaces/offer-pricing/offer-pricing.interface';
import { IEntityDomainResponse } from '@interfaces/entity/domain/entity-domain.interface';
import { EntityClientService } from '@clients/entity/entity-client.service';

@Injectable()
export class OfferBannerService {
  private _entityClient = inject(EntityClientService);

  private pricingBannerData$ =
    new BehaviorSubject<OfferPricingBannerData | null>(null);

  public updatePricingBannerData(data: IOfferPricingBannerData) {
    this.pricingBannerData$.next(
      data ? new OfferPricingBannerData(data) : undefined
    );
  }

  public patchPricingBannerData(data: IOfferPricingBannerData) {
    const previousData = this.pricingBannerData$.getValue();
    const value = { ...previousData, ...data };
    this.pricingBannerData$.next(new OfferPricingBannerData(value));
  }

  public getPricingBannerData() {
    return this.pricingBannerData$.asObservable();
  }

  /**
   * Fetch getEntityDomainById
   * @param params
   * @returns
   */
  getEntityDomainById(domainId: string): Observable<IEntityDomainResponse> {
    return this._entityClient.getEntityDomainById(domainId);
  }
}
