import { IResponseBase } from '@interfaces/generic/responses.interface';
import {
  EFPBillingSettingEditData,
  EFpBillingSettingEditRequest,
} from '@parameters/funding-plans/fp-billing-setting-edit.parameter';

/**
 * Request
 */

export interface IFPBillingSettingEditRequest {
  [EFpBillingSettingEditRequest.name]: string;
  [EFpBillingSettingEditRequest.description]: string;
  [EFpBillingSettingEditRequest.amount]: number;
  [EFpBillingSettingEditRequest.percent]: number;
  [EFpBillingSettingEditRequest.endDate]: string;
}

export const CFPBillingSettingEditRequestDefault: IFPBillingSettingEditRequest =
  {
    [EFpBillingSettingEditRequest.name]: '',
    [EFpBillingSettingEditRequest.description]: '',
    [EFpBillingSettingEditRequest.amount]: 0,
    [EFpBillingSettingEditRequest.percent]: 0,
    [EFpBillingSettingEditRequest.endDate]: null,
  };

/**
 * Response
 */

interface IFPBillingSettingEditData {
  [EFPBillingSettingEditData.id]: string;
}

export interface IFPBillingSettingEditResponse
  extends IResponseBase<IFPBillingSettingEditData> {}
