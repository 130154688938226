export enum ECountryListParams {
  page = 'page',
  limit = 'limit',
  search = 'search',
}

export const CCountryListParamsDefault = {
  [ECountryListParams.page]: 1,
  [ECountryListParams.limit]: 50,
  [ECountryListParams.search]: '',
};
