import { NgModule } from '@angular/core';
import { PaymentAccountDropdown } from './payment-account-dropdown/payment-account-dropdown.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  GtrSelectModule,
  GtrIconModule,
} from '@gettrx/core-components-angular';
import { PaymentConfirmPasswordModule } from '@atoms/payment-confirm-password/payment-confirm-password.module';

@NgModule({
  declarations: [PaymentAccountDropdown],
  imports: [
    GtrSelectModule,
    ReactiveFormsModule,
    GtrIconModule,
    RouterModule,
    PaymentConfirmPasswordModule,
  ],
  exports: [PaymentAccountDropdown],
})
export class PaymentAccountDropdownModule {}
