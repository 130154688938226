import { Injectable } from '@angular/core';
import { GenericClientService } from '@clients/generic/generic-client.service';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import {
  IMerchantSearchParams,
  IMerchantSearchResponse,
} from '@interfaces/merchant/merchant-search.interface';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import {
  IFindSingleMerchantParams,
  IFindSingleMerchantResponse,
  IMerchantMMSPayload,
  IMerchantMmsResponse,
} from '@interfaces/merchant/merchant-mms.interface';
import { IAgentListResponse } from '@interfaces/merchant/merchant-agent-list.interface';
import { IEquipmentListResponse } from '@interfaces/merchant/merchant-equipment-list.interface';
import { IPayfacInfoListResponse } from '@interfaces/merchant/merchant-payfac-info-list.interface';

@Injectable()
export class MerchantMSClientService {
  constructor(private genericClient: GenericClientService) {}

  getMerchantSearch(
    queryParams: IMerchantSearchParams
  ): Observable<IMerchantSearchResponse> {
    const endpoint = EndpointsParameter.MMSSearchV2Merchant;

    let params = new HttpParams()
      .set('type', queryParams.type || '')
      .set('search', queryParams.search || '')
      .set('status', queryParams.status || '')
      .set('page', queryParams.page || 0)
      .set('limit', queryParams.size || 100)
      .set('order_by', queryParams.sort.join(',') || 'created_timestamp:desc');
    if (queryParams.agent_id && queryParams.agent_id !== null) {
      params = params.set('agent_id', queryParams.agent_id);
    }
    return this.genericClient.genericGet<IMerchantSearchResponse>(endpoint, {
      params,
    });
  }

  getMerchant(
    queryParams: IFindSingleMerchantParams
  ): Observable<IFindSingleMerchantResponse> {
    const endpoint = EndpointsParameter.MMSMerchant;
    return this.genericClient.genericGet<IFindSingleMerchantResponse>(
      endpoint + queryParams.merchantId
    );
  }

  getMmsAgentList(): Observable<IAgentListResponse> {
    const endpoint = EndpointsParameter.MMSAgents;
    return this.genericClient.genericGet<IAgentListResponse>(endpoint);
  }

  getMmsEquipmentList(): Observable<IEquipmentListResponse> {
    const endpoint = EndpointsParameter.MMSEquipments;
    return this.genericClient.genericGet<IEquipmentListResponse>(endpoint);
  }

  getMmsPayfacInfoList(): Observable<IPayfacInfoListResponse> {
    const endpoint = EndpointsParameter.MMSPayfacInfo;
    return this.genericClient.genericGet<IPayfacInfoListResponse>(endpoint);
  }

  updateMerchantMms(
    params: IMerchantMMSPayload
  ): Observable<IMerchantMmsResponse> {
    const endpoint = EndpointsParameter.MMSMerchants;
    return this.genericClient.genericPut<IMerchantMmsResponse>(
      endpoint,
      params
    );
  }
  // Code Block For Create MMS
  createMerchantMms(
    params: IMerchantMMSPayload
  ): Observable<IMerchantMmsResponse> {
    const endpoint = EndpointsParameter.MMSMerchants;
    return this.genericClient.genericPost<IMerchantMmsResponse>(
      endpoint,
      params
    );
  }
}
