import { Injectable } from '@angular/core';
import { GenericClientService } from '@clients/generic/generic-client.service';
import { GenericHelper } from '@helpers/generic.helper';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import {
  IProspectOnboardingCustomizationRequest,
  IProspectOnboardingCustomizationResponse,
} from '@interfaces/prospects/prospect-onboading-customization.interface';
import { Observable } from 'rxjs';
import { IResponseBase } from '@interfaces/generic/responses.interface';

@Injectable()
export class ProspectOnboardingCustomizationClientService {
  constructor(private readonly _genericClient: GenericClientService) {}

  getOnboardingDetail(
    prospectId: string
  ): Observable<IProspectOnboardingCustomizationResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.ProspectOnboardingCustomizations,
      prospectId
    );

    return this._genericClient.genericGet<IProspectOnboardingCustomizationResponse>(
      endpoint
    );
  }

  postOnboardingDetail(
    prospectId: string,
    request: IProspectOnboardingCustomizationRequest
  ): Observable<IResponseBase<null>> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.ProspectOnboardingCustomizations,
      prospectId
    );

    return this._genericClient.genericPost<IResponseBase<null>>(
      endpoint,
      request
    );
  }

  putOnboardingDetail(
    prospectId: string,
    request: IProspectOnboardingCustomizationRequest
  ): Observable<IResponseBase<null>> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.ProspectOnboardingCustomizations,
      prospectId
    );

    return this._genericClient.genericPut<IResponseBase<null>>(
      endpoint,
      request
    );
  }
}
