import { Injectable } from '@angular/core';
import { GenericClientService } from '@clients/generic/generic-client.service';
import {
  CMetricsKpisProcessingParamsDefault,
  IMetricsKpisProcessingParams,
  IMetricsKpisProcessingResponse,
} from '@interfaces/metrics/metrics-kpis-precessing.interface';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EMetricsKpisProcessingParams } from '@parameters/metrics/metrics-kpis-processing.parameter';

@Injectable()
export class MetricsKpisProcessingClientService {
  /**
   * Constructor
   * @param genericClient
   */
  constructor(private genericClient: GenericClientService) {}

  /**
   * Get Metrics Kpis Processing
   * @param queryParams
   * @returns
   */
  getMetricKPIsProcessing(
    queryParams: IMetricsKpisProcessingParams
  ): Observable<IMetricsKpisProcessingResponse> {
    const endpoint = EndpointsParameter.MetricsKPIsProcessing;
    const params = new HttpParams()
      .set(
        EMetricsKpisProcessingParams.startDate,
        queryParams[EMetricsKpisProcessingParams.startDate] ||
          CMetricsKpisProcessingParamsDefault[
            EMetricsKpisProcessingParams.startDate
          ]
      )
      .set(
        EMetricsKpisProcessingParams.endDate,
        queryParams[EMetricsKpisProcessingParams.endDate] ||
          CMetricsKpisProcessingParamsDefault[
            EMetricsKpisProcessingParams.endDate
          ]
      )
      .set(
        EMetricsKpisProcessingParams.kpiList,
        queryParams[EMetricsKpisProcessingParams.kpiList].join(',') ||
          CMetricsKpisProcessingParamsDefault[
            EMetricsKpisProcessingParams.kpiList
          ].join(',')
      )
      .set(
        EMetricsKpisProcessingParams.midList,
        queryParams[EMetricsKpisProcessingParams.midList].join(',') ||
          CMetricsKpisProcessingParamsDefault[
            EMetricsKpisProcessingParams.midList
          ].join(',')
      )
      .set(
        EMetricsKpisProcessingParams.binList,
        queryParams[EMetricsKpisProcessingParams.binList].join(',') ||
          CMetricsKpisProcessingParamsDefault[
            EMetricsKpisProcessingParams.binList
          ].join(',')
      )
      .set(
        EMetricsKpisProcessingParams.cardBrandList,
        queryParams[EMetricsKpisProcessingParams.cardBrandList].join(',') ||
          CMetricsKpisProcessingParamsDefault[
            EMetricsKpisProcessingParams.cardBrandList
          ].join(',')
      )
      .set(
        EMetricsKpisProcessingParams.relationshipList,
        queryParams[EMetricsKpisProcessingParams.relationshipList].join(',') ||
          CMetricsKpisProcessingParamsDefault[
            EMetricsKpisProcessingParams.relationshipList
          ].join(',')
      )
      .set(
        EMetricsKpisProcessingParams.volumeAs,
        queryParams[EMetricsKpisProcessingParams.volumeAs].join('') ||
          CMetricsKpisProcessingParamsDefault[
            EMetricsKpisProcessingParams.volumeAs
          ].join('')
      );

    return this.genericClient.genericGet<IMetricsKpisProcessingResponse>(
      endpoint,
      { params }
    );
  }
}
