export enum Role {
  Admin = 'Admin',
  Client = 'Client',
  User = 'User',
}

export enum UserRoles {
  Agent = 'agent',
  Merchant = 'merchant',
  AgentSource = 'agent_source',
  Referral = 'referral',
  ReferralSource = 'referral_source',
  SalesManager = 'sales_manager',
  Entity = 'entity',
  Bank = 'bank',
  GettrxAdmin = 'gettrx_admin',
  MerchantSigner = 'merchant_signer',
  GettrxSupport = 'gettrx_support',
  GettrxUnderwriting = 'gettrx_underwriting',
  GettrxDataEntry = 'gettrx_data_entry',
  GettrxDeployment = 'gettrx_deployment',
  GettrxRiskAnalyst = 'gettrx_risk_analyst',
  Cipher = 'cipher',
  GettrxRiskManager = 'gettrx_risk_manager',
  GettrxUnderwritingManager = 'gettrx_underwriting_manager',
}
