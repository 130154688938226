<div class="payment-gateway-page w-100">
  <div class="row justify-content-center">
    <div
      class="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-8 align-self-start mt-md-1"
    >
      <div class="d-block page-area-title">
        <h1
          class="create_account_heading text-primary fw-800 text-uppercase mb-0 text-center"
        >
          CHOOSE YOUR PAYMENT GATEWAY FOR OVER THE PHONE OR ONLINE PAYMENTS
        </h1>
      </div>

      <div
        class="mt-md-3 mt-1 mbb-2 pt-1 w-100 mh-400 px-md-5 page-area"
      >
        <div class="row justify-content-center">
          <div
            *ngFor="let item of paymentGatewaysList; let i = index"
            class="col-md-4 col-6 splugin-col"
          >
            <div
              [class.active]="id == item.id"
              (click)="selectGateway(item)"
              [attr.data-transaction-name]="'onboarding--payment-gateways--select'"
              class="card bg-white comman position-relative rounded-1 border-white text-center"
            >
              <div *ngIf="!item?.decription" class="product-img-1 mt-1">
                <img
                  [src]="
                    item?.imageUrl ||
                    'assets\images\hardwares-img/shoping-plugin-1.png'
                  "
                  class="img-fluid w-100"
                  alt=""
                  style="max-width: 150px;"
                />
              </div>
              <div *ngIf="item?.decription" class="card-title mb-0">
                <h5 class="fw-700 text-primary mb-0 mtt-3 text-center">
                  {{cmService.capitalizeFirstLetter(item?.decription)}}
                </h5>
              </div>
            </div>
          </div>
          <!-- splugin-col end -->
        </div>
        <div class="row other-form mt-1">
          <div class="col-12">
            <div *ngIf="isOtherSelected && paymentGatewaysList.length > 0" class="form-group">
              <input
                type="text"
                [(ngModel)]="otherTextBox"
                [ngModelOptions]="{ standalone: true }"
                required
                class="form-control font-roboto"
                [formControl]="paymentGatewayName"
                [maxlength]="constants.explanation_limit"
                [attr.data-transaction-name]="'onboarding--payment-gateways--name-input'"
              />
              <div class="validation_error" *ngIf="paymentGatewayName.errors">
                <div
                  *ngIf="paymentGatewayName.errors.required && paymentGatewayName.touched"
                >
                  Payment Gateway Name is required.
                </div>
                <div
                  *ngIf="
                    paymentGatewayName.errors.whitespace &&
                    !paymentGatewayName.errors.required
                  "
                >
                  White space is not allowed.
                </div>
                <div
                  *ngIf="
                    paymentGatewayName.errors.pattern && !paymentGatewayName.errors.required
                  "
                >
                Payment Gateway Name should contain only letters and numbers.
                </div>
              </div>
              <span class="highlight"></span>
              <span class="bar"></span>
              <label>Please enter payment gateway name</label>
            </div>
          </div>
        </div>
        <!-- other-form end -->
      </div>
      <hr />
      <div
        class="text-center d-flex flex-row justify-content-center align-items-center mt-2 pt-1"
      >
        <button type="button"
          class="back_button text-uppercase me-1 waves-effect waves-float waves-light"
          (click)="goBack()"
                [attr.data-transaction-name]="'onboarding--payment-gateways--back-button'"
        >
          Back</button
        ><button type="button"
          class="btn btn-primary mw-120 text-uppercase waves-effect waves-float waves-light"
          [class.button--loading]="loading"
          [disabled]="
            (isOptionselected && !loading)
              ? (isOtherSelected && !loading)
                ? (paymentGatewayName.invalid && !loading)
                : false
              : true
          "
          (click)="submit()"
                 [attr.data-transaction-name]="'onboarding--payment-gateways--continue-button'"
        >
          Continue
        </button>
      </div>
    </div>
  </div>
</div>
