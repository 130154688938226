import {
  CDateFilterListValue,
  EDateFilter,
} from '@parameters/filter/date.parameter';

export const enum EMetricsKpisMerchantParams {
  startDate = 'start_date',
  endDate = 'end_date',
  midList = 'mids',
  binList = 'bins',
  kpiList = 'kpis',
  relationshipIds = 'relationship_ids',
}

export const enum EMetricsKpisMerchantParamsKpi {
  active = 'active_merchants',
  new = 'new_merchants',
  closed = 'closed_merchants',
}

export const CMetricsKpisMerchantParamsDate =
  CDateFilterListValue[EDateFilter.last30Days];
