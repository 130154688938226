import { Component, Input, OnChanges, computed, signal } from '@angular/core';
import { OfferInterchangePlusCoreFees } from '../../../../shared/models/offer-pricing/offer-pricing.model';

@Component({
  selector: 'offer-banner-interchange-plus',
  template: `
    <ng-container *ngIf="interchangePlusCoreFeesFields().length">
      <ng-container *ngFor="let key of interchangePlusCoreFeesFields()">
        <ng-container
          *ngIf="
            (hasOverInterchange() &&
              !hasSameOverInterchange() &&
              key.type === 'overInterchange') ||
            (hasOverInterchangeAmex() &&
              !hasSameOverInterchange() &&
              key.type === 'overInterchangeAmex') ||
            (hasSameOverInterchange() && key.type === 'sameOverInterchange')
          "
          [ngTemplateOutlet]="transactionData"
          [ngTemplateOutletContext]="{ key: key }"
        ></ng-container>
      </ng-container>
    </ng-container>

    <ng-template #transactionData let-key="key">
      <div class="d-flex justify-content-center align-items-center my-1 sidev1">
        <span
          class="offer_val_web fs-22"
          *ngIf="interchangePlusCoreFeesData()[key.discount]"
        >
          {{ interchangePlusCoreFeesData()[key.discount] | roundWithDecimal }}%
        </span>
        <span
          class="fw-300 px-0 plus_web"
          *ngIf="
            interchangePlusCoreFeesData()[key.discount] &&
            ((!hasHighlightPerItem() &&
              interchangePlusCoreFeesData()[key.authFee]) ||
              hasPerItem())
          "
        >
          +
        </span>
        <span
          class="offer_val_web fs-22"
          *ngIf="
            !hasHighlightPerItem() && interchangePlusCoreFeesData()[key.authFee]
          "
        >
          {{ interchangePlusCoreFeesData()[key.authFee] | currencySymbol }} Auth
        </span>

        <span class="offer_val_web fs-22" *ngIf="hasPerItem()">
          {{ interchangePlusCoreFeesData()?.intPlusPerItem | currencySymbol }}
        </span>
      </div>
      <p
        class="ps-0 mb-2 fs-12 mobile-content"
        *ngIf="
          key.label &&
          (interchangePlusCoreFeesData()[key.discount] ||
            interchangePlusCoreFeesData()[key.authFee] ||
            hasPerItem())
        "
      >
        {{ key.label }}
      </p>
      <p
        class="ps-0 mb-2 fs-12 desktop-content"
        *ngIf="
          key.label &&
          (interchangePlusCoreFeesData()[key.discount] ||
            interchangePlusCoreFeesData()[key.authFee] ||
            hasPerItem())
        "
      >
        {{ key.label }}
      </p>
    </ng-template>
  `,
})
export class InterchangePlusDetailComponent implements OnChanges {
  @Input() public interchangePlusCoreFees: OfferInterchangePlusCoreFees;

  public readonly interchangePlusCoreFeesData =
    signal<OfferInterchangePlusCoreFees>(null);

  public readonly interchangePlusCoreFeesFields = signal([
    {
      discount: 'intPlusDiscountRatePercentage',
      authFee: 'intPlusAuthFee',
      label: 'Over Interchange',
      type: 'overInterchange',
    },
    {
      discount: 'intPlusAmexDiscountRatePercentage',
      authFee: 'intPlusAmexAuthFee',
      label: 'Over Interchange (Amex)',
      type: 'overInterchangeAmex',
    },
    {
      discount: 'intPlusDiscountRatePercentage',
      authFee: 'intPlusAuthFee',
      label: 'Over Interchange',
      type: 'sameOverInterchange',
    },
  ]);

  public readonly hasOverInterchange = computed(() => {
    return (
      this.interchangePlusCoreFeesData()?.intPlusDiscountRatePercentage ||
      this.interchangePlusCoreFeesData()?.intPlusAuthFee
    );
  });

  public readonly hasOverInterchangeAmex = computed(() => {
    return (
      this.interchangePlusCoreFeesData()?.intPlusAmexDiscountRatePercentage ||
      this.interchangePlusCoreFeesData()?.intPlusAmexAuthFee
    );
  });

  public readonly hasSameOverInterchange = computed(() => {
    if (this.hasHighlightPerItem()) {
      return (
        this.interchangePlusCoreFeesData()?.intPlusAmexDiscountRatePercentage ==
        this.interchangePlusCoreFeesData()?.intPlusDiscountRatePercentage
      );
    } else {
      return (
        this.interchangePlusCoreFeesData()?.intPlusAmexDiscountRatePercentage ==
          this.interchangePlusCoreFeesData()?.intPlusDiscountRatePercentage &&
        this.interchangePlusCoreFeesData()?.intPlusAmexAuthFee ==
          this.interchangePlusCoreFeesData()?.intPlusAuthFee
      );
    }
  });

  public readonly hasHighlightPerItem = computed(() => {
    return this.interchangePlusCoreFeesData()?.highlightPerItemFees;
  });

  public readonly hasPerItem = computed(() => {
    return (
      this.hasHighlightPerItem() &&
      this.interchangePlusCoreFeesData()?.intPlusPerItem
    );
  });

  ngOnChanges(): void {
    this.interchangePlusCoreFeesData.set(this.interchangePlusCoreFees);
  }
}
