import { Injectable } from '@angular/core';
import { GenericClientService } from '@clients/generic/generic-client.service';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import {
  CEquipmentListForOnboardingCustomizationParamsDefault,
  IEquipmentListForOnboardingCustomizationParams,
  IEquipmentListForOnboardingCustomizationResponse,
} from '@interfaces/core/equipment-list-for-onboarding-customization.interface';
import { EEquipmentListForOnboardingCustomizationParams } from '@parameters/core/equipment-list-for-onboarding-customization.parameter';
import { GenericHelper } from '@helpers/generic.helper';

@Injectable()
export class EquipmentClientService {
  constructor(private readonly _genericClient: GenericClientService) {}

  equipmentListForOnboardingCustomization(
    id: string,
    queryParams: IEquipmentListForOnboardingCustomizationParams,
    hasProspect: boolean
  ): Observable<IEquipmentListForOnboardingCustomizationResponse> {
    const endpoint = hasProspect
      ? GenericHelper.replaceUrl(
          EndpointsParameter.ProspectOnboardingConfigurationEquipments,
          id
        )
      : GenericHelper.replaceUrl(
          EndpointsParameter.OnboardingTemplateEquipmentsList,
          id
        );
    let params = new HttpParams()
      .set(
        EEquipmentListForOnboardingCustomizationParams.size,
        queryParams[EEquipmentListForOnboardingCustomizationParams.size] ||
          CEquipmentListForOnboardingCustomizationParamsDefault[
            EEquipmentListForOnboardingCustomizationParams.size
          ]
      )
      .set(
        EEquipmentListForOnboardingCustomizationParams.page,
        queryParams[EEquipmentListForOnboardingCustomizationParams.page] ||
          CEquipmentListForOnboardingCustomizationParamsDefault[
            EEquipmentListForOnboardingCustomizationParams.page
          ]
      );

    if (
      queryParams[EEquipmentListForOnboardingCustomizationParams.cashDiscount]
    ) {
      params = params.append(
        EEquipmentListForOnboardingCustomizationParams.cashDiscount,
        queryParams[
          EEquipmentListForOnboardingCustomizationParams.cashDiscount
        ] ||
          CEquipmentListForOnboardingCustomizationParamsDefault[
            EEquipmentListForOnboardingCustomizationParams.cashDiscount
          ]
      );
    }
    return this._genericClient.genericGet<IEquipmentListForOnboardingCustomizationResponse>(
      endpoint,
      { params }
    );
  }
}
