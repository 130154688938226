export const PORTAL_PATH = {
  base: '',
  dashboards: 'dashboard',
  application: 'application',
  portfolio: 'portfolio',
  reports: 'reports',
  advancedSettlement: 'advanced-settlement',
  resources: 'resources',
  settings: 'settings',
  mms: 'mms',
  tools: 'ops-and-uw-tools',
  riskAnalysis: 'risk-analysis',
  onboardTemplate: 'settings',
  payments: 'payments',
  developers: 'developers',
  equipments: 'equipments',
};

export const enum PORTAL {
  merchant = 'm',
  entity = 'e',
}
