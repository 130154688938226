import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

import { GenericClientService } from '@clients/generic/generic-client.service';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import {
  CEntityParamsDefault,
  IEntityListParams,
  IEntityListResponse,
} from '@interfaces/entity/entity-list.interface';
import { EEntityListParams } from '@parameters/entity/entity-list.parameter';
import { IEntityDomainResponse } from '@interfaces/entity/domain/entity-domain.interface';

@Injectable()
export class EntityClientService {
  constructor(private genericClient: GenericClientService) {}

  /**
   * Get entity list
   * @param queryParams
   * @returns <IEnityListResponse> respose from server.
   */
  getEntityList(
    queryParams: IEntityListParams
  ): Observable<IEntityListResponse> {
    const httpParams = new HttpParams()
      .set(
        EEntityListParams.size,
        queryParams[EEntityListParams.size] ||
          CEntityParamsDefault[EEntityListParams.size]
      )
      .set(
        EEntityListParams.page,
        queryParams[EEntityListParams.page] ||
          CEntityParamsDefault[EEntityListParams.page]
      )
      .set(
        EEntityListParams.search,
        queryParams[EEntityListParams.search] ||
          CEntityParamsDefault[EEntityListParams.search]
      )
      .set(EEntityListParams.type, queryParams[EEntityListParams.type] || '');
    const endpoint = EndpointsParameter.EntityList;
    return this.genericClient.genericGet<IEntityListResponse>(endpoint, {
      params: httpParams,
    });
  }

  /**
   * Get entity domain
   * @param queryParams
   * @returns <IEntityDomainResponse> respose from server.
   */
  getEntityDomainById(domainId: string): Observable<IEntityDomainResponse> {
    const endpoint = EndpointsParameter.Domains;
    return this.genericClient.genericGet<IEntityDomainResponse>(
      `${endpoint}/${domainId}`,
      {}
    );
  }
}
