export enum onboardingSteps {
  business_detail_0,
  business_detail,
  accept_payment,
  payment_percentage,
  select_hardware,
  payment_gateway,
  existing_gateway_account,
  taking_payments,
  shoping_cart,
  ecommerce_platform,
  start_business,
  business_incorporated,
  where_business_incorporated,
  traded_comapany,
  business_website,
  verify_business,
  business_principles,
  personal_bankruptcy,
  sales_volume,
  ach_sales_volume,
  funds_settelment,
  product_description,
  product_received,
  store_data,
  dcc_compliant,
  general_info,
  product_services,
  summary,
  accept_merchant_agreement,
  application_submitted,
}

export const enum EOnboardingPageId {
  business_detail = 'business-detail',
  accept_payment = 'accept-payments',
  payment_percentage = 'payment-percentage',
  select_hardware = 'select-hardware',
  payment_gateway = 'payment-gateway',
  existing_gateway_account = 'gateway-account',
  taking_payments = 'taking-payments',
  shoping_cart = 'shoping-cart',
  ecommerce_platform = 'ecommerce-platform',
  start_business = 'start-business',
  business_incorporated = 'business-incorporated',
  where_business_incorporated = 'where-business-incorporated',
  traded_comapany = 'traded-comapany',
  business_website = 'business-website',
  verify_business = 'verify-business',
  business_principles = 'business-principals',
  personal_bankruptcy = 'personal-bankruptcy',
  sales_volume = 'sales-volume',
  ach_sales_volume = 'ach-sales-volume',
  funds_settelment = 'funds-settelment',
  product_description = 'product-description',
  product_received = 'product-received',
  store_data = 'store-data',
  dcc_compliant = 'dcc-compliant',
  general_info = 'general-info',
  product_services = 'product-services',
  summary = 'summary',
  accept_merchant_agreement = 'accept-agreement',
  application_submitted = 'application-submitted',
}

export class APP_ROUTES {
  public static email_verified: string = 'verification';
  public static business_detail: string = EOnboardingPageId.business_detail;
  public static business_type: string = 'business-type';
  public static accept_payment: string = EOnboardingPageId.accept_payment;
  public static payment_percentage: string =
    EOnboardingPageId.payment_percentage;
  public static select_hardware: string = EOnboardingPageId.select_hardware;
  public static payment_gateway: string = EOnboardingPageId.payment_gateway;
  public static has_existing_gateway_account: string =
    EOnboardingPageId.existing_gateway_account;
  public static virtual_terminal: string = 'virtual-terminal';
  public static taking_payments: string = EOnboardingPageId.taking_payments;
  public static shoping_cart: string = EOnboardingPageId.shoping_cart;
  public static ecommerce_platform: string =
    EOnboardingPageId.ecommerce_platform;

  public static start_business: string = EOnboardingPageId.start_business;
  public static business_incorporated: string =
    EOnboardingPageId.business_incorporated;
  public static where_business_incorporated: string =
    EOnboardingPageId.where_business_incorporated;
  public static traded_comapany: string = EOnboardingPageId.traded_comapany;
  public static business_website: string = EOnboardingPageId.business_website;
  public static verify_business: string = EOnboardingPageId.verify_business;
  public static business_principles: string =
    EOnboardingPageId.business_principles;
  public static personal_bankruptcy: string =
    EOnboardingPageId.personal_bankruptcy;
  public static sales_volume: string = EOnboardingPageId.sales_volume;
  public static ach_sales_volume: string = EOnboardingPageId.ach_sales_volume;
  public static funds_settelment: string = EOnboardingPageId.funds_settelment;
  public static product_description: string =
    EOnboardingPageId.product_description;
  public static product_received: string = EOnboardingPageId.product_received;
  public static store_data: string = EOnboardingPageId.store_data;
  public static dcc_compliant: string = EOnboardingPageId.dcc_compliant;
  public static general_info: string = EOnboardingPageId.general_info;
  public static product_services: string = EOnboardingPageId.product_services;
  public static summary: string = EOnboardingPageId.summary;
  public static accept_merchant_agreement: string =
    EOnboardingPageId.accept_merchant_agreement;
  public static application_submitted: string =
    EOnboardingPageId.application_submitted;
  public static merchant: string = 'merchant';
  public static merchants_bank: string = 'merchants-bank';
  public static dashboard: string = 'dashboard';
  public static prospects: string = 'prospects';
  public static merchants: string = 'merchants';
  public static agent: string = 'agent';
  public static reports: string = 'reports';
  public static disputes: string = 'disputes';
  public static deposits = 'deposits';
  public static deposits_bank = 'deposits-bank';
  public static deposits_merchant = 'deposits-merchant';
  public static volume = 'settled-volume';
  public static merchant_boarding = 'merchant-boarding';
}

export class APP_ROUTE_KEYS {
  // public static business_category: string = 'businessCategory';
  public static accept_payment: string = 'paymentMethods';
  public static payment_percentage: string = 'paymentPercentage';

  // route keys for handleing selected method routing
  public static select_hardware: string = 'hardwareTerminals';

  public static payment_gateway: string = 'paymentGateways';
  public static has_existing_gateway_account: string =
    'hasExistingGatewayAccount';
  public static taking_payments: string =
    'ecommercePluginsAndOnlinePaymentMethods';
  public static shoping_cart: string = 'shoppingCarts';
  public static ecommerce_platform: string = 'ecommercePlugins';
  public static traded_comapany: string = 'isPubliclyTraded';
  public static personal_bankruptcy: string = 'merchantBankruptcy';
  public static sales_volume: string = 'sales_volume';
  public static ach_sales_volume: string = 'ach_sales_volume';
  public static product_received: string = 'productDeliveryTimeline';
  public static store_data: string = 'cardHolderDataStorage';
  public static dcc_compliant: string = 'isPCICompliant';
  public static general_info: string = 'hadDataBreach';
}
export interface RoutePoints {
  stepName: string;
  stepNumber: number;
  routeKey: string;
  skip: boolean;
  pageId: string;
}

export const routesArray: RoutePoints[] = [
  {
    stepName: APP_ROUTES.business_detail,
    stepNumber: onboardingSteps.business_detail_0,
    routeKey: '',
    skip: false,
    pageId: EOnboardingPageId.business_detail,
  },
  {
    stepName: APP_ROUTES.business_detail,
    stepNumber: onboardingSteps.business_detail,
    routeKey: '',
    skip: false,
    pageId: EOnboardingPageId.business_detail,
  },
  {
    stepName: APP_ROUTES.accept_payment,
    stepNumber: onboardingSteps.accept_payment,
    routeKey: APP_ROUTE_KEYS.accept_payment,
    skip: false,
    pageId: EOnboardingPageId.accept_payment,
  },
  {
    stepName: APP_ROUTES.payment_percentage,
    stepNumber: onboardingSteps.payment_percentage,
    routeKey: APP_ROUTE_KEYS.payment_percentage,
    skip: false,
    pageId: EOnboardingPageId.payment_percentage,
  },
  {
    stepName: APP_ROUTES.select_hardware,
    stepNumber: onboardingSteps.select_hardware,
    routeKey: APP_ROUTE_KEYS.select_hardware,
    skip: false,
    pageId: EOnboardingPageId.select_hardware,
  },
  {
    stepName: APP_ROUTES.payment_gateway,
    stepNumber: onboardingSteps.payment_gateway,
    routeKey: APP_ROUTE_KEYS.payment_gateway,
    skip: false,
    pageId: EOnboardingPageId.payment_gateway,
  },
  {
    stepName: APP_ROUTES.has_existing_gateway_account,
    stepNumber: onboardingSteps.existing_gateway_account,
    routeKey: APP_ROUTE_KEYS.has_existing_gateway_account,
    skip: false,
    pageId: EOnboardingPageId.existing_gateway_account,
  },
  {
    stepName: APP_ROUTES.taking_payments,
    stepNumber: onboardingSteps.taking_payments,
    routeKey: APP_ROUTE_KEYS.taking_payments,
    skip: false,
    pageId: EOnboardingPageId.taking_payments,
  },
  {
    stepName: APP_ROUTES.shoping_cart,
    stepNumber: onboardingSteps.shoping_cart,
    routeKey: APP_ROUTE_KEYS.shoping_cart,
    skip: false,
    pageId: EOnboardingPageId.shoping_cart,
  },
  {
    stepName: APP_ROUTES.ecommerce_platform,
    stepNumber: onboardingSteps.ecommerce_platform,
    routeKey: APP_ROUTE_KEYS.ecommerce_platform,
    skip: true,
    pageId: EOnboardingPageId.ecommerce_platform,
  },

  {
    stepName: APP_ROUTES.start_business,
    stepNumber: onboardingSteps.start_business,
    routeKey: '',
    skip: false,
    pageId: EOnboardingPageId.start_business,
  },
  {
    stepName: APP_ROUTES.business_incorporated,
    stepNumber: onboardingSteps.business_incorporated,
    routeKey: '',
    skip: false,
    pageId: EOnboardingPageId.business_incorporated,
  },
  {
    stepName: APP_ROUTES.where_business_incorporated,
    stepNumber: onboardingSteps.where_business_incorporated,
    routeKey: '',
    skip: false,
    pageId: EOnboardingPageId.where_business_incorporated,
  },
  {
    stepName: APP_ROUTES.traded_comapany,
    stepNumber: onboardingSteps.traded_comapany,
    routeKey: APP_ROUTE_KEYS.traded_comapany,
    skip: false,
    pageId: EOnboardingPageId.traded_comapany,
  },
  {
    stepName: APP_ROUTES.business_website,
    stepNumber: onboardingSteps.business_website,
    routeKey: '',
    skip: false,
    pageId: EOnboardingPageId.business_website,
  },
  {
    stepName: APP_ROUTES.verify_business,
    stepNumber: onboardingSteps.verify_business,
    routeKey: '',
    skip: false,
    pageId: EOnboardingPageId.verify_business,
  },
  {
    stepName: APP_ROUTES.business_principles,
    stepNumber: onboardingSteps.business_principles,
    routeKey: '',
    skip: false,
    pageId: EOnboardingPageId.business_principles,
  },
  {
    stepName: APP_ROUTES.personal_bankruptcy,
    stepNumber: onboardingSteps.personal_bankruptcy,
    routeKey: APP_ROUTE_KEYS.personal_bankruptcy,
    skip: false,
    pageId: EOnboardingPageId.personal_bankruptcy,
  },
  {
    stepName: APP_ROUTES.sales_volume,
    stepNumber: onboardingSteps.sales_volume,
    routeKey: APP_ROUTE_KEYS.sales_volume,
    skip: false,
    pageId: EOnboardingPageId.sales_volume,
  },
  {
    stepName: APP_ROUTES.ach_sales_volume,
    stepNumber: onboardingSteps.ach_sales_volume,
    routeKey: APP_ROUTE_KEYS.ach_sales_volume,
    skip: true,
    pageId: EOnboardingPageId.ach_sales_volume,
  },
  {
    stepName: APP_ROUTES.funds_settelment,
    stepNumber: onboardingSteps.funds_settelment,
    routeKey: '',
    skip: false,
    pageId: EOnboardingPageId.funds_settelment,
  },
  {
    stepName: APP_ROUTES.product_description,
    stepNumber: onboardingSteps.product_description,
    routeKey: '',
    skip: false,
    pageId: EOnboardingPageId.product_description,
  },
  {
    stepName: APP_ROUTES.product_received,
    stepNumber: onboardingSteps.product_received,
    routeKey: APP_ROUTE_KEYS.product_received,
    skip: false,
    pageId: EOnboardingPageId.product_received,
  },
  {
    stepName: APP_ROUTES.store_data,
    stepNumber: onboardingSteps.store_data,
    routeKey: APP_ROUTE_KEYS.store_data,
    skip: false,
    pageId: EOnboardingPageId.store_data,
  },
  {
    stepName: APP_ROUTES.dcc_compliant,
    stepNumber: onboardingSteps.dcc_compliant,
    routeKey: APP_ROUTE_KEYS.dcc_compliant,
    skip: false,
    pageId: EOnboardingPageId.dcc_compliant,
  },
  {
    stepName: APP_ROUTES.general_info,
    stepNumber: onboardingSteps.general_info,
    routeKey: APP_ROUTE_KEYS.general_info,
    skip: false,
    pageId: EOnboardingPageId.general_info,
  },
  {
    stepName: APP_ROUTES.product_services,
    stepNumber: onboardingSteps.product_services,
    routeKey: '',
    skip: false,
    pageId: EOnboardingPageId.product_services,
  },
  {
    stepName: APP_ROUTES.summary,
    stepNumber: onboardingSteps.summary,
    routeKey: '',
    skip: false,
    pageId: EOnboardingPageId.summary,
  },
  {
    stepName: APP_ROUTES.accept_merchant_agreement,
    stepNumber: onboardingSteps.accept_merchant_agreement,
    routeKey: '',
    skip: false,
    pageId: EOnboardingPageId.accept_merchant_agreement,
  },
  {
    stepName: APP_ROUTES.application_submitted,
    stepNumber: onboardingSteps.application_submitted,
    routeKey: '',
    skip: false,
    pageId: EOnboardingPageId.application_submitted,
  },
];
