import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { AccountsStoreService } from '../../services/accounts-store.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Account } from '../../models/accounts-list.model';
import {
  CGatewayAccountColor,
  CGatewayAccountStatus,
} from '@parameters/accounts/accounts-list.parameter';

@Component({
  selector: 'app-account-detail-home',
  templateUrl: './account-detail-home.component.html',
  styleUrls: ['./account-detail-home.component.scss'],
})
export class AccountDetailHomeComponent implements OnInit {
  private readonly _cdr = inject(ChangeDetectorRef);
  private readonly _ngZone = inject(NgZone);
  private readonly _destroyRef = inject(DestroyRef);

  private readonly _accountsStore = inject(AccountsStoreService);
  public hasDealId: boolean = false;

  @Output() onEdit: EventEmitter<any> = new EventEmitter();

  @Input({ required: true }) public accountId: string;
  @Input() set isDeal(value: boolean) {
    if (value) this.hasDealId = value;
  }

  public readonly gatewayStatusLabel = CGatewayAccountStatus;
  public readonly gatewayStatusColor = CGatewayAccountColor;

  public loading: boolean = false;
  public accountDetail: Account;
  public isEditable: boolean = false;

  ngOnInit(): void {
    this._fetchDetails();
  }

  private _fetchDetails() {
    this.loading = true;
    this._accountsStore
      .getAccountDetail(this.accountId)
      ?.pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe({
        next: (res) => {
          this.loading = false;
          this.accountDetail = res;
        },
        error: () => {
          this.accountDetail = undefined;
          this._ngZone.run(() => {
            this.loading = false;
            this._cdr.detectChanges();
          });
        },
      });
  }

  public handleFormClose(refresh: boolean) {
    this.isEditable = false;
    refresh && this._fetchDetails();
  }

  public toggleEdit() {
    if (!this.hasDealId) {
      this.isEditable = !this.isEditable;
    } else {
      this.onEdit.emit(true);
    }
  }
}
