export enum EDocumentTypes {
  Pdf = 'pdf',
  Jpg = 'jpg',
  Excel = 'excel',
  Png = 'png',
}

export const DocumentUrls = {
  [EDocumentTypes.Pdf]: '/assets/images/logo/pdf-logo.png',
  [EDocumentTypes.Jpg]: '/assets/images/logo/jpg-logo.png',
  [EDocumentTypes.Excel]: '/assets/images/logo/excel-logo.png',
  [EDocumentTypes.Png]: '/assets/images/logo/png-logo.png',
};

export interface ITrainingVideo {
  title: string;
  description: string;
  imageSrc: string;
  date: string;
  id: number;
  tags: string[];
}

export interface ITrainingSection {
  title: string;
  documents: ITrainingDocument[];
}

export interface ITrainingDocument {
  title: string;
  date: string;
  type: EDocumentTypes;
  id: number;
}
