export const DEVELOPERS_PATHS = {
  base: 'developers',
  apiKeys: 'api-keys',
  partnerAccountId: 'partnerAccountId',
  webhook: 'webhook',
};

export const WEBHOOK_PARAMS = {
  editionId: 'id',
  editionIdCreate: 'create',
};
