import { Injectable } from '@angular/core';
import { GenericClientService } from '@clients/generic/generic-client.service';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import {
  CReportsBatchListParamsDefault,
  IReportsBatchCSVParams,
  IReportsBatchCSVResponse,
  IReportsBatchDetailCSVParams,
  IReportsBatchListParams,
  IReportsBatchListResponse,
} from '@interfaces/reports/batch/batch-list.interface';
import { HttpParams } from '@angular/common/http';
import { EReportsBatchListParams } from '@parameters/reports/batch/batch-list.parameter';
import { Observable } from 'rxjs';
import {
  CReportsBatchDetailParamsDefault,
  IReportsBatchDetailParams,
  IReportsBatchDetailResponse,
} from '@interfaces/reports/batch/batch-detail.interface';
import { EReportsBatchDetailParams } from '@parameters/reports/batch/batch-detail.parameter';
import { GenericHelper } from '@helpers/generic.helper';

@Injectable()
export class BatchClientService {
  constructor(private genericClient: GenericClientService) {}

  getBatchList(
    queryParams: IReportsBatchListParams
  ): Observable<IReportsBatchListResponse> {
    const endpoint = EndpointsParameter.ReportsBatch;
    const params = new HttpParams()
      .set(
        EReportsBatchListParams.size,
        queryParams[EReportsBatchListParams.size] ||
          CReportsBatchListParamsDefault[EReportsBatchListParams.size]
      )
      .set(
        EReportsBatchListParams.page,
        queryParams[EReportsBatchListParams.page] ||
          CReportsBatchListParamsDefault[EReportsBatchListParams.page]
      )
      .set(
        EReportsBatchListParams.sort,
        queryParams[EReportsBatchListParams.sort] ||
          CReportsBatchListParamsDefault[EReportsBatchListParams.sort]
      )
      .set(
        EReportsBatchListParams.search,
        queryParams[EReportsBatchListParams.search] ||
          CReportsBatchListParamsDefault[EReportsBatchListParams.search]
      )
      .set(
        EReportsBatchListParams.startDate,
        queryParams[EReportsBatchListParams.startDate] ||
          CReportsBatchListParamsDefault[EReportsBatchListParams.startDate]
      )
      .set(
        EReportsBatchListParams.endDate,
        queryParams[EReportsBatchListParams.endDate] ||
          CReportsBatchListParamsDefault[EReportsBatchListParams.endDate]
      )
      .set(
        EReportsBatchListParams.midList,
        queryParams[EReportsBatchListParams.midList].join(',') ||
          CReportsBatchListParamsDefault[EReportsBatchListParams.midList].join(
            ','
          )
      )
      .set(
        EReportsBatchListParams.binList,
        queryParams[EReportsBatchListParams.binList].join(',') ||
          CReportsBatchListParamsDefault[EReportsBatchListParams.binList].join(
            ','
          )
      )
      .set(
        EReportsBatchListParams.cardBrandList,
        queryParams[EReportsBatchListParams.cardBrandList].join(',') ||
          CReportsBatchListParamsDefault[
            EReportsBatchListParams.cardBrandList
          ].join(',')
      );
    return this.genericClient.genericGet<IReportsBatchListResponse>(endpoint, {
      params,
    });
  }

  getBatchDetail(
    id: string,
    queryParams: IReportsBatchDetailParams
  ): Observable<IReportsBatchDetailResponse> {
    const endpoint = `${EndpointsParameter.ReportsBatch}/${id}`;
    const params = new HttpParams()
      .set(
        EReportsBatchDetailParams.size,
        queryParams[EReportsBatchDetailParams.size] ||
          CReportsBatchDetailParamsDefault[EReportsBatchDetailParams.size]
      )
      .set(
        EReportsBatchDetailParams.page,
        queryParams[EReportsBatchDetailParams.page] ||
          CReportsBatchDetailParamsDefault[EReportsBatchDetailParams.page]
      );
    return this.genericClient.genericGet<IReportsBatchDetailResponse>(
      endpoint,
      {
        params,
      }
    );
  }

  downloadCSV(
    body: IReportsBatchCSVParams
  ): Observable<IReportsBatchCSVResponse> {
    const endpoint = EndpointsParameter.ReportsBatchCSV;
    return this.genericClient.genericPost<IReportsBatchCSVResponse>(
      endpoint,
      body
    );
  }

  downloadBatchDetailCSV(
    batchId: string,
    body: Partial<IReportsBatchDetailCSVParams>
  ) {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.ReportsBatchDetailCSV,
      batchId
    );
    return this.genericClient.genericPost<IReportsBatchCSVResponse>(
      endpoint,
      body
    );
  }
}
