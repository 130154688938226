import {
  IResponseBase,
  IResponseData,
} from '@interfaces/generic/responses.interface';
import {
  EEntityListParams,
  EEntityType,
} from '@parameters/entity/entity-list.parameter';

export interface IEntityData {
  id: string;
  accountId: string;
  firstName: string;
  lastName: string;
  entityDbaName: string;
  entityLegalName: string;
}

export interface IEntityListResponse
  extends IResponseBase<IResponseData<IEntityData>> {}

export interface IEntityListParams {
  [EEntityListParams.page]: number;
  [EEntityListParams.size]: number;
  [EEntityListParams.search]: string;
  [EEntityListParams.type]: EEntityType;
}

export const CEntityParamsDefault: IEntityListParams = {
  [EEntityListParams.type]: EEntityType.agent,
  [EEntityListParams.page]: 1,
  [EEntityListParams.size]: 10,
  [EEntityListParams.search]: '',
};
