import { DateTimeFormatOptions } from '@gettrx/core-components-angular/lib/molecules/time-picker/gtr-time.utils';
import { DatesHelper } from '@helpers/date/dates.helper';
import { EDateFilter } from '@parameters/filter/date.parameter';
import dayjs from 'dayjs';

const todayDate = dayjs().date();
const defaultDate = 20;
const defaultLabelFormat = {
  month: 'long',
  year: 'numeric',
} as DateTimeFormatOptions;

function getStartDate(monthsAgo: number): Date {
  return dayjs().local().subtract(monthsAgo, 'month').startOf('month').toDate();
}

function getEndDate(monthsAgo: number): Date {
  return dayjs().local().subtract(monthsAgo, 'month').endOf('month').toDate();
}

export function getReportsResidualDateValue(): {
  startDate: string;
  endDate: string;
} {
  if (todayDate > defaultDate) {
    const startDate =
      DatesHelper.startOfDayFirstDayOfLastMonthLocal().format('YYYY-MM-DD');
    const endDate =
      DatesHelper.endOfDayLastDayOfLastMonthLocal().format('YYYY-MM-DD');

    return { startDate, endDate };
  } else {
    const _startDateOfMonth = getStartDate(2);
    const _endDateOfMonth = getEndDate(2);

    const startDate =
      DatesHelper.customStartOfDayLocal(_startDateOfMonth).format('YYYY-MM-DD');
    const endDate =
      DatesHelper.customEndOfDayLocal(_endDateOfMonth).format('YYYY-MM-DD');

    return { startDate, endDate };
  }
}

export function getReportsResidualUnixDate(): {
  startDate: number;
  endDate: number;
} {
  if (todayDate > defaultDate) {
    const startDate = DatesHelper.startOfDayFirstDayOfLastMonthUnixTimestamp();
    const endDate = DatesHelper.endOfDayLastDayOfLastMonthUnixTimestamp();

    return { startDate, endDate };
  } else {
    const _startDateOfMonth = getStartDate(2);
    const _endDateOfMonth = getEndDate(2);

    const startDate =
      DatesHelper.customStartOfDayUnixTimestamp(_startDateOfMonth);
    const endDate = DatesHelper.customEndOfDayUnixTimestamp(_endDateOfMonth);

    return { startDate, endDate };
  }
}

export function getReportsResidualDateFilterListValue() {
  const startDate = dayjs(getReportsResidualDateValue().startDate).toDate();

  return {
    name: EDateFilter.custom,
    value: getReportsResidualDateValue(),
    unixValue: getReportsResidualUnixDate(),
    label: `${startDate.toLocaleDateString('default', defaultLabelFormat)}`,
  };
}
