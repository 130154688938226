import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericClientService } from '@clients/generic/generic-client.service';
import { GenericHelper } from '@helpers/generic.helper';
import {
  IAddOfferPricing,
  IAddOfferPricingResponse,
  IOfferPricingResponse,
} from '@interfaces/offer-pricing/offer-pricing.interface';
import {
  IOnboardingAllowedDomainParams,
  IOnboardingAllowedDomainResponse,
} from '@interfaces/onboarding-template/allowed-domain.interface';
import {
  IOnboardingTemplateCreate,
  IOnboardingTemplateCreateResponseData,
} from '@interfaces/onboarding-template/onboarding-template-create.interface';
import {
  IFindOnboardingTemplateResponse,
  IOnboardingTemplateEditRequest,
  IOnboardingTemplateEditResponseData,
} from '@interfaces/onboarding-template/onboarding-template-detail.interface';
import {
  COnboardingTemplateParamsDefault,
  IOnboardingTemplateParams,
  IOnboardingTemplateResponse,
} from '@interfaces/onboarding-template/onboarding-template.interface';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import { EOnboardingAllowedDomainParams } from '@parameters/onboarding-template/onboarding-allowed-domain.parameter';
import { EOnboardingTemplateParams } from '@parameters/onboarding-template/onboarding-template.parameter';
import { Observable } from 'rxjs';

@Injectable()
export class OnboardingTemplateClientService {
  constructor(private genericClient: GenericClientService) {}

  getOnboardingTemplateList(
    queryParams: IOnboardingTemplateParams
  ): Observable<IOnboardingTemplateResponse> {
    const endpoint = EndpointsParameter.OnboardingTemplate;
    const params = new HttpParams()
      .set(
        EOnboardingTemplateParams.page,
        queryParams[EOnboardingTemplateParams.page] ||
          COnboardingTemplateParamsDefault[EOnboardingTemplateParams.page]
      )
      .set(
        EOnboardingTemplateParams.search,
        queryParams[EOnboardingTemplateParams.search] ||
          COnboardingTemplateParamsDefault[EOnboardingTemplateParams.search]
      )
      .set(
        EOnboardingTemplateParams.size,
        queryParams[EOnboardingTemplateParams.size] ||
          COnboardingTemplateParamsDefault[EOnboardingTemplateParams.size]
      )
      .set(
        EOnboardingTemplateParams.sort,
        queryParams[EOnboardingTemplateParams.sort] ||
          COnboardingTemplateParamsDefault[EOnboardingTemplateParams.sort]
      )
      .set(
        EOnboardingTemplateParams.status,
        queryParams[EOnboardingTemplateParams.status].join(',') ||
          COnboardingTemplateParamsDefault[
            EOnboardingTemplateParams.status
          ].join(',')
      );

    return this.genericClient.genericGet<IOnboardingTemplateResponse>(
      endpoint,
      {
        params,
      }
    );
  }

  /**
   *
   * @param queryParams
   * @returns
   */

  getOnboardingAllowedDomainList(
    onboardingTemplateId: string,
    queryParams: IOnboardingAllowedDomainParams
  ): Observable<IOnboardingAllowedDomainResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.OnboardingAllowedDomains,
      onboardingTemplateId
    );
    const params = new HttpParams()
      .set(
        EOnboardingAllowedDomainParams.page,
        queryParams[EOnboardingAllowedDomainParams.page] ||
          COnboardingTemplateParamsDefault[EOnboardingAllowedDomainParams.page]
      )
      .set(
        EOnboardingAllowedDomainParams.search,
        queryParams[EOnboardingAllowedDomainParams.search] ||
          COnboardingTemplateParamsDefault[
            EOnboardingAllowedDomainParams.search
          ]
      )
      .set(
        EOnboardingAllowedDomainParams.size,
        queryParams[EOnboardingAllowedDomainParams.size] ||
          COnboardingTemplateParamsDefault[EOnboardingAllowedDomainParams.size]
      )
      .set(
        EOnboardingAllowedDomainParams.sort,
        queryParams[EOnboardingAllowedDomainParams.sort] ||
          COnboardingTemplateParamsDefault[EOnboardingAllowedDomainParams.sort]
      );

    return this.genericClient.genericGet<IOnboardingAllowedDomainResponse>(
      endpoint,
      {
        params,
      }
    );
  }

  /**
   *
   * @param queryParams
   * @returns
   */

  getCompleteDomainList(
    queryParams: IOnboardingAllowedDomainParams
  ): Observable<IOnboardingAllowedDomainResponse> {
    const endpoint = EndpointsParameter.Domains;

    let params = new HttpParams()
      .set(
        EOnboardingAllowedDomainParams.page,
        queryParams[EOnboardingAllowedDomainParams.page] ||
          COnboardingTemplateParamsDefault[EOnboardingAllowedDomainParams.page]
      )
      .set(
        EOnboardingAllowedDomainParams.search,
        queryParams[EOnboardingAllowedDomainParams.search] ||
          COnboardingTemplateParamsDefault[
            EOnboardingAllowedDomainParams.search
          ]
      )
      .set(
        EOnboardingAllowedDomainParams.size,
        queryParams[EOnboardingAllowedDomainParams.size] ||
          COnboardingTemplateParamsDefault[EOnboardingAllowedDomainParams.size]
      );

    if (queryParams[EOnboardingAllowedDomainParams.entityId]) {
      params = params.append(
        EOnboardingAllowedDomainParams.entityId,
        queryParams[EOnboardingAllowedDomainParams.entityId] || ''
      );
    }

    return this.genericClient.genericGet<IOnboardingAllowedDomainResponse>(
      endpoint,
      {
        params,
      }
    );
  }

  /**
   * Get Pricing Plan Data
   * @param prospectId
   * @returns
   */
  public getOnboardingPricingPlanData(
    onboardingTemplateId: string
  ): Observable<IOfferPricingResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.OnboardingPricingPlan,
      onboardingTemplateId
    );
    return this.genericClient.genericGet<IOfferPricingResponse>(endpoint);
  }

  /**
   * Add/Update Pricing Plan
   * @param data
   * @returns
   */
  public updateOnboardingPricingPlan(
    data: IAddOfferPricing,
    onboardingTemplateId: string,
    isEdit: boolean = false
  ): Observable<IAddOfferPricingResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.OnboardingPricingPlan,
      onboardingTemplateId
    );
    delete (data as any)?.visualizeBrand;
    if (isEdit) {
      return this.genericClient.genericPatch<IAddOfferPricingResponse>(
        endpoint,
        data
      );
    } else {
      return this.genericClient.genericPost<IAddOfferPricingResponse>(
        endpoint,
        data
      );
    }
  }

  /**
   * Create Onboarding Template
   * @param params
   * @returns
   */
  public createOnboardingTemplate(
    params: IOnboardingTemplateCreate
  ): Observable<IOnboardingTemplateCreateResponseData> {
    const endpoint = EndpointsParameter.OnboardingTemplate;

    return this.genericClient.genericPost<IOnboardingTemplateCreateResponseData>(
      endpoint,
      params
    );
  }

  /**
   * Get Onboarding Data
   * @param
   * @returns
   */
  public getOnboardingDetail(
    onboardingTemplateId: string
  ): Observable<IFindOnboardingTemplateResponse> {
    const endpoint = EndpointsParameter.OnboardingTemplate;
    return this.genericClient.genericGet<IFindOnboardingTemplateResponse>(
      `${endpoint}/${onboardingTemplateId}`
    );
  }

  /**
   * Update Onboarding template Detail
   * @param
   * @returns
   */
  public updateOnboardingTemplateDetail(
    onboardingTemplateId: string,
    params: IOnboardingTemplateEditRequest
  ): Observable<IOnboardingTemplateEditResponseData> {
    const endpoint = EndpointsParameter.OnboardingTemplate;
    return this.genericClient.genericPatch<IOnboardingTemplateEditResponseData>(
      `${endpoint}/${onboardingTemplateId}`,
      params
    );
  }
}
