<!-- offer banner  -->
<ng-container>
  <ng-container
    [ngTemplateOutlet]="isOpen ? mobileOfferBanner : offerBanner"
  ></ng-container>
</ng-container>

<ng-template #scrollListItem let-data="data">
  <div class="offer_list w-100 mb-1" *ngIf="data?.isVisible">
    <div
      class="d-flex flex-row align-items-center justify-content-between px-1 py-1"
    >
      <span class="fs-12 fw-300 text-start">{{ data?.label }}</span>
      <span class="fs-13 fw-400 first-value mwx-100 text-end">
        {{ data?.value }}
      </span>
    </div>
  </div>
</ng-template>

<ng-template #scrollListItemLabel let-label="label">
  <div class="other-agent-fees-type mb-1">
    <span> {{ label }} </span>
  </div>
</ng-template>

<ng-template #mobileOfferBanner>
  <div class="offer-banner-modal" id="offer-toggle-banner">
    <div class="offer-banner-modal-content">
      <div id="sd-ofr" class="new-sidebar-offer font-roboto">
        <div class="offer-banner-modal-header">
          <button
            type="button"
            class="btn-close white fs-14"
            (click)="closeModal()"
            [attr.data-transaction-name]="
              dataTransactionName + '--offer-banner--close'
            "
            aria-label="Close"
          ></button>
        </div>
        <div class="offer_image_web text-center">
          <div
            class="row new_offer_content_web text-white pt-1 m-0 btn-primary"
            [class.show-offer-img]="
              brandingData && brandingData.showSidebarImage
            "
            [class.show-full-hight]="isOnboardingPage"
          >
            <div
              class="col-12 text-center"
              style="z-index: 9"
              *ngIf="pricingBannerData()"
            >
              <section class="top-header">
                <h3 class="text-white fw-600 mb-0 mt-3">
                  {{ pricingBannerData()?.tagLine }}
                </h3>
                <p class="fw-300 fs-14"></p>

                <!-- Ach only header -->
                <ng-container
                  *ngIf="
                    pricingBannerData()?.paymentMethodType ===
                    paymentMethodType.ACH_ONLY
                  "
                >
                  <offer-banner-ach-core-fees
                    [achCoreFees]="pricingBannerData()?.achCoreFees"
                  />
                </ng-container>

                <!-- Flat Rate Header -->
                <ng-container
                  *ngIf="
                    pricingBannerData()?.pricingType ===
                      offerPricingType.FLAT_RATE &&
                    pricingBannerData()?.flatRateCoreFees
                  "
                >
                  <offer-banner-flat-rate
                    [flatRateCoreFees]="pricingBannerData()?.flatRateCoreFees"
                  ></offer-banner-flat-rate>
                </ng-container>
                <!-- Flat Rate Header Ends -->

                <!-- Cash Discount Header -->
                <ng-container
                  *ngIf="
                    pricingBannerData()?.pricingType ===
                      offerPricingType.CASH_DISCOUNT &&
                    pricingBannerData()?.cashDiscountCoreFees
                  "
                >
                  <offer-banner-cash-discount
                    [cashDiscountCoreFees]="
                      pricingBannerData()?.cashDiscountCoreFees
                    "
                  ></offer-banner-cash-discount>
                </ng-container>
                <!-- Cash Discount Header Ends -->

                <!-- Tiered -->
                <ng-container
                  *ngIf="
                    pricingBannerData()?.pricingType ===
                      offerPricingType.TIERED &&
                    pricingBannerData()?.tieredCoreFees
                  "
                >
                  <offer-banner-tiered
                    [tieredCoreFees]="pricingBannerData()?.tieredCoreFees"
                  ></offer-banner-tiered>
                </ng-container>
                <!-- Tiered Ends -->

                <!-- Interchange Header -->
                <ng-container
                  *ngIf="
                    pricingBannerData()?.pricingType ===
                      offerPricingType.INTERCHANGE_PLUS &&
                    pricingBannerData()?.interchangePlusCoreFees
                  "
                >
                  <offer-banner-interchange-plus
                    [interchangePlusCoreFees]="
                      pricingBannerData()?.interchangePlusCoreFees
                    "
                  ></offer-banner-interchange-plus>
                </ng-container>
                <!-- Interchange Header Ends -->
              </section>

              <!-- Other Fees Wrapper -->
              <div
                class="d-flex flex-column justify-content-center align-items-center sidev2 key-list"
              >
                <div
                  class="offers-outer-wrapper custom-scroll overflow-auto mxh-405"
                  [style.width]="'100%'"
                  [style.max-height]="
                    listHeight
                      ? listHeight + 'px !important'
                      : '400px !important'
                  "
                  [style.min-height]="
                    listHeight &&
                    listHeight < 185 &&
                    listHeight + 'px !important'
                  "
                >
                  <!-- Cash Discount Program Rate -->
                  <ng-container
                    *ngIf="
                      pricingBannerData()?.pricingType ===
                        offerPricingType.CASH_DISCOUNT &&
                      pricingBannerData()?.cashDiscountCoreFees &&
                      pricingBannerData()?.cashDiscountCoreFees
                        ?.cashDiscountProgramRatePercentage
                    "
                    [ngTemplateOutlet]="scrollListItem"
                    [ngTemplateOutletContext]="{
                      data: {
                        label: 'Program Rate *',
                        value:
                          (pricingBannerData()?.cashDiscountCoreFees
                            ?.cashDiscountProgramRatePercentage
                            | roundWithDecimal) + '%',
                        isVisible: true
                      }
                    }"
                  >
                  </ng-container>
                  <!-- Cash Discount Program Rate Ends -->

                  <!-- Interchange plus -->
                  <ng-container
                    *ngIf="
                      pricingBannerData()?.pricingType ===
                        offerPricingType.INTERCHANGE_PLUS &&
                      pricingBannerData()?.interchangePlusCoreFees
                    "
                  >
                    <!-- Not Highlight per item -->
                    <ng-container
                      *ngIf="
                        !hasInterchangeHighlightPerItem() &&
                        pricingBannerData()?.interchangePlusCoreFees
                          ?.intPlusPerItem
                      "
                      [ngTemplateOutlet]="scrollListItem"
                      [ngTemplateOutletContext]="{
                        data: {
                          label: 'Per Item',
                          value:
                            (pricingBannerData()?.interchangePlusCoreFees
                              ?.intPlusPerItem | currencySymbol),
                          isVisible: true
                        }
                      }"
                    ></ng-container>

                    <!-- Highlight per item and show auth -->
                    <ng-container *ngIf="hasInterchangeHighlightPerItem()">
                      <ng-container
                        *ngIf="
                          pricingBannerData()?.interchangePlusCoreFees
                            ?.intPlusAuthFee && !hasInterchangeSameAuthFee()
                        "
                        [ngTemplateOutlet]="scrollListItem"
                        [ngTemplateOutletContext]="{
                          data: {
                            label: 'Auth Fee',
                            value:
                              (pricingBannerData()?.interchangePlusCoreFees
                                ?.intPlusAuthFee | currencySymbol),
                            isVisible: true
                          }
                        }"
                      ></ng-container>

                      <ng-container
                        *ngIf="
                          pricingBannerData()?.interchangePlusCoreFees
                            ?.intPlusAmexAuthFee && !hasInterchangeSameAuthFee()
                        "
                        [ngTemplateOutlet]="scrollListItem"
                        [ngTemplateOutletContext]="{
                          data: {
                            label: 'AMEX Auth Fee',
                            value:
                              (pricingBannerData()?.interchangePlusCoreFees
                                ?.intPlusAmexAuthFee | currencySymbol),
                            isVisible: true
                          }
                        }"
                      ></ng-container>

                      <ng-container
                        *ngIf="
                          hasInterchangeSameAuthFee() &&
                          pricingBannerData()?.interchangePlusCoreFees
                            ?.intPlusAuthFee
                        "
                        [ngTemplateOutlet]="scrollListItem"
                        [ngTemplateOutletContext]="{
                          data: {
                            label: 'Auth Fee',
                            value:
                              (pricingBannerData()?.interchangePlusCoreFees
                                ?.intPlusAuthFee | currencySymbol),
                            isVisible: true
                          }
                        }"
                      ></ng-container>
                    </ng-container>

                    <!-- DEBIT & PIN DEBIT AUTH FEE -->
                    <ng-container
                      *ngIf="
                        hasSameInterchangeAuthFee() &&
                        pricingBannerData()?.interchangePlusCoreFees
                          ?.intPlusPinDebitAuthFee
                      "
                      [ngTemplateOutlet]="scrollListItem"
                      [ngTemplateOutletContext]="{
                        data: {
                          label: 'Debit/EBT Auth Fee',
                          value:
                            (pricingBannerData()?.interchangePlusCoreFees
                              ?.intPlusPinDebitAuthFee | currencySymbol),
                          isVisible: true
                        }
                      }"
                    ></ng-container>

                    <ng-container
                      *ngIf="
                        !hasSameInterchangeAuthFee() &&
                        pricingBannerData()?.interchangePlusCoreFees
                          ?.intPlusPinDebitAuthFee
                      "
                      [ngTemplateOutlet]="scrollListItem"
                      [ngTemplateOutletContext]="{
                        data: {
                          label: 'PIN Debit Auth Fee',
                          value:
                            (pricingBannerData()?.interchangePlusCoreFees
                              ?.intPlusPinDebitAuthFee | currencySymbol),
                          isVisible: true
                        }
                      }"
                    ></ng-container>

                    <ng-container
                      *ngIf="
                        !hasSameInterchangeAuthFee() &&
                        pricingBannerData()?.interchangePlusCoreFees
                          ?.intPlusEbtAuthFee
                      "
                      [ngTemplateOutlet]="scrollListItem"
                      [ngTemplateOutletContext]="{
                        data: {
                          label: 'EBT Auth Fee',
                          value:
                            (pricingBannerData()?.interchangePlusCoreFees
                              ?.intPlusEbtAuthFee | currencySymbol),
                          isVisible: true
                        }
                      }"
                    ></ng-container>
                  </ng-container>
                  <!-- Interchange plus Ends -->

                  <!-- Tiered Offer Start -->
                  <ng-container
                    *ngIf="
                      pricingBannerData()?.pricingType ===
                        offerPricingType.TIERED &&
                      pricingBannerData()?.tieredCoreFees
                    "
                  >
                    <!-- Highlight auth fee -->
                    <ng-container *ngIf="hasTieredHighlightAuthFee()">
                      <!-- Has same qual rate -->
                      <ng-container
                        *ngIf="
                          (hasTieredSameQualPerItem() ||
                            hasSameSignatureDebit() ||
                            hasSameAmex()) &&
                          hasTieredQualRate()
                        "
                      >
                        <div class="offer_list w-100 mb-1">
                          <div
                            class="d-flex flex-row align-items-center justify-content-between px-1 py-1"
                          >
                            <span class="fs-12 fw-300 text-start"
                              >Qual Per Item</span
                            >
                            <span
                              class="fs-13 fw-400 first-value mwx-100 text-end"
                            >
                              {{ hasTieredQualRate() | currencySymbol }}
                            </span>
                          </div>
                        </div>
                      </ng-container>

                      <!-- Debit Signature -->
                      <ng-container
                        *ngIf="
                          !hasTieredSameQualPerItem() &&
                          !hasSameSignatureDebit() &&
                          hasTieredSignatureDebitRate()
                        "
                      >
                        <div class="offer_list w-100 mb-1">
                          <div
                            class="d-flex flex-row align-items-center justify-content-between px-1 py-1"
                          >
                            <span class="fs-12 fw-300 text-start"
                              >Signature Debit Qual Per Item</span
                            >
                            <span
                              class="fs-13 fw-400 first-value mwx-100 text-end"
                            >
                              {{
                                hasTieredSignatureDebitRate() | currencySymbol
                              }}
                            </span>
                          </div>
                        </div>
                      </ng-container>

                      <!-- VI/MC/DS Qual Per Item -->
                      <ng-container
                        *ngIf="
                          !hasTieredSameQualPerItem() &&
                          !hasSameAmex() &&
                          !hasSameSignatureDebit() &&
                          hasTieredQualRate()
                        "
                      >
                        <div class="offer_list w-100 mb-1">
                          <div
                            class="d-flex flex-row align-items-center justify-content-between px-1 py-1"
                          >
                            <span class="fs-12 fw-300 text-start"
                              >VI/MC/DS Qual Per Item</span
                            >
                            <span
                              class="fs-13 fw-400 first-value mwx-100 text-end"
                            >
                              {{ hasTieredQualRate() | currencySymbol }}
                            </span>
                          </div>
                        </div>
                      </ng-container>

                      <!-- Amex Rate -->
                      <ng-container
                        *ngIf="
                          !hasTieredSameQualPerItem() &&
                          !hasSameAmex() &&
                          hasTieredAmexRate()
                        "
                      >
                        <div class="offer_list w-100 mb-1">
                          <div
                            class="d-flex flex-row align-items-center justify-content-between px-1 py-1"
                          >
                            <span class="fs-12 fw-300 text-start"
                              >Amex Qual Per Item</span
                            >
                            <span
                              class="fs-13 fw-400 first-value mwx-100 text-end"
                            >
                              {{ hasTieredAmexRate() | currencySymbol }}
                            </span>
                          </div>
                        </div>
                      </ng-container>
                    </ng-container>

                    <!-- Mid Qual -->
                    <ng-container
                      *ngIf="
                        pricingBannerData()?.tieredCoreFees
                          ?.tieredMidQualRatePerItem ||
                        pricingBannerData()?.tieredCoreFees
                          ?.tieredMidQualRatePercentage
                      "
                    >
                      <div class="offer_list w-100 mb-1">
                        <div
                          class="d-flex flex-row align-items-center justify-content-between px-1 py-1"
                        >
                          <span class="fs-12 fw-300 text-start"
                            >Mid-Qual Surcharge</span
                          >
                          <span
                            class="fs-13 fw-400 first-value mwx-100 text-end"
                          >
                            <ng-container
                              *ngIf="
                                pricingBannerData()?.tieredCoreFees
                                  ?.tieredMidQualRatePercentage
                              "
                              >{{
                                pricingBannerData()?.tieredCoreFees
                                  ?.tieredMidQualRatePercentage
                                  | roundWithDecimal
                              }}%</ng-container
                            >
                            <ng-container
                              *ngIf="
                                pricingBannerData()?.tieredCoreFees
                                  ?.tieredMidQualRatePerItem &&
                                pricingBannerData()?.tieredCoreFees
                                  ?.tieredMidQualRatePercentage
                              "
                            >
                              +
                            </ng-container>
                            <ng-container
                              *ngIf="
                                pricingBannerData()?.tieredCoreFees
                                  ?.tieredMidQualRatePerItem
                              "
                              >{{
                                pricingBannerData()?.tieredCoreFees
                                  ?.tieredMidQualRatePerItem | currencySymbol
                              }}</ng-container
                            >
                          </span>
                        </div>
                      </div>
                    </ng-container>

                    <!-- Non Qual -->
                    <ng-container
                      *ngIf="
                        pricingBannerData()?.tieredCoreFees
                          ?.tieredNonQualRatePercentage ||
                        pricingBannerData()?.tieredCoreFees
                          ?.tieredNonQualRatePerItem
                      "
                    >
                      <div class="offer_list w-100 mb-1">
                        <div
                          class="d-flex flex-row align-items-center justify-content-between px-1 py-1"
                        >
                          <span class="fs-12 fw-300 text-start"
                            >Non-Qual Surcharge</span
                          >
                          <span
                            class="fs-13 fw-400 first-value mwx-100 text-end"
                          >
                            <ng-container
                              *ngIf="
                                pricingBannerData()?.tieredCoreFees
                                  ?.tieredNonQualRatePercentage
                              "
                              >{{
                                pricingBannerData()?.tieredCoreFees
                                  ?.tieredNonQualRatePercentage
                                  | roundWithDecimal
                              }}%</ng-container
                            >
                            <ng-container
                              *ngIf="
                                pricingBannerData()?.tieredCoreFees
                                  ?.tieredNonQualRatePercentage &&
                                pricingBannerData()?.tieredCoreFees
                                  ?.tieredNonQualRatePerItem
                              "
                            >
                              +
                            </ng-container>
                            <ng-container
                              *ngIf="
                                pricingBannerData()?.tieredCoreFees
                                  ?.tieredNonQualRatePerItem
                              "
                              >{{
                                pricingBannerData()?.tieredCoreFees
                                  ?.tieredNonQualRatePerItem | currencySymbol
                              }}</ng-container
                            >
                          </span>
                        </div>
                      </div>
                    </ng-container>

                    <!-- Highlight per item is false then show auth -->
                    <ng-container *ngIf="!hasTieredHighlightAuthFee()">
                      <ng-container
                        [ngTemplateOutlet]="scrollListItem"
                        [ngTemplateOutletContext]="{
                          data: {
                            label: 'Auth Fee',
                            value:
                              (pricingBannerData()?.tieredCoreFees
                                ?.tieredAuthFee | currencySymbol),
                            isVisible:
                              hasTieredSameAuthFee() ||
                              pricingBannerData()?.tieredCoreFees?.tieredAuthFee
                          }
                        }"
                      ></ng-container>

                      <ng-container
                        [ngTemplateOutlet]="scrollListItem"
                        [ngTemplateOutletContext]="{
                          data: {
                            label: 'AMEX Auth Fee',
                            value:
                              (pricingBannerData()?.tieredCoreFees
                                ?.tieredAmexAuthFee | currencySymbol),
                            isVisible:
                              !hasTieredSameAuthFee() &&
                              pricingBannerData()?.tieredCoreFees
                                ?.tieredAmexAuthFee
                          }
                        }"
                      ></ng-container>
                    </ng-container>

                    <!-- DEBIT & PIN DEBIT AUTH FEE -->
                    <ng-container
                      [ngTemplateOutlet]="scrollListItem"
                      [ngTemplateOutletContext]="{
                        data: {
                          label: 'PIN Debit Auth Fee',
                          value:
                            (pricingBannerData()?.tieredCoreFees
                              ?.tieredPinDebitAuthFee | currencySymbol),
                          isVisible:
                            pricingBannerData()?.tieredCoreFees
                              ?.tieredPinDebitAuthFee
                        }
                      }"
                    ></ng-container>

                    <ng-container
                      [ngTemplateOutlet]="scrollListItem"
                      [ngTemplateOutletContext]="{
                        data: {
                          label: 'EBT Auth Fee',
                          value:
                            (pricingBannerData()?.tieredCoreFees
                              ?.tieredEbtAuthFee | currencySymbol),
                          isVisible:
                            pricingBannerData()?.tieredCoreFees
                              ?.tieredEbtAuthFee
                        }
                      }"
                    ></ng-container>
                  </ng-container>
                  <!-- Tiered Offer Ends -->

                  <!-- ACH Only Fee -->
                  <ng-container
                    *ngIf="
                      pricingBannerData()?.paymentMethodType !==
                        paymentMethodType.CARD_ONLY &&
                      pricingBannerData()?.achCoreFees
                    "
                  >
                    <ng-container *ngIf="hasAchCredits()">
                      <div class="offer_list w-100 mb-1">
                        <div
                          class="d-flex flex-row align-items-center justify-content-between px-1 py-1"
                        >
                          <span class="fs-12 fw-300 text-start"
                            >ACH Credits</span
                          >
                          <span class="fs-13 fw-400 first-value list-key-value">
                            <ng-container
                              *ngIf="
                                pricingBannerData()?.achCoreFees?.credits
                                  ?.achCreditDiscountFee
                              "
                              >{{
                                pricingBannerData()?.achCoreFees?.credits
                                  ?.achCreditDiscountFee | roundWithDecimal
                              }}%</ng-container
                            >
                            <ng-container
                              *ngIf="
                                pricingBannerData()?.achCoreFees?.credits
                                  ?.achCreditDiscountFee &&
                                pricingBannerData()?.achCoreFees?.credits
                                  ?.achCreditPerItemFee
                              "
                            >
                              +
                            </ng-container>
                            <ng-container
                              *ngIf="
                                pricingBannerData()?.achCoreFees?.credits
                                  ?.achCreditPerItemFee
                              "
                              >{{
                                pricingBannerData()?.achCoreFees?.credits
                                  ?.achCreditPerItemFee | currencySymbol
                              }}</ng-container
                            >
                            <br />
                            <span
                              *ngIf="
                                pricingBannerData()?.achCoreFees?.credits
                                  ?.achCreditMaxFeePerTransaction
                              "
                              class="fs-12"
                              style="color: #a6a6a6"
                            >
                              Capped at
                              {{
                                pricingBannerData()?.achCoreFees?.credits
                                  ?.achCreditMaxFeePerTransaction
                                  | currencySymbol
                              }}</span
                            >
                          </span>
                        </div>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="hasAchDebits()">
                      <div class="offer_list w-100 mb-1">
                        <div
                          class="d-flex flex-row align-items-center justify-content-between px-1 py-1"
                        >
                          <span class="fs-12 fw-300 text-start"
                            >ACH Debits</span
                          >
                          <span class="fs-13 fw-400 first-value list-key-value">
                            <ng-container
                              *ngIf="
                                pricingBannerData()?.achCoreFees?.debits
                                  ?.achDebitDiscountFee
                              "
                              >{{
                                pricingBannerData()?.achCoreFees?.debits
                                  ?.achDebitDiscountFee | roundWithDecimal
                              }}%</ng-container
                            >
                            <ng-container
                              *ngIf="
                                pricingBannerData()?.achCoreFees?.debits
                                  ?.achDebitDiscountFee &&
                                pricingBannerData()?.achCoreFees?.debits
                                  ?.achDebitPerItemFee
                              "
                            >
                              +
                            </ng-container>
                            <ng-container
                              *ngIf="
                                pricingBannerData()?.achCoreFees?.debits
                                  ?.achDebitPerItemFee
                              "
                              >{{
                                pricingBannerData()?.achCoreFees?.debits
                                  ?.achDebitPerItemFee | currencySymbol
                              }}</ng-container
                            >
                            <br />
                            <span
                              *ngIf="
                                pricingBannerData()?.achCoreFees?.debits
                                  ?.achDebitMaxFeePerTransaction
                              "
                              class="fs-12"
                              style="color: #a6a6a6"
                            >
                              Capped at
                              {{
                                pricingBannerData()?.achCoreFees?.debits
                                  ?.achDebitMaxFeePerTransaction
                                  | currencySymbol
                              }}</span
                            >
                          </span>
                        </div>
                      </div>
                    </ng-container>

                    <ng-container
                      [ngTemplateOutlet]="scrollListItem"
                      [ngTemplateOutletContext]="{
                        data: {
                          label: 'ACH Refunds',
                          value:
                            (pricingBannerData()?.achCoreFees?.debits
                              ?.achDebitRefundFee | currencySymbol),
                          isVisible:
                            pricingBannerData()?.achCoreFees?.debits
                              ?.achDebitRefundFee
                        }
                      }"
                    ></ng-container>
                  </ng-container>
                  <!-- ACH Only Fee Ends -->

                  <!-- Other Fees -->
                  <ng-container
                    *ngIf="
                      mapFieldToShow().length &&
                      pricingBannerData()?.otherAgentFees
                    "
                  >
                    <!-- Other Agent Fees -->
                    <ng-container
                      *ngFor="let key of mapFieldToShow()"
                      [ngTemplateOutlet]="scrollListItem"
                      [ngTemplateOutletContext]="{
                        data: {
                          label: key?.inputLabel,
                          value:
                            (pricingBannerData()?.otherAgentFees[key.value]
                            | currencySymbol),
                          isVisible:
                            pricingBannerData()?.otherAgentFees[
                              key.isVisible
                            ] && pricingBannerData()?.otherAgentFees[key.value]
                        }
                      }"
                    >
                    </ng-container>
                  </ng-container>
                  <!-- Other Agent End -->

                  <!-- Other Fee name -->
                  <ng-container
                    *ngIf="pricingBannerData()?.otherAgentFees?.showOtherFee"
                    [ngTemplateOutlet]="scrollListItem"
                    [ngTemplateOutletContext]="{
                      data: {
                        label:
                          pricingBannerData()?.otherAgentFees?.otherFeeName ||
                          'Other Fee',
                        value:
                          (pricingBannerData()?.otherAgentFees?.otherFee
                          | currencySymbol),
                        isVisible: pricingBannerData()?.otherAgentFees?.otherFee
                      }
                    }"
                  >
                  </ng-container>
                  <!-- Other Fee name Ends -->

                  <!-- Gateways Fees -->
                  <ng-container
                    *ngIf="
                      !hasNoGateway() && pricingBannerData()?.otherAgentFees
                    "
                  >
                    <ng-container
                      *ngFor="let key of gatewayFields()"
                      [ngTemplateOutlet]="scrollListItem"
                      [ngTemplateOutletContext]="{
                        data: {
                          label: key?.inputLabel,
                          value:
                            (pricingBannerData()?.otherAgentFees[key.value]
                            | currencySymbol),
                          isVisible:
                            pricingBannerData()?.otherAgentFees[
                              key.isVisible
                            ] && pricingBannerData()?.otherAgentFees[key.value]
                        }
                      }"
                    >
                    </ng-container>
                  </ng-container>
                  <!-- Gateways Fees End  -->

                  <!-- Mobile Fees -->
                  <ng-container
                    *ngIf="
                      !hasNoMobile() && pricingBannerData()?.otherAgentFees
                    "
                  >
                    <ng-container
                      *ngFor="let key of mobileFeesFields()"
                      [ngTemplateOutlet]="scrollListItem"
                      [ngTemplateOutletContext]="{
                        data: {
                          label: key?.inputLabel,
                          value:
                            (pricingBannerData()?.otherAgentFees[key.value]
                            | currencySymbol),
                          isVisible:
                            pricingBannerData()?.otherAgentFees[
                              key.isVisible
                            ] && pricingBannerData()?.otherAgentFees[key.value]
                        }
                      }"
                    >
                    </ng-container>
                  </ng-container>
                  <!-- Mobile Fees End -->

                  <!-- Wireless Fees -->
                  <ng-container
                    *ngIf="
                      !hasNoWireless() && pricingBannerData()?.otherAgentFees
                    "
                  >
                    <ng-container
                      *ngFor="let key of wirelessFeesFields()"
                      [ngTemplateOutlet]="scrollListItem"
                      [ngTemplateOutletContext]="{
                        data: {
                          label: key?.inputLabel,
                          value:
                            (pricingBannerData()?.otherAgentFees[key.value]
                            | currencySymbol),
                          isVisible:
                            pricingBannerData()?.otherAgentFees[
                              key.isVisible
                            ] && pricingBannerData()?.otherAgentFees[key.value]
                        }
                      }"
                    >
                    </ng-container>
                  </ng-container>
                  <!-- Wireless Fees End -->

                  <!-- ACH Agent Fees -->
                  <ng-container
                    *ngIf="
                      !hasNoAchAgentFees() &&
                      pricingBannerData()?.otherAgentFees
                    "
                  >
                    <ng-container
                      *ngFor="let key of achAgentFeeFields()"
                      [ngTemplateOutlet]="scrollListItem"
                      [ngTemplateOutletContext]="{
                        data: {
                          label: key?.inputLabel,
                          value:
                            (pricingBannerData()?.otherAgentFees[key.value]
                            | currencySymbol),
                          isVisible:
                            pricingBannerData()?.otherAgentFees[
                              key.isVisible
                            ] && pricingBannerData()?.otherAgentFees[key.value]
                        }
                      }"
                    >
                    </ng-container>
                  </ng-container>
                  <!-- ACH Agent Fees End -->

                  <!-- Other Standard Fee -->
                  <ng-container
                    *ngIf="
                      mapOtherStandardFeeKeys().length &&
                      pricingBannerData()?.otherStandardFees
                    "
                  >
                    <ng-container
                      *ngFor="let key of mapOtherStandardFeeKeys()"
                      [ngTemplateOutlet]="scrollListItem"
                      [ngTemplateOutletContext]="{
                        data: {
                          label: key?.inputLabel,
                          value:
                            (pricingBannerData()?.otherStandardFees[key.value]
                            | currencySymbol),
                          isVisible:
                            pricingBannerData()?.otherStandardFees[
                              key.isVisible
                            ] &&
                            pricingBannerData()?.otherStandardFees[key.value]
                        }
                      }"
                    >
                    </ng-container>
                  </ng-container>
                  <!-- Other Standard Fee -->
                </div>

                <ng-container
                  *ngIf="
                    totalproductCost?.totalTerminalItems ||
                    totalproductCost?.totalAccessoriesItems
                  "
                >
                  <terminal-calculations
                    style="width: 100%"
                    [totalproductCost]="totalproductCost"
                  ></terminal-calculations>
                </ng-container>
              </div>
              <!-- Other Fees Wrapper End -->

              <div class="ss">
                <img src="assets/images/single-rocket.png" />
              </div>
            </div>
            <div class="col-12 text-center" style="z-index: 1">
              <div slot="modal-actions" class="d-flex flex-row m-36 pull-right">
                <gtr-button
                  color="primary"
                  variant="raised"
                  type="submit"
                  (click)="closeModal()"
                  [dataTransactionName]="
                    dataTransactionName + '--offer-banner--close'
                  "
                  [attr.data-transaction-name]="
                    dataTransactionName + '--offer-banner--close'
                  "
                  >Close
                </gtr-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Offer banner -->
<ng-template #offerBanner>
  <div
    id="sd-ofr"
    class="sidebar-offer font-roboto"
    [ngStyle]="{
      top: isActive ? '62px' : '0px',
      'z-index': isActive ? '' : '99999'
    }"
  >
    <div class="offer_image_web text-center">
      <div
        class="row offer_content_web text-white pt-1 m-0 btn-primary"
        [class.show-offer-img]="brandingData && brandingData.showSidebarImage"
        [class.show-full-hight]="isOnboardingPage"
      >
        <div class="col-12 text-center" *ngIf="pricingBannerData()">
          <section class="top-header">
            <h3 class="text-white fw-600 mb-0 mt-3">
              {{ pricingBannerData()?.tagLine }}
            </h3>
            <p class="fw-300 fs-14"></p>

            <!-- Ach only header -->
            <ng-container
              *ngIf="
                pricingBannerData()?.paymentMethodType ===
                paymentMethodType.ACH_ONLY
              "
            >
              <offer-banner-ach-core-fees
                [achCoreFees]="pricingBannerData()?.achCoreFees"
              />
            </ng-container>

            <!-- Flat Rate Header -->
            <ng-container
              *ngIf="
                pricingBannerData()?.pricingType ===
                  offerPricingType.FLAT_RATE &&
                pricingBannerData()?.flatRateCoreFees
              "
            >
              <offer-banner-flat-rate
                [flatRateCoreFees]="pricingBannerData()?.flatRateCoreFees"
              ></offer-banner-flat-rate>
            </ng-container>
            <!-- Flat Rate Header Ends -->

            <!-- Cash Discount Header -->
            <ng-container
              *ngIf="
                pricingBannerData()?.pricingType ===
                  offerPricingType.CASH_DISCOUNT &&
                pricingBannerData()?.cashDiscountCoreFees
              "
            >
              <offer-banner-cash-discount
                [cashDiscountCoreFees]="
                  pricingBannerData()?.cashDiscountCoreFees
                "
              ></offer-banner-cash-discount>
            </ng-container>
            <!-- Cash Discount Header Ends -->

            <!-- Tiered -->
            <ng-container
              *ngIf="
                pricingBannerData()?.pricingType === offerPricingType.TIERED &&
                pricingBannerData()?.tieredCoreFees
              "
            >
              <offer-banner-tiered
                [tieredCoreFees]="pricingBannerData()?.tieredCoreFees"
              ></offer-banner-tiered>
            </ng-container>
            <!-- Tiered Ends -->

            <!-- Interchange Header -->
            <ng-container
              *ngIf="
                pricingBannerData()?.pricingType ===
                  offerPricingType.INTERCHANGE_PLUS &&
                pricingBannerData()?.interchangePlusCoreFees
              "
            >
              <offer-banner-interchange-plus
                [interchangePlusCoreFees]="
                  pricingBannerData()?.interchangePlusCoreFees
                "
              ></offer-banner-interchange-plus>
            </ng-container>
            <!-- Interchange Header Ends -->
          </section>

          <!-- Other Fees Wrapper -->
          <div
            class="d-flex flex-column justify-content-center align-items-center sidev2 key-list"
          >
            <div
              class="offers-outer-wrapper custom-scroll overflow-auto mxh-405"
              [style.width]="'100%'"
              [style.max-height]="
                listHeight ? listHeight + 'px !important' : '400px !important'
              "
              [style.min-height]="
                listHeight && listHeight < 185 && listHeight + 'px !important'
              "
            >
              <!-- Cash Discount Program Rate -->
              <ng-container
                *ngIf="
                  pricingBannerData()?.pricingType ===
                    offerPricingType.CASH_DISCOUNT &&
                  pricingBannerData()?.cashDiscountCoreFees &&
                  pricingBannerData()?.cashDiscountCoreFees
                    ?.cashDiscountProgramRatePercentage
                "
                [ngTemplateOutlet]="scrollListItem"
                [ngTemplateOutletContext]="{
                  data: {
                    label: 'Program Rate *',
                    value:
                      (pricingBannerData()?.cashDiscountCoreFees
                        ?.cashDiscountProgramRatePercentage
                        | roundWithDecimal) + '%',
                    isVisible: true
                  }
                }"
              >
              </ng-container>
              <!-- Cash Discount Program Rate Ends -->

              <!-- Interchange plus -->
              <ng-container
                *ngIf="
                  pricingBannerData()?.pricingType ===
                    offerPricingType.INTERCHANGE_PLUS &&
                  pricingBannerData()?.interchangePlusCoreFees
                "
              >
                <!-- Not Highlight per item -->
                <ng-container
                  *ngIf="
                    !hasInterchangeHighlightPerItem() &&
                    pricingBannerData()?.interchangePlusCoreFees?.intPlusPerItem
                  "
                  [ngTemplateOutlet]="scrollListItem"
                  [ngTemplateOutletContext]="{
                    data: {
                      label: 'Per Item',
                      value:
                        (pricingBannerData()?.interchangePlusCoreFees
                          ?.intPlusPerItem | currencySymbol),
                      isVisible: true
                    }
                  }"
                ></ng-container>

                <!-- Highlight per item and show auth -->
                <ng-container *ngIf="hasInterchangeHighlightPerItem()">
                  <ng-container
                    *ngIf="
                      pricingBannerData()?.interchangePlusCoreFees
                        ?.intPlusAuthFee && !hasInterchangeSameAuthFee()
                    "
                    [ngTemplateOutlet]="scrollListItem"
                    [ngTemplateOutletContext]="{
                      data: {
                        label: 'Auth Fee',
                        value:
                          (pricingBannerData()?.interchangePlusCoreFees
                            ?.intPlusAuthFee | currencySymbol),
                        isVisible: true
                      }
                    }"
                  ></ng-container>

                  <ng-container
                    *ngIf="
                      pricingBannerData()?.interchangePlusCoreFees
                        ?.intPlusAmexAuthFee && !hasInterchangeSameAuthFee()
                    "
                    [ngTemplateOutlet]="scrollListItem"
                    [ngTemplateOutletContext]="{
                      data: {
                        label: 'AMEX Auth Fee',
                        value:
                          (pricingBannerData()?.interchangePlusCoreFees
                            ?.intPlusAmexAuthFee | currencySymbol),
                        isVisible: true
                      }
                    }"
                  ></ng-container>

                  <ng-container
                    *ngIf="
                      hasInterchangeSameAuthFee() &&
                      pricingBannerData()?.interchangePlusCoreFees
                        ?.intPlusAuthFee
                    "
                    [ngTemplateOutlet]="scrollListItem"
                    [ngTemplateOutletContext]="{
                      data: {
                        label: 'Auth Fee',
                        value:
                          (pricingBannerData()?.interchangePlusCoreFees
                            ?.intPlusAuthFee | currencySymbol),
                        isVisible: true
                      }
                    }"
                  ></ng-container>
                </ng-container>

                <!-- DEBIT & PIN DEBIT AUTH FEE -->
                <ng-container
                  *ngIf="
                    hasSameInterchangeAuthFee() &&
                    pricingBannerData()?.interchangePlusCoreFees
                      ?.intPlusPinDebitAuthFee
                  "
                  [ngTemplateOutlet]="scrollListItem"
                  [ngTemplateOutletContext]="{
                    data: {
                      label: 'Debit/EBT Auth Fee',
                      value:
                        (pricingBannerData()?.interchangePlusCoreFees
                          ?.intPlusPinDebitAuthFee | currencySymbol),
                      isVisible: true
                    }
                  }"
                ></ng-container>

                <ng-container
                  *ngIf="
                    !hasSameInterchangeAuthFee() &&
                    pricingBannerData()?.interchangePlusCoreFees
                      ?.intPlusPinDebitAuthFee
                  "
                  [ngTemplateOutlet]="scrollListItem"
                  [ngTemplateOutletContext]="{
                    data: {
                      label: 'PIN Debit Auth Fee',
                      value:
                        (pricingBannerData()?.interchangePlusCoreFees
                          ?.intPlusPinDebitAuthFee | currencySymbol),
                      isVisible: true
                    }
                  }"
                ></ng-container>

                <ng-container
                  *ngIf="
                    !hasSameInterchangeAuthFee() &&
                    pricingBannerData()?.interchangePlusCoreFees
                      ?.intPlusEbtAuthFee
                  "
                  [ngTemplateOutlet]="scrollListItem"
                  [ngTemplateOutletContext]="{
                    data: {
                      label: 'EBT Auth Fee',
                      value:
                        (pricingBannerData()?.interchangePlusCoreFees
                          ?.intPlusEbtAuthFee | currencySymbol),
                      isVisible: true
                    }
                  }"
                ></ng-container>
              </ng-container>
              <!-- Interchange plus Ends -->

              <!-- Tiered Offer Start -->
              <ng-container
                *ngIf="
                  pricingBannerData()?.pricingType ===
                    offerPricingType.TIERED &&
                  pricingBannerData()?.tieredCoreFees
                "
              >
                <!-- Highlight auth fee -->
                <ng-container *ngIf="hasTieredHighlightAuthFee()">
                  <!-- Has same qual rate -->
                  <ng-container
                    *ngIf="
                      (hasTieredSameQualPerItem() ||
                        hasSameSignatureDebit() ||
                        hasSameAmex()) &&
                      hasTieredQualRate()
                    "
                  >
                    <div class="offer_list w-100 mb-1">
                      <div
                        class="d-flex flex-row align-items-center justify-content-between px-1 py-1"
                      >
                        <span class="fs-12 fw-300 text-start"
                          >Qual Per Item</span
                        >
                        <span
                          class="fs-13 fw-400 first-value list-key-value mwx-90"
                        >
                          {{ hasTieredQualRate() | currencySymbol }}
                        </span>
                      </div>
                    </div>
                  </ng-container>

                  <!-- Debit Signature -->
                  <ng-container
                    *ngIf="
                      !hasTieredSameQualPerItem() &&
                      !hasSameSignatureDebit() &&
                      hasTieredSignatureDebitRate()
                    "
                  >
                    <div class="offer_list w-100 mb-1">
                      <div
                        class="d-flex flex-row align-items-center justify-content-between px-1 py-1"
                      >
                        <span class="fs-12 fw-300 text-start"
                          >Signature Debit Qual Per Item</span
                        >
                        <span
                          class="fs-13 fw-400 first-value list-key-value mwx-90"
                        >
                          {{ hasTieredSignatureDebitRate() | currencySymbol }}
                        </span>
                      </div>
                    </div>
                  </ng-container>

                  <!-- VI/MC/DS Qual Per Item -->
                  <ng-container
                    *ngIf="
                      !hasTieredSameQualPerItem() &&
                      !hasSameAmex() &&
                      !hasSameSignatureDebit() &&
                      hasTieredQualRate()
                    "
                  >
                    <div class="offer_list w-100 mb-1">
                      <div
                        class="d-flex flex-row align-items-center justify-content-between px-1 py-1"
                      >
                        <span class="fs-12 fw-300 text-start"
                          >VI/MC/DS Qual Per Item</span
                        >
                        <span
                          class="fs-13 fw-400 first-value list-key-value mwx-90"
                        >
                          {{ hasTieredQualRate() | currencySymbol }}
                        </span>
                      </div>
                    </div>
                  </ng-container>

                  <!-- Amex Rate -->
                  <ng-container
                    *ngIf="
                      !hasTieredSameQualPerItem() &&
                      !hasSameAmex() &&
                      hasTieredAmexRate()
                    "
                  >
                    <div class="offer_list w-100 mb-1">
                      <div
                        class="d-flex flex-row align-items-center justify-content-between px-1 py-1"
                      >
                        <span class="fs-12 fw-300 text-start"
                          >Amex Qual Per Item</span
                        >
                        <span
                          class="fs-13 fw-400 first-value list-key-value mwx-90"
                        >
                          {{ hasTieredAmexRate() | currencySymbol }}
                        </span>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>

                <!-- Mid Qual -->
                <ng-container
                  *ngIf="
                    pricingBannerData()?.tieredCoreFees
                      ?.tieredMidQualRatePerItem ||
                    pricingBannerData()?.tieredCoreFees
                      ?.tieredMidQualRatePercentage
                  "
                >
                  <div class="offer_list w-100 mb-1">
                    <div
                      class="d-flex flex-row align-items-center justify-content-between px-1 py-1"
                    >
                      <span class="fs-12 fw-300 text-start"
                        >Mid-Qual Surcharge</span
                      >
                      <span
                        class="fs-13 fw-400 first-value list-key-value mwx-90"
                      >
                        <ng-container
                          *ngIf="
                            pricingBannerData()?.tieredCoreFees
                              ?.tieredMidQualRatePercentage
                          "
                          >{{
                            pricingBannerData()?.tieredCoreFees
                              ?.tieredMidQualRatePercentage | roundWithDecimal
                          }}%</ng-container
                        >
                        <ng-container
                          *ngIf="
                            pricingBannerData()?.tieredCoreFees
                              ?.tieredMidQualRatePerItem &&
                            pricingBannerData()?.tieredCoreFees
                              ?.tieredMidQualRatePercentage
                          "
                        >
                          +
                        </ng-container>
                        <ng-container
                          *ngIf="
                            pricingBannerData()?.tieredCoreFees
                              ?.tieredMidQualRatePerItem
                          "
                          >{{
                            pricingBannerData()?.tieredCoreFees
                              ?.tieredMidQualRatePerItem | currencySymbol
                          }}</ng-container
                        >
                      </span>
                    </div>
                  </div>
                </ng-container>

                <!-- Non Qual -->
                <ng-container
                  *ngIf="
                    pricingBannerData()?.tieredCoreFees
                      ?.tieredNonQualRatePercentage ||
                    pricingBannerData()?.tieredCoreFees
                      ?.tieredNonQualRatePerItem
                  "
                >
                  <div class="offer_list w-100 mb-1">
                    <div
                      class="d-flex flex-row align-items-center justify-content-between px-1 py-1"
                    >
                      <span class="fs-12 fw-300 text-start"
                        >Non-Qual Surcharge</span
                      >
                      <span
                        class="fs-13 fw-400 first-value list-key-value mwx-90"
                      >
                        <ng-container
                          *ngIf="
                            pricingBannerData()?.tieredCoreFees
                              ?.tieredNonQualRatePercentage
                          "
                          >{{
                            pricingBannerData()?.tieredCoreFees
                              ?.tieredNonQualRatePercentage | roundWithDecimal
                          }}%</ng-container
                        >
                        <ng-container
                          *ngIf="
                            pricingBannerData()?.tieredCoreFees
                              ?.tieredNonQualRatePercentage &&
                            pricingBannerData()?.tieredCoreFees
                              ?.tieredNonQualRatePerItem
                          "
                        >
                          +
                        </ng-container>
                        <ng-container
                          *ngIf="
                            pricingBannerData()?.tieredCoreFees
                              ?.tieredNonQualRatePerItem
                          "
                          >{{
                            pricingBannerData()?.tieredCoreFees
                              ?.tieredNonQualRatePerItem | currencySymbol
                          }}</ng-container
                        >
                      </span>
                    </div>
                  </div>
                </ng-container>

                <!-- Highlight per item is false then show auth -->
                <ng-container *ngIf="!hasTieredHighlightAuthFee()">
                  <ng-container
                    [ngTemplateOutlet]="scrollListItem"
                    [ngTemplateOutletContext]="{
                      data: {
                        label: 'Auth Fee',
                        value:
                          (pricingBannerData()?.tieredCoreFees?.tieredAuthFee
                          | currencySymbol),
                        isVisible:
                          hasTieredSameAuthFee() ||
                          pricingBannerData()?.tieredCoreFees?.tieredAuthFee
                      }
                    }"
                  ></ng-container>

                  <ng-container
                    [ngTemplateOutlet]="scrollListItem"
                    [ngTemplateOutletContext]="{
                      data: {
                        label: 'AMEX Auth Fee',
                        value:
                          (pricingBannerData()?.tieredCoreFees
                            ?.tieredAmexAuthFee | currencySymbol),
                        isVisible:
                          !hasTieredSameAuthFee() &&
                          pricingBannerData()?.tieredCoreFees?.tieredAmexAuthFee
                      }
                    }"
                  ></ng-container>
                </ng-container>

                <!-- DEBIT & PIN DEBIT AUTH FEE -->
                <ng-container
                  [ngTemplateOutlet]="scrollListItem"
                  [ngTemplateOutletContext]="{
                    data: {
                      label: 'PIN Debit Auth Fee',
                      value:
                        (pricingBannerData()?.tieredCoreFees
                          ?.tieredPinDebitAuthFee | currencySymbol),
                      isVisible:
                        pricingBannerData()?.tieredCoreFees
                          ?.tieredPinDebitAuthFee
                    }
                  }"
                ></ng-container>

                <ng-container
                  [ngTemplateOutlet]="scrollListItem"
                  [ngTemplateOutletContext]="{
                    data: {
                      label: 'EBT Auth Fee',
                      value:
                        (pricingBannerData()?.tieredCoreFees?.tieredEbtAuthFee
                        | currencySymbol),
                      isVisible:
                        pricingBannerData()?.tieredCoreFees?.tieredEbtAuthFee
                    }
                  }"
                ></ng-container>
              </ng-container>
              <!-- Tiered Offer Ends -->

              <!-- ACH Only Fee -->
              <ng-container
                *ngIf="
                  pricingBannerData()?.paymentMethodType !==
                    paymentMethodType.CARD_ONLY &&
                  pricingBannerData()?.achCoreFees
                "
              >
                <ng-container *ngIf="hasAchCredits()">
                  <div class="offer_list w-100 mb-1">
                    <div
                      class="d-flex flex-row align-items-center justify-content-between px-1 py-1"
                    >
                      <span class="fs-12 fw-300 text-start">ACH Credits</span>
                      <span class="fs-13 fw-400 first-value list-key-value">
                        <ng-container
                          *ngIf="
                            pricingBannerData()?.achCoreFees?.credits
                              ?.achCreditDiscountFee
                          "
                          >{{
                            pricingBannerData()?.achCoreFees?.credits
                              ?.achCreditDiscountFee | roundWithDecimal
                          }}%</ng-container
                        >
                        <ng-container
                          *ngIf="
                            pricingBannerData()?.achCoreFees?.credits
                              ?.achCreditDiscountFee &&
                            pricingBannerData()?.achCoreFees?.credits
                              ?.achCreditPerItemFee
                          "
                        >
                          +
                        </ng-container>
                        <ng-container
                          *ngIf="
                            pricingBannerData()?.achCoreFees?.credits
                              ?.achCreditPerItemFee
                          "
                          >{{
                            pricingBannerData()?.achCoreFees?.credits
                              ?.achCreditPerItemFee | currencySymbol
                          }}</ng-container
                        >
                        <br />
                        <span
                          *ngIf="
                            pricingBannerData()?.achCoreFees?.credits
                              ?.achCreditMaxFeePerTransaction
                          "
                          class="fs-12"
                          style="color: #a6a6a6"
                        >
                          Capped at
                          {{
                            pricingBannerData()?.achCoreFees?.credits
                              ?.achCreditMaxFeePerTransaction | currencySymbol
                          }}</span
                        >
                      </span>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="hasAchDebits()">
                  <div class="offer_list w-100 mb-1">
                    <div
                      class="d-flex flex-row align-items-center justify-content-between px-1 py-1"
                    >
                      <span class="fs-12 fw-300 text-start">ACH Debits</span>
                      <span class="fs-13 fw-400 first-value list-key-value">
                        <ng-container
                          *ngIf="
                            pricingBannerData()?.achCoreFees?.debits
                              ?.achDebitDiscountFee
                          "
                          >{{
                            pricingBannerData()?.achCoreFees?.debits
                              ?.achDebitDiscountFee | roundWithDecimal
                          }}%</ng-container
                        >
                        <ng-container
                          *ngIf="
                            pricingBannerData()?.achCoreFees?.debits
                              ?.achDebitDiscountFee &&
                            pricingBannerData()?.achCoreFees?.debits
                              ?.achDebitPerItemFee
                          "
                        >
                          +
                        </ng-container>
                        <ng-container
                          *ngIf="
                            pricingBannerData()?.achCoreFees?.debits
                              ?.achDebitPerItemFee
                          "
                          >{{
                            pricingBannerData()?.achCoreFees?.debits
                              ?.achDebitPerItemFee | currencySymbol
                          }}</ng-container
                        >
                        <br />
                        <span
                          *ngIf="
                            pricingBannerData()?.achCoreFees?.debits
                              ?.achDebitMaxFeePerTransaction
                          "
                          class="fs-12"
                          style="color: #a6a6a6"
                        >
                          Capped at
                          {{
                            pricingBannerData()?.achCoreFees?.debits
                              ?.achDebitMaxFeePerTransaction | currencySymbol
                          }}</span
                        >
                      </span>
                    </div>
                  </div>
                </ng-container>

                <ng-container
                  [ngTemplateOutlet]="scrollListItem"
                  [ngTemplateOutletContext]="{
                    data: {
                      label: 'ACH Refunds',
                      value:
                        (pricingBannerData()?.achCoreFees?.debits
                          ?.achDebitRefundFee | currencySymbol),
                      isVisible:
                        pricingBannerData()?.achCoreFees?.debits
                          ?.achDebitRefundFee
                    }
                  }"
                ></ng-container>
              </ng-container>
              <!-- ACH Only Fee Ends -->

              <!-- Other Fees -->
              <ng-container
                *ngIf="
                  mapFieldToShow().length && pricingBannerData()?.otherAgentFees
                "
              >
                <!-- Other Agent Fees -->
                <ng-container
                  *ngFor="let key of mapFieldToShow()"
                  [ngTemplateOutlet]="scrollListItem"
                  [ngTemplateOutletContext]="{
                    data: {
                      label: key?.inputLabel,
                      value:
                        (pricingBannerData()?.otherAgentFees[key.value]
                        | currencySymbol),
                      isVisible:
                        pricingBannerData()?.otherAgentFees[key.isVisible] &&
                        pricingBannerData()?.otherAgentFees[key.value]
                    }
                  }"
                >
                </ng-container>
              </ng-container>
              <!-- Other Agent End -->

              <!-- Other Fee name -->
              <ng-container
                *ngIf="pricingBannerData()?.otherAgentFees?.showOtherFee"
                [ngTemplateOutlet]="scrollListItem"
                [ngTemplateOutletContext]="{
                  data: {
                    label:
                      pricingBannerData()?.otherAgentFees?.otherFeeName ||
                      'Other Fee',
                    value:
                      (pricingBannerData()?.otherAgentFees?.otherFee
                      | currencySymbol),
                    isVisible: pricingBannerData()?.otherAgentFees?.otherFee
                  }
                }"
              >
              </ng-container>
              <!-- Other Fee name Ends -->

              <!-- Gateways Fees -->
              <ng-container
                *ngIf="!hasNoGateway() && pricingBannerData()?.otherAgentFees"
              >
                <ng-container
                  *ngFor="let key of gatewayFields()"
                  [ngTemplateOutlet]="scrollListItem"
                  [ngTemplateOutletContext]="{
                    data: {
                      label: key?.inputLabel,
                      value:
                        (pricingBannerData()?.otherAgentFees[key.value]
                        | currencySymbol),
                      isVisible:
                        pricingBannerData()?.otherAgentFees[key.isVisible] &&
                        pricingBannerData()?.otherAgentFees[key.value]
                    }
                  }"
                >
                </ng-container>
              </ng-container>
              <!-- Gateways Fees End  -->

              <!-- Mobile Fees -->
              <ng-container
                *ngIf="!hasNoMobile() && pricingBannerData()?.otherAgentFees"
              >
                <ng-container
                  *ngFor="let key of mobileFeesFields()"
                  [ngTemplateOutlet]="scrollListItem"
                  [ngTemplateOutletContext]="{
                    data: {
                      label: key?.inputLabel,
                      value:
                        (pricingBannerData()?.otherAgentFees[key.value]
                        | currencySymbol),
                      isVisible:
                        pricingBannerData()?.otherAgentFees[key.isVisible] &&
                        pricingBannerData()?.otherAgentFees[key.value]
                    }
                  }"
                >
                </ng-container>
              </ng-container>
              <!-- Mobile Fees End -->

              <!-- Wireless Fees -->
              <ng-container
                *ngIf="!hasNoWireless() && pricingBannerData()?.otherAgentFees"
              >
                <ng-container
                  *ngFor="let key of wirelessFeesFields()"
                  [ngTemplateOutlet]="scrollListItem"
                  [ngTemplateOutletContext]="{
                    data: {
                      label: key?.inputLabel,
                      value:
                        (pricingBannerData()?.otherAgentFees[key.value]
                        | currencySymbol),
                      isVisible:
                        pricingBannerData()?.otherAgentFees[key.isVisible] &&
                        pricingBannerData()?.otherAgentFees[key.value]
                    }
                  }"
                >
                </ng-container>
              </ng-container>
              <!-- Wireless Fees End -->

              <!-- ACH Agent Fees -->
              <ng-container
                *ngIf="
                  !hasNoAchAgentFees() && pricingBannerData()?.otherAgentFees
                "
              >
                <ng-container
                  *ngFor="let key of achAgentFeeFields()"
                  [ngTemplateOutlet]="scrollListItem"
                  [ngTemplateOutletContext]="{
                    data: {
                      label: key?.inputLabel,
                      value:
                        (pricingBannerData()?.otherAgentFees[key.value]
                        | currencySymbol),
                      isVisible:
                        pricingBannerData()?.otherAgentFees[key.isVisible] &&
                        pricingBannerData()?.otherAgentFees[key.value]
                    }
                  }"
                >
                </ng-container>
              </ng-container>
              <!-- ACH Agent Fees End -->

              <!-- Other Standard Fee -->
              <ng-container
                *ngIf="
                  mapOtherStandardFeeKeys().length &&
                  pricingBannerData()?.otherStandardFees
                "
              >
                <ng-container
                  *ngFor="let key of mapOtherStandardFeeKeys()"
                  [ngTemplateOutlet]="scrollListItem"
                  [ngTemplateOutletContext]="{
                    data: {
                      label: key?.inputLabel,
                      value:
                        (pricingBannerData()?.otherStandardFees[key.value]
                        | currencySymbol),
                      isVisible:
                        pricingBannerData()?.otherStandardFees[key.isVisible] &&
                        pricingBannerData()?.otherStandardFees[key.value]
                    }
                  }"
                >
                </ng-container>
              </ng-container>
              <!-- Other Standard Fee -->
            </div>

            <ng-container
              *ngIf="
                totalproductCost?.totalTerminalItems ||
                totalproductCost?.totalAccessoriesItems
              "
            >
              <terminal-calculations
                style="width: 100%"
                [totalproductCost]="totalproductCost"
              ></terminal-calculations>
            </ng-container>
          </div>
          <!-- Other Fees Wrapper End -->

          <div class="ss">
            <img src="assets/images/single-rocket.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
