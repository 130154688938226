import {
  CDateFilterListValue,
  EDateFilter,
} from '@parameters/filter/date.parameter';
import {
  CReportsAuthOperationTypeName,
  CReportsAuthStatusName,
  EReportsAuthOperationType,
  EReportsAuthStatus,
} from '@parameters/reports/auth/auth.parameter';

export const enum EReportsAuthListParams {
  size = 'limit',
  page = 'page',
  sort = 'order_by',
  search = 'search',
  startDate = 'start_date',
  endDate = 'end_date',
  midList = 'mids',
  binList = 'bins',
  status = 'status',
  cardBrandList = 'card_brands',
  operationTypeList = 'operation_types',
}

export enum CARD_TYPE {
  VISA = 'visa',
  MASTER_CARD = 'mastercard',
  DISCOVER = 'discover',
  JCB = 'jcb',
  AMEX = 'amex',
  PIN_DEBIT = 'pin_debit',
  EBT = 'ebt',
  OTHER = 'other',
  UNKNOWN = 'unknown',
}

export const CardIcons = {
  [CARD_TYPE.VISA]: 'assets/images/visa.svg',
  [CARD_TYPE.MASTER_CARD]: 'assets/images/mastercard.svg',
  [CARD_TYPE.DISCOVER]: 'assets/images/disc.svg',
  [CARD_TYPE.JCB]: 'assets/images/jcb.svg',
  [CARD_TYPE.AMEX]: 'assets/images/amex.png',
  [CARD_TYPE.PIN_DEBIT]: 'assets/images/pin.svg',
  [CARD_TYPE.EBT]: 'assets/images/EBT.svg',
  [CARD_TYPE.OTHER]: 'assets/images/question.svg',
  [CARD_TYPE.UNKNOWN]: 'assets/images/question.svg',
};

export const CReportsAuthListParamsDate =
  CDateFilterListValue[EDateFilter.last30Days];

export const CReportsAuthStatusList = [
  {
    name: CReportsAuthStatusName[EReportsAuthStatus.approved],
    key: EReportsAuthStatus.approved,
  },
  {
    name: CReportsAuthStatusName[EReportsAuthStatus.declined],
    key: EReportsAuthStatus.declined,
  },
  {
    name: CReportsAuthStatusName[EReportsAuthStatus.error],
    key: EReportsAuthStatus.error,
  },
];

export const CReportsAuthListParamsStatus = [
  EReportsAuthStatus.approved,
  EReportsAuthStatus.declined,
];

export const CReportsAuthOperationTypeList = [
  {
    name: CReportsAuthOperationTypeName[EReportsAuthOperationType.credit],
    key: EReportsAuthOperationType.credit,
  },
  {
    name: CReportsAuthOperationTypeName[EReportsAuthOperationType.debit],
    key: EReportsAuthOperationType.debit,
  },
];

export const enum EReportsAuthCSVParams {
  search = 'search',
  startDate = 'startDate',
  endDate = 'endDate',
  midList = 'mids',
  binList = 'bins',
  status = 'status',
  cardBrandList = 'cardBrands',
  operationTypeList = 'operationTypes',
}
