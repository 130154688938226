import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { SIDENAV_MODE } from '../sidebar.interface';
import { environment } from '@environments/environment';
import { SidebarItemsService } from 'src/app/shared/services/sidebar-items.service';
import { IBrandingResponse } from '@interfaces/branding/branding.interface';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnChanges, OnInit {
  /**
   * Branding Data
   */
  public brandingData: IBrandingResponse;

  /**
   * Image url
   */
  public readonly imageUrl: string = `${environment.imgFileBaseUrl}/`;

  /* Input property for manage mode of sidebar */
  @Input() public mode: SIDENAV_MODE = 'push';

  /* Input property for manage opening state of sidebar */
  @Input() public opened: boolean = true;

  /* Event emitter if something changed in opened state of sidenav */
  @Output() public toggleSidenav: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  /* Event emitter if something changed in mode state of sidenav */
  @Output() public toggleMode: EventEmitter<SIDENAV_MODE> =
    new EventEmitter<SIDENAV_MODE>();

  /**
   * Constructor
   * @param sidebarItemService
   */
  constructor(private readonly sidebarItemService: SidebarItemsService) {}

  /**
   * On Changes
   */
  ngOnChanges(): void {
    this.handleToggleEvent();
    this.handleModeEvent();
  }

  /**
   * Oninit
   */
  ngOnInit(): void {
    this.brandingData = this.sidebarItemService.brandingData || null;
  }

  /* function to hide sidenav */
  public hideSidenav() {
    this.opened = false;
    this.handleToggleEvent();
  }

  /* private function for handle toggle events for opening state */
  private handleToggleEvent() {
    this.toggleSidenav.emit(this.opened);
  }

  /* private function for handle toggle events for mode state */
  private handleModeEvent() {
    this.toggleMode.emit(this.mode);
  }
}
