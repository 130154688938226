import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[formControlName][appFaxNumberMask]',
})
export class FaxNumberMaskDirective {
  @Input() ssnType: string;
  constructor(public ngControl: NgControl) {}

  @HostListener('ngModelChange', ['$event'])
  public onModelChange(event) {
    if (event) {
      this.onInputChange(event, false);
    }
  }

  @HostListener('keydown.backspace', ['$event'])
  public keydownBackspace(event) {
    if (event) {
      this.onInputChange(event.target.value, true);
    }
  }

  onInputChange(event, backspace) {
    let newVal = event.replace(/\D/g, '');

    if (this.ssnType !== 'ssn') {
      if (backspace && newVal.length <= 3) {
        newVal = newVal.substring(0, newVal.length - 1);
      }
      if (newVal.length === 0) {
        newVal = '';
      } else if (newVal.length <= 3) {
        newVal = newVal.replace(/^(\d{0,3})/, '($1)');
      } else if (newVal.length <= 6) {
        newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1)-$2');
      } else if (newVal.length >= 10) {
        newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1)-$2-$3');
      } else {
        newVal = newVal.substring(0, 10);
        newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1)-$2-$3');
      }
    } else {
      if (backspace && newVal.length <= 3) {
        newVal = newVal.substring(0, newVal.length - 1);
      }
      if (newVal.length === 0) {
        newVal = '';
      } else if (newVal.length === 2) {
        newVal = newVal.replace(/^(\d{0,3})/, '$1-');
      } else {
        newVal = newVal.substring(0, 9);
        newVal = newVal.replace(/^(\d{0,2})(\d{0,7})/, '$1-$2');
      }
    }
    this.ngControl.valueAccessor.writeValue(newVal || '');
  }
}
