import {
  CDateFilterListValue,
  EDateFilter,
} from '@parameters/filter/date.parameter';

export const enum EDashboardChartParams {
  startDate = 'start_date',
  endDate = 'end_date',
  midList = 'mids',
  binList = 'bins',
  cardBrandList = 'card_brands',
  relationshipList = 'relationship_ids',
  groupBy = 'group_by',
  volumeAs = 'volume_as',
}

export const CDashboardChartParamsGroupBy = EDashboardChartGroupBy.day;
export const CDashboardChartParamsDate =
  CDateFilterListValue[EDateFilter.last30Days];

export const enum EDashboardChartGroupBy {
  day = 'day',
  week = 'week',
  month = 'month',
  year = 'year',
}

export const enum EDashboardChartGroupByLabel {
  day = 'Day',
  week = 'Week',
  month = 'Month',
  year = 'Year',
}

export const CDashboardChartGroupByValues = [
  { label: EDashboardChartGroupByLabel.day, value: EDashboardChartGroupBy.day },
  {
    label: EDashboardChartGroupByLabel.week,
    value: EDashboardChartGroupBy.week,
  },
  {
    label: EDashboardChartGroupByLabel.month,
    value: EDashboardChartGroupBy.month,
  },
  {
    label: EDashboardChartGroupByLabel.year,
    value: EDashboardChartGroupBy.year,
  },
];
