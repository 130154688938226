import {
  IResponseBase,
  IResponsePagination,
} from '@interfaces/generic/responses.interface';
import {
  CReportsResidualListParamsDate,
  EReportsResidualCSVParams,
  EReportsResidualListParamStatus,
  EReportsResidualListParams,
  EReportsResidualListSort,
} from '@parameters/reports/residual/residual-list.parameter';
import { IReportsResidual } from '@interfaces/reports/residual/residual.interface';

export interface IReportsResidualListParams {
  [EReportsResidualListParams.size]: number;
  [EReportsResidualListParams.page]: number;
  [EReportsResidualListParams.sort]: string;
  [EReportsResidualListParams.search]: string;
  [EReportsResidualListParams.startDate]: string;
  [EReportsResidualListParams.endDate]: string;
  [EReportsResidualListParams.midList]: string[];
  [EReportsResidualListParams.binList]: number[];
  [EReportsResidualListParams.agentCodeList]: string[];
  [EReportsResidualListParams.status]: EReportsResidualListParamStatus;
}

export const CReportsResidualListParamsDefault: IReportsResidualListParams = {
  [EReportsResidualListParams.size]: 10,
  [EReportsResidualListParams.page]: 1,
  [EReportsResidualListParams.sort]: EReportsResidualListSort.createdDateDesc,
  [EReportsResidualListParams.search]: '',
  [EReportsResidualListParams.startDate]:
    CReportsResidualListParamsDate.value.startDate,
  [EReportsResidualListParams.endDate]:
    CReportsResidualListParamsDate.value.endDate,
  [EReportsResidualListParams.midList]: [],
  [EReportsResidualListParams.binList]: [],
  [EReportsResidualListParams.agentCodeList]: [],
  [EReportsResidualListParams.status]: undefined,
};

export interface IReportsResidualStats {
  totalSalesVolume: number;
  totalSalesCount: number;
  totalNetIncome: number;
  totalResidualsPaid: number;
}

export interface IReportsResidualListResponse
  extends IResponseBase<{
    summary: IResponsePagination;
    rows: IReportsResidual[];
    other: IReportsResidualStats;
  }> {}

export interface IReportsResidualCSVParams {
  [EReportsResidualCSVParams.search]: string;
  [EReportsResidualCSVParams.startDate]: string;
  [EReportsResidualCSVParams.endDate]: string;
  [EReportsResidualCSVParams.midList]: string[];
  [EReportsResidualCSVParams.binList]: string[];
  [EReportsResidualCSVParams.agentCodeList]: string[];
  [EReportsResidualCSVParams.status]: EReportsResidualListParamStatus;
}

export const CReportsResidualCSVParamsDefault: IReportsResidualCSVParams = {
  [EReportsResidualCSVParams.search]: '',
  [EReportsResidualCSVParams.startDate]:
    CReportsResidualListParamsDate.value.startDate,
  [EReportsResidualCSVParams.endDate]:
    CReportsResidualListParamsDate.value.endDate,
  [EReportsResidualCSVParams.midList]: [],
  [EReportsResidualCSVParams.binList]: [],
  [EReportsResidualCSVParams.agentCodeList]: [],
  [EReportsResidualCSVParams.status]: undefined,
};

export interface IReportsResidualCSVResponse
  extends IResponseBase<{ url: string }> {}
