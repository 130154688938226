import {
  CDateFilterListValue,
  EDateFilter,
} from '@parameters/filter/date.parameter';

export const enum EReportsStatementListParams {
  size = 'limit',
  page = 'page',
  sort = 'order_by',
  search = 'search',
  startDate = 'start_date',
  endDate = 'end_date',
  midList = 'mids',
}

export const enum EReportsStatementListSort {
  createdDateAsc = 'created_date:asc',
  createdDateDesc = 'created_date:desc',
}

export const CReportsStatementListParamsDate =
  CDateFilterListValue[EDateFilter.lastMonth];
