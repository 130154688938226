import { IBrandingData } from '@interfaces/branding/branding.interface';
import {
  BRANDING_DEFAULT_COLOR,
  BRANDING_DEFULT_COLOR_VAR,
} from '@parameters/branding/branding.parameter';

export class BrandingResponse {
  public domain: string;
  public favicon: string;
  public fromEmailDomain: string;
  public id: string;
  public linkFive: string;
  public linkFiveUrl: string;
  public linkFour: string;
  public linkFourUrl: string;
  public linkOne: string;
  public linkOneUrl: string;
  public linkThree: string;
  public linkThreeUrl: string;
  public linkTwo: string;
  public linkTwoUrl: string;
  public logo: string;
  public logoWidth: string;
  public metaDescription: string;
  public metaKeywords: string;
  public primaryColor: string;
  public secondaryColor: string;
  public primaryColorVar: string;
  public secondaryColorVar: string;
  public showSidebarImage: boolean;
  public sidebarImage: string;
  public slug: string;

  constructor(iBrandingData: IBrandingData) {
    this.domain = iBrandingData.domain;
    this.favicon = iBrandingData.favicon;
    this.fromEmailDomain = iBrandingData.fromEmailDomain;
    this.id = iBrandingData.id;
    this.linkFive = iBrandingData.linkFive;
    this.linkFiveUrl = iBrandingData.linkFiveUrl;
    this.linkFour = iBrandingData.linkFour;
    this.linkFourUrl = iBrandingData.linkFourUrl;
    this.linkOne = iBrandingData.linkOne;
    this.linkOneUrl = iBrandingData.linkOneUrl;
    this.linkThree = iBrandingData.linkThree;
    this.linkThreeUrl = iBrandingData.linkThreeUrl;
    this.linkTwo = iBrandingData.linkTwo;
    this.linkTwoUrl = iBrandingData.linkTwoUrl;
    this.logo = iBrandingData.logo;
    this.logoWidth = iBrandingData.logoWidth || '180px';
    this.metaDescription = iBrandingData.metaDescription;
    this.metaKeywords = iBrandingData.metaKeywords;
    this.primaryColor = iBrandingData.primaryColor || BRANDING_DEFAULT_COLOR;
    this.secondaryColor =
      iBrandingData.secondaryColor || BRANDING_DEFAULT_COLOR;
    this.primaryColorVar = iBrandingData.primaryColor
      ? this.colorCodeToRGB(iBrandingData.primaryColor)
      : BRANDING_DEFULT_COLOR_VAR;
    this.secondaryColorVar = iBrandingData.secondaryColor
      ? this.colorCodeToRGB(iBrandingData.secondaryColor)
      : BRANDING_DEFULT_COLOR_VAR;
    this.showSidebarImage = iBrandingData.showSidebarImage;
    this.sidebarImage = iBrandingData.sidebarImage;
    this.slug = iBrandingData.slug;
  }

  private colorCodeToRGB(colorCode: string) {
    // Remove any leading '#' from the color code, if present
    colorCode = colorCode.replace('#', '');

    // Parse the RGB components from the color code
    const red = parseInt(colorCode.substring(0, 2), 16);
    const green = parseInt(colorCode.substring(2, 4), 16);
    const blue = parseInt(colorCode.substring(4, 6), 16);

    // Create the RGBA string
    const rgb = `${red}, ${green}, ${blue}`;

    return rgb;
  }
}
