import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { EPaymentMethod } from '@parameters/filter/payment-method.parameter';

export enum EOCEPaymentMethodsFormControlName {
  optionList = 'optionList',
  hidden = 'hidden',
  permission = 'permission',
  active = 'active',
}

export enum EOCEPaymentMethodsOptionFormControlName {
  id = 'id',
  name = 'name',
  predefined = 'predefined',
  preselected = 'preselected',
  percentage = 'percentage',
}

export interface IOCEPaymentMethodsFormValue {
  [EOCEPaymentMethodsFormControlName.optionList]: IOCEPaymentMethodsOptionFormValue[];
  [EOCEPaymentMethodsFormControlName.hidden]: boolean;
  [EOCEPaymentMethodsFormControlName.permission]: boolean;
  [EOCEPaymentMethodsFormControlName.active]: boolean;
}

export interface IOCEPaymentMethodsOptionFormValue {
  [EOCEPaymentMethodsOptionFormControlName.id]: EPaymentMethod;
  [EOCEPaymentMethodsOptionFormControlName.name]: string;
  [EOCEPaymentMethodsOptionFormControlName.predefined]: boolean;
  [EOCEPaymentMethodsOptionFormControlName.preselected]: boolean;
  [EOCEPaymentMethodsOptionFormControlName.percentage]: number;
}

export interface IOCEPaymentMethodsForm {
  [EOCEPaymentMethodsFormControlName.optionList]: FormArray<
    FormGroup<IOCEPaymentMethodsOptionForm>
  >;
  [EOCEPaymentMethodsFormControlName.hidden]: FormControl<
    IOCEPaymentMethodsFormValue[EOCEPaymentMethodsFormControlName.hidden]
  >;
  [EOCEPaymentMethodsFormControlName.permission]: FormControl<
    IOCEPaymentMethodsFormValue[EOCEPaymentMethodsFormControlName.permission]
  >;
  [EOCEPaymentMethodsFormControlName.active]: FormControl<
    IOCEPaymentMethodsFormValue[EOCEPaymentMethodsFormControlName.active]
  >;
}

export interface IOCEPaymentMethodsOptionForm {
  [EOCEPaymentMethodsOptionFormControlName.id]: FormControl<
    IOCEPaymentMethodsOptionFormValue[EOCEPaymentMethodsOptionFormControlName.id]
  >;
  [EOCEPaymentMethodsOptionFormControlName.name]: FormControl<
    IOCEPaymentMethodsOptionFormValue[EOCEPaymentMethodsOptionFormControlName.name]
  >;
  [EOCEPaymentMethodsOptionFormControlName.predefined]: FormControl<
    IOCEPaymentMethodsOptionFormValue[EOCEPaymentMethodsOptionFormControlName.predefined]
  >;
  [EOCEPaymentMethodsOptionFormControlName.preselected]: FormControl<
    IOCEPaymentMethodsOptionFormValue[EOCEPaymentMethodsOptionFormControlName.preselected]
  >;
  [EOCEPaymentMethodsOptionFormControlName.percentage]: FormControl<
    IOCEPaymentMethodsOptionFormValue[EOCEPaymentMethodsOptionFormControlName.percentage]
  >;
}

export const COCEPaymentMethodsFormDefaultValue: IOCEPaymentMethodsFormValue = {
  [EOCEPaymentMethodsFormControlName.optionList]: [],
  [EOCEPaymentMethodsFormControlName.hidden]: false,
  [EOCEPaymentMethodsFormControlName.permission]: false,
  [EOCEPaymentMethodsFormControlName.active]: false,
};
