import {
  IResponseBase,
  IResponseData,
} from '@interfaces/generic/responses.interface';
import {
  CReportsBatchListParamsDate,
  EReportsBatchCSVParams,
  EReportsBatchDetailCSVParams,
  EReportsBatchListParams,
  EReportsBatchListSort,
} from '@parameters/reports/batch/batch-list.parameter';
import { IReportsBatch } from '@interfaces/reports/batch/batch.interface';
import { ECardBrand } from '@parameters/filter/card-brand.parameter';

export interface IReportsBatchListParams {
  [EReportsBatchListParams.size]: number;
  [EReportsBatchListParams.page]: number;
  [EReportsBatchListParams.sort]: string;
  [EReportsBatchListParams.search]: string;
  [EReportsBatchListParams.startDate]: string;
  [EReportsBatchListParams.endDate]: string;
  [EReportsBatchListParams.midList]: string[];
  [EReportsBatchListParams.binList]: number[];
  [EReportsBatchListParams.cardBrandList]: ECardBrand[];
}

export const CReportsBatchListParamsDefault: IReportsBatchListParams = {
  [EReportsBatchListParams.size]: 10,
  [EReportsBatchListParams.page]: 1,
  [EReportsBatchListParams.sort]: EReportsBatchListSort.createdDateDesc,
  [EReportsBatchListParams.search]: '',
  [EReportsBatchListParams.startDate]:
    CReportsBatchListParamsDate.value.startDate,
  [EReportsBatchListParams.endDate]: CReportsBatchListParamsDate.value.endDate,
  [EReportsBatchListParams.midList]: [],
  [EReportsBatchListParams.binList]: [],
  [EReportsBatchListParams.cardBrandList]: [],
};

export interface IReportsBatchListResponseOther {
  totalSales: number;
  totalCredits: number;
  totalSalesAmount: number;
  totalCreditsAmount: number;
}

export interface IReportsBatchListResponseData<T> extends IResponseData<T> {
  others: IReportsBatchListResponseOther;
}

export interface IReportsBatchListResponse
  extends IResponseBase<IReportsBatchListResponseData<IReportsBatch>> {}

export interface IReportsBatchCSVParams {
  [EReportsBatchCSVParams.search]: string;
  [EReportsBatchCSVParams.startDate]: string;
  [EReportsBatchCSVParams.endDate]: string;
  [EReportsBatchCSVParams.midList]: string[];
  [EReportsBatchCSVParams.binList]: string[];
  [EReportsBatchCSVParams.cardBrandList]: ECardBrand[];
}

export interface IReportsBatchCSVResponse
  extends IResponseBase<{ url: string }> {}

export interface IReportsBatchDetailCSVParams {
  [EReportsBatchDetailCSVParams.startDate]: string;
  [EReportsBatchDetailCSVParams.endDate]: string;
  [EReportsBatchDetailCSVParams.search]: string;
}
