import {
  IResponseBase,
  IResponseData,
} from '@interfaces/generic/responses.interface';
import {
  EReportsDealListParams,
  EReportsDealListSort,
} from '@parameters/ops-and-uw-tools/deal/deal-list.parameter';

export interface IReportsDealListParams {
  [EReportsDealListParams.size]: number;
  [EReportsDealListParams.page]: number;
  [EReportsDealListParams.sort]: string;
  [EReportsDealListParams.search]: string;
  [EReportsDealListParams.startDate]: number;
  [EReportsDealListParams.endDate]: number;
  [EReportsDealListParams.status]: string[];
  [EReportsDealListParams.relationshipId]: string[],
}

export const CReportsDealListParamsDefault: IReportsDealListParams = {
  [EReportsDealListParams.size]: 10,
  [EReportsDealListParams.page]: 1,
  [EReportsDealListParams.sort]: EReportsDealListSort.createdDateDesc,
  [EReportsDealListParams.search]: '',
  [EReportsDealListParams.startDate]: null,
  [EReportsDealListParams.endDate]: null,
  [EReportsDealListParams.status]: [],
  [EReportsDealListParams.relationshipId]: [],
};

export interface IReportsDeal {
  id: string;
  mid: string;
  dbaName: string;
  dealCode: string;
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  status: string;
  submittedDate: string;
  resolutionDate: string;
  resolution: string;
  relationshipName: string;
  gettrxAgentCode: string;
  relationshipId: string;
}

export interface IDealListResponse
  extends IResponseBase<IResponseData<IReportsDeal>> {}
