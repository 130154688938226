export interface IDealCreateVarSheetRequest {
  vNumber: string;
  description: string;
  terminalType: string;
  uploadId: string;
}

export const enum EDealVarSheetListParams {
  dealId = 'dealId',
}

export interface IDealSendVarSheetRequest {
  name: string;
  email_template: string;
  sendTo: string[];
}
