import { Injectable } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

@Injectable({providedIn: 'root'})
export class GlobalToastrService {
  /**
   * Constructor
   *
   * @param {ToastrService} toastr
   */
  constructor(private toastr: ToastrService) {}

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  // Success
  toastrSuccess(message?) {
    return this.toastr.success(message, 'Success', {
      toastClass: 'toast show ngx-toastr',
      closeButton: true,
    });
  }

  // Info
  toastrInfo(message?, title?) {
    return this.toastr.info(message, title || 'Info', {
      toastClass: 'toast show ngx-toastr',
      closeButton: true,
    });
  }

  // Warning
  toastrWarning(message?, title?) {
    return this.toastr.warning(message, title || 'Warning', {
      toastClass: 'toast show ngx-toastr',
      closeButton: true,
    });
  }

  // Error
  toastrError(message?) {
    return this.toastr.error(message, 'Error', {
      toastClass: 'toast show ngx-toastr',
      closeButton: true,
    });
  }

}
