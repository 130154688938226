<!-- app-breadcrumb start -->
<section class="gtr-breadcrumb-wrapper">
  <ul class="breadcrums">
    <li *ngFor="let link of breadcrumb;let last=last">
      <a
        *ngIf="link?.isLink"
        [routerLink]="[link?.link]"
        [attr.data-transaction-name]="link?.link + '--breadcrumb'"
        [queryParams]="link?.queryParams"
        [state]="link?.state"
        >{{ link?.name }}</a
      >
      <span *ngIf="!link?.isLink">{{ link?.name }}</span>
      <gtr-icon class="chevron-icon" name="chevron-right" *ngIf="!last" size="22"></gtr-icon>
    </li>
  </ul>
</section>
<!-- app-breadcrumb end -->
