import {
  IPaymentAccessToken,
  IPaymentAccount,
  IPaymentAccountListResponse,
  IPaymentPermission,
} from '@interfaces/payments/payment-account.interface';
import { ResponsePagination } from '@models/generic/responses.model';

export class PaymentAccount {
  public id: string;
  public name: string;

  constructor(iPaymentAccount: IPaymentAccount) {
    this.id = iPaymentAccount?.id || '';
    this.name = iPaymentAccount?.name || '';
  }
}

export class PaymentAccountList {
  public list: PaymentAccount[];
  public pagination: ResponsePagination;

  constructor(iResponsePaymentAccount: IPaymentAccountListResponse) {
    this.list = iResponsePaymentAccount?.data?.rows?.length
      ? iResponsePaymentAccount?.data?.rows?.map(
          (account) => new PaymentAccount(account)
        )
      : [];
    this.pagination = iResponsePaymentAccount?.data?.summary
      ? new ResponsePagination(iResponsePaymentAccount?.data?.summary)
      : undefined;
  }
}

export class PaymentPermission {
  public action: string;
  public effect: string;
  public resource: string;

  constructor(iPaymentPermission: IPaymentPermission) {
    this.action = iPaymentPermission?.action;
    this.effect = iPaymentPermission?.effect;
    this.resource = iPaymentPermission?.resource;
  }
}

export class PaymentAccessToken {
  public accessToken: string;
  public permissions: IPaymentPermission[];

  constructor(iPaymentAccessToken: IPaymentAccessToken) {
    this.accessToken = iPaymentAccessToken?.accessToken || '';
    this.permissions = iPaymentAccessToken?.permissions?.length
      ? iPaymentAccessToken?.permissions?.map(
          (permission) => new PaymentPermission(permission)
        )
      : [];
  }
}
