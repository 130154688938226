import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { AuthOnboardingImplementService } from './auth-onboarding-implement.service';
import { SkipableRoutesService } from 'src/app/shared/services/skipable-routes.service';
import {
  APP_ROUTE_KEYS,
  APP_ROUTES,
  RoutePoints,
  routesArray,
} from '@helpers/routes.enum';
import { EGatewayTitles } from '../steps/payment-gateways/payment-gateways.component';
import { EPaymentMethodType } from '@parameters/offer-pricing/offer-pricing.parameter';
import { EPaymentMethod } from '@parameters/filter/payment-method.parameter';

@Injectable()
export class OnboardingCustomizationService {
  onlinePaymentMethod: any[] = [];
  paymentMethods: any[] = [];
  paymentGatewayTitle: string = '';
  routeList: RoutePoints[] = [];
  businessIncorporated: string = '';

  paymentMethodType: EPaymentMethodType = null;

  constructor(
    private onboardingImplement: AuthOnboardingImplementService,
    private skipRouteService: SkipableRoutesService
  ) {}

  /**
   * getter and setters for properties starts
   */

  // Payment methods
  set setPaymentMethods(paymentMethods: any[]) {
    this.paymentMethods = paymentMethods;
  }
  get getPaymentMethods(): Array<any> {
    return this.paymentMethods;
  }

  // Payment gateways
  set setPaymentGatewaySelectedTitle(paymentGatewayTitle: string) {
    this.paymentGatewayTitle = paymentGatewayTitle;
  }
  get getPaymentGatewaySelectedTitle(): string {
    return this.paymentGatewayTitle;
  }

  // Online payment methods
  set setOnlinePaymentMethods(onlinePaymentMethods: any[]) {
    this.onlinePaymentMethod = onlinePaymentMethods;
  }
  get getOnlinePaymentMethods(): Array<any> {
    return this.onlinePaymentMethod;
  }

  // payment method type
  set setPaymentMethodType(paymentMethodType: EPaymentMethodType) {
    this.paymentMethodType = paymentMethodType;
  }

  get getPaymentMethodType() {
    return this.paymentMethodType;
  }

  // business incorporated
  set setBusinessIncorported(value: string) {
    this.businessIncorporated = value || '';
  }

  get getBusinessIncorporated() {
    return this.businessIncorporated;
  }

  /**
   * getter and setters for properties ends
   */

  /**
   *
   * @returns business detail response data
   */
  getOnboardingBusinessDetail(
    applyValidations: boolean = true
  ): Observable<any> {
    return this.onboardingImplement.getOnboardingBusinessDetail().pipe(
      map((res: any) => {
        if (!applyValidations) {
          return res;
        }
        const { data, success } = res;
        const {
          paymentMethods,
          ecommercePluginsAndOnlinePaymentMethods,
          paymentGateway,
        } = data;

        this.setPaymentMethodType =
          data?.paymentMethodType || EPaymentMethodType.CARD_ONLY;

        if (success) {
          /**
           * Accept payment methods logic starts
           */
          this.setPaymentMethods = paymentMethods;
          this.setPaymentGatewaySelectedTitle =
            paymentGateway[0]?.paymentGateway?.logoTitle;
          this.setOnlinePaymentMethods =
            ecommercePluginsAndOnlinePaymentMethods?.map(
              (onlinePaymentMethod) => onlinePaymentMethod?.onlinePayment
            );
          data.paymentMethods = [
            ...this.mapPaymentMethodWithRightConventions(), // replace virtual-terminal url with payment-gateway url
          ];
          this.setBusinessIncorported = data?.registationType?.shortId;
          this.getAllValidations();
        }

        return res;
      })
    );
  }

  mapRouteList() {
    try {
      this.routeList = JSON.parse(JSON.stringify([...routesArray]));
      this.routeList.map((route: RoutePoints) => {
        if (route?.routeKey) {
          route.skip = this.skipRouteService.shouldDefaultSkip(route.routeKey);
        }
      });

      this.skipRouteService.setDefaultMappedRoutes = JSON.parse(
        JSON.stringify([...this.routeList])
      );
      this.skipRouteService.setDefaultMappedRoutes = [...this.routeList];
    } catch (error) {
      console.log('Onboarding customization data not found');
    }
  }

  getAllValidations(): void {
    // this.initRoutes();
    this.mapRouteList();
    this.skipRouteService.mappedSkipableRouteList = [
      ...this.skipRouteService.getDefaultMappedRoutes,
    ];
    this.setPaymentMethods = [
      ...this.mapPaymentMethodWithRightConventions(), // replace virtual-terminal url with payment-gateway url
    ];
    const PaymentMethodSelectedRouteUrls = this.getPaymentMethods.map(
      (method: any) => method?.url
    );

    // skip product services route if type is not online only
    if (
      this.getPaymentMethods?.some(
        (gateway) =>
          gateway?.shortId === 'online' || gateway?.shortId === 'keyed_in'
      ) &&
      this.getPaymentMethodType !== EPaymentMethodType.ACH_ONLY
    ) {
      this.skipRouteService.updateRoutes([APP_ROUTES.product_services], false);
    } else if (this.getPaymentMethods?.length) {
      this.skipRouteService.updateRoutes([APP_ROUTES.product_services], true);
    }

    /**
     * Accept payment methods logic ends
     */

    /**
     *IN-PERSON: for in-person and business logics starts
     */
    const isInpersonSelectHardwareSkipped =
      PaymentMethodSelectedRouteUrls.includes(APP_ROUTES.select_hardware) &&
      !this.skipRouteService.isSkipableRoute(APP_ROUTES.select_hardware) &&
      this.getPaymentMethodType !== EPaymentMethodType.ACH_ONLY;

    if (isInpersonSelectHardwareSkipped) {
      this.skipRouteService.updateRoutesForInPersonSelectHardware(false);
    } else {
      this.skipRouteService.updateRoutesForInPersonSelectHardware();
    }

    /**
     *IN-PERSON: for in-person and business logics ends
     */

    /**
     *OVER THE PHONE: for payment gateways and business logics starts
     */

    const isPaymentGatewaySkipped =
      (PaymentMethodSelectedRouteUrls.includes(APP_ROUTES.payment_gateway) ||
        PaymentMethodSelectedRouteUrls.includes(APP_ROUTES.taking_payments)) &&
      !this.skipRouteService.isSkipableRoute(APP_ROUTES.payment_gateway) &&
      this.getPaymentMethodType !== EPaymentMethodType.ACH_ONLY;

    if (isPaymentGatewaySkipped) {
      this.skipRouteService.updateRoutes([APP_ROUTES.payment_gateway], false);
      this.verifySelectedPaymentGateway();
    } else {
      this.skipRouteService.updateRoutesForPaymentGatwayAndExistingGatewayAccount();
    }

    /**
     *OVER THE PHONE: for payment gateways OVER THE PHONE and business logics ends
     */

    /**
     * ONLINE: for online payment and business logics starts
     */

    const isOnlinePaymentSkipped =
      PaymentMethodSelectedRouteUrls.includes(APP_ROUTES.taking_payments) &&
      !this.skipRouteService.isSkipableRoute(APP_ROUTES.taking_payments) &&
      this.getPaymentMethodType !== EPaymentMethodType.ACH_ONLY;

    if (isOnlinePaymentSkipped) {
      this.verifySelectedOnlinePaymentMethod();
    } else {
      this.skipRouteService.updateRoutes([APP_ROUTES.taking_payments], true);

      if (
        PaymentMethodSelectedRouteUrls.includes(APP_ROUTES.taking_payments) &&
        !(
          this.skipRouteService.isSkipableRoute(APP_ROUTES.shoping_cart) ||
          this.skipRouteService.isSkipableRoute(APP_ROUTES.ecommerce_platform)
        ) &&
        this.getPaymentMethodType !== EPaymentMethodType.ACH_ONLY
      ) {
        this.verifySelectedOnlinePaymentMethod();
      } else {
        this.skipRouteService.updateRoutesForShoppingAndEcommerce();
      }

      this.skipRouteService.setDataBreach =
        this.skipRouteService.isSkipableRoute(APP_ROUTES.general_info);
      this.skipRouteService.updateRoutes([APP_ROUTES.general_info], false);
    }

    /**
     * ONLINE: for online payment and business logics ends
     */

    /**
     * Configure Sales volue, ach volumem, PCI DSS Compliant and Card Holder electronic config
     */
    if (this.getPaymentMethodType == EPaymentMethodType.ACH_ONLY) {
      this.skipRouteService.updateRoutes(
        [
          APP_ROUTES.select_hardware,
          APP_ROUTES.payment_gateway,
          APP_ROUTES.has_existing_gateway_account,
          APP_ROUTES.sales_volume,
          APP_ROUTES.store_data,
          APP_ROUTES.dcc_compliant,
        ],
        true
      );
    }

    if (
      this.getPaymentMethodType == EPaymentMethodType.ACH_ONLY ||
      this.getPaymentMethodType == EPaymentMethodType.CARD_AND_ACH
    ) {
      this.skipRouteService.updateRoutes([APP_ROUTES.ach_sales_volume], false);
    } else if (this.getPaymentMethodType == EPaymentMethodType.CARD_ONLY) {
      this.skipRouteService.updateRoutes([APP_ROUTES.ach_sales_volume], true);
    }

    // product service page
    const inpersonObject = this.getPaymentMethods?.find(
      (pm) => pm?.shortId === EPaymentMethod.InPerson
    );
    if (inpersonObject && (inpersonObject?.percentage || 0) > 70) {
      this.skipRouteService.updateRoutes([APP_ROUTES.product_services], true);
    } else {
      this.skipRouteService.updateRoutes([APP_ROUTES.product_services], false);
    }

    //public traded company
    if (
      !this.skipRouteService.shouldDefaultSkip(
        APP_ROUTE_KEYS.traded_comapany
      ) &&
      (this.getBusinessIncorporated == 'partnership' ||
        this.getBusinessIncorporated == 'corporation')
    ) {
      this.skipRouteService.updateRoutes([APP_ROUTES.traded_comapany], false);
    } else {
      this.skipRouteService.updateRoutes([APP_ROUTES.traded_comapany], true);
    }
  }

  initRoutes() {
    this.skipRouteService.updateRoutes(
      [
        APP_ROUTES.select_hardware,
        APP_ROUTES.payment_gateway,
        APP_ROUTES.has_existing_gateway_account,
        APP_ROUTES.taking_payments,
        APP_ROUTES.shoping_cart,
        // APP_ROUTES.ecommerce_platform,
      ],
      false
    );
  }

  /**
   *
   * @param paymentMethods payment methods replace virtual-terminal url with payment-gateway and checks for single selected method
   * @returns an array of modified paymeny methods
   */
  mapPaymentMethodWithRightConventions: any = () => {
    try {
      this.checkPercentageRoute();
      return (this.getPaymentMethods || ([] as Array<any>)).map(
        (pMethod: any) => {
          if (pMethod?.url === APP_ROUTES.virtual_terminal) {
            return { ...pMethod, url: APP_ROUTES.payment_gateway };
          } else {
            return { ...pMethod };
          }
        }
      );
    } catch (error) {
      console.log('Payment Methods are not in right format!');
    }
  };

  checkPercentageRoute(): void {
    const isSkipPercentage = !this.skipRouteService.isSkipableRoute(
      APP_ROUTES.payment_percentage
    )
      ? this.getPaymentMethods && this.getPaymentMethods?.length === 1
      : true;
    this.skipRouteService.updateRoutes(
      [APP_ROUTES.payment_percentage],
      isSkipPercentage
    ); // skip percentage page if signle payment method selected
  }
  /**
   * for Payment Gateway and business logics
   */
  verifySelectedPaymentGateway: any = (): void => {
    // this.skipExistingGatewayAccountRoute();
    if (
      this.getPaymentGatewaySelectedTitle === EGatewayTitles.notUsingCurrently
    ) {
      this.skipRouteService.updateRoutes(
        [APP_ROUTES.has_existing_gateway_account],
        true
      );
    } else {
      if (
        !this.skipRouteService.isSkipableRoute(
          APP_ROUTES.has_existing_gateway_account
        )
      ) {
        this.skipExistingGatewayAccountRoute();
      } else {
        this.skipExistingGatewayAccountRoute(true);
      }
    }
  };

  skipExistingGatewayAccountRoute: any = (skip: boolean = false): void => {
    this.skipRouteService.updateRoutes(
      [APP_ROUTES.has_existing_gateway_account],
      skip
    );
  };

  /**
   * for online payment and business logics
   */
  verifySelectedOnlinePaymentMethod: any = (): void => {
    try {
      if (this.getOnlinePaymentMethods?.length > 0) {
        const paymentType = this.getOnlinePaymentMethods[0]?.shortId;
        this.skipRouteService.skipRoutesForPaymentsOnline(paymentType);
      }
    } catch (error) {
      console.log('Online payment Methods not found!');
    }
  };
}
