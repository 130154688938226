import { IResponseBase } from '@interfaces/generic/responses.interface';
import {
  CMetricsKpisProcessingParamsDate,
  EMetricsKpisProcessingParams,
  EMetricsKpisProcessingParamsKpi,
} from '@parameters/metrics/metrics-kpis-processing.parameter';
import { IMetricsKpisValue } from '@interfaces/metrics/metrics-kpis.interface';
import { ECardBrand } from '@parameters/filter/card-brand.parameter';
import { EVolumeAs } from '@parameters/filter/volume-as.parameter';

export interface IMetricsKpisProcessingParams {
  [EMetricsKpisProcessingParams.startDate]: string;
  [EMetricsKpisProcessingParams.endDate]: string;
  [EMetricsKpisProcessingParams.midList]: number[];
  [EMetricsKpisProcessingParams.binList]: number[];
  [EMetricsKpisProcessingParams.kpiList]: EMetricsKpisProcessingParamsKpi[];
  [EMetricsKpisProcessingParams.cardBrandList]: ECardBrand[];
  [EMetricsKpisProcessingParams.relationshipList]: string[];
  [EMetricsKpisProcessingParams.volumeAs]: EVolumeAs[];
}

export interface IMetricsKpisProcessing {
  grossVolume: IMetricsKpisValue;
  totalRefunds: IMetricsKpisValue;
  netVolume: IMetricsKpisValue;
  disputedVolume: IMetricsKpisValue;
}

export interface IMetricsKpisProcessingResponse
  extends IResponseBase<IMetricsKpisProcessing> {}

export const CMetricsKpisProcessingParamsDefault: IMetricsKpisProcessingParams =
  {
    [EMetricsKpisProcessingParams.startDate]:
      CMetricsKpisProcessingParamsDate.value.startDate,
    [EMetricsKpisProcessingParams.endDate]:
      CMetricsKpisProcessingParamsDate.value.endDate,
    [EMetricsKpisProcessingParams.midList]: [],
    [EMetricsKpisProcessingParams.binList]: [],
    [EMetricsKpisProcessingParams.kpiList]: [
      EMetricsKpisProcessingParamsKpi.grossVolume,
      EMetricsKpisProcessingParamsKpi.totalRefunds,
      EMetricsKpisProcessingParamsKpi.netVolume,
      EMetricsKpisProcessingParamsKpi.disputedVolume,
    ],
    [EMetricsKpisProcessingParams.cardBrandList]: [],
    [EMetricsKpisProcessingParams.relationshipList]: [],
    [EMetricsKpisProcessingParams.volumeAs]: [EVolumeAs.calendar],
  };
