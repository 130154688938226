import {
  IResponseBase,
  IResponseData,
} from '@interfaces/generic/responses.interface';
import {
  EOnboardingTemplateListSort,
  EOnboardingTemplateParams,
  EOnboardingTemplateStatus,
} from '@parameters/onboarding-template/onboarding-template.parameter';

export interface IOnboardingTemplateParams {
  [EOnboardingTemplateParams.page]: number;
  [EOnboardingTemplateParams.search]: string;
  [EOnboardingTemplateParams.size]: number;
  [EOnboardingTemplateParams.sort]: string;
  [EOnboardingTemplateParams.status]: EOnboardingTemplateStatus[];
}

export const COnboardingTemplateParamsDefault: IOnboardingTemplateParams = {
  [EOnboardingTemplateParams.page]: 1,
  [EOnboardingTemplateParams.search]: '',
  [EOnboardingTemplateParams.size]: 10,
  [EOnboardingTemplateParams.sort]: EOnboardingTemplateListSort.createdDateDesc,
  [EOnboardingTemplateParams.status]: [],
};

export interface IOnboardingTemplate {
  id: string;
  preferredBrandingDomainId: string;
  templateName: string;
  description: string;
  entityDbaName: string;
  entityFirstName: string;
  entityLastName: string;
  status: string;
  createdOn: Date;
  pricingPlanId: string;
}

export interface IOnboardingTemplateResponse
  extends IResponseBase<IResponseData<IOnboardingTemplate>> {}
