import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { GenericClientService } from '@clients/generic/generic-client.service';
import {
  CMspMerchantApplicationParamsDefault,
  IMspMerchantApplicationListParams,
  IMspMerchantApplicationListResponse,
} from '@interfaces/authentication/msp-merchant-application-list.interface';
import { AuthenticationEndpointsParameter } from '@parameters/authentication/auth-entity-endpoints.parameter';
import { HttpParams } from '@angular/common/http';
import { EMspMerchantApplicationListParams } from '@parameters/authentication/msp-merchant-application-list.parameter';

@Injectable()
export class MspMerchantApplicationClientService {
  private endpoint: string =
    AuthenticationEndpointsParameter.MechantApplicationList;
  constructor(private genericClient: GenericClientService) {}

  /**
   * Get merchanr application lsit
   * @returns
   */
  getMerchantApplicationList(
    queryParams: IMspMerchantApplicationListParams
  ): Observable<IMspMerchantApplicationListResponse> {
    const httpParams = new HttpParams()
      .set(
        EMspMerchantApplicationListParams.size,
        queryParams[EMspMerchantApplicationListParams.size] ||
          CMspMerchantApplicationParamsDefault[
            EMspMerchantApplicationListParams.size
          ]
      )
      .set(
        EMspMerchantApplicationListParams.page,
        queryParams[EMspMerchantApplicationListParams.page] ||
          CMspMerchantApplicationParamsDefault[
            EMspMerchantApplicationListParams.page
          ]
      )
      .set(
        EMspMerchantApplicationListParams.sort,
        queryParams[EMspMerchantApplicationListParams.sort] ||
          CMspMerchantApplicationParamsDefault[
            EMspMerchantApplicationListParams.sort
          ]
      )
      .set(
        EMspMerchantApplicationListParams.search,
        queryParams[EMspMerchantApplicationListParams.search] ||
          CMspMerchantApplicationParamsDefault[
            EMspMerchantApplicationListParams.search
          ]
      )
      .set(
        EMspMerchantApplicationListParams.status,
        queryParams[EMspMerchantApplicationListParams.status]?.join(',') || ''
      );
    return this.genericClient.genericGet<any>(this.endpoint, {
      params: httpParams,
    });
  }
}
