export const MSP_APPLICATION_PROGRESS_PAGE_URL = {
  base: '',
  multiSignaturePrincipal: 'merchant-application-progress',
  secondarySigner: 'secondary-signer',
  secondarySignerSummary: 'summary',
  secondarySignerAgreement: 'agreement',
};

export const MSP_APPLICATION_PROGRESS_PAGE_TITLE = {
  base: '',
  multiSignaturePrincipal: 'Merchant application progress',
  secondarySigner: 'Secondary Signer',
  secondarySignerSummary: 'Secondary Signer Summary',
  secondarySignerAgreement: 'Secondary Signer Agreement',
};
