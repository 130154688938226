import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarContainerComponent } from './sidebar-container/sidebar-container.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { MenuListComponent } from './menu-list/menu-list.component';
import { RouterModule } from '@angular/router';
import { SidebarContentComponent } from './sidebar-content/sidebar-content.component';

const COMPONENTS = [
  SidebarContainerComponent,
  SidenavComponent,
  MenuItemComponent,
  MenuListComponent,
  SidebarContentComponent,
];

@NgModule({
  declarations: COMPONENTS,
  imports: [CommonModule, RouterModule],
  exports: COMPONENTS,
})
export class SidebarModule {}
