import { Injectable } from '@angular/core';
import { GenericClientService } from '@clients/generic/generic-client.service';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import {
  CReportsVolumeListParamsDefault,
  IReportsVolumeCSVParams,
  IReportsVolumeCSVResponse,
  IReportsVolumeListParams,
  IReportsVolumeListResponse,
} from '@interfaces/reports/volume/volume-list.interface';
import { HttpParams } from '@angular/common/http';
import { EReportsVolumeListParams } from '@parameters/reports/volume/volume-list.parameter';
import { Observable } from 'rxjs';

@Injectable()
export class VolumeClientService {
  constructor(private genericClient: GenericClientService) {}

  getVolumeList(
    queryParams: IReportsVolumeListParams
  ): Observable<IReportsVolumeListResponse> {
    const endpoint = EndpointsParameter.ReportsVolume;
    const params = new HttpParams()
      .set(
        EReportsVolumeListParams.size,
        queryParams[EReportsVolumeListParams.size] ||
          CReportsVolumeListParamsDefault[EReportsVolumeListParams.size]
      )
      .set(
        EReportsVolumeListParams.page,
        queryParams[EReportsVolumeListParams.page] ||
          CReportsVolumeListParamsDefault[EReportsVolumeListParams.page]
      )
      .set(
        EReportsVolumeListParams.sort,
        queryParams[EReportsVolumeListParams.sort] ||
          CReportsVolumeListParamsDefault[EReportsVolumeListParams.sort]
      )
      .set(
        EReportsVolumeListParams.search,
        queryParams[EReportsVolumeListParams.search] ||
          CReportsVolumeListParamsDefault[EReportsVolumeListParams.search]
      )
      .set(
        EReportsVolumeListParams.startDate,
        queryParams[EReportsVolumeListParams.startDate] ||
          CReportsVolumeListParamsDefault[EReportsVolumeListParams.startDate]
      )
      .set(
        EReportsVolumeListParams.endDate,
        queryParams[EReportsVolumeListParams.endDate] ||
          CReportsVolumeListParamsDefault[EReportsVolumeListParams.endDate]
      )
      .set(
        EReportsVolumeListParams.midList,
        queryParams[EReportsVolumeListParams.midList].join(',') ||
          CReportsVolumeListParamsDefault[
            EReportsVolumeListParams.midList
          ].join(',')
      )
      .set(
        EReportsVolumeListParams.binList,
        queryParams[EReportsVolumeListParams.binList].join(',') ||
          CReportsVolumeListParamsDefault[
            EReportsVolumeListParams.binList
          ].join(',')
      )
      .set(
        EReportsVolumeListParams.cardBrandList,
        queryParams[EReportsVolumeListParams.cardBrandList].join(',') ||
          CReportsVolumeListParamsDefault[
            EReportsVolumeListParams.cardBrandList
          ].join(',')
      );
    return this.genericClient.genericGet<IReportsVolumeListResponse>(endpoint, {
      params,
    });
  }

  downloadCSV(
    body: IReportsVolumeCSVParams
  ): Observable<IReportsVolumeCSVResponse> {
    const endpoint = EndpointsParameter.ReportsVolumeCSV;
    return this.genericClient.genericPost<IReportsVolumeCSVResponse>(
      endpoint,
      body
    );
  }
}
