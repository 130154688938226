import {
  CDateFilterListValue,
  EDateFilter,
} from '@parameters/filter/date.parameter';

export const enum EMetricsKPIsVolumeParams {
  startDate = 'start_date',
  endDate = 'end_date',
  midList = 'mids',
  binList = 'bins',
  kpiList = 'kpis',
  cardBrandList = 'card_brands',
}

export const enum EMetricsKpisVolumeParamsKpi {
  totalSales = 'total_sales',
  totalRefunds = 'total_refunds',
  totalNet = 'total_net',
  totalTransactions = 'total_transactions',
}

export const CMetricsKPIsVolumeParamsDate =
  CDateFilterListValue[EDateFilter.last30Days];
