import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import {
  GtrSelectComponent,
  GtrSelectListLoaderVariation,
} from '@gettrx/core-components-angular';
import { PartnerSelectAccounts } from '@models/developers/partner-account-select.model';
import { ResponsePagination } from '@models/generic/responses.model';
import { BUSINESS_PATH } from '@parameters/routing/business.parameter';
import { DEVELOPERS_PATHS } from '@parameters/routing/developers';
import { PORTAL_PATH } from '@parameters/routing/portal.parameter';
import { DevelopersAccountService } from '@stores/developers-account-store.service';
import { PortalStoreService } from '@stores/portal-store.service';
import {
  EMPTY,
  Subject,
  catchError,
  debounceTime,
  distinctUntilChanged,
  from,
  groupBy,
  mergeMap,
  reduce,
  switchMap,
  toArray,
} from 'rxjs';
import { UserRoles } from 'src/app/auth/models';
import { AuthenticationService } from 'src/app/auth/service';
import { CommonService } from 'src/app/shared/services/common.service';
import {
  DashboardType,
  SidebarItemsService,
} from 'src/app/shared/services/sidebar-items.service';

@Component({
  selector: 'app-partner-account-dropdown',
  templateUrl: './partner-account-dropdown.component.html',
  styleUrls: ['./partner-account-dropdown.component.scss'],
})
export class PartnerAccountDropdownComponent implements OnInit, OnDestroy {
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _sidebarService = inject(SidebarItemsService);
  private readonly _commonService = inject(CommonService);
  private readonly _developerAccountStore = inject(DevelopersAccountService);
  private readonly _ngZone = inject(NgZone);
  private readonly _cdRef = inject(ChangeDetectorRef);
  private readonly _portalService = inject(PortalStoreService);
  private readonly _authService = inject(AuthenticationService);

  public readonly router = inject(Router);
  public readonly loaderVariation = GtrSelectListLoaderVariation.TEXT;

  public partnerAccountsList: PartnerSelectAccounts[] = [];
  public pagination: ResponsePagination;
  public isFirstRequest: boolean = true;

  private searchDebouncer$: Subject<string> = new Subject();

  @ViewChild('gtrSelect') gtrSelect: GtrSelectComponent;

  public accountId: string;
  public searchValue: string = '';
  public loading: boolean = false;
  public requestLoader: boolean = false;

  public hasMerchant: boolean = false;

  ngOnInit(): void {
    this.hasMerchant = !!this._authService.hasRole(UserRoles.Merchant);

    /** To Setup search observable */
    this._setupSearchDebouncer();

    /** To authenticate user on the basis of current partner's account id */
    this._authenticatePartner();

    /** To get partner's account list */
    this._getPartnerAccountsList();
  }

  /** Get latest partner account whenever get changes and call authenticatePartnerAccount method */
  private _authenticatePartner() {
    this._developerAccountStore?.partnerAccountId$
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe({
        next: (selectedAccountId) => {
          if (selectedAccountId) {
            this.accountId = selectedAccountId || '';
            this._commonService.partnerAccountId = this.accountId;
            if (
              selectedAccountId !==
              this._developerAccountStore?.previousPartnerAccountId
            ) {
              this._authenticateAccount(this.accountId);
            }
          }
        },
      });
  }

  /**
   * Get list of payment account ids
   * @returns
   */
  private _getPartnerAccountsList() {
    this.loading = true;
    return this._developerAccountStore.filterChange$
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        switchMap(() => {
          return this._developerAccountStore.getPartnerAccountsList().pipe(
            catchError(() => {
              this._ngZone.run(() => {
                this.loading = false;
                this.partnerAccountsList = [];
                this.pagination = undefined;
              });
              this._cdRef.detectChanges();
              return EMPTY;
            })
          );
        })
      )
      .subscribe({
        next: (res) => {
          this.loading = false;
          this.partnerAccountsList =
            res?.pagination?.page === 1
              ? res?.list
              : [...this.partnerAccountsList, ...res?.list];

          if (
            this.isFirstRequest &&
            this._commonService?.partnerAccountId &&
            !this.partnerAccountsList?.some(
              (account) => account?.id === this._commonService?.partnerAccountId
            )
          ) {
            this.partnerAccountsList?.push(
              new PartnerSelectAccounts({
                name: '',
                id: this._commonService?.partnerAccountId,
              })
            );
          }

          this._mapArray(this.partnerAccountsList);
          this.pagination = res?.pagination || undefined;
        },
      });
  }

  /** Filter array */
  private _mapArray(array: PartnerSelectAccounts[]) {
    from(array)
      ?.pipe(
        takeUntilDestroyed(this._destroyRef),
        groupBy((account) => account?.id),
        mergeMap((duplicateEntry$) =>
          duplicateEntry$.pipe(
            reduce((acc, cur) => {
              if (cur?.name) return cur;
              return acc;
            })
          )
        ),
        toArray()
      )
      ?.subscribe({
        next: (res) => {
          this.partnerAccountsList = res;
          this.isFirstRequest = false;
        },
      });
  }

  /** To set value of accountId base of specific cases  */
  private _setupAccountIdValue() {
    if (
      /** CASE 1: If have partnerAccountId & paymentAccessToken then set value of account id */
      this._commonService?.partnerAccountId
    ) {
      this.accountId = this._commonService.partnerAccountId;
    } else {
      /** CASE 3: If neither partnerAccountId nor paymentAccessToken are available then call handleValueChange method */
      this.handleValueChange(this.partnerAccountsList?.[0]?.id);
    }
  }

  /** Debounce for account id dropdown search box */
  private _setupSearchDebouncer(): void {
    this.searchDebouncer$
      .pipe(
        debounceTime(750),
        distinctUntilChanged(),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe((search: string) => {
        this.loading = true;
        this._developerAccountStore.search = search;
      });
  }

  /**
   * Handle Pagination for accountIds dropdown it behaves like infinite scroll
   * @param detail
   */
  public handlePagination(detail: any) {
    if (
      detail.reachedBottom &&
      this.pagination?.totalRecords > this.partnerAccountsList?.length &&
      !this.loading
    ) {
      this._developerAccountStore.page = this.pagination.page + 1;
    }
  }

  /**
   * Get latest value of accountID dropdown search
   * @param detail
   */
  public onSearchChange(detail: any) {
    this.searchDebouncer$.next(detail);
  }

  /**
   * Handle value change for accountId dropdown
   * @param event
   */
  public handleValueChange(accountId: string) {
    this._developerAccountStore.partnerAccountId$.next(accountId || '');
  }

  /**
   * Method to call endpoint for authenticate Payment account
   * @param accountId
   */
  private _authenticateAccount(accountId: string) {
    if (!accountId) return;

    this.requestLoader = false;
    this._commonService.partnerAccountId = accountId || '';
    this._developerAccountStore.refreshPartnerAccount$.next(true);
    this.redirectToSession();
    this._setupAccountIdValue();
  }

  /**
   * To get back to dashboard
   * @returns
   */
  public backToDashboard() {
    this._sidebarService.updateDashboardType(DashboardType.DEFAULT);
    this._commonService.partnerAccountId = '';
    return this.router.navigate([
      `/${BUSINESS_PATH.portal}/${this._portalService.type}`,
    ]);
  }

  /** Method used to refresh partnerAccountId param */
  public redirectToSession() {
    const hasAccountId = this.router?.url?.includes(
      this._developerAccountStore?.previousPartnerAccountId
    );

    this._developerAccountStore.currentPartnerAccount$?.next(
      this.accountId || ''
    );
    if (hasAccountId) {
      this.router.navigateByUrl(
        this.router.url?.replace(
          this._developerAccountStore?.previousPartnerAccountId,
          this.accountId
        )
      );
    } else {
      this.router.navigate([
        `/${BUSINESS_PATH.portal}/${this._portalService.type}/${PORTAL_PATH.developers}/${this._commonService?.partnerAccountId}/${DEVELOPERS_PATHS.apiKeys}`,
      ]);
    }
  }

  ngOnDestroy(): void {
    this._developerAccountStore.defaultValues();
  }
}
