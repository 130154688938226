import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

import { GenericClientService } from '@clients/generic/generic-client.service';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import { EEntityMerchantListParams } from '@parameters/entity/merchant/entity-merchant-list.parameter';
import {
  CEntityMerchantListParamsDefault,
  IEntityMerchantListParams,
  IEntityMerchantListResponse,
} from '@interfaces/entity/merchant/entity-merchant-list.interface';

@Injectable()
export class EntityMerchantClientService {
  private endpoint: string = EndpointsParameter.EntityMerchantList;

  constructor(private genericClient: GenericClientService) {}

  /**
   * Get entity relationship list
   * @param queryParams
   * @returns <IEntityMerchantListResponse> response from server.
   */
  getEntityMerchantList(
    queryParams: IEntityMerchantListParams
  ): Observable<IEntityMerchantListResponse> {
    let httpParams = new HttpParams()
      .set(
        EEntityMerchantListParams.relationshipTypes,
        queryParams[EEntityMerchantListParams.relationshipTypes].join(',') ||
          CEntityMerchantListParamsDefault[
            EEntityMerchantListParams.relationshipTypes
          ].join(',')
      )
      .set(
        EEntityMerchantListParams.sort,
        queryParams[EEntityMerchantListParams.sort] ||
          CEntityMerchantListParamsDefault[EEntityMerchantListParams.sort]
      )
      .set(
        EEntityMerchantListParams.page,
        queryParams[EEntityMerchantListParams.page] ||
          CEntityMerchantListParamsDefault[EEntityMerchantListParams.page]
      )
      .set(
        EEntityMerchantListParams.size,
        queryParams[EEntityMerchantListParams.size] ||
          CEntityMerchantListParamsDefault[EEntityMerchantListParams.size]
      )
      .set(
        EEntityMerchantListParams.search,
        queryParams[EEntityMerchantListParams.search] ||
          CEntityMerchantListParamsDefault[EEntityMerchantListParams.search]
      );

    if (queryParams[EEntityMerchantListParams.currentRelationship]) {
      httpParams = httpParams.append(
        EEntityMerchantListParams.currentRelationship,
        queryParams[EEntityMerchantListParams.currentRelationship] ||
          CEntityMerchantListParamsDefault[
            EEntityMerchantListParams.currentRelationship
          ]
      );
    }

    return this.genericClient.genericGet<IEntityMerchantListResponse>(
      this.endpoint,
      { params: httpParams }
    );
  }
}
