import { FormControl } from '@angular/forms';

//OCE: Onboarding Customization Edit

export enum EOCEMerchantAccountFormControlName {
  hasGateway = 'hasGateway',
  hidden = 'hidden',
  active = 'active',
}

export interface IOCEMerchantAccountFormValue {
  [EOCEMerchantAccountFormControlName.hasGateway]: boolean;
  [EOCEMerchantAccountFormControlName.hidden]: boolean;
  [EOCEMerchantAccountFormControlName.active]: boolean;
}

export interface IOCEMerchantAccountForm {
  [EOCEMerchantAccountFormControlName.hasGateway]: FormControl<
    IOCEMerchantAccountFormValue[EOCEMerchantAccountFormControlName.hasGateway]
  >;
  [EOCEMerchantAccountFormControlName.hidden]: FormControl<
    IOCEMerchantAccountFormValue[EOCEMerchantAccountFormControlName.hidden]
  >;
  [EOCEMerchantAccountFormControlName.active]: FormControl<
    IOCEMerchantAccountFormValue[EOCEMerchantAccountFormControlName.active]
  >;
}

export const COCEMerchantAccountFormDefault: IOCEMerchantAccountFormValue = {
  [EOCEMerchantAccountFormControlName.hasGateway]: false,
  [EOCEMerchantAccountFormControlName.hidden]: false,
  [EOCEMerchantAccountFormControlName.active]: false,
};
