import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '@environments/environment';
import { constants } from '../helpers/constants';
import { Meta } from '@angular/platform-browser';
import { IResponseBase } from '@interfaces/generic/responses.interface';
import {
  IBrandingData,
  IBrandingResponse,
} from '@interfaces/branding/branding.interface';
import { BrandingResponse } from '@models/branding/branding.model';
import { BRANDING_DEFAULT_COLOR } from '@parameters/branding/branding.parameter';
import { ProxyGateway } from '@parameters/http/api-proxy-gateway';

@Injectable({
  providedIn: 'root',
})
export class BrandingService {
  private brandingData: IBrandingResponse;
  private readonly appBaseUrl: string = ProxyGateway.loadBaseApiUrl();
  private readonly imgBaseUrl: string = environment.imgFileBaseUrl;
  private favIcon: HTMLLinkElement;

  /**
   * Constructor
   * @param http
   * @param meta
   * @param _document
   */
  constructor(
    private readonly http: HttpClient,
    private readonly meta: Meta,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  public async load(): Promise<void | IResponseBase<IBrandingData>> {
    await this.getProjectInfo();
  }

  public async getProjectInfo() {
    try {
      let brandingRequest: any = await this.http
        .get(this.appBaseUrl + 'v1/agent/branding', {
          headers: {
            // 'ngrok-skip-browser-warning': 'true',
          },
        })
        .toPromise();

      if (brandingRequest) {
        const response = brandingRequest;

        if (
          response &&
          response != 'undefined' &&
          response != 'null' &&
          response.data
        ) {
          this.brandingData = new BrandingResponse(response.data);
          if (this.brandingData) {
            localStorage.setItem(
              constants.branding,
              JSON.stringify(this.brandingData)
            );
            this.themeHandle(this.brandingData);
          }
        }
      }
    } catch (err) {}
    this.themeHandle(this.brandingData);
  }

  // #4396dd
  public themeHandle(brandData: IBrandingResponse) {
    const data = brandData
      ? brandData
      : this.storedBrandingData
      ? this.storedBrandingData
      : null;
    let defaultcolor = BRANDING_DEFAULT_COLOR;
    //set color property
    this.setDocumentStyleProperty(
      '--primary-color',
      data?.primaryColor || defaultcolor
    );
    this.setDocumentStyleProperty(
      '--secondary-color',
      data?.secondaryColor || defaultcolor
    );
    this.setDocumentStyleProperty('--blue', data?.primaryColor || defaultcolor);
    this.setDocumentStyleProperty(
      '--phone',
      data?.primaryColor || defaultcolor
    );
    this.setDocumentStyleProperty(
      '--primary-color-var',
      data?.primaryColorVar || this.colorCodeToRGB(defaultcolor)
    );

    this.setDocumentStyleProperty(
      '--secondary-color-var',
      data?.secondaryColorVar || this.colorCodeToRGB(defaultcolor)
    );

    //sidebar images
    if (data?.showSidebarImage) {
      this.setDocumentStyleProperty(
        '--sidebar-image-url',
        `url(${
          data?.sidebarImage
            ? this.imgBaseUrl + '/' + data?.sidebarImage
            : 'assets/images/rocket.png'
        })`
      );
    }

    //set text color
    const col = this.getTextContrast(data?.primaryColor || defaultcolor);
    col && this.setDocumentStyleProperty('--text-color', col);

    //set secondary color
    const colSecondary = this.getTextContrast(
      data?.secondaryColor || defaultcolor
    );
    colSecondary &&
      this.setDocumentStyleProperty('--secondary-text-color', colSecondary);

    //set fav icon
    this.favIcon = this._document.querySelector('#appIcon');
    this.favIcon.href = `${this.imgBaseUrl}/${data?.favicon ?? data?.logo}`;

    //set meta description
    if (data?.metaDescription) {
      this.meta.updateTag({
        name: 'description',
        content: data?.metaDescription,
      });
    }

    //set meta keywords
    if (data?.metaKeywords) {
      this.meta.updateTag({
        name: 'keywords',
        content: data?.metaKeywords,
      });
    }

    // this.favIcon.href = icon;
  }

  /**
   * Common method to set property
   * @param property
   * @param value
   */
  private setDocumentStyleProperty(property: string, value: string) {
    document.documentElement.style.setProperty(property, value);
  }

  /**
   * Text Color
   * @param hexcolor
   */
  getTextContrast(hexcolor: string) {
    /*!
     * Get the contrasting color for any hex color
     * @param  {String} A hexcolor value
     * @return {String} The contrasting color (black or white)
     */

    // If a leading # is provided, remove it
    if (hexcolor.slice(0, 1) === '#') {
      hexcolor = hexcolor.slice(1);
    }

    // If a three-character hexcode, make six-character
    if (hexcolor.length === 3) {
      hexcolor = hexcolor
        .split('')
        .map(function (hex) {
          return hex + hex;
        })
        .join('');
    }

    // Convert to RGB value
    var r = parseInt(hexcolor.substr(0, 2), 16);
    var g = parseInt(hexcolor.substr(2, 2), 16);
    var b = parseInt(hexcolor.substr(4, 2), 16);

    // Get YIQ ratio
    var yiq = (r * 299 + g * 587 + b * 114) / 1000;

    // Check contrast
    let color = '';
    if (yiq >= 200) {
      color = '#000000';
    } else {
      color = '#ffffff';
    }

    return color;
  }

  /**
   * Convert color code into RGBA
   * @param colorCode
   * @param alpha
   * @returns
   */
  public colorCodeToRGB(colorCode: string) {
    // Remove any leading '#' from the color code, if present
    colorCode = colorCode.replace('#', '');

    // Parse the RGB components from the color code
    const red = parseInt(colorCode.substring(0, 2), 16);
    const green = parseInt(colorCode.substring(2, 4), 16);
    const blue = parseInt(colorCode.substring(4, 6), 16);

    // Create the RGBA string
    const rgb = `${red}, ${green}, ${blue}`;

    return rgb;
  }

  public get storedBrandingData(): IBrandingResponse {
    const brandingData = localStorage.getItem(constants.branding);
    if (brandingData) {
      return new BrandingResponse(JSON.parse(brandingData));
    } else {
      return;
    }
  }
}
