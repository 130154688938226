import {
  CDateFilterListValue,
  EDateFilter,
} from '@parameters/filter/date.parameter';

export const enum EMerchantListParams {
  size = 'limit',
  page = 'page',
  sort = 'order_by',
  search = 'search',
  status = 'status',
  volumeAs = 'volume_as',
  volStartDate = 'vol_start_date',
  volEndDate = 'vol_end_date',
  approvedStartDate = 'approved_start_date',
  approvedEndDate = 'approved_end_date',
  closedStartDate = 'closed_start_date',
  closedEndDate = 'closed_end_date',
  mids = 'mids',
  relationshipIds = 'relationship_ids',
}

export const enum EMerchantListSort {
  createdDateAsc = 'created_date:asc',
  createdDateDesc = 'created_date:desc',
  dbaNameAsc = 'dba_name:asc',
  dbaNameDesc = 'dba_name:desc',
  volumeAsc = 'current_volume:asc',
  volumeDesc = 'current_volume:desc',
  totalVolumeAsc = 'total_volume:asc',
  totalVolumeDesc = 'total_volume:desc',
}

export const CMerchantListParamsVolDate =
  CDateFilterListValue[EDateFilter.currentMonth];

/**
 * Table column names label
 */
export const enum EMerchantListTableHeadLabel {
  dbaName = 'dba name | mid',
  volume = 'volume between',
  totalVolume = 'total volume',
}
