import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[searchContainerWrapper]',
})
export class SearchContainerWrapperDirective {
  params = {
    justifyContent: '',
    flexDirection: '',
  };

  @HostListener('window:resize', ['$event'])
  public onResize(event) {
    // Rewrite media query css by calling addStyles Method based on window.innerWidth condition
    this.addStyles();
  }

  // Private
  @Input('overRideCSS') set justifyContent(value: any) {
    this.params.justifyContent = value.justifyContent;
    this.params.flexDirection = value.flexDirection;
    this.addStyles();
  }
  /**
   * Constructor
   *
   */
  constructor() {
    this.addStyles();
  }

  addStyles(): void {
    // Get the native element
  }
}
