import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberNotation',
})
export class NumberNotationPipe implements PipeTransform {
  /**
   * Round Decimal Pipe
   * @param value
   * @param decimalCount
   * @returns
   */
  constructor(private decimalPipe: DecimalPipe) {}

  transform(num: number, decimalCount: number = 3, hasDecimal: boolean = true) {
    if (
      isNaN(num) ||
      isNaN(decimalCount) ||
      (num === 0 && Number.isInteger(num)) ||
      decimalCount < 0
    ) {
      return (hasDecimal && '0.00') || '0';
    }
    const sign = Math.sign(Number(num));
    // Nine Zeroes for Billions
    return Math.abs(Number(num)) >= 1.0e12
      ? Number(
          (
            sign * Number(Math.abs(Number((Number(num) * 100) / 100) / 1.0e12))
          ).toFixed(decimalCount)
        ).toLocaleString() + 'T'
      : Math.abs(Number(num)) >= 1.0e9
      ? Number(
          (
            sign * Number(Math.abs(Number((Number(num) * 100) / 100) / 1.0e9))
          ).toFixed(decimalCount)
        ).toLocaleString() + 'B'
      : // Six Zeroes for Millions
      Math.abs(Number(num)) >= 1.0e6
      ? Number(
          (
            sign * Number(Math.abs(Number((Number(num) * 100) / 100) / 1.0e6))
          ).toFixed(decimalCount)
        ).toLocaleString() + 'M'
      : // : // Three Zeroes for Thousands
      // Math.abs(Number(num)) >= 1.0e4
      // ? (sign * Number(Math.abs(Number(Number(num)) / 1.0e3))).toFixed(
      //     decimalCount
      //   ) + 'K'
      hasDecimal
      ? this.decimalPipe.transform(num, '1.2-2', 'en-US')
      : Number(Math.floor(num)).toLocaleString();
  }
}
