import {
  CDateFilterListValue,
  EDateFilter,
} from '@parameters/filter/date.parameter';

export const enum EMetricsKpisProcessingParams {
  startDate = 'start_date',
  endDate = 'end_date',
  midList = 'mids',
  binList = 'bins',
  kpiList = 'kpis',
  cardBrandList = 'card_brands',
  relationshipList = 'relationship_ids',
  volumeAs = 'volume_as',
}

export const enum EMetricsKpisProcessingParamsKpi {
  grossVolume = 'gross_volume',
  totalRefunds = 'total_refunds',
  netVolume = 'net_volume',
  disputedVolume = 'disputed_volume',
}

export const CMetricsKpisProcessingParamsDate =
  CDateFilterListValue[EDateFilter.last30Days];
