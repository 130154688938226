import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentConfirmPasswordComponent } from './payment-confirm-password/payment-confirm-password.component';
import { GtrButtonModule, GtrInputModule, GtrModalModule } from '@gettrx/core-components-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import {InputErrorMsgComponent} from "@molecules/input-error-msg/input-error-msg.component";



@NgModule({
  declarations: [
    PaymentConfirmPasswordComponent
  ],
    imports: [
        CommonModule,
        GtrModalModule,
        ReactiveFormsModule,
        FormsModule,
        GtrInputModule,
        GtrButtonModule,
        SharedModule,
        InputErrorMsgComponent
    ],
  exports: [PaymentConfirmPasswordComponent]
})
export class PaymentConfirmPasswordModule { }
