import { Injectable } from '@angular/core';
import { AuthOnboardingClientService } from '@clients/authentication/auth-onboarding-client.service';
import { IOnboardingSkipableRoutesData } from '@interfaces/authentication/onboading-skipable-routes-list.interface';
import { map, Observable } from 'rxjs';

@Injectable()
export class AuthOnboardingImplementService {
  constructor(private onboardingClient: AuthOnboardingClientService) {}

  /**
   * Fetch entity relationship list
   * @param params
   * @returns
   */
  getOnboardingConfiguration(): Observable<IOnboardingSkipableRoutesData> {
    return this.onboardingClient.getOnboardingConfiguration();
  }

  /**
   * Fetch onboarding business details
   * @returns
   */
  getOnboardingBusinessDetail(): Observable<any> {
    return this.onboardingClient.getOnboardingBusinessDetail()?.pipe(
      map((res) => {
        if (res?.data) {
          const onlinePaymentMethods = res?.data?.onlinePaymentMethods?.length
            ? res?.data?.onlinePaymentMethods
            : [];
          Object?.assign(res?.data, {
            ecommercePluginsAndOnlinePaymentMethods: [...onlinePaymentMethods],
          });
        }

        return res;
      })
    );
  }
}
