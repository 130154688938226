import { Injectable } from '@angular/core';
import { GenericClientService } from '@clients/generic/generic-client.service';
import {
  CMetricsKPIsDisputeParamsDefault,
  IMetricsKPIsDisputeParams,
  IMetricsKPIsDisputeResponse,
} from '@interfaces/metrics/metrics-kpis-dispute.interface';
import { Observable } from 'rxjs';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import { HttpParams } from '@angular/common/http';
import { EMetricsKPIsDisputeParams } from '@parameters/metrics/metrics-kpis-dispute.parameter';

@Injectable()
export class MetricsKpisDisputeClientService {
  constructor(private genericClient: GenericClientService) {}

  getMetricsKPIsDispute(
    queryParams: IMetricsKPIsDisputeParams
  ): Observable<IMetricsKPIsDisputeResponse> {
    const endpoint = EndpointsParameter.MetricsKPIsDisputes;
    const params = new HttpParams()
      .set(
        EMetricsKPIsDisputeParams.startDate,
        queryParams[EMetricsKPIsDisputeParams.startDate] ||
          CMetricsKPIsDisputeParamsDefault[EMetricsKPIsDisputeParams.startDate]
      )
      .set(
        EMetricsKPIsDisputeParams.endDate,
        queryParams[EMetricsKPIsDisputeParams.endDate] ||
          CMetricsKPIsDisputeParamsDefault[EMetricsKPIsDisputeParams.endDate]
      )
      .set(
        EMetricsKPIsDisputeParams.midList,
        queryParams[EMetricsKPIsDisputeParams.midList].join(',') ||
          CMetricsKPIsDisputeParamsDefault[
            EMetricsKPIsDisputeParams.midList
          ].join(',')
      )
      .set(
        EMetricsKPIsDisputeParams.binList,
        queryParams[EMetricsKPIsDisputeParams.binList].join(',') ||
          CMetricsKPIsDisputeParamsDefault[
            EMetricsKPIsDisputeParams.binList
          ].join(',')
      );
    return this.genericClient.genericGet<IMetricsKPIsDisputeResponse>(
      endpoint,
      { params }
    );
  }
}
