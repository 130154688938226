import { Component, DestroyRef, Input, inject } from '@angular/core';
import { EOfferPricingType } from '@parameters/offer-pricing/offer-pricing.parameter';
import { TotalProductCost } from 'src/app/business/authentication/auth-onboarding/steps/select-hardware/select-hardware.component';
import { OfferBannerService } from './services/offer-banner.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'terminal-calculations',
  template: `
    <div
      class="total-offer w-100 mt-1"
      *ngIf="
        totalproductCost?.totalTerminalItems ||
        totalproductCost?.totalAccessoriesItems
      "
    >
      <div class="offer_list w-100 mb-1">
        <div
          class="d-flex flex-row align-items-center justify-content-between px-1 pt-1"
        >
          <span class="fs-12 fw-300 text-start"
            >Terminal Cost
            <span *ngIf="totalproductCost?.totalTerminalItems"
              >({{ totalproductCost?.totalTerminalItems }})</span
            ></span
          >
          <span class="fs-13 fw-400 first-value mwx-86 text-end">{{
            '$' + (totalproductCost?.terminalCost?.toFixed(2) || '0.00')
          }}</span>
        </div>
        <div
          class="d-flex flex-row align-items-center justify-content-between px-1 pt-1 monthly-cost-row"
        >
          <span class="fs-12 fw-300 text-start"
            >Accessories Cost<span
              *ngIf="totalproductCost?.totalAccessoriesItems"
              >({{ totalproductCost?.totalAccessoriesItems }})</span
            ></span
          >
          <span class="fs-13 fw-400 first-value mwx-86 text-end">{{
            '$' + (totalproductCost?.accessoriesCost?.toFixed(2) || '0.00')
          }}</span>
        </div>

        <div
          class="d-flex flex-row align-items-center justify-content-between px-1 pt-1"
          *ngIf="totalproductCost?.includedProduct && !isCashDiscount"
        >
          <span class="fs-12 fw-300 text-start">Terminal(s) Included</span>
          <span class="fs-13 fw-400 first-value mwx-86 text-end"
            >-
            {{
              '$' + (totalproductCost?.includedProduct?.toFixed(2) || '0.00')
            }}</span
          >
        </div>

        <div
          class="d-flex flex-row align-items-center justify-content-between px-1 pt-1"
          *ngIf="isCashDiscount"
        >
          <span class="fs-12 fw-300 text-start">All terminals included</span>
          <span class="fs-13 fw-400 first-value mwx-86 text-end"
            >-
            {{
              '$' + (totalproductCost?.terminalCost?.toFixed(2) || '0.00')
            }}</span
          >
        </div>

        <hr class="p-0 mtt-2 mb-0" />
        <div
          class="d-flex flex-row align-items-center justify-content-between px-1 pt-1 terminal-cost-row"
          [class.pb-1]="totalproductCost?.monthlyCost <= 0"
        >
          <span class="fs-12 fw-300 text-start text-white"
            >Total Hardware Cost</span
          >
          <span class="fs-13 fw-400 first-value mwx-86 text-end text-white">{{
            '$' + (totalproductCost?.finalCost?.toFixed(2) || '0.00')
          }}</span>
        </div>

        <div
          class="d-flex flex-row align-items-center justify-content-between px-1 pt-1 pb-1"
          *ngIf="totalproductCost?.monthlyCost > 0"
        >
          <span class="fs-12 fw-300 text-start text-white">Monthly Cost</span>
          <span class="fs-13 fw-400 first-value mwx-86 text-end text-white">{{
            '$' + (totalproductCost?.monthlyCost?.toFixed(2) || '0.00')
          }}</span>
        </div>
      </div>

      <div class="offer_list w-100 mb-1" *ngIf="isCashDiscount">
        <div
          class="d-flex flex-row align-items-center justify-content-between px-1 pt-1 pb-1"
        >
          <span class="fs-12 fw-300 text-start text-white"
            >Monthly Program Fee</span
          >
          <span class="fs-13 fw-400 first-value mwx-86 text-end text-white">{{
            '$' +
              ((
                +totalproductCost?.totalTerminalItems * coreReccuringFee
              )?.toFixed(2) || '0.00')
          }}</span>
        </div>
      </div>
    </div>
  `,
})
export class TerminalCalculationsComponent {
  public destroyRef = inject(DestroyRef);

  @Input() public totalproductCost!: TotalProductCost;
  public pricingType!: string;
  public coreReccuringFee!: number;
  public readonly offerPricingType = EOfferPricingType;

  constructor(private readonly offerBannerService: OfferBannerService) {
    this.offerBannerService
      .getPricingBannerData()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res) => {
          this.pricingType = res?.pricingType;
          this.coreReccuringFee =
            +res?.cashDiscountCoreFees?.programRecurringFee || 0;
        },
      });
  }

  get isCashDiscount(): boolean {
    return this.pricingType === this.offerPricingType.CASH_DISCOUNT;
  }
}
