import { Injectable } from '@angular/core';
import { GenericClientService } from '@clients/generic/generic-client.service';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import { HttpParams } from '@angular/common/http';
import { EMetricsChartCardBrandVolumeParams } from '@parameters/metrics/chart/metrics-charts-card-brand-volume.parameter';
import {
  CMetricsChartCardBrandVolumeParamsDefault,
  IMetricsChartCardBrandVolumeParams,
  IMetricsChartCardBrandVolumeResponse,
} from '@interfaces/metrics/charts/metrics-charts-card-brand-volume.interface';
import { Observable } from 'rxjs';

@Injectable()
export class MetricsChartsCardBrandVolumeClientService {
  constructor(private genericClient: GenericClientService) {}

  public getList(
    queryParams: IMetricsChartCardBrandVolumeParams
  ): Observable<IMetricsChartCardBrandVolumeResponse> {
    const endpoint = EndpointsParameter.MetricsChartsCardBrandVolume;

    const params = new HttpParams()
      .set(
        EMetricsChartCardBrandVolumeParams.midList,
        queryParams[EMetricsChartCardBrandVolumeParams.midList].join(',') ||
          CMetricsChartCardBrandVolumeParamsDefault[
            EMetricsChartCardBrandVolumeParams.midList
          ].join(',')
      )
      .set(
        EMetricsChartCardBrandVolumeParams.binList,
        queryParams[EMetricsChartCardBrandVolumeParams.binList].join(',') ||
          CMetricsChartCardBrandVolumeParamsDefault[
            EMetricsChartCardBrandVolumeParams.binList
          ].join(',')
      )
      .set(
        EMetricsChartCardBrandVolumeParams.cardBrandList,
        queryParams[EMetricsChartCardBrandVolumeParams.cardBrandList].join(
          ','
        ) ||
          CMetricsChartCardBrandVolumeParamsDefault[
            EMetricsChartCardBrandVolumeParams.cardBrandList
          ].join(',')
      )
      .set(
        EMetricsChartCardBrandVolumeParams.relationshipList,
        queryParams[EMetricsChartCardBrandVolumeParams.relationshipList].join(
          ','
        ) ||
          CMetricsChartCardBrandVolumeParamsDefault[
            EMetricsChartCardBrandVolumeParams.relationshipList
          ].join(',')
      )
      .set(
        EMetricsChartCardBrandVolumeParams.startDate,
        queryParams[EMetricsChartCardBrandVolumeParams.startDate] ||
          CMetricsChartCardBrandVolumeParamsDefault[
            EMetricsChartCardBrandVolumeParams.startDate
          ]
      )
      .set(
        EMetricsChartCardBrandVolumeParams.endDate,
        queryParams[EMetricsChartCardBrandVolumeParams.endDate] ||
          CMetricsChartCardBrandVolumeParamsDefault[
            EMetricsChartCardBrandVolumeParams.endDate
          ]
      )
      .set(
        EMetricsChartCardBrandVolumeParams.volumeAs,
        queryParams[EMetricsChartCardBrandVolumeParams.volumeAs].join('') ||
          CMetricsChartCardBrandVolumeParamsDefault[
            EMetricsChartCardBrandVolumeParams.volumeAs
          ].join('')
      );
    return this.genericClient.genericGet<IMetricsChartCardBrandVolumeResponse>(
      endpoint,
      { params }
    );
  }
}
