import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  NgZone,
  Output,
  inject,
} from '@angular/core';
import { AccountsStoreService } from '../../services/accounts-store.service';
import { Account } from '../../models/accounts-list.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CAccountFormDefaultValues,
  EAccountFormKeys,
} from '@parameters/accounts/accounts-form.parameter';
import { noWhitespaceValidator } from '@helpers/validators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CommonService } from 'src/app/shared/services/common.service';
import { IAccountForm } from '@interfaces/accounts/accounts-form.interface';
import {
  CGatewayAccountStatusOptions,
  EGatewayAccountStatus,
} from '@parameters/accounts/accounts-list.parameter';

@Component({
  selector: 'app-account-detail-form',
  templateUrl: './account-detail-form.component.html',
  styleUrls: ['./account-detail-form.component.scss'],
})
export class AccountDetailFormComponent {
  private readonly _cdr = inject(ChangeDetectorRef);
  private readonly _ngZone = inject(NgZone);
  private readonly _destroyRef = inject(DestroyRef);

  private readonly _accountsStore = inject(AccountsStoreService);
  private readonly _cm = inject(CommonService);

  public readonly formKeys = EAccountFormKeys;
  public readonly gatewayStatusOptions = CGatewayAccountStatusOptions;

  public accountId: string;
  public loading: boolean = false;
  public selectedGatewayStatus: EGatewayAccountStatus;

  @Input({ required: true }) public set detail(value: Account) {
    this.accountId = value?.id || '';
    this._mapFormValue(value);
  }

  @Output() public close = new EventEmitter<boolean>();

  public readonly accountUpdateForm = new FormGroup({
    [EAccountFormKeys.accountName]: new FormControl(
      CAccountFormDefaultValues[EAccountFormKeys.accountName],
      {
        validators: [
          Validators.required,
          noWhitespaceValidator,
          Validators.maxLength(100),
        ],
      }
    ),
    [EAccountFormKeys.isPaymentEnabled]: new FormControl(
      CAccountFormDefaultValues[EAccountFormKeys.isPaymentEnabled],
      {
        validators: [Validators.required, noWhitespaceValidator],
      }
    ),
  });

  public submitForm() {
    if (this.accountUpdateForm?.valid && this.accountId) {
      this.loading = true;
      this._accountsStore
        .updateAccountDetail(
          this.accountUpdateForm?.value as IAccountForm,
          this.accountId
        )
        ?.pipe(takeUntilDestroyed(this._destroyRef))
        ?.subscribe({
          next: (res) => {
            this.loading = false;
            this.accountUpdateForm.reset(CAccountFormDefaultValues);
            if (res?.message) {
              this._cm.success(res?.message);
            }
            this.discard(true);
          },
          error: () => {
            this._ngZone.run(() => {
              this.loading = false;
              this._cdr.detectChanges();
            });
          },
        });
    } else {
      this.accountUpdateForm?.markAllAsTouched();
    }
  }

  public discard(refresh: boolean = false) {
    this.selectedGatewayStatus = CAccountFormDefaultValues[
      EAccountFormKeys.isPaymentEnabled
    ]
      ? EGatewayAccountStatus.enabled
      : EGatewayAccountStatus.disabled;
    this.accountUpdateForm.reset(CAccountFormDefaultValues);
    this.close.emit(refresh);
  }

  private _mapFormValue(data: Account) {
    this.selectedGatewayStatus = data?.isPaymentsEnabled
      ? EGatewayAccountStatus.enabled
      : EGatewayAccountStatus.disabled;
    this.accountUpdateForm.patchValue({
      [EAccountFormKeys.accountName]: data?.accountName || '',
      [EAccountFormKeys.isPaymentEnabled]: !!data?.isPaymentsEnabled,
    });
  }

  public handleStatusChange(selectedStatus: EGatewayAccountStatus) {
    this.selectedGatewayStatus = selectedStatus;
    this.accountUpdateForm
      .get(EAccountFormKeys.isPaymentEnabled)
      ?.setValue(
        selectedStatus === EGatewayAccountStatus.enabled ? true : false
      );
    this.accountUpdateForm
      .get(EAccountFormKeys.isPaymentEnabled)
      ?.markAllAsTouched();
  }
}
