import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  ActivatedRoute,
  RouterStateSnapshot,
} from '@angular/router';

import { AuthenticationService } from 'src/app/auth/service';
import { GlobalConstantRoutes } from '@parameters/routing/global-constant-routes';
import { PORTAL } from '@parameters/routing/portal.parameter';
import { BUSINESS_PATH } from '@parameters/routing/business.parameter';

@Injectable({ providedIn: 'root' })
export class OnboardGuard {
  /**
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService
  ) {}

  // canActivate
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = this._authenticationService.authToken;
    const id = this._authenticationService.currentSelectedMerchantId;

    const routeUrl = `/${BUSINESS_PATH.portal}/${
      PORTAL.merchant
    }/${GlobalConstantRoutes.merchantDashboardWithId(id)}`;

    /**
     * Now merchant redirect to dashboard if reload the domain without route
     */

    if (token) {
      this._router.navigate([routeUrl]);
    }
  }
}

@Injectable({ providedIn: 'root' })
export class PaymentGuard {
  constructor() {}

  // canActivate
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (sessionStorage.payment_methods) {
      const pay_methods: any[] =
        JSON.parse(sessionStorage.payment_methods) || [];
      let url: string[] = pay_methods.map((x) => x.url);
      if (url.includes(state.url.split('/')[2])) {
        return true;
      }
      history.back();
      return false;
    } else {
      return true;
    }
  }
}

@Injectable({ providedIn: 'root' })
export class SubmissionGuard {
  /**
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService
  ) {}

  // canActivate
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = this._authenticationService.currentSelectedMerchantId;
    const routeUrl = `/${BUSINESS_PATH.portal}/${
      PORTAL.merchant
    }/${GlobalConstantRoutes.merchantDashboardWithId(id)}`;
    if (!localStorage?.is_application_submitted) {
      this._router.navigate([routeUrl]);
      return false;
    } else {
      return true;
    }
  }
}

@Injectable({ providedIn: 'root' })
export class verificationGuard {
  /**
   * @param acRoute
   */
  constructor(private acRoute: ActivatedRoute) {}

  // canActivate
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.acRoute.queryParams.subscribe(async (params) => {
      let key = await params?.token;
      if (key) {
        return true;
      } else {
        return false;
      }
    });
    return true;
  }
}
