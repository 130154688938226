export enum EOfferPricingType {
  FLAT_RATE = 'flat_rate',
  INTERCHANGE_PLUS = 'interchange_plus',
  CASH_DISCOUNT = 'cash_discount',
  TIERED = 'tiered',
}

export const COfferPricingType = [
  {
    id: EOfferPricingType.FLAT_RATE,
    name: 'Flat Rate',
  },
  {
    id: EOfferPricingType.INTERCHANGE_PLUS,
    name: 'Interchange Plus',
  },
  {
    id: EOfferPricingType.CASH_DISCOUNT,
    name: 'Cash Discount',
  },
  {
    id: EOfferPricingType.TIERED,
    name: 'Tiered Plan',
  },
];

export type PricingType =
  | EOfferPricingType.FLAT_RATE
  | EOfferPricingType.INTERCHANGE_PLUS
  | EOfferPricingType.CASH_DISCOUNT
  | EOfferPricingType.TIERED;

export enum EPaymentMethodType {
  CARD_ONLY = 'card',
  ACH_ONLY = 'ach',
  CARD_AND_ACH = 'card_and_ach',
}

export enum EPaymentMethodConfigurationType {
  CARD_ONLY = 'card',
  ACH_ONLY = 'ach',
  CARD_AND_ACH = 'card_and_ach',
  OTHER_CONFIGURATIONS = 'other_configurations',
}

export const CPaymentMethodConfigurationsTypeLabel = {
  [EPaymentMethodConfigurationType.CARD_ONLY]: 'Card Default Configuration',
  [EPaymentMethodConfigurationType.ACH_ONLY]: 'ACH Default Configuration',
  [EPaymentMethodConfigurationType.CARD_AND_ACH]: 'Card & ACH',
  [EPaymentMethodConfigurationType.OTHER_CONFIGURATIONS]: 'Other Configuration',
};

export const CPaymentMethodTypeLabel = {
  [EPaymentMethodType.CARD_ONLY]: 'Card Only',
  [EPaymentMethodType.ACH_ONLY]: 'ACH Only',
  [EPaymentMethodType.CARD_AND_ACH]: 'Card & ACH',
};

export const CPaymentMethodType = [
  {
    id: EPaymentMethodType.CARD_ONLY,
    name: 'Card Only',
  },
  {
    id: EPaymentMethodType.ACH_ONLY,
    name: 'ACH Only',
  },
  {
    id: EPaymentMethodType.CARD_AND_ACH,
    name: 'Card & ACH',
  },
];

export type PaymentMethodType =
  | EPaymentMethodType.CARD_ONLY
  | EPaymentMethodType.ACH_ONLY
  | EPaymentMethodType.CARD_AND_ACH;

export enum EAchPaymentType {
  DEBITS_ONLY = 'debits',
  CREDITS_ONLY = 'credits',
  DEBITS_AND_CREDITS = 'debits_and_credits',
}

export const CAchPaymentTypeLabel = {
  [EAchPaymentType.DEBITS_ONLY]: 'Debits Only',
  [EAchPaymentType.CREDITS_ONLY]: 'Credits Only',
  [EAchPaymentType.DEBITS_AND_CREDITS]: 'Debits & Credits',
};

export const CAchPaymentTypes = [
  {
    id: EAchPaymentType.DEBITS_ONLY,
    name: 'Debits Only',
  },
  {
    id: EAchPaymentType.CREDITS_ONLY,
    name: 'Credits Only',
  },
  {
    id: EAchPaymentType.DEBITS_AND_CREDITS,
    name: 'Debit & Credits',
  },
];

export enum EOfferPricePaymentMethodType {
  ACH = 'ach',
  CARD = 'card',
  CARD_AND_ACH = 'card_and_ach',
}
