import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  ViewChild,
} from '@angular/core';
import { SIDENAV_MODE } from '../sidebar.interface';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-sidebar-content',
  templateUrl: './sidebar-content.component.html',
  styleUrls: ['./sidebar-content.component.scss'],
})
export class SidebarContentComponent implements AfterViewInit {
  @ViewChild('sidebarContentWrapper', { static: true })
  private sidebarContentWrapper: ElementRef<HTMLDivElement>;
  public setOpacity: boolean = false;
  public collapsed: boolean = false;
  public mode: SIDENAV_MODE = 'push';

  @HostListener('window:resize', ['$event'])
  public onResize(event) {
    // Rewrite media query css by calling addStyles Method based on window.innerWidth condition
    this.setSidebarContentMargin();
  }

  /* To manage collapsible state of sidebar */
  @Input() public set setCollapsed(value: boolean) {
    this.collapsed = value;
  }

  /* To manage blackoverlay and margins on basis of sidenav mode */
  @Input() public set setMode(value: SIDENAV_MODE) {
    this.mode = value;
  }

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.setSidebarState();
  }

  ngAfterViewInit(): void {
    this.setSidebarContentMargin();
  }

  onScroll(e) {
    let element = this.sidebarContentWrapper.nativeElement;
    this.setOpacity = element.scrollTop > 0;
  }

  setSidebarState(): void {
    if (window.innerWidth <= 1200) {
      this.collapsed = true;
    } else {
      this.collapsed = false;
    }
  }

  /* Setting sidebar content margin dynamically */
  private setSidebarContentMargin() {
    const contentWrapper = this.sidebarContentWrapper.nativeElement;
    const sidebarContainer = this.document.getElementById('sidenav-container');
    const sidebarWidth =
      sidebarContainer && sidebarContainer.clientWidth
        ? sidebarContainer.clientWidth
        : 0;
    const hasClass = contentWrapper.classList.contains('expand');
    if (hasClass && sidebarWidth) {
      this.sidebarContentWrapper.nativeElement.style.marginLeft = ` ${sidebarWidth}px`;
    } else {
      this.sidebarContentWrapper.nativeElement.style.marginLeft = '0px';
    }
  }
}
