export const enum EFPOnboardingTemplateListParams {
  size = 'limit',
  page = 'page',
  sort = 'order_by',
  search = 'search',
}

export const enum EFPOnboardingTemplateListSort {
  asc = 'asc',
  desc = 'desc',
  createdOn = 'created_on',
}

export const CFPOnboardingTemplateListParamsDefault = {
  [EFPOnboardingTemplateListParams.size]: 10,
  [EFPOnboardingTemplateListParams.page]: 1,
  [EFPOnboardingTemplateListParams.sort]: [
    EFPOnboardingTemplateListSort.createdOn,
    EFPOnboardingTemplateListSort.desc,
  ],
  [EFPOnboardingTemplateListParams.search]: '',
};
