<!---->
<nav>
  <div
    class="toggle-icon top-nav-item-hidden"
    *ngIf="!(navbarEventBusService?.isBlockSearchOpened$ | async)"
  >
    <a (click)="buttonClicked()"  [attr.data-transaction-name]="'top-navbar-toggle'">
      <svg
        width="18"
        height="14"
        viewBox="0 0 18 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 1H10"
          stroke="#ffffff"
          stroke-width="1.75"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17 1H10"
          stroke="white"
          stroke-opacity="0.1"
          stroke-width="1.75"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17 7H8"
          stroke="#ffffff"
          stroke-width="1.75"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17 7H8"
          stroke="white"
          stroke-opacity="0.1"
          stroke-width="1.75"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17 13H10"
          stroke="#ffffff"
          stroke-width="1.75"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17 13H10"
          stroke="white"
          stroke-opacity="0.1"
          stroke-width="1.75"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5 3L1 7L5 11"
          stroke="#ffffff"
          stroke-width="1.75"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5 3L1 7L5 11"
          stroke="white"
          stroke-opacity="0.1"
          stroke-width="1.75"
          stroke-linecap="round"
          stroke-linejoin="round"
        /></svg></a>
  </div>
  <ul
    class="menu top-nav-item-hidden"
    *ngIf="!(navbarEventBusService?.isBlockSearchOpened$ | async)"
  >
    <span>
      <app-nav-profile></app-nav-profile>
    </span>
  </ul>
</nav>
