<a (click)="backToDashboard()" class="dashboard-link" [attr.data-transaction-name]="'partner-account-dropdown'">
  <gtr-icon name="chevron-left" size="20"
  [dataTransactionName]="'partner-account-dropdown'"></gtr-icon>
  <span>Back to Dashboard</span>
</a>

<div class="partner-account-dropdown-wrapper">
  <img src="/assets/images/menu/business-and-trade.svg" alt="company-icon" />
  <div class="dropdown-wrapper flex-grow-1">
    <gtr-select
      [inputLabel]="hasMerchant ? 'Business Account' : 'Partner Account'"
      [lazyLoaded]="true"
      [listLoading]="loading"
      [listLoaderVariation]="loaderVariation"
      [labelKey]="'name'"
      [valueKey]="'id'"
      [subLabel]="'id'"
      [highlightLabel]="'id'"
      [selectedLabel]="'name'"
      [placeholder]="'Select'"
      [options]="partnerAccountsList"
      [textLimit]="100"
      [disabled]="requestLoader"
      [value]="accountId || ''"
      [searchable]="true"
      (valueChanged)="handleValueChange($event)"
      (searchChanged)="onSearchChange($event)"
      (gtrScroll)="handlePagination($event)"
      [dataTransactionName]="'partner-account-dropdown'"
    ></gtr-select>
  </div>
</div>
