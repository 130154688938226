import { Injectable } from '@angular/core';
import { GenericClientService } from '@clients/generic/generic-client.service';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import {
  IFPBillingSettingListParams,
  IFPBillingSettingListResponse,
} from '@interfaces/funding-plans/fp-billing-setting-list.interface';
import { HttpParams } from '@angular/common/http';
import {
  CFPBillingSettingListParamsDefault,
  EFPBillingSettingListParams,
} from '@parameters/funding-plans/fp-billing-setting-list.parameter';
import { Observable } from 'rxjs';
import { GenericHelper } from '@helpers/generic.helper';
import {
  CFPBillingSettingCreateRequestDefault,
  IFPBillingSettingCreateRequest,
  IFPBillingSettingCreateResponse,
} from '@interfaces/funding-plans/fp-billing-setting-create.interface';
import { EFpBillingSettingCreateRequest } from '@parameters/funding-plans/fp-billing-setting-create.parameter';
import {
  CFPBillingSettingEditRequestDefault,
  IFPBillingSettingEditRequest,
  IFPBillingSettingEditResponse,
} from '@interfaces/funding-plans/fp-billing-setting-edit.interface';
import { EFpBillingSettingEditRequest } from '@parameters/funding-plans/fp-billing-setting-edit.parameter';
import { IFPBillingSettingDeleteResponse } from '@interfaces/funding-plans/fp-billing-setting-delete.interface';

@Injectable()
export class FpBillingSettingClientService {
  constructor(private genericClient: GenericClientService) {}

  /**
   * Get Funding Plan Billing Setting List
   * @returns
   */

  getBillingSettingList(
    fundingPlanId: string,
    queryParams: IFPBillingSettingListParams
  ): Observable<IFPBillingSettingListResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.FPBillingSetting,
      fundingPlanId
    );

    const params = new HttpParams()
      .set(
        EFPBillingSettingListParams.size,
        queryParams[EFPBillingSettingListParams.size] ||
          CFPBillingSettingListParamsDefault[EFPBillingSettingListParams.size]
      )
      .set(
        EFPBillingSettingListParams.page,
        queryParams[EFPBillingSettingListParams.page] ||
          CFPBillingSettingListParamsDefault[EFPBillingSettingListParams.page]
      )
      .set(
        EFPBillingSettingListParams.sort,
        queryParams[EFPBillingSettingListParams.sort]?.join(':') ||
          CFPBillingSettingListParamsDefault[
            EFPBillingSettingListParams.sort
          ].join(':')
      )
      .set(
        EFPBillingSettingListParams.search,
        queryParams[EFPBillingSettingListParams.search] ||
          CFPBillingSettingListParamsDefault[EFPBillingSettingListParams.search]
      );

    return this.genericClient.genericGet<IFPBillingSettingListResponse>(
      endpoint,
      { params }
    );
  }

  createBillingSetting(
    fundingPlanId: string,
    request: IFPBillingSettingCreateRequest
  ): Observable<IFPBillingSettingCreateResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.FPBillingSetting,
      fundingPlanId
    );

    const body: IFPBillingSettingCreateRequest = {
      [EFpBillingSettingCreateRequest.name]:
        request[EFpBillingSettingCreateRequest.name] ||
        CFPBillingSettingCreateRequestDefault[
          EFpBillingSettingCreateRequest.name
        ],
      [EFpBillingSettingCreateRequest.description]:
        request[EFpBillingSettingCreateRequest.description] ||
        CFPBillingSettingCreateRequestDefault[
          EFpBillingSettingCreateRequest.description
        ],
      [EFpBillingSettingCreateRequest.type]:
        request[EFpBillingSettingCreateRequest.type] ||
        CFPBillingSettingCreateRequestDefault[
          EFpBillingSettingCreateRequest.type
        ],
      [EFpBillingSettingCreateRequest.frequency]:
        request[EFpBillingSettingCreateRequest.frequency] ||
        CFPBillingSettingCreateRequestDefault[
          EFpBillingSettingCreateRequest.frequency
        ],
      [EFpBillingSettingCreateRequest.frequencyStart]:
        request[EFpBillingSettingCreateRequest.frequencyStart] ||
        CFPBillingSettingCreateRequestDefault[
          EFpBillingSettingCreateRequest.frequencyStart
        ],
      [EFpBillingSettingCreateRequest.amount]:
        request[EFpBillingSettingCreateRequest.amount] ||
        CFPBillingSettingCreateRequestDefault[
          EFpBillingSettingCreateRequest.amount
        ],
      [EFpBillingSettingCreateRequest.percent]:
        request[EFpBillingSettingCreateRequest.percent] ||
        CFPBillingSettingCreateRequestDefault[
          EFpBillingSettingCreateRequest.percent
        ],
      [EFpBillingSettingCreateRequest.date]:
        request[EFpBillingSettingCreateRequest.date] ||
        CFPBillingSettingCreateRequestDefault[
          EFpBillingSettingCreateRequest.date
        ],
    };

    return this.genericClient.genericPost<IFPBillingSettingCreateResponse>(
      endpoint,
      body
    );
  }

  deleteBillingSetting(
    fundingPlanId: string,
    billingSettingId: string,
    request: any
  ): Observable<IFPBillingSettingDeleteResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.FPBillingSetting,
      fundingPlanId
    );

    return this.genericClient.genericDelete<IFPBillingSettingDeleteResponse>(
      `${endpoint}/${billingSettingId}`,
      { ['body' as any]: request as any }
    );
  }

  updateBillingSetting(
    fundingPlanId: string,
    billingSettingId: string,
    request: IFPBillingSettingEditRequest
  ): Observable<IFPBillingSettingEditResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.FPBillingSetting,
      fundingPlanId
    );

    const body: IFPBillingSettingEditRequest = {
      [EFpBillingSettingEditRequest.name]:
        request[EFpBillingSettingEditRequest.name] ||
        CFPBillingSettingEditRequestDefault[EFpBillingSettingEditRequest.name],
      [EFpBillingSettingEditRequest.description]:
        request[EFpBillingSettingEditRequest.description] ||
        CFPBillingSettingEditRequestDefault[
          EFpBillingSettingEditRequest.description
        ],
      [EFpBillingSettingEditRequest.amount]:
        +request[EFpBillingSettingEditRequest.amount] ||
        CFPBillingSettingEditRequestDefault[
          EFpBillingSettingEditRequest.amount
        ],
      [EFpBillingSettingEditRequest.percent]:
        +request[EFpBillingSettingEditRequest.percent] ||
        CFPBillingSettingEditRequestDefault[
          EFpBillingSettingEditRequest.percent
        ],
      [EFpBillingSettingEditRequest.endDate]:
        request[EFpBillingSettingEditRequest.endDate] ||
        CFPBillingSettingEditRequestDefault[
          EFpBillingSettingEditRequest.endDate
        ],
    };

    return this.genericClient.genericPatch<IFPBillingSettingEditResponse>(
      `${endpoint}/${billingSettingId}`,
      body
    );
  }
}
