import { Injectable } from '@angular/core';
import { GenericClientService } from '@clients/generic/generic-client.service';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { EMerchantListParams } from '@parameters/merchant/merchant/merchant-list.parameter';
import {
  CMerchantListParamsDefault,
  IMerchantListParams,
  IMerchantListResponse,
} from '@interfaces/merchant/merchant-list.interface';

@Injectable()
export class MerchantClientService {
  constructor(private genericClient: GenericClientService) {}

  getMerchantList(
    queryParams: IMerchantListParams
  ): Observable<IMerchantListResponse> {
    const endpoint = EndpointsParameter.Merchant;

    let params = new HttpParams()
      .set(
        EMerchantListParams.sort,
        queryParams[EMerchantListParams.sort]?.join(',') ||
          CMerchantListParamsDefault[EMerchantListParams.sort].join(',')
      )
      .set(
        EMerchantListParams.page,
        queryParams[EMerchantListParams.page] ||
          CMerchantListParamsDefault[EMerchantListParams.page]
      )
      .set(
        EMerchantListParams.size,
        queryParams[EMerchantListParams.size] ||
          CMerchantListParamsDefault[EMerchantListParams.size]
      )
      .set(
        EMerchantListParams.search,
        queryParams[EMerchantListParams.search] ||
          CMerchantListParamsDefault[EMerchantListParams.search]
      )
      .set(
        EMerchantListParams.status,
        queryParams[EMerchantListParams.status].join(',') ||
          CMerchantListParamsDefault[EMerchantListParams.status].join(',')
      )
      .set(
        EMerchantListParams.mids,
        queryParams[EMerchantListParams.mids].join(',') ||
          CMerchantListParamsDefault[EMerchantListParams.mids].join(',')
      )
      .set(
        EMerchantListParams.relationshipIds,
        queryParams[EMerchantListParams.relationshipIds].join(',') ||
          CMerchantListParamsDefault[EMerchantListParams.relationshipIds].join(
            ','
          )
      )
      .set(
        EMerchantListParams.volumeAs,
        queryParams[EMerchantListParams.volumeAs] ||
          CMerchantListParamsDefault[EMerchantListParams.volumeAs]
      );

    if (
      queryParams[EMerchantListParams.volStartDate] &&
      queryParams[EMerchantListParams.volEndDate]
    ) {
      params = params
        .append(
          EMerchantListParams.volStartDate,
          queryParams[EMerchantListParams.volStartDate] ||
            CMerchantListParamsDefault[EMerchantListParams.volStartDate]
        )
        .append(
          EMerchantListParams.volEndDate,
          queryParams[EMerchantListParams.volEndDate] ||
            CMerchantListParamsDefault[EMerchantListParams.volEndDate]
        );
    }
    if (
      queryParams[EMerchantListParams.approvedStartDate] &&
      queryParams[EMerchantListParams.approvedEndDate]
    ) {
      params = params
        .append(
          EMerchantListParams.approvedStartDate,
          queryParams[EMerchantListParams.approvedStartDate] ||
            CMerchantListParamsDefault[EMerchantListParams.approvedStartDate]
        )
        .append(
          EMerchantListParams.approvedEndDate,
          queryParams[EMerchantListParams.approvedEndDate] ||
            CMerchantListParamsDefault[EMerchantListParams.approvedEndDate]
        );
    }

    if (
      queryParams[EMerchantListParams.closedStartDate] &&
      queryParams[EMerchantListParams.closedEndDate]
    ) {
      params = params
        .append(
          EMerchantListParams.closedStartDate,
          queryParams[EMerchantListParams.closedStartDate] ||
            CMerchantListParamsDefault[EMerchantListParams.closedStartDate]
        )
        .append(
          EMerchantListParams.closedEndDate,
          queryParams[EMerchantListParams.closedEndDate] ||
            CMerchantListParamsDefault[EMerchantListParams.closedEndDate]
        );
    }

    return this.genericClient.genericGet<IMerchantListResponse>(endpoint, {
      params,
    });
  }
}
