import { ResponsePagination } from '@models/generic/responses.model';
import { IResponseData } from '@interfaces/generic/responses.interface';

import { IEntityRelationship } from '@interfaces/entity/relationship/entity-relationship.interface';

export class EntityRelationship {
  public id: string;
  public entityId: string;
  public relationshipName: string;
  public relationshipType: string;
  public gettrxAgentCode: string;
  public salesforceId: string;
  public createdOn: string;

  constructor(iEntityRelationship: IEntityRelationship) {
    this.id = iEntityRelationship?.id;
    this.entityId = iEntityRelationship?.entityId;
    this.relationshipName =
      iEntityRelationship?.relationshipName || iEntityRelationship?.id;
    this.relationshipType = iEntityRelationship?.relationshipType;
    this.gettrxAgentCode = iEntityRelationship?.gettrxAgentCode;
    this.salesforceId = iEntityRelationship?.salesforceId;
    this.createdOn = iEntityRelationship?.createdOn;
  }
}

export class EntityRelationshipListResponse {
  pagination: ResponsePagination;
  list: EntityRelationship[];

  constructor(iResponseData: IResponseData<IEntityRelationship>) {
    this.pagination = new ResponsePagination(iResponseData.summary);
    this.list =
      iResponseData.rows && iResponseData.rows?.length
        ? iResponseData.rows.map(
            (iRelationship) => new EntityRelationship(iRelationship)
          )
        : [];
  }
}
