import { IResponseBase } from '@interfaces/generic/responses.interface';
import {
  CMetricsKPIsDisputeParamsDate,
  EMetricsKPIsDisputeParams,
} from '@parameters/metrics/metrics-kpis-dispute.parameter';

export interface IMetricsKPIsDisputeParams {
  [EMetricsKPIsDisputeParams.startDate]: string;
  [EMetricsKPIsDisputeParams.endDate]: string;
  [EMetricsKPIsDisputeParams.midList]: string[];
  [EMetricsKPIsDisputeParams.binList]: string[];
}

export const CMetricsKPIsDisputeParamsDefault: IMetricsKPIsDisputeParams = {
  [EMetricsKPIsDisputeParams.startDate]:
    CMetricsKPIsDisputeParamsDate.value.startDate,
  [EMetricsKPIsDisputeParams.endDate]:
    CMetricsKPIsDisputeParamsDate.value.endDate,
  [EMetricsKPIsDisputeParams.midList]: [],
  [EMetricsKPIsDisputeParams.binList]: [],
};

export interface IMetricsKPIsDisputeRatioValueData {
  count: number;
  volume: number;
  trxRatio?: number;
  volRatio?: number;
}

export interface IMetricsKPIsDisputeRatioValue {
  transaction: IMetricsKPIsDisputeRatioValueData;
  cb: IMetricsKPIsDisputeRatioValueData;
}

export interface IMetricsKPIsDisputeRatio {
  visa: IMetricsKPIsDisputeRatioValue;
  mastercard: IMetricsKPIsDisputeRatioValue;
  discover: IMetricsKPIsDisputeRatioValue;
}

export interface IMetricsKPIsDisputeResponse
  extends IResponseBase<IMetricsKPIsDisputeRatio> {}
