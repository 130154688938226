import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfferBannerComponent } from './offer-banner.component';
import { OfferBannerService } from './services/offer-banner.service';
import { CoreModule } from 'src/app/core/core.module';
import { FlatRateDetailComponent } from './flat-rate-detail.component';
import { CashDiscountDetailComponent } from './cash-discount-detail.component';
import { InterchangePlusDetailComponent } from './interchange-plus-detail.component';
import { TieredDetailComponent } from './tiered-detail.component';
import { TerminalCalculationsComponent } from './terminal-calculations.component';
import { ClientsModule } from '@clients/clients.module';
import { GtrButtonModule } from '@gettrx/core-components-angular';
import { AchCoreFeesDetailComponent } from './ach-core-fees-detail';

@NgModule({
  declarations: [
    OfferBannerComponent,
    FlatRateDetailComponent,
    CashDiscountDetailComponent,
    InterchangePlusDetailComponent,
    TieredDetailComponent,
    TerminalCalculationsComponent,
    AchCoreFeesDetailComponent,
  ],
  exports: [
    OfferBannerComponent,
    FlatRateDetailComponent,
    CashDiscountDetailComponent,
    InterchangePlusDetailComponent,
    TieredDetailComponent,
    TerminalCalculationsComponent,
    AchCoreFeesDetailComponent,
  ],
  imports: [CommonModule, CoreModule, ClientsModule, GtrButtonModule],
  providers: [OfferBannerService],
})
export class OfferBannerModule {}
