import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

import { GenericClientService } from '@clients/generic/generic-client.service';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import { EProspectsListParams } from '@parameters/prospects/prospects-list.parameter';
import {
  CProspectsListParamsDefault,
  IProspectsListParams,
  IProspectsListResponse,
} from '@interfaces/prospects/prospects-list.interface';
import {
  IProspectCreateRequest,
  IProspectCreateResponse,
} from '@interfaces/prospects/prospect-create.interface';

@Injectable()
export class ProspectClientService {
  private endpoint = EndpointsParameter.Prospect;

  constructor(private genericClient: GenericClientService) {}

  /**
   * Funding Plan List
   * @param queryParams
   * @returns
   */

  getProspectsList(
    queryParams: IProspectsListParams
  ): Observable<IProspectsListResponse> {
    let httpParams = new HttpParams()
      .set(
        EProspectsListParams.status,
        queryParams[EProspectsListParams.status]?.join(',') ||
          CProspectsListParamsDefault[EProspectsListParams.status].join(',')
      )
      .set(
        EProspectsListParams.limit,
        queryParams[EProspectsListParams.limit] ||
          CProspectsListParamsDefault[EProspectsListParams.limit]
      )
      .set(
        EProspectsListParams.page,
        queryParams[EProspectsListParams.page] ||
          CProspectsListParamsDefault[EProspectsListParams.page]
      )
      .set(
        EProspectsListParams.sort,
        queryParams[EProspectsListParams.sort] ||
          CProspectsListParamsDefault[EProspectsListParams.sort]
      )
      .set(
        EProspectsListParams.search,
        queryParams[EProspectsListParams.search] ||
          CProspectsListParamsDefault[EProspectsListParams.search]
      )
      .set(
        EProspectsListParams.relationshipId,
        queryParams[EProspectsListParams.relationshipId]?.join(',') ||
          CProspectsListParamsDefault[EProspectsListParams.relationshipId]?.join(',')
      );

    if (
      !!queryParams[EProspectsListParams.startDate] &&
      !!queryParams[EProspectsListParams.endDate]
    ) {
      httpParams = httpParams
        .append(
          EProspectsListParams.startDate,
          queryParams[EProspectsListParams.startDate] ||
            CProspectsListParamsDefault[EProspectsListParams.startDate]
        )
        .append(
          EProspectsListParams.endDate,
          queryParams[EProspectsListParams.endDate] ||
            CProspectsListParamsDefault[EProspectsListParams.endDate]
        );
    }

    return this.genericClient.genericGet<IProspectsListResponse>(
      this.endpoint,
      { params: httpParams }
    );
  }

  /**
   * Add or create new prospect
   * @param params request parameter for create prospect
   * @returns <IProspectCreateResponse> respose from server.
   */
  createProspect(
    params: IProspectCreateRequest
  ): Observable<IProspectCreateResponse> {
    return this.genericClient.genericPost<IProspectCreateResponse>(
      this.endpoint,
      params
    );
  }
}
