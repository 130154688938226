import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import { ENumericInputType } from '@parameters/merchant-management-system/mms-detail.enum';

@Directive({
  selector: '[appNumericInputFormatter]',
})
export class NumericInputFormatDirective implements OnInit {
  @Input() digitsAfterDecimal: number = 2;
  @Input() numericType: string = ENumericInputType.Currency;
  constructor(private el: ElementRef<HTMLInputElement>) {}

  ngOnInit() {
    // Format the initial value when the directive is initialized.
    this.formatValue();
  }

  @HostListener('blur', ['$event.target.value'])
  public onInput(value: string) {
    // Format the value whenever the user types in the input field.
    this.formatValue();
  }

  private formatValue() {
    const value = this.el.nativeElement.value;
    const parsedValue = parseFloat(value);
    if (!isNaN(parsedValue)) {
      const percentValue = String(parsedValue)?.replace(/[^0-9.]/g, '');
      const parts = percentValue?.split('.');
      if (!parts[1] || parts[1]?.length < 3) {
        this.digitsAfterDecimal = 2;
      } else {
        this.digitsAfterDecimal = parts[1]?.length || 2;
      }
      this.el.nativeElement.value = parsedValue.toFixed(
        this.digitsAfterDecimal
      );
    }
  }
}
