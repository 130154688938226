import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import {
  APP_ROUTES,
  EOnboardingPageId,
  onboardingSteps,
} from 'src/app/shared/helpers/routes.enum';
import { CommonService } from 'src/app/shared/services/common.service';
import { HttpsService } from 'src/app/shared/services/base.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { noWhitespaceValidator } from 'src/app/shared/helpers/validators';
import { constants } from 'src/app/shared/helpers/constants';
import { OnboardingCustomizationService } from '../../services/onboarding-customization.service';

export enum EGatewayTitles {
  notUsingCurrently = 'Not using one currently',
}
@Component({
  selector: 'app-payment-gateways',
  templateUrl: './payment-gateways.component.html',
  styleUrls: ['./payment-gateways.component.scss'],
})
export class PaymentGatewaysComponent implements OnInit {
  isOtherSelected: boolean = false;
  id: any;
  otherTextBox: string = '';
  isOptionselected: boolean = false;
  loading: boolean = false;
  paymentGatewaysList: any = [];
  paymentGatewayName = new UntypedFormControl('');
  step: number = onboardingSteps.payment_gateway;
  paymentOnline: any[] = [];
  constants = constants;
  selectedItem: any;
  constructor(
    public cmService: CommonService,
    private http: HttpsService,
    private onboardingCustomizationService: OnboardingCustomizationService,
    private cd: ChangeDetectorRef,
    private ngZone: NgZone
  ) {
    this.cmService.progressBar = this.step;
    this.paymentGatewayName.addValidators([
      Validators.required,
      noWhitespaceValidator,
      Validators.pattern(constants.alpha_numeric),
    ]);
    this.getMerchantDetail();
  }

  ngOnInit(): void {}

  getMerchantDetail() {
    this.onboardingCustomizationService
      .getOnboardingBusinessDetail()
      .subscribe((res: any) => {
        if (res.success) {
          let data = res?.data;
          this.paymentOnline = data?.onlinePaymentMethods;
          if (data?.paymentGateway[0]) {
            const selectedItem = data?.paymentGateway[0];
            this.selectedItem = selectedItem?.paymentGateway;
            this.id = selectedItem?.paymentGatewayId;
            selectedItem && (this.isOptionselected = true);
            if (selectedItem?.paymentGateway?.decription == 'other') {
              this.otherTextBox = selectedItem?.name;
              this.isOtherSelected = true;
              this.paymentGatewayName.patchValue(this.otherTextBox);
            }
          }
        }
        this.getPaymentGatewaysList();
      });
  }

  getPaymentGatewaysList() {
    this.http.httpGet('getPaymentGatewayPlugin').subscribe((res: any) => {
      if (res.success) {
        let data = res?.data;
        this.paymentGatewaysList = data;
        this.paymentGatewaysList.forEach((element) => {
          element.imageUrl =
            'https://d16ah8wr3b1l39.cloudfront.net/' + element?.logo;
        });
      }
    });
  }

  goBack() {
    this.cmService.progressDec(APP_ROUTES.select_hardware);
  }

  selectGateway(item: any) {
    this.selectedItem = item;
    if (String(item.decription).toLowerCase().trim() == 'other') {
      this.isOtherSelected = true;
    } else {
      this.isOtherSelected = false;
    }
    this.otherTextBox = '';
    this.paymentGatewayName.reset();
    this.id = item?.id;
    this.isOptionselected = true;
  }

  submit() {
    this.otherTextBox = this.paymentGatewayName.value || '';
    if (this.isOtherSelected && this.paymentGatewayName.invalid) {
      this.paymentGatewayName.markAsTouched();
    } else {
      this.postSelectedPaymentGateway();
    }
  }

  postSelectedPaymentGateway() {
    let obj = {
      id: this.id,
      name: this.otherTextBox,
    };
    let input = {
      idsPaymentGateWay: [obj],
      currentPageId: EOnboardingPageId.payment_gateway,
    };
    this.loading = true;
    this.http.httpPut('savePaymentGatewayPlugin', input).subscribe(
      (res: any) => {
        this.loading = false;
        if (res.success) {
          this.otherTextBox = '';
          /**
           * for next step after payment gateway
           */
          this.onboardingCustomizationService.setPaymentGatewaySelectedTitle =
            this.selectedItem.logoTitle; // set selected gateway title
          this.onboardingCustomizationService.verifySelectedPaymentGateway(); // verify logics for next step
          this.cmService.progressInc(APP_ROUTES.has_existing_gateway_account);
        } else {
          this.cmService.error(
            this.cmService.capitalizeFirstLetter(res.message)
          );
        }
      },
      (error) => {
        this.ngZone.run(() => {
          this.loading = false;
        });
        this.cd.detectChanges();
      }
    );
  }
}
