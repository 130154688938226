import { environment } from '@environments/environment';
import { ProxyGateway } from './api-proxy-gateway';

const API_BASE = `${ProxyGateway.loadBaseApiUrl()}`;
const MOCK3_BASE = `${environment.mock3Url}`;
const PAYMENT_API_BASE = `${environment.paymentApiBaseUrl}`;
const IAM_API_BASE = `${environment.iamApiBaseUrl}`;

export class EndpointsParameter {
  /** Merchant MMS */
  public static MMSSearchMerchant = `${API_BASE}v1/mms/search-merchant`;
  public static MMSSearchV2Merchant = `${API_BASE}v1/mms/search-merchant-v2`;
  public static MMSMerchant = `${API_BASE}v1/mms/merchant/`;
  public static MMSMerchants = `${API_BASE}v1/mms/merchants/`;
  public static MMSAgents = `${API_BASE}v1/mms/merchants/agents`;
  public static MMSAgentSearch = `${API_BASE}v1/mms/search-agent`;
  public static MMSEquipments = `${API_BASE}v1/mms/merchants/equipments`;
  public static MMSPayfacInfo = `${API_BASE}v1/mms/merchants/payfac-methods-info`;

  /** MERCHANT */
  public static Merchant = `${API_BASE}v1/merchants`;
  public static MyMerchant = `${API_BASE}v1/my-merchants`;

  /** FUNDING PLAN */
  public static FundingPlan = `${API_BASE}v1/funding-plans`;
  public static FPBillingSetting = `${API_BASE}v1/funding-plans/??/billing-settings`;
  public static FPOnboardingTemplate = `${API_BASE}v1/funding-plans/??/onboarding-templates`;
  public static FPChangeLog = `${API_BASE}v1/funding-plans/??/change-logs`;
  public static FPEnrolledMerchant = `${API_BASE}v1/funding-plans/??/enrolled-merchants`;
  public static FPAllowedMerchants = `${API_BASE}v1/funding-plans/??/allowed-merchants`;
  public static FPAllowedOnboardingTemplates = `${API_BASE}v1/funding-plans/??/allowed-templates`;
  public static FPDeleteEnrolledMerchant = `${API_BASE}v1/funding-plans/??/leave-merchants`;

  /** PROSPECT */
  public static Prospect = `${API_BASE}v1/prospects`;
  public static ProspectKpis = `${API_BASE}kpis/prospects`;
  public static ProspectGetAllEquipmentTerminal = `${API_BASE}v1/merchants/??/equipments`;
  public static ProspectGetAllVirtualGateways = `${API_BASE}v1/merchants/??/payment-gateway-plugins`;
  public static ProspectEquipmentTerminal = `${API_BASE}v1/prospects/??/equipments`;
  public static ProspectVirtualGateways = `${API_BASE}v1/prospects/??/virtual-gateways`;
  public static ProspectTerminalCalculation = `${API_BASE}v1/prospects/??/terminal-final-cost`;
  public static ProspectRequiredDocument = `${API_BASE}v1/application/??/pending-documents`;
  public static ProspectRequiredInformationDocument = `${API_BASE}v1/application/??/information-requests`;
  public static ProspectDocumentUploads = `${API_BASE}v1/uploads/??`;
  public static ProspectUploadDocuments = `${API_BASE}v1/uploads/??/required-docs`;
  public static NewProspectUploadDocuments = `${API_BASE}v1/application/??/information-requests/???/uploads`;
  public static ProspectSendOffer = `${API_BASE}v1/prospects/??/send-offer`;
  public static ProspectRevertSignature = `${API_BASE}v1/prospects/??/agreement-signatures`;
  public static ProspectAttachExistingPlan = `${API_BASE}v1/prospects/??/attach-existing-pricing-plan`;
  public static ProspectOnboardingCustomizations = `${API_BASE}v1/prospects/??/onboarding-customizations`;
  public static ProspectOnboardingConfigurationEquipments = `${API_BASE}v1/agent/??/equipments`;
  public static ProspectInformationSendResponse = `${API_BASE}v1/application/??/information-requests/???/responses`;

  /** REPORTS */
  public static ReportsVolume = `${API_BASE}v1/volumes`;
  public static ReportsDispute = `${API_BASE}v1/disputes`;
  public static ReportsDisputeDocuments = `${API_BASE}v1/disputes/??/documents`;
  public static ReportsAuths = `${API_BASE}v1/authorizations`;
  public static ReportsAuthsV2 = `${API_BASE}v1/authorizations_v2`;
  public static ReportsBatch = `${API_BASE}v1/batches`;
  public static ReportsResidual = `${API_BASE}v1/residuals`;
  public static ReportsResidualCSV = `${API_BASE}v1/residuals/csv`;
  public static ReportsStatement = `${API_BASE}v1/statement`;
  public static ReportsVolumeCSV = `${API_BASE}v1/volumes/csv`;
  public static ReportsDisputesCSV = `${API_BASE}v1/disputes/csv`;
  public static ReportsAuthCSV = `${API_BASE}v1/authorizations_v2/csv`;
  public static ReportsBatchCSV = `${API_BASE}v1/batches/csv`;
  public static ReportsBatchDetailCSV = `${API_BASE}v1/batches/??/csv`;

  public static DepositsSettlementsSummary = `${API_BASE}v1/deposits/list`;
  public static DepositsSettlementsDetail = `${API_BASE}v1/deposits/detail/??`;
  public static DepositsCSV = `${API_BASE}v1/deposits/csv`;
  public static DepositsDetailCSV = `${API_BASE}v1/deposits/detail/csv`;

  /** Entity */
  public static EntityRelationshipList = `${API_BASE}v1/entity/relationships`;
  public static EntityMerchantList = `${API_BASE}v1/entity/merchants`;
  public static EntityList = `${API_BASE}v1/entity/list`;
  public static Domains = `${API_BASE}v1/entity/domains`;

  /** Metrics */
  public static MetricsKPIsProcessing = `${API_BASE}v1/metrics/kpis/processing`;
  public static MetricsKPIsProspect = `${API_BASE}v1/metrics/kpis/prospects`;
  public static MetricsKPIsMerchant = `${API_BASE}v1/metrics/kpis/merchants`;
  public static MetricsKPIsDisputes = `${API_BASE}v1/metrics/kpis/disputes`;
  public static MetricsKPIsVolumes = `${API_BASE}v1/metrics/kpis/volumes`;
  public static MetricsKPIsDeal = `${API_BASE}v1/metrics/kpis/deals`;

  /** METRICS CHART */
  public static MetricsChartsNetVolume = `${API_BASE}v1/metrics/charts/net-volume`;
  public static MetricsChartsNetDeposits = `${MOCK3_BASE}v1/metrics/charts/net-deposits`;
  public static MetricsChartsSettledTransactionsCount = `${API_BASE}v1/metrics/charts/settled-transactions-count`;
  public static MetricsChartsDisputedVolume = `${API_BASE}v1/metrics/charts/disputed-volume`;
  public static MetricsChartsCardBrandVolume = `${API_BASE}v1/metrics/charts/cardbrand-volume`;

  /** ONBOARDING ROUTES */
  public static OnboardingConfiguration = `${API_BASE}v1/merchants/??/onboarding-configurations`;
  // public static OnboardingBusinessDetail = `${API_BASE}v1/merchant/bussiness`;

  public static OnboardingBusinessDetail = `${API_BASE}v1/merchants/??`;

  /** ONBOARDING TEMPLATE */
  public static OnboardingTemplate = `${API_BASE}v1/onboarding-templates`;
  public static OnboardingAllowedDomains = `${API_BASE}v1/onboarding-templates/??/allowed-domains`;
  public static OnboardingPricingPlan = `${API_BASE}v1/onboarding-templates/??/pricing-plans`;
  public static OnboardingTemplateOnboardingCustomizations = `${API_BASE}v1/onboarding-templates/??/onboarding-customizations`;
  public static OnboardingTemplateEquipmentsList = `${API_BASE}v1/onboarding-templates/??/equipments`;

  /* OPS & UW TOOLS */
  public static Users = `${API_BASE}v1/users`;
  public static UsersCreate = `${API_BASE}v1/users/create`;
  public static UserDetail = `${API_BASE}v1/users/??`;

  public static Deal = `${API_BASE}v1/deals`;
  public static ValidateMid = `${API_BASE}ops/underwriting/retrieve-account-info`;
  public static SubmitToAex = `${API_BASE}ops/underwriting/submit-to-aex`;
  public static SubmitToTsys = `${API_BASE}ops/tsys-express/submit-to-tsys`;

  public static ImportMerchantFromSF = `${API_BASE}ops/import/merchant/??`;
  public static ImportDealFromSF = `${API_BASE}ops/import/deal/??`;

  public static DealDetails = `${API_BASE}v1/deals/??`;
  public static DealKycInquiryDetails = `${API_BASE}v1/deals/??/inquiries/??`;
  public static DealKycInquiry = `${API_BASE}v1/deals/??/inquiries`;
  public static DealReRunKycInquiry = `${API_BASE}v1/deals/??/inquiries`;
  public static DealDownloadKycInquiry = `${API_BASE}v1/deals/??/inquiries/??/reports/??`;

  public static PathBoardingConfiguration = `${API_BASE}v1/merchants/??/boarding-and-deployment-configurations`;
  public static PathDeploymentConfiguration = `${API_BASE}v1/merchants/??/deployment-configurations`;
  public static PathRequestAdditionalDocuments = `${API_BASE}v1/application/??/uploads/underwriting`;
  public static GetPendingDocuments = `${API_BASE}v1/application/??/pending-documents`;
  public static ApplicationSendResponse = `${API_BASE}v1/application/??/information-requests/???/responses`;

  public static AutomationsList = `${API_BASE}v1/automation-tasks/??`;
  public static AutomationAction = `${API_BASE}v1/automation-tasks/??/action`;
  public static CreateVarSheet = `${API_BASE}v1/deals/??/varsheet`;
  public static VarSheetList = `${API_BASE}v1/deals/??/varsheet`;
  public static DeleteVarSheet = `${API_BASE}v1/deals/??/varsheet/??`;
  public static SendVarSheet = `${API_BASE}v1/deals/??/varsheet/??`;

  public static DealAllowedStatus = `${API_BASE}v1/deals/??/allowed-status-transitions`;

  public static DealRequiredDocument = `${API_BASE}v1/application/??/deals/pending-documents`;
  public static DealDocumentUploads = `${API_BASE}v1/uploads/deals/??`;
  public static DealUploadDocuments = `${API_BASE}v1/uploads/??/deals/required-docs`;
  public static NewDealUploadDocuments = `${API_BASE}v1/application/??/information-requests/???/uploads`;
  public static DealInformationRequests = `${API_BASE}v1/application/??/deals/information-requests`;
  public static DealInfoRequestSendResponse = `${API_BASE}v1/application/??/deals/information-requests/???/responses`;

  public static ApplicationUploadDocuments = `${API_BASE}v1/uploads/??/merchant/required-docs`;
  public static NewApplicationUploadDocuments = `${API_BASE}v1/application/??/information-requests/???/uploads`;

  public static DealEquipmentTerminal = `${API_BASE}v1/merchants/??/equipments`;
  public static DealVirtualGateways = `${API_BASE}v1/merchants/??/payment-gateway-plugins`;
  public static DealSelectedEquipmentTerminal = `${API_BASE}v1/deals/??/equipments`;
  public static DealSelectedVirtualGateways = `${API_BASE}v1/deals/??/virtual-gateways`;
  public static DealTerminalCalculation = `${API_BASE}v1/deals/??/terminal-final-cost`;
  public static DealOnboardingCustomizations = `${API_BASE}v1/deals/??/onboarding-customizations`;
  public static DealOfferPricing = `${API_BASE}v1/deals/??/pricing-plans`;
  public static DealNotes = `${API_BASE}v1/deals/??/notes`;
  public static UpdateDealInfo = `${API_BASE}v1/application/??/information-requests/???/status`;

  public static UsersList = `${API_BASE}v1/users`;

  public static ManageEntities = `${API_BASE}v1/entities`;
  public static ManageEntitiesKpis = `${API_BASE}v1/entities/kpis`;
  public static ManageEntitiesUser = `${API_BASE}v1/entities/??/associated-users`;
  public static ManageEntitiesRelationship = `${API_BASE}v1/entities/??/relationships`;
  public static ManageEntitiesMerchants = `${API_BASE}v1/entities/??/merchants`;
  public static ManageEntitiesBrands = `${API_BASE}v1/entities/??/domains`;
  public static ManageEntitiesOnboardingDefault = `${API_BASE}v1/entities/??/onboarding-default-configs`; //onboarding-deafult-configs
  public static ManageEntitiesOnboardingTemplates = `${API_BASE}v1/entities/??/onboarding-templates`;

  /* Traninig */
  public static TrainingVideos = `${API_BASE}v1/resources/videos`;
  public static TrainingVideoDetail = `${API_BASE}v1/resources/videos/??`;
  public static TrainingVideoTags = `${API_BASE}v1/resources/video-tags`;
  public static TrainingSections = `${API_BASE}v1/training/sections`;

  /* Core */
  public static PostS3Image = `${API_BASE}v1/uploads/??/s3-signed-url`;
  public static CoreStates = `${API_BASE}v1/core/states`;
  public static CoreCountries = `${API_BASE}v1/core/countries`;
  public static CoreStandardFees = `${API_BASE}v1/core/standard-fees`;
  public static CorePricingMinimumValues = `${API_BASE}v1/core/pricing-program-minimum-values`;
  public static CoreEquipmentsForOnboardingCustomizations = `${API_BASE}v1/core/equipments-for-onboarding-customizations`;

  /* Risk Analysis */
  public static RiskEvents = `${API_BASE}v1/risks/event-summaries`;
  public static RiskEventsDetail = `${API_BASE}v1/risks/event-summaries/??`;
  public static RiskEventDetailSettleAlert = `${API_BASE}v1/risks/event-summaries/??/settlement-alerts`;
  public static RiskEventDetailSettleAlertDisposition = `${API_BASE}v1/risks/settlement-alerts`;
  public static SettlementAlerts = `${API_BASE}v1/risks/settlement-alerts`;
  public static SettlementAlertsDisposition = `${API_BASE}v1/risks/settlement-alerts`;
  public static StatsDeposits = `${API_BASE}v1/risks/daily-stats/deposits`;
  public static StatsCredits = `${API_BASE}v1/risks/daily-stats/credits`;
  public static StatsChargebacks = `${API_BASE}v1/risks/risk-analysis/stats-chargebacks`;
  public static StatsSales = `${API_BASE}v1/risks/daily-stats/sales`;

  /* Payments */
  /* Create payments */
  public static PaymentAccounts = `${API_BASE}v1/users/gateway-accounts`;
  public static PaymentAccountAuthentication = `${API_BASE}v1/auth/gateway-accounts/??`;
  public static PaymentAuthToken = `${API_BASE}v1/auth/payment-auth-token`;
  public static Payments = `${PAYMENT_API_BASE}v1/accounts/??/payments`;
  public static RetryPayment = `${PAYMENT_API_BASE}v1/accounts/??/payments`;
  public static PaymentDetail = `${PAYMENT_API_BASE}v1/accounts/??/payments/???`;
  public static PaymentCapture = `${PAYMENT_API_BASE}v1/accounts/??/payments/???/capture`;
  public static PaymentRefundableAmount = `${PAYMENT_API_BASE}v1/accounts/??/payments/???/refundable-amount`;
  public static PaymentList = `${PAYMENT_API_BASE}v1/accounts/??/payments`;
  public static PaymentMethods = `${PAYMENT_API_BASE}v1/accounts/??/customers/???/payment-methods`;
  public static PaymentMethodIntent = `${PAYMENT_API_BASE}v1/accounts/??/payments/setup-requests`;
  public static ProcessRefund = `${PAYMENT_API_BASE}v1/accounts/??/payments/???/refunds`;
  public static PaymentReceipt = `${PAYMENT_API_BASE}v1/accounts/??/payments/???/email-receipt`;
  public static TransactionList = `${PAYMENT_API_BASE}v1/accounts/??/transactions`;
  public static PaymentDomains = `${PAYMENT_API_BASE}v1/accounts/??/settings/payment-method-domains`;
  public static PaymentDomainVerify = `${PAYMENT_API_BASE}v1/accounts/??/settings/payment-method-domains/???/verify`;
  public static PaymentMethodSettings = `${PAYMENT_API_BASE}v1/accounts/??/settings/payment-methods`;

  /* Customers */
  public static Customer = `${PAYMENT_API_BASE}v1/accounts/??/customers`;
  public static CustomerOperationsWithId = `${PAYMENT_API_BASE}v1/accounts/??/customers/???`;
  /* Links */
  public static PaymentLink = `${PAYMENT_API_BASE}v1/accounts/??/payment-link`;

  // transfers
  public static PaymentTransfers = `${PAYMENT_API_BASE}v1/accounts/??/transfers`;

  // USERS
  public static MerchantUsersList = `${API_BASE}v1/users/merchants`;
  public static UserSendWelcomeEmail = `${API_BASE}v1/users/???/??/send-welcome-email`;
  public static UserSendPasswordEmail = `${API_BASE}v1/users/???/??/send-reset-password-email`;
  public static MerchantUserAssociatedAccount = `${API_BASE}v1/users/merchants/??/associated-accounts`;
  public static PartnerUserAssociatedAccount = `${API_BASE}v1/users/partners/??/associated-accounts`;
  public static PartnerUserAssociatedBins = `${API_BASE}v1/users/partners/??/associated-bins`;
  public static PartnerUsersList = `${API_BASE}v1/users/partners`;
  public static InternalUsersList = `${API_BASE}v1/users/internal`;
  public static AllAssociatedMerchants = `${API_BASE}v1/merchants/list-for-associated-accounts`;
  public static PartnerEligibleDomains = `${API_BASE}v1/users/partners/??/eligible-domains`;
  public static PartnerMyAccounts = `${API_BASE}v1/users/my-accounts`;

  public static ApiKeys = `${IAM_API_BASE}v1/accounts/??/api-keys`;
  public static ApiKeysRoll = `${IAM_API_BASE}v1/accounts/??/api-keys/???/roll`;

  // ACCOUNTS
  public static Accounts = `${API_BASE}v1/accounts`;
  public static AccountMerchants = `${API_BASE}v1/accounts/??/merchants`;
  public static AccountGatewayConfiguration = `${API_BASE}v1/accounts/??/gateway-configurations`;

  // equipments
  public static Equipments = `${API_BASE}v1/settings/agents/??/equipments`;
  public static PublicPostS3Image = `${API_BASE}v1/core/??/s3-signed-public-url`;
  public static EquipmentDetails = `${API_BASE}v1/settings/agents/??/equipments`;
  public static EquipmentLinkedTerminals = `${API_BASE}v1/settings/agents/??/equipments/???/linked-terminals`;
  public static EquipmentLinkedAccessories = `${API_BASE}v1/settings/agents/??/equipments/???/linked-accessories`;
  public static EquipmentPricingList = `${API_BASE}v1/settings/agents/??/equipments/???/prices`;
  public static EquipmentLinkTerminal = `${API_BASE}v1/settings/agents/??/equipments/???/linked-terminals`;
  public static EquipmentLinkAccessory = `${API_BASE}v1/settings/agents/??/equipments/???/linked-accessories`;
  public static EquipmentUnLinkItem = `${API_BASE}v1/settings/agents/??/equipments/???/????/link`;
  public static EquipmentCloneItem = `${API_BASE}v1/settings/agents/??/equipments/???/clone`;

  // WebHooks
  public static Webhook = `${IAM_API_BASE}v1/accounts/??/webhook-subscriptions`;
  public static WebhookEdition = `${IAM_API_BASE}v1/accounts/??/webhook-subscriptions`;
  public static WebhookEvents = `${IAM_API_BASE}v1/accounts/??/webhook-subscriptions/???/event-attempts`;
  public static WebhookExpireSigningKey = `${IAM_API_BASE}v1/accounts/??/webhook-subscriptions/???/signing-keys/????/expire`;
  public static WebhookReveal = `${IAM_API_BASE}v1/accounts/??/webhook-subscriptions/???/signing-keys/????/reveal`;
  public static WebhookEventAttempt = `${IAM_API_BASE}v1/accounts/??/webhook-subscriptions/???/webhook-events/????/webhook-attempts`;
}
