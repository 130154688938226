import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericClientService } from '@clients/generic/generic-client.service';
import { IResponseBase } from '@interfaces/generic/responses.interface';
import {
  CTrainingVideoListParamsDefault,
  ITrainingVideoListParams,
  IVideoListResponse,
} from '@interfaces/training/training-video-list.interface';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import { ETrainingVideoListParams } from '@parameters/resources/training/training-video.parameter';
import { Observable } from 'rxjs';
import {
  EDocumentTypes,
  ITrainingSection,
} from '../../../../business/portal/resources/training/views/training-home/training-home.types';
import { IVideoTagListResponse } from '@interfaces/training/training-video-tag-list.interface';
import { GenericHelper } from '@helpers/generic.helper';
import { IVideoDetailResponse } from '@interfaces/training/training-video-detail.interface';

@Injectable()
export class TrainingClientService {
  constructor(private genericClient: GenericClientService) {}

  getVideoList(
    queryParams: ITrainingVideoListParams
  ): Observable<IVideoListResponse> {
    const endpoint = EndpointsParameter.TrainingVideos;
    let params = new HttpParams()
      .set(
        ETrainingVideoListParams.page,
        queryParams[ETrainingVideoListParams.page] ||
          CTrainingVideoListParamsDefault[ETrainingVideoListParams.page]
      )
      .set(
        ETrainingVideoListParams.size,
        queryParams[ETrainingVideoListParams.size] ||
          CTrainingVideoListParamsDefault[ETrainingVideoListParams.size]
      )
      .set(
        ETrainingVideoListParams.search,
        queryParams[ETrainingVideoListParams.search] ||
          CTrainingVideoListParamsDefault[ETrainingVideoListParams.search]
      )
      .set(
        ETrainingVideoListParams.tags,
        queryParams[ETrainingVideoListParams.tags]?.join(',') ||
          CTrainingVideoListParamsDefault[ETrainingVideoListParams.tags].join(
            ','
          )
      );

    return this.genericClient.genericGet<IVideoListResponse>(endpoint, {
      params,
    });
  }

  getVideoTagList(): Observable<IVideoTagListResponse> {
    const endpoint = EndpointsParameter.TrainingVideoTags;
    return this.genericClient.genericGet<IVideoTagListResponse>(endpoint);
  }

  getVideoDetail(id: string): Observable<IVideoDetailResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.TrainingVideoDetail,
      id
    );
    return this.genericClient.genericGet<IVideoDetailResponse>(endpoint);
  }

  getSectionList(): Observable<IResponseBase<ITrainingSection[]>> {
    //const endpoint = EndpointsParameter.TrainingSections;

    return new Observable<IResponseBase<ITrainingSection[]>>((observer) => {
      observer.next({
        code: 200,
        message: 'OK',
        success: true,
        data: SECTIONS,
      });
      observer.complete();
    });
    // return this.genericClient.genericGet(endpoint);
  }
}

const SECTIONS = [
  {
    title: 'Section #1',
    documents: [
      {
        title: 'Product requirement .excel',
        date: 'June 14, 2023',
        type: EDocumentTypes.Excel,
        id: Math.floor(Math.random() * 100),
      },
      {
        title: 'Product requirement .pdf',
        date: 'June 14, 2023',
        type: EDocumentTypes.Pdf,
        id: Math.floor(Math.random() * 100),
      },
      {
        title: 'Product requirement .pdf',
        date: 'June 14, 2023',
        type: EDocumentTypes.Jpg,
        id: Math.floor(Math.random() * 100),
      },
      {
        title: 'Product requirement .pdf',
        date: 'June 14, 2023',
        type: EDocumentTypes.Png,
        id: Math.floor(Math.random() * 100),
      },
      {
        title: 'Product requirement .pdf',
        date: 'June 14, 2023',
        type: EDocumentTypes.Excel,
        id: Math.floor(Math.random() * 100),
      },
      {
        title: 'Product requirement .pdf',
        date: 'June 14, 2023',
        type: EDocumentTypes.Jpg,
        id: Math.floor(Math.random() * 100),
      },
    ],
  },
  {
    title: 'Section #2',
    documents: [
      {
        title: 'Product requirement .pdf',
        date: 'June 14, 2023',
        type: EDocumentTypes.Excel,
        id: Math.floor(Math.random() * 100),
      },
      {
        title: 'Product requirement .pdf',
        date: 'June 14, 2023',
        type: EDocumentTypes.Pdf,
        id: Math.floor(Math.random() * 100),
      },
      {
        title: 'Product requirement .pdf',
        date: 'June 14, 2023',
        type: EDocumentTypes.Excel,
        id: Math.floor(Math.random() * 100),
      },
      {
        title: 'Product requirement .pdf',
        date: 'June 14, 2023',
        type: EDocumentTypes.Png,
        id: Math.floor(Math.random() * 100),
      },
      {
        title: 'Product requirement .pdf',
        date: 'June 14, 2023',
        type: EDocumentTypes.Excel,
        id: Math.floor(Math.random() * 100),
      },
      {
        title: 'Product requirement .pdf',
        date: 'June 14, 2023',
        type: EDocumentTypes.Excel,
        id: Math.floor(Math.random() * 100),
      },
    ],
  },
  {
    title: 'Section #3',
    documents: [
      {
        title: 'Product requirement .pdf',
        date: 'June 14, 2023',
        type: EDocumentTypes.Excel,
        id: Math.floor(Math.random() * 100),
      },
      {
        title: 'Product requirement .pdf',
        date: 'June 14, 2023',
        type: EDocumentTypes.Jpg,
        id: Math.floor(Math.random() * 100),
      },
      {
        title: 'Product requirement .pdf',
        date: 'June 14, 2023',
        type: EDocumentTypes.Excel,
        id: Math.floor(Math.random() * 100),
      },
      {
        title: 'Product requirement .pdf',
        date: 'June 14, 2023',
        type: EDocumentTypes.Png,
        id: Math.floor(Math.random() * 100),
      },
      {
        title: 'Product requirement .pdf',
        date: 'June 14, 2023',
        type: EDocumentTypes.Excel,
        id: Math.floor(Math.random() * 100),
      },
      {
        title: 'Product requirement .pdf',
        date: 'June 14, 2023',
        type: EDocumentTypes.Excel,
        id: Math.floor(Math.random() * 100),
      },
    ],
  },
];
