import { IAccountMerchantsListParams } from '@interfaces/accounts/account-merchants-list.interface';

export enum EAccountMerchantsListParams {
  page = 'page',
  size = 'limit',
  search = 'search',
  sort = 'order_by',
}

export const CAccountMerchantsListParams: IAccountMerchantsListParams = {
  [EAccountMerchantsListParams.page]: 1,
  [EAccountMerchantsListParams.size]: 10,
  [EAccountMerchantsListParams.search]: '',
  [EAccountMerchantsListParams.sort]: [],
};

export enum EAccountMerchantListLabels {
  merchantDbaName = 'merchant dba name',
  merchantLegalName = 'merchant legal name',
  mid = 'mid',
  actions = 'actions',
}

export enum EAccountMerchantListSortKeys {
  legalNameAsc = 'legal_name:asc',
  legalNameDesc = 'legal_name:desc',
  midAsc = 'mid:asc',
  midDesc = 'mid:desc',
  dbaNameAsc = 'dba_name:asc',
  dbaNameDesc = 'dba_name:desc',
  createdDateAsc = 'created_date:asc',
  createdDateDesc = 'created_date:desc',
}
