import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { EPaymentGateway } from '@parameters/filter/payment-gateway.parameter';

//OCE: Onboarding Customization Edit

export enum EOCEOnlinePhonePaymentGatewayFormControlName {
  optionList = 'optionList',
  hidden = 'hidden',
  active = 'active',
  additionalInformation = 'additionalInformation',
}

export enum EOCEOnlinePhonePaymentGatewayOptionFormControlName {
  id = 'id',
  name = 'name',
  predefined = 'predefined',
  preselected = 'preselected',
}

export interface IOCEOnlinePhonePaymentGatewayFormValue {
  [EOCEOnlinePhonePaymentGatewayFormControlName.optionList]: IOCEOnlinePhonePaymentGatewayOptionFormValue[];
  [EOCEOnlinePhonePaymentGatewayFormControlName.hidden]: boolean;
  [EOCEOnlinePhonePaymentGatewayFormControlName.active]: boolean;
  [EOCEOnlinePhonePaymentGatewayFormControlName.additionalInformation]?: string;
}

export interface IOCEOnlinePhonePaymentGatewayOptionFormValue {
  [EOCEOnlinePhonePaymentGatewayOptionFormControlName.id]: EPaymentGateway;
  [EOCEOnlinePhonePaymentGatewayOptionFormControlName.name]: string;
  [EOCEOnlinePhonePaymentGatewayOptionFormControlName.predefined]: boolean;
  [EOCEOnlinePhonePaymentGatewayOptionFormControlName.preselected]: boolean;
}

export interface IOCEOnlinePhonePaymentGatewayForm {
  [EOCEOnlinePhonePaymentGatewayFormControlName.optionList]: FormArray<
    FormGroup<IOCEOnlinePhonePaymentGatewayOptionForm>
  >;
  [EOCEOnlinePhonePaymentGatewayFormControlName.hidden]: FormControl<
    IOCEOnlinePhonePaymentGatewayFormValue[EOCEOnlinePhonePaymentGatewayFormControlName.hidden]
  >;
  [EOCEOnlinePhonePaymentGatewayFormControlName.active]: FormControl<
    IOCEOnlinePhonePaymentGatewayFormValue[EOCEOnlinePhonePaymentGatewayFormControlName.active]
  >;
}

export interface IOCEOnlinePhonePaymentGatewayOptionForm {
  [EOCEOnlinePhonePaymentGatewayOptionFormControlName.id]: FormControl<
    IOCEOnlinePhonePaymentGatewayOptionFormValue[EOCEOnlinePhonePaymentGatewayOptionFormControlName.id]
  >;
  [EOCEOnlinePhonePaymentGatewayOptionFormControlName.name]: FormControl<
    IOCEOnlinePhonePaymentGatewayOptionFormValue[EOCEOnlinePhonePaymentGatewayOptionFormControlName.name]
  >;
  [EOCEOnlinePhonePaymentGatewayOptionFormControlName.predefined]: FormControl<
    IOCEOnlinePhonePaymentGatewayOptionFormValue[EOCEOnlinePhonePaymentGatewayOptionFormControlName.predefined]
  >;
  [EOCEOnlinePhonePaymentGatewayOptionFormControlName.preselected]: FormControl<
    IOCEOnlinePhonePaymentGatewayOptionFormValue[EOCEOnlinePhonePaymentGatewayOptionFormControlName.preselected]
  >;
}

export const COCEOnlinePhonePaymentGatewayFormDefault: IOCEOnlinePhonePaymentGatewayFormValue =
  {
    [EOCEOnlinePhonePaymentGatewayFormControlName.optionList]: [],
    [EOCEOnlinePhonePaymentGatewayFormControlName.hidden]: false,
    [EOCEOnlinePhonePaymentGatewayFormControlName.active]: false,
  };
