import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericClientService } from '@clients/generic/generic-client.service';
import { IMerchantBoardingSubmitTsysResponse } from '@interfaces/ops-and-uw-tools/merchant-boarding/merchant-boarding-submit.interface';
import {
  CValidateMidParamsDefault,
  IValidateMidParams,
  IValidateMidResponse,
} from '@interfaces/ops-and-uw-tools/merchant-boarding/merchant-boarding.interface';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import {
  ESubmitToTsysRequest,
  EValidateMidParams,
} from '@parameters/ops-and-uw-tools/merchant-boarding/merchant-boarding.parameter';

@Injectable()
export class MerchantBoardingClientService {
  constructor(private genericClient: GenericClientService) {}

  validateMid(queryParams: IValidateMidParams) {
    const endpoint = EndpointsParameter.ValidateMid;
    const params = new HttpParams().set(
      EValidateMidParams.mid,
      queryParams[EValidateMidParams.mid] ||
        CValidateMidParamsDefault[EValidateMidParams.mid]
    );
    return this.genericClient.genericGet<IValidateMidResponse>(endpoint, {
      params,
    });
  }

  submitToTsys(request: ESubmitToTsysRequest) {
    const endpoint = EndpointsParameter.SubmitToTsys;
    return this.genericClient.genericPost<IMerchantBoardingSubmitTsysResponse>(
      endpoint,
      request
    );
  }
}
