import {
  EOnboardingCustomizationEditFormControlName,
  IOnboardingCustomizationEditFormValue,
} from '@pages/onboarding-customization/onboarding-customization-edit/services/onboarding-customization-edit-form.interface';
import {
  IHardwareTerminalsRequest,
  IOnboardingCustomizationRequest,
} from '@interfaces/onboarding-customization/onboarding-customization-request.interface';
import {
  IOCPaymentPercentage,
  IOCProductDeliveryTimeline,
  IOCQuestionStep,
  IOCSelectionStep,
} from '@interfaces/onboarding-customization/onboarding-customization.interface';
import { EShoppingCart } from '@parameters/filter/ecommerce.parameter';
import {
  EOnlinePaymentMethod,
  EPaymentMethod,
} from '@parameters/filter/payment-method.parameter';
import { EPaymentGateway } from '@parameters/filter/payment-gateway.parameter';
import {
  EOCEPaymentMethodsFormControlName,
  EOCEPaymentMethodsOptionFormControlName,
} from '@pages/onboarding-customization/onboarding-customization-edit/views/oce-payment-methods/oce-payment-methods-form.interface';
import {
  EOCEEquipmentPricingFormControlName,
  EOCEEquipmentPricingOptionFormControlName,
} from '@pages/onboarding-customization/onboarding-customization-edit/views/oce-equipment-pricing/oce-equipment-pricing-form.interface';
import {
  EOCEOnlinePhonePaymentGatewayFormControlName,
  EOCEOnlinePhonePaymentGatewayOptionFormControlName,
} from '@pages/onboarding-customization/onboarding-customization-edit/views/oce-online-phone-payment-gateway/oce-online-phone-payment-gateway-form.interface';
import {
  EOCEOnlinePaymentIntegrationFormControlName,
  EOCEOnlinePaymentIntegrationOptionFormControlName,
} from '@pages/onboarding-customization/onboarding-customization-edit/views/oce-online-payment-integration/oce-online-payment-integration-form.interface';
import { EOCEMerchantAccountFormControlName } from '@pages/onboarding-customization/onboarding-customization-edit/views/oce-merchant-account/oce-merchant-account-form.interface';
import {
  EOCEShoppingCartIntegrationFormControlName,
  EOCEShoppingCartIntegrationOptionFormControlName,
} from '@pages/onboarding-customization/onboarding-customization-edit/views/oce-shopping-cart-integration/oce-shopping-cart-integration-form.interface';
import {
  EOCEOtherQuestionsFormControlName,
  EOCEOtherQuestionsOptionFormControlName,
} from '@pages/onboarding-customization/onboarding-customization-edit/views/oce-other-questions/oce-other-questions-form.interface';
import { EDeliveryTimeline } from '@parameters/filter/delivery-timeline.parameter';

export class OnboardingCustomizationEditRequest
  implements IOnboardingCustomizationRequest
{
  paymentMethods: IOCSelectionStep<EPaymentMethod[], EPaymentMethod[]>;
  paymentPercentage: IOCPaymentPercentage;
  hardwareTerminals: IHardwareTerminalsRequest;
  paymentGateways: IOCSelectionStep<
    EPaymentGateway | string,
    EPaymentGateway[]
  >;
  hasExistingGatewayAccount: IOCQuestionStep;
  ecommercePluginsAndOnlinePaymentMethods: IOCSelectionStep<
    EOnlinePaymentMethod,
    EOnlinePaymentMethod[]
  >;
  // Commented for Future Use
  // ecommercePlugins: IOCSelectionStep<EEcommercePlugin, EEcommercePlugin[]>;
  shoppingCarts: IOCSelectionStep<EShoppingCart, EShoppingCart[]>;
  hadDataBreach: IOCQuestionStep;
  isPCICompliant: IOCQuestionStep;
  isPubliclyTraded: IOCQuestionStep;
  merchantBankruptcy: IOCQuestionStep;
  cardHolderDataStorage: IOCQuestionStep;
  productDeliveryTimeline: IOCProductDeliveryTimeline;

  constructor(value: IOnboardingCustomizationEditFormValue) {
    this.setPaymentMethods(value);
    this.setEquipmentPricing(value);
    this.setOnlinePhonePaymentGateway(value);
    this.setMerchantAccount(value);
    this.setOnlinePaymentIntegration(value);
    // this.setEcommerceIntegration(value);
    this.setShoppingCartIntegration(value);
    this.setOtherQuestionList(value);
  }

  setPaymentMethods(value: IOnboardingCustomizationEditFormValue) {
    const paymentMethods =
      value[EOnboardingCustomizationEditFormControlName.paymentMethods];
    if (paymentMethods?.[EOCEPaymentMethodsFormControlName.active]) {
      const paymentMethodsPredefinedOptions = paymentMethods[
        EOCEPaymentMethodsFormControlName.optionList
      ]
        .filter(
          (value) => !!value[EOCEPaymentMethodsOptionFormControlName.predefined]
        )
        .map((value) => value[EOCEPaymentMethodsOptionFormControlName.id]);

      const paymentMethodsPreselectedValues = paymentMethods[
        EOCEPaymentMethodsFormControlName.optionList
      ]
        .filter(
          (value) =>
            !!value[EOCEPaymentMethodsOptionFormControlName.preselected]
        )
        .map((value) => value[EOCEPaymentMethodsOptionFormControlName.id]);

      const paymentPercentageValues = paymentMethodsPreselectedValues.map(
        (value) => {
          const optionValues = paymentMethods[
            EOCEPaymentMethodsFormControlName.optionList
          ].find(
            (option) =>
              option[EOCEPaymentMethodsOptionFormControlName.id] === value
          );

          return {
            id: optionValues[EOCEPaymentMethodsOptionFormControlName.id],
            percentage:
              optionValues[EOCEPaymentMethodsOptionFormControlName.percentage],
          };
        }
      );

      this.paymentMethods = {
        skip: paymentMethods[EOCEPaymentMethodsFormControlName.hidden],
        predefinedOptions: paymentMethodsPredefinedOptions,
        selectedValue: paymentMethodsPreselectedValues,
      };

      if (paymentMethods[EOCEPaymentMethodsFormControlName.hidden]) {
        this.paymentPercentage = {
          skip: !paymentMethods[EOCEPaymentMethodsFormControlName.permission],
          selectedValue: paymentPercentageValues,
        };
      }
    }
  }

  setEquipmentPricing(value: IOnboardingCustomizationEditFormValue) {
    const equipmentPricing =
      value[EOnboardingCustomizationEditFormControlName.equipmentPricing];
    if (equipmentPricing?.[EOCEEquipmentPricingFormControlName.active]) {
      const predefinedOptions = equipmentPricing[
        EOCEEquipmentPricingFormControlName.optionList
      ]
        .filter(
          (value) => !!value[EOCEEquipmentPricingOptionFormControlName.active]
        )
        .map((value) => value[EOCEEquipmentPricingOptionFormControlName.id]);

      this.hardwareTerminals = {
        skip: equipmentPricing[EOCEEquipmentPricingFormControlName.hidden],
        predefinedOptions: predefinedOptions,
      };
    }
  }

  setOnlinePhonePaymentGateway(value: IOnboardingCustomizationEditFormValue) {
    const onlinePhonePaymentGateway =
      value[
        EOnboardingCustomizationEditFormControlName.onlinePhonePaymentGateway
      ];
    if (
      onlinePhonePaymentGateway?.[
        EOCEOnlinePhonePaymentGatewayFormControlName.active
      ]
    ) {
      const predefinedOptions = onlinePhonePaymentGateway[
        EOCEOnlinePhonePaymentGatewayFormControlName.optionList
      ]
        .filter(
          (value) =>
            !!value[
              EOCEOnlinePhonePaymentGatewayOptionFormControlName.predefined
            ]
        )
        .map(
          (value) =>
            value[EOCEOnlinePhonePaymentGatewayOptionFormControlName.id]
        );

      const selectedValue = onlinePhonePaymentGateway[
        EOCEOnlinePhonePaymentGatewayFormControlName.optionList
      ].find(
        (value) =>
          !!value[
            EOCEOnlinePhonePaymentGatewayOptionFormControlName.preselected
          ]
      )?.[EOCEOnlinePhonePaymentGatewayOptionFormControlName.id];

      this.paymentGateways = {
        skip: onlinePhonePaymentGateway[
          EOCEOnlinePhonePaymentGatewayFormControlName.hidden
        ],
        predefinedOptions: predefinedOptions,
        selectedValue:
          selectedValue && selectedValue === EPaymentGateway.Other
            ? onlinePhonePaymentGateway?.[
                EOCEOnlinePhonePaymentGatewayFormControlName
                  .additionalInformation
              ] || ('' as string)
            : (selectedValue as EPaymentGateway),
      };
    }
  }

  setMerchantAccount(value: IOnboardingCustomizationEditFormValue) {
    const merchantAccount =
      value[EOnboardingCustomizationEditFormControlName.merchantAccount];
    if (merchantAccount?.[EOCEMerchantAccountFormControlName.active]) {
      this.hasExistingGatewayAccount = {
        skip: merchantAccount[EOCEMerchantAccountFormControlName.hidden],
        selectedValue:
          merchantAccount[EOCEMerchantAccountFormControlName.hasGateway],
      };
    }
  }

  setOnlinePaymentIntegration(value: IOnboardingCustomizationEditFormValue) {
    const ecommercePluginsAndOnlinePaymentMethods =
      value[
        EOnboardingCustomizationEditFormControlName.onlinePaymentIntegration
      ];
    if (
      ecommercePluginsAndOnlinePaymentMethods?.[
        EOCEOnlinePaymentIntegrationFormControlName.active
      ]
    ) {
      const predefinedOptions = ecommercePluginsAndOnlinePaymentMethods[
        EOCEOnlinePaymentIntegrationFormControlName.optionList
      ]
        .filter(
          (value) =>
            !!value[
              EOCEOnlinePaymentIntegrationOptionFormControlName.predefined
            ]
        )
        .map(
          (value) => value[EOCEOnlinePaymentIntegrationOptionFormControlName.id]
        );

      const selectedValue = ecommercePluginsAndOnlinePaymentMethods[
        EOCEOnlinePaymentIntegrationFormControlName.optionList
      ].find(
        (value) =>
          !!value[EOCEOnlinePaymentIntegrationOptionFormControlName.preselected]
      )?.[EOCEOnlinePaymentIntegrationOptionFormControlName.id];

      this.ecommercePluginsAndOnlinePaymentMethods = {
        skip: ecommercePluginsAndOnlinePaymentMethods[
          EOCEOnlinePaymentIntegrationFormControlName.hidden
        ],
        predefinedOptions: predefinedOptions,
        selectedValue:
          selectedValue && selectedValue == EOnlinePaymentMethod.Other
            ? ecommercePluginsAndOnlinePaymentMethods?.[
                EOCEOnlinePaymentIntegrationFormControlName
                  .additionalInformation
              ] || ''
            : selectedValue,
      };
    }
  }

  // COMMENTED FOR FUTURE USE
  // setEcommerceIntegration(value: IOnboardingCustomizationEditFormValue) {
  //   const ecommerceIntegration =
  //     value[EOnboardingCustomizationEditFormControlName.ecommerceIntegration];
  //   if (
  //     ecommerceIntegration?.[EOCEEcommerceIntegrationFormControlName.active]
  //   ) {
  //     const predefinedOptions = ecommerceIntegration[
  //       EOCEEcommerceIntegrationFormControlName.optionList
  //     ]
  //       .filter(
  //         (value) =>
  //           !!value[EOCEEcommerceIntegrationOptionFormControlName.predefined]
  //       )
  //       .map(
  //         (value) => value[EOCEEcommerceIntegrationOptionFormControlName.id]
  //       );

  //     const selectedValue = ecommerceIntegration[
  //       EOCEEcommerceIntegrationFormControlName.optionList
  //     ].find(
  //       (value) =>
  //         !!value[EOCEEcommerceIntegrationOptionFormControlName.preselected]
  //     )?.[EOCEEcommerceIntegrationOptionFormControlName.id];

  //     this.ecommercePlugins = {
  //       skip: ecommerceIntegration[
  //         EOCEEcommerceIntegrationFormControlName.hidden
  //       ],
  //       predefinedOptions: predefinedOptions,
  //       selectedValue: selectedValue,
  //     };
  //   }
  // }

  setShoppingCartIntegration(value: IOnboardingCustomizationEditFormValue) {
    const shoppingCartIntegration =
      value[
        EOnboardingCustomizationEditFormControlName.shoppingCartIntegration
      ];
    if (
      shoppingCartIntegration?.[
        EOCEShoppingCartIntegrationFormControlName.active
      ]
    ) {
      const predefinedOptions = shoppingCartIntegration[
        EOCEShoppingCartIntegrationFormControlName.optionList
      ]
        .filter(
          (value) =>
            !!value[EOCEShoppingCartIntegrationOptionFormControlName.predefined]
        )
        .map(
          (value) => value[EOCEShoppingCartIntegrationOptionFormControlName.id]
        );

      const selectedValue = shoppingCartIntegration[
        EOCEShoppingCartIntegrationFormControlName.optionList
      ].find(
        (value) =>
          !!value[EOCEShoppingCartIntegrationOptionFormControlName.preselected]
      )?.[EOCEShoppingCartIntegrationOptionFormControlName.id];

      this.shoppingCarts = {
        skip: shoppingCartIntegration[
          EOCEShoppingCartIntegrationFormControlName.hidden
        ],
        predefinedOptions: predefinedOptions,
        selectedValue: selectedValue,
      };
    }
  }

  setOtherQuestionList(value: IOnboardingCustomizationEditFormValue) {
    const shoppingCartIntegration =
      value[EOnboardingCustomizationEditFormControlName.otherQuestionList];

    const publicTrade =
      shoppingCartIntegration[EOCEOtherQuestionsFormControlName.publicTrade];
    if (publicTrade?.[EOCEOtherQuestionsOptionFormControlName.active]) {
      this.isPubliclyTraded = {
        skip: publicTrade[EOCEOtherQuestionsOptionFormControlName.hidden],
        selectedValue: false,
      };
    }

    const bankrupt =
      shoppingCartIntegration[EOCEOtherQuestionsFormControlName.bankrupt];
    if (bankrupt?.[EOCEOtherQuestionsOptionFormControlName.active]) {
      this.merchantBankruptcy = {
        skip: bankrupt[EOCEOtherQuestionsOptionFormControlName.hidden],
        selectedValue: false,
      };
    }

    const deliveryTime =
      shoppingCartIntegration[EOCEOtherQuestionsFormControlName.deliveryTime];
    if (deliveryTime?.[EOCEOtherQuestionsOptionFormControlName.active]) {
      this.productDeliveryTimeline = {
        skip: deliveryTime[EOCEOtherQuestionsOptionFormControlName.hidden],
        selectedValue: deliveryTime[
          EOCEOtherQuestionsOptionFormControlName.custom
        ] as EDeliveryTimeline,
      };
    }

    const cardHolder =
      shoppingCartIntegration[EOCEOtherQuestionsFormControlName.cardHolder];
    if (cardHolder?.[EOCEOtherQuestionsOptionFormControlName.active]) {
      this.cardHolderDataStorage = {
        skip: cardHolder[EOCEOtherQuestionsOptionFormControlName.hidden],
        selectedValue: false,
      };
    }

    const PCICompliant =
      shoppingCartIntegration[EOCEOtherQuestionsFormControlName.PCICompliant];
    if (PCICompliant?.[EOCEOtherQuestionsOptionFormControlName.active]) {
      this.isPCICompliant = {
        skip: PCICompliant[EOCEOtherQuestionsOptionFormControlName.hidden],
        selectedValue: false,
      };
    }

    const dataBreach =
      shoppingCartIntegration[EOCEOtherQuestionsFormControlName.dataBreach];
    if (dataBreach?.[EOCEOtherQuestionsOptionFormControlName.active]) {
      this.hadDataBreach = {
        skip: dataBreach[EOCEOtherQuestionsOptionFormControlName.hidden],
        selectedValue: false,
      };
    }
  }
}
