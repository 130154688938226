import {
  IResponseBase,
  IResponseData,
} from '@interfaces/generic/responses.interface';
import {
  CReportsDisputeListParamsDate,
  EReportsDisputeCSVParams,
  EReportsDisputeListParams,
  EReportsSearchBy,
} from '@parameters/reports/dispute/dispute-list.parameter';
import { IReportsDispute } from '@interfaces/reports/dispute/dispute.interface';
import { ECardBrand } from '@parameters/filter/card-brand.parameter';
import { EDisputeState } from '@parameters/filter/dispute-states.parameter';

export interface IReportsDisputeListParams {
  [EReportsDisputeListParams.size]: number;
  [EReportsDisputeListParams.page]: number;
  [EReportsDisputeListParams.sort]: string;
  [EReportsDisputeListParams.search]: string;
  [EReportsDisputeListParams.startDate]: string;
  [EReportsDisputeListParams.endDate]: string;
  [EReportsDisputeListParams.midList]: string[];
  [EReportsDisputeListParams.binList]: number[];
  [EReportsDisputeListParams.cardBrandList]: ECardBrand[];
  [EReportsDisputeListParams.searchBy]: EReportsSearchBy;
  [EReportsDisputeListParams.disputeStates]: EDisputeState[];
}

export const CReportsDisputeListParamsDefault: IReportsDisputeListParams = {
  [EReportsDisputeListParams.size]: 10,
  [EReportsDisputeListParams.page]: 1,
  [EReportsDisputeListParams.sort]: '',
  [EReportsDisputeListParams.search]: '',
  [EReportsDisputeListParams.startDate]:
    CReportsDisputeListParamsDate.value.startDate,
  [EReportsDisputeListParams.endDate]:
    CReportsDisputeListParamsDate.value.endDate,
  [EReportsDisputeListParams.midList]: [],
  [EReportsDisputeListParams.binList]: [],
  [EReportsDisputeListParams.cardBrandList]: [],
  [EReportsDisputeListParams.searchBy]: EReportsSearchBy.lastModifiedDate,
  [EReportsDisputeListParams.disputeStates]: [],
};

export interface IReportsDisputeListResponseOther {
  totalChargebackVolume: number;
  totalChargebackCount: number;
}

export interface IReportsDisputeListResponseData<T> extends IResponseData<T> {
  other: IReportsDisputeListResponseOther;
}

export interface IReportsDisputeListResponse
  extends IResponseBase<IReportsDisputeListResponseData<IReportsDispute>> {}

export interface IReportsDisputeCSVParams {
  [EReportsDisputeCSVParams.search]: string;
  [EReportsDisputeCSVParams.startDate]: string;
  [EReportsDisputeCSVParams.endDate]: string;
  [EReportsDisputeCSVParams.midList]: string[];
  [EReportsDisputeCSVParams.binList]: string[];
  [EReportsDisputeCSVParams.cardBrandList]: ECardBrand[];
  [EReportsDisputeCSVParams.disputeStates]: EDisputeState[];
  [EReportsDisputeCSVParams.searchBy]: EReportsSearchBy;
}

export interface IReportsDisputeCSVResponse
  extends IResponseBase<{ url: string }> {}
