import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';

export const ResidualRouterGuard: CanActivateFn = () => {
  const commonService = inject(CommonService);
  const router = inject(Router);

  if (!commonService.hasAccessToEntityResiduals) {
    return router.createUrlTree(['/miscellaneous/error'], {
      queryParams: { code: 403 },
    });
  }
  return true;
};
