import {
  IResponseBase,
  IResponseData,
} from '@interfaces/generic/responses.interface';
import { ETrainingVideoListParams } from '@parameters/resources/training/training-video.parameter';

export interface ITrainingVideoListParams {
  [ETrainingVideoListParams.page]: number;
  [ETrainingVideoListParams.size]: number;
  [ETrainingVideoListParams.search]: string;
  [ETrainingVideoListParams.tags]: string[];
}

export const CTrainingVideoListParamsDefault: ITrainingVideoListParams = {
  [ETrainingVideoListParams.page]: 1,
  [ETrainingVideoListParams.size]: 10,
  [ETrainingVideoListParams.search]: '',
  [ETrainingVideoListParams.tags]: [],
};

export interface IVideoListItem {
  id: string;
  title: string;
  description: string;
  shortDescription: string;
  imageSrc: string;
  date: Date;
  tags: string[];
}

export interface IVideoListResponse
  extends IResponseBase<IResponseData<IVideoListItem>> {}
