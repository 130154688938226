import {
  IResponseBase,
  IResponseData,
} from '@interfaces/generic/responses.interface';
import {
  CReportsStatementListParamsDate,
  EReportsStatementListParams,
  EReportsStatementListSort,
} from '@parameters/reports/statement/statement-list.parameter';
import { IReportsStatement } from '@interfaces/reports/statement/statement.interface';

export interface IReportsStatementListParams {
  [EReportsStatementListParams.size]: number;
  [EReportsStatementListParams.page]: number;
  [EReportsStatementListParams.sort]: string;
  [EReportsStatementListParams.search]: string;
  [EReportsStatementListParams.startDate]: string;
  [EReportsStatementListParams.endDate]: string;
  [EReportsStatementListParams.midList]: string[];
}

export const CReportsStatementListParamsDefault: IReportsStatementListParams = {
  [EReportsStatementListParams.size]: 10,
  [EReportsStatementListParams.page]: 1,
  [EReportsStatementListParams.sort]: EReportsStatementListSort.createdDateDesc,
  [EReportsStatementListParams.search]: '',
  [EReportsStatementListParams.startDate]:
    CReportsStatementListParamsDate.value.startDate,
  [EReportsStatementListParams.endDate]:
    CReportsStatementListParamsDate.value.endDate,
  [EReportsStatementListParams.midList]: [],
};

export interface IReportsStatementListResponse
  extends IResponseBase<IResponseData<IReportsStatement>> {}
