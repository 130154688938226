import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  NgZone,
  Output,
  inject,
} from '@angular/core';
import { AccountGatewayConfigStoreService } from '../../services/account-gateway-store.service';
import { TROW } from '@gettrx/core-components-angular';
import { THEAD } from '@gettrx/core-components-angular';
import {
  CConfigType,
  CPaymentMethodType,
  EAccountGatewayConfigListLabel,
} from '@parameters/accounts/account-gateway-config.parameter';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AccountGatewayConfig } from '../../models/account-gateway-config.model';

@Component({
  selector: 'app-account-gateway-configuration',
  templateUrl: './account-gateway-configuration.component.html',
  styleUrls: ['./account-gateway-configuration.component.scss'],
})
export class AccountGatewayConfigurationComponent {
  private readonly _cdr = inject(ChangeDetectorRef);
  private readonly _ngZone = inject(NgZone);
  private readonly _destroyRef = inject(DestroyRef);
  public defaultValues: any = {};
  private readonly _accountGatewayStore = inject(
    AccountGatewayConfigStoreService
  );

  @Input({ required: true }) public accountId: string;
  @Input() public hideAddModal: boolean = false;
  @Input() set configurationDetails(value: any) {
    this.openDefaultValuesModal(value);
  }

  @Output() onAdd: EventEmitter<any> = new EventEmitter();
  @Output() addedDefaultConfigs: EventEmitter<any> = new EventEmitter();

  public accountGatewayRows: TROW[] = [];
  public accountGatewayCols: THEAD[] = [
    {
      label: EAccountGatewayConfigListLabel.configType,
      wrap: 'INNERHTMLWRAP',
      priority: 'HIGH',
      type: 'innerHTML',
      sorting: { hasSorting: false, sort: 0 },
      skeletonConfigs: {
        mainTable: {
          width: '210px',
          height: '14px',
          className: '',
          repeat: 2,
        },
        resposvieTable: {
          width: '100%',
          height: '14px',
          className: '',
          repeat: 2,
        },
      },
    },
    {
      label: EAccountGatewayConfigListLabel.configuration,
      wrap: 'INNERHTMLWRAP',
      priority: 'HIGH',
      type: 'innerHTML',
      sorting: { hasSorting: false, sort: 0 },
      skeletonConfigs: {
        mainTable: {
          width: '140px',
          height: '14px',
          className: '',
          repeat: 3,
        },
        resposvieTable: {
          width: '100%',
          height: '14px',
          className: '',
          repeat: 3,
        },
      },
    },
    {
      label: EAccountGatewayConfigListLabel.defaultConfig,
      priority: 'HIGH',
      type: 'string',
      sorting: { hasSorting: false, sort: 0 },
      skeletonConfigs: {
        mainTable: {
          width: '90px',
          height: '14px',
          className: '',
          repeat: 1,
        },
        resposvieTable: {
          width: '100%',
          height: '14px',
          className: '',
          repeat: 1,
        },
      },
    },
    {
      label: EAccountGatewayConfigListLabel.descriptorPrefix,
      priority: 'LOW',
      type: 'string',
      sorting: { hasSorting: false, sort: 0 },
      skeletonConfigs: {
        mainTable: {
          width: '90px',
          height: '14px',
          className: '',
          repeat: 1,
        },
        resposvieTable: {
          width: '100%',
          height: '14px',
          className: '',
          repeat: 1,
        },
      },
    },
    {
      label: EAccountGatewayConfigListLabel.actions,
      align: 'left',
      type: 'actions',
      sorting: { hasSorting: false, sort: 0 },
      skeletonConfigs: {
        mainTable: {
          width: '30px',
          height: '12px',
          className: '',
          repeat: 1,
        },
        resposvieTable: {
          width: '100%',
          height: '12px',
          className: '',
          repeat: 1,
        },
      },
    },
  ];

  public loading: boolean = false;
  public accountGatewayList: AccountGatewayConfig[] = [];

  public addModalState: boolean = false;
  public activeDetail: any;

  ngOnInit(): void {
    this._getGatewayConfiguration();
  }

  /** Associated configurations list */
  private _getGatewayConfiguration() {
    this.loading = true;
    this.initTableData();
    this._accountGatewayStore
      .getGatewayConfiguration(this.accountId)
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe({
        next: (res) => {
          this.loading = false;
          this.accountGatewayRows = [];
          this.accountGatewayList = res?.list ? res?.list : [];
          const configs = [];

          this.accountGatewayList.map((item) => {
            if (item.defaultConfig) {
              configs.push(CPaymentMethodType[item?.configPm]);
            }
          });

          this.addedDefaultConfigs.emit(configs);

          this.createMainTableData();
        },
        error: () => {
          this._ngZone.run(() => {
            this.loading = false;
          });
          this._cdr.detectChanges();
        },
      });
  }

  /** Initialize table data */
  public initTableData() {
    this.accountGatewayRows = [];
    for (let i = 0; i < 5; i++) {
      this.accountGatewayRows.push({
        expand: { isExpanded: true, expandAfter: 3 },
        id: '',
        isSelected: false,
        columnsData: [
          {
            value: '',
          },
          {
            value: '',
          },
          {
            value: '',
          },
          {
            value: '',
          },
          {
            value: '',
            type: 'actions',
            actions: [{ type: 'icon', icon: 'edit', color: '', name: 'Edit' }],
          },
        ],
      });
    }
  }

  public createMainTableData() {
    this.accountGatewayList?.map((data) => {
      this.accountGatewayRows.push({
        expand: { isExpanded: false, expandAfter: 3 },
        id: data?.configId,
        isSelected: false,
        columnsData: [
          {
            value: this.getConfigTemplate(data),
          },
          {
            value: this.getConfigurations(data),
          },
          {
            value: data?.defaultConfig
              ? `Yes - ${CPaymentMethodType[data?.configPm]}`
              : 'No',
          },
          {
            value: data?.descriptorPrefix || 'Not Defined',
          },
          {
            value: data?.configId,
            type: 'actions',
            actions: [{ type: 'icon', icon: 'edit', color: '', name: 'Edit' }],
          },
        ],
      });
    });
  }

  /** Template for config type */
  private getConfigTemplate(data: AccountGatewayConfig) {
    if (data?.configPm || data?.configId || data?.configType) {
      let templateString = '';

      if (data?.configPm && CPaymentMethodType[data?.configPm]) {
        templateString += `${CPaymentMethodType[data?.configPm]}`;

        if (data?.configType && CConfigType[data?.configType]) {
          templateString += ' - ';
        }
      }

      if (data?.configType && CConfigType[data?.configType]) {
        templateString += `${CConfigType[data?.configType]}`;
      }

      if (data?.configId) {
        templateString += `<br/><b>${data?.configId}</b>`;
      }

      return templateString;
    } else {
      return 'Not Defined';
    }
  }

  /** Get template of configurations */
  private getConfigurations(data: AccountGatewayConfig) {
    if (data?.mid || data?.payfacMid || data?.tid) {
      let templateString = '';

      if (data?.mid) {
        templateString += `<b>MID: </b>${data?.mid}`;

        if (data?.tid || data?.payfacMid) {
          templateString += '<br/>';
        }
      }

      if (data?.tid) {
        templateString += `<b>TID: </b>${data?.tid}`;

        if (data?.payfacMid) {
          templateString += `<br/>`;
        }
      }

      if (data?.payfacMid) {
        templateString += `<b>PF ID: </b>${data?.payfacMid}`;
      }

      return templateString;
    } else {
      return 'Not Defined';
    }
  }

  /**
   * Detect events from table's actions column
   * @param e
   */
  public actionDone(e: { id: string; action: { name: string } }) {
    switch (e.action.name?.toLowerCase()) {
      case 'edit':
        const data = this.accountGatewayList?.find(
          (account) => account?.configId === e?.id
        );
        data && this.openModal(data);
        break;
    }
  }

  /**
   * Open detail modal
   * @param data
   */
  openDefaultValuesModal(data?: any) {
    if (data.show) {
      this.defaultValues = data;
      this.addModalState = true;
    }
  }

  /**
   * Open detail modal
   * @param data
   */
  public openModal(data?: any) {
    if (this.hideAddModal) {
      this.onAdd.emit(true);
    } else {
      if (data) {
        this.activeDetail = data;
        this.addModalState = true;
      } else {
        this.activeDetail = undefined;
        this.addModalState = true;
      }
    }
  }

  /** Close detail modal */
  public closeModal(refresh: boolean) {
    this.activeDetail = undefined;
    this.addModalState = false;

    refresh && this._getGatewayConfiguration();
  }
}
