<div
  id="sidenav-container"
  class="sidenav-container {{ mode }}"
  [ngClass]="{ expand: opened, collapsed: !opened }"
>
  <div class="sidenav-header" id="sidenav-header">
    <img
      [src]="imageUrl + brandingData?.logo"
      alt="branding-logo"
      class="branding-logo"
        [attr.width]="
          brandingData?.logoWidth ? brandingData?.logoWidth : 'auto'
        "
    routerLink="/"/>
    <a
      *ngIf="mode === 'over'"
      (click)="this.hideSidenav()"
      [attr.data-transaction-name]="'sidenav-hide'"
      aria-label="sidenav-toggle"
      class="sidenav-toggle-icon"
    ></a>
  </div>
  <ng-content></ng-content>
</div>

<span
  (click)="hideSidenav()"
  class="sidenav-overlay-container {{
    opened && mode == 'over' ? 'show' : 'hide'
  }}" [attr.data-transaction-name]="'sidenav-hide'"
></span>
