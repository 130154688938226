import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  computed,
  inject,
  signal,
} from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { IBrandingResponse } from '@interfaces/branding/branding.interface';
import { CommonService } from 'src/app/shared/services/common.service';
import { OfferPricingBannerData } from './models/offer-banner.model';
import { OfferBannerService } from './services/offer-banner.service';
import {
  EOfferPricingType,
  EPaymentMethodType,
} from '@parameters/offer-pricing/offer-pricing.parameter';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TotalProductCost } from 'src/app/business/authentication/auth-onboarding/steps/select-hardware/select-hardware.component';

@Component({
  selector: 'app-offer-banner',
  templateUrl: './offer-banner.component.html',
  styles: [
    `
      .no-white-space {
        white-space: nowrap;
      }

      .mwx-90 {
        width: 90px;
      }

      .m-36 {
        margin: 36px 0px 36px 0px;
      }
    `,
  ],
})
export class OfferBannerComponent
  implements OnInit, AfterViewInit, AfterViewChecked
{
  // Terminal cost Input
  public isOpen: boolean = false;
  @Input() public totalproductCost!: TotalProductCost;
  @Input() public isOnboardingPage!: boolean;
  @Input() public dataTransactionName: string = 'no-tagged'
  @Output() public onClose = new EventEmitter();
  @Input() set isModal(value: boolean) {
    if (value) {
      this.isOpen = true;
      document.getElementById('sidebar-container').classList.add('add-overlay');
    }
  }

  public pricingType!: string;
  public destroyRef = inject(DestroyRef);
  public brandingData: IBrandingResponse;

  public listHeight: number = 400;
  public readonly pricingBannerData = signal<OfferPricingBannerData>(null);
  public readonly offerPricingType = EOfferPricingType;
  public readonly paymentMethodType = EPaymentMethodType;
  public readonly isActive = true;
  public readonly imageUrl: string = `${environment.imgFileBaseUrl}/`;
  public readonly mapFieldToShow = signal([
    {
      inputLabel: 'AVS Fee',
      value: 'avsFee',
      isVisible: 'showAvsFee',
    },
    {
      inputLabel: 'Batch Fee',
      value: 'batchFee',
      isVisible: 'showBatchFee',
    },
    {
      inputLabel: 'Statement',
      value: 'statementFee',
      isVisible: 'showStatementFee',
    },
    {
      inputLabel: 'Pin Debit Access',
      value: 'pinDebitAccessFee',
      isVisible: 'showPinDebitAccessFee',
    },
    {
      inputLabel: 'Online Reporting',
      value: 'onlineReportingFee',
      isVisible: 'showOnlineReportingFee',
    },
    {
      inputLabel: 'Terminal Warranty Fee',
      value: 'terminalWarrantyFee',
      isVisible: 'showTerminalWarrantyFee',
    },
    {
      inputLabel: 'Merchant Club Fee',
      value: 'merchantClubFee',
      isVisible: 'showMerchantClubFee',
    },
  ]);

  public readonly mapOtherStandardFeeKeys = signal([
    {
      inputLabel: 'Voice Auth Fee',
      value: 'voiceAuthFee',
      isVisible: 'showVoiceAuthFee',
    },
    {
      inputLabel: 'Retrieval Request Fee',
      value: 'retrievalRequestFee',
      isVisible: 'showRetrievalRequestFee',
    },
    {
      inputLabel: 'Chargeback Fee',
      value: 'chargebackFee',
      isVisible: 'showChargebackFee',
    },
    {
      inputLabel: 'ACH Reject Fee',
      value: 'achRejectFee',
      isVisible: 'showAchRejectFee',
    },
    {
      inputLabel: 'Account Maint. Fee',
      value: 'acctMaintenanceFee',
      isVisible: 'showAcctMaintenanceFee',
    },
    {
      inputLabel: 'Annual Account Maint. Fee',
      value: 'annualAcctMaintenanceFee',
      isVisible: 'showAnnualAcctMaintenanceFee',
    },
    // {
    //   inputLabel: 'RDR Handling Fee',
    //   value: 'rdrHandlingFee',
    //   isVisible: 'showRdrHandlingFee',
    // },
    {
      inputLabel: 'EdgePay Safeguard Fee',
      value: 'edgepaySafeguardFee',
      isVisible: 'showEdgepaySafeguardFee',
    },
    {
      inputLabel: 'Account Change Fee',
      value: 'accountChangeFee',
      isVisible: 'showAccountChangeFee',
    },
    {
      inputLabel: 'Monthly Minimum Fee',
      value: 'monthlyMinimumFee',
      isVisible: 'showMonthlyMinimumFee',
    },
    {
      inputLabel: 'E-comm Compliance Fee',
      value: 'ecommComplianceFee',
      isVisible: 'showEcommComplianceFee',
    },
    {
      inputLabel: 'Qtly. PCI Compliance Fee',
      value: 'qtlyPciComplianceFee',
      isVisible: 'showQtlyPciComplianceFee',
    },
    {
      inputLabel: 'PCI Monthly Fee',
      value: 'pciMonthlyFee',
      isVisible: 'showPciMonthlyFee',
    },
    {
      inputLabel: 'VI/MC High-Risk Registration Fee',
      value: 'highRiskRegistrationFee',
      isVisible: 'showHighRiskRegistrationFee',
    },
    {
      inputLabel: 'High-Risk Admin Fee',
      value: 'highRiskAdminFee',
      isVisible: 'showHighRiskAdminFee',
    },
    {
      inputLabel: 'Platform Fee',
      value: 'platformFee',
      isVisible: 'showPlatformFee',
    },
    {
      inputLabel: 'ACH Correction Notice Fee',
      value: 'achCorrectionNoticeFee',
      isVisible: 'showAchCorrectionNoticeFee',
    },
    {
      inputLabel: 'ACH Admin Return Fee',
      value: 'achAdminReturnFee',
      isVisible: 'showAchAdminReturnFee',
    },
    {
      inputLabel: 'ACH Unauthorized Return Fee',
      value: 'achUnauthorizedReturnFee',
      isVisible: 'showAchUnauthorizedReturnFee',
    },
    {
      inputLabel: 'ACH Annual Subscription',
      value: 'achAnnualSubscriptionFee',
      isVisible: 'showAchAnnualSubscriptionFee',
    },
    {
      inputLabel: 'ACH Monthly Minimum',
      value: 'achMonthlyMinFee',
      isVisible: 'showAchMonthlyMinFee',
    },
    {
      inputLabel: 'ACH Reject Fee',
      value: 'achRejectFee2',
      isVisible: 'showAchRejectFee2',
    },
  ]);

  public readonly gatewayFields = signal([
    {
      inputLabel: 'Gateway Setup Fee',
      value: 'gatewaySetUpFee',
      isVisible: 'showGatewaySetUpFee',
    },
    {
      inputLabel: 'Gateway Per Transaction',
      value: 'gatewayTransFee',
      isVisible: 'showGatewayTransFee',
    },
    {
      inputLabel: 'Gateway Monthly Fee',
      value: 'gatewayMonthlyFee',
      isVisible: 'showGatewayMonthlyFee',
    },
  ]);

  public readonly hasNoGateway = computed(() => {
    return !(
      (this.pricingBannerData()?.otherAgentFees?.showGatewaySetUpFee &&
        this.pricingBannerData()?.otherAgentFees?.gatewaySetUpFee) ||
      (this.pricingBannerData()?.otherAgentFees?.showGatewayMonthlyFee &&
        this.pricingBannerData()?.otherAgentFees?.gatewayMonthlyFee) ||
      (this.pricingBannerData()?.otherAgentFees?.showGatewayTransFee &&
        this.pricingBannerData()?.otherAgentFees?.gatewayTransFee)
    );
  });

  public readonly mobileFeesFields = signal([
    {
      inputLabel: 'Mobile Setup',
      value: 'mobileSetUpFee',
      isVisible: 'showMobileSetUpFee',
    },
    {
      inputLabel: 'Mobile Per Transaction',
      value: 'mobilePerTransFee',
      isVisible: 'showMobilePerTransFee',
    },
    {
      inputLabel: 'Mobile Monthly Fee',
      value: 'mobileMonthlyFee',
      isVisible: 'showMobileMonthlyFee',
    },
  ]);

  public readonly hasNoMobile = computed(() => {
    return !(
      (this.pricingBannerData()?.otherAgentFees?.showMobileSetUpFee &&
        this.pricingBannerData()?.otherAgentFees?.mobileSetUpFee) ||
      (this.pricingBannerData()?.otherAgentFees?.showMobilePerTransFee &&
        this.pricingBannerData()?.otherAgentFees?.mobilePerTransFee) ||
      (this.pricingBannerData()?.otherAgentFees?.showMobileMonthlyFee &&
        this.pricingBannerData()?.otherAgentFees?.mobileMonthlyFee)
    );
  });

  public readonly wirelessFeesFields = signal([
    {
      inputLabel: 'Wireless Setup Fee',
      value: 'wirelessSetUpFee',
      isVisible: 'showWirelessSetUpFee',
    },
    {
      inputLabel: 'Wireless Per Transaction',
      value: 'wirelessPerTransFee',
      isVisible: 'showWirelessPerTransFee',
    },
    {
      inputLabel: 'Wireless Monthly Fee',
      value: 'wirelessMonthlyFee',
      isVisible: 'showWirelessMonthlyFee',
    },
  ]);

  public readonly achAgentFeeFields = signal([
    {
      inputLabel: 'ACH Batch Fee',
      value: 'achBatchFee',
      isVisible: 'showAchBatchFee',
    },
    {
      inputLabel: 'ACH Setup Fee',
      value: 'achSetupFee',
      isVisible: 'showAchSetupFee',
    },
  ]);

  public readonly hasNoAchAgentFees = computed(() => {
    return !(
      (this.pricingBannerData()?.otherAgentFees?.showAchBatchFee &&
        this.pricingBannerData()?.otherAgentFees?.achBatchFee) ||
      (this.pricingBannerData()?.otherAgentFees?.showAchSetupFee &&
        this.pricingBannerData()?.otherAgentFees?.achSetupFee)
    );
  });

  public readonly hasNoWireless = computed(() => {
    return !(
      (this.pricingBannerData()?.otherAgentFees?.showWirelessSetUpFee &&
        this.pricingBannerData()?.otherAgentFees?.wirelessSetUpFee) ||
      (this.pricingBannerData()?.otherAgentFees?.showWirelessPerTransFee &&
        this.pricingBannerData()?.otherAgentFees?.wirelessPerTransFee) ||
      (this.pricingBannerData()?.otherAgentFees?.showWirelessMonthlyFee &&
        this.pricingBannerData()?.otherAgentFees?.wirelessMonthlyFee)
    );
  });

  public readonly hasSameInterchangeAuthFee = computed(() => {
    return (
      this.pricingBannerData()?.interchangePlusCoreFees?.intPlusEbtAuthFee ===
      this.pricingBannerData()?.interchangePlusCoreFees?.intPlusPinDebitAuthFee
    );
  });

  public readonly hasInterchangeHighlightPerItem = computed(() => {
    return this.pricingBannerData()?.interchangePlusCoreFees
      ?.highlightPerItemFees;
  });

  public readonly hasInterchangeSameAuthFee = computed(() => {
    return (
      this.pricingBannerData()?.interchangePlusCoreFees?.intPlusAuthFee ===
      this.pricingBannerData()?.interchangePlusCoreFees?.intPlusAmexAuthFee
    );
  });

  // Tiered Offer Logics

  public readonly hasSameSignatureDebit = computed(() => {
    const hasSamePerItem =
      this.pricingBannerData()?.tieredCoreFees?.tieredDiscountRatePerItem ===
      this.pricingBannerData()?.tieredCoreFees?.tieredSignatureDebitPerItem;

    return hasSamePerItem;
  });

  public readonly hasSameAmex = computed(() => {
    const hasSamePerItem =
      this.pricingBannerData()?.tieredCoreFees?.tieredDiscountRatePerItem ===
      this.pricingBannerData()?.tieredCoreFees?.tieredAmexDiscountRatePerItem;

    return hasSamePerItem;
  });

  public readonly hasTieredSameQual = computed(() => {
    const hasSameRate =
      this.pricingBannerData()?.tieredCoreFees?.tieredDiscountRatePercentage ===
        this.pricingBannerData()?.tieredCoreFees
          ?.tieredAmexDiscountRatePercentage &&
      this.pricingBannerData()?.tieredCoreFees
        ?.tieredAmexDiscountRatePercentage ===
        this.pricingBannerData()?.tieredCoreFees
          ?.tieredSignatureDebitPercentage;

    const hasSamePerItem =
      this.pricingBannerData()?.tieredCoreFees?.tieredDiscountRatePerItem ===
        this.pricingBannerData()?.tieredCoreFees
          ?.tieredAmexDiscountRatePerItem &&
      this.pricingBannerData()?.tieredCoreFees
        ?.tieredAmexDiscountRatePerItem ===
        this.pricingBannerData()?.tieredCoreFees.tieredSignatureDebitPerItem;

    return this.hasTieredHighlightAuthFee()
      ? hasSameRate
      : hasSameRate && hasSamePerItem;
  });

  public readonly hasTieredHighlightAuthFee = computed(() => {
    return this.pricingBannerData()?.tieredCoreFees?.highlightAuthFees;
  });

  public readonly hasTieredSameQualPerItem = computed(() => {
    const hasSamePerItem =
      this.pricingBannerData()?.tieredCoreFees?.tieredDiscountRatePerItem ===
        this.pricingBannerData()?.tieredCoreFees
          ?.tieredAmexDiscountRatePerItem &&
      this.pricingBannerData()?.tieredCoreFees
        ?.tieredAmexDiscountRatePerItem ===
        this.pricingBannerData()?.tieredCoreFees.tieredSignatureDebitPerItem;

    return hasSamePerItem;
  });

  public readonly hasTieredAmexRate = computed(() => {
    return this.pricingBannerData()?.tieredCoreFees
      ?.tieredAmexDiscountRatePerItem;
  });

  public readonly hasTieredSignatureDebitRate = computed(() => {
    return this.pricingBannerData()?.tieredCoreFees
      ?.tieredSignatureDebitPerItem;
  });

  public readonly hasTieredQualRate = computed(() => {
    return this.pricingBannerData()?.tieredCoreFees?.tieredDiscountRatePerItem;
  });

  public readonly hasTieredSignatureDebit = computed(() => {
    return (
      this.pricingBannerData()?.tieredCoreFees?.tieredSignatureDebitPerItem ||
      (!this.hasTieredHighlightAuthFee() &&
        this.pricingBannerData()?.tieredCoreFees
          ?.tieredSignatureDebitPercentage) ||
      (this.hasTieredHighlightAuthFee() &&
        this.pricingBannerData()?.tieredCoreFees?.tieredAuthFee)
    );
  });

  public readonly hasTieredSameAuthFee = computed(() => {
    return (
      this.pricingBannerData()?.tieredCoreFees?.tieredAmexAuthFee ===
      this.pricingBannerData()?.tieredCoreFees?.tieredAuthFee
    );
  });

  // ACH Only
  public readonly hasAchCredits = computed(() => {
    return (
      this.pricingBannerData()?.paymentMethodType ==
        this.paymentMethodType.CARD_AND_ACH &&
      (this.pricingBannerData()?.achCoreFees?.credits?.achCreditDiscountFee ||
        this.pricingBannerData()?.achCoreFees?.credits?.achCreditPerItemFee ||
        this.pricingBannerData()?.achCoreFees?.credits
          ?.achCreditMaxFeePerTransaction)
    );
  });

  public readonly hasAchDebits = computed(() => {
    return (
      this.pricingBannerData()?.paymentMethodType ==
        this.paymentMethodType.CARD_AND_ACH &&
      (this.pricingBannerData()?.achCoreFees?.debits?.achDebitDiscountFee ||
        this.pricingBannerData()?.achCoreFees?.debits?.achDebitPerItemFee ||
        this.pricingBannerData()?.achCoreFees?.debits
          ?.achDebitMaxFeePerTransaction ||
        this.pricingBannerData()?.achCoreFees?.debits?.achDebitRefundFee)
    );
  });

  constructor(
    public router: Router,
    private cmService: CommonService,
    private readonly offerBannerService: OfferBannerService,
    private readonly _cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.brandingData = this.cmService.brandingData || null;

    this.offerBannerService
      .getPricingBannerData()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res) => {
          this.pricingType = res?.pricingType;
          this.pricingBannerData.set(res);
        },
      });
  }

  @ViewChild('addMerchantModal')
  public addMerchantModal: any;

  ngAfterViewInit(): void {
    this.updateOfferBannerMaxHeight();
  }

  ngAfterViewChecked(): void {
    this.updateOfferBannerMaxHeight();
  }

  closeModal() {
    document
      .getElementById('sidebar-container')
      .classList.remove('add-overlay');
    this.isOpen = false;
    this.onClose.emit(true);
  }

  public updateOfferBannerMaxHeight() {
    const terminalHeight = this.terminalWrapperHeight
      ? this.terminalWrapperHeight + 20
      : 0;

    const totalHeight = (this.topHeaderHeight ?? 0) + (terminalHeight ?? 0);

    const height = this.offerBannerHeight - totalHeight;

    const scrollContainerHeight = height
      ? this.brandingData?.showSidebarImage
        ? height - (this.isOnboardingPage ? 202 : 192)
        : height - (this.isOnboardingPage ? 110 : 80)
      : 400;

    this.listHeight = scrollContainerHeight || 400;
    this._cd.detectChanges();
  }

  public get offerBannerHeight() {
    return document.getElementsByClassName('offer_content_web')?.[0]
      ?.clientHeight;
  }

  public get topHeaderHeight() {
    return document.getElementsByClassName('top-header')?.[0]?.clientHeight;
  }

  public get terminalWrapperHeight() {
    return this.totalproductCost?.totalAccessoriesItems ||
      this.totalproductCost?.totalTerminalItems
      ? document.querySelector('terminal-calculations')?.clientHeight
      : 0;
  }
}
