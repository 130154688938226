import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-menu-list',
  templateUrl: './menu-list.component.html',
  styleUrls: ['./menu-list.component.scss'],
})
export class MenuListComponent implements AfterViewInit {
  /* To get menuListContainer element from template */
  @ViewChild('menuListContainer', { static: true })
  private menuListContainer: ElementRef<HTMLDivElement>;

  /**
   * Constructor
   * @param document
   * @param cd
   */
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private cd: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.detectHeaderChanges();
    this.cd.detectChanges();
  }

  /* To set dynamic height of header */
  private detectHeaderChanges() {
    const sidenavHeader = this.document.getElementById('sidenav-header');
    if (!sidenavHeader) return;
    const headerHeight = sidenavHeader?.clientHeight;
    this.menuListContainer.nativeElement.style.setProperty(
      'max-height',
      `calc(100% - ${headerHeight}px)`
    );
  }
}
