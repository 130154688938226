import {
  AbstractControl,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { constants } from './constants';

export function noWhitespaceValidator(
  control: UntypedFormControl
): ValidationErrors | null {
  const isValid = !(
    control.value &&
    control.value.toString().startsWith(' ') &&
    control.value.toString().trim().length === 0
  );
  return isValid ? null : { whitespace: true };
}

export function dateValidator(
  control: UntypedFormControl
): ValidationErrors | null {
  let isValid = true;
  let todayDate = new Date();
  let currentValue = new Date(control.value);
  let currentyear = currentValue.getFullYear();
  var year = new Date(todayDate).getFullYear();
  var month = new Date(todayDate).getMonth();
  var day = new Date(todayDate).getDate();
  let dobMax = new Date(year - 18, month, day);

  if (isNaN(currentyear)) {
    return { dateValidation: true };
  } else {
    if (!(dobMax.getFullYear() < currentValue.getFullYear())) {
      isValid = true;
    } else {
      isValid = false;
    }
    return isValid ? null : { dateValidation: true };
  }
}
export class CustomValidator {
  // Number only validation
  static numeric(control: AbstractControl) {
    let val = control.value;

    if (val === null || val === '') return null;

    if (!val.toString().match(/^[0-9]+(\.?[0-9]+)?$/))
      return { invalidNumber: true };

    return null;
  }

  static integer(control: AbstractControl) {
    let val = control.value;

    if (val === null || val === '') return null;

    // Use a regular expression to check if the value is an integer.
    const integerPattern = /^\d+$/;
    if (!integerPattern.test(control.value)) {
      return { notInteger: true };
    }

    return null;
  }

  static percentage(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value === null || value === undefined || value === '') {
      return null;
    }

    const numericValue = parseFloat(value);

    if (isNaN(numericValue)) {
      return { percentage: true };
    }

    // Ensure numericValue is between 0 and 100
    if (numericValue < 0 || numericValue > 100) {
      return { percentage: true };
    }

    return null;
  }

  static bankRoutingNumber(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value === null || value === undefined || value === '') {
      return null;
    }

    const integerPattern = /^\d+$/;
    if (!integerPattern.test(control?.value)) {
      return { invalidRoutingNumber: true };
    }

    if (value?.length == 9) {
      const firstDigit = +value[0];
      const secondDigit = +value[1];
      if (firstDigit && secondDigit) {
        const sum = firstDigit + secondDigit;
        if (+sum >= 1 && sum <= 12) {
          return null;
        }
      }
    }

    return { invalidRoutingNumber: true };
  }

  static bankPhoneNumber(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value === null || value === undefined || value === '') {
      return null;
    }

    const integerPattern =
      /(?=^.{0,15}$)^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (!integerPattern.test(control?.value)) {
      return { invalidBankPhoneNumber: true };
    }

    return null;
  }

  static validateEmail(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value === null || value === undefined || value === '') {
      return null;
    }

    const integerPattern = new RegExp(constants.email_regex);
    if (!integerPattern.test(control?.value)) {
      return { invalidEmail: true };
    }

    return null;
  }
}

// Custom validator function for allowing a dynamic number of digits after the decimal point
export function digitsAfterDecimalValidator(digits: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    const regex = digits
      ? new RegExp(`^\\d+(\\.\\d{1,${digits}})?$`)
      : /^[0-9]+$/; // Regular expression with dynamic digits

    if (
      value !== null &&
      value !== undefined &&
      !regex.test(value.toString())
    ) {
      return {
        [digits ? 'decimalExceeded' : 'decimalNotAllowed']: {
          allowedDigits: digits,
          actualValue: value,
        },
      };
    }

    return null; // Validation passed
  };
}

export function maxNumberValidator(number: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let val = control.value;

    if (val === null || val === '') return null;

    // Use a regular expression to check if the value is an integer.
    if (val > number) {
      return { maxNumber: true };
    }

    return null;
  };
}

// Custom validator for postal code
export function zipCodeValidator(countryCode: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control?.value;
    const defaultRegex = new RegExp(`^[a-zA-Z0-9]{1,12}$`);

    let regEx = defaultRegex;

    switch (countryCode?.toUpperCase()) {
      case 'US': {
        regEx = new RegExp(`^[0-9]{5}(?:-[0-9]{4})?$`);
        break;
      }
      case 'GB': {
        regEx = new RegExp(
          `^[A-Za-z]{1,2}[0-9Rr][0-9A-Za-z]? [0-9][A-Za-z]{2}$`
        );
        break;
      }
      case 'CA': {
        regEx = new RegExp(`^[a-zA-Z][0-9][a-zA-Z]? [0-9][a-zA-Z][0-9]$`);
        break;
      }
      default: {
        regEx = defaultRegex;
      }
    }

    if (
      value !== null &&
      value !== undefined &&
      value !== '' &&
      !regEx.test(value)
    ) {
      return { invalidZipCode: true };
    }

    return null; // Validation passed
  };
}
