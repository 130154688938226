import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { GenericClientService } from '@clients/generic/generic-client.service';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import { IOnboardingSkipableRoutesData } from '@interfaces/authentication/onboading-skipable-routes-list.interface';
import { Router } from '@angular/router';
import { ApiEndpointHelper } from '@helpers/api-endpoints.helper';
import { GenericHelper } from '@helpers/generic.helper';

@Injectable()
export class AuthOnboardingClientService {
  constructor(
    private genericClient: GenericClientService,
    private router: Router
  ) {}

  /**
   * Get onboarding skipable routes list
   * @param queryParams
   * @returns
   */
  getOnboardingConfiguration(): Observable<IOnboardingSkipableRoutesData> {
    const id = ApiEndpointHelper.setApiUrl(this.router);
    GenericHelper.replaceUrl(EndpointsParameter.OnboardingConfiguration, id);
    const endpoint: string = GenericHelper.replaceUrl(
      EndpointsParameter.OnboardingConfiguration,
      id
    );
    return this.genericClient.genericGet<IOnboardingSkipableRoutesData>(
      endpoint
    );
  }

  /**
   * Get onboarding business detail
   * @returns
   */
  getOnboardingBusinessDetail(): Observable<any> {
    const id = ApiEndpointHelper.setApiUrl(this.router);
    GenericHelper.replaceUrl(EndpointsParameter.OnboardingBusinessDetail, id);
    const endpoint: string = GenericHelper.replaceUrl(
      EndpointsParameter.OnboardingBusinessDetail,
      id
    );

    return this.genericClient.genericGet<any>(endpoint);
  }
}
