import {
  IResponseBase,
  IResponseData,
} from '@interfaces/generic/responses.interface';
import {
  EFPAllowedOnboardingTemplatesParams,
  EFPAllowedOnboardingTemplatesSort,
} from '@parameters/funding-plans/fp-allowed-onboarding-templates.parameter';

export interface IFPAllowedOnboardingTemplatesParams {
  [EFPAllowedOnboardingTemplatesParams.size]: number;
  [EFPAllowedOnboardingTemplatesParams.page]: number;
  [EFPAllowedOnboardingTemplatesParams.sort]: EFPAllowedOnboardingTemplatesSort[];
}

export const CFPAllowedOnboardingTemplatesParamsDefault = {
  [EFPAllowedOnboardingTemplatesParams.size]: 10,
  [EFPAllowedOnboardingTemplatesParams.page]: 1,
  [EFPAllowedOnboardingTemplatesParams.sort]: [
    EFPAllowedOnboardingTemplatesSort.createdDateAsc,
  ],
};

export interface IFPAllowedOnboardingTemplates {
  id: string;
  templateName: string;
  addedOn: string;
  status: string;
}

export interface IFPAllowedOnboardingTemplatesResponse
  extends IResponseBase<IResponseData<IFPAllowedOnboardingTemplates>> {}
