import { Injectable } from '@angular/core';
import {
  CMspMerchantApplicationParamsDefault,
  IMspMerchantApplication,
  IMspMerchantApplicationListParams,
  IMspStoredMerchantApplicationItem,
} from '@interfaces/authentication/msp-merchant-application-list.interface';
import {
  EMspApplicationStatus,
  EMspMerchantApplicationListParams,
  EMspMerchantApplicationListSort,
  EMspMerchantSignerStatus,
  EMspStoredMerchantApplicationSelectedItem,
} from '@parameters/authentication/msp-merchant-application-list.parameter';
import { BehaviorSubject, Observable } from 'rxjs';
import { MspMerchantApplicationImplementService } from './msp-merchant-application-implement.service';
import { MspMerchantApplicationListResponse } from '@models/authentication/msp-merchant-application/msp-merchant-application-list';
import { CookieService } from 'ngx-cookie-service';
import { AuthSession } from 'src/app/auth/models/auth-session.enum';
import { ApmUserService } from "@stores/apm-user.service";

@Injectable({ providedIn: 'root' })
export class MspMerchantApplicationStoreService {
  private _mids: string[] = [];
  private _merchantIdsInProgress: string[] = [];
  private _merchantIds: string[] = [];
  private _applicationListIds: IMspMerchantApplication[] = [];
  private _selectedMerchantApplicationItem: IMspMerchantApplication =
    {} as IMspMerchantApplication;
  private merchantApplicationListSubject: BehaviorSubject<MspMerchantApplicationListResponse> =
    new BehaviorSubject<MspMerchantApplicationListResponse>(undefined);
  /**
   * filter queries
   */
  private filterChangeSubject: BehaviorSubject<boolean>;
  private filterCleanSubject: BehaviorSubject<boolean>;
  private _size: number;
  private _page: number;
  private _sort: EMspMerchantApplicationListSort;
  private _search: string;
  private _status: EMspApplicationStatus[];
  private _filterDirty = false;
  private currentMerchantSubject = new BehaviorSubject<IMspMerchantApplication>(
    null
  );
  currentMerchant = this.currentMerchantSubject.asObservable();
  constructor(
    private mspMerchantApplicationImplementService: MspMerchantApplicationImplementService,
    private cookieService: CookieService,
    private apmStoreService: ApmUserService
  ) {}

  set selectedMerchantApplicationItem(
    selectedMerchantApplicationItem: IMspMerchantApplication
  ) {
    this._selectedMerchantApplicationItem = selectedMerchantApplicationItem;
  }

  public get selectedMerchantApplicationItem(): IMspMerchantApplication {
    return this._selectedMerchantApplicationItem;
  }

  set merchantIdsInProgress(merchantIdsInProgress: string[]) {
    this._merchantIdsInProgress = merchantIdsInProgress;
  }

  public get merchantIdsInProgress(): string[] {
    return this._merchantIdsInProgress;
  }

  set merchantIds(merchantIds: string[]) {
    this._merchantIds = merchantIds;
  }

  public get merchantIds(): string[] {
    return this._merchantIds;
  }

  set mids(mids: string[]) {
    this._mids = mids;
  }

  public get mids(): string[] {
    return this._mids;
  }

  public get isMerchantHasOnlyOneBusiness(): boolean {
    return this.merchantIdsInProgress.length === 1 || this.mids.length === 1;
  }

  public get isPrimarySignerApplicationCompletedOrApproved(): boolean {
    return this.mids.length >= 1;
  }

  set applicationListIds(applicationListIds: IMspMerchantApplication[]) {
    this._applicationListIds = applicationListIds;
  }

  public get applicationListIds(): IMspMerchantApplication[] {
    return this._applicationListIds;
  }

  get merchantApplicationListSubject$(): Observable<MspMerchantApplicationListResponse> {
    return this.merchantApplicationListSubject.asObservable();
  }

  set merchantApplicationListSubject$(
    value: MspMerchantApplicationListResponse
  ) {
    this.merchantApplicationListSubject.next(value);
  }

  /**
   * get stored state MIDS, MERCHANTIDSPROGRESS, MERCHANTIDS
   */

  get getStoredMids(): string[] {
    try {
      return (
        (this.cookieService.get(AuthSession.mids) &&
          (JSON?.parse(
            this.cookieService.get(AuthSession.mids)
          ) as unknown as Array<string>)) ||
        []
      );
    } catch (err) {}
  }

  get getStoredMerchantIdsProgress(): string[] {
    try {
      return (
        (this.cookieService.get(AuthSession.merchantIdsInProgress) &&
          (JSON?.parse(
            this.cookieService.get(AuthSession.merchantIdsInProgress)
          ) as unknown as Array<string>)) ||
        []
      );
    } catch (err) {}
  }

  get getStoredMerchantIds(): string[] {
    try {
      return (
        (this.cookieService.get(AuthSession.merchantIds) &&
          (JSON?.parse(
            this.cookieService.get(AuthSession.merchantIds)
          ) as unknown as Array<string>)) ||
        []
      );
    } catch (err) {}
  }

  get isPrimarySignerAtleatOneBusinessApproved(): boolean {
    try {
      return this.getStoredMids.length > 0;
    } catch (err) {}
  }

  get isPrimarySignerNoBusinessApproved(): boolean {
    try {
      return this.getStoredMerchantIdsProgress.length === 0;
    } catch (err) {}
  }

  get isOnlyOneBusinessInProgress(): boolean {
    try {
      return this.getStoredMerchantIdsProgress.length === 1;
    } catch (err) {}
  }

  get isBusinessInProgressMoreThanOne(): boolean {
    try {
      return this.getStoredMerchantIdsProgress.length > 1;
    } catch (err) {}
  }

  get isBusinessInProgressEmpty(): boolean {
    try {
      return this.getStoredMerchantIdsProgress.length === 0;
    } catch (err) {}
  }

  /**
   * Selected Merchant business detail
   */

  public setSelectedProfile() {
    const selectedProfile = this.selectedMerchantApplicationItem;
    this.currentMerchantSubject.next(selectedProfile);
    const params: IMspStoredMerchantApplicationItem = {
      [EMspStoredMerchantApplicationSelectedItem.selectedApplicationMerchantId]:
        selectedProfile.merchantId,
      [EMspStoredMerchantApplicationSelectedItem.selectedApplicationMerchantBusiness]:
        selectedProfile.businessName,
      [EMspStoredMerchantApplicationSelectedItem.selectedApplicationCurrentProspectStatusId]:
        selectedProfile.currentProspectStatusId,
      [EMspStoredMerchantApplicationSelectedItem.selectedApplicationOfferId]:
        selectedProfile.offerId,
      [EMspStoredMerchantApplicationSelectedItem.selectedApplicationPageId]:
        selectedProfile.currentPageId,
      [EMspStoredMerchantApplicationSelectedItem.selectedApplicationSignatureRole]:
        selectedProfile.signatureRole,
      [EMspStoredMerchantApplicationSelectedItem.selectedApplicationStatus]:
        selectedProfile.status,
    };
    this.currentSelectedOfferId =
      this.selectedMerchantApplicationItem.offerId || '';
    this.currentSelectedMerchantId =
      this.selectedMerchantApplicationItem.merchantId || '';
    this.cookieService.set(
      AuthSession.selectedMerchantApplication,
      JSON.stringify(params),
      {
        path: '/',
      }
    );
  }

  get currentSelectedMerchantId(): string {
    return this.cookieService.get(AuthSession.currentSelectedMerchantId) || '';
  }

  set currentSelectedMerchantId(id: string) {
    this.cookieService.set(AuthSession.currentSelectedMerchantId, id, {
      path: '/',
    });
  }

  get currentSelectedOfferId(): string {
    return this.cookieService.get(AuthSession.offerId) || '';
  }

  set currentSelectedOfferId(id: string) {
    this.cookieService.set(AuthSession.offerId, id, {
      path: '/',
    });
  }

  get getStoredSelectedMerchantApplicationData(): IMspStoredMerchantApplicationItem {
    try {
      return (
        (this.cookieService.get(AuthSession.selectedMerchantApplication) &&
          (JSON?.parse(
            this.cookieService.get(AuthSession.selectedMerchantApplication)
          ) as unknown as IMspStoredMerchantApplicationItem)) ||
        ({} as IMspStoredMerchantApplicationItem)
      );
    } catch (err) {}
  }

  get selectedMerchantId(): string {
    return (
      this.getStoredSelectedMerchantApplicationData
        .selectedApplicationMerchantId || ''
    );
  }

  get selectedApplicationMerchantBusiness(): string {
    return (
      this.getStoredSelectedMerchantApplicationData
        .selectedApplicationMerchantBusiness || ''
    );
  }

  get selectedApplicationCurrentProspectStatusId(): string {
    return (
      this.getStoredSelectedMerchantApplicationData
        .selectedApplicationCurrentProspectStatusId || ''
    );
  }

  get selectedApplicationOfferId(): string {
    return (
      this.getStoredSelectedMerchantApplicationData
        .selectedApplicationOfferId || ''
    );
  }

  get selectedApplicationPageId(): string {
    return (
      this.getStoredSelectedMerchantApplicationData.selectedApplicationPageId ||
      ''
    );
  }

  get selectedApplicationSignatureRole(): string {
    return (
      this.getStoredSelectedMerchantApplicationData
        .selectedApplicationSignatureRole || ''
    );
  }

  get selectedApplicationStatus(): string {
    return (
      this.getStoredSelectedMerchantApplicationData.selectedApplicationStatus ||
      ''
    );
  }

  /**
   *
   * @param res login response
   */

  public setRequiredInfoCookies(res) {
    const { data } = res;
    const { token, refreshToken, paymentAuthToken, roles, firstName, lastName } = data;
    const username = `${firstName} ${lastName}`;
    this.cookieService.set(AuthSession.isoAccessToken, token, {
      path: '/',
    });
    this.cookieService.set(AuthSession.refreshToken, refreshToken, {
      path: '/',
    });
    this.cookieService.set(AuthSession.paymentAuthToken, paymentAuthToken, {
      path: '/',
    });
    this.cookieService.set(AuthSession.roles, JSON.stringify(roles), {
      path: '/',
    });
    this.cookieService.set(AuthSession.username, JSON.stringify(username), {
      path: '/',
    });
    const {
      mids,
      merchantIdsInProgress,
      merchantIds,
      primarySignerMerchantIds,
      secondarySignerMerchantIds,
    } = data;
    this.cookieService.set(AuthSession.mids, JSON.stringify(mids), {
      path: '/',
    });
    this.cookieService.set(
      AuthSession.merchantIdsInProgress,
      JSON.stringify(merchantIdsInProgress),
      {
        path: '/',
      }
    );
    this.cookieService.set(
      AuthSession.merchantIds,
      JSON.stringify(merchantIds),
      {
        path: '/',
      }
    );
    this.cookieService.set(
      AuthSession.primarySignerMerchantIds,
      JSON.stringify(primarySignerMerchantIds),
      {
        path: '/',
      }
    );
    this.cookieService.set(
      AuthSession.secondarySignerMerchantIds,
      JSON.stringify(secondarySignerMerchantIds),
      {
        path: '/',
      }
    );
    this.mids = mids;
    this.merchantIdsInProgress = merchantIdsInProgress;
    this.merchantIds = merchantIds;

    this.apmStoreService.setUserContext();
  }

  /**
   *
   * @returns api response
   */
  getMerchantApplicationList() {
    this.status = [
      EMspApplicationStatus.progress,
      EMspApplicationStatus.active,
    ];
    const params: IMspMerchantApplicationListParams = {
      [EMspMerchantApplicationListParams.size]: this.size,
      [EMspMerchantApplicationListParams.page]: this.page,
      [EMspMerchantApplicationListParams.sort]: this.sort,
      [EMspMerchantApplicationListParams.search]: this.search,
      [EMspMerchantApplicationListParams.status]: this.status,
    };
    return this.mspMerchantApplicationImplementService.getMerchantApplicationList(
      params
    );
  }

  public get size() {
    return this._size;
  }

  public set size(size: number) {
    this._size = size;
  }

  public get page() {
    return this._page;
  }

  public set page(page: number) {
    this._page = page;
  }

  public get search() {
    return this._search;
  }

  public set search(search: string) {
    this._search = search;
    this.page =
      CMspMerchantApplicationParamsDefault[
        EMspMerchantApplicationListParams.page
      ];
  }

  public get sort() {
    return this._sort;
  }

  public set sort(sort: EMspMerchantApplicationListSort) {
    this._sort = sort;
    this.page =
      CMspMerchantApplicationParamsDefault[
        EMspMerchantApplicationListParams.page
      ];
  }

  public get status(): EMspApplicationStatus[] {
    return this._status;
  }

  public set status(status: EMspApplicationStatus[]) {
    this._status = status;
    this.page =
      CMspMerchantApplicationParamsDefault[
        EMspMerchantApplicationListParams.page
      ];
  }

  get chooseAppropriateStatus(): EMspMerchantSignerStatus[] {
    return this.isBusinessInProgressEmpty &&
      this.isPrimarySignerAtleatOneBusinessApproved
      ? [EMspMerchantSignerStatus.accepted]
      : this.isPrimarySignerNoBusinessApproved &&
        !this.isBusinessInProgressEmpty
      ? [EMspMerchantSignerStatus.pending]
      : [EMspMerchantSignerStatus.pending, EMspMerchantSignerStatus.accepted] ||
        [];
  }

  get filterChange$(): Observable<boolean> {
    return this.filterChangeSubject.asObservable();
  }

  set filterChange(value: boolean) {
    this.dirtyFilter();
    this.filterChangeSubject.next(value);
  }

  get filterClean$(): Observable<boolean> {
    return this.filterCleanSubject.asObservable();
  }

  set filterClean(state: boolean) {
    this.defaultValues();

    this.filterChange = true;
    this.filterCleanSubject.next(state);
  }

  private defaultValues() {
    this._size =
      CMspMerchantApplicationParamsDefault[
        EMspMerchantApplicationListParams.size
      ];
    this._sort =
      CMspMerchantApplicationParamsDefault[
        EMspMerchantApplicationListParams.sort
      ];

    this.status =
      CMspMerchantApplicationParamsDefault[
        EMspMerchantApplicationListParams.status
      ];
    this._page =
      CMspMerchantApplicationParamsDefault[
        EMspMerchantApplicationListParams.page
      ];
    this._search =
      CMspMerchantApplicationParamsDefault[
        EMspMerchantApplicationListParams.search
      ];
  }

  get filterDirty() {
    return this._filterDirty;
  }

  private dirtyFilter() {
    const status = !!this.status;
    const search = !!this.search;

    this._filterDirty = status || search;
  }
}
