import { Injectable } from '@angular/core';
import { GenericClientService } from '@clients/generic/generic-client.service';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import { HttpParams } from '@angular/common/http';
import {
  CUserPartnerEligibleDomains,
  EUserListParams,
  EUserPartnerEligibleDomains,
} from '@parameters/ops-and-uw-tools/users/user-list.parameter';
import {
  CUserListParamsDefault,
  IUserListParams,
  IUserListResponse,
} from '@interfaces/ops-and-uw-tools/users/user-list.interface';
import {
  IInternalUserDetail,
  IMerchantUserDetail,
} from '@interfaces/ops-and-uw-tools/users/user-detail.interface';
import { Observable } from 'rxjs';
import { GenericHelper } from '@helpers/generic.helper';
import { EUserType } from '@parameters/ops-and-uw-tools/users/user.parameter';
import {
  IUserCreatePayload,
  IUserCreateResponse,
} from '@interfaces/ops-and-uw-tools/users/user-create.interface';
import {
  CUserDetailListParamsDefault,
  IUserDetailListParams,
  IUserDetailListResponse,
} from '@interfaces/ops-and-uw-tools/users/user-detail-list.interface';
import { EUserDetailListParams } from '@parameters/ops-and-uw-tools/users/user-detail-list.parameter';
import { IResponseBase } from '@interfaces/generic/responses.interface';
import {
  IPartnerUserAssociatedBins,
  IPartnerUserAssociatedEntities,
} from '@interfaces/ops-and-uw-tools/users/user-associate.interface';
import { IUserPartnerDomainsParams } from '@interfaces/ops-and-uw-tools/users/user-partner-domains.interface';

@Injectable()
export class UsersAssociateClientService {
  constructor(private genericClient: GenericClientService) {}

  getUserList(
    queryParams: IUserListParams,
    type: EUserType
  ): Observable<IUserListResponse> {
    const endpoint =
      type === EUserType.merchant
        ? EndpointsParameter.MerchantUsersList
        : type === EUserType.partner
        ? EndpointsParameter.PartnerUsersList
        : EndpointsParameter.InternalUsersList;
    let params = new HttpParams()
      .set(
        EUserListParams.size,
        queryParams[EUserListParams.size] ||
          CUserListParamsDefault[EUserListParams.size]
      )
      .set(
        EUserListParams.page,
        queryParams[EUserListParams.page] ||
          CUserListParamsDefault[EUserListParams.page]
      )
      .set(
        EUserListParams.sort,
        queryParams[EUserListParams.sort] ||
          CUserListParamsDefault[EUserListParams.sort]
      )
      .set(
        EUserListParams.search,
        queryParams[EUserListParams.search] ||
          CUserListParamsDefault[EUserListParams.search]
      )
      // .set(
      //   EUserListParams.name,
      //   queryParams[EUserListParams.name] ||
      //   CUserListParamsDefault[EUserListParams.name]
      // )
      // .set(
      //   EUserListParams.email,
      //   queryParams[EUserListParams.email] ||
      //   CUserListParamsDefault[EUserListParams.email]
      // )
      .set(
        EUserListParams.status,
        queryParams[EUserListParams.status] ||
          CUserListParamsDefault[EUserListParams.status]
      );

    return this.genericClient.genericGet<IUserListResponse>(endpoint, {
      params,
    });
  }

  getUserDetail(
    id: string,
    type: EUserType
  ): Observable<IResponseBase<IInternalUserDetail | IMerchantUserDetail>> {
    const endpoint =
      type === EUserType.merchant
        ? EndpointsParameter.MerchantUsersList
        : type === EUserType.partner
        ? EndpointsParameter.PartnerUsersList
        : EndpointsParameter.InternalUsersList;

    return this.genericClient.genericGet<
      IResponseBase<IInternalUserDetail | IMerchantUserDetail>
    >(`${endpoint}/${id}`);
  }

  createUser(payload: IUserCreatePayload): Observable<IUserCreateResponse> {
    const endpoint = EndpointsParameter.UsersCreate;
    return this.genericClient.genericPost<IUserCreateResponse>(
      endpoint,
      payload
    );
  }

  updateUser(payload: IUserCreatePayload): Observable<IUserCreateResponse> {
    const endpoint = EndpointsParameter.UsersCreate;
    return this.genericClient.genericPut<IUserCreateResponse>(
      endpoint,
      payload
    );
  }

  getUserDetailList(
    id: string,
    queryParams: IUserDetailListParams
  ): Observable<IUserDetailListResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.UserDetail,
      id
    );
    let params = new HttpParams()
      .set(
        EUserDetailListParams.size,
        queryParams[EUserDetailListParams.size] ||
          CUserDetailListParamsDefault[EUserDetailListParams.size]
      )
      .set(
        EUserDetailListParams.page,
        queryParams[EUserDetailListParams.page] ||
          CUserDetailListParamsDefault[EUserDetailListParams.page]
      )
      .set(
        EUserDetailListParams.sort,
        queryParams[EUserDetailListParams.sort]?.join(',') ||
          CUserDetailListParamsDefault[EUserDetailListParams.sort]?.join(',')
      )
      .set(
        EUserDetailListParams.search,

        queryParams[EUserDetailListParams.search] ||
          CUserDetailListParamsDefault[EUserDetailListParams.search]
      );
    return this.genericClient.genericGet<IUserDetailListResponse>(endpoint, {
      params,
    });
  }

  getUserAssociatedAccounts(
    id: string,
    queryParams: IUserDetailListParams
  ): Observable<IUserDetailListResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.MerchantUserAssociatedAccount,
      id
    );
    let params = new HttpParams()
      .set(
        EUserDetailListParams.size,
        queryParams[EUserDetailListParams.size] ||
          CUserDetailListParamsDefault[EUserDetailListParams.size]
      )
      .set(
        EUserDetailListParams.page,
        queryParams[EUserDetailListParams.page] ||
          CUserDetailListParamsDefault[EUserDetailListParams.page]
      )
      .set(
        EUserDetailListParams.sort,
        queryParams[EUserDetailListParams.sort]?.join(',') ||
          CUserDetailListParamsDefault[EUserDetailListParams.sort]?.join(',')
      )
      .set(
        EUserDetailListParams.search,
        queryParams[EUserDetailListParams.search] ||
          CUserDetailListParamsDefault[EUserDetailListParams.search]
      );
    return this.genericClient.genericGet<IUserDetailListResponse>(endpoint, {
      params,
    });
  }

  getPartnerAssociatedEntities(
    id: string,
    queryParams: IUserDetailListParams
  ): Observable<IPartnerUserAssociatedEntities> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.PartnerUserAssociatedAccount,
      id
    );
    let params = new HttpParams()
      .set(
        EUserDetailListParams.size,
        queryParams[EUserDetailListParams.size] ||
          CUserDetailListParamsDefault[EUserDetailListParams.size]
      )
      .set(
        EUserDetailListParams.page,
        queryParams[EUserDetailListParams.page] ||
          CUserDetailListParamsDefault[EUserDetailListParams.page]
      )
      .set(
        EUserDetailListParams.sort,
        queryParams[EUserDetailListParams.sort]?.join(',') ||
          CUserDetailListParamsDefault[EUserDetailListParams.sort]?.join(',')
      )
      .set(
        EUserDetailListParams.search,
        queryParams[EUserDetailListParams.search] ||
          CUserDetailListParamsDefault[EUserDetailListParams.search]
      );
    return this.genericClient.genericGet<IPartnerUserAssociatedEntities>(
      endpoint,
      {
        params,
      }
    );
  }

  getPartnerAssociatedBins(
    id: string,
    queryParams: IUserDetailListParams
  ): Observable<IPartnerUserAssociatedBins> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.PartnerUserAssociatedBins,
      id
    );
    let params = new HttpParams()
      .set(
        EUserDetailListParams.size,
        queryParams[EUserDetailListParams.size] ||
          CUserDetailListParamsDefault[EUserDetailListParams.size]
      )
      .set(
        EUserDetailListParams.page,
        queryParams[EUserDetailListParams.page] ||
          CUserDetailListParamsDefault[EUserDetailListParams.page]
      )
      .set(
        EUserDetailListParams.sort,
        queryParams[EUserDetailListParams.sort]?.join(',') ||
          CUserDetailListParamsDefault[EUserDetailListParams.sort]?.join(',')
      )
      .set(
        EUserDetailListParams.search,
        queryParams[EUserDetailListParams.search] ||
          CUserDetailListParamsDefault[EUserDetailListParams.search]
      );
    return this.genericClient.genericGet<IPartnerUserAssociatedBins>(endpoint, {
      params,
    });
  }

  merchantAssociatedAccounts(
    queryParams: IUserDetailListParams
  ): Observable<IUserDetailListResponse> {
    const endpoint = EndpointsParameter.AllAssociatedMerchants;
    let params = new HttpParams()
      .set(
        EUserDetailListParams.size,
        queryParams[EUserDetailListParams.size] ||
          CUserDetailListParamsDefault[EUserDetailListParams.size]
      )
      .set(
        EUserDetailListParams.page,
        queryParams[EUserDetailListParams.page] ||
          CUserDetailListParamsDefault[EUserDetailListParams.page]
      )
      .set(
        EUserDetailListParams.sort,
        queryParams[EUserDetailListParams.sort]?.join(',') ||
          CUserDetailListParamsDefault[EUserDetailListParams.sort]?.join(',')
      )
      .set(
        EUserDetailListParams.search,
        queryParams[EUserDetailListParams.search] ||
          CUserDetailListParamsDefault[EUserDetailListParams.search]
      );
    return this.genericClient.genericGet<IUserDetailListResponse>(endpoint, {
      params,
    });
  }

  addAssociatedAccounts(
    ids: string[],
    userId: string
  ): Observable<IResponseBase<null>> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.MerchantUserAssociatedAccount,
      userId
    );
    return this.genericClient.genericPost<IResponseBase<null>>(endpoint, {
      accountIds: ids?.length ? ids : [],
    });
  }

  addAssociatedEntities(
    ids: string[],
    userId: string
  ): Observable<IResponseBase<null>> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.PartnerUserAssociatedAccount,
      userId
    );
    return this.genericClient.genericPost<IResponseBase<null>>(endpoint, {
      entityIds: ids?.length ? ids : [],
    });
  }

  addAssociatedBins(
    ids: string[],
    userId: string
  ): Observable<IResponseBase<null>> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.PartnerUserAssociatedBins,
      userId
    );
    return this.genericClient.genericPost<IResponseBase<null>>(endpoint, {
      bins: ids?.length ? ids : [],
    });
  }

  sendWelcomeEmail(
    userId: string,
    domainId: string,
    userType: EUserType
  ): Observable<IResponseBase<{ link: string }>> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.UserSendWelcomeEmail?.replace(
        '???',
        userType === EUserType.merchant
          ? 'merchants'
          : userType === EUserType.partner
          ? 'partners'
          : 'internal'
      ),
      userId
    );

    return this.genericClient.genericPost<IResponseBase<{ link: string }>>(
      endpoint,
      {
        domainId: domainId,
      }
    );
  }

  sendResetPassword(
    userId: string,
    domainId: string,
    userType: EUserType
  ): Observable<IResponseBase<{ link: string }>> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.UserSendPasswordEmail?.replace(
        '???',
        userType === EUserType.merchant
          ? 'merchants'
          : userType === EUserType.partner
          ? 'partners'
          : 'internal'
      ),
      userId
    );

    return this.genericClient.genericPost<IResponseBase<{ link: string }>>(
      endpoint,
      {
        domainId: domainId,
      }
    );
  }

  /** Eligible domains */
  getEligibleDomains(userId: string, params: IUserPartnerDomainsParams) {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.PartnerEligibleDomains,
      userId
    );

    const httpParams = new HttpParams()
      .set(
        EUserPartnerEligibleDomains.page,
        params[EUserPartnerEligibleDomains.page] ||
          CUserPartnerEligibleDomains[EUserPartnerEligibleDomains.page]
      )
      .set(
        EUserPartnerEligibleDomains.limit,
        params[EUserPartnerEligibleDomains.limit] ||
          CUserPartnerEligibleDomains[EUserPartnerEligibleDomains.limit]
      )
      .set(
        EUserPartnerEligibleDomains.search,
        params[EUserPartnerEligibleDomains.search] ||
          CUserPartnerEligibleDomains[EUserPartnerEligibleDomains.search]
      );

    return this.genericClient.genericGet(endpoint, { params: httpParams });
  }
}
