import { Injectable } from '@angular/core';
import {
  IProspectUpload,
  IProspectsUploadParams,
  IUploadRequiredDocument,
} from '@interfaces/prospects/prospects-uploads.interface';
import {
  CProspectsUploadParams,
  EProspectsUploadParams,
} from '@parameters/prospects/prospects-uploads.parameter';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProspectsInformationRequestImplementService } from './prospect-information-req-implement.service';

@Injectable()
export class ProspectsInformationRequestStoreService {
  /** Filters for Uploaded Documents List */
  private _filterChange = new BehaviorSubject<boolean>(true);
  private _filterRequestDocumentsChange = new BehaviorSubject<boolean>(true);
  private _filterClean = new BehaviorSubject<boolean>(true);
  private _filterDirty: boolean = false;

  private _page: number = CProspectsUploadParams[EProspectsUploadParams.page];
  private _limit: number = CProspectsUploadParams[EProspectsUploadParams.limit];
  private _search: string =
    CProspectsUploadParams[EProspectsUploadParams.search];
  private _sort: string[] = CProspectsUploadParams[EProspectsUploadParams.sort];

  constructor(
    private readonly _prospectUploadImplement: ProspectsInformationRequestImplementService
  ) {}

  /**
   * Get list of required documents
   * @param prospectId
   * @returns
   */
  public getInfoRequests(prospectId: string, params?: { search: '' }) {
    return this._prospectUploadImplement.getRequiredDocuments(
      prospectId,
      params
    );
  }

  /** Method for Documents List Params */
  public set page(value: number) {
    this._page = value || CProspectsUploadParams[EProspectsUploadParams.page];
    this.filterChange();
  }

  public set limit(value: number) {
    this._limit = value || CProspectsUploadParams[EProspectsUploadParams.limit];
  }

  public set sort(value: string[]) {
    this._sort = value || CProspectsUploadParams[EProspectsUploadParams.sort];
    this.page = CProspectsUploadParams[EProspectsUploadParams.page];
  }

  public set search(value: string) {
    this._search =
      value || CProspectsUploadParams[EProspectsUploadParams.search];
    this.page = CProspectsUploadParams[EProspectsUploadParams.page];
  }

  public get page() {
    return this._page;
  }

  public get limit() {
    return this._limit;
  }

  public get sort() {
    return this._sort;
  }

  public get search() {
    return this._search;
  }

  public get filterChange$(): Observable<boolean> {
    return this._filterChange.asObservable();
  }

  public filterChange() {
    this.filterDirty = true;
    this._filterChange.next(true);
  }

  public get filterRequestDocumentsChange$(): Observable<boolean> {
    return this._filterRequestDocumentsChange.asObservable();
  }
  public filterRequestDocumentsChange() {
    this.filterChange();
    this._filterRequestDocumentsChange.next(true);
  }

  public get filterClean$(): Observable<boolean> {
    return this._filterClean.asObservable();
  }

  public filterClean() {
    this.defaultValues();

    this.filterChange();
    this._filterClean.next(true);
  }

  public get filterDirty() {
    return this._filterDirty;
  }

  public set filterDirty(value: boolean) {
    this._filterDirty = value;
  }

  public defaultValues() {
    this._page = CProspectsUploadParams[EProspectsUploadParams.page];
    this._limit = CProspectsUploadParams[EProspectsUploadParams.limit];
    this._sort = CProspectsUploadParams[EProspectsUploadParams.sort];
    this._search = CProspectsUploadParams[EProspectsUploadParams.search];
  }

  public get filterParams(): IProspectsUploadParams {
    return {
      [EProspectsUploadParams.page]: this.page,
      [EProspectsUploadParams.limit]: this.limit,
      [EProspectsUploadParams.search]: this.search,
      [EProspectsUploadParams.sort]: this.sort,
    };
  }

  /** Method for Documents List Params End */

  public uploadRequiredDocuments(files: IProspectUpload[], prospectId: string) {
    const body: IUploadRequiredDocument = {
      docs: files,
    };

    return this._prospectUploadImplement.uploadRequiredDocuments(
      body,
      prospectId
    );
  }
}
