import {
  CDateFilterListValue,
  EDateFilter,
} from '@parameters/filter/date.parameter';

export const enum EReportsVolumeListParams {
  size = 'limit',
  page = 'page',
  sort = 'order_by',
  search = 'search',
  startDate = 'start_date',
  endDate = 'end_date',
  midList = 'mids',
  binList = 'bins',
  cardBrandList = 'card_brands',
}

export const enum EReportsVolumeListSort {
  createdDateAsc = 'created_date:asc',
  createdDateDesc = 'created_date:desc',
}

export const CReportsVolumeListParamsDate =
  CDateFilterListValue[EDateFilter.last30Days];

export enum EReportsVolumeCSVParams {
  search = 'search',
  startDate = 'startDate',
  endDate = 'endDate',
  midList = 'mids',
  binList = 'bins',
  cardBrandList = 'cardBrands',
}
