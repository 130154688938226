import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NavbarEventbusService } from '../navbar-eventbus.service';
import { AuthenticationService } from 'src/app/auth/service';
import { CommonService } from 'src/app/shared/services/common.service';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { AuthSession } from 'src/app/auth/models/auth-session.enum';

@Component({
  selector: 'app-nav-profile',
  templateUrl: './nav-profile.component.html',
  styleUrls: ['./nav-profile.component.scss'],
})
export class NavProfileComponent implements OnInit {
  @ViewChild('navProfileMenu') navProfileMenu: ElementRef | undefined;
  username: string;
  @HostListener('document:click', ['$event.target'])
  public onClickOutside(targetElement: HTMLElement) {
    const clickedInsideMenu =
      this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInsideMenu) {
      this.navEventService.isNavProfileMenuVisible = false;
    }
  }

  public userRole: string;
  private subscription: Subscription = new Subscription();

  constructor(
    private elementRef: ElementRef,
    public navEventService: NavbarEventbusService,
    private _authService: AuthenticationService,
    private _cmnService: CommonService,
    private _cookieService: CookieService
  ) {}

  toggleDropdown() {
    this.navEventService.isNavProfileMenuVisible =
      !this.navEventService.isNavProfileMenuVisible;
  }

  ngOnInit(): void {
    const roles = this._authService.userRoles;
    if (roles && roles.length) {
      // this.userRole = 'roles.join(', ')';
    }

    if (this._cookieService.get(AuthSession.username)) {
      const username = JSON.parse(
        this._cookieService.get(AuthSession.username)
      );
      this.username = username;
    }
    const s1 = this._cmnService.currentUser.subscribe((username) => {
      this.username = username;
    });
    this.subscription.add(s1);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit() {}

  logout() {
    this._authService.logout();
    this.navEventService.isNavProfileMenuVisible = false;
  }
}
