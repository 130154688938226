import { Injectable, inject } from '@angular/core';
import { PartnerAccountSelectClientService } from '@clients/developers/partner-account-select-client-service';
import { IPartnerAccountListParams } from '@interfaces/developers/partner-account-select.interface';
import { PartnerSelectAccountsListResponse } from '@models/developers/partner-account-select.model';
import { Observable, map } from 'rxjs';

@Injectable({providedIn: "root"})
export class PartnerAccountSelectImplementService {
  private readonly _partnerAccountClient = inject(
    PartnerAccountSelectClientService
  );

  public getPartnerAccounts(
    params: IPartnerAccountListParams
  ): Observable<PartnerSelectAccountsListResponse> {
    return this._partnerAccountClient
      .getPartnerAccounts(params)
      .pipe(map((res) => new PartnerSelectAccountsListResponse(res)));
  }
}
