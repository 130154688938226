import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[btnContainer]',
})
export class BtnContainerDirective {
  /**
   * container styles
   */
  private styles = {
    width: 'auto',
    overflow: 'auto',
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
    //...and so on
  };

  @HostListener('window:resize', ['$event'])
  public onResize(event) {
    // Rewrite media query css by calling addStyles Method based on window.innerWidth condition
  }

  // Private
  private _nativeElement: any;
  /**
   * Constructor
   *
   * @param {ElementRef} _elementRef
   */
  constructor(private _elementRef: ElementRef, private renderer: Renderer2) {
    this.addStyles();
  }

  addStyles(): void {
    // Get the native element
    this._nativeElement = this._elementRef.nativeElement;

    Object.keys(this.styles).forEach((element) => {
      this.renderer.setStyle(
        this._nativeElement,
        `${element}`,
        this.styles[element]
      );
    });
  }
}
