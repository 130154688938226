import { getReportsResidualDateFilterListValue } from './residual-date';

export const enum EReportsResidualListParams {
  size = 'limit',
  page = 'page',
  sort = 'order_by',
  search = 'search',
  startDate = 'start_date',
  endDate = 'end_date',
  midList = 'mids',
  binList = 'bins',
  agentCodeList = 'agent_code',
  status = 'status',
}

export const enum EReportsResidualCSVParams {
  search = 'search',
  startDate = 'startDate',
  endDate = 'endDate',
  midList = 'mids',
  binList = 'bins',
  agentCodeList = 'agentCode',
  status = 'status',
}

export const enum EReportsResidualListSort {
  createdDateAsc = 'created_date:asc',
  createdDateDesc = 'created_date:desc',
}

/**
 * Table column names label
 */
export const enum EReportsResidualListTableHeadLabel {
  dbaName = 'DBA NAME | MID',
  agentName = 'agent | rep code',
  resMonth = 'res.month',
  status = 'status',
  revShare = 'rev share',
  commision = 'commission',
  salesVol = 'sales vol.',
  salesCount = 'sales count',
  totalIncome = 'total income',
  totalExpense = 'total expense',
  netIncome = 'net income',
}

/**
 * Table column Sorting lables
 */
export const enum EReportsResidualListParamSort {
  dbaNameAsc = 'dbaName:asc',
  dbaNameDesc = 'dbaName:desc',
}

export enum EReportsResidualListParamStatus {
  active = 'active',
  closed = 'closed',
  suspended = 'suspended',
}

export const CReportsResidualListParamStatusLabel = {
  [EReportsResidualListParamStatus.active]: 'Active',
  [EReportsResidualListParamStatus.closed]: 'Closed',
  [EReportsResidualListParamStatus.suspended]: 'Suspended',
};

export const CReportsResidualListParamStatusColor = {
  [EReportsResidualListParamStatus.active]: 'success',
  [EReportsResidualListParamStatus.closed]: 'dark',
  [EReportsResidualListParamStatus.suspended]: 'danger',
};

export const CReportsResidualListParamStatus = [
  {
    label:
      CReportsResidualListParamStatusLabel[
        EReportsResidualListParamStatus.active
      ],
    value: EReportsResidualListParamStatus.active,
  },
  {
    label:
      CReportsResidualListParamStatusLabel[
        EReportsResidualListParamStatus.closed
      ],
    value: EReportsResidualListParamStatus.closed,
  },
  {
    label:
      CReportsResidualListParamStatusLabel[
        EReportsResidualListParamStatus.suspended
      ],
    value: EReportsResidualListParamStatus.suspended,
  },
];

export const CReportsResidualListParamsDate =
  getReportsResidualDateFilterListValue();

export const MOCK_RESIDUAL_LIST = {
  success: true,
  code: 200,
  message: 'Relationships retrieved',
  data: {
    summary: {
      totalRows: 151,
      recordsRetrieved: 10,
      page: 1,
      limit: 10,
    },
    other: {
      totalSalesVolume: 1550000.0,
      totalSalesCount: 13000,
      netRevenue: 350000.0,
      totalResidualsPaid: 120000.0,
    },
    rows: [
      {
        id: 27,
        mid: '6320340201027018',
        dBAName: 'Company DBA 1',
        residualMonth: '2023-11-01',
        status: 'active',
        agentName: 'Trevor Galvin',
        agentId: 'XXXXX1-3295-XXX',
        revenueShare: 0.7,
        commissionAmount: 500.0,
        salesVolume: 100000.0,
        salesCount: 500,
        totalIncome: 4500.0,
        totalExpense: 1000.0,
        netIncome: 3500.0,
        referralRecord: false,
      },
      {
        id: 28,
        mid: '6320340201027018',
        dBAName: 'Company DBA 1',
        residualMonth: '2023-11-01',
        status: null,
        agentName: 'Jason Galvin',
        agentId: 'RRRRR1-3262-RRR',
        revenueShare: null,
        commissionAmount: 100.0,
        salesVolume: null,
        salesCount: null,
        totalIncome: null,
        totalExpense: null,
        netIncome: null,
        referralRecord: true,
      },
      {
        id: 29,
        mid: '6320340201027999',
        dBAName: 'Company DBA 2',
        residualMonth: '2023-11-01',
        status: 'active',
        agentName: 'Karen Campbell',
        agentId: '115353-2474-101',
        revenueShare: 0.8,
        commissionAmount: 900.0,
        salesVolume: 200000.0,
        salesCount: 1000,
        totalIncome: 7500.0,
        totalExpense: 1800.0,
        netIncome: 4700.0,
        referralRecord: false,
      },
      {
        id: 30,
        mid: '6320340201025123',
        dBAName: 'Company DBA 3',
        residualMonth: '2023-11-01',
        status: 'closed',
        agentName: 'Allen Kopelman',
        agentId: '121051-2078-278',
        revenueShare: 0.6,
        commissionAmount: 4500.0,
        salesVolume: 3500000.0,
        salesCount: 2400,
        totalIncome: 8300.0,
        totalExpense: 2300.0,
        netIncome: 6000.0,
        referralRecord: false,
      },
      {
        id: 27,
        mid: '6320340201027018',
        dBAName: 'Company DBA 1',
        residualMonth: '2023-11-01',
        status: 'active',
        agentName: 'Trevor Galvin',
        agentId: 'XXXXX1-3295-XXX',
        revenueShare: 0.7,
        commissionAmount: 500.0,
        salesVolume: 100000.0,
        salesCount: 500,
        totalIncome: 4500.0,
        totalExpense: 1000.0,
        netIncome: 3500.0,
        referralRecord: false,
      },
      {
        id: 28,
        mid: '6320340201027018',
        dBAName: 'Company DBA 1',
        residualMonth: '2023-10-01',
        status: null,
        agentName: 'Jason Galvin',
        agentId: 'RRRRR1-3262-RRR',
        revenueShare: null,
        commissionAmount: 100.0,
        salesVolume: null,
        salesCount: null,
        totalIncome: null,
        totalExpense: null,
        netIncome: null,
        referralRecord: true,
      },
      {
        id: 29,
        mid: '6320340201027999',
        dBAName: 'Company DBA 2',
        residualMonth: '2023-10-01',
        status: 'active',
        agentName: 'Karen Campbell',
        agentId: '115353-2474-101',
        revenueShare: 0.8,
        commissionAmount: 900.0,
        salesVolume: 200000.0,
        salesCount: 1000,
        totalIncome: 7500.0,
        totalExpense: 1800.0,
        netIncome: 4700.0,
        referralRecord: false,
      },
      {
        id: 30,
        mid: '6320340201025123',
        dBAName: 'Company DBA 3',
        residualMonth: '2023-10-01',
        status: 'active',
        agentName: 'Allen Kopelman',
        agentId: '121051-2078-278',
        revenueShare: 0.6,
        commissionAmount: 3500.0,
        salesVolume: 2500000.0,
        salesCount: 1400,
        totalIncome: 7300.0,
        totalExpense: 1300.0,
        netIncome: 5000.0,
        referralRecord: false,
      },
    ],
  },
};
