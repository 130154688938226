export enum EUserStatus {
  enabled = 'enabled',
  disabled = 'disabled',
  active = 'active',
  inactive = 'inactive',
}

export const CUserStatusName = {
  [EUserStatus.enabled]: 'Enabled',
  [EUserStatus.disabled]: 'Disabled',
  [EUserStatus.active]: 'Enabled',
  [EUserStatus.inactive]: 'Disabled',
};
export const CUserStatusColor = {
  [EUserStatus.enabled]: 'success',
  [EUserStatus.disabled]: 'dark',
  [EUserStatus.active]: 'success',
  [EUserStatus.inactive]: 'dark',
};

export enum EUserType {
  merchant = 'merchantKey',
  partner = 'partnerKey',
  admin = 'adminKey',
}

export const CUserTypePath = {
  [EUserType.merchant]: 'merchant',
  [EUserType.partner]: 'partner',
  [EUserType.admin]: 'admin',
};

export const CUserTypeKeyFromPath = {
  [CUserTypePath[EUserType.merchant]]: EUserType.merchant,
  [CUserTypePath[EUserType.partner]]: EUserType.partner,
  [CUserTypePath[EUserType.admin]]: EUserType.admin,
};

export const CUserTypeName = {
  [EUserType.merchant]: 'Merchant',
  [EUserType.partner]: 'Partner',
  [EUserType.admin]: 'Admin',
};

export const CUserStatusOptions = [
  { value: EUserStatus.active, label: CUserStatusName[EUserStatus.enabled] },
  { value: EUserStatus.inactive, label: CUserStatusName[EUserStatus.disabled] },
];
