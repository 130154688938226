export const PAYMENT_PATHS = {
  base: 'payments',
  account: 'account',
  home: 'payment',
  requests: 'requests',
  transactions: 'transactions',
  customers: 'customers',
  links: 'links',
  invoices: 'invoices',
  paymentAccountId: 'paymentAccountId',
  createPayment: 'create',
  paymentSuccess: 'payment-success',
  paymentDetail: 'payment-detail',
  invoiceDetail: 'detail',
  paymentSettings: 'settings',
  paymentMethods: 'payment-methods',
  domains: 'domains',
  transfers: 'transfers',
};
