import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  NgZone,
  OnInit,
} from '@angular/core';
import { constants } from 'src/app/shared/helpers/constants';
import {
  APP_ROUTES,
  EOnboardingPageId,
  onboardingSteps,
} from 'src/app/shared/helpers/routes.enum';
import { HttpsService } from 'src/app/shared/services/base.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { CookieService } from 'ngx-cookie-service';
import { OnboardingCustomizationService } from '../../services/onboarding-customization.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, Validators } from '@angular/forms';
import { noWhitespaceValidator } from '@helpers/validators';
import { environment } from '@environments/environment';

export enum Titles {
  shopping = 'Website Shopping Carts',
  ecommerce = 'E-Commerce Platforms',
  integrations = 'Custom Integrations',
}

export enum EOnlinePaymentMethodShortId {
  WebsiteShoppingCarts = 'website_shopping_carts',
  ECommercePlatforms = 'e_commerce_platforms',
  CustomIntegrations = 'custom_integrations',
}
@Component({
  selector: 'app-taking-payments',
  templateUrl: './taking-payments.component.html',
  styleUrls: ['./taking-payments.component.scss'],
})
export class TakingPaymentsComponent implements OnInit {
  private readonly _destroyRef = inject(DestroyRef);

  loading: boolean = false;
  paymentTypeList: any[] = [];
  takingPaymentList: any[] = [];
  step: number = onboardingSteps.taking_payments;
  selectedItem: any;
  id: any;
  paymentMethods: any = [];
  isOtherSelected: boolean = false;
  platformName = new FormControl<string>('');
  constants = constants;
  otherTextBox: string = '';

  constructor(
    private cmService: CommonService,
    private cookieService: CookieService,
    private http: HttpsService,
    private onboardingCustomizationService: OnboardingCustomizationService,
    private cd: ChangeDetectorRef,
    private ngZone: NgZone
  ) {
    this.cmService.progressBar = this.step;
    this.platformName.addValidators([
      Validators.required,
      noWhitespaceValidator,
      Validators.pattern(constants.alpha_numeric),
    ]);
  }

  ngOnInit(): void {
    this.getMerchantDetail();
    this.getMerchantTakingPayment();
  }

  submit() {
    this.postMerchantTakingPayments();
  }

  getMerchantDetail() {
    this.onboardingCustomizationService
      .getOnboardingBusinessDetail()
      .subscribe((res: any) => {
        if (res.success) {
          let data = res?.data;

          if (data?.ecommercePluginsAndOnlinePaymentMethods) {
            this.selectedItem =
              data?.ecommercePluginsAndOnlinePaymentMethods[0]?.onlinePayment;
            this.id = this.selectedItem?.id;

            if (this.selectedItem?.shortId === 'other') {
              this.isOtherSelected = true;
              this.otherTextBox =
                data?.ecommercePluginsAndOnlinePaymentMethods?.[0]?.name;
              this.platformName?.patchValue(this.otherTextBox);
            }
          }
        }
      });
  }

  getMerchantTakingPayment() {
    this.http
      .httpGet('getEcommAndOnlinePayments')
      ?.pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe({
        next: (res: any) => {
          let data = res?.data?.data;
          this.takingPaymentList = data;
          this.takingPaymentList?.map((element) => {
            element.imageUrl = `${environment.imgFileBaseUrl}/${element.logo}`;
          });
        },
      });
  }

  goBack() {
    this.cmService.progressDec(APP_ROUTES.has_existing_gateway_account);
  }

  postMerchantTakingPayments() {
    this.otherTextBox = this.platformName?.value?.trim() || '';

    if (this.isOtherSelected && this.platformName?.invalid) {
      return this.platformName?.markAllAsTouched();
    }

    let input = {
      bussinessId: this.cookieService.get(constants.business_id),
      currentPageId: EOnboardingPageId.taking_payments,
      ecommercePluginOrOnlinePaymentMethodId: this.selectedItem.id,
      ecommercePluginOrOnlinePaymentMethodName: this.isOtherSelected
        ? this.otherTextBox
        : '',
    };

    this.loading = true;
    this.http.httpPatch('saveEcommAndOnlinePayments', input).subscribe({
      next: (res: any) => {
        this.loading = false;
        if (res.success) {
          this.onboardingCustomizationService.setOnlinePaymentMethods = [
            this.selectedItem,
          ];
          this.onboardingCustomizationService.verifySelectedOnlinePaymentMethod();
          this.cmService.progressInc(APP_ROUTES.shoping_cart);
        } else {
          this.cmService.error(
            this.cmService.capitalizeFirstLetter(res.message)
          );
        }
      },
      error: (error) => {
        this.ngZone.run(() => {
          this.loading = false;
        });
        this.cd.detectChanges();
      },
    });
  }

  setPayment(item) {
    item.isActive = !item.isActive;
    this.selectedItem = item;
    this.id = item.id;
    this.otherTextBox = '';
    this.platformName.reset('');

    if (this.selectedItem.shortId === 'other') {
      this.isOtherSelected = true;
    } else {
      this.isOtherSelected = false;
    }
  }
}
