import { Injectable } from '@angular/core';
import { IUploadRequiredDocument } from '@interfaces/prospects/prospects-uploads.interface';
import { Observable, map } from 'rxjs';
import { ProspectInfromationRequestClientService } from '@clients/prospects/prospect-information-requests';
import { ProspectsInformationRequest } from '../models/prospect-information.model';

@Injectable()
export class ProspectsInformationRequestImplementService {
  constructor(
    private readonly _prospectUploadClient: ProspectInfromationRequestClientService
  ) {}

  public getRequiredDocuments(
    prospectId: string,
    params: { search: string }
  ): Observable<ProspectsInformationRequest[]> {
    return this._prospectUploadClient
      .getInformationRequests(prospectId, params)
      .pipe(
        map((res) => {
          if (res && res.success) {
            return res?.data?.requiredInformation?.length
              ? res?.data?.requiredInformation?.map(
                  (reqDoc) => new ProspectsInformationRequest(reqDoc)
                )
              : [];
          } else {
            return undefined;
          }
        })
      );
  }

  public uploadRequiredDocuments(
    docs: IUploadRequiredDocument,
    prospectId: string
  ) {
    return this._prospectUploadClient.uploadRequiredDocuments(docs, prospectId);
  }
}
