import { Component, Input, OnChanges, computed, signal } from '@angular/core';
import { OfferTieredCoreFees } from '../../../../shared/models/offer-pricing/offer-pricing.model';

@Component({
  selector: 'offer-banner-tiered',
  template: `
    <ng-container *ngIf="tieredCoreFeesFields().length">
      <ng-container *ngFor="let key of tieredCoreFeesFields()">
        <ng-container
          *ngIf="
            (hasSameQualRate() || hasSameSignatureDebit() || hasSameAmex()) &&
            key.type === 'sameQualifiedRate'
          "
          [ngTemplateOutlet]="transactionData"
          [ngTemplateOutletContext]="{ key: key }"
        ></ng-container>

        <ng-container
          *ngIf="
            !hasSameSignatureDebit() &&
            !hasSameQualRate() &&
            key.type === 'perQualSignature'
          "
          [ngTemplateOutlet]="transactionData"
          [ngTemplateOutletContext]="{ key: key }"
        ></ng-container>

        <ng-container
          *ngIf="
            !hasSameSignatureDebit() &&
            !hasSameQualRate() &&
            !hasSameAmex() &&
            key.type === 'perQualTransaction'
          "
          [ngTemplateOutlet]="transactionData"
          [ngTemplateOutletContext]="{ key: key }"
        ></ng-container>

        <ng-container
          *ngIf="
            ((!hasSameAmex() && !hasSameQualRate()) ||
              (hasHighlightAuthFees() && !hasSameAuthFees())) &&
            key.type === 'perQualAmex'
          "
          [ngTemplateOutlet]="transactionData"
          [ngTemplateOutletContext]="{ key: key }"
        ></ng-container>
      </ng-container>
    </ng-container>

    <ng-template #transactionData let-key="key">
      <div class="d-flex justify-content-center align-items-center my-1 sidev1">
        <span
          class="offer_val_web fs-22"
          *ngIf="tieredCoreFeesData()[key.discount]"
        >
          {{ tieredCoreFeesData()[key.discount] | roundWithDecimal }}%
        </span>
        <span
          class="fw-300 px-0 plus_web"
          *ngIf="
            tieredCoreFeesData()[key.discount] &&
            ((!hasHighlightAuthFees() && tieredCoreFeesData()[key.perItem]) ||
              (key.type === 'perQualAmex' && hasAmexAuthFees()) ||
              (key.type !== 'perQualAmex' && hasAuthFees()))
          "
        >
          +
        </span>
        <span
          class="offer_val_web fs-22"
          *ngIf="
            (!hasHighlightAuthFees() && tieredCoreFeesData()[key.perItem]) ||
            (key.type === 'perQualAmex' && hasAmexAuthFees()) ||
            (key.type !== 'perQualAmex' && hasAuthFees())
          "
        >
          {{
            hasHighlightAuthFees()
              ? ((key.type === 'perQualAmex'
                  ? tieredCoreFeesData()?.tieredAmexAuthFee
                  : tieredCoreFeesData()?.tieredAuthFee
                ) | currencySymbol) + ' Auth'
              : (tieredCoreFeesData()[key.perItem] | currencySymbol)
          }}
        </span>
      </div>
      <p
        class="ps-0 mb-2 fs-12 mobile-content"
        *ngIf="
          key.label &&
          (tieredCoreFeesData()[key.discount] ||
            tieredCoreFeesData()[key.perItem] ||
            hasAuthFees() ||
            hasAmexAuthFees())
        "
      >
        {{ key.label }}
      </p>
      <p
        class="ps-0 mb-2 fs-12 desktop-content"
        *ngIf="
          key.label &&
          (tieredCoreFeesData()[key.discount] ||
            tieredCoreFeesData()[key.perItem] ||
            hasAuthFees() ||
            hasAmexAuthFees())
        "
      >
        {{ key.label }}
      </p>
    </ng-template>
  `,
})
export class TieredDetailComponent implements OnChanges {
  @Input() public tieredCoreFees: OfferTieredCoreFees;

  public readonly tieredCoreFeesData = signal<OfferTieredCoreFees>(null);

  public readonly tieredCoreFeesFields = signal([
    {
      discount: 'tieredDiscountRatePercentage',
      perItem: 'tieredDiscountRatePerItem',
      label: 'per Qualified Transaction',
      type: 'sameQualifiedRate',
    },
    {
      discount: 'tieredSignatureDebitPercentage',
      perItem: 'tieredSignatureDebitPerItem',
      label: 'per Qual. Signature Debit Transaction',
      type: 'perQualSignature',
    },
    {
      discount: 'tieredDiscountRatePercentage',
      perItem: 'tieredDiscountRatePerItem',
      label: 'per VI/MC/DS Qualified Transaction',
      type: 'perQualTransaction',
    },
    {
      discount: 'tieredAmexDiscountRatePercentage',
      perItem: 'tieredAmexDiscountRatePerItem',
      label: 'per Qualified AMEX Transaction',
      type: 'perQualAmex',
    },
  ]);

  public readonly hasSameQualRate = computed(() => {
    const hasSameRate =
      this.tieredCoreFeesData()?.tieredDiscountRatePercentage ===
        this.tieredCoreFeesData()?.tieredAmexDiscountRatePercentage &&
      this.tieredCoreFeesData()?.tieredAmexDiscountRatePercentage ===
        this.tieredCoreFeesData().tieredSignatureDebitPercentage;

    const hasSamePerItem =
      this.tieredCoreFeesData()?.tieredDiscountRatePerItem ===
        this.tieredCoreFeesData()?.tieredAmexDiscountRatePerItem &&
      this.tieredCoreFeesData()?.tieredAmexDiscountRatePerItem ===
        this.tieredCoreFeesData().tieredSignatureDebitPerItem;

    return this.hasHighlightAuthFees()
      ? hasSameRate
      : hasSameRate && hasSamePerItem;
  });

  public readonly hasSameSignatureDebit = computed(() => {
    const hasSameRate =
      this.tieredCoreFeesData()?.tieredDiscountRatePercentage ===
      this.tieredCoreFeesData().tieredSignatureDebitPercentage;

    const hasSamePerItem =
      this.tieredCoreFeesData()?.tieredDiscountRatePerItem ===
      this.tieredCoreFeesData().tieredSignatureDebitPerItem;

    return this.hasHighlightAuthFees()
      ? hasSameRate
      : hasSameRate && hasSamePerItem;
  });

  public readonly hasSameAmex = computed(() => {
    const hasSameRate =
      this.tieredCoreFeesData()?.tieredDiscountRatePercentage ===
      this.tieredCoreFeesData().tieredAmexDiscountRatePercentage;

    const hasSamePerItem =
      this.tieredCoreFeesData()?.tieredDiscountRatePerItem ===
      this.tieredCoreFeesData().tieredAmexDiscountRatePerItem;

    return this.hasHighlightAuthFees()
      ? hasSameRate
      : hasSameRate && hasSamePerItem;
  });

  public readonly hasHighlightAuthFees = computed(() => {
    return this.tieredCoreFeesData()?.highlightAuthFees;
  });

  public readonly hasAuthFees = computed(() => {
    return (
      this.hasHighlightAuthFees() && this.tieredCoreFeesData()?.tieredAuthFee
    );
  });

  public readonly hasAmexAuthFees = computed(() => {
    return (
      this.hasHighlightAuthFees() &&
      this.tieredCoreFeesData()?.tieredAmexAuthFee
    );
  });

  public readonly hasSameAuthFees = computed(() => {
    return (
      this.hasHighlightAuthFees() &&
      this.tieredCoreFeesData()?.tieredAmexAuthFee ===
        this.tieredCoreFeesData()?.tieredAuthFee
    );
  });

  ngOnChanges(): void {
    this.tieredCoreFeesData.set(this.tieredCoreFees);
  }
}
