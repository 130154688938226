import { IResponseBase } from '@interfaces/generic/responses.interface';
import {
  CMetricsKPIsVolumeParamsDate,
  EMetricsKpisVolumeParamsKpi,
  EMetricsKPIsVolumeParams,
} from '@parameters/metrics/metrics-kpis-volume.parameter';
import { IMetricsKpisValue } from '@interfaces/metrics/metrics-kpis.interface';
import { ECardBrand } from '@parameters/filter/card-brand.parameter';

export interface IMetricsKPIsVolumeParams {
  [EMetricsKPIsVolumeParams.startDate]: string;
  [EMetricsKPIsVolumeParams.endDate]: string;
  [EMetricsKPIsVolumeParams.midList]: string[];
  [EMetricsKPIsVolumeParams.binList]: string[];
  [EMetricsKPIsVolumeParams.kpiList]: EMetricsKpisVolumeParamsKpi[];
  [EMetricsKPIsVolumeParams.cardBrandList]: ECardBrand[];
}

export const CMetricsKPIsVolumeParamsDefault: IMetricsKPIsVolumeParams = {
  [EMetricsKPIsVolumeParams.startDate]:
    CMetricsKPIsVolumeParamsDate.value.startDate,
  [EMetricsKPIsVolumeParams.endDate]:
    CMetricsKPIsVolumeParamsDate.value.endDate,
  [EMetricsKPIsVolumeParams.midList]: [],
  [EMetricsKPIsVolumeParams.binList]: [],
  [EMetricsKPIsVolumeParams.cardBrandList]: [],
  [EMetricsKPIsVolumeParams.kpiList]: [
    EMetricsKpisVolumeParamsKpi.totalSales,
    EMetricsKpisVolumeParamsKpi.totalRefunds,
    EMetricsKpisVolumeParamsKpi.totalNet,
    EMetricsKpisVolumeParamsKpi.totalTransactions,
  ],
};

export interface IMetricsKPIsVolume {
  totalSales: IMetricsKpisValue;
  totalRefunds: IMetricsKpisValue;
  totalNet: IMetricsKpisValue;
  totalTransactions: IMetricsKpisValue;
}

export interface IMetricsKPIsVolumeResponse
  extends IResponseBase<IMetricsKPIsVolume> {}
