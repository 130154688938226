import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencySymbol',
})
export class CurrencySymbolPipe implements PipeTransform {
  /**
   * Transform
   * @param value
   * @returns
   */
  transform(value: number): string {
    if (typeof value !== 'number') value = Number(value);
    return value < 1 ? `${(value * 100).toFixed(0)}¢` : `$${value.toFixed(2)}`;
  }
}
