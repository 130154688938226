import { Injectable, inject } from '@angular/core';
import { AccountsClientService } from '@clients/accounts/accounts-client.service';
import { IAccountsListParams } from '@interfaces/accounts/accounts-list.interface';
import { map } from 'rxjs';
import { Account, AccountListResponse } from '../models/accounts-list.model';
import { IAccountForm } from '@interfaces/accounts/accounts-form.interface';
import { IAccountMerchantsListParams } from '@interfaces/accounts/account-merchants-list.interface';
import { AccountAssociatedMerchantsList } from '../models/account-merchants.model';
import { IAccountGatewayConfigForm } from '@interfaces/accounts/account-gateway-configurations.interface';
import { AccountGatewayConfigResponse } from '../models/account-gateway-config.model';

@Injectable()
export class AccountsImplementService {
  private readonly _accountsClient = inject(AccountsClientService);

  /**
   * Get accounts list
   * @param params
   * @returns
   */
  getAccountsList(params: IAccountsListParams) {
    return this._accountsClient
      .getAccountsList(params)
      ?.pipe(map((res) => new AccountListResponse(res?.data)));
  }

  /**
   * Get Account Details
   * @param accountId
   * @returns
   */
  public getAccountDetail(accountId: string) {
    return this._accountsClient
      .getAccountDetail(accountId)
      ?.pipe(map((res) => new Account(res?.data)));
  }

  /**
   * Update account details
   * @param payload
   * @param accountId
   * @returns
   */
  public updateAccountDetail(payload: IAccountForm, accountId: string) {
    return this._accountsClient.updateAccountDetails(payload, accountId);
  }

  /**
   * Associated merchants list
   * @param params
   * @param accountId
   * @returns
   */
  public getMerchantsList(
    params: IAccountMerchantsListParams,
    accountId: string
  ) {
    return this._accountsClient
      .accountAssociatedMerchants(params, accountId)
      ?.pipe(map((res) => new AccountAssociatedMerchantsList(res?.data)));
  }

  /**
   * Get Configurations list
   * @param accountId
   * @returns
   */
  public getGatewayConfiguration(accountId: string) {
    return this._accountsClient
      .getAccountGatewayConfig(accountId)
      ?.pipe(map((res) => new AccountGatewayConfigResponse(res)));
  }

  /**
   * Add Gateway config
   * @param payload
   * @param accountId
   * @returns
   */
  public addGatewayConfiguration(
    payload: IAccountGatewayConfigForm,
    accountId: string
  ) {
    return this._accountsClient.addAccountGatewayConfig(payload, accountId);
  }

  /**
   * Update gateway config
   * @param payload
   * @param accountId
   * @param gatewayId
   * @returns
   */
  public updateGatewayConfiguration(
    payload: IAccountGatewayConfigForm,
    gatewayId: string,
    accountId: string
  ) {
    return this._accountsClient.updateAccountGatewayConfig(
      payload,
      gatewayId,
      accountId
    );
  }
}
