import { IResponseBase } from '@interfaces/generic/responses.interface';
import {
  EFPBillingSettingCreateData,
  EFpBillingSettingCreateRequest,
} from '@parameters/funding-plans/fp-billing-setting-create.parameter';
import {
  EFPBillingSettingFeeType,
  EFPBillingSettingFrequency,
  EFPBillingSettingFrequencyStart,
} from '@parameters/funding-plans/fp-billing-setting.parameter';

/**
 * Request
 */

export interface IFPBillingSettingCreateRequest {
  [EFpBillingSettingCreateRequest.name]: string;
  [EFpBillingSettingCreateRequest.description]: string;
  [EFpBillingSettingCreateRequest.type]: EFPBillingSettingFeeType;
  [EFpBillingSettingCreateRequest.frequency]: EFPBillingSettingFrequency;
  [EFpBillingSettingCreateRequest.frequencyStart]: EFPBillingSettingFrequencyStart;
  [EFpBillingSettingCreateRequest.amount]: number;
  [EFpBillingSettingCreateRequest.percent]: number;
  [EFpBillingSettingCreateRequest.date]: string;
}

export const CFPBillingSettingCreateRequestDefault: IFPBillingSettingCreateRequest =
  {
    [EFpBillingSettingCreateRequest.name]: '',
    [EFpBillingSettingCreateRequest.description]: '',
    [EFpBillingSettingCreateRequest.type]: EFPBillingSettingFeeType.PER_PAYMENT,
    [EFpBillingSettingCreateRequest.frequency]: null,
    [EFpBillingSettingCreateRequest.frequencyStart]: null,
    [EFpBillingSettingCreateRequest.amount]: 0,
    [EFpBillingSettingCreateRequest.percent]: 0,
    [EFpBillingSettingCreateRequest.date]: null,
  };

/**
 * Response
 */

interface IFPBillingSettingCreateData {
  [EFPBillingSettingCreateData.id]: string;
}

export interface IFPBillingSettingCreateResponse
  extends IResponseBase<IFPBillingSettingCreateData> {}
