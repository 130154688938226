<ng-container *ngIf="control && !defaultValidation">
  <div class="input-error-msg for-input">
    <ng-container *ngIf="control?.errors">
      <div *ngIf="control?.invalid && (control?.dirty || control?.touched)">
        <gtr-icon name="info-circle-filled" [size]="12"></gtr-icon>
        @if (!customError){
        <span *ngIf="control?.errors?.required">{{inputLabel ? inputLabel : 'This field'}} is required.</span>
        <span *ngIf="control?.errors?.min">{{inputLabel ? inputLabel : 'Value'}} must be greater than or equal to {{
          getMinValue }}.</span>
        <span *ngIf="control?.errors?.minlength && !control?.errors?.required">
          <span *ngIf="digitsToRemove">
            {{inputLabel ? inputLabel : 'Value'}} must be of {{ getMinLengthValue - digitsToRemove }} digits.
          </span>
          <span *ngIf="!digitsToRemove">
            {{inputLabel ? inputLabel : 'Value'}} must be greater than equal to {{ getMinLengthValue }} characters.
          </span>
        </span>
        <span *ngIf="control?.errors?.maxlength && !control?.errors?.required && !control?.errors?.decimalExceeded">
          <span *ngIf="digitsToRemove"> {{inputLabel ? inputLabel : 'Value'}} must be of {{ getMaxLengthValue -
            digitsToRemove }}
            digits.
          </span>
          <span *ngIf="!digitsToRemove">
            {{inputLabel ? inputLabel : 'Value'}} must be less than equal to {{ getMaxLengthValue }}
            characters.
          </span>
        </span>
        <span *ngIf="control?.errors?.max">{{inputLabel ? inputLabel : 'Value'}} must be less than or equal to {{
          getMaxValue }}.</span>
        <span *ngIf="control?.errors?.invalidNumber">{{inputLabel ? inputLabel : 'Value'}} should be a valid
          number.</span>
        <span *ngIf="control?.errors?.percentage && !control?.errors?.invalidNumber">
          {{inputLabel ? inputLabel : 'Percentage'}} should be in
          between 0 to 100.</span>
        <span *ngIf="control?.errors?.decimalExceeded && !(control?.errors?.invalidNumber || control?.errors?.percentage)
                     && !control?.errors?.required">
          Only {{minDigitsAfterDecimal?.allowedDigits}}
          {{minDigitsAfterDecimal?.allowedDigits > 1 ? 'digits are' : 'digit is'}} allowed after the decimal
          point.</span>
        <span *ngIf="control?.errors?.decimalNotAllowed && !(control?.errors?.invalidNumber || control?.errors?.percentage)
                     && !control?.errors?.required">
          Only integers are allowed.</span>
        <span *ngIf="control?.errors?.whitespace">Only whitespace is not allowed.</span>
        <span *ngIf="control?.errors?.maxNumber">Value should be less than {{inputType === 'number' ? (max | number : '1.0-0') : (max | number : '1.2-2')}}.</span>
        <span
          *ngIf="control?.errors?.notInteger && !(control?.errors?.minlength || control?.errors?.maxlength || invalidNumber)">Invalid
          number format.</span>
        <span *ngIf="control?.errors?.invalidRoutingNumber">Invalid routing/transit number.</span>
        <span *ngIf="control?.errors?.invalidBankPhoneNumber">Invalid phone number.</span>
        <span *ngIf="control?.errors?.invalidFaxNumber">Invalid Fax number.</span>
        <span *ngIf="control?.errors?.invalidEmail && !control?.errors?.required ">Email is invalid.</span>
        <span *ngIf="control?.errors?.invalidTime && !control?.errors?.required ">
          {{inputLabel ? inputLabel : 'This field'}} is invalid.
        </span>
        <span *ngIf="control?.errors?.pattern">{{inputLabel ? inputLabel : 'This field'}} is invalid.</span>
        <span *ngIf="control?.errors?.invalidDate && !control?.errors?.required">
          {{inputLabel ? inputLabel : 'This field'}} is invalid.
        </span>
        <span *ngIf="control?.errors?.numericPrecision && !control?.errors?.decimalExceeded">
          Only {{precisionBeforeDecimal?.allowedPrecision}}
          {{precisionBeforeDecimal?.allowedPrecision > 1 ? 'digits are' : 'digit is'}} allowed before the decimal
          point.</span>
        <span *ngIf="control?.errors?.invaliduuid">UUID is invalid.</span>
        <span *ngIf="control?.errors?.duplicateEmail">Email must be unique for each principal.</span>
        <span *ngIf="control?.errors?.invalidurl">Url is invalid.</span>
        <span *ngIf="control?.errors?.invalidStockTicker">Only letters and dashes are allowed.</span>
        <span *ngIf="control?.errors?.wrongDate">Invalid date</span>
        <span *ngIf="control?.errors?.invalidPhoneNumber">Invalid phone number</span>
        <span *ngIf="control?.errors?.invalidBody">Invalid value</span>
        <span *ngIf="control?.errors?.cardExpiry">Invalid card's expiration date</span>
        <span *ngIf="control?.errors?.greaterThanZero">Value should be greater than 0</span>
        <span *ngIf="control?.errors?.invalidZipCode">Invalid zip code value</span>
        <span *ngIf="control?.errors?.invalidUniqueValue?.invalidUniqueValue">Value must {{
          control?.errors?.invalidUniqueValue?.key ? 'not be similar to ' + control?.errors?.invalidUniqueValue?.key :
          'be unique' }}</span>
        <span *ngIf="control?.errors?.invalidColorCode">Invalid hex color code</span>
        <span *ngIf="control?.errors?.invalidColor">The color is invalid.</span>
        } @else {
          <span>{{ customError }}</span>
        }
      </div>
    </ng-container>
  </div>
</ng-container>
