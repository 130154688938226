import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { EOnlinePaymentMethod } from '@parameters/filter/payment-method.parameter';

//OCE: Onboarding Customization Edit

export enum EOCEOnlinePaymentIntegrationFormControlName {
  optionList = 'optionList',
  hidden = 'hidden',
  active = 'active',
  additionalInformation = 'additionalInformation',
}

export enum EOCEOnlinePaymentIntegrationOptionFormControlName {
  id = 'id',
  name = 'name',
  predefined = 'predefined',
  preselected = 'preselected',
}

export interface IOCEOnlinePaymentIntegrationFormValue {
  [EOCEOnlinePaymentIntegrationFormControlName.optionList]: IOCEOnlinePaymentIntegrationOptionFormValue[];
  [EOCEOnlinePaymentIntegrationFormControlName.hidden]: boolean;
  [EOCEOnlinePaymentIntegrationFormControlName.active]: boolean;
  [EOCEOnlinePaymentIntegrationFormControlName.additionalInformation]?: string;
}

export interface IOCEOnlinePaymentIntegrationOptionFormValue {
  [EOCEOnlinePaymentIntegrationOptionFormControlName.id]: EOnlinePaymentMethod;
  [EOCEOnlinePaymentIntegrationOptionFormControlName.name]: string;
  [EOCEOnlinePaymentIntegrationOptionFormControlName.predefined]: boolean;
  [EOCEOnlinePaymentIntegrationOptionFormControlName.preselected]: boolean;
}

export interface IOCEOnlinePaymentIntegrationForm {
  [EOCEOnlinePaymentIntegrationFormControlName.optionList]: FormArray<
    FormGroup<IOCEOnlinePaymentIntegrationOptionForm>
  >;
  [EOCEOnlinePaymentIntegrationFormControlName.hidden]: FormControl<
    IOCEOnlinePaymentIntegrationFormValue[EOCEOnlinePaymentIntegrationFormControlName.hidden]
  >;
  [EOCEOnlinePaymentIntegrationFormControlName.active]: FormControl<
    IOCEOnlinePaymentIntegrationFormValue[EOCEOnlinePaymentIntegrationFormControlName.active]
  >;
}

export interface IOCEOnlinePaymentIntegrationOptionForm {
  [EOCEOnlinePaymentIntegrationOptionFormControlName.id]: FormControl<
    IOCEOnlinePaymentIntegrationOptionFormValue[EOCEOnlinePaymentIntegrationOptionFormControlName.id]
  >;
  [EOCEOnlinePaymentIntegrationOptionFormControlName.name]: FormControl<
    IOCEOnlinePaymentIntegrationOptionFormValue[EOCEOnlinePaymentIntegrationOptionFormControlName.name]
  >;
  [EOCEOnlinePaymentIntegrationOptionFormControlName.predefined]: FormControl<
    IOCEOnlinePaymentIntegrationOptionFormValue[EOCEOnlinePaymentIntegrationOptionFormControlName.predefined]
  >;
  [EOCEOnlinePaymentIntegrationOptionFormControlName.preselected]: FormControl<
    IOCEOnlinePaymentIntegrationOptionFormValue[EOCEOnlinePaymentIntegrationOptionFormControlName.preselected]
  >;
}

export const COCEOnlinePaymentIntegrationFormDefault: IOCEOnlinePaymentIntegrationFormValue =
  {
    [EOCEOnlinePaymentIntegrationFormControlName.optionList]: [],
    [EOCEOnlinePaymentIntegrationFormControlName.hidden]: false,
    [EOCEOnlinePaymentIntegrationFormControlName.active]: false,
  };
