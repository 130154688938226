import {
  CDateFilterListValue,
  EDateFilter,
} from '@parameters/filter/date.parameter';

export const enum EDepositListParams {
  startDate = 'start_date',
  endDate = 'end_date',
  midList = 'mids',
  binList = 'bins',
  type = 'label',
  limit = 'limit',
  page = 'page',
  sort = 'order_by',
  search = 'search',
  sources = 'sources',
}

export const CDepositListParamsDate =
  CDateFilterListValue[EDateFilter.last30Days];

export const enum EDepositListParamsSort {
  dbaAsc = 'dba,asc',
}

export const enum EDepositCSVBody {
  startDate = 'startDate',
  endDate = 'endDate',
  midList = 'mids',
  binList = 'bins',
  search = 'search',
  sources = 'sources',
  type = 'type',
}
