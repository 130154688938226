import { Component, Input, OnChanges, computed, signal } from '@angular/core';
import { OfferCashDiscountCoreFees } from '../../../../shared/models/offer-pricing/offer-pricing.model';

@Component({
  selector: 'offer-banner-cash-discount',
  template: `
    <ng-container *ngIf="cashDiscountCoreFeesFields().length">
      <ng-container *ngFor="let key of cashDiscountCoreFeesFields()">
        <ng-container
          *ngIf="hasProgramRecurringFee() && key.type === 'programRecurringFee'"
          [ngTemplateOutlet]="transactionData"
          [ngTemplateOutletContext]="{ key: key }"
        ></ng-container>
      </ng-container>
    </ng-container>

    <ng-template #transactionData let-key="key">
      <div class="d-flex justify-content-center align-items-center my-1 sidev1">
        <span
          class="offer_val_web fs-22"
          *ngIf="cashDiscountCoreFeesData()[key.value]"
        >
          {{ cashDiscountCoreFeesData()[key.value] | currencySymbol }}
        </span>
      </div>
      <p
        class="ps-0 mb-2 fs-12 mobile-content"
        *ngIf="key.label && cashDiscountCoreFeesData()[key.value]"
      >
        {{ key.label }}
      </p>
      <p
        class="ps-0 mb-2 fs-12 desktop-content"
        *ngIf="key.label && cashDiscountCoreFeesData()[key.value]"
      >
        {{ key.label }}
      </p>
    </ng-template>
  `,
})
export class CashDiscountDetailComponent implements OnChanges {
  @Input() public cashDiscountCoreFees: OfferCashDiscountCoreFees;

  public readonly cashDiscountCoreFeesData =
    signal<OfferCashDiscountCoreFees>(null);

  public readonly cashDiscountCoreFeesFields = signal([
    {
      value: 'programRecurringFee',
      label: 'Per month/terminal',
      type: 'programRecurringFee',
    },
  ]);

  public readonly hasProgramRecurringFee = computed(() => {
    return this.cashDiscountCoreFeesData()?.programRecurringFee;
  });

  ngOnChanges(): void {
    this.cashDiscountCoreFeesData.set(this.cashDiscountCoreFees);
  }
}
