import { Injectable } from '@angular/core';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import { GenericClientService } from '@clients/generic/generic-client.service';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { EMyMerchantListParams } from '@parameters/merchant/my-merchant/my-merchant-list.parameter';
import {
  CMyMerchantListParamsDefault,
  IMyMerchantListParams,
  IMyMerchantListResponse,
} from '@interfaces/merchant/my-merchant/my-merchant-list.interface';

@Injectable()
export class MyMerchantClientService {
  constructor(private genericClient: GenericClientService) {}

  getMyMerchantList(
    queryParams: IMyMerchantListParams
  ): Observable<IMyMerchantListResponse> {
    const endpoint = EndpointsParameter.MyMerchant;
    let params = new HttpParams()
      .set(
        EMyMerchantListParams.page,
        queryParams[EMyMerchantListParams.page] ||
          CMyMerchantListParamsDefault[EMyMerchantListParams.page]
      )
      .set(
        EMyMerchantListParams.size,
        queryParams[EMyMerchantListParams.size] ||
          CMyMerchantListParamsDefault[EMyMerchantListParams.size]
      )
      .set(
        EMyMerchantListParams.search,
        queryParams[EMyMerchantListParams.search] ||
          CMyMerchantListParamsDefault[EMyMerchantListParams.search]
      );

    return this.genericClient.genericGet<IMyMerchantListResponse>(endpoint, {
      params,
    });
  }
}
