import { Injectable } from '@angular/core';
import { GenericClientService } from '@clients/generic/generic-client.service';
import { Observable } from 'rxjs';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import { HttpParams } from '@angular/common/http';
import { EMetricsKpisMerchantParams } from '@parameters/metrics/metrics-kpis-merchant.parameter';
import {
  CMetricsKpisMerchantParamsDefault,
  IMetricsKpisMerchantParams,
  IMetricsKpisMerchantResponse,
} from '@interfaces/metrics/metrics-kpis-merchant.interface';

@Injectable()
export class MetricsKpisMerchantClientService {
  constructor(private genericClient: GenericClientService) {}

  getMetricsKpisMerchant(
    queryParams: IMetricsKpisMerchantParams
  ): Observable<IMetricsKpisMerchantResponse> {
    const endpoint = EndpointsParameter.MetricsKPIsMerchant;

    const params = new HttpParams()
      .set(
        EMetricsKpisMerchantParams.startDate,
        queryParams[EMetricsKpisMerchantParams.startDate] ||
          CMetricsKpisMerchantParamsDefault[
            EMetricsKpisMerchantParams.startDate
          ]
      )
      .set(
        EMetricsKpisMerchantParams.endDate,
        queryParams[EMetricsKpisMerchantParams.endDate] ||
          CMetricsKpisMerchantParamsDefault[EMetricsKpisMerchantParams.endDate]
      )
      .set(
        EMetricsKpisMerchantParams.kpiList,
        queryParams[EMetricsKpisMerchantParams.kpiList].join(',') ||
          CMetricsKpisMerchantParamsDefault[
            EMetricsKpisMerchantParams.kpiList
          ].join(',')
      )
      .set(
        EMetricsKpisMerchantParams.midList,
        queryParams[EMetricsKpisMerchantParams.midList].join(',') ||
          CMetricsKpisMerchantParamsDefault[
            EMetricsKpisMerchantParams.midList
          ].join(',')
      )
      .set(
        EMetricsKpisMerchantParams.binList,
        queryParams[EMetricsKpisMerchantParams.binList].join(',') ||
          CMetricsKpisMerchantParamsDefault[
            EMetricsKpisMerchantParams.binList
          ].join(',')
      )
      .set(
        EMetricsKpisMerchantParams.relationshipIds,
        queryParams[EMetricsKpisMerchantParams.relationshipIds].join(',') ||
          CMetricsKpisMerchantParamsDefault[
            EMetricsKpisMerchantParams.relationshipIds
          ].join(',')
      );
    return this.genericClient.genericGet(endpoint, { params });
  }
}
