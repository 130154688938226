export const enum EMspMerchantApplicationListParams {
  status = 'status',
  sort = 'order_by',
  page = 'page',
  size = 'limit',
  search = 'search',
}

export const enum EMspMerchantApplicationListSort {
  createdAtAsc = 'created_at:asc',
  createdAtDesc = 'created_at:desc',
}

export const enum EMspApplicationStatus {
  active = 'active',
  progress = 'in_progress',
}

export const enum EMspMerchantSignerStatus {
  accepted = 'accepted',
  pending = 'pending',
}

export const enum EMspMerchantApplicationSignatureRoleLabel {
  primary = 'Primary Signer',
  secondary = 'Secondary Signer',
}

export enum EMspMerchantApplicationSignatureRoleValue {
  primary = 'primary_signer',
  secondary = 'secondary_signer',
}

export const enum EMspStoredMerchantApplicationSelectedItem {
  selectedApplicationMerchantBusiness = 'selectedApplicationMerchantBusiness',
  selectedApplicationSignatureRole = 'selectedApplicationSignatureRole',
  selectedApplicationMerchantId = 'selectedApplicationMerchantId',
  selectedApplicationPageId = 'selectedApplicationPageId',
  selectedApplicationStatus = 'selectedApplicationStatus',
  selectedApplicationOfferId = 'selectedApplicationOfferId',
  selectedApplicationCurrentProspectStatusId = 'selectedApplicationCurrentProspectStatusId',
}

export const enum EMerchantApplicationIdUrl {
  merchantApplicationId = 'merchantapplicationid',
}
