import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericClientService } from '@clients/generic/generic-client.service';
import { GenericHelper } from '@helpers/generic.helper';
import { IEquipmentTerminalDetail } from '@interfaces/equipment-details/equipment-terminal.interface';
import { IResponseBase } from '@interfaces/generic/responses.interface';
import {
  IProspectTerminalCalculationResponse,
  IProspectsEquipmentParams,
  IProspectsPaymentGatewayList,
  IProspectsPaymentGatewayResponse,
  IProspectsPhysicalTerminalResponse,
  IProspectsUpdateEquipments,
  IProspectsUpdatePaymentGateway,
  TProspectDeleteEquipment,
} from '@interfaces/prospects/prospects-equipments.interface';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import {
  CProspectsEquipmentParams,
  EProspectsEquipmentParams,
} from '@parameters/prospects/prospects-equipment.parameter';
import { Observable } from 'rxjs';

@Injectable()
export class ProspectEquipmentClientService {
  constructor(private readonly _genericClient: GenericClientService) {}

  /**
   * Get All Equipment Terminals
   * @param merchantId
   * @returns
   */
  public getAllEquipmentTerminals(merchantId: string) {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.ProspectGetAllEquipmentTerminal,
      merchantId
    );

    return this._genericClient.genericGet<IEquipmentTerminalDetail[]>(endpoint);
  }

  /**
   * Get All Payment Gateways
   * @param merchantId
   * @returns
   */
  public getAllPaymentGateways(merchantId: string) {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.ProspectGetAllVirtualGateways,
      merchantId
    );

    return this._genericClient.genericGet<IProspectsPaymentGatewayList>(
      endpoint
    );
  }

  /**
   * Get Physical Terminal
   * @param prospectId
   * @returns
   */
  public getEquipmentTerminals(
    prospectId: string,
    params: IProspectsEquipmentParams,
    isPaginated = true
  ): Observable<IProspectsPhysicalTerminalResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.ProspectEquipmentTerminal,
      prospectId
    );

    let httpParams = new HttpParams();
    if (isPaginated) {
      httpParams = httpParams.append(
        EProspectsEquipmentParams.page,
        params?.page ||
          CProspectsEquipmentParams[EProspectsEquipmentParams.page]
      );
      httpParams = httpParams.append(
        EProspectsEquipmentParams.limit,
        params?.limit ||
          CProspectsEquipmentParams[EProspectsEquipmentParams.limit]
      );
    }

    return this._genericClient.genericGet<IProspectsPhysicalTerminalResponse>(
      endpoint,
      { params: httpParams }
    );
  }

  /**
   * Get Payment Gateways
   * @param prospectId
   * @returns
   */
  public getPaymentGateways(
    prospectId: string,
    params: IProspectsEquipmentParams
  ): Observable<IProspectsPaymentGatewayResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.ProspectVirtualGateways,
      prospectId
    );

    const httpParams = new HttpParams()
      .set(
        EProspectsEquipmentParams.page,
        params?.page ||
          CProspectsEquipmentParams[EProspectsEquipmentParams.page]
      )
      .set(
        EProspectsEquipmentParams.limit,
        params?.limit ||
          CProspectsEquipmentParams[EProspectsEquipmentParams.limit]
      );

    return this._genericClient.genericGet<IProspectsPaymentGatewayResponse>(
      endpoint,
      { params: httpParams }
    );
  }

  /**
   * Update Payment Gateway
   * @param payload
   * @param prospectId
   * @returns
   */
  public updatePaymentGateway(
    payload: IProspectsUpdatePaymentGateway,
    prospectId: string
  ): Observable<IResponseBase<null>> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.ProspectVirtualGateways,
      prospectId
    );

    return this._genericClient.genericPatch<IResponseBase<null>>(
      endpoint,
      payload
    );
  }

  public deletePaymentGateway(
    gatewayId: string,
    prospectId: string
  ): Observable<IResponseBase<null>> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.ProspectVirtualGateways,
      prospectId
    );

    return this._genericClient.genericDelete<IResponseBase<null>>(
      `${endpoint}/${gatewayId}`
    );
  }

  /**
   * Update Equipment
   * @param payload
   * @param prospectId
   */
  public updateEquipments(
    payload: IProspectsUpdateEquipments,
    prospectId: string
  ): Observable<IResponseBase<null>> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.ProspectEquipmentTerminal,
      prospectId
    );

    return this._genericClient.genericPatch<IResponseBase<null>>(
      endpoint,
      payload
    );
  }

  /**
   * Delete Equipment
   * @param prospectId
   * @param type
   */
  public deleteEquipment(
    prospectId: string,
    pid: string,
    type: TProspectDeleteEquipment
  ): Observable<IResponseBase<null>> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.ProspectEquipmentTerminal,
      prospectId
    );

    const params = new HttpParams().set('type', type);

    return this._genericClient.genericDelete<IResponseBase<null>>(
      `${endpoint}/${pid}`,
      {
        params: params,
      }
    );
  }

  /**
   * Get Terminal Calculations data
   * @param prospectId
   * @returns
   */
  public getTerminalCalculation(
    prospectId: string
  ): Observable<IProspectTerminalCalculationResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.ProspectTerminalCalculation,
      prospectId
    );
    return this._genericClient.genericGet<IProspectTerminalCalculationResponse>(
      endpoint
    );
  }
}
