import { IAccount } from '@interfaces/accounts/accounts-list.interface';
import { IResponseData } from '@interfaces/generic/responses.interface';
import { ResponsePagination } from '@models/generic/responses.model';
import { EGatewayAccountStatus } from '@parameters/accounts/accounts-list.parameter';

export class Account {
  public accountName: string;
  public associatedMids: string[];
  public isPaymentsEnabled: boolean;
  public id: string;
  public gatewayStatus: EGatewayAccountStatus;

  constructor(iAccount: IAccount) {
    this.accountName = iAccount?.accountName;
    this.associatedMids = iAccount?.associatedMids?.length
      ? iAccount?.associatedMids
      : [];
    this.isPaymentsEnabled = iAccount?.isPaymentsEnabled;
    this.id = iAccount?.id;
    this.gatewayStatus = this.isPaymentsEnabled
      ? EGatewayAccountStatus.enabled
      : EGatewayAccountStatus.disabled;
  }
}

export class AccountListResponse {
  public list: Account[];
  public pagination: ResponsePagination;

  constructor(iAccountList: IResponseData<IAccount>) {
    this.pagination = new ResponsePagination(iAccountList?.summary);
    this.list = iAccountList?.rows?.length
      ? iAccountList?.rows?.map((account) => new Account(account))
      : [];
  }
}
