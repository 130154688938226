import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { GlobalToastrService } from './toastr.service';
import { IBrandingResponse } from '@interfaces/branding/branding.interface';
import { BrandingService } from './branding.service';
import { SkipableRoutesService } from './skipable-routes.service';
import { APP_ROUTES, RoutePoints } from '@helpers/routes.enum';
import { CookieService } from 'ngx-cookie-service';
import { PAYMENT_PATHS } from '@parameters/routing/payments';
import { AuthSession } from 'src/app/auth/models/auth-session.enum';
import { DEVELOPERS_PATHS } from '@parameters/routing/developers';
import { ApmUserService } from '@stores/apm-user.service';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  progressBar: number;
  isLoading: boolean = false;
  terminalCostForOffer$: Subject<any> = new Subject<any>();
  isBusinessCreated: Subject<any> = new Subject<any>();
  currentUser: Subject<any> = new Subject<any>();
  private currentUserRoles$: Subject<string[]> = new Subject<string[]>();
  public roles = this.currentUserRoles$.asObservable();

  nextCurrentRoles(data: any) {
    this.currentUserRoles$.next(data);
  }
  constructor(
    private router: Router,
    private readonly brandingService: BrandingService,
    private toast: GlobalToastrService,
    private skipRouteService: SkipableRoutesService,
    private cookieService: CookieService,
    private apmService: ApmUserService
  ) {}

  public set(item: string, value): void {
    sessionStorage.setItem(item, JSON.stringify(value));
  }

  public get(key: string) {
    return new Promise((resolve, reject) => {
      if (!sessionStorage.key) {
        return reject('data not found !1');
      } else {
        return resolve(JSON.parse(sessionStorage.getItem(key)));
      }
    });
  }
  public capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  public clear(key: string): void {
    sessionStorage.removeItem(key);
  }

  preventBackOnEnter(event: KeyboardEvent) {
    if (event.charCode === 13) {
      event.preventDefault();
    }
  }

  success(message: string) {
    return this.toast.toastrSuccess(message);
  }
  warning(message: string, title?) {
    return this.toast.toastrWarning(message, title);
  }
  info(message: string) {
    return this.toast.toastrInfo(message);
  }
  error(message: string) {
    return this.toast.toastrError(message);
  }

  verifyPhone(event: any, form: any, control: any) {
    form.controls[control].patchValue(event.target.value);
  }

  public get brandingData(): IBrandingResponse {
    return this.brandingService.storedBrandingData;
  }
  // Progress bar events
  progressInc(routeEndPoint: string, url?: string[]) {
    this.skipRouteService.nextRoute(routeEndPoint, url);
  }

  progressDec(routeEndPoint: string, url?: string[]) {
    this.skipRouteService.previousRoute(routeEndPoint, url);
  }
  progressCustom(routeEndPoint: string, id: string) {
    const selectedRoute: RoutePoints[] =
      this.skipRouteService.mappedSkipableRouteList.filter(
        (item: RoutePoints) => item.stepName === routeEndPoint
      );
    this.skipRouteService.setcurrentPosition =
      selectedRoute[0].stepName === APP_ROUTES.business_detail
        ? 1
        : selectedRoute[0].stepNumber;
    this.router.navigate([`/onboarding/${id}/${routeEndPoint}`]);
  }

  public get paymentAccountId() {
    return this.cookieService.get(PAYMENT_PATHS.paymentAccountId);
  }

  public set paymentAccountId(value: string) {
    if (!value) this.cookieService.delete(PAYMENT_PATHS.paymentAccountId, '/');

    this.cookieService.set(PAYMENT_PATHS.paymentAccountId, value, {
      path: '/',
    });
  }

  public get partnerAccountId() {
    return this.cookieService.get(DEVELOPERS_PATHS.partnerAccountId);
  }

  public set partnerAccountId(value: string) {
    if (!value)
      this.cookieService.delete(DEVELOPERS_PATHS.partnerAccountId, '/');

    this.cookieService.set(DEVELOPERS_PATHS.partnerAccountId, value, {
      path: '/',
    });
  }

  public get paymentAuthToken() {
    return this.cookieService.get(AuthSession.paymentAuthToken);
  }

  public set paymentAuthToken(value: string) {
    if (!value) this.cookieService.delete(AuthSession.paymentAuthToken, '/');

    this.cookieService.set(AuthSession.paymentAuthToken, value, {
      path: '/',
    });
  }

  public get paymentAccessToken() {
    return this.cookieService.get(AuthSession.paymentAccessToken);
  }

  public set paymentAccessToken(value: string) {
    if (!value) this.cookieService.delete(AuthSession.paymentAccessToken, '/');

    this.cookieService.set(AuthSession.paymentAccessToken, value, {
      path: '/',
    });
  }

  public get hasAccessToEntityResiduals(): boolean {
    return this.apmService.getDecodedAccessToken()?.[
      AuthSession.hasAccessToEntityResiduals
    ];
  }
}
