import { CoreConfig } from '@core/types/core-config';

// prettier-ignore
export const coreConfig: CoreConfig = {
  layout: {                // fadeInLeft, zoomIn , fadeIn, none
    menu : {
      hidden               : false,           // Boolean: true, false
    },
    // ? For horizontal menu, navbar type will work for navMenu type
    navbar: {
      hidden               : false,           // Boolean: true, false
    },
    navLinks    : true                         // Boolean: true, flase (Show/Hide Header links)
  },
  
}
