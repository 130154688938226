import { Component, EventEmitter, Output } from '@angular/core';
import { NavbarEventbusService } from '../navbar-eventbus.service';

@Component({
  selector: 'app-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.scss'],
})
export class TopNavbarComponent {
  @Output() public toggleSidebar: EventEmitter<string> =
    new EventEmitter<string>();
  constructor(public navbarEventBusService: NavbarEventbusService) {}

  public buttonClicked() {
    this.toggleSidebar.emit('');
  }
}
