import { ModuleWithProviders, NgModule } from '@angular/core';
import { GenericClientService } from '@clients/generic/generic-client.service';
import { AppStoreService } from '@stores/app-store.service';
import { PortalStoreService } from '@stores/portal-store.service';
import { OnboardingCustomizationEditStoreService } from '@pages/onboarding-customization/onboarding-customization-edit/services/onboarding-customization-edit-store.service';

@NgModule()
export class AppFactoryModule {
  static forRoot(): ModuleWithProviders<AppFactoryModule> {
    return {
      ngModule: AppFactoryModule,

      providers: [
        {
          provide: AppStoreService,
          useClass: AppStoreService,
        },
        {
          provide: GenericClientService,
          useClass: GenericClientService,
        },
        {
          provide: PortalStoreService,
          useClass: PortalStoreService,
        },
        {
          provide: OnboardingCustomizationEditStoreService,
          useClass: OnboardingCustomizationEditStoreService,
        },
      ],
    };
  }
}
