<div class="ecom-platform-page w-100">

  <div class="row justify-content-center">
    <div class="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-8  col-xxl-8 align-self-start">
      <div class="d-block page-area-title">
        <h1 class="create_account_heading text-primary fw-800 text-uppercase mb-0 text-center">
          HOW WILL YOU TAKE PAYMENTS ONLINE?
        </h1>
      </div>

      <div class="mtt-4 px-2 mbb-2 pt-2 w-100 mh-400 page-area">
        <div class="row justify-content-center">
          <div class="col-md-3 col-sm-6 col-12 splugin-col" *ngFor="let item of takingPaymentList;let i =index">
            <div [class.active]="id == item?.id" (click)="setPayment(item)"
              [attr.data-transaction-name]="'onboarding--taking-payments--select-payment'"
              class="card cp bg-white comman position-relative rounded-1 border-white">
              <div class="product-img-1 mt-1" *ngIf="item?.logo">
                <img [src]="item?.imageUrl || 'assets\images\hardwares-img/pay-online-1.png'" class="img-fluid" alt="">
              </div>
              <div class="card-title mb-0"
                *ngIf="item?.shortId == 'e_commerce_platforms' || item?.shortId == 'other' || item?.shortId == 'not_using_one_currently' || item?.shortId == 'custom_integrations' ">
                <h5 class="fw-700 text-primary mb-0 mtt-3 text-center">{{item?.title}}</h5>
              </div>
            </div>
          </div>
          <!-- tpayment-col end -->

          <!-- other selected form -->
          <div *ngIf="isOtherSelected && takingPaymentList?.length > 0" class="row other-form mt-1">
            <div class="col-12">
              <div class="form-group">
                <input type="text" required class="form-control font-roboto" [formControl]="platformName"
                  [maxlength]="constants.explanation_limit"
                  [attr.data-transaction-name]="'onboarding--e-commerce-platform--platform-input'" />
                <div class="validation_error" *ngIf="platformName.errors">
                  <div *ngIf="platformName.errors.required && platformName.touched">
                    Platform Name is required.
                  </div>
                  <div *ngIf="
                    platformName.errors.whitespace &&
                    !platformName.errors.required
                  ">
                    White space is not allowed.
                  </div>
                  <div *ngIf="
                    platformName.errors.pattern && !platformName.errors.required
                  ">
                    Platform Name should contain only letters and numbers.
                  </div>
                </div>
                <span class="highlight"></span>
                <span class="bar"></span>
                <label>Please enter e-commerce platform name</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="text-center d-flex flex-row justify-content-center align-items-center mt-2 pt-1">
        <button type="button" class="back_button text-uppercase me-1 waves-effect waves-float waves-light"
          (click)="goBack()" [attr.data-transaction-name]="'onboarding--taking-payments--back-button'"> Back
        </button>
        <button type="button" class="btn btn-primary mw-120 text-uppercase waves-effect waves-float waves-light"
          [class.button--loading]="loading" (click)="submit()" [disabled]="!selectedItem || loading"
          [attr.data-transaction-name]="'onboarding--taking-payments--continue-button'"> Continue
        </button>
      </div>
    </div>
  </div>
</div>