export const enum EFPChangeLogListParams {
  size = 'limit',
  page = 'page',
  sort = 'order_by',
  search = 'search',
}

export const enum EFPChangeLogListSort {
  asc = 'asc',
  desc = 'desc',
  createdOn = 'created_on',
}

export const CFPChangeLogListParamsDefault = {
  [EFPChangeLogListParams.size]: 10,
  [EFPChangeLogListParams.page]: 1,
  [EFPChangeLogListParams.sort]: [
    EFPChangeLogListSort.createdOn,
    EFPChangeLogListSort.desc,
  ],
  [EFPChangeLogListParams.search]: '',
};
