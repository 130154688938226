import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  inject,
} from '@angular/core';

import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { CoreConfigService } from '@core/services/config.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/auth/service';
import { CommonService } from 'src/app/shared/services/common.service';
import {
  DashboardType,
  SidebarItemsService,
} from 'src/app/shared/services/sidebar-items.service';
import { MENU_ITEM_DATA } from '../../sidebar/sidebar.interface';
import { DOCUMENT } from '@angular/common';
import { UserRoles } from 'src/app/auth/models';
import { MspMerchantApplicationStoreService } from 'src/app/business/authentication/services/msp-merchant-application-store.service';
import { PortalStoreService } from '@stores/portal-store.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CoreConfig } from '@core/types/core-config';
import { PaymentAccountsStoreService } from '@stores/payment-accounts-store.service';
import { DevelopersAccountService } from '@stores/developers-account-store.service';

@Component({
  selector: 'vertical-layout',
  templateUrl: './vertical-layout.component.html',
  styleUrls: ['./vertical-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerticalLayoutComponent implements OnInit, OnDestroy {
  coreConfig: CoreConfig;
  /*
    public for App sidebar opened
  */
  public sidebarOpened: boolean = false;

  /*
    public for App sidebar opened
  */
  public sidebarMode: string = 'push';

  /**
   * subcription
   */
  private subscription: Subscription = new Subscription();

  // Private
  private _unsubscribeAll: Subject<any>;
  /*
      App sidebar menu data
    */
  public sidebarData: MENU_ITEM_DATA[];

  navigationInProgress: boolean = true;

  isContentLoaded: boolean = false;

  public sidebarDashboardType: DashboardType;

  public readonly dashboardType = DashboardType;

  private readonly _destroyRef = inject(DestroyRef);

  @HostListener('window:resize', ['$event'])
  public onWindowResize() {
    this.handleSidebarProps();
  }

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   * @param router
   * @param document
   * @param _authService
   * @param _cmnService
   * @param sidebarItemService
   * @param _changeDetectorRef
   * @param authStoreService
   * @param portalStore
   * @param _paymentAccountStore
   * @param _developerAccountStore
   */

  constructor(
    private _coreConfigService: CoreConfigService,
    public router: Router,
    @Inject(DOCUMENT) private document: Document,
    private _authService: AuthenticationService,
    private _cmnService: CommonService,
    private sidebarItemService: SidebarItemsService,
    private _changeDetectorRef: ChangeDetectorRef,
    private authStoreService: MspMerchantApplicationStoreService,
    private portalStore: PortalStoreService,
    private readonly _paymentAccountStore: PaymentAccountsStoreService,
    private readonly _developerAccountStore: DevelopersAccountService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.navigationInProgress = true;
        this.isContentLoaded = !this.router.navigated;
        // Navigation started
      }

      if (event instanceof NavigationEnd) {
        this.navigationInProgress = false;
        this.isContentLoaded = !this.router.navigated;
        // Navigation completed
      }
    });
    this.subscription.add(
      this._cmnService.roles.subscribe((roles: string[]) => {
        //Set Sidebar Data
        this.setSidebarData();
      })
    );

    this.portalStore.type$.subscribe((portal) => {
      if (portal) {
        this.setSidebarData();
      }
    });

    this.sidebarItemService.dashboardType$
      .pipe(takeUntilDestroyed())
      .subscribe((newType) => {
        this.setSidebarData();
        this.sidebarDashboardType = newType ?? DashboardType.DEFAULT;
      });

    this._paymentAccountStore?.currentPaymentAccount$
      ?.pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((res) => {
        if (res) {
          this.setSidebarData();
        }
      });

    this._developerAccountStore?.currentPartnerAccount$
      ?.pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((res) => {
        if (res) {
          this.setSidebarData();
        }
      });

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------
  ngAfterContentChecked() {
    this._changeDetectorRef.detectChanges();
  }
  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to config changes
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config: CoreConfig) => {
        this.coreConfig = config;
      });

    // Subscribe to router events
    this.subscription.add(
      this.router.events
        .pipe(filter((e) => e instanceof NavigationEnd))
        .subscribe({
          next: () => {
            if (this.sidebarMode == 'over') {
              this.sidebarOpened = false;
            }
          },
        })
    );

    this.sidebarItemService.sidebarToggleMenu$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((isOpen) => {
        this.handleSidenav(isOpen);
      })

    //Set Sidebar Data
    this.setSidebarData();
  }

  ngAfterViewInit() {
    /**
     * Set opened state of sidebar based on window width
     */
    this.handleSidebarProps();
  }

  /**
   * Local method to handle sidebar mode and opened
   */
  private handleSidebarProps() {
    if (window.innerWidth > 1200) {
      this.sidebarMode = 'push';
      this.sidebarOpened = true;
      this._changeDetectorRef.detectChanges();
    } else {
      this.sidebarMode = 'over';
      this.sidebarOpened = false;
    }
    this.toggleTopnavClasses();
  }

  /**
   * Sidebar date
   */
  public setSidebarData() {
    this.authStoreService.currentMerchant.subscribe((res) => {
      if (res) {
        this.setDashboardVisibility(res);
      }
    });
    if (this.authStoreService.getStoredSelectedMerchantApplicationData) {
      this.setDashboardVisibility(
        this.authStoreService.getStoredSelectedMerchantApplicationData
      );
    }
    this.sidebarData = this.sidebarItemService.sideBarOptions;
  }

  setDashboardVisibility(merchant) {
    this.sidebarData = this.sidebarItemService.sideBarOptions;
    this.sidebarData.map((item) => {
      if (
        item.name === 'Dashboard' &&
        this._authService.hasRole(UserRoles.Merchant)
      ) {
        item.show = true;
      }
    });
  }

  /**
   * Local method to handle topnav classes
   */
  toggleTopnavClasses() {
    const topnavElement = this.document.querySelector('app-top-navbar');
    if (!topnavElement) return;
    this.sidebarOpened && this.sidebarMode !== 'over'
      ? this.document
          .querySelector('app-top-navbar')
          .classList.add('sidebar-expanded')
      : this.document
          .querySelector('app-top-navbar')
          .classList.remove('sidebar-expanded');
  }

  /**
   * Public method to validate sidebar hide/show state
   */
  get validateSidebar() {
    const url = this.router.url.split('?return-url')[0];
    return (
      url.includes('login') ||
      url.includes('signup') ||
      url.includes('forgot-password') ||
      url.includes('reset-password') ||
      url.includes('set-password') ||
      url.includes('onboarding') ||
      url.includes('verification') ||
      url.includes('application-submitted') ||
      url.includes('merchant-application-progress') ||
      url.includes('secondary-signer') ||
      url.includes('miscellaneous/error') ||
      url.includes('miscellaneous/page-not-found') ||
      url.includes('miscellaneous/terms-of-use') ||
      url.includes('miscellaneous/permission-denied')
    );
  }

  get hasDashboardRoute() {
    const url = this.router.url.split('?return-url')[0];
    return url.includes('dashboard');
  }

  /**
   * Handle sidenav states from sidebar content
   */
  public handleSidenav(event: boolean) {
    this.sidebarOpened = event;
    this.toggleTopnavClasses();
  }

  /**
   * Public toggle Sidebar main to detect the state of sidebar from sidenav component
   */
  public toggleSidebarMain() {
    this.sidebarOpened = !this.sidebarOpened;
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
    this.subscription.unsubscribe();
  }
}
