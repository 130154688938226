import { DatesHelper } from '@helpers/date/dates.helper';

export const enum EDateFilter {
  today = 'Today',
  last7Days = 'Last 7 days',
  last14Days = 'Last 14 days',
  last30Days = 'Last 30 days',
  currentMonth = 'Current Month',
  lastMonth = 'Last Month',
  custom = 'Custom',
}

export interface DateFilterValue {
  name: EDateFilter;
  label: EDateFilter | string;
  value: {
    startDate: string;
    endDate: string;
  };
  unixValue: {
    startDate: number;
    endDate: number;
  };
}

export interface IDateFilterListValue {
  [EDateFilter.today]: DateFilterValue;
  [EDateFilter.last7Days]: DateFilterValue;
  [EDateFilter.last14Days]: DateFilterValue;
  [EDateFilter.last30Days]: DateFilterValue;
  [EDateFilter.currentMonth]: DateFilterValue;
  [EDateFilter.lastMonth]: DateFilterValue;
  [EDateFilter.custom]: DateFilterValue;
}

export const CDateFilterListValue: IDateFilterListValue = {
  [EDateFilter.today]: {
    name: EDateFilter.today,
    label: EDateFilter.today,
    value: {
      startDate: DatesHelper.startOfDayDaysAgoLocal(0).format('YYYY-MM-DD'),
      endDate: DatesHelper.todayEndOfDayLocal().format('YYYY-MM-DD'),
    },
    unixValue: {
      startDate: DatesHelper.startOfDayDaysAgoUnixTimestamp(0),
      endDate: DatesHelper.todayEndOfDayUnixTimestamp(),
    },
  },
  [EDateFilter.last7Days]: {
    name: EDateFilter.last7Days,
    label: EDateFilter.last7Days,
    value: {
      startDate: DatesHelper.startOfDayDaysAgoLocal(7).format('YYYY-MM-DD'),
      endDate: DatesHelper.todayEndOfDayLocal().format('YYYY-MM-DD'),
    },
    unixValue: {
      startDate: DatesHelper.startOfDayDaysAgoUnixTimestamp(7),
      endDate: DatesHelper.todayEndOfDayUnixTimestamp(),
    },
  },
  [EDateFilter.last14Days]: {
    name: EDateFilter.last14Days,
    label: EDateFilter.last14Days,
    value: {
      startDate: DatesHelper.startOfDayDaysAgoLocal(14).format('YYYY-MM-DD'),
      endDate: DatesHelper.todayEndOfDayLocal().format('YYYY-MM-DD'),
    },
    unixValue: {
      startDate: DatesHelper.startOfDayDaysAgoUnixTimestamp(14),
      endDate: DatesHelper.todayEndOfDayUnixTimestamp(),
    },
  },
  [EDateFilter.last30Days]: {
    name: EDateFilter.last30Days,
    label: EDateFilter.last30Days,
    value: {
      startDate: DatesHelper.startOfDayDaysAgoLocal(30).format('YYYY-MM-DD'),
      endDate: DatesHelper.todayEndOfDayLocal().format('YYYY-MM-DD'),
    },
    unixValue: {
      startDate: DatesHelper.startOfDayDaysAgoUnixTimestamp(30),
      endDate: DatesHelper.todayEndOfDayUnixTimestamp(),
    },
  },
  [EDateFilter.currentMonth]: {
    name: EDateFilter.currentMonth,
    label: EDateFilter.currentMonth,
    value: {
      startDate:
        DatesHelper.startOfDayFirstDayOfCurrentMonthLocal().format(
          'YYYY-MM-DD'
        ),
      endDate:
        DatesHelper.endOfDayLastDayOfCurrentMonthLocal().format('YYYY-MM-DD'),
    },
    unixValue: {
      startDate: DatesHelper.startOfDayFirstDayOfCurrentMonthUnixTimestamp(),
      endDate: DatesHelper.endOfDayLastDayOfCurrentMonthUnixTimestamp(),
    },
  },
  [EDateFilter.lastMonth]: {
    name: EDateFilter.lastMonth,
    label: EDateFilter.lastMonth,
    value: {
      startDate:
        DatesHelper.startOfDayFirstDayOfLastMonthLocal().format('YYYY-MM-DD'),
      endDate:
        DatesHelper.endOfDayLastDayOfLastMonthLocal().format('YYYY-MM-DD'),
    },
    unixValue: {
      startDate: DatesHelper.startOfDayFirstDayOfLastMonthUnixTimestamp(),
      endDate: DatesHelper.endOfDayLastDayOfLastMonthUnixTimestamp(),
    },
  },
  [EDateFilter.custom]: {
    name: EDateFilter.custom,
    label: EDateFilter.custom,
    value: null,
    unixValue: null,
  },
};

export const CDateRangeFilterList: DateFilterValue[] = [
  CDateFilterListValue[EDateFilter.today],
  CDateFilterListValue[EDateFilter.last7Days],
  CDateFilterListValue[EDateFilter.last14Days],
  CDateFilterListValue[EDateFilter.last30Days],
  CDateFilterListValue[EDateFilter.currentMonth],
  CDateFilterListValue[EDateFilter.lastMonth],
  CDateFilterListValue[EDateFilter.custom],
];
