import {
  IResponseBase,
  IResponseData,
} from '@interfaces/generic/responses.interface';
import {
  EReportsBatchDetailParams,
  EReportsBatchDetailType,
} from '@parameters/reports/batch/batch-detail.parameter';

export interface IReportsBatchDetailParams {
  [EReportsBatchDetailParams.page]: number;
  [EReportsBatchDetailParams.size]: number;
}

export const CReportsBatchDetailParamsDefault: IReportsBatchDetailParams = {
  [EReportsBatchDetailParams.size]: 10,
  [EReportsBatchDetailParams.page]: 1,
};

export interface IReportsBatchDetail {
  cardHolderNumber: string;
  transactionAmount: number;
  transactionDate: string;
  transactionReferance: string;
  transactionType: EReportsBatchDetailType;
  cardBrand: string;
}

export interface IReportsBatchDetailResponseOther {
  batchDate: string;
  dbaName: string;
  mid: string;
  referanceNumber: string;
  totalNetVolume: number;
  totalRefunds: number;
  totalSales: number;
}

export interface IReportsBatchDetailResponseData<T> extends IResponseData<T> {
  others: IReportsBatchDetailResponseOther;
}

export interface IReportsBatchDetailResponse
  extends IResponseBase<IReportsBatchDetailResponseData<IReportsBatchDetail>> {}

export enum CARD_TYPE {
  VISA = 'visa',
  MASTER_CARD = 'mastercard',
  DISCOVER = 'discover',
  JCB = 'jcb',
  AMEX = 'amex',
  PIN_DEBIT = 'pin_debit',
  EBT = 'ebt',
  OTHER = 'other',
}

export const CardIcons = {
  [CARD_TYPE.VISA]: 'assets/images/visa.svg',
  [CARD_TYPE.MASTER_CARD]: 'assets/images/mastercard.svg',
  [CARD_TYPE.DISCOVER]: 'assets/images/disc.svg',
  [CARD_TYPE.JCB]: 'assets/images/jcb.svg',
  [CARD_TYPE.AMEX]: 'assets/images/amex.png',
  [CARD_TYPE.PIN_DEBIT]: 'assets/images/pin.svg',
  [CARD_TYPE.EBT]: 'assets/images/EBT.svg',
  [CARD_TYPE.OTHER]: 'assets/images/question.svg',
};
