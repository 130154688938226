<div class="account-detail-card" [class.no-border]="hasDealId">
  <ng-container *ngIf="!isEditable; else editForm">
    <div class="account-detail-edit-icon">
      <gtr-badge type="icon" shape="round" color="info" (click)="toggleEdit()"
                 [dataTransactionName]="'account-detail--edit'"
                 [attr.data-transaction-name]="'account-detail--edit'">
        <gtr-icon slot="icon" name="pencil" size="16"></gtr-icon>
      </gtr-badge>
    </div>

    <div class="row">
      <div class="col-12 col-md-4">
        <gtr-display-value
          [loading]="loading"
          [label]="'Account Name'"
          [value]="accountDetail?.accountName || 'Not Defined'"
          [dataTransactionName]="'account-detail--account-name'"
          [attr.data-transaction-name]="'account-detail--account-name'"
        ></gtr-display-value>
      </div>
      <div class="col-12 col-md-4">
        <gtr-display-value
          [loading]="loading"
          [label]="'Account ID'"
          [value]="accountDetail?.id || 'Not Defined'"
          [dataTransactionName]="'account-detail--account-id'"
          [attr.data-transaction-name]="'account-detail--account-id'"
        ></gtr-display-value>
      </div>

      <div class="col-12 col-md-4">
        <gtr-display-value
          [loading]="loading"
          [label]="'Gateway Status'"
          [contentType]="'projected'"
          [dataTransactionName]="'account-detail--gateway-status'"
          [attr.data-transaction-name]="'account-detail--gateway-status'"
        >
          <div slot="custom-content">
            <gtr-badge
              [color]="
                gatewayStatusColor[accountDetail?.gatewayStatus] || 'gray'
              "
            >
              {{
                gatewayStatusLabel[accountDetail?.gatewayStatus] ||
                  "Not Defined"
              }}
            </gtr-badge>
          </div>
        </gtr-display-value>
      </div>
    </div>
  </ng-container>

  <ng-template #editForm>
    <app-account-detail-form
      [detail]="accountDetail"
      (close)="handleFormClose($event)"
    />
  </ng-template>
</div>
