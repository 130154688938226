import { HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { GenericClientService } from '@clients/generic/generic-client.service';
import {
  IPartnerAccountListParams,
  IPartnerAccountListResponse,
} from '@interfaces/developers/partner-account-select.interface';
import {
  CPartnerAccountListParams,
  EPartnerAccountListParams,
} from '@parameters/developers/partner-account-select.parameter';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class PartnerAccountSelectClientService {
  private _genericClient = inject(GenericClientService);

  public getPartnerAccounts(
    params: IPartnerAccountListParams
  ): Observable<IPartnerAccountListResponse> {
    const endpoint = EndpointsParameter.PartnerMyAccounts;

    let httpParams = new HttpParams()
      .set(
        EPartnerAccountListParams.page,
        params[EPartnerAccountListParams.page] ||
          CPartnerAccountListParams[EPartnerAccountListParams.page]
      )
      .set(
        EPartnerAccountListParams.limit,
        params[EPartnerAccountListParams.limit] ||
          CPartnerAccountListParams[EPartnerAccountListParams.limit]
      )
      .set(
        EPartnerAccountListParams.search,
        params[EPartnerAccountListParams.search] ||
          CPartnerAccountListParams[EPartnerAccountListParams.search]
      );

    return this._genericClient.genericGet<IPartnerAccountListResponse>(
      endpoint,
      { params: httpParams }
    );
  }
}
