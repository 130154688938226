import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

import { GenericClientService } from '@clients/generic/generic-client.service';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import {
  CDepositListParamsDefault,
  IDepositCSVBody,
  IDepositCSVResponse,
  IDepositListParams,
  IDepositListResponse,
} from '@interfaces/reports/deposit/deposit-list.interface';
import { EDepositListParams } from '@parameters/reports/deposits/deposit-list.parameter';
import {
  CDepositDetailParamsDefault,
  IDepositDetailCSVBody,
  IDepositDetailCSVResponse,
  IDepositDetailParams,
  IDepositDetailResponse,
} from '@interfaces/reports/deposit/deposit-detail.interface';
import { EDepositDetailParams } from '@parameters/reports/deposits/deposit-detail.parameter';
import { GenericHelper } from '@helpers/generic.helper';

@Injectable()
export class DepositClientService {
  constructor(private genericClient: GenericClientService) {}

  /**
   * Deposits Summary List
   * @param queryParams
   * @returns
   */

  getDepositList(
    queryParams: IDepositListParams
  ): Observable<IDepositListResponse> {
    const endpoint = EndpointsParameter.DepositsSettlementsSummary;
    const params = new HttpParams()
      .set(
        EDepositListParams.midList,
        queryParams[EDepositListParams.midList].join(',') ||
          CDepositListParamsDefault[EDepositListParams.midList].join(',')
      )
      .set(
        EDepositListParams.sources,
        queryParams[EDepositListParams.sources].join(',') ||
          CDepositListParamsDefault[EDepositListParams.sources].join(',')
      )
      .set(
        EDepositListParams.binList,
        queryParams[EDepositListParams.binList].join(',') ||
          CDepositListParamsDefault[EDepositListParams.binList].join(',')
      )
      .set(
        EDepositListParams.type,
        queryParams[EDepositListParams.type] ||
          CDepositListParamsDefault[EDepositListParams.type]
      )
      .set(
        EDepositListParams.limit,
        queryParams[EDepositListParams.limit] ||
          CDepositListParamsDefault[EDepositListParams.limit]
      )
      .set(
        EDepositListParams.page,
        queryParams[EDepositListParams.page] ||
          CDepositListParamsDefault[EDepositListParams.page]
      )
      .set(
        EDepositListParams.sort,
        queryParams[EDepositListParams.sort] ||
          CDepositListParamsDefault[EDepositListParams.sort]
      )
      .set(
        EDepositListParams.search,
        queryParams[EDepositListParams.search] ||
          CDepositListParamsDefault[EDepositListParams.search]
      )
      .set(
        EDepositListParams.startDate,
        queryParams[EDepositListParams.startDate] ||
          CDepositListParamsDefault[EDepositListParams.startDate]
      )
      .set(
        EDepositListParams.endDate,
        queryParams[EDepositListParams.endDate] ||
          CDepositListParamsDefault[EDepositListParams.endDate]
      );

    return this.genericClient.genericGet<IDepositListResponse>(endpoint, {
      params,
    });
  }

  /**
   * Deposits Detail List
   * @param detailId
   * @param queryParams
   * @returns
   */

  getDepositDetail(
    detailId: string,
    queryParams: IDepositDetailParams
  ): Observable<IDepositDetailResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.DepositsSettlementsDetail,
      detailId
    );
    const params = new HttpParams()
      .set(
        EDepositDetailParams.size,
        queryParams[EDepositDetailParams.size] ||
          CDepositDetailParamsDefault[EDepositDetailParams.size]
      )
      .set(
        EDepositDetailParams.page,
        queryParams[EDepositDetailParams.page] ||
          CDepositDetailParamsDefault[EDepositDetailParams.page]
      );

    return this.genericClient.genericGet<IDepositDetailResponse>(endpoint, {
      params,
    });
  }

  /**
   * Download CSV
   * @param body
   * @returns
   */
  public downloadCSV(body: IDepositCSVBody): Observable<IDepositCSVResponse> {
    const endpoint = EndpointsParameter.DepositsCSV;
    return this.genericClient.genericPost(endpoint, body);
  }

  /**
   * Download CSV
   * @param body
   * @returns
   */
  public downloadDetailCSV(
    body: IDepositDetailCSVBody
  ): Observable<IDepositDetailCSVResponse> {
    const endpoint = EndpointsParameter.DepositsDetailCSV;
    return this.genericClient.genericPost(endpoint, body);
  }
}
