import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { THttpOptions } from '@interfaces/generic/http-options.interface';

@Injectable({
  providedIn: 'root',
})
export class GenericClientService {
  constructor(private http: HttpClient) {}

  public genericGet<T>(
    endpoint: string,
    options: THttpOptions = {}
  ): Observable<T> {
    return this.http
      .get<T>(endpoint, options)
      .pipe(tap((response) => this.log(`Get ` + endpoint)));
  }

  public genericPost<T>(
    endpoint: string,
    body: any | null,
    options: THttpOptions = {}
  ): Observable<T> {
    return this.http
      .post<T>(endpoint, body, options)
      .pipe(tap((response) => this.log(`Post ` + endpoint)));
  }

  public genericPut<T>(
    endpoint: string,
    body: any | null,
    options: THttpOptions = {}
  ): Observable<T> {
    return this.http
      .put<T>(endpoint, body, options)
      .pipe(tap((response) => this.log(`Put ` + endpoint)));
  }

  public genericDelete<T>(
    endpoint: string,
    options: THttpOptions = {}
  ): Observable<T> {
    return this.http
      .delete<T>(endpoint, options)
      .pipe(tap((response) => this.log(`Delete ` + endpoint)));
  }

  public genericPatch<T>(
    endpoint: string,
    body: any | null,
    options: THttpOptions = {}
  ): Observable<T> {
    return this.http
      .patch<T>(endpoint, body, options)
      .pipe(tap((response) => this.log(`Patch ` + endpoint)));
  }

  /** Log a message with the GenericService */
  public log(message: string, showLogs = environment.showLogs) {
    if (showLogs) console.log(message);
  }
}
