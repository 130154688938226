export const enum EFundingPlanListParams {
  status = 'status',
  limit = 'limit',
  page = 'page',
  sort = 'order_by',
  search = 'search',
}

export const enum EFundingPlanListParamSort {
  asc = 'asc',
  desc = 'desc',
  createdDate = 'created_on',
  // dbaName = 'dba_name',
  // type = 'type',
  // dba_name = 'dba_name',
  // mid = 'mid',
  // legal_name = 'legal_name',
  // business_city = 'business_city',
  // name = 'name',
  // customer_service_phone_number = 'customer_service_phone_number',
  // status = 'status',
  // created_timestamp = 'created_timestamp',
  // created_by = 'created_by',
  // updated_timestamp = 'updated_timestamp',
  // updated_by = 'updated_by',
  // id = 'id',
  // planName = 'planName',
  // Description = 'Description',
  // createdOn = 'createdOn',
}

export const CFundingPlanListParamsDefault = {
  [EFundingPlanListParams.status]: [],
  [EFundingPlanListParams.limit]: 10,
  [EFundingPlanListParams.page]: 1,
  [EFundingPlanListParams.sort]: [
    EFundingPlanListParamSort.createdDate,
    EFundingPlanListParamSort.desc,
  ],
  [EFundingPlanListParams.search]: '',
};
