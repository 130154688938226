import {
  IGenericTokenPagination,
  IResponsePagination,
  ITokenBasedPagination,
} from '@interfaces/generic/responses.interface';

export class ResponsePagination {
  page: number;
  size: number;
  totalRecords: number;
  recordsPerPage: number;

  constructor(iResponsePagination: IResponsePagination) {
    this.totalRecords = iResponsePagination.totalRows;
    this.page = iResponsePagination.page;
    this.size = iResponsePagination.limit;
    this.recordsPerPage = iResponsePagination.recordsRetrieved;
  }
}

export class TokenBasedPagination {
  recordsRetrieved: number;
  limit: number;
  nextResultsToken: string;
  currentPageToken: string;
  prevPageToken: string;

  constructor(iTokenPagination: ITokenBasedPagination) {
    this.recordsRetrieved = iTokenPagination?.recordsRetrieved;
    this.limit = iTokenPagination?.limit;
    this.nextResultsToken = iTokenPagination?.nextResultsToken;
    this.currentPageToken = iTokenPagination?.currentPageToken;
    this.prevPageToken = iTokenPagination?.prevPageToken;
  }
}

export class GenericTokenPagination {
  recordsRetrieved: number;
  limit: number;
  nextResultsToken: string;

  constructor(res: IGenericTokenPagination) {
    this.recordsRetrieved = res?.recordsRetrieved;
    this.limit = res?.limit;
    this.nextResultsToken = res?.nextResultsToken;
  }
}
