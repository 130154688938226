import { Injectable } from '@angular/core';
import { GenericClientService } from '@clients/generic/generic-client.service';
import {
  CMetricsKPIsVolumeParamsDefault,
  IMetricsKPIsVolumeParams,
  IMetricsKPIsVolumeResponse,
} from '@interfaces/metrics/metrics-kpis-volume.interface';
import { Observable } from 'rxjs';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import { HttpParams } from '@angular/common/http';
import { EMetricsKPIsVolumeParams } from '@parameters/metrics/metrics-kpis-volume.parameter';

@Injectable()
export class MetricsKpisVolumeClientService {
  constructor(private genericClient: GenericClientService) {}

  getMetricsKPIsVolume(
    queryParams: IMetricsKPIsVolumeParams
  ): Observable<IMetricsKPIsVolumeResponse> {
    const endpoint = EndpointsParameter.MetricsKPIsVolumes;
    const params = new HttpParams()
      .set(
        EMetricsKPIsVolumeParams.startDate,
        queryParams[EMetricsKPIsVolumeParams.startDate] ||
          CMetricsKPIsVolumeParamsDefault[EMetricsKPIsVolumeParams.startDate]
      )
      .set(
        EMetricsKPIsVolumeParams.endDate,
        queryParams[EMetricsKPIsVolumeParams.endDate] ||
          CMetricsKPIsVolumeParamsDefault[EMetricsKPIsVolumeParams.endDate]
      )
      .set(
        EMetricsKPIsVolumeParams.midList,
        queryParams[EMetricsKPIsVolumeParams.midList].join(',') ||
          CMetricsKPIsVolumeParamsDefault[
            EMetricsKPIsVolumeParams.midList
          ].join(',')
      )
      .set(
        EMetricsKPIsVolumeParams.binList,
        queryParams[EMetricsKPIsVolumeParams.binList].join(',') ||
          CMetricsKPIsVolumeParamsDefault[
            EMetricsKPIsVolumeParams.binList
          ].join(',')
      )
      .set(
        EMetricsKPIsVolumeParams.kpiList,
        queryParams[EMetricsKPIsVolumeParams.kpiList].join(',') ||
          CMetricsKPIsVolumeParamsDefault[
            EMetricsKPIsVolumeParams.kpiList
          ].join(',')
      )
      .set(
        EMetricsKPIsVolumeParams.cardBrandList,
        queryParams[EMetricsKPIsVolumeParams.cardBrandList].join(',') ||
          CMetricsKPIsVolumeParamsDefault[
            EMetricsKPIsVolumeParams.cardBrandList
          ].join(',')
      );
    return this.genericClient.genericGet<IMetricsKPIsVolumeResponse>(endpoint, {
      params,
    });
  }
}
