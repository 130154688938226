import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import {
  AmountFormatter,
  CurrencySymbolPipe,
  RoundWithDecimalPipe,
  WhiteSpaceSeparator,
} from './pipes';
import { NumericInputFormatDirective } from './directives';
import { NumberNotationPipe } from './pipes/notation.pipe';
import { TimeInputMaskDirective } from './directives/time-input-mask.directive';

const CORE_PIPES = [
  AmountFormatter,
  NumberNotationPipe,
  RoundWithDecimalPipe,
  CurrencySymbolPipe,
  WhiteSpaceSeparator,
];
const CORE_DIRECTIVES = [NumericInputFormatDirective, TimeInputMaskDirective];

@NgModule({
  declarations: [...CORE_PIPES, ...CORE_DIRECTIVES],
  imports: [CommonModule],
  exports: [...CORE_PIPES, ...CORE_DIRECTIVES],
  providers: [DecimalPipe],
})
export class CoreModule {}
