import { Injectable } from '@angular/core';
import { GenericClientService } from '@clients/generic/generic-client.service';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  CReportsStatementListParamsDefault,
  IReportsStatementListParams,
  IReportsStatementListResponse,
} from '@interfaces/reports/statement/statement-list.interface';
import { EReportsStatementListParams } from '@parameters/reports/statement/statement-list.parameter';
import { IReportsStatementDetailResponse } from '@interfaces/reports/statement/statement-detail.interface';

@Injectable()
export class StatementClientService {
  constructor(private genericClient: GenericClientService) {}

  getList(
    queryParams: IReportsStatementListParams
  ): Observable<IReportsStatementListResponse> {
    const endpoint = EndpointsParameter.ReportsStatement;
    const params = new HttpParams()
      .set(
        EReportsStatementListParams.size,
        queryParams[EReportsStatementListParams.size] ||
          CReportsStatementListParamsDefault[EReportsStatementListParams.size]
      )
      .set(
        EReportsStatementListParams.page,
        queryParams[EReportsStatementListParams.page] ||
          CReportsStatementListParamsDefault[EReportsStatementListParams.page]
      )
      .set(
        EReportsStatementListParams.sort,
        queryParams[EReportsStatementListParams.sort] ||
          CReportsStatementListParamsDefault[EReportsStatementListParams.sort]
      )
      .set(
        EReportsStatementListParams.search,
        queryParams[EReportsStatementListParams.search] ||
          CReportsStatementListParamsDefault[EReportsStatementListParams.search]
      )
      .set(
        EReportsStatementListParams.startDate,
        queryParams[EReportsStatementListParams.startDate] ||
          CReportsStatementListParamsDefault[
            EReportsStatementListParams.startDate
          ]
      )
      .set(
        EReportsStatementListParams.endDate,
        queryParams[EReportsStatementListParams.endDate] ||
          CReportsStatementListParamsDefault[
            EReportsStatementListParams.endDate
          ]
      )
      .set(
        EReportsStatementListParams.midList,
        queryParams[EReportsStatementListParams.midList].join(',') ||
          CReportsStatementListParamsDefault[
            EReportsStatementListParams.midList
          ].join(',')
      );
    return this.genericClient.genericGet<IReportsStatementListResponse>(
      endpoint,
      {
        params,
      }
    );
  }

  getDetail(id: string): Observable<IReportsStatementDetailResponse> {
    const endpoint = `${EndpointsParameter.ReportsStatement}/${id}`;
    return this.genericClient.genericGet<IReportsStatementDetailResponse>(
      endpoint
    );
  }
}
