import { Injectable } from '@angular/core';
import { UserRoles } from 'src/app/auth/models';
import { AuthenticationService } from 'src/app/auth/service';
import { MENU_ITEM_DATA } from 'src/app/core/components/sidebar/sidebar.interface';
import { CommonService } from './common.service';
import { IBrandingResponse } from '@interfaces/branding/branding.interface';
import { PortalStoreService } from '@stores/portal-store.service';
import { BUSINESS_PATH } from '@parameters/routing/business.parameter';
import { PORTAL_PATH } from '@parameters/routing/portal.parameter';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';
import { PAYMENT_PATHS } from '@parameters/routing/payments';
import { PaymentAccountsStoreService } from '@stores/payment-accounts-store.service';
import { DEVELOPERS_PATHS } from '@parameters/routing/developers';
import { DevelopersAccountService } from '@stores/developers-account-store.service';
import { SETTINGS_PATH } from '@parameters/routing/setting.parameter';

export enum DashboardType {
  DEFAULT = 'DEFAULT',
  PAYMENT = 'PAYMENT',
  DEVELOPERS = 'DEVELOPERS',
}

@Injectable({
  providedIn: 'root',
})
export class SidebarItemsService {
  private dashboardType = new BehaviorSubject<DashboardType>(
    DashboardType.DEFAULT
  );

  private currentDashboardType = toSignal(this.dashboardType$);
  private readonly _currentAccountId = toSignal(
    this._paymentAccountStore?.currentPaymentAccount$
  );

  private sidebarToggleMenuSubject: Subject<boolean> = new Subject<boolean>();

  private readonly _currentPartnerId = toSignal(
    this._developersAccountStore?.currentPartnerAccount$
  );

  /**
   * Constructor
   * @param authService
   */
  public merchantId: string;
  public roles: string[] = [];

  constructor(
    private readonly authService: AuthenticationService,
    private readonly commonService: CommonService,
    private portalStore: PortalStoreService,
    private readonly _paymentAccountStore: PaymentAccountsStoreService,
    private readonly _developersAccountStore: DevelopersAccountService
  ) {
    this.commonService.roles.subscribe((role) => {
      if (role && role?.length) {
        this.roles = role;
        this.sideBarOptions;
      }
    });

    this.portalStore.type$.subscribe((portal) => {
      if (portal) {
        this.sideBarOptions;
      }
    });
  }

  public get sidebarToggleMenu$(): Observable<boolean> {
    return this.sidebarToggleMenuSubject.asObservable();
  }

  public sidebarToggleMenu(value: boolean) {
    this.sidebarToggleMenuSubject.next(value);
  }

  /**
   * Method to get sidebar options
   */
  public get sideBarOptions(): MENU_ITEM_DATA[] {
    this.merchantId = this.authService.currentSelectedMerchantId;
    const id = this.merchantId;
    const userRole: string[] = this.roles?.length
      ? this.roles
      : this.authService.userRoles;
    const primaryOrSecondaryMerchantIdsExist =
      this.authService.primarySignerMerchantIds?.length > 0 ||
      this.authService.secondarySignerMerchantIds?.length > 0;

    const paymentAccoutId = this.commonService.paymentAccountId;
    const partnerAccountId = this.commonService.partnerAccountId;

    if (this.currentDashboardType() === DashboardType.PAYMENT) {
      return [
        {
          name: 'Payments',
          path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/${
            PAYMENT_PATHS.base
          }/${this._currentAccountId() || paymentAccoutId}/${
            PAYMENT_PATHS.home
          }`,
          icon: 'credit-card',
          show: true,
        },
        {
          name: 'Transfers',
          path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/${
            PAYMENT_PATHS.base
          }/${this._currentAccountId() || paymentAccoutId}/${
            PAYMENT_PATHS.transfers
          }`,
          icon: 'transfers',
          show: true,
        },
        {
          name: 'Customers',
          path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/${
            PAYMENT_PATHS.base
          }/${this._currentAccountId() || paymentAccoutId}/${
            PAYMENT_PATHS.customers
          }`,
          icon: 'payment-customers',
          show: true,
        },
        {
          name: 'ADVANCED SETTINGS',
          isLabel: true,
          show: true,
        },
        {
          name: 'Payment Methods',
          path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/${
            PAYMENT_PATHS.base
          }/${this._currentAccountId() || paymentAccoutId}/${
            PAYMENT_PATHS.paymentSettings
          }/${PAYMENT_PATHS.paymentMethods}`,
          icon: 'wallet',
          show: true,
        },
        {
          name: 'Domains',
          path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/${
            PAYMENT_PATHS.base
          }/${this._currentAccountId() || paymentAccoutId}/${
            PAYMENT_PATHS.paymentSettings
          }/${PAYMENT_PATHS.domains}`,
          icon: 'web',
          show: true,
        },

        // NOTE: Commented for future use
        // {
        //   name: 'Payment Links',
        //   path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/${
        //     PAYMENT_PATHS.base
        //   }/${this._currentAccountId() || paymentAccoutId}/${
        //     PAYMENT_PATHS.links
        //   }`,
        //   icon: 'payment-links',
        //   show: true,
        // },
        // {
        //   name: 'Invoices',
        //   path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/${
        //     PAYMENT_PATHS.base
        //   }/${this._currentAccountId() || paymentAccoutId}/${
        //     PAYMENT_PATHS.invoices
        //   }`,
        //   icon: 'payment-invoices',
        //   show: true,
        // },
        // {
        //   name: 'Reports',
        //   isLabel: true,
        //   show: true,
        // },
        // {
        //   name: 'TO BE DETERMINED',
        //   path: `/to-be-determined`,
        //   icon: 'payment-reports',
        //   show: true,
        // },
      ];
    } else if (this.currentDashboardType() === DashboardType.DEVELOPERS) {
      return [
        {
          name: 'Developers',
          isLabel: true,
          show:
            userRole.includes(UserRoles.GettrxAdmin) ||
            userRole.includes(UserRoles.Agent) ||
            userRole.includes(UserRoles.Merchant),
        },
        {
          name: 'API Keys',
          path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/${
            DEVELOPERS_PATHS.base
          }/${this._currentPartnerId() || partnerAccountId}/${
            DEVELOPERS_PATHS.apiKeys
          }`,
          icon: 'api-key',
          show:
            userRole.includes(UserRoles.GettrxAdmin) ||
            userRole.includes(UserRoles.Agent) ||
            userRole.includes(UserRoles.Merchant),
        },
        {
          name: 'Webhooks',
          path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/${
            DEVELOPERS_PATHS.base
          }/${this._currentPartnerId() || partnerAccountId}/${
            DEVELOPERS_PATHS.webhook
          }`,
          icon: 'webhooks',
          show:
            userRole.includes(UserRoles.GettrxAdmin) ||
            userRole.includes(UserRoles.Agent) ||
            userRole.includes(UserRoles.Merchant),
        },
      ];
    } else {
      return [
        {
          name: 'Applications',
          path:
            `/${BUSINESS_PATH.portal}/${this.portalStore.type}/application/` +
            id,
          icon: 'application',
          show:
            (userRole.includes(UserRoles.Merchant) ||
              userRole.includes(UserRoles.MerchantSigner)) &&
            primaryOrSecondaryMerchantIdsExist,
        },
        {
          name: 'Dashboard',
          path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/dashboard/main`,
          icon: 'dashboard',
          show:
            userRole.includes(UserRoles.GettrxAdmin) ||
            userRole.includes(UserRoles.Agent) ||
            userRole.includes(UserRoles.Merchant) ||
            userRole.includes(UserRoles.GettrxRiskManager) ||
            userRole.includes(UserRoles.GettrxUnderwritingManager),
        },
        {
          name: 'Payments',
          path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/payments`,
          icon: 'credit-card',
          show:
            userRole.includes(UserRoles.GettrxAdmin) ||
            userRole.includes(UserRoles.Merchant) ||
            userRole.includes(UserRoles.Agent),
        },
        {
          name: 'Portfolio',
          isLabel: true,
          show:
            userRole.includes(UserRoles.Agent) ||
            userRole.includes(UserRoles.GettrxAdmin),
        },
        {
          name: 'Merchants',
          path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/portfolio/merchants`,
          icon: 'merchant',
          show:
            userRole.includes(UserRoles.Agent) ||
            userRole.includes(UserRoles.GettrxAdmin),
        },
        {
          name: 'Prospects',
          path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/portfolio/prospects`,
          icon: 'zoom-check',
          show:
            userRole.includes(UserRoles.Agent) ||
            userRole.includes(UserRoles.GettrxAdmin),
        },
        {
          name: 'Merchant management',
          isLabel: true,
          show:
            userRole.includes(UserRoles.Bank) ||
            userRole.includes(UserRoles.GettrxAdmin) ||
            userRole.includes(UserRoles.GettrxRiskAnalyst) ||
            userRole.includes(UserRoles.GettrxUnderwriting) ||
            userRole.includes(UserRoles.GettrxRiskManager) ||
            userRole.includes(UserRoles.GettrxUnderwritingManager),
        },
        {
          name: 'Merchants',
          path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/mms`,
          icon: 'merchant',
          show:
            userRole.includes(UserRoles.Bank) ||
            userRole.includes(UserRoles.GettrxAdmin) ||
            userRole.includes(UserRoles.GettrxRiskAnalyst) ||
            userRole.includes(UserRoles.GettrxUnderwriting) ||
            userRole.includes(UserRoles.GettrxRiskManager) ||
            userRole.includes(UserRoles.GettrxUnderwritingManager),
        },
        {
          name: 'Reports',
          isLabel: true,
          show:
            userRole.includes(UserRoles.Merchant) ||
            userRole.includes(UserRoles.Agent) ||
            userRole.includes(UserRoles.GettrxAdmin) ||
            userRole.includes(UserRoles.Bank) ||
            userRole.includes(UserRoles.GettrxSupport) ||
            userRole.includes(UserRoles.GettrxRiskAnalyst) ||
            userRole.includes(UserRoles.GettrxUnderwriting) ||
            userRole.includes(UserRoles.GettrxRiskManager) ||
            userRole.includes(UserRoles.GettrxUnderwritingManager),
        },
        {
          name: 'Auths',
          path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/reports/auths`,
          icon: 'secure',
          show:
            userRole.includes(UserRoles.Merchant) ||
            userRole.includes(UserRoles.GettrxAdmin) ||
            userRole.includes(UserRoles.Agent) ||
            userRole.includes(UserRoles.GettrxSupport) ||
            userRole.includes(UserRoles.GettrxRiskAnalyst) ||
            userRole.includes(UserRoles.GettrxUnderwriting) ||
            userRole.includes(UserRoles.GettrxRiskManager) ||
            userRole.includes(UserRoles.GettrxUnderwritingManager),
        },
        {
          name: 'Settled Batches',
          path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/reports/settled-batches`,
          icon: 'alert-circle',
          show:
            userRole.includes(UserRoles.Merchant) ||
            userRole.includes(UserRoles.GettrxAdmin) ||
            userRole.includes(UserRoles.Agent) ||
            userRole.includes(UserRoles.GettrxSupport) ||
            userRole.includes(UserRoles.GettrxRiskAnalyst) ||
            userRole.includes(UserRoles.GettrxUnderwriting) ||
            userRole.includes(UserRoles.GettrxRiskManager) ||
            userRole.includes(UserRoles.GettrxUnderwritingManager),
        },
        {
          name: 'Settled Volume',
          path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/reports/settled-volume`,
          icon: 'antenna-bars',
          show:
            userRole.includes(UserRoles.Agent) ||
            userRole.includes(UserRoles.GettrxAdmin) ||
            userRole.includes(UserRoles.GettrxSupport) ||
            userRole.includes(UserRoles.GettrxRiskAnalyst) ||
            userRole.includes(UserRoles.GettrxUnderwriting) ||
            userRole.includes(UserRoles.GettrxRiskManager) ||
            userRole.includes(UserRoles.GettrxUnderwritingManager),
        },
        {
          name: 'Deposits',
          path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/reports/deposits`,
          icon: 'building-bank',
          show:
            userRole.includes(UserRoles.Bank) ||
            userRole.includes(UserRoles.Merchant) ||
            userRole.includes(UserRoles.Agent) ||
            userRole.includes(UserRoles.GettrxAdmin) ||
            userRole.includes(UserRoles.GettrxSupport) ||
            userRole.includes(UserRoles.GettrxRiskAnalyst) ||
            userRole.includes(UserRoles.GettrxUnderwriting) ||
            userRole.includes(UserRoles.GettrxRiskManager) ||
            userRole.includes(UserRoles.GettrxUnderwritingManager),
        },
        {
          name: 'Disputes',
          path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/reports/disputes`,
          icon: 'alert-triangle',
          show:
            userRole.includes(UserRoles.Merchant) ||
            userRole.includes(UserRoles.Agent) ||
            userRole.includes(UserRoles.GettrxAdmin) ||
            userRole.includes(UserRoles.GettrxSupport) ||
            userRole.includes(UserRoles.GettrxRiskAnalyst) ||
            userRole.includes(UserRoles.GettrxUnderwriting) ||
            userRole.includes(UserRoles.GettrxRiskManager) ||
            userRole.includes(UserRoles.GettrxUnderwritingManager),
        },
        {
          name: 'Statements',
          path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/reports/statements`,
          icon: 'receipt',
          show:
            userRole.includes(UserRoles.Merchant) ||
            userRole.includes(UserRoles.Agent) ||
            userRole.includes(UserRoles.GettrxAdmin) ||
            userRole.includes(UserRoles.GettrxRiskAnalyst) ||
            userRole.includes(UserRoles.GettrxUnderwriting) ||
            userRole.includes(UserRoles.GettrxRiskManager) ||
            userRole.includes(UserRoles.GettrxUnderwritingManager),
        },
        {
          name: 'Residuals',
          path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/reports/residuals`,
          icon: 'residuals',
          show:
            (userRole.includes(UserRoles.Agent) ||
              userRole.includes(UserRoles.GettrxAdmin)) &&
            this.commonService.hasAccessToEntityResiduals
              ? true
              : false,
        },
        {
          name: 'Advanced Settlement',
          isLabel: true,
          show:
            /**
             * Temporarily commented for future use
             */
            // userRole.includes(UserRoles.Bank) ||
            // userRole.includes(UserRoles.Agent) ||
            userRole.includes(UserRoles.GettrxAdmin),
        },
        {
          name: 'Funding Plans',
          path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/advanced-settlement/funding-plans`,
          icon: 'dollar',
          show:
            /**
             * Temporarily commented for future use
             */
            // userRole.includes(UserRoles.Bank) ||
            // userRole.includes(UserRoles.Agent) ||
            userRole.includes(UserRoles.GettrxAdmin),
        },
        {
          name: 'Onboarding Templates',
          path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/${PORTAL_PATH.onboardTemplate}/onboard-template`,
          icon: 'tariff',
          show: false,
          /**
           * Temporarily commented for future use
           */
          // userRole.includes(UserRoles.Agent) ||
          // userRole.includes(UserRoles.GettrxAdmin),
        },
        {
          name: 'Domains',
          path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/advanced-settlement/domains`,
          icon: 'globe',
          show: false,
        },
        {
          name: 'Resources',
          isLabel: true,
          show:
            /**
             * Temporarily commented for future use
             */
            // userRole.includes(UserRoles.Agent) ||
            userRole.includes(UserRoles.GettrxAdmin),
        },
        {
          name: 'Products',
          path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/resources/products`,
          icon: 'box',
          show: false, // userRole.includes(UserRoles.GettrxAdmin),
        },
        {
          name: 'Training',
          path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/resources/training`,
          icon: 'users',
          show:
            /**
             * Temporarily commented for future use
             */
            // userRole.includes(UserRoles.Agent) ||
            userRole.includes(UserRoles.GettrxAdmin),
        },
        {
          name: 'Ops & UW Tools',
          isLabel: true,
          show:
            userRole.includes(UserRoles.GettrxAdmin) ||
            userRole.includes(UserRoles.GettrxSupport) ||
            userRole.includes(UserRoles.GettrxUnderwriting) ||
            userRole.includes(UserRoles.GettrxDataEntry) ||
            userRole.includes(UserRoles.GettrxUnderwritingManager),
        },
        {
          name: 'Deals',
          path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/ops-and-uw-tools/deals`,
          icon: 'star',
          show:
            userRole.includes(UserRoles.GettrxAdmin) ||
            userRole.includes(UserRoles.GettrxSupport) ||
            userRole.includes(UserRoles.GettrxUnderwriting) ||
            userRole.includes(UserRoles.GettrxDataEntry) ||
            userRole.includes(UserRoles.GettrxUnderwritingManager) ||
            userRole.includes(UserRoles.GettrxDeployment),
        },
        {
          name: 'Automated Underwriting', // before it was "Agreement Express"
          path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/ops-and-uw-tools/agreement-express`,
          icon: 'agreement',
          show:
            userRole.includes(UserRoles.GettrxAdmin) ||
            userRole.includes(UserRoles.GettrxUnderwriting) ||
            userRole.includes(UserRoles.GettrxUnderwritingManager),
        },
        {
          name: 'Merchant Boarding',
          path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/ops-and-uw-tools/merchant-boarding`,
          icon: 'merchant-boarding',
          show:
            userRole.includes(UserRoles.GettrxAdmin) ||
            userRole.includes(UserRoles.GettrxUnderwriting) ||
            userRole.includes(UserRoles.GettrxUnderwritingManager),
        },
        {
          name: 'Import Merchants',
          path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/ops-and-uw-tools/import-merchants`,
          icon: 'user-check',
          show:
            userRole.includes(UserRoles.GettrxAdmin) ||
            userRole.includes(UserRoles.GettrxUnderwriting) ||
            userRole.includes(UserRoles.GettrxUnderwritingManager),
        },
        {
          name: 'Risk Analysis',
          isLabel: true,
          show:
            userRole.includes(UserRoles.GettrxAdmin) ||
            userRole.includes(UserRoles.Bank) ||
            userRole.includes(UserRoles.GettrxRiskAnalyst) ||
            userRole.includes(UserRoles.GettrxRiskManager),
        },
        {
          name: 'Risk Events',
          path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/${PORTAL_PATH.riskAnalysis}/risk-events`,
          icon: 'events',
          show:
            userRole.includes(UserRoles.GettrxAdmin) ||
            userRole.includes(UserRoles.Bank) ||
            userRole.includes(UserRoles.GettrxRiskAnalyst) ||
            userRole.includes(UserRoles.GettrxRiskManager),
        },
        {
          name: 'Settlement Alerts',
          path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/${PORTAL_PATH.riskAnalysis}/settlement-alerts`,
          icon: 'alert-triangle',
          show:
            userRole.includes(UserRoles.GettrxAdmin) ||
            userRole.includes(UserRoles.Bank) ||
            userRole.includes(UserRoles.GettrxRiskAnalyst) ||
            userRole.includes(UserRoles.GettrxRiskManager),
        },
        {
          name: 'Daily Statistics',
          path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/${PORTAL_PATH.riskAnalysis}/daily-statistics`,
          icon: 'chart-dots',
          show:
            userRole.includes(UserRoles.GettrxAdmin) ||
            userRole.includes(UserRoles.Bank) ||
            userRole.includes(UserRoles.GettrxRiskAnalyst) ||
            userRole.includes(UserRoles.GettrxRiskManager),
        },
        {
          name: 'Settings',
          isLabel: true,
          show:
            userRole.includes(UserRoles.Agent) ||
            userRole.includes(UserRoles.GettrxAdmin) ||
            userRole.includes(UserRoles.Merchant),
        },
        {
          name: 'Onboarding Templates',
          path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/${PORTAL_PATH.onboardTemplate}/onboard-template`,
          icon: 'tariff',
          show:
            userRole.includes(UserRoles.Agent) ||
            userRole.includes(UserRoles.GettrxAdmin),
        },
        {
          name: 'Accounts',
          path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/${PORTAL_PATH.onboardTemplate}/${SETTINGS_PATH.accounts}`,
          icon: 'building-store',
          show:
            userRole.includes(UserRoles.GettrxAdmin) ||
            userRole.includes(UserRoles.GettrxSupport) ||
            userRole.includes(UserRoles.GettrxUnderwriting) ||
            userRole.includes(UserRoles.GettrxDataEntry) ||
            userRole.includes(UserRoles.GettrxUnderwritingManager) ||
            userRole.includes(UserRoles.GettrxDeployment),
        },
        {
          name: 'Entities',
          path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/${PORTAL_PATH.onboardTemplate}/manage-entities`,
          icon: 'building-skyscraper',
          show: userRole.includes(UserRoles.GettrxAdmin),
        },
        {
          name: 'Users',
          path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/settings/users`,
          icon: 'users',
          show: userRole.includes(UserRoles.GettrxAdmin),
        },
        // {
        //   name: 'My Account',
        //   path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/settings/my-account`,
        //   icon: 'user',
        //   show: false, // userRole.includes(UserRoles.GettrxAdmin),
        // },
        {
          name: 'My Domains',
          path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/settings/my-domains`,
          icon: 'globe',
          show: false, // userRole.includes(UserRoles.GettrxAdmin),
        },
        {
          name: 'My Relationships',
          path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/settings/my-relationships`,
          icon: 'users',
          show: false, // userRole.includes(UserRoles.GettrxAdmin),
        },
        {
          name: 'Developers',
          path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/${PORTAL_PATH.developers}`,
          icon: 'code',
          show:
            userRole.includes(UserRoles.GettrxAdmin) ||
            userRole.includes(UserRoles.Agent) ||
            userRole.includes(UserRoles.Merchant),
        },
        {
          name: 'Equipments',
          path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/settings/${PORTAL_PATH.equipments}`,
          icon: 'point-of-sale',
          show:
            userRole.includes(UserRoles.Agent) ||
            userRole.includes(UserRoles.GettrxAdmin),
        },

        // {
        //   name: 'Daily Stats Deposits',
        //   path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/${PORTAL_PATH.riskAnalysis}/stats-deposits`,
        //   icon: 'chart-pie',
        //   show: userRole.includes(UserRoles.GettrxAdmin),
        // },
        // {
        //   name: 'Daily Stats Credits',
        //   path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/${PORTAL_PATH.riskAnalysis}/stats-credits`,
        //   icon: 'alert-triangle',
        //   show: userRole.includes(UserRoles.GettrxAdmin),
        // },
        // {
        //   name: 'Daily Stats Sales',
        //   path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/${PORTAL_PATH.riskAnalysis}/stats-sales`,
        //   icon: 'alert-triangle',
        //   show: userRole.includes(UserRoles.GettrxAdmin),
        // },
        // {
        //   name: 'Daily Stats Chargeback',
        //   path: `/${BUSINESS_PATH.portal}/${this.portalStore.type}/${PORTAL_PATH.riskAnalysis}/stats-chargebacks`,
        //   icon: 'alert-triangle',
        //   show: userRole.includes(UserRoles.GettrxAdmin),
        // },
      ];
    }
  }

  /**
   * Get Branding Data
   */
  public get brandingData(): IBrandingResponse {
    return this.commonService.brandingData;
  }

  /** Update Dashboard type */
  public updateDashboardType(type: DashboardType) {
    return this.dashboardType.next(type || DashboardType.DEFAULT);
  }

  /** Get Dashboard type */
  public get dashboardType$(): Observable<DashboardType> {
    return this.dashboardType.asObservable();
  }
}
