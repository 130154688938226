import {
  CDateFilterListValue,
  EDateFilter,
} from '@parameters/filter/date.parameter';

export const enum EMetricsChartCardBrandVolumeParams {
  startDate = 'start_date',
  endDate = 'end_date',
  midList = 'mids',
  binList = 'bins',
  cardBrandList = 'card_brands',
  relationshipList = 'relationship_ids',
  volumeAs = 'volume_as',
}

export const CMetricsChartCardBrandVolumeParamsDate =
  CDateFilterListValue[EDateFilter.last30Days];
