import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { AuthenticationService } from '../service';
import { PORTAL } from '@parameters/routing/portal.parameter';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard {
  constructor(
    private _authService: AuthenticationService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    const token = this._authService.authToken;
    const requiredRole = route.data.role || [];

    if (token) {
      if (this._authService.hasMultipleRoles(requiredRole)) {
        return true;
      } else {
        // Redirect to a different route if the user doesn't have the required role
        this.router.navigate(['/miscellaneous/error'], {
          queryParams: { code: 403 },
        });
        return false;
      }
    } else {
      const portalEntity = state.url.includes(`/${PORTAL.entity}/`);
      const loginUrl = portalEntity ? '/entity/login' : '/login';
      this.router.navigate([loginUrl], {
        queryParams: {
          'return-url': state.url,
        },
      });
    }
  }
}
