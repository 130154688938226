import {
  IResponseBase,
  IResponseData,
} from '@interfaces/generic/responses.interface';
import {
  EProspectsListParams,
  EProspectsListParamSort,
} from '@parameters/prospects/prospects-list.parameter';
import { EProspectStatus } from '@parameters/prospects/prospects.parameter';
import { IProspectItem } from '@interfaces/prospects/prospect.interface';

export interface IProspectsListParams {
  [EProspectsListParams.status]: EProspectStatus[];
  [EProspectsListParams.limit]: number;
  [EProspectsListParams.page]: number;
  [EProspectsListParams.sort]: EProspectsListParamSort;
  [EProspectsListParams.search]: string;
  [EProspectsListParams.startDate]: number;
  [EProspectsListParams.endDate]: number;
  [EProspectsListParams.relationshipId]: string[];
}

export const CProspectsListParamsDefault: IProspectsListParams = {
  [EProspectsListParams.status]: [],
  [EProspectsListParams.limit]: 10,
  [EProspectsListParams.page]: 1,
  [EProspectsListParams.sort]: EProspectsListParamSort.createdDateDesc,
  [EProspectsListParams.search]: '',
  [EProspectsListParams.startDate]: null,
  [EProspectsListParams.endDate]: null,
  [EProspectsListParams.relationshipId]: [],
};

export interface IProspectsListResponse
  extends IResponseBase<IResponseData<IProspectItem>> {}
