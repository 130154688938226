import { IResponseBase } from './../../interfaces/generic/responses.interface';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { GenericClientService } from '@clients/generic/generic-client.service';
import { GenericHelper } from '@helpers/generic.helper';
import {
  IPaymentAccessToken,
  IPaymentAccountListResponse,
  IPaymentAccountParams,
  IPaymentConfirmPassword,
} from '@interfaces/payments/payment-account.interface';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import {
  CPaymentAccountParamsDefault,
  EPaymentAccountParams,
} from '@parameters/payments/payment-account.parameter';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { AuthSession } from 'src/app/auth/models/auth-session.enum';

@Injectable({ providedIn: 'root' })
export class PaymentAccountsClientService {
  private readonly _genericClient = inject(GenericClientService);
  private readonly _cookieService = inject(CookieService);

  /**
   * Get Payment Accounts List
   * @param params
   * @returns
   */
  public getPaymentAccountsList(params: IPaymentAccountParams) {
    const endpoint = EndpointsParameter.PaymentAccounts;

    const httpParams = new HttpParams()
      .set(
        EPaymentAccountParams.page,
        params?.page || CPaymentAccountParamsDefault[EPaymentAccountParams.page]
      )
      .set(
        EPaymentAccountParams.limit,
        params?.limit ||
          CPaymentAccountParamsDefault[EPaymentAccountParams.limit]
      )
      .set(
        EPaymentAccountParams.search,
        params?.search ||
          CPaymentAccountParamsDefault[EPaymentAccountParams.search]
      );
    return this._genericClient.genericGet<IPaymentAccountListResponse>(
      endpoint,
      { params: httpParams }
    );
  }

  /**
   * Authentication Payment account
   * @param accountId
   * @returns
   */
  public authenticatePaymentAccount(
    accountId: string
  ): Observable<IResponseBase<IPaymentAccessToken>> {
    const paymentAuthToken = this._cookieService.get(
      AuthSession.paymentAuthToken
    );
    const endpoint = `${GenericHelper.replaceUrl(
      EndpointsParameter.PaymentAccountAuthentication,
      accountId
    )}`;

    const httpHeaders = new HttpHeaders().set(
      'Payment-Authorization',
      `Bearer ${paymentAuthToken}` || ''
    );

    return this._genericClient.genericGet<IResponseBase<IPaymentAccessToken>>(
      endpoint,
      {
        headers: httpHeaders,
      }
    );
  }

  /**
   * Get Payment Auth Token
   * @param body
   * @returns
   */
  public getPaymentAuthToken(
    body: IPaymentConfirmPassword
  ): Observable<IResponseBase<string>> {
    const endpoint = EndpointsParameter.PaymentAuthToken;

    return this._genericClient.genericPost<IResponseBase<string>>(
      endpoint,
      body
    );
  }
}
