import { IResponseBase } from '@interfaces/generic/responses.interface';
import { EValidateMidParams } from '@parameters/ops-and-uw-tools/merchant-boarding/merchant-boarding.parameter';

export interface IValidateMid {
  sfAccountId: string;
  dbaName: string;
  legalName: string;
  fullMerchantAddress: string;
  controllingPrincipalFullName: string;
  salesforceUrl: string;
}

export interface IValidateMidResponse extends IResponseBase<IValidateMid> {}

export interface IValidateMidParams {
  [EValidateMidParams.mid]: string;
}

export const CValidateMidParamsDefault: IValidateMidParams = {
  [EValidateMidParams.mid]: undefined,
};
