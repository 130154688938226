import { Injectable } from '@angular/core';
import { GenericClientService } from '@clients/generic/generic-client.service';
import {
  CMetricsKpisProspectParamsDefault,
  IMetricsKpisProspectParams,
  IMetricsKpisProspectResponse,
} from '@interfaces/metrics/metrics-kpis-prospect.interface';
import { Observable } from 'rxjs';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import { HttpParams } from '@angular/common/http';
import { EMetricsKpisProspectParams } from '@parameters/metrics/metrics-kpis-prospect.parameter';

@Injectable()
export class MetricsKpisProspectClientService {
  constructor(private genericClient: GenericClientService) {}

  getMetricsKpisProspect(
    queryParams: IMetricsKpisProspectParams
  ): Observable<IMetricsKpisProspectResponse> {
    const endpoint = EndpointsParameter.MetricsKPIsProspect;

    const params = new HttpParams()
      .set(
        EMetricsKpisProspectParams.startDate,
        queryParams[EMetricsKpisProspectParams.startDate] ||
          CMetricsKpisProspectParamsDefault[
            EMetricsKpisProspectParams.startDate
          ]
      )
      .set(
        EMetricsKpisProspectParams.endDate,
        queryParams[EMetricsKpisProspectParams.endDate] ||
          CMetricsKpisProspectParamsDefault[EMetricsKpisProspectParams.endDate]
      )
      .set(
        EMetricsKpisProspectParams.kpiList,
        queryParams[EMetricsKpisProspectParams.kpiList].join(',') ||
          CMetricsKpisProspectParamsDefault[
            EMetricsKpisProspectParams.kpiList
          ].join(',')
      )
      .set(
        EMetricsKpisProspectParams.midList,
        queryParams[EMetricsKpisProspectParams.midList].join(',') ||
          CMetricsKpisProspectParamsDefault[
            EMetricsKpisProspectParams.midList
          ].join(',')
      )
      .set(
        EMetricsKpisProspectParams.binList,
        queryParams[EMetricsKpisProspectParams.binList].join(',') ||
          CMetricsKpisProspectParamsDefault[
            EMetricsKpisProspectParams.binList
          ].join(',')
      );
    return this.genericClient.genericGet(endpoint, { params });
  }
}
