import { Component, OnInit, Input } from '@angular/core';
import { Params } from '@angular/router';

// Breadcrumb component interface
export interface Breadcrumb {
  name: string;
  isLink: boolean;
  link?: string;
  state?: any;
  queryParams?: Params | null;
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  // input variable
  @Input() breadcrumb: Breadcrumb[];

  constructor() {}

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    // concatenate default properties with passed properties
    this.breadcrumb = this.breadcrumb;
  }
}
